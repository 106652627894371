import { FormProps, ForwardedRefProps } from '@typedefs/props';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { I18nKey } from 'react-i18next';

import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { Input, Size } from '@shared/unsorted/Input/Input';
import { SelectionOption } from '@typedefs/selectOption';
import { IconName } from '../Icon/Icon';
import { ToolTipBox, ToolTipBoxProps } from '../ToolTipBox/ToolTipBox';
import { useTypeAhead } from './hook';

const typeAheadDirection = ['upward', 'downward'] as const;
const optionState = ['disabled', 'default'] as const;

type Direction = typeof typeAheadDirection[number];
type OptionState = typeof optionState[number];

const styles = {
    container: Cn.c('w-full relative'),
    list: (direction: Direction, size: Size) => {
        let sizeStyles;

        if (direction === 'downward') {
            sizeStyles = Cn.c('mt-1');
        } else {
            switch (size) {
                case 'small':
                    sizeStyles = Cn.c('bottom-10');
                    break;
                case 'medium':
                    sizeStyles = Cn.c('bottom-12');
                    break;
                case 'large':
                    sizeStyles = Cn.c('bottom-14');
                    break;
                case 'x-large':
                    sizeStyles = Cn.c('bottom-[4.5rem]');
                    break;
            }
        }

        return Cn.join([
            Cn.c('w-full max-h-96 overflow-auto rounded-lg shadow-md bg-surface-default z-[100] absolute'),
            sizeStyles,
        ])
    },
    listRow: (size: Size, state: OptionState) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c('font-paragraph-xsmall-regular');
                break;
            case 'medium':
                sizeStyles = Cn.c('font-paragraph-small-regular');
                break;
            case 'large':
            case 'x-large':
                sizeStyles = Cn.c('font-paragraph-base-regular');
                break;
        }

        return Cn.join([
            Cn.c('cursor-pointer py-2 px-4 block flex items-center justify-between w-full text-emphasized'),
            sizeStyles,
            state === 'disabled' ? Cn.c('cursor-not-allowed text-disabled') : Cn.c('hover:bg-surface-hovered-default'),
        ]);
    },
};

interface Props<FieldsType extends FieldValues>
    extends
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLInputElement> {
    label?: I18nKey;
    placeholder?: I18nKey;
    direction?: Direction;
    required?: boolean;
    options: SelectionOption[];
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    onInputChange?: (value?: string) => void;
    errorLabel?: I18nKey;
    form: UseFormReturn<any, any>; // TODO: [IMPLEMENT] Decide on what will be the type for this form from rescript-hook-form
    inputValue?: string;
    disabled?: boolean;
    /** Size of the input */
    size?: Size;
    /**  Icon of the input */
    iconName?: IconName;
    /** Tooltip displayed when the input is disabled */
    disabledTooltip?: ToolTipBoxProps;
}

// @ocaml.doc("TypeAhead Component
// - `name`: name of the input
// - `label`: optional - the label to display for the input
// - `className`: optional - class to apply
// - `labelClassName`: optional - class to apply to the label
// - `inputClassName`: optional - class to apply to the input
// - `placeholder`: optional - placeholder for the input
// - `errors`: optional - the possible errors of a form(returned by the`RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically(returned by the`RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message(defaults to the`label` prop if provided, `name` otherwise)
// - `required`: optional - show the * for required input - false by default
// - `options`: array of select like options to be displayed in a list
// - `direction`: optional - absolute positioning of the list, whether to open upwards or downwards - downward by default
// - `form`: the current form being used
// - `onInputChange`: optional - trigger when user is typing something
// - `inputValue`: optional - default value
// - `disabled`: optional - is the input disabled or not - false by default
// @ocaml.doc("
// `disabled` attribute not present on the component because it doesn't work as expected. 
// Workaround for this, wrap the entire component in an element that can be disabled.
// e.g.

//  < fieldset disabled = true >
//    <TypeAhead />
//  </fieldset >
//     ")
const TypeAhead = <FieldsType extends FieldValues>(
    {
        name,
        label,
        placeholder,
        direction = 'downward',
        required: isRequired = false,
        options = [],
        className,
        inputClassName,
        labelClassName,
        onInputChange,
        errors,
        clearErrors,
        errorLabel,
        form,
        inputValue,
        disabled: isDisabled,
        size = 'medium',
        iconName,
        forwardedRef,
        disabledTooltip: typeAheadDisabledTooltip,
    }: Props<FieldsType>,
) => {
    const { typeAheadRef, state: { mode, nameValue }, onChange, onClick, onFocus } = useTypeAhead(name, form, onInputChange, inputValue);

    const typeAheadInput = (
        <div className={styles.container} ref={typeAheadRef}>
            <Input
                name={name}
                required={isRequired}
                label={label}
                className={className}
                inputClassName={inputClassName}
                labelClassName={labelClassName}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={onFocus}
                value={mode.type === 'show' ? nameValue : mode.value}
                errors={errors}
                clearErrors={clearErrors}
                errorLabel={errorLabel}
                disabled={isDisabled}
                autocomplete='off'
                iconName={iconName}
                size={size}
                forwardedRef={forwardedRef}
            />
            {mode.type === 'edit' &&
                <div className={styles.list(direction, size)} hidden={options.length === 0}>
                    {options.map(({ key, label, value, disabled, disabledTooltip }) => {
                        const state: OptionState = disabled ? 'disabled' : 'default';

                        const labelContent = (
                            <div className={styles.listRow(size, state)} key={key} onClick={() => !disabled && onClick(value, label)}>{label}</div>
                        );

                        if (isDefined(disabledTooltip) && disabled) {
                            return (
                                <ToolTipBox
                                    key={key}
                                    delay={isDefined(disabledTooltip.delay) ? disabledTooltip.delay : [500, 0]} // TODO: 500ms show delay is temporary. Might need to play with other select options to make sure it's not too long/short
                                    {...disabledTooltip}
                                >
                                    {labelContent}
                                </ToolTipBox>
                            );
                        }

                        return labelContent;
                    })}
                </div>
            }
        </div>
    );

    return (isDefined(typeAheadDisabledTooltip) && isDisabled
        ? <ToolTipBox
            {...typeAheadDisabledTooltip}
        >
            {typeAheadInput}
        </ToolTipBox>
        : typeAheadInput
    );
};

export {
    TypeAhead
};

