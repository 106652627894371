import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        email: string;
        password: string;
    }

    export const defaultValues = (): EncoderType => {
        return {
            email: '',
            password: ''
        }
    }
}

namespace Decoder {
    export const schema = z.object({
        email: z.string().min(1).email(),
        password: z.string().min(1),
    })
}

export {
    Encoder,
    Decoder
}