import { Cn } from '@helpers/unsorted/classNames';
import { HTMLAttributes } from 'react';
import { ActivityLog, ActivityTargetType } from '@helpers/core/activityLog';
import { Icon, IconName } from '../Icon/Icon';
import { useActivityLogEntry } from './hook';
import { ActivityLogMessage } from './ActivityLogMessage/ActivityLogMessage';

export type Props = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  relativeDate?: Date;
  activityLog: ActivityLog;
}

export const styles = {
  container: Cn.c('w-full flex flex-row items-center gap-3'),
  icon: Cn.c('w-[14px] h-[14px]'),
  message: Cn.c('text-sm mb-1 block'),
  timestamp: Cn.c('text-xs text-placeholder block'),
}

export const iconByActivityLogTarget: Record<ActivityTargetType, IconName> = {
  USER: "user",
  SETTINGS: "settings",
  APPLICATION: "documentNewspaperFill",
  CANDIDATE: "candidateFill",
  JOB: "briefcaseFilled",
  STEP: "briefcaseFilled",
  EVENT: "calendarFill",
}

const ActivityLogEntry: React.FunctionComponent<Props> = ({ activityLog, relativeDate, className = '', ...rest }) => {
  const { formattedActivityLogTimestamp } = useActivityLogEntry({ relativeDate, activityLog });

  return (
    <div className={Cn.join([styles.container, className])} {...rest}>
      <div>
        <Icon className={styles.icon} name={iconByActivityLogTarget[activityLog.targetType]} />
      </div>
      <div>
        <span className={styles.message}>
          <ActivityLogMessage activityLog={activityLog} />
        </span>
        <span className={styles.timestamp}>
          {formattedActivityLogTimestamp}
        </span>
      </div>
    </div >
  )
}

export {
  ActivityLogEntry,
}
