import { FunctionComponent } from "react"
import { useCSVImportSetupStep } from "./hook";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { isUndefined } from "@helpers/core/typeGuards";
import { FormProvider } from "react-hook-form";
import { UploadDragAndDrop } from "../../UploadDragAndDrop/UploadDragAndDrop";
import { ApplicationInformationSubForm } from "@shared/application/ApplicationInformationSubForm/ApplicationInformationSubForm";

const styles = {
    footer: Cn.c("border-t border-default p-6 flex justify-end"),
    emptyContainer: Cn.c("flex justify-center items-center h-full"),
    container: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    section: Cn.c('flex flex-col py-6'),
    sectionBorder: Cn.c('border-b border-default'),
    uploadFileRequirement: Cn.c('font-paragraph-small-regular text-subdued mt-2'),
    downloadCSVText: Cn.c("text-primary-default font-paragraph-small-regular cursor-pointer underline"),
    header: Cn.c('font-paragraph-base-semi-bold text-emphasized'),
}

const CSVImportSetupStep: FunctionComponent = () => {
    const {
        applicationInformationSubFormData,
        form,
        onSubmit,
        onSubFormChange,
        downloadTemplate,
        isSubmitting,
        csvFile,
        onUpload,
        removeFile
    } = useCSVImportSetupStep()

    const { t } = useTranslation()

    return (
        <>
            {
                applicationInformationSubFormData.isLoading
                    ? <div className={styles.emptyContainer}><Loader variant="primaryDefault" size="lg" /></div>
                    : <form className={styles.container} onSubmit={onSubmit} id="csv-form">
                        <div className={Cn.join([styles.section, styles.sectionBorder])}>
                            <p className={styles.header}>{t("applications.csvImport.setupStep.sectionHeader.importSettings")}</p>
                            <FormProvider {...form}>
                                <ApplicationInformationSubForm
                                    jobPositions={applicationInformationSubFormData.data.jobPositions}
                                    entryCategories={applicationInformationSubFormData.data.entryCategories}
                                    acquisitionCategories={applicationInformationSubFormData.data.acquisitionCategories}
                                    recruitmentSteps={applicationInformationSubFormData.data.recruitmentSteps}
                                    onChange={onSubFormChange}
                                />
                            </FormProvider>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.header}>{t("applications.csvImport.setupStep.sectionHeader.uploadFile")}</p>
                            <p className={styles.uploadFileRequirement}>
                                <Trans
                                    i18nKey={"applications.csvImport.setupStep.uploadFileRequirement"}
                                    components={{
                                        highlight: <span
                                            className={styles.downloadCSVText}
                                            onClick={downloadTemplate}
                                        >
                                        </span>
                                    }}
                                />
                            </p>
                            <UploadDragAndDrop
                                onUpload={onUpload}
                                onRemove={removeFile}
                                csvFile={csvFile}
                            />
                        </div>
                    </form>
            }
            <div className={styles.footer}>
                <Button
                    formId="csv-form"
                    type="submit"
                    variant="primaryFilled"
                    size="md"
                    iconName="arrowRightLine"
                    isTrailingIcon
                    isLoading={isSubmitting}
                    isDisabled={isUndefined(csvFile)}>
                    {t("applications.csvImport.moveToPreview")}
                </Button>
            </div>
        </>
    )


}

export {
    CSVImportSetupStep
}