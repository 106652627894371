import { SelectableStepTypesQuery } from '@entities';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { displayedRecruitmentStages } from '@helpers/core/constants';
import { hasValue } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { CollapsibleRecruitmentStage } from '@shared/job_position/JobPositionRecruitmentSteps/RecruitmentStage/CollapsibleRecruitmentStage';
import { RecruitmentStageHeader } from '@shared/job_position/JobPositionRecruitmentSteps/RecruitmentStage/RecruitmentStageHeader';
import { isStepEditable } from '@shared/job_position/JobPositionRecruitmentSteps/helpers';
import { Button } from '@shared/unsorted/Button/Button';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as value from '../value';
import { Row } from './Row';
import { useRecruitmentStageCreateForm } from './hook';

const styles = {
    container: Cn.c("flex flex-col space-y-6"),
    table: Cn.c('w-full font-paragraph-small-regular'),
    row: (isFirst = false) => Cn.join([
        Cn.c('flex py-0 px-6 items-start relative'),
        Cn.ifTrue(isFirst, Cn.c('mt-4')),
    ]),
    noStepsContainer: Cn.c('mt-6 ml-10 text-subdued font-paragraph-small-regular'),
    addStepContainer: Cn.c('pt-6 pb-7 ml-7'),
};

interface Props {
    form: UseFormReturn<value.Encoder.EncoderType>;
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>;
}

const RecruitmentStageCreateForm: FunctionComponent<Props> = ({ form, recruitmentStepTypes }) => {
    const { t } = useTranslation();

    const {
        currentDropArea,
        setCurrentDropArea,
        onDragEnd,
        fields,
        insertNewRecruitmentStep,
        removeRecruitmentStep,
        stepTypeOptions,
    } = useRecruitmentStageCreateForm(form, recruitmentStepTypes);

    return (
        <div className={styles.container}>
            <DragDropContext
                onDragEnd={(...parameters) => {
                    setCurrentDropArea(null);
                    onDragEnd(...parameters);
                }}
                onDragStart={(result) => setCurrentDropArea(result.source.droppableId)}
                onDragUpdate={(result) => setCurrentDropArea(result.destination?.droppableId || null)}
            >
                {displayedRecruitmentStages.map((stage, stageIndex) => {
                    const stepList = fields.filter(step => step.stage === stage);

                    return (
                        <CollapsibleRecruitmentStage
                            index={stageIndex + 1}
                            stageName={`jobPositions.panel.recruitmentFlow.stage.${stage}.name`}
                            stageDescription={`jobPositions.panel.recruitmentFlow.stage.${stage}.description`}
                            isCollapsed={false}
                            showIsEmptyStageIndicator={false}
                            key={stage}
                        >
                            <Droppable droppableId={stage}
                                renderClone={(provided, snapshot, rubric) => {
                                    const stepIndex = rubric.source.index;
                                    const step = stepList[stepIndex];

                                    return (
                                        <div className={styles.row(rubric.source.index === 0)}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            {hasValue(step) &&
                                                <Row
                                                    fields={fields}
                                                    stage={stage}
                                                    provided={provided}
                                                    step={step}
                                                    index={stepIndex}
                                                    form={form}
                                                    removeRecruitmentStep={removeRecruitmentStep}
                                                    stepTypeOptions={stepTypeOptions} />
                                            }
                                        </div>
                                    )
                                }}
                            >
                                {provided => (
                                    <div className={styles.table}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <RecruitmentStageHeader />
                                        {stepList.map((step, index) => {
                                            const isEditable = isStepEditable(step);

                                            return (
                                                <Draggable draggableId={step.id} index={index} isDragDisabled={!isEditable} key={step.id}>
                                                    {provided =>
                                                        <div className={styles.row(index === 0)}
                                                            {...provided.draggableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Row
                                                                fields={fields}
                                                                stage={stage}
                                                                provided={provided}
                                                                step={step}
                                                                index={index}
                                                                form={form}
                                                                removeRecruitmentStep={removeRecruitmentStep}
                                                                stepTypeOptions={stepTypeOptions}
                                                            />
                                                        </div>
                                                    }
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable >
                            {
                                stepList.length === 0 && currentDropArea !== stage
                                && <div className={styles.noStepsContainer}>{t("jobPositions.panel.recruitmentFlow.table.noSteps")}</div>
                            }
                            <Button
                                size='md'
                                variant='primaryPlain'
                                iconName='plus'
                                onClick={() => insertNewRecruitmentStep(stage)}
                                className={styles.addStepContainer}
                            >
                                {t("jobPositions.panel.add")}
                            </Button>
                        </CollapsibleRecruitmentStage>
                    );
                })}
            </DragDropContext>
        </div>
    );
};

export {
    RecruitmentStageCreateForm
};

