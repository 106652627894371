import { toQueryString, useRouteQuery } from "./hook";

export namespace EventRegistration {
    export const PATH_NAME = '/event_registration';

    interface QueryParams {
        emailIdentifier: string;
        applicationId: string;
    }

    export const useSearchParams = (): QueryParams => {
        const searchParams = useRouteQuery();

        return {
            emailIdentifier: searchParams.get('email_identifier') ?? '',
            applicationId: searchParams.get('application_id') ?? '',
        };
    };

    export const toRoute = ({ emailIdentifier, applicationId }: { emailIdentifier?: string | null, applicationId?: string | null } = {}) => {
        return {
            PATH_NAME,
            search: `?${toQueryString({
                email_identifier: emailIdentifier,
                application_id: applicationId,
            })}`,
        };
    };
}

export namespace DocumentUpload {
    export const PATH_NAME = '/document_upload';

    interface QueryParams {
        applicationId: string;
    }

    export const useSearchParams = (): QueryParams => {
        const searchParams = useRouteQuery();

        return {
            applicationId: searchParams.get('application_id') ?? '',
        };
    };

    export const toRoute = ({ applicationId }: { applicationId?: string | null } = {}) => {
        return {
            PATH_NAME,
            search: `?${toQueryString({
                application_id: applicationId,
            })}`,
        };
    };
}