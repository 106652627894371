import { FunctionComponent } from "react";

import { ChildrenProps } from "@typedefs/props";

import { assertNever } from "@helpers/typeHelpers";
import { Cn } from "@helpers/unsorted/classNames";
import EmptyTarget from "../../../../../public/icons/empty-target.svg";
import RemixIconsCalendar from "../../../../../public/icons/remix-icons_calendar.svg";
import RemixIconsEmptyDocument from "../../../../../public/icons/remix-icons_document-file-list-fill.svg";
import RemixIconsEmptyEmail from "../../../../../public/icons/remix-icons_mail-fill.svg";
import RemixIconsEmptyReport from "../../../../../public/icons/remix-icons_report.svg";
import RemixIconsSearch from "../../../../../public/icons/remix-icons_search.svg";
import RemixIconsSuitcase from "../../../../../public/icons/remix-icons_suitcase.svg";
import RemixIconsEmptyCandidate from "../../../../../public/icons/remix-icons_empty-candidate.svg";

const emptyStateIcons = [
  'emptyReport',
  'emptyJobPosition',
  'emptyCandidate',
  'emptyTarget',
  'emptyDocument',
  'emptyEmail',
  'emptyCalendar',
  'notFound',
] as const;

type EmptyStateIconName = typeof emptyStateIcons[number];
interface Props extends ChildrenProps {
  className?: string;
  name: EmptyStateIconName;
}

const EmptyStateIcon: FunctionComponent<Props> = ({ className, name }) => {
  const iconClassname = Cn.getSome(className);

  switch (name) {
    case "emptyReport":
      return <RemixIconsEmptyReport className={iconClassname} />;
    case "emptyJobPosition":
      return <RemixIconsSuitcase className={iconClassname} />;
    case "emptyCandidate":
      return <RemixIconsEmptyCandidate className={iconClassname} />;
    case "emptyTarget":
      return <EmptyTarget className={iconClassname} />;
    case "emptyDocument":
      return <RemixIconsEmptyDocument className={iconClassname} />;
    case "emptyEmail":
      return <RemixIconsEmptyEmail className={iconClassname} />;
    case "emptyCalendar":
      return <RemixIconsCalendar className={iconClassname} />;
    case "notFound":
      return <RemixIconsSearch className={iconClassname} />;

    default:
      assertNever(name);

      return null;
  }
};

export {
  EmptyStateIcon, EmptyStateIconName, emptyStateIcons
};

