const toString = (value: boolean) => value ? 'true' : 'false';

const fromString = (value: string): boolean => {
    switch(value) {
        case 'true': return true;
        case 'false': return false;
    }

    throw new Error(`Boolean expected, got ${value}`,)
}

export {
    toString,
    fromString,
};
