import { isDefined } from "@helpers/core/typeGuards";
import { useErrorMessage } from "@helpers/hooks/unsorted/errorMessageHook";
import { FieldErrors } from 'react-hook-form';
import { I18nKey, useTranslation } from "react-i18next";
import { z } from 'zod';

interface RadioInputGroupHookType {
    error?: string;
    getIsChecked: (currentValue: string) => boolean;
}

const useRadioInputGroup = (name: string, value?: string, label?: I18nKey, errorLabel?: I18nKey, errors?: FieldErrors): RadioInputGroupHookType => {
    const { t } = useTranslation();

    const error = useErrorMessage(
        name,
        errors,
        isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
    );

    const getSelectedValue = () => {
        return z.string().default("").parse(value)
    }

    const getIsChecked = (currentValue: string) => {
        const selectedValue = getSelectedValue()

        return selectedValue === currentValue
    }

    return {
        error,
        getIsChecked
    }
}

export {
    useRadioInputGroup
}
