import { useBackToPreviousListPage } from "@helpers/hooks/useBackToPreviousListPage"
import { Cn } from "@helpers/unsorted/classNames"
import { Icon } from "@shared/unsorted/Icon/Icon"

const styles = {
    closeIcon: Cn.c("w-6 h-6 text-icons-emphasized cursor-pointer"),
}

const CloseIcon = () => {
    const { backToPreviousListPage } = useBackToPreviousListPage()

    return <Icon name="close" className={styles.closeIcon} onClick={backToPreviousListPage} />
}

export {
    CloseIcon
}