import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BulkActionGeneralConfirmationPayload } from "../reducer";
import { isOneOf } from "@helpers/core/typeGuards";
import { bulkActions, needSetupUpdateStatusBulkActions } from "@helpers/core/constants";
import { useBulkActionGeneralConfirmationModal } from "./hook";

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-warning-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-warning-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-warning-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    noteContainer: Cn.c("p-3 bg-surface-warning-subdued rounded-lg mt-4"),
    noteText: Cn.c("text-warning-emphasized font-paragraph-xsmall-medium"),
    buttons: Cn.c("mt-6 flex space-x-3 justify-end px-6")
}

interface Props extends ModalProps {
    closeModal: VoidFunction;
    payload?: BulkActionGeneralConfirmationPayload;
}

const BulkActionGeneralConfirmationModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    payload,
}) => {
    const { t } = useTranslation();

    const action = payload?.action;
    const optionalPayload = payload?.bulkActionPayload;
    const archiveReason = optionalPayload?.type === 'archive' ? optionalPayload.archiveReason : undefined;

    const {
        numberOfCandidate,
        note,
        confirm,
    } = useBulkActionGeneralConfirmationModal({
        closeModal,
        payload,
    });

    return (
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[30rem]"
        >
            {
                isOneOf(bulkActions)(action) && !isOneOf(needSetupUpdateStatusBulkActions)(action) &&
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.outerCircle}>
                            <div className={styles.innerCircle}>
                                <Icon name="errorWarning" className={styles.warningIcon} />
                            </div>
                        </div>
                        <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                    </div>
                    <div className={styles.body}>
                        <p className={styles.description}>{t(`applications.bulkAction.${action}.title`)}</p>
                        <p className={styles.subDescription}>
                            {t(`applications.bulkAction.${action}.description`,
                                {
                                    count: numberOfCandidate,
                                    archiveReason: archiveReason && t(`recruitmentStepStatusV2.${archiveReason}`)
                                }
                            )}
                        </p>
                        {note && <div className={styles.noteContainer}>
                            <p className={styles.noteText}>{note}</p>
                        </div>
                        }
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant="secondary"
                            size="md"
                            onClick={closeModal}
                            isFull={true}
                        >
                            {t(`applications.bulkAction.${action}.cancel`)}
                        </Button>
                        <Button
                            variant="primaryFilled"
                            size="md"
                            isFull={true}
                            onClick={confirm}
                        >
                            {t(`applications.bulkAction.${action}.primaryCTA`)}
                        </Button>
                    </div>
                </div>
            }
        </Modal>
    );
}

export {
    BulkActionGeneralConfirmationModal,
}