import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout"
import { FunctionComponent } from "react"
import { useCSVImportPage } from "./hook";
import { Cn } from "@helpers/unsorted/classNames";
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader";
import { Button } from "@shared/unsorted/Button/Button";
import { useTranslation } from "react-i18next";
import { CSVImportForm } from "./CSVImportForm/CSVImportForm";

const styles = {
    title: Cn.c("px-6 pt-6 font-h2-bold text-emphasized"),
}

const CSVImportPage: FunctionComponent = () => {
    const { t } = useTranslation()

    const { switchToManuallyAdding, switchToTop } = useCSVImportPage();

    return (
        <DefaultLayout>
            <PageHeader
                action={[
                    <Button
                        key={1}
                        size="sm"
                        variant="primaryOutline"
                        onClick={switchToManuallyAdding}
                    >
                        {t('applications.csvImport.switchToManualInput')}
                    </Button>
                ]}
            >
                <Button
                    size="md"
                    variant="ghost"
                    isTrailingIcon={false}
                    iconName="arrowLeftLine"
                    onClick={switchToTop}
                >
                    {t('applications.csvImport.backToCandidateTop')}
                </Button>
            </PageHeader>
            <p className={styles.title}>{t("applications.csvImport.title")}</p>
            <CSVImportForm />
        </DefaultLayout>
    )
}

export {
    CSVImportPage
}