import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        email: string;
        roles: string[];
    }

    export const defaultValues = (): EncoderType => {
        return {
            email: '',
            roles: [],
        };
    };
}

namespace Decoder {
    const idSchema = z.string().uuid();

    export const schema = z.object({
        email: z.string().email(),
        roles: z.array(idSchema).min(1),
    });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
};