import { Candidate, CandidateAdd } from "@routes/candidate";
import { useNavigate } from "react-router-dom";

const useManualAddPage = () => {
    const navigate = useNavigate();

    const switchToCSVImport = () => {
        navigate(CandidateAdd.CSV_IMPORT_PATH_NAME);
    }

    const switchToTop = () => {
        const routes = Candidate.toRoute()
        navigate(`${routes.PATH_NAME}${routes.search}`)
    }

    return {
        switchToCSVImport,
        switchToTop
    }
}

export {
    useManualAddPage
}