import { useTranslation } from 'react-i18next';
import { Mode as EventSettingsModal } from './EventSettingContext'
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { FormEventHandler, useEffect, useState } from 'react';

import * as value from './value';
import { SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { RadioInputOptions } from '@shared/unsorted/RadioInput/RadioInputGroup/RadioInputGroup';
import { SelectionOption } from '@typedefs/selectOption';
import { range } from '@helpers/unsorted/arrayExtra';
import { dateFormat } from '@helpers/unsorted/dateFormat';

import * as boolExtra from "@helpers/unsorted/boolExtra";
import { useMutation } from 'urql';
import { CreateEventDocument, UpdateEventDocument } from '@entities';
import { handleResponse } from '@helpers/unsorted/urqlExtra';
import { useEventSettingContext } from './useEventSettingContext';

interface EventSettingsModalHookType {
    close: VoidFunction;
    form: UseFormReturn<value.Encoder.Type>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    formatOptions: RadioInputOptions[];
    daysBeforeOptions: SelectionOption[];
    cutoffTimeOptions: SelectionOption[];
    setExpirationLinkOptions: RadioInputOptions[];
    expirationLinkOptions: SelectionOption[];
    shouldSetExpirationLink: boolean;
    isSubmitting: boolean;
    mode: EventSettingsModal;
}

const useEventSettingsModal = (): EventSettingsModalHookType => {
    const [mode, setEventSettingModalMode] = useEventSettingContext()

    const event = mode.name === 'edit' ? mode.payload.event : undefined

    const { t } = useTranslation();

    const { error: toastError, success: toastSuccess } = useToast()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const defaultValues = value.Encoder.defaultValues(event)

    const [createEventResponse, createEvent] = useMutation(CreateEventDocument)

    const [updateEventResponse, updateEvent] = useMutation(UpdateEventDocument)

    const form = useForm<value.Encoder.Type>({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const { handleSubmit, reset, control, setValue } = form

    const shouldSetExpirationLink: boolean = boolExtra.fromString(useWatch({
        control,
        name: 'shouldSetExpirationLink'
    }))

    const formatOptions: RadioInputOptions[] = [
        {
            label: 'event.format.offline',
            value: "false",
            disabled: false,
        },
        {
            label: 'event.format.online',
            value: "true",
            disabled: false,
        },
    ]

    const daysBeforeOptions: SelectionOption[] = range(14, 1).map(day => ({
        key: day.toString(),
        value: day.toString(),
        label: day.toString(),
    }))

    const cutoffTimeOptions: SelectionOption[] = range(24).map(time => ({
        key: time.toString(),
        value: time.toString(),
        label: dateFormat.simpleTime(new Date(0, 0, 0, time, 0, 0))
    }))

    const setExpirationLinkOptions: RadioInputOptions[] = [
        {
            label: 'event.setting.setExpirationLink.yes',
            value: "true",
            disabled: false,
        },
        {
            label: 'event.setting.setExpirationLink.no',
            value: "false",
            disabled: false,
        },
    ]

    const expirationLinkOptions: SelectionOption[] = range(30, 1).map(day => ({
        key: day.toString(),
        value: day.toString(),
        label: t('event.setting.expirationLinkDay', { count: day }),
    }))

    const close = () => {
        reset()
        setEventSettingModalMode({ name: 'hide' })
    }

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            if (mode.name === 'create') {
                createEvent({
                    ...validValues,
                    recruitmentStepId: mode.payload.recruitmentStepId
                })
            }

            if (mode.name === 'edit') {
                updateEvent({
                    ...validValues,
                    id: mode.payload.event.id
                })
            }
        } catch (error) {
            toastError('global.error')
        }
    }

    useEffect(() => {
        handleResponse(createEventResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('event.setting.createSuccessMessage')
                close()
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('event.setting.createFailedMessage')
            }
        })
    }, [createEventResponse])

    useEffect(() => {
        handleResponse(updateEventResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('event.setting.updateSuccessMessage')
                close()
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('event.setting.updateFailedMessage')
            }
        })
    }, [updateEventResponse])

    useEffect(() => {
        if (!shouldSetExpirationLink) {
            form.clearErrors('daysUntilLinkInvalid')
            setValue('daysUntilLinkInvalid', '')
        }
    }, [shouldSetExpirationLink])

    useEffect(() => {
        reset(defaultValues)
    }, [event])

    return {
        close,
        form,
        onSubmit: handleSubmit(onSubmit),
        isSubmitting,
        formatOptions,
        daysBeforeOptions,
        cutoffTimeOptions,
        setExpirationLinkOptions,
        expirationLinkOptions,
        shouldSetExpirationLink,
        mode,
    }
}

export {
    useEventSettingsModal
}