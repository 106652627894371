import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType } from '../hook';
import { ActionByStatus } from './ActionByStatus/ActionByStatus';
import { Mode, useMainActions } from './hook';

type Direction = 'upward' | 'downward';

const styles = {
    button: Cn.c('ml-px flex-1'),
    caretIcon: (mode: Mode) => Cn.join([
        Cn.c('ml-2 w-6 h-6'),
        Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180'))
    ]),
    list: (direction: Direction) => Cn.join([
        Cn.c('w-full absolute z-10 bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md'),
        direction === 'upward' ? Cn.c('bottom-16') : Cn.c('right-0 top-12'),
    ]),
    listRow: Cn.c('block cursor-pointer font-paragraph-small-regular px-4 py-2 hover:bg-surface-hovered-default'),
    actionsContent: Cn.c('flex flex-row items-center justify-around'),
};

// @ocaml.doc("Button to display main actions on recruitment process
//     - `direction`[#upwards | #downwards] - absolute positioning of the list, whether to open upwards or downwards
// ")
interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    onAction: (otherAction: ActionType) => void;
    direction?: Direction;
}

const MainActions: FunctionComponent<Props> = ({
    application,
    onAction,
    direction = 'downward',
}) => {
    const { t } = useTranslation();

    const {
        currentStepStatusActions: { mainActions },
    } = application;

    const filteredMainActions = mainActions.filter(action => action !== 'ARCHIVE');

    const isFinalizeAction = useMemo(() => {
        const finalActions = ['FINALIZE_PASS', 'FINALIZE_FAIL'] as const;

        return finalActions.every(action => filteredMainActions.includes(action));
    }, [filteredMainActions]);

    const {
        actionListDisplayMode,
        toggleMainActionList,
        mainActionsRef,
        onClickMainAction,
    } = useMainActions(onAction);

    return (
        filteredMainActions.length > 1
            ? <div ref={mainActionsRef}>
                <Button
                    size="md"
                    onClick={toggleMainActionList}
                    className={styles.button}
                >
                    <div className={styles.actionsContent}>
                        {isFinalizeAction
                            ? t("applications.panel.recruitmentStepFinalizeDecision")
                            : t("applications.panel.recruitmentStepMainActions")
                        }
                        <Icon name="caret" className={styles.caretIcon(actionListDisplayMode)} />
                    </div>
                </Button>
                {actionListDisplayMode === "show" &&
                    <div className={styles.list(direction)}>
                        {filteredMainActions.map((mainAction, index) =>
                            <div
                                className={styles.listRow}
                                key={index}
                                onClick={() => onClickMainAction(mainAction)}
                            >
                                {t(`recruitmentStepStatusAction.${mainAction}`)}
                            </div>
                        )}
                    </div>
                }
            </div>
            : <>
                {filteredMainActions.length === 0
                    ? <ActionByStatus application={application} />
                    : <Button
                        size="md"
                        onClick={() => onClickMainAction(filteredMainActions[0])}
                    >
                        {t(`recruitmentStepStatusAction.${filteredMainActions[0]}`)}
                    </Button>
                }
            </>
    )
};

export {
    MainActions
};

