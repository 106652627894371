import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    rowHeader: Cn.c('flex py-0 px-6 bg-surface-emphasized font-paragraph-xsmall-semi-bold text-subdued'),
    baseCellStyle: Cn.c('flex items-center px-3 py-4'),
    cell: (columnNumber: number) => Cn.join([
        styles.baseCellStyle,
        Cn.ifTrue(columnNumber === 1, Cn.c('justify-start flex-[1.2]')),
        Cn.ifTrue(columnNumber === 2, Cn.c('justify-start flex-[0.8]')),
        Cn.ifTrue(columnNumber >= 3, Cn.c('justify-center w-[176px]')),
    ]),
    tooltipContainer: Cn.c('flex gap-x-1'),
    questionMark: Cn.c('text-icons-subdued w-3.5 h-3.5'),
}

const RecruitmentStageHeader: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.rowHeader}>
            <div className={styles.cell(1)}>{t("jobPositions.panel.recruitmentFlow.table.stepName.name")}</div>
            <div className={styles.cell(2)}>{t("jobPositions.panel.recruitmentFlow.table.stepType.name")}</div>
            <div className={styles.cell(3)}>
                <div className={styles.tooltipContainer}>
                    <span> {t("jobPositions.panel.recruitmentFlow.table.notification.name")}</span>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.panel.recruitmentFlow.table.notification.tooltipText")}
                        placement="top">
                        <div>
                            <Icon name="questionMark" className={styles.questionMark} />
                        </div>
                    </ToolTipBox>
                </div>
            </div>
            <div className={styles.cell(4)}>
                <div className={styles.tooltipContainer}>
                    <span> {t("jobPositions.panel.recruitmentFlow.table.evaluation.name")}</span>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.panel.recruitmentFlow.table.evaluation.tooltipText")}
                        placement="top">
                        <div>
                            <Icon name="questionMark" className={styles.questionMark} />
                        </div>
                    </ToolTipBox>
                </div>
            </div>
            <div className={styles.cell(5)}>
                <div className={styles.tooltipContainer}>
                    <span> {t("jobPositions.panel.recruitmentFlow.table.decision.name")}</span>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.panel.recruitmentFlow.table.decision.tooltipText")}
                        placement="top">
                        <div>
                            <Icon name="questionMark" className={styles.questionMark} />
                        </div>
                    </ToolTipBox>
                </div>
            </div>
        </div>
    );
};


export {
    RecruitmentStageHeader
};
