import { FunctionComponent } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '../Icon/Icon';
import { usePagination } from './hook';

const styles = {
    wrapper: Cn.c("flex space-x-2 justify-center items-center cursor-pointer"),
    arrow: (isDisabled: boolean) => Cn.join([
        Cn.c("w-4 h-4"),
        isDisabled ? Cn.c("text-icons-disabled") : Cn.c("text-icons-emphasized")
    ]),
    circle: (isCurrent: boolean) => Cn.join([
        Cn.c(
            "font-paragraph-xsmall-medium w-8 h-8 rounded-full flex justify-center items-center",
        ),
        isCurrent
            ? Cn.c("bg-actions-primary-default text-on-primary")
            : Cn.c("border border-default bg-surface-default text-emphasized hover:bg-surface-hovered-default"),
    ]),
};

interface Props {
    pageCount: number;
    currentPage: number;
    onSelectPage: (page: number) => void;
}

const Pagination: FunctionComponent<Props> = ({ pageCount, currentPage, onSelectPage }) => {
    const {
        disableLoadingPreviousSequence,
        loadPreviousSequence,
        disableLoadingNextSequence,
        loadNextSequence,
        disableToPreviousPage,
        loadPreviousPage,
        disableToNextPage,
        loadNextPage,
        pageSequence
    } = usePagination(pageCount, currentPage, onSelectPage);

    return (
        <div className={styles.wrapper}>
            {!disableLoadingPreviousSequence && <Icon
                name="arrowDoubleLeft"
                onClick={() => { !disableLoadingPreviousSequence && loadPreviousSequence() }}
                className={styles.arrow(disableLoadingPreviousSequence)} />}
            {pageSequence.length > 0 && <Icon
                name="arrowLeft"
                onClick={() => { !disableToPreviousPage && loadPreviousPage() }}
                className={styles.arrow(disableToPreviousPage)}
            />}
            {
                pageSequence.map((page) => (
                    <div
                        className={styles.circle(page === currentPage)}
                        key={page}
                        onClick={() => onSelectPage(page)}
                    >
                        <p>{page}</p>
                    </div>
                ))
            }
            {pageSequence.length > 0 && <Icon
                name="arrowRight"
                onClick={() => { !disableToNextPage && loadNextPage() }}
                className={styles.arrow(disableToNextPage)} />}
            {!disableLoadingNextSequence && <Icon
                name="arrowDoubleRight"
                onClick={() => { !disableLoadingNextSequence && loadNextSequence() }}
                className={styles.arrow(disableLoadingNextSequence)} />}
        </div>
    );
};

export {
    Pagination,
};
