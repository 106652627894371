import { FunctionComponent } from 'react';

import { isNonNull } from '@helpers/core/typeGuards';
import { useOpenFolder } from './openFolderHook';

type OpenFolderProps = ReturnType<typeof useOpenFolder>['openFolderProps'];
interface Props {
    accept?: string;
    multiple?: boolean;
    disabled?: boolean;
    openFolderProps: OpenFolderProps;
}

// @ocaml.doc("You need to mount this component in your DOM so that it can be used to handle the open folder and
// files state logic. The component _must_ receive the `openFolderProps` prop
// that's returned by the `OpenFolderHook.use` function.
//
// The component accepts some props you'd expect from a simple `input` with type `file`:
//
// ```
// <OpenFolderHook.Input openFolderProps accept=\"image/*\" disabled multiple=true />
// ```")
const OpenFolderInput: FunctionComponent<Props> = ({ accept, multiple, disabled, openFolderProps: { ref, setFiles } }) => {
    return (
        <input
            onChange={event => {
                const selectedFiles = event.target.files;

                // Because of how Firefox handles the FileList object contained in file inputs
                // We need to force create a new reference to force the UI update
                // https://github.com/facebook/react/issues/18104
                setFiles(() => isNonNull(selectedFiles) ? Array.from(selectedFiles) : undefined);
            }}
            ref={ref}
            type="file"
            accept={accept}
            disabled={disabled}
            multiple={multiple}
            style={{ display: 'none' }}
        />
    );
};

export {
    OpenFolderInput,
};
