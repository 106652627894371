import { AddEventSessionGroupDocument, GetAllRegisteredClients_AllRegisteredClientsDocument, GetAllRegisteredClients_AllRegisteredClientsQuery, ValidateSessionLocationDocument } from "@entities"
import { useToast } from "@helpers/hooks/unsorted/toastHook"
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra"
import { AddGroupSettings, Error as LocationValidationError, Mode, useEventSessionUniqueLocationWarningModal } from "@shared/event/EventSessionUniqueLocationWarningModal/useEventSessionUniqueLocationWarningModal"
import { Entity } from "@typedefs/graphql"
import { SelectionOption } from "@typedefs/selectOption"
import { FormEventHandler, useEffect, useMemo, useState } from "react"
import { SubmitHandler, UseFormReturn, useForm, useWatch } from "react-hook-form"
import { useClient, useMutation, useQuery } from "urql"

import { useCurrentLanguage } from "@helpers/core/i18n"
import { hasValue, isDefined } from "@helpers/core/typeGuards"
import { mergeTuple, uniqueLocationBetweenEvents, uniqueLocationBetweenGroup, uniqueLocationBetweenSessions } from "@shared/event/EventSessionUniqueLocationWarningModal/validations"
import { getEventSessionName } from "@shared/event/helpers"
import { useTranslation } from "react-i18next"
import * as value from './value'
import { useEventSessionDetailContext } from "@pages/EventSessionDetailPage/EventSessionDetailContext"

interface EventSessionGroupAddModalHookType {
    isLoading: boolean;
    mode: Mode;
    onSubmit: FormEventHandler<HTMLFormElement>;
    onFinalSubmit: VoidFunction;
    onCloseWarningModal: VoidFunction;
    clientOptions: SelectionOption[];
    close: VoidFunction;
    form: UseFormReturn<value.Encoder.Type>;
    isSubmitting: boolean;
}

const useEventSessionGroupAddModal = (closeModal: VoidFunction): EventSessionGroupAddModalHookType => {
    const { t } = useTranslation()
    const currentLanguage = useCurrentLanguage()

    const { event, eventSession } = useEventSessionDetailContext()

    const [clients, setClients] = useState<Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[]>([])

    const [isLoading, setIsLoading] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const urqlClient = useClient()

    const [clientsResponse] = useQuery({ query: GetAllRegisteredClients_AllRegisteredClientsDocument })

    const [addEventSessionGroupResponse, addEventSessionGroup] = useMutation(AddEventSessionGroupDocument)

    const { error: toastError, success: toastSuccess } = useToast()

    const { mode, backToInit, showWarning } = useEventSessionUniqueLocationWarningModal()

    const defaultValues = value.Encoder.defaultValues(event.isOnline)

    const form = useForm<value.Encoder.Type>({
        defaultValues: defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const {
        reset,
        control,
        handleSubmit,
    } = form

    const close = () => {
        backToInit();

        closeModal()
        reset(defaultValues)
    }

    const clientOptions = useMemo(() => {
        return clients.reduce(
            (options: SelectionOption[], client) => {
                switch (client.__typename) {
                    case 'InvitedClient':
                        return options
                    case 'RegisteredClient':
                        return options.concat([
                            {
                                key: client.id,
                                label: client.fullName,
                                value: client.id,
                            },
                        ])
                    default:
                        return options
                }
            },
            [],
        )
    }, [clients])

    const asyncValidateEventSessionLocation = async (value: string) => {
        const result = await urqlClient.query(ValidateSessionLocationDocument, {
            location: value,
            isOnline: event.isOnline
        }, {
            requestPolicy: 'network-only'
        }).toPromise()

        return result.data?.validateEventSessionLocation
    }

    const formGroups = useWatch({
        control,
        name: 'groups'
    })

    const existingLocation = event.sessions
        .filter(session => session.id === eventSession.id)
        .flatMap(session => session.groups.map(({ location }) => location)) || []

    const formLocations = formGroups.map(({ location }) => location).concat(existingLocation)

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            const validationResult = await Promise.all(validValues.groups.map(async (group, index) => {
                const isUniqueLocationBetweenSessions = uniqueLocationBetweenSessions({
                    value: group.location,
                    event: event,
                    currentLanguage,
                });

                const isUniqueLocationBetweenGroups = uniqueLocationBetweenGroup({
                    locations: formLocations,
                    position: index,
                    value: group.location,
                });

                const isUniqueLocationBetweenEvents = await uniqueLocationBetweenEvents({
                    asyncValidateEventSessionLocation,
                    event: event,
                    value: group.location,
                })

                return {
                    isUniqueLocationBetweenSessions,
                    isUniqueLocationBetweenGroups,
                    isUniqueLocationBetweenEvents,
                };
            }));

            const errorReports = {
                isUniqueLocationBetweenSessions: {
                    name: 'isUniqueLocationBetweenSessions' as const,
                    payload: validationResult
                        .map(({ isUniqueLocationBetweenSessions }, index) => {
                            if (isUniqueLocationBetweenSessions) {
                                return {
                                    group: index + 1,
                                    message: `${isUniqueLocationBetweenSessions.sessionName} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenSessions.groupNo })})`
                                }
                            }
                        }).filter(isDefined)
                },
                isUniqueLocationBetweenEvents: {
                    name: 'isUniqueLocationBetweenEvents' as const,
                    payload: validationResult
                        .map(({ isUniqueLocationBetweenEvents }, index) => {
                            if (isUniqueLocationBetweenEvents) {
                                return {
                                    group: index + 1,
                                    message: `${isUniqueLocationBetweenEvents.eventName} - ${getEventSessionName(isUniqueLocationBetweenEvents.session, currentLanguage)} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenEvents.groupNo })})`,
                                }
                            }
                        }).filter(isDefined)
                },
                isUniqueLocationBetweenGroups: {
                    name: 'isUniqueLocationBetweenGroups' as const,
                    payload: {
                        duplicateGroups: mergeTuple(
                            validationResult
                                .map(({ isUniqueLocationBetweenGroups }) => isUniqueLocationBetweenGroups)
                                .filter(isDefined))
                    }
                }
            }

            let validationError: LocationValidationError | undefined = undefined

            if (errorReports.isUniqueLocationBetweenSessions.payload.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenSessions
            } else if (errorReports.isUniqueLocationBetweenGroups.payload.duplicateGroups.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenGroups
            } else if (errorReports.isUniqueLocationBetweenEvents.payload.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenEvents
            }

            if (validationError) {
                showWarning(validationError, {
                    groupCount: validValues.groups.length,
                    groups: validValues.groups,
                })
            } else {
                addEventSessionGroup({
                    sessionId: eventSession.id,
                    location: validValues.groups[0].location,
                    locationType: validValues.groups[0].locationType,
                    clientIds: validValues.groups[0].clientIds
                }, getQueryContext('EventSession'))
            }
        } catch (error) {
            toastError('global.error')
        }
    }

    const onFinalSubmit = () => {
        try {
            if (mode.name === 'warn') {
                const settings = mode.payload.currentSetting as AddGroupSettings;

                addEventSessionGroup({
                    sessionId: eventSession.id,
                    location: settings.groups[0].location,
                    locationType: settings.groups[0].locationType,
                    clientIds: settings.groups[0].clientIds
                }, getQueryContext('EventSession'))

                close()
            }
        } catch {
            toastError('global.error');
        }
    };

    useEffect(() => {
        reset(defaultValues)
    }, [event])

    useEffect(() => {
        handleResponse(clientsResponse, {
            onFetching: () => {
                setIsLoading(true)
            },
            onData: (data) => {
                setIsLoading(false)
                setClients(data.clients.edges)
            },
            onError: () => {
                setIsLoading(false)
                toastError('global.error')
                setClients([])
            }
        })
    }, [clientsResponse])

    useEffect(() => {
        handleResponse(addEventSessionGroupResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('event.addSessionGroup.createSuccessfully')
                close()
            },
            onError: () => {
                toastError('event.addSessionGroup.createFailed')
                close()
            }
        })
    }, [addEventSessionGroupResponse])

    return {
        clientOptions,
        isLoading,
        onSubmit: handleSubmit(onSubmit),
        onFinalSubmit,
        mode,
        onCloseWarningModal: backToInit,
        close,
        form,
        isSubmitting,
    }
}

export {
    useEventSessionGroupAddModal
}
