import { useApplicationSelectionContext } from '@shared/application/bulkActions/ApplicationSelectionContext';
import { BulkActionErrorModalPayload } from '../reducer';
import { useBulkActionExecution } from '@shared/application/useBulkActionExecution';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { splitToChunks } from '@helpers/unsorted/arrayExtra';
import { BULK_ACTION_ID_SIZE } from '../ApplicationsTable/BulkActionMenu/hook';
import { isDefined } from '@helpers/core/typeGuards';
import { useBulkActionModalContext } from '../BulkActionModalContext';
import { SelectedApplications } from '@shared/application/bulkActions/helpers';

interface BulkActionErrorModeHookType {
    retry: VoidFunction;
}

const useBulkActionErrorModal = ({
    closeModal,
    errorPayload,
}: {
    closeModal: VoidFunction,
    errorPayload?: BulkActionErrorModalPayload,
}): BulkActionErrorModeHookType => {
    const { success: toastSuccess } = useToast();

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const { setApplicationSelection, applicationSelection } = useApplicationSelectionContext();

    const action = errorPayload?.action
    const failedApplications = errorPayload?.failedIds || []
    const bulkActionPayload = errorPayload?.bulkActionPayload

    const payload = isDefined(action) ? {
        action,
        applicationIdBatches: splitToChunks(failedApplications, BULK_ACTION_ID_SIZE),
        bulkActionPayload,
    } : undefined

    const { execute } = useBulkActionExecution({
        action: errorPayload?.action,
        payload,
        onStartExecution: () => {
            if (action) {
                dispatchBulkActionModalMode({
                    name: 'showBulkActionLoadingModal',
                    payload: {
                        action,
                        total: failedApplications.length,
                        processed: 0
                    }
                })
            }
        },
        onCompleteExecutingBatch: (result) => {
            if (action) {
                dispatchBulkActionModalMode({
                    name: 'showBulkActionLoadingModal',
                    payload: {
                        action,
                        total: failedApplications.length,
                        processed: result.totalProcessed
                    }
                })
            }
        },
        onCompleteExecution: (result) => {
            if (action) {
                if (result.detail.success === failedApplications.length) {
                    closeModal();
                    toastSuccess('applications.bulkAction.success');
                    setApplicationSelection({});
                }

                if (result.detail.failed > 0) {
                    const originalSelectedApplications = applicationSelection[action]

                    if (originalSelectedApplications) {
                        setApplicationSelection({
                            ...applicationSelection,
                            [action]: result.detail.failedIds.reduce((acc: SelectedApplications, id) => {
                                acc[id] = originalSelectedApplications[id]

                                return acc
                            }, {})
                        })

                        // Need to call onError again if there are still failed applications
                        dispatchBulkActionModalMode({
                            name: 'showBulkActionErrorModal',
                            payload: {
                                action,
                                failedIds: result.detail.failedIds,
                                success: result.detail.success,
                                bulkActionPayload: errorPayload.bulkActionPayload,
                            }
                        })
                    }
                }
            }

        },
    });

    return {
        retry: execute
    };
};

export {
    useBulkActionErrorModal,
}