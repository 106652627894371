import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Input } from '@shared/unsorted/Input/Input';
import { Loader } from '@shared/unsorted/Loader/Loader';
import { TextArea } from '@shared/unsorted/TextArea/TextArea';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RecruitmentStageCreateForm } from './RecruitmentStageCreateForm/RecruitmentStageCreateForm';
import { useJobPositionCreatePage } from './hook';
import { WarningModal } from './WarningModal';
import { useModal } from '@helpers/hooks/unsorted/useModal';

const styles = {
    content: Cn.c('pt-5 pb-6 bg-surface-default h-full flex flex-col flex-1 overflow-auto'),
    header: Cn.c('flex justify-between items-center pb-5 px-6'),
    modalTitle: Cn.c("font-paragraph-base-medium text-emphasized"),
    buttons: Cn.c('flex space-x-4'),
    inputContainer: Cn.c("w-4/5"),
    jobName: Cn.c('font-h1-bold border-0 pl-0 mb-5'),
    body: Cn.c('border-t border-default pt-8 px-32 flex flex-col flex-1 overflow-auto'),
    title: Cn.c("pb-6"),
    headerContainer: Cn.c('flex flex-col space-y-2 pb-6'),
    headerTitle: Cn.c('font-h2-bold text-emphasized'),
    headerDescription: Cn.c('font-paragraph-base-regular text-subdued'),
    form: Cn.c('flex flex-col flex-1 overflow-auto'),
    textArea: Cn.c('h-24'),
};

const JobPositionCreatePage = () => {
    const { t } = useTranslation();

    const { closeModal: closeEditModal } = useModal()

    const {
        form,
        onSubmit,
        state,
        isSubmitting,
        currentTitle,
        showWarningModal,
        closeWarningModal,
        onCancel,
    } = useJobPositionCreatePage();

    const { clearErrors, control, formState } = form;

    return (
        <>
            {state.loading
                ? <Loader />
                : <div className={styles.content}>
                    <form onSubmit={onSubmit} className={styles.form}>
                        <div className={styles.header}>
                            <div className={styles.modalTitle}>{t('jobPositionCreate.breadcrumbs.title')}</div>
                            <div className={styles.buttons}>
                                <Button
                                    size="md"
                                    variant="secondary"
                                    onClick={onCancel}
                                    isDisabled={isSubmitting}
                                >
                                    {t("global.cancel")}
                                </Button>
                                <Button
                                    size="md"
                                    variant="primaryFilled"
                                    type="submit"
                                    isDisabled={!formState.isDirty || currentTitle === ""}
                                    isLoading={isSubmitting}
                                >
                                    {t("global.save")}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.headerContainer}>
                                <div className={styles.headerTitle}>{t("jobPositionCreate.label.overview")}</div>
                                <div className={styles.headerDescription}>{t("jobPositionCreate.label.overviewDescription")}</div>
                            </div>
                            <Controller
                                name='title'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <Input
                                        label='jobPositionCreate.label.title'
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errors={formState.errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        value={value}
                                        required
                                        placeholder={'jobPositionCreate.placeholder.jobPositionName'}
                                        className={styles.title}
                                        size='x-large'
                                    />
                                )}
                            />
                            <div className={styles.headerContainer}>
                                <Controller
                                    name="documentRequirements"
                                    control={control}
                                    render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                        <TextArea
                                            label='jobPositionCreate.label.requiredDocs'
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            clearErrors={clearErrors}
                                            forwardedRef={ref}
                                            errors={formState.errors}
                                            value={value}
                                            textAreaClassName={styles.textArea}
                                            placeholder="jobPositions.shared.placeholder.documentRequirement"
                                            orientation='vertical'
                                        />}
                                />
                            </div>
                            <div className={styles.headerContainer}>
                                <div className={styles.headerTitle}>{t("jobPositionCreate.label.flow")}</div>
                                <div className={styles.headerDescription}>{t("jobPositionCreate.label.flowDescription")}</div>
                            </div>
                            <RecruitmentStageCreateForm form={form} recruitmentStepTypes={state.recruitmentStepTypes} />
                        </div>
                    </form>
                </div>
            }
            <WarningModal
                isOpen={showWarningModal}
                closeModal={closeWarningModal}
                onProceed={closeEditModal}
            />
        </>
    );
};

export {
    JobPositionCreatePage
};

