import { RefObject, useRef, useState } from 'react';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { ActionType } from '../hook';

type Mode = 'show' | 'hide';

interface MainActionsHookResult {
    actionListDisplayMode: Mode;
    mainActionsRef: RefObject<HTMLDivElement>;
    toggleMainActionList: VoidFunction;
    onClickMainAction: (
        mainAction: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.mainActions'>[number],
    ) => void;
}

const useMainActions = (onAction: (otherAction: ActionType) => void): MainActionsHookResult => {
    const [actionListDisplayMode, setActionListDisplayMode] = useState<Mode>('hide');

    const mainActionsRef = useRef<HTMLDivElement>(null);

    useClickOutside([mainActionsRef], () => setActionListDisplayMode('hide')); // TODO: [CHECK] Verify if this is still necessary

    const toggleMainActionList = () => actionListDisplayMode === 'hide' ? setActionListDisplayMode('show') : setActionListDisplayMode('hide');

    const onClickMainAction = (
        mainAction: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.mainActions'>[number],
    ) => {
        onAction(mainAction);
        setActionListDisplayMode('hide');
    };

    return {
        actionListDisplayMode,
        mainActionsRef,
        toggleMainActionList,
        onClickMainAction,
    };
};

export {
    Mode,
    useMainActions,
};
