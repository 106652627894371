import { EventSessionsQuery } from "@entities"
import { Cn } from "@helpers/unsorted/classNames"
import { ExternalLink } from "@shared/unsorted/ExternalLink/ExternalLink"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const styles = {
    groupName: Cn.c("font-paragraph-xsmall-medium text-emphasized"),
    groupDetailContainer: Cn.c("flex space-x-2"),
    groupDetailItem: Cn.c("flex items-center space-x-1 pt-2 overflow-auto"),
    detailIcon: Cn.c("w-3.5 h-3.5 text-icons-subdued"),
    detailText: Cn.c("font-paragraph-xsmall-regular text-subdued overflow-hidden"),
    link: Cn.c("text-primary-default"),
    location: Cn.c("truncate")
}

interface Props {
    isOnline: boolean
    eventSessionGroup: Entity<EventSessionsQuery, 'eventSessions.groups'>
}

const EventSessionGroupDetail: FunctionComponent<Props> = ({
    isOnline,
    eventSessionGroup
}) => {
    const { t } = useTranslation()
    return (
        <div>
            <p className={styles.groupName}>
                {
                    t('event.eventSession.eventSessionGroup.name',
                        { count: eventSessionGroup.order + 1, ordinal: true })
                }
            </p>
            <div className={styles.groupDetailContainer}>
                <span className={Cn.join([styles.groupDetailItem, Cn.c("shrink-0")])}>
                    <Icon name="candidates" className={styles.detailIcon} />
                    <p className={styles.detailText}>{
                        t('dashboard.upcomingEvents.participant', { count: eventSessionGroup.recruitmentProcessAssignments.length })
                    }</p>
                </span>
                <span className={styles.groupDetailItem}>
                    <Icon name="location" className={styles.detailIcon} />
                    <div className={styles.detailText}>{
                        isOnline
                            ? <ExternalLink
                                href={eventSessionGroup.location}
                                className={styles.link}
                            >
                                {eventSessionGroup.location}
                            </ExternalLink>
                            : <p className={styles.location}>{eventSessionGroup.location}</p>
                    }</div>
                </span>
            </div>
        </div>
    )
}

export {
    EventSessionGroupDetail
}