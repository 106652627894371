import { useReducer, useCallback, useEffect, useRef, ChangeEvent } from 'react';
import { isDefined } from '@helpers/core/typeGuards';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { useDebounce } from '@helpers/hooks/unsorted/debounceHook';
import { UseFormReturn } from 'react-hook-form';
import { typeAheadReducer } from './reducer';

const useTypeAhead = (
    name: string,
    form: UseFormReturn<any, any>, // TODO: [IMPLEMENT] Decide on what will be the type for this form from rescript-hook-form
    onInputChange?: (value?: string) => void,
    inputValue?: string,
) => {
    const typeAheadRef = useRef<HTMLDivElement>(null);

    const { setValue, getValues } = form;

    const [state, dispatch] = useReducer(typeAheadReducer, {
        nameValue: isDefined(inputValue) ? inputValue : "",
        mode: { type: 'show' },
    });

    useClickOutside([typeAheadRef], () => {
        dispatch({ type: 'BlurInput' });
    })

    useDebounce((state.mode.type === 'edit' ? state.mode.value : ""), onInputChange, 500);

    const onFocus = useCallback(() => {
        dispatch({ type: 'FocusInput' });
    }, [dispatch]);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget

        dispatch({ type: 'EditNameValue', updatedValue: value });
    }, [dispatch]);

    const onClick = useCallback((value: string, label: string) => {
        setValue(name, value);
        dispatch({ type: 'SetNewNameValue', newNameValue: label });
    }, [dispatch]);

    const value = getValues(name);

    useEffect(() => {
        isDefined(value) && value.trim().length === 0 && dispatch({ type: 'SetNewNameValue', newNameValue: '' });
    }, [value])


    return {
        state,
        onChange,
        onClick,
        onFocus,
        typeAheadRef
    };
}

export {
    useTypeAhead,
}