import { Cn } from "@helpers/unsorted/classNames"
import { Button } from "@shared/unsorted/Button/Button"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Modal } from "@shared/unsorted/Modal/Modal"
import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { BulkEventSessionAssignmentOptionModalPayload } from "../reducer"
import { useBulkEventSessionAssignmentOptionModal } from "./hook"

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-warning-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-warning-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-warning-default"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    buttons: Cn.c("mt-6 px-6 flex flex-col space-y-3")
}

interface Props extends ModalProps {
    closeModal: VoidFunction
    payload?: BulkEventSessionAssignmentOptionModalPayload
}

const BulkEventSessionAssignmentOptionModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    payload
}) => {
    const { t } = useTranslation()

    const {
        proceedAllApplications,
        proceedUnassignedApplicationsOnly
    } = useBulkEventSessionAssignmentOptionModal(payload?.recruitmentStepId || '')

    const unassignedApplicationCount = payload ? payload.totalApplications - payload.numOfApplicationsAssignedToSession : 0

    return (
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t('applications.bulkEventSessionAssignmentOption.title',
                        { count: payload?.numOfApplicationsAssignedToSession || 0 }
                    )}</p>
                    <p className={styles.subDescription}>{t('applications.bulkEventSessionAssignmentOption.description')}</p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        onClick={proceedAllApplications}
                    >
                        {t('applications.bulkEventSessionAssignmentOption.proceedAll')}
                    </Button>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={proceedUnassignedApplicationsOnly}
                        isDisabled={unassignedApplicationCount === 0}
                    >
                        {t('applications.bulkEventSessionAssignmentOption.proceedUnassigned',
                            { count: unassignedApplicationCount }
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {
    BulkEventSessionAssignmentOptionModal
}