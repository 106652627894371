import { isDefined } from '@helpers/core/typeGuards';
import { FormProps, ForwardedRefProps } from '@typedefs/props';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';
import { Label } from '../Label/Label';
import { Cn } from '@helpers/unsorted/classNames';
import { Size } from '../Input/Input';
import { Mode, useTimePicker } from './hook';
import { TooltipPlacement } from '../ToolTipBox/ToolTipBox';
import { PseudoInput } from '../MultiSelect/PseudoInput/PseudoInput';
import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';

const selectDirection = ['upward', 'downward'] as const;
const optionState = ['selected', 'default'] as const;

type Direction = typeof selectDirection[number];
type OptionState = typeof optionState[number];

const styles = {
    root: Cn.c('w-full'),
    inputLabel: (size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c('font-paragraph-xsmall-medium')
                break;
            case 'medium':
                sizeStyles = Cn.c('font-paragraph-small-medium')
                break;
            case 'large':
            case 'x-large':
                sizeStyles = Cn.c('font-paragraph-base-medium')
                break;
        }

        return Cn.join([
            Cn.c('mb-1 text-emphasized'),
            sizeStyles,
        ])
    },
    selectWrapper: (hasLabel: boolean, direction: Direction) => Cn.join([
        Cn.c('flex relative'),
        Cn.ifFalse(hasLabel, Cn.c('w-full')),
        direction === 'upward' ? Cn.c('flex-col-reverse') : Cn.c('flex-col')
    ]),
    input: Cn.c('select-none'),
    caretIcon: (mode: Mode, size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c('right-4 top-2 w-4 h-4')
                break;
            case 'medium':
                sizeStyles = Cn.c('right-4 top-3 w-4 h-4')
                break;
            case 'large':
                sizeStyles = Cn.c('right-4 top-3 w-6 h-6')
                break;
            case 'x-large':
                sizeStyles = Cn.c('right-4 top-5 w-6 h-6')
                break;
        }

        return Cn.join([
            Cn.c('absolute'),
            sizeStyles,
            Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180')),
        ]);
    },
    optionsContainer: (direction: Direction, size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = direction === 'upward' ? Cn.c('bottom-10') : Cn.c('top-10')
                break;
            case 'medium':
                sizeStyles = direction === 'upward' ? Cn.c('bottom-12') : Cn.c('top-12')
                break;
            case 'large':
                sizeStyles = direction === 'upward' ? Cn.c('bottom-14') : Cn.c('top-14')
                break;
            case 'x-large':
                sizeStyles = direction === 'upward' ? Cn.c('bottom-[4.5rem]') : Cn.c('top-[4.5rem]')
                break;
        }

        return Cn.join([
            Cn.c('w-full rounded shadow-md bg-surface-default z-[100] absolute'),
            sizeStyles,
        ]);
    },
    timeContainer: Cn.c('flex'),
    timeColumn: Cn.c('flex flex-col flex-1 text-center w-full max-h-48'),
    header: Cn.c('w-full font-paragraph-small-medium text-subdued py-3 border-b border-subdued'),
    options: Cn.c('flex flex-col w-full font-paragraph-small-regular text-emphasized overflow-auto'),
    singleOption: (state: OptionState) => Cn.join([
        Cn.c('cursor-pointer py-3 hover:bg-surface-hovered-default'),
        Cn.ifTrue(state === 'selected', Cn.c('text-primary-default bg-surface-primary-subdued'))
    ]),
    buttons: Cn.c('flex p-3 gap-x-4 border-t border-subdued'),
};

interface Props<FieldsType extends FieldValues>
    extends
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLSelectElement> {
    minuteStep?: number;
    size?: Size;
    required?: boolean;
    disabled?: boolean;
    placeholder?: I18nKey;
    direction?: Direction;
    label?: I18nKey;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    onChange?: (value: string) => void;
    onBlur?: VoidFunction;
    errorLabel?: I18nKey;
    value?: string;
}

const TimePicker = <FieldsType extends FieldValues>({
    name,
    minuteStep = 1,
    size = 'medium',
    required: isRequired = false,
    disabled: isDisabled = false,
    placeholder,
    direction = 'downward',
    label,
    tooltip,
    tooltipPlacement,
    className,
    inputClassName,
    labelClassName,
    onChange,
    onBlur,
    errors,
    clearErrors,        
    errorLabel,
    value,
}: Props<FieldsType>) => {
    const { t } = useTranslation();

    const {
        selectRef,
        toggle,
        mode,
        selectedValueLabel,
        hourOptions,
        minuteOptions,
        selectedHours,
        selectedMinutes,
        onHourSelect,
        onMinuteSelect,
        onConfirmTimeSelection,
        onCancel,
    } = useTimePicker({
        name,
        value,
        onBlur,
        clearErrors,
        onChange,
        minuteStep,
    });

    return (
        <div className={Cn.join([styles.root, Cn.getSome(className)])} ref={selectRef}>
            {isDefined(label) &&
                <Label
                    label={label} 
                    labelClassName={Cn.join([
                        styles.inputLabel(size),
                        Cn.getSome(labelClassName),
                    ])}
                    required={isRequired}
                    tooltip={tooltip}
                    tooltipPlacement={tooltipPlacement} />
            }
            <div className={styles.selectWrapper(isDefined(label), direction)}>
                <PseudoInput
                    pseudoInputClassName={Cn.join([styles.input, Cn.getSome(inputClassName)])}
                    title={selectedValueLabel}
                    onClick={() => { !isDisabled && toggle() }}
                    placeholder={placeholder}
                    name={name}
                    errors={errors}
                    errorLabel={errorLabel}
                    label={label}
                    state={mode === "show" ? "highlighted" : "normal"}
                    disabled={isDisabled}
                    size={size}
                    text={selectedValueLabel}
                />
                <Icon name="arrowDown" className={styles.caretIcon(mode, size)} />
                {mode === "show" &&
                    <div className={styles.optionsContainer(direction, size)}>
                        <div className={styles.timeContainer}>
                            <div className={styles.timeColumn}>
                                <div className={styles.header}>{t("time.hours")}</div>
                                <div className={styles.options}>
                                    {hourOptions.map(({ key, value: hourValue, label }) => {
                                        const state: OptionState = hourValue === selectedHours ? 'selected' : 'default';
                                        
                                        return (
                                            <label
                                                key={key}
                                                className={styles.singleOption(state)}
                                                onClick={() => onHourSelect(hourValue)}
                                            >
                                            {label}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.timeColumn}>
                                <div className={styles.header}>{t("time.minutes")}</div>
                                <div className={styles.options}>
                                    {minuteOptions.map(({ key, value: minuteValue, label }) => {
                                        const state: OptionState = minuteValue === selectedMinutes ? 'selected' : 'default';

                                        return (
                                            <label
                                                key={key}
                                                className={styles.singleOption(state)}
                                                onClick={() => onMinuteSelect(minuteValue)}
                                            >
                                            {label}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={onCancel}
                                isFull
                            >
                                {t("global.cancel")}
                            </Button>
                            <Button
                                variant="primaryOutline"
                                size="sm"
                                onClick={onConfirmTimeSelection}
                                isFull
                            >
                                {t("global.confirm")}
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export {
    TimePicker,
};