import { OpenFolderInput } from "@helpers/hooks/unsorted/rehooks/openFolder/OpenFolderInput";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { EmptyStateContainer } from "@shared/unsorted/EmptyStateContainer/EmptyStateContainer";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "./Row";
import { useDocumentUploadPage } from "./hook";

const styles = {
    root: Cn.c("flex flex-col flex-1 bg-default font-paragraph-small-regular text-default"),
    topBar: Cn.c(
        "flex flex-shrink-0 px-10 items-center justify-center w-full h-12 bg-surface-default shadow-md fixed z-50 text-black",
    ),
    wrapper: Cn.c("pt-28 m-auto w-[36rem]"),
    form: Cn.c("flex flex-col bg-surface-default rounded shadow-base p-6 pb-8"),
    requestTitle: Cn.c("font-h2-bold text-emphasized pt-1 pb-3"),
    candidateName: Cn.c("text-black"),
    jobName: Cn.c("font-h4-medium pt-1 text-subdued"),
    documentRequirements: Cn.c("py-8"),
    candidateDocumentsContainer: Cn.c('space-y-6 min-h-application-panel-tab flex flex-col'),
    candidateDocumentsTop: Cn.c('flex flex-row items-center pb-4 border-b-subdued border-b'),
    title: Cn.c('font-paragraph-base-bold text-default flex-1 text-left'),
    table: Cn.c('w-full flex flex-col flex-grow space-y-6'),
    emptyTable: Cn.c('w-full flex flex-col items-center justify-center flex-grow'),
    documentWrapper: Cn.c('border border-default rounded'),
    downloadText: Cn.c('font-paragraph-small-medium text-primary-default cursor-pointer'),
    topButtons: Cn.c('space-x-2 mr-2 flex flex-row'),
    deleteIcon: (isUploading: boolean) => Cn.ifTrue(isUploading, Cn.c('pointer-events-none')),
    uploadSection: (isUploading: boolean) => Cn.join([
        Cn.c('flex flex-1 flex-col items-center justify-center border-2 border-dashed border-emphasized rounded mb-3 py-14 space-y-4'),
        Cn.ifTrue(isUploading, Cn.c('pointer-events-none')),
    ]),
    uploadInstruction: Cn.c('text-center font-paragraph-xsmall-medium text-subdued'),
    uploadIconContainer: Cn.c('w-10 h-10 bg-surface-dark-subdued flex items-center justify-center rounded-full'),
    uploadIcon: Cn.c('w-6 h-6 text-icons-on-primary'),
}

const DocumentUploadPage: FunctionComponent = () => {
    const {
        state,
        switchToEdit,
        switchToList,
        removeSelectedDocument,
        documentUpload,
        downloadDocument,
        onSubmit,
    } = useDocumentUploadPage();

    const { t } = useTranslation()

    return state.isLoading
        ? <Loader />
        : <div className={styles.root}>
            {
                state.mode.name === 'error'
                    ? <div>error</div>
                    : <>
                        <div className={styles.topBar}> {state.mode.jobPosition.company.companyName} </div>
                        <div className={styles.wrapper}>
                            <div className={styles.form}>
                                <span className={styles.candidateName}> {state.mode.candidate.name} </span>
                                <span className={styles.jobName}> {state.mode.jobPosition.title} </span>
                                <span className={styles.requestTitle}> {t('documentUpload.title')}</span>
                                <span> {t('documentUpload.instruction')} </span>
                                <span className={styles.documentRequirements}> {state.mode.jobPosition.documentRequirements} </span>


                                <div className={styles.candidateDocumentsContainer}>
                                    <div className={styles.candidateDocumentsTop}>
                                        <div className={styles.title}>
                                            {t("applications.panel.candidateDocuments.title")}
                                        </div>
                                        {state.mode.documentState.internalMode === "list"
                                            ? <Button size="sm" variant="secondary" onClick={switchToEdit}>
                                                {t("global.edit")}
                                            </Button>
                                            : <div className={styles.topButtons}>
                                                <Button
                                                    size="sm"
                                                    variant="secondary"
                                                    onClick={switchToList}
                                                    isDisabled={state.isSubmitting}>
                                                    {t("global.cancel")}
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    variant="primaryFilled"
                                                    onClick={onSubmit}
                                                    isLoading={state.isSubmitting}>
                                                    {t("global.save")}
                                                </Button>
                                            </div>
                                        }
                                    </div >
                                    <div className={styles.table}>
                                        {state.mode.documentState.internalMode === "list" ? (
                                            state.mode.candidate.documents.length === 0
                                                ? <div className={styles.emptyTable}>
                                                    <EmptyStateContainer icon="emptyDocument"
                                                        description={"applications.panel.candidateDocuments.empty.description"}
                                                        subDescription={"applications.panel.candidateDocuments.empty.subDescription"} />
                                                </div>
                                                : <div className={styles.documentWrapper}>
                                                    {state.mode.candidate.documents.map(({ filename, id }) =>
                                                        <Row key={id} label={filename} />
                                                    )}
                                                </div>
                                        ) : (
                                            <>
                                                {state.mode.documentState.currentDocuments.length !== 0 &&
                                                    <div className={styles.documentWrapper}>
                                                        {state.mode.documentState.currentDocuments.map(({ payload: { id, filename } }) =>
                                                            <Row key={id} label={filename}>
                                                                <Icon
                                                                    name="delete"
                                                                    className={styles.deleteIcon(state.isSubmitting)}
                                                                    onClick={() => removeSelectedDocument(id)}
                                                                />
                                                            </Row>
                                                        )}
                                                    </div>}
                                                <div className={styles.uploadSection(state.isSubmitting)}>
                                                    <div className={styles.uploadIconContainer}>
                                                        <Icon name="upload" className={styles.uploadIcon} />
                                                    </div>
                                                    <Button size="md" variant="secondary"
                                                        onClick={() => documentUpload.openFolder()}>
                                                        {t("applications.panel.candidateDocuments.uploadSelectButton")}
                                                    </Button>
                                                    <div>
                                                        <div className={styles.uploadInstruction}>
                                                            {t("applications.panel.candidateDocuments.uploadSelectSizeInstruction")}
                                                        </div>
                                                        <div className={styles.uploadInstruction}>
                                                            {t("applications.panel.candidateDocuments.uploadSelectFileInstruction")}
                                                        </div>
                                                    </div>
                                                    <OpenFolderInput
                                                        multiple={true}
                                                        accept=".jpeg,.jpg,.png,.bmp,.pdf,.xlsx,.docx,.pptx"
                                                        openFolderProps={documentUpload.openFolderProps}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div >
                            </div>
                        </div>
                    </>
            }
        </div>
}

export {
    DocumentUploadPage
};
