import * as GroupValue from "@shared/event/event_session/EventSessionGroup/value"
import { z } from "zod";

namespace Encoder {
    export const defaultValues = (isOnline: boolean) => {
        return {
            groups: [GroupValue.Encoder.defaultValues(isOnline, "", [])]
        }
    }

    export type Type = ReturnType<typeof defaultValues>
}

namespace Decoder {
    export const schema = z.object({
        groups: z.array(GroupValue.Decoder.schema)
    }).transform((values, ctx) => {
        const invalidGroup = values.groups.find(
            group => group.location.length === 0
        )

        if (invalidGroup) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid group",
            });

            return z.NEVER;
        }

        return {
            groups: values.groups,
        }
    })
}

export {
    Encoder,
    Decoder,
}