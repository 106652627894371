import { Cn } from '@helpers/unsorted/classNames';
import { ChangeEventHandler, FunctionComponent } from 'react';

const toggleSwitchSizes = ['sm', 'lg'] as const;

type Size = typeof toggleSwitchSizes[number];

// TODO: [CHECK] In Zeplin, disabled state style is defined.
// However in current usage (job position recruitment stage edit mode), disabled state style is same as enabled state style not clickable.
const styles = {
    switch: (size: Size) => Cn.join([
        Cn.c('toggle'),
        Cn.ifTrue(size === 'lg', Cn.c('toggle-lg')),
    ]),
    label: (size: Size) => Cn.join([
        Cn.c('toggle-btn cursor-pointer'),
        Cn.ifTrue(size === 'lg', Cn.c('toggle-btn-lg')),
    ]),
};

interface Props {
    /** The id of the input. */
    id: string;
    /** The checked state of the input. */
    checked: boolean;
    /** The disabled state of the input. */
    disabled?: boolean;
    /** The onChange handler of the input. */
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /** The size of the input. Defaults to small */
    size?: Size;
}

const ToggleSwitch: FunctionComponent<Props> = ({
    id,
    checked,
    disabled = false,
    onChange,
    size = 'sm',
}) => {
    return (
        <>
            <input className={styles.switch(size)} id={id} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            <label className={styles.label(size)} htmlFor={id}></label>
        </>
    );
};

export {
    ToggleSwitch,
    toggleSwitchSizes
};
