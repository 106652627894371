import { isDefined } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';
import { FormProps, ForwardedRefProps } from '@typedefs/props';
import { FocusEventHandler } from 'react';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';
import { Orientation } from '@typedefs/orientation';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { TooltipPlacement } from '../ToolTipBox/ToolTipBox';

interface DatePickerProps<FieldsType extends FieldValues>
    extends
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLInputElement> {
    label?: I18nKey;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    disabled?: boolean;
    placeholder?: I18nKey;
    onChange?: (event: unknown) => void;
    onBlur?: VoidFunction;
    onFocus?: FocusEventHandler;
    errorLabel?: I18nKey;
    required?: boolean;
    orientation?: Orientation;
    max?: string;
    min?: string;
    value?: string;
}

const styles = {
    root: (orientation: Orientation, width: Width) => {
        let widthStyle = '';
        switch (width) {
            case 'fullWidth':
                widthStyle = Cn.c('w-full');
                break;
            case 'halfWidth':
                widthStyle = Cn.c('w-1/2');
                break;
            case 'quarter':
                widthStyle = Cn.c('w-1/4');
                break;
        }

        return Cn.join([
            Cn.c('flex'),
            widthStyle,
            orientation === 'horizontal' ? Cn.c('flex-row space-x-3 items-baseline') : Cn.c('flex-col space-y-1')
        ]);

    },
    container: Cn.c('flex flex-col space-y-1'),
    inputWrapper: Cn.c('flex flex-col relative justify-center'),
    input: (isDisabled: boolean, hasError: boolean) => Cn.join([
        Cn.c('border border-emphasized py-2 px-3 rounded h-10 w-full font-paragraph-small-regular cursor-pointer bg-surface-default focus:outline-none placeholder-default'),
        Cn.ifTrue(hasError, Cn.c('border-critical-default')),
        isDisabled ? Cn.c('bg-surface-disabled text-disabled') : Cn.c('text-default focus:border-primary-default'),
    ]),
    calendarIcon: Cn.c('absolute right-4 text-icons-primary-default w-4 h-4'),
    error: Cn.c('text-critical-default font-paragraph-small-regular'),
};

const datePickerWidths = ['fullWidth', 'halfWidth', 'quarter'] as const;

type Width = typeof datePickerWidths[number];

interface Props<FieldsType extends FieldValues> extends DatePickerProps<FieldsType> {
    width?: Width;
    // TODO: [CHECK] Check if needed. From Rescript code, only used in DateTimePicker component. 
    // In DateTimePicker, it's value depends on the error message hook of the said component.
    hasError?: boolean;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
}

// @ocaml.doc("Date Picker Component
//
// - `name`: name of the input
// - `label`: optional - the label to display for the input
// - `className`: optional - class to apply
// - `labelClassName`: optional - class to apply to the label
// - `inputClassName`: optional - class to apply to the input
// - `disabled`: optional - is the input disabled or not
// - `placeholder`: optional - placeholder for the input
// - `onChange`: optional - function called when the input changes
// - `onBlur`: optional - function called when the input is blurred
// - `onFocus`: optional - function called when the input is focused
// - `value`: optional - value of the input
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `required`: bool - show the * for required input - false by default
// - `orientation`: [#horizontal | #vertical] - direction between label and input
// - `variant`: optional - the input looks (defaults to secondary)
// - `max`: optional - the latest date to accept
// - `min`: optional - the earliest date to accept
// - `hasError`: optional - will force the error status on the date picker
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// ")
const DatePicker = <FieldsType extends FieldValues>(
    {
        name,
        label,
        className,
        labelClassName,
        inputClassName,
        disabled: isDisabled = false,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        errors,
        clearErrors,
        errorLabel,
        required: isRequired = false,
        orientation = 'horizontal',
        width = 'fullWidth',
        max,
        min,
        hasError = false,
        value,
        forwardedRef,
        tooltip,
        tooltipPlacement,
    }: Props<FieldsType>,
) => {
    const { t } = useTranslation();

    const error = useErrorMessage(
        name,
        errors,
        isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
    );

    return (
        <div className={Cn.join([styles.root(orientation, width), Cn.getSome(className)])}>
            {isDefined(label) && <Label label={label} labelClassName={labelClassName} required={isRequired} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
            <div className={styles.container}>
                <div className={styles.inputWrapper}>
                    <input
                        type="date"
                        className={Cn.join([
                            styles.input(isDisabled, isDefined(error) || hasError),
                            Cn.getSome(inputClassName),
                        ])}
                        name={name}
                        placeholder={isDefined(placeholder) ? t(placeholder) : undefined}
                        onChange={event => {
                            onChange && onChange(event);
                        }}
                        onBlur={() => onBlur && onBlur()}
                        onFocus={event => {
                            clearErrors && clearErrors(name);
                            onFocus && onFocus(event);
                        }}
                        value={value}
                        max={max}
                        min={min}
                        required={isRequired}
                        ref={forwardedRef}
                    />
                    <Icon name="calendar" className={styles.calendarIcon} />
                </div>
                {isDefined(error) && <div className={styles.error}>{error}</div>}
            </div>
        </div>
    );
};

export {
    DatePicker,
    DatePickerProps,
    datePickerWidths
};

