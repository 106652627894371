import { SelectableStepTypesQuery } from '@entities';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import { allRecruitmentStages } from '@helpers/core/constants';
import { isNull, isNullish, isOneOf } from '@helpers/core/typeGuards';
import { StepFormType, getDestinationIndex, getLastValidStepIndex, stageRange } from '@shared/job_position/JobPositionRecruitmentSteps/helpers';
import { RecruitmentStageType } from '@typedefs/aliases';
import { Entity } from '@typedefs/graphql';
import { SelectionOption } from '@typedefs/selectOption';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFieldArrayRemove, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as value from '../value';

interface RecruitmentStageFormHookType {
    currentDropArea: string | null;
    setCurrentDropArea: Dispatch<SetStateAction<string | null>>;
    onDragEnd: OnDragEndResponder;
    fields: StepFormType[];
    insertNewRecruitmentStep: (stage: RecruitmentStageType) => void;
    removeRecruitmentStep: UseFieldArrayRemove;
    stepTypeOptions: SelectionOption[];
}

const useRecruitmentStageCreateForm = (
    form: UseFormReturn<value.Encoder.EncoderType>,
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>,
): RecruitmentStageFormHookType => {
    const { t } = useTranslation();

    const { control, setValue } = form;

    const { insert, remove, fields, move } = useFieldArray({
        control,
        name: 'recruitmentSteps',
    })
    const [currentDropArea, setCurrentDropArea] = useState<string | null>(null);

    const onDragEnd: OnDragEndResponder = (result) => {
        const { source, destination, reason } = result;

        // Drag cancelled
        if (reason === 'CANCEL') {
            return;
        }
        // Moved nowhere
        if (isNull(destination)) {
            return;
        }
        // Location didn't change
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        // Invalid droppable ID
        if (!isOneOf(allRecruitmentStages)(destination.droppableId)) {
            return;
        }

        const sourceOffset = fields.findIndex(step => step.stage === source.droppableId);
        const sourceIndex = source.index + sourceOffset;
        const destinationIndex = getDestinationIndex({ source, destination, fields, sourceOffset });

        if (isNullish(destinationIndex)) {
            return;
        }

        setValue(`recruitmentSteps.${sourceIndex}.stage`, destination.droppableId);
        move(sourceIndex, destinationIndex);
    };

    const insertNewRecruitmentStep = (stage: RecruitmentStageType) => {
        const range = stageRange(fields, stage)
        const stageHasSteps = range.start !== -1

        // Archive stage is not displayed and it shouldn't allow inserting new steps to it
        if (stage === "ARCHIVE") {
            return
        }

        // In PRE_EMPLOYMENT stage, only allow adding new steps before the Recruitment Result step
        if (stage === "PRE_EMPLOYMENT") {
            const index = fields.length - 2
            insert(index, value.Encoder.makeItem({ stage }))
        } else if (stageHasSteps) {
            insert(range.end + 1, value.Encoder.makeItem({ stage }))
        } else {
            // Get the index of the last valid step in one of the previous stages
            const lastValidIndex = getLastValidStepIndex(fields, stage)
            insert(lastValidIndex + 1, value.Encoder.makeItem({ stage }))
        }
    };

    const stepTypeOptions: SelectionOption[] = recruitmentStepTypes
        .filter((type) => type === 'DOCUMENT' || type === 'EVENT')
        .map((type) => ({
            key: type,
            label: t(`recruitmentStepTypeV2.${type}`),
            value: type,
        }));

    useEffect(() => {
        form.setFocus('title');
    }, [form.setFocus]);

    return {
        currentDropArea,
        setCurrentDropArea,
        onDragEnd,
        fields,
        insertNewRecruitmentStep,
        removeRecruitmentStep: remove,
        stepTypeOptions,
    };
};

export {
    useRecruitmentStageCreateForm
};

