import { ChangeEvent, FocusEventHandler, HTMLInputTypeAttribute, KeyboardEventHandler, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

import { assertNever } from '@helpers/typeHelpers';
import { isDefined } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';
import { Cn } from '@helpers/unsorted/classNames';
import { FormProps, ForwardedRefProps, HTMLPassedProps } from '@typedefs/props';
import { Icon, IconName } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { ToolTipBox, ToolTipBoxProps, TooltipPlacement } from '../ToolTipBox/ToolTipBox';

const inputSizes = ['small', 'medium', 'large', 'x-large'] as const;
type Size = typeof inputSizes[number];

type IconPosition = 'left' | 'right';

type AutocompleteMode = 'on' | 'off';

const styles = {
    root: Cn.c('w-full'),
    label: (size: Size, labelClassName?: string) => {
        let labelSizeStyle;

        switch (size) {
            case 'small':
                labelSizeStyle = Cn.c('font-paragraph-xsmall-medium')
                break;
            case 'medium':
                labelSizeStyle = Cn.c('font-paragraph-small-medium')
                break;
            case 'large':
            case 'x-large':
                labelSizeStyle = Cn.c('font-paragraph-base-medium')
                break;
        }

        return Cn.join([
            Cn.c('mb-1 text-emphasized'),
            labelSizeStyle,
            Cn.getSome(labelClassName),
        ]);
    },
    inputWrapper: Cn.c('flex flex-col space-y-1 w-full'),
    inputContainer: Cn.c('flex items-center relative'),
    inputIconContainer: Cn.c('flex w-full items-center relative'),
    input: (size: Size, isDisabled: boolean, hasError: boolean, hasWarning: boolean, iconPosition?: IconPosition) => {
        let sizeStyles = '';
        let iconPositionStyles = '';

        switch (size) {
            case 'small':
                sizeStyles = Cn.c('py-2 px-3 h-8 font-paragraph-xsmall-regular')
                break;
            case 'medium':
                sizeStyles = Cn.c('p-4 h-10 font-paragraph-small-regular')
                break;
            case 'large':
                sizeStyles = Cn.c('p-4 h-12 font-paragraph-base-regular')
                break;
            case 'x-large':
                sizeStyles = Cn.c('py-4 px-6 h-16 font-paragraph-base-regular')
                break;
        }

        switch (iconPosition) {
            case undefined:
                break;
            case 'left':
                iconPositionStyles = Cn.c('pl-9');
                break;
            case 'right':
                iconPositionStyles = Cn.c('pr-9');
                break;
            default:
                assertNever(iconPosition);
                break;
        }

        return Cn.join([
            sizeStyles,
            iconPositionStyles,
            Cn.c('border border-default w-full cursor-pointer rounded-lg bg-surface-default focus:outline-none focus:border-primary-default placeholder-default text-emphasized'),
            Cn.ifTrue(hasWarning, Cn.c('border-warning-default')),
            Cn.ifTrue(hasError, Cn.c('border-critical-default')),
            Cn.ifTrue(isDisabled, Cn.c('bg-surface-disabled')),
        ])
    },
    error: Cn.c('text-critical-default font-paragraph-xsmall-regular'),
    warning: Cn.c('text-warning-default font-paragraph-xsmall-regular'),
    icon: (size: Size, position: IconPosition) => {
        let iconSizeStyle = '';
        let iconPositionStyle = '';

        switch (size) {
            case 'small':
                iconSizeStyle = Cn.c('w-3.5 h-3.5')
                break;
            case 'medium':
            case 'large':
            case 'x-large':
                iconSizeStyle = Cn.c('w-4 h-4')
                break;
        }

        switch (position) {
            case 'left':
                iconPositionStyle = Cn.c('left-4');
                break;
            case 'right':
                iconPositionStyle = Cn.c('right-4');
                break;
            default:
                assertNever(position);
                break;
        }

        return Cn.join([
            iconSizeStyle,
            iconPositionStyle,
            Cn.c('absolute text-icons-subdued')
        ]);
    },
    clearIcon: (size: Size) => {
        let iconSizeStyle;

        switch (size) {
            case 'small':
                iconSizeStyle = Cn.c('w-3.5 h-3.5')
                break;
            case 'medium':
            case 'large':
            case 'x-large':
                iconSizeStyle = Cn.c('w-4 h-4')
                break;
        }

        return Cn.join([
            iconSizeStyle,
            Cn.c('absolute right-4 text-icons-subdued')
        ]);
    },
    unitText: Cn.c('mt-8 ml-3 font-paragraph-small-regular'), // TODO: [REFACTOR] Add implementation for this when design is ready
};
interface Props<FieldsType extends FieldValues>
    extends
    HTMLPassedProps<'value'>,
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLInputElement> {
    /** Size of the input */
    size?: Size;
    required?: boolean;
    label?: I18nKey;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    unit?: I18nKey;
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    disabled?: boolean;
    placeholder?: I18nKey;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: VoidFunction;
    onFocus?: FocusEventHandler;
    reset?: VoidFunction;
    errorLabel?: I18nKey;
    onKeyDown?: KeyboardEventHandler;
    type?: HTMLInputTypeAttribute;
    autocomplete?: AutocompleteMode;
    readOnly?: boolean;
    title?: string;
    maxLength?: number;
    hasError?: boolean;
    /**  Icon of the input */
    iconName?: IconName;
    iconClassName?: string;
    iconPosition?: IconPosition;
    onClickIcon?: VoidFunction;
    warnValidator?: (value: string) => I18nKey | undefined;
    /** Tooltip shown when the input is disabled */
    disabledTooltip?: ToolTipBoxProps;
}

// @ocaml.doc("Input Component
//
// - `name`: name of the input
// - `label`: optional - the label to display for the input
// - `unit`: optional - the unit to display for the input
// - `className`: optional - class to apply to the container
// - `inputClassName`: optional - class to apply to the input
// - `labelClassName`: optional - class to apply to the label
// - `disabled`: optional - is the input disabled or not - false by default
// - `placeholder`: optional - placeholder for the input
// - `onChange`: optional - function called when the input changes
// - `onBlur`: optional - function called when the input is blurred
// - `onFocus`: optional - function called when the input is focused
// - `value`: optional - value of the input
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `required`: optional - show the * for required input - false by default
// - `type`: optional - the HTML type of the input - 'text' by default
// - `autocomplete`: optional - if browser should display suggestions on user inserted text - 'on' by default
// - `readOnly`: optional - is the input readonly - false by default
// - `title`: optional - title of the input
// - `maxLength`: optional - max length of the input
// - `hasError`: optional - will force the error status on the input - false by default
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// ")
const Input = <FieldsType extends FieldValues>(
    {
        name,
        size = 'medium',
        required: isRequired = false,
        label,
        unit,
        className,
        inputClassName,
        labelClassName,
        disabled: isDisabled = false,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        onKeyDown,
        reset,
        value,
        errors,
        clearErrors,
        errorLabel,
        type = 'text',
        autocomplete = 'on',
        readOnly = false,
        title,
        maxLength,
        hasError = false, // TODO: [CHECK] Verify if still needed. In Rescript code, this prop is only used in `DateTimePicker` component
        iconName,
        iconClassName = '', 
        iconPosition = 'left',
        onClickIcon,
        forwardedRef,
        tooltip,
        tooltipPlacement,
        warnValidator,
        disabledTooltip: inputDisabledTooltip,
    }: Props<FieldsType>,
) => {
    const { t } = useTranslation();

    const errorFieldName = isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name;

    const error = useErrorMessage(
        name,
        errors,
        errorFieldName,
    );

    const [warnMessage, setWarnMessage] = useState<string | undefined>(undefined);

    const inputContent = (
        <div className={Cn.join([styles.root, Cn.getSome(className)])}>
            {isDefined(label) && <Label label={label} labelClassName={styles.label(size, labelClassName)} required={isRequired} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
            <div className={styles.inputWrapper}>
                <div className={styles.inputContainer}>
                    <div className={styles.inputIconContainer}>
                        <input
                            disabled={isDisabled}
                            type={type}
                            autoComplete={autocomplete}
                            className={Cn.join([
                                styles.input(size, isDisabled, isDefined(error) || hasError, isDefined(warnMessage), iconName && iconPosition),
                                Cn.getSome(inputClassName),
                            ])}
                            name={name}
                            placeholder={isDefined(placeholder) ? t(placeholder) : undefined}
                            onChange={e => {
                                if (isDefined(warnValidator)) {
                                    const result = warnValidator(e.target.value);
                                    setWarnMessage(result ? t(result, { fieldName: errorFieldName }) : undefined);
                                }

                                onChange && onChange(e);
                            }}
                            onBlur={() => onBlur && onBlur()}
                            onFocus={event => {
                                clearErrors && clearErrors(name);
                                onFocus && onFocus(event);
                            }}
                            onKeyDown={onKeyDown}
                            value={value}
                            readOnly={readOnly}
                            title={title}
                            ref={forwardedRef}
                            maxLength={maxLength}
                        />
                        {isDefined(iconName) && <Icon name={iconName} className={Cn.join([styles.icon(size, iconPosition), iconClassName])} onClick={onClickIcon} />}
                    </div>
                    {isDefined(reset) && value && <Icon name="close" className={styles.clearIcon(size)} onClick={reset} />}
                    {isDefined(unit) && <span className={styles.unitText}> {t(unit)} </span>}
                </div>
                {isDefined(error) && <div className={styles.error}>{error}</div>}
                {isDefined(warnMessage) && <div className={styles.warning}>{warnMessage}</div>}
            </div>
        </div>
    );

    return (isDefined(inputDisabledTooltip) && isDisabled
        ? <ToolTipBox
            {...inputDisabledTooltip}
        >
            {inputContent}
        </ToolTipBox>
        : inputContent
    );
};

export {
    Input,
    Props,
    Size,
    IconPosition,
    inputSizes
};
