import { Event_OneByIdQuery } from "@entities"
import { hasValue } from "@helpers/core/typeGuards";
import * as boolExtra from "@helpers/unsorted/boolExtra";
import { Entity } from "@typedefs/graphql"
import { z } from 'zod';

namespace Encoder {
    export type Type = ReturnType<typeof Encoder.defaultValues>

    export const defaultValues = (event?: Entity<Event_OneByIdQuery, 'event'>) => {

        return {
            name: event?.name || '',
            displayName: event?.displayName || '',
            duration: event?.duration.toString() || '0',
            candidateCapacity: event?.candidateCapacity.toString() || '0',
            description: event?.description || '',
            isOnline: event?.isOnline?.toString() || 'false',
            daysPrior: event?.deadline.daysPrior.toString() || '',
            cutoffTime: event?.deadline.cutoffTime.toString() || '0',
            shouldSetExpirationLink: hasValue(event?.daysUntilLinkInvalid).toString() || 'false',
            daysUntilLinkInvalid: event?.daysUntilLinkInvalid?.toString() || '',
        }
    }
}

namespace Decoder {
    export const schema = z.object({
        name: z.string().min(1),
        displayName: z.string().min(1),
        duration: z.string().min(1),
        candidateCapacity: z.string().min(1),
        description: z.string().min(1),
        daysPrior: z.string().min(1),
        cutoffTime: z.string().min(1),
        isOnline: z.enum(["true", "false"]),
        shouldSetExpirationLink: z.enum(["true", "false"]),
        daysUntilLinkInvalid: z.string().nullish(),
    }).transform(values => ({
        name: values.name,
        displayName: values.displayName,
        duration: parseInt(values.duration) || 0,
        candidateCapacity: parseInt(values.candidateCapacity) || 0,
        description: values.description,
        isOnline: boolExtra.fromString(values.isOnline),
        daysPrior: parseInt(values.daysPrior) || 0,
        cutoffTime: parseInt(values.cutoffTime) || 0,
        shouldSetExpirationLink: boolExtra.fromString(values.isOnline),
        daysUntilLinkInvalid: values.daysUntilLinkInvalid ? parseInt(values.daysUntilLinkInvalid) : undefined,
    }))
}

export {
    Encoder,
    Decoder
}