import { EventSession_OneByIdQuery } from "@entities"
import { Cn } from "@helpers/unsorted/classNames"
import { getWithDefault } from "@helpers/unsorted/stringExtra"
import { useBulkEventSessionAssignmentContext } from "@pages/ApplicationsPage/BulkEventSessionAssignmentModal/BulkEventSessionAssignmentContext"
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const styles = {
    container: Cn.c("flex flex-col space-y-2 mt-1"),
    line: Cn.c("flex items-center space-x-0.5"),
    label: Cn.c("text-subdued font-paragraph-small-regular"),
    value: (variant: 'under' | 'equal' | 'over') => {
        return Cn.join([
            Cn.c("font-paragraph-small-regular"),
            Cn.ifTrue(variant === 'under', "text-emphasized"),
            Cn.ifTrue(variant === 'equal', "text-success-default"),
            Cn.ifTrue(variant === 'over', "text-warning-default"),
        ])
    },
    shownExaminers: Cn.c("font-paragraph-small-regular text-emphasized"),
    moreExaminers: Cn.c("text-emphasized font-paragraph-small-link-regular underline"),
    tooltip: Cn.c("w-18 truncate"),
    tooltipContent: Cn.c("whitespace-pre font-paragraph-xsmall-medium text-on-primary"),
}

interface Props {
    group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>
    candidateCapacity: number
}

const GroupDetail: FunctionComponent<Props> = ({
    group,
    candidateCapacity
}) => {
    const { t } = useTranslation()
    const [{ settings: { groupId, applicationIds } }] = useBulkEventSessionAssignmentContext()
    const currentCapacity = group.recruitmentProcessAssignments.length + (groupId && groupId === group.id && applicationIds ? applicationIds.length : 0)
    const shownExaminers = group.examinerAssignments.length === 0 ? getWithDefault() : group.examinerAssignments[0].client.fullName
    const remainingExaminers = group.examinerAssignments.slice(1)

    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <span className={styles.label}>
                    {t('applications.bulkEventSessionAssignment.sessionGroupSelection.examiners')}
                </span>
                <span className={styles.shownExaminers}>{shownExaminers}</span>
                {remainingExaminers.length > 0 && <>
                    <span className={styles.shownExaminers}>,</span>
                    <ToolTipBox
                        tooltipContent={
                            <span className={styles.tooltipContent}>
                                {remainingExaminers.map(({ client }) => client.fullName).join("\n")}
                            </span>}
                        toolTipClassName={styles.tooltip}
                    >
                        <span className={styles.moreExaminers}>
                            {t('applications.bulkEventSessionAssignment.sessionGroupSelection.moreExaminers',
                                { count: remainingExaminers.length })}
                        </span>
                    </ToolTipBox>
                </>}
            </div>
            <div className={styles.line}>
                <span className={styles.label}>
                    {t('applications.bulkEventSessionAssignment.sessionGroupSelection.candidates')}
                </span>
                <span className={styles.value(
                    currentCapacity === candidateCapacity
                        ? 'equal'
                        : currentCapacity < candidateCapacity
                            ? 'under'
                            : 'over'
                )}>
                    {t('applications.bulkEventSessionAssignment.sessionGroupSelection.candidateCount',
                        { total: candidateCapacity, registered: currentCapacity }
                    )}
                </span>
            </div>
        </div>
    )
}

export {
    GroupDetail
}