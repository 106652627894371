import { FunctionComponent } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ChildrenProps } from '@typedefs/props';

const styles = {
    row: Cn.c('flex flex-row items-center justify-between py-3 px-3.5 font-paragraph-small-regular text-default border-b border-default last:border-b-0 group hover:bg-surface-hovered-default'),
    labelWrapper: Cn.c('flex space-x-2'),
    documentIcon: Cn.c('text-icons-subdued w-5 h-5'),
    label: Cn.c('text-default max-w-xs truncate'),
};

interface Props extends ChildrenProps {
    label: string;
}

const Row: FunctionComponent<Props> = ({ label, children }) => {
    return (
        <div className={styles.row} title={label}>
            <div className={styles.labelWrapper}>
                <Icon name="document" className={styles.documentIcon} />
                <p className={styles.label}>{label}</p>
            </div>
            <div> {children} </div>
        </div >
    );
};

export {
    Row
};
