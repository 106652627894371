import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react"
import { MenuItem } from "./MenuItems";
import { assertNever } from "@helpers/typeHelpers";
import { MenuData, MenuGroupData, MenuItemData, Size, SubmenuPosition } from "./NestedMenu";

const styles = {
    container: (size: Size) =>
        Cn.join([
            Cn.c(
                "absolute z-[100] min-w-full w-max bg-white rounded-lg shadow-md top-0 hidden py-2"
            ),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("font-paragraph-xsmall-regular");
                    }
                    case "medium": {
                        return Cn.c("font-paragraph-small-regular");
                    }
                    case "large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    case "x-large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    default: {
                        assertNever(size);
                        return "";
                    }
                }
            })(),
        ]),
    subMenu: (submenuPosition: SubmenuPosition) => {
        if (submenuPosition === "left") {
            return Cn.c("right-[100%]");
        }
        return Cn.c("left-[100%]");
    },
    show: Cn.c("flex flex-col space-y-2"),
    list: Cn.c("border-b border-b-default"),
    groupName: Cn.c('text-subdued font-paragraph-xsmall-medium px-4 pt-2 pb-1 cursor-auto')
}

interface Props {
    groups: MenuGroupData[];
    itemCustomization?: MenuData['item'];
    size?: Size
    submenuPosition?: SubmenuPosition
    showMenu?: boolean
    depthLevel: number
    value?: string
    onChange?: (value: string) => void
}

const MenuContainer: FunctionComponent<Props> = ({
    groups,
    itemCustomization,
    size = 'medium',
    submenuPosition = 'right',
    showMenu = false,
    depthLevel,
    onChange,
}) => {
    return (
        <div className={Cn.join([
            styles.container(size),
            Cn.ifTrue(depthLevel > 0, styles.subMenu(submenuPosition)),
            Cn.ifTrue(showMenu, styles.show)
        ])}>
            {groups.map((group, index) => {
                return (
                    <ul key={index} className={Cn.ifTrue(index !== groups.length - 1, styles.list)}>
                        {group.groupName && <li className={styles.groupName}>{group.groupName}</li>}
                        {group.items.map((item, index) => (
                            <MenuItem
                                itemCustomization={itemCustomization}
                                item={item}
                                depthLevel={depthLevel + 1}
                                key={index}
                                submenuPosition={submenuPosition}
                                onChange={onChange}
                                size={size}
                            />
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export {
    MenuContainer,
}