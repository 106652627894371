import { isDefined } from "@helpers/core/typeGuards";
import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";
import { useDebounce } from "@helpers/hooks/unsorted/debounceHook";
import { useDomRef } from "@helpers/hooks/unsorted/domRefHook";
import { ChangeEvent, KeyboardEventHandler, RefObject, useEffect, useReducer, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { I18nKey, useTranslation } from "react-i18next";
import { State, initialState, typeAheadReducer } from "./reducer";
import { ValueItem } from "./value";

interface MultiValueTypeAheadHookType {
    typeAheadRef: RefObject<HTMLDivElement>;
    state: State;
    focus: VoidFunction;
    selectItem: (item: ValueItem.ValueItemType) => void;
    removeItem: (label: string) => void;
    onKeyDown: KeyboardEventHandler;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void; //TODO: rebind correct type
    warnMessage?: string;
}

const useMultiValueTypeAhead = ({
    form, name, onInputChange, onValueChange, inputValue, enableInsert, warnValidator,
}:
    {
        form: UseFormReturn<any, any>,//TODO: rebind correct type
        name: string,
        onInputChange?: (currentValues: ValueItem.ValueItemType[], value?: string) => void,
        onValueChange?: (newValue: ValueItem.ValueItemType[]) => void,
        inputValue?: ValueItem.ValueItemType[],
        enableInsert?: boolean,
        warnValidator?: () => I18nKey | undefined;
    }): MultiValueTypeAheadHookType => {
    const { t } = useTranslation()

    const typeAheadRef = useDomRef<HTMLDivElement>()

    const { setValue, getValues } = form

    const [state, dispatch] = useReducer(
        typeAheadReducer, initialState(inputValue),
    )

    const [warnMessage, setWarnMessage] = useState<string | undefined>(undefined);

    const filterEmptyLabel = (items: ValueItem.ValueItemType[]) => items.filter(item => item.label.length !== 0)

    const removeDuplicate = (arr: ValueItem.ValueItemType[]) => {
        const itemsHaveValues = arr.filter(item => isDefined(item.value))
        const newlyAddedItems = arr.filter(item => !isDefined(item.value))
        const sortedItems = itemsHaveValues.concat(newlyAddedItems)

        return sortedItems.filter((item, index) =>
            sortedItems.findIndex(i => i.label === item.label) === index
        )
    }

    const selectItem = (item: ValueItem.ValueItemType) => {
        const newItems = removeDuplicate(filterEmptyLabel(currentValues).concat([item]))
        setValue(name, newItems)
    }

    const removeItem = (label: string) => {
        const newItems =
            removeDuplicate(filterEmptyLabel(currentValues).filter(currentValue => currentValue.label !== label))

        setValue(name, newItems)

    }

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        const keyPress = event.key

        if (keyPress == "Enter") {
            event.preventDefault()
            const target = event.currentTarget

            if (enableInsert && target) {
                const label = target.value
                dispatch({
                    name: 'EditInput',
                    payload: {
                        text: ""
                    }
                })

                selectItem({
                    label: label,
                })
            }
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget

        dispatch({
            name: 'EditInput',
            payload: {
                text: value
            }
        })
    }

    //TODO: remove this comment after rebinding the correct type
    const currentValues = getValues(name) as ValueItem.ValueItemType[]

    const focus = () => dispatch({ name: 'FocusInput' })

    useClickOutside([typeAheadRef], () => dispatch({ name: 'BlurInput' }));

    useDebounce(state.mode.name === 'edit' ? state.mode.payload.value : "", (value?: string) => {
        onInputChange && onInputChange(filterEmptyLabel(currentValues), value)
    }, 500);

    useEffect(() => {
        dispatch({
            name: 'SetNewItems',
            payload: {
                items: currentValues
            }
        })

        if (isDefined(warnValidator)) {
            const message = warnValidator();
            setWarnMessage(message ? t(message) : undefined);
        }

        onValueChange && onValueChange(currentValues)
    }, [currentValues])

    return {
        typeAheadRef,
        state,
        focus,
        selectItem,
        removeItem,
        onKeyDown,
        onChange,
        warnMessage,
    }
}

export {
    useMultiValueTypeAhead
};
