import { Event_OneByIdQuery } from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from '@helpers/unsorted/classNames';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { Button } from '@shared/unsorted/Button/Button';
import { Chip } from '@shared/unsorted/Chip/Chip';
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToggleSwitch } from '@shared/unsorted/ToggleSwitch/ToggleSwitch';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatusType, useEventDetails } from './hook';
import { generatePath, useNavigate } from 'react-router-dom';
import { EventSessionDetail } from '@routes/eventSession';

const baseTableCell = Cn.c('table-td font-paragraph-small-regular');

const styles = {
    metadata: Cn.c('pt-8 pb-6 border-b border-b-default'),
    name: Cn.c('font-h2-bold text-emphasized pb-3'),
    infoRow: Cn.c('flex space-x-3 pb-2'),
    detailContainer: Cn.c('flex items-center space-x-1'),
    icon: Cn.c('text-icons-disabled w-3.5 h-3.5'),
    label: Cn.c('text-subdued font-paragraph-xsmall-regular'),
    detail: Cn.c('font-paragraph-xsmall-medium text-emphasized'),
    sessionSection: Cn.c('pt-6 flex flex-col overflow-auto'),
    sessionsHeaderContainer: Cn.c('flex justify-between pb-3'),
    eventSessionManagement: Cn.c('font-h4-bold text-emphasized'),
    toggleContainer: Cn.c('flex items-center space-x-2'),
    toggleLabel: Cn.c('text-emphasized font-paragraph-small-regular'),
    emptyTable: Cn.c('w-full flex flex-col items-center justify-center bg-surface-default min-h-empty-table border border-subdued'),
    tableInnerContainer: Cn.c('bg-surface-default border border-subdued flex overflow-auto'),
    table: Cn.c('table-sticky'),
    tableTH: Cn.c('table-thead-row table-th-sticky top-0'),
    tableRow: Cn.c('cursor-pointer'),
    dateCell: Cn.join([baseTableCell, Cn.c('font-paragraph-small-medium text-primary-default')]),
    status: (status: EventStatusType) => {
        switch (status) {
            //TODO: adding color later
            default: return Cn.c('text-emphasized');
        }
    },
    additionType: Cn.c('w-fit'),
};

interface Props {
    event: Entity<Event_OneByIdQuery, 'event'>;
    openAddEventSessionModal: VoidFunction;
}

const EventDetails: FunctionComponent<Props> = ({ event, openAddEventSessionModal }) => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();
    const navigate = useNavigate()

    const {
        displayName,
        candidateCapacity,
        isOnline,
        deadline: { daysPrior, cutoffTime },
        sessions,
    } = event;

    const {
        hideCompletedSessions,
        toggleHideCompletedSessions,
        filteredEventSessions,
        getEventSessionStatus,
    } = useEventDetails(event);

    return (
        <>
            <div className={styles.metadata} data-section="metadata">
                <p className={styles.name}>{event.name}</p>
                <div className={styles.infoRow}>
                    <div className={styles.detailContainer}>
                        <Icon name="calendarCheck" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventDetail.displayNameLabel")}</span>
                        <span className={styles.detail}>{displayName}</span>
                    </div>
                    <div className={styles.detailContainer}>
                        <Icon name="candidates" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventDetail.maxCapacityLabel")}</span>
                        <span className={styles.detail}>{t("event.eventDetail.maxCapacity", { count: candidateCapacity })}</span>
                    </div>
                    <div className={styles.detailContainer}>
                        <Icon name="discuss" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventDetail.formatLabel")}</span>
                        <span className={styles.detail}>
                            {isOnline
                                ? t("event.format.online")
                                : t("event.format.offline")
                            }
                        </span>
                    </div>
                </div>
                <div className={styles.infoRow}>
                    <div className={styles.detailContainer}>
                        <Icon name="timer" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventDetail.reservationDeadlineLabel")}</span>
                        <span className={styles.detail}>{t("event.eventDetail.reservationDeadline", { days: daysPrior, time: cutoffTime.toString().padStart(2, "0") })}</span>
                    </div>
                </div>
            </div>
            <div className={styles.sessionSection} data-section="eventSessionManagement">
                <div className={styles.sessionsHeaderContainer}>
                    <p className={styles.eventSessionManagement}>{t("event.eventDetail.eventSessionManagement")}</p>
                    <div className={styles.toggleContainer}>
                        <ToggleSwitch
                            id={"hideCompletedSessions"}
                            disabled={sessions.length === 0}
                            checked={hideCompletedSessions}
                            onChange={toggleHideCompletedSessions}
                        />
                        <span className={styles.toggleLabel}>{t("event.eventDetail.hideCompletedSessions")}</span>
                    </div>
                </div>
                {sessions.length === 0
                    ? <div className={styles.emptyTable}>
                        <EmptyStateContainer
                            icon="emptyCandidate"
                            description="event.eventDetail.empty.description"
                            subDescription="event.eventDetail.empty.subDescription">
                            <Button size="md" onClick={openAddEventSessionModal}>{t("event.eventDetail.addSession")}</Button>
                        </EmptyStateContainer>
                    </div>
                    :
                    <div className={styles.tableInnerContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.tableTH}>
                                        {t("event.eventDetail.table.header.eventSession")}
                                    </th>
                                    <th className={styles.tableTH}>
                                        {t("event.eventDetail.table.header.groupsCount")}
                                    </th>
                                    <th className={styles.tableTH}>
                                        {t("event.eventDetail.table.header.status")}
                                    </th>
                                    <th className={styles.tableTH}>
                                        {t("event.eventDetail.table.header.reservedCandidatesCount")}
                                    </th>
                                    <th className={styles.tableTH}>
                                        {t("event.eventDetail.table.header.additionType")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEventSessions.map(eventSession => {
                                    const { id, groupCount, startAt, endAt, registeredCount } = eventSession;

                                    const status = getEventSessionStatus(eventSession)

                                    return (
                                        <tr
                                            key={id}
                                            className={styles.tableRow}
                                            onClick={() => {
                                                navigate(generatePath(EventSessionDetail.PATH_NAME, { id }))
                                            }}
                                        >
                                            <td className={styles.dateCell}>
                                                <span> {dateFormat.simpleDate(new Date(startAt), "-", currentLanguage)} </span>
                                                <span>
                                                    {`${dateFormat.simpleTime(new Date(startAt))} ~ ${dateFormat.simpleTime(new Date(endAt))}`}
                                                </span>
                                            </td>
                                            <td className={baseTableCell}> {groupCount} </td>
                                            <td className={baseTableCell}>
                                                <div>
                                                    {/* TODO: Need to verify mapping first. Then update logic */}
                                                    {status && <span className={styles.status(status)}>
                                                        {t(`event.eventDetail.table.availabilityStatuses.${status}`)}
                                                    </span>}
                                                </div>
                                            </td>
                                            <td className={baseTableCell}>
                                                {t("event.eventDetail.table.reservedCandidates", { registered: registeredCount, total: groupCount * candidateCapacity })}
                                            </td>
                                            {/* TODO: Replace hardcoded addition type when other types are added */}
                                            <td className={baseTableCell}>
                                                <Chip
                                                    variant="outline"
                                                    label={t("event.eventDetail.table.additionTypes.manual")}
                                                    className={styles.additionType}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </>
    );
};

export {
    EventDetails
};
