import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCSVImportFormContext } from "../../useCSVImportFormContext";
import { downloadUrl } from "@helpers/unsorted/downloadUrl";
import { parseSize, toSizeString } from "@helpers/unsorted/numberExtra";
import { MAX_FILE_SIZE } from "@helpers/core/constants";
import { v4 as uuidv4 } from 'uuid';
import { useStepperContext } from "@shared/layout/stepper/useStepperContext";
import { useMutation } from "urql";
import { ValidateCandidateCsvImportFileDocument } from "@entities";
import { isDefined } from "@helpers/core/typeGuards";
import { useEffect, useState } from "react";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { checkError } from "@pages/application/CSVImportPage/helper";

const useCSVImportErrorCorrectionStep = (errorCSVUrl?: string) => {
    const [{ csvFile, setting }, dispatch] = useCSVImportFormContext()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isMessageShown, setIsMessageShown] = useState(true);

    const { goToStep } = useStepperContext()

    const { t } = useTranslation()

    const { error: toastError } = useToast();

    const [validateCsvResponse, validateCandidateCSV] = useMutation(ValidateCandidateCsvImportFileDocument);

    const form = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const { handleSubmit } = form;

    const downloadErrorCSV = () => {
        if (!errorCSVUrl) return;

        downloadUrl(t('applications.csvImport.errorCorrectionStep.errorFileName'), errorCSVUrl);
    }

    const onUpload = (files?: File[]) => {
        if (files?.length) {
            if (files.some(file => file.size > parseSize(MAX_FILE_SIZE.CSV_IMPORT))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.CSV_IMPORT) });
                removeFile()

                return;
            }

            const fileUploaded = files[0];

            dispatch({
                name: 'SetFileUpload',
                payload: {
                    csvFile: {
                        file: fileUploaded,
                        filename: fileUploaded.name,
                        id: uuidv4(),
                    },
                },
            });
        }
    };

    const onSubmit = () => {
        try {
            if (isDefined(csvFile)) {
                validateCandidateCSV({
                    setting: {
                        jobPositionId: setting?.jobPosition.id || '',
                        acquisitionChannelId: setting?.acquisitionChannel?.id,
                        entryChannelId: setting?.entryChannel?.id,
                    },
                    filename: csvFile.filename,
                    content: csvFile.file as any
                });
            }

        } catch (error) {
            toastError('global.error');
        }
    }

    const removeFile = () => dispatch({
        name: 'SetFileUpload',
        payload: {
            csvFile: undefined,
        },
    });

    const backToSetup = () => {
        goToStep('setup')
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'setup' }
            }
        })
    }

    useEffect(() => {
        handleResponse(validateCsvResponse, {
            onFetching: () => setIsSubmitting(true),
            onError: () => {
                setIsSubmitting(false);
            },
            onData: (data) => {
                setIsSubmitting(false);
                if (checkError(data.validateCandidateCSVImportFile)) {
                    goToStep('errorCorrection')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'errorCorrection',
                                data: {
                                    errorCSVUrl: data.validateCandidateCSVImportFile.errorCSVUrl,
                                    errorSummary: data.validateCandidateCSVImportFile.errorSummary,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.csvImport.steps.errorCorrection', variant: 'selected' },
                                        { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'default' },
                                        { id: 'import', label: 'applications.csvImport.steps.import', variant: 'default' },
                                    ],
                                    resetCSVFile: true
                                }
                            }
                        }
                    })
                } else {
                    goToStep('preview')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'preview',
                                data: {
                                    validationResults: data.validateCandidateCSVImportFile.result,
                                    validCSVUrl: data.validateCandidateCSVImportFile.validCSVUrl,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.csvImport.steps.errorCorrection', variant: 'success' },
                                        { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'selected' },
                                        { id: 'import', label: 'applications.csvImport.steps.import', variant: 'default' },
                                    ],
                                }
                            }
                        }
                    })
                }
            },
        });
    }, [validateCsvResponse]);

    return {
        csvFile,
        setting,
        removeFile,
        onUpload,
        downloadErrorCSV,
        backToSetup,
        isSubmitting,
        submitForm: handleSubmit(onSubmit),
        isMessageShown,
        setIsMessageShown
    }
}

export {
    useCSVImportErrorCorrectionStep
}