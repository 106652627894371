import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react";
import { useEventRegistrationPage } from "./hook";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Button } from "@shared/unsorted/Button/Button";
import { Controller } from "react-hook-form";
import { RadioInputGroup } from "@shared/unsorted/RadioInput/RadioInputGroup/RadioInputGroup";
import { ErrorMode } from "./ErrorMode";
import { SuccessMode } from "./SuccessMode";

const styles = {
  root: Cn.c("flex flex-col flex-1 bg-default font-paragraph-small-regular text-default"),
  topBar: Cn.c(
    "flex flex-shrink-0 px-10 items-center justify-center w-full h-12 bg-surface-default shadow-md fixed z-50 text-black",
  ),
  wrapper: Cn.c("pt-28 m-auto max-w-144"),
  form: Cn.c("flex flex-col bg-surface-default rounded shadow-base p-6 pb-8"),
  candidateName: Cn.c("text-black"),
  eventName: Cn.c("font-h4-medium pt-1 text-subdued"),
  requestTitle: Cn.c("font-h2-bold text-emphasized pt-1 pb-3"),
  duration: Cn.c("flex gap-x-1 py-8"),
  durationValue: Cn.c("font-paragraph-small-bold text-emphasized"),
  eventDescription: Cn.c("border rounded border-emphasized px-2 py-4"),
  scheduleContainer: Cn.c("pt-6"),
  radioInput: Cn.c("border-b border-default pb-4"),
  schedule: Cn.c("ml-2 flex flex-1 gap-x-3 font-paragraph-small-bold text-black"),
  spacing: Cn.c("pt-8"),
}

const EventRegistrationPage: FunctionComponent = () => {
  const { state, onSubmit, form } = useEventRegistrationPage()

  const { control, clearErrors, formState: { errors } } = form

  const { t } = useTranslation()

  return state.isLoading
    ? <Loader />
    : <div className={styles.root}>
      {
        state.mode.name === 'error'
          ? <ErrorMode {...state.mode.payload} />
          : state.mode.name === 'success'
            ? <SuccessMode {...state.mode.payload} />
            : <>
              <div className={styles.topBar}> {state.mode.unreservedEvent.company.companyName} </div>
              <div className={styles.wrapper}>
                <form className={styles.form} onSubmit={onSubmit}>
                  <span className={styles.candidateName}> {state.mode.candidate.name} </span>
                  <span className={styles.eventName}> {state.mode.unreservedEvent.displayName} </span>
                  <span className={styles.requestTitle}> {t('eventRegistration.title')}</span>
                  <span> {t('eventRegistration.instruction')} </span>
                  <div className={styles.duration}>
                    <span>{t('eventRegistration.durationLabel')}</span>
                    <span className={styles.durationValue}>
                      {state.mode.unreservedEvent.duration} {t('eventRegistration.durationUnit')}
                    </span>
                  </div>
                  <div className={styles.eventDescription}>
                    <span> {state.mode.unreservedEvent.description} </span>
                  </div>
                  <div className={styles.scheduleContainer}>
                    <Controller
                      name='sessionId'
                      control={control}
                      rules={{ required: true, minLength: 1 }}
                      render={({ field: { value, name, onBlur, onChange, ref } }) =>
                        <RadioInputGroup
                          name={name}
                          onChange={onChange}
                          options={state.mode.name === 'loaded' ? state.mode.slots : []}
                          forwardedRef={ref}
                          onBlur={onBlur}
                          errors={errors}
                          clearErrors={clearErrors}
                          value={value}
                          containerOrientation="vertical"
                          inputClassName={styles.radioInput}
                          inputLabelClassName={styles.schedule}
                        /> //TODO: add I18n for error message
                      }
                    />
                  </div>
                  <span className={styles.spacing}>
                    {t('eventRegistration.confirmation')}
                  </span>
                  <div className={styles.spacing}>
                    <Button size='lg' variant='primaryFilled' type="submit" isLoading={state.isSubmitting} isFull={true} isDisabled={!state.mode.canSubmit}>
                      {t('eventRegistration.reserve')}
                    </Button>
                  </div>
                </form>
              </div>
            </>
      }
    </div>
};

export {
  EventRegistrationPage,
};
