import { useEffect, useState } from "react";
import { useCSVBulkEvaluationFormContext } from "../../CSVBulkEvaluationFormContext"
import { useStepperContext } from "@shared/layout/stepper/useStepperContext";
import { useTranslation } from "react-i18next";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useMutation } from "urql";
import { ValidateBulkEvaluationCsvFileDocument } from "@entities";
import { parseSize, toSizeString } from "@helpers/unsorted/numberExtra";
import { MAX_FILE_SIZE } from "@helpers/core/constants";
import { v4 as uuidv4 } from 'uuid';
import { isDefined } from "@helpers/core/typeGuards";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { checkError } from "@pages/application/CSVBulkEvaluationPage/helper";
import { downloadUrl } from "@helpers/unsorted/downloadUrl";

const useCSVBulkEvaluationErrorCorrectionStep = (errorCSVUrl?: string) => {
    const [{ csvFile }, dispatch] = useCSVBulkEvaluationFormContext()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isMessageShown, setIsMessageShown] = useState(true);

    const { goToStep } = useStepperContext()

    const { t } = useTranslation()

    const { error: toastError } = useToast();

    const [validateCsvResponse, validateBulkEvaluationCSV] = useMutation(ValidateBulkEvaluationCsvFileDocument);

    const downloadErrorCSV = () => {
        if (!errorCSVUrl) return;

        downloadUrl(t('applications.bulkEvaluation.errorCorrectionStep.errorFileName'), errorCSVUrl);
    }

    const onUpload = (files?: File[]) => {
        if (files?.length) {
            if (files.some(file => file.size > parseSize(MAX_FILE_SIZE.CSV_IMPORT))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.CSV_IMPORT) });
                removeFile()

                return;
            }

            const fileUploaded = files[0];

            dispatch({
                name: 'SetFileUpload',
                payload: {
                    csvFile: {
                        file: fileUploaded,
                        filename: fileUploaded.name,
                        id: uuidv4(),
                    },
                },
            });
        }
    };

    const onSubmit = () => {
        try {
            if (isDefined(csvFile)) {
                validateBulkEvaluationCSV({
                    filename: csvFile.filename,
                    content: csvFile.file as any
                });
            }

        } catch (error) {
            toastError('global.error');
        }
    }

    const removeFile = () => dispatch({
        name: 'SetFileUpload',
        payload: {
            csvFile: undefined,
        },
    });

    const backToSetup = () => {
        goToStep('setup')
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'setup' }
            }
        })
    }

    useEffect(() => {
        handleResponse(validateCsvResponse, {
            onFetching: () => setIsSubmitting(true),
            onError: () => {
                setIsSubmitting(false);
            },
            onData: (data) => {
                setIsSubmitting(false);
                if (checkError(data.validateBulkEvaluationCSVFile)) {
                    goToStep('errorCorrection')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'errorCorrection',
                                data: {
                                    errorCSVUrl: data.validateBulkEvaluationCSVFile.errorCSVUrl,
                                    errorSummary: data.validateBulkEvaluationCSVFile.errorSummary,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.bulkEvaluation.steps.errorCorrection', variant: 'selected' },
                                        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'default' },
                                        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
                                    ],
                                    resetCSVFile: true
                                }
                            }
                        }
                    })
                } else {
                    goToStep('preview')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'preview',
                                data: {
                                    validationResults: data.validateBulkEvaluationCSVFile.result,
                                    validCSVUrl: data.validateBulkEvaluationCSVFile.validCSVUrl,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.bulkEvaluation.steps.errorCorrection', variant: 'success' },
                                        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'selected' },
                                        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
                                    ],
                                }
                            }
                        }
                    })
                }
            },
        });
    }, [validateCsvResponse]);

    return {
        csvFile,
        isSubmitting,
        isMessageShown,
        setIsMessageShown,
        onUpload,
        removeFile,
        backToSetup,
        onSubmit,
        downloadErrorCSV
    }
}

export {
    useCSVBulkEvaluationErrorCorrectionStep
}