import { JobPositions_AllDocument, JobPositions_AllQuery } from "@entities"
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra"
import { JobPosition } from "@routes/jobPosition"
import { AsyncResponseType } from "@typedefs/aliases"
import { Entity } from "@typedefs/graphql"
import { useEffect, useState } from "react"
import { useQuery } from "urql"

interface InitialStateData {
    totalRows: number
    clientHasNoJobPosition: boolean
    jobPositions: Entity<JobPositions_AllQuery, 'jobPositions.edges'>[]
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const queryParams = JobPosition.useSearchParams()

    const jobPositionListQueryContext = useQueryContext("JobPosition");
    const [jobPositionListResponse,] = useQuery({
        query: JobPositions_AllDocument, context: jobPositionListQueryContext, variables: {
            offset: queryParams.rowsPerPage * (queryParams.page - 1),
            first: queryParams.rowsPerPage,
            jobName: queryParams.search,
            sortType: queryParams.sortType,
            order: queryParams.order
        }
    });

    useEffect(() => {
        handleResponse(
            jobPositionListResponse,
            {
                onData: (data) => setInitialState({
                    isLoading: false,
                    result: {
                        isSuccess: true,
                        data: {
                            totalRows: data.jobPositions.totalCount,
                            jobPositions: data.jobPositions.edges,
                            clientHasNoJobPosition: data.jobPositions.clientHasNoJobPosition,
                        }
                    }
                }),
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [jobPositionListResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}