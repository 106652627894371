import { Event_OneByIdDocument, Event_OneByIdQuery } from "@entities";
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { EventDetail } from "@routes/event";
import { AsyncResponseType } from "@typedefs/aliases";
import { Entity } from "@typedefs/graphql";
import { useEffect, useState } from "react";
import { useQuery } from "urql";

interface InitialStateData {
    event: Entity<Event_OneByIdQuery, 'event'>;
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const { id } = EventDetail.useSearchParams();

    const eventQueryContext = useQueryContext(['Event', 'EventSession']);
    const [eventResponse,] = useQuery({
        query: Event_OneByIdDocument,
        variables: {
            id,
        },
        context: eventQueryContext,
    });

    useEffect(() => {
        handleResponse(
            eventResponse,
            {
                onData: (data) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                event: data.event
                            }
                        }
                    })
                },
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [eventResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}