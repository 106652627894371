import { Cn } from "@helpers/unsorted/classNames";
import { TooltipPlacement } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { FormProps } from "@typedefs/props";
import { FieldValues } from "react-hook-form";
import { I18nKey } from "react-i18next";
import { Size } from "../Checkbox/Checkbox";
import { Label } from "../Label/Label";
import {
    useNestedCheckboxGroup,
    CheckboxOption,
    NestedCheckboxOption,
} from "./hook";
import { NestedCheckboxGroupItem } from "./NestedCheckboxGroupItem/NestedCheckboxGroupItem";

const styles = {
    error: Cn.c("text-critical-default font-paragraph-small-regular"),
    questionMark: Cn.c("text-icons-default w-4 h-4"),
};

type Props<FieldsType extends FieldValues, Value> = FormProps<FieldsType> &
    Omit<React.HTMLAttributes<HTMLUListElement>, "children"> & {
        value: Value[];
        valueEquals?: (x: Value, y: Value) => boolean;
        options: NestedCheckboxOption<Value>[];
        optionClassName?: string;
        onChange: (value: Value[]) => void;
        size: Size;
        required?: boolean;
        errorLabel?: I18nKey;
        label?: I18nKey;
        labelClassName?: string;
        tooltip?: I18nKey;
        tooltipPlacement?: TooltipPlacement;
        tooltipIconClassName?: string;
    };

/* Want to do this ideally, but getting type inference issues when Value is a union 
type Props<FieldsType extends FieldValues, Value> = Value extends object
    ? Require<BaseProps<FieldsType, Value>, "valueEquals">
    : BaseProps<FieldsType, Value>;
*/

const NestedCheckboxGroup = <FieldsType extends FieldValues, Value>(
    props: Props<FieldsType, Value>
) => {
    const {
        name,
        value,
        valueEquals = (x, y) => x === y,
        options,
        optionClassName,
        onChange,
        size,
        required = false,
        errors,
        errorLabel,
        label,
        labelClassName,
        tooltip,
        tooltipPlacement,
        tooltipIconClassName,
        ...rest
    } = props;
    const { state, error } = useNestedCheckboxGroup({
        name,
        value,
        valueEquals,
        options,
        errors,
        errorLabel,
        label,
    });

    return (
        <fieldset>
            {label && (
                <Label
                    label={label}
                    labelClassName={labelClassName}
                    required={required}
                    tooltip={tooltip}
                    tooltipPlacement={tooltipPlacement}
                />
            )}
            <ul {...rest}>
                {state.map((tree) => (
                    <NestedCheckboxGroupItem
                        key={tree.value.key}
                        tree={tree}
                        value={value}
                        valueEquals={valueEquals}
                        size={size}
                        depth={0}
                        onChange={onChange}
                        optionClassName={optionClassName}
                    />
                ))}
            </ul>
            {error && <div className={styles.error}>{error}</div>}
        </fieldset>
    );
};

export { NestedCheckboxGroup, CheckboxOption, NestedCheckboxOption };
