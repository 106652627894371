import {
    JobPositionWithCountDocument,
    RecruitmentStageWithCountDocument,
    RecruitmentStepStatusWithCountDocument,
    RecruitmentStepWithCountDocument,
    SchoolWithCountDocument,
} from "@entities";
import {
    displayedRecruitmentStages,
    allClosedStepStatuses,
    allOngoingStepStatuses,
    recruitmentStepStatusGroups,
    ClosedStepStatus,
} from "@helpers/core/constants";
import { assertNever } from '@helpers/typeHelpers';
import { foldForest } from '@helpers/data/tree';
import { isDefined, isOneOf } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { CheckboxOption } from "@shared/unsorted/CheckboxGroup/CheckboxGroup";
import { NestedCheckboxOption } from "@shared/unsorted/NestedCheckboxGroup/NestedCheckboxGroup";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "urql";
import * as value from './value'
import { UseFormReturn, useForm, useWatch } from "react-hook-form";
import { useCurrentLanguage } from "@helpers/core/i18n";
import { Candidate } from "@routes/candidate";

type FilterTextLabelType = string | CheckboxOption;

interface State {
    ongoingRecruitmentStages: value.Decoder.FilterOption<typeof displayedRecruitmentStages[number]>[],
    archivedRecruitmentStepStatuses: value.Decoder.FilterOption<ClosedStepStatus>[]
    recruitmentStepStatusSearchKeyword: string
    currentRecruitmentStepStatusOptions: CheckboxOption[]
    jobPositionSearchKeyword: string
    currentJobPositionOptions: CheckboxOption[]
    recruitmentStepSearchKeyword: string
    currentJobPositionStepsOptions: CheckboxOption[]
    schoolSearchKeyword: string
    currentSchoolOptions: CheckboxOption[]
    candidateStatusSearchKeyword: string;
}

interface ApplicationFilterHookType {
    state: State
    candidateStatusOptions: NestedCheckboxOption<value.Decoder.CandidateStatus>[];
    candidateStatusOptionsCount: number;
    candidateStatusFilterLabel: string | undefined
    currentJobPositionIds: string[]
    onSelectFilter: VoidFunction;
    queryParams: Candidate.QueryParams
    form: UseFormReturn<value.Encoder.Type>
    onSearchRecruitmentStepStatus: (search: string) => void
    onSearchJobPosition: (search: string) => void
    onSearchJobPositionSteps: (search: string) => void
    onSearchSchool: (search: string) => void
    onSearchCandidateStatus: (search: string) => void
    getFilterLabel: (selectedValues: string[], currentOptions: CheckboxOption[]) => FilterTextLabelType | undefined
}

const STEP_STATUS_GROUP_KEYS = ["GENERAL", "DOCUMENT", "EVENT", "ARCHIVE_REASON"] as const
const getStepStatusGroupKey = (group: string) => isOneOf(STEP_STATUS_GROUP_KEYS)(group) ? group : 'UNKNOWN';

// Case insensitive search
const matchSearchKeyword = (label: string, searchKeyword: string): boolean =>
    label.toLowerCase().includes(searchKeyword.toLowerCase())

const formatOptionLabel = (label: string, count: number): string =>
    `${label} (${count})`

const isFilterOptionVisible = <A>(option: value.Decoder.FilterOption<A>, state: A[], searchKeyword: string = ''): boolean => {
    return (
        option.count > 0 ||
        state.some(s => s === option.value)
    ) && matchSearchKeyword(option.label, searchKeyword)
}

const useApplicationFilter = (): ApplicationFilterHookType => {
    const { t } = useTranslation()

    const currentLanguage = useCurrentLanguage()

    const navigate = useNavigate();

    const queryParams = Candidate.useSearchParams();

    const defaultValues = value.Encoder.defaultValues({
        ...queryParams,
    });

    const form = useForm<value.Encoder.Type>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: defaultValues,
    });

    const [state, setState] = useState<State>({
        ongoingRecruitmentStages: [],
        archivedRecruitmentStepStatuses: [],
        recruitmentStepStatusSearchKeyword: "",
        currentRecruitmentStepStatusOptions: [],
        jobPositionSearchKeyword: "",
        currentJobPositionOptions: [],
        recruitmentStepSearchKeyword: "",
        currentJobPositionStepsOptions: [],
        schoolSearchKeyword: "",
        currentSchoolOptions: [],
        candidateStatusSearchKeyword: "",
    })

    const currentCandidateStatuses = useWatch({
        control: form.control,
        name: "candidateStatuses",
    });

    const { ongoing: currentOngoingStages, archived: currentArchivedStepStatuses } = useMemo(() => (
        value.Decoder.foldCandidateStatuses(currentCandidateStatuses)
    ), [currentCandidateStatuses]);

    const currentJobPositionIds = useWatch({
        control: form.control,
        name: 'jobPositionIds',
    })

    const currentOngoingStepStatuses = useWatch({
        control: form.control,
        name: 'ongoingStepStatuses',
    })

    const currentStepIds = useWatch({
        control: form.control,
        name: 'stepIds',
    })

    const currentSchoolIds = useWatch({
        control: form.control,
        name: 'schoolIds',
    })

    const { error: toastError } = useToast();

    const recruitmentStageWithCountQueryContext = useQueryContext(['RecruitmentStageWithCount', 'RecruitmentProcesses', 'RecruitmentProcess'])
    const [recruitmentStageWithCountResponse] = useQuery({
        query: RecruitmentStageWithCountDocument,
        context: recruitmentStageWithCountQueryContext,
        variables: {
            input: {
                jobPositionIds: currentJobPositionIds,
                stepStatuses: currentOngoingStepStatuses,
                stepIds: currentStepIds,
                schoolIds: currentSchoolIds,
            }
        },
    })

    const ongoingRecruitmentStepStatusWithCountQueryContext = useQueryContext(['RecruitmentStepStatusWithCount', 'RecruitmentProcesses', 'RecruitmentProcess', 'Ongoing'])
    const [ongoingRecruitmentStepStatusWithCountResponse] = useQuery({
        query: RecruitmentStepStatusWithCountDocument,
        context: ongoingRecruitmentStepStatusWithCountQueryContext,
        variables: {
            input: {
                applicationType: 'ONGOING',
                jobPositionIds: currentJobPositionIds,
                stepIds: currentStepIds,
                schoolIds: currentSchoolIds,
                and: [
                    {
                        or: [
                            {
                                stages: currentOngoingStages,
                            },
                            {
                                stepStatuses: currentArchivedStepStatuses,
                            }
                        ]
                    }
                ]
            }
        },
    })

    const archivedRecruitmentStepStatusWithCountQueryContext = useQueryContext(['RecruitmentStepStatusWithCount', 'RecruitmentProcesses', 'RecruitmentProcess', 'Archived'])
    const [archivedRecruitmentStepStatusWithCountResponse] = useQuery({
        query: RecruitmentStepStatusWithCountDocument,
        context: archivedRecruitmentStepStatusWithCountQueryContext,
        variables: {
            input: {
                applicationType: 'ARCHIVED',
                jobPositionIds: currentJobPositionIds,
                stepIds: currentStepIds,
                schoolIds: currentSchoolIds,
                and: [
                    {
                        // A little hacky but makes sure we don't have an archived status if an ongoing recruitment status is selected
                        // RecruitmentStepStatusWithCountDocument ignores stepStatuses at the root filter level
                        stepStatuses: currentOngoingStepStatuses,
                    }
                ]
            }
        },
    })

    const jobPositionsWithCountQueryContext = useQueryContext(['JobPositionsWithCount', 'RecruitmentProcesses', 'RecruitmentProcess', 'JobPosition'])
    const [jobPositionsWithCountResponse] = useQuery({
        query: JobPositionWithCountDocument,
        context: jobPositionsWithCountQueryContext,
        variables: {
            input: {
                stepStatuses: currentOngoingStepStatuses,
                stepIds: currentStepIds,
                schoolIds: currentSchoolIds,
                and: [
                    {
                        or: [
                            {
                                stages: currentOngoingStages,
                            },
                            {
                                stepStatuses: currentArchivedStepStatuses,
                            }
                        ]
                    }
                ]
            },
        },
    })

    const RecruitmentStepWithCountQueryContext = useQueryContext(['JobPositionWithCount', 'RecruitmentProcesses', 'RecruitmentProcess', 'JobPosition'])
    const [recruitmentStepWithCountResponse] = useQuery({
        query: RecruitmentStepWithCountDocument,
        context: RecruitmentStepWithCountQueryContext,
        variables: {
            input: {
                stepStatuses: currentOngoingStepStatuses,
                jobPositionIds: currentJobPositionIds,
                schoolIds: currentSchoolIds,
                and: [
                    {
                        or: [
                            {
                                stages: currentOngoingStages,
                            },
                            {
                                stepStatuses: currentArchivedStepStatuses,
                            }
                        ]
                    }
                ]
            },
        },
        pause: currentJobPositionIds.length !== 1,
    })

    const schoolWithCountQueryContext = useQueryContext(['SchoolWithCount', 'RecruitmentProcesses', 'RecruitmentProcess'])
    const [schoolWithCountResponse] = useQuery({
        query: SchoolWithCountDocument,
        context: schoolWithCountQueryContext,
        variables: {
            input: {
                jobPositionIds: currentJobPositionIds,
                stepStatuses: currentOngoingStepStatuses,
                stepIds: currentStepIds,
                schoolName: state.schoolSearchKeyword,
                and: [
                    {
                        or: [
                            {
                                stages: currentOngoingStages,
                            },
                            {
                                stepStatuses: currentArchivedStepStatuses,
                            }
                        ]
                    }
                ]
            }
        },
    })

    // Case insensitive search
    const filterOptions = (options: CheckboxOption[], searchKeyword: string) => {
        const filteredOptions = options.filter((option) => option.type === 'header' || option.label.toLowerCase().includes(searchKeyword.toLowerCase()));

        return filteredOptions.reduce<CheckboxOption[]>((acc, item) => {
            if (item.type === 'header') {
                const hasExistingOptions = filteredOptions.some((opt) => opt.type != 'header' && opt.headerKey === item.key)

                if (hasExistingOptions) {
                    acc.push(item);
                }
            } else {
                acc.push(item);
            }

            return acc;
        }, [])
    }

    const onSearchRecruitmentStepStatus = (search: string) => {
        setState(currentState => ({
            ...currentState,
            recruitmentStepStatusSearchKeyword: search,
        }))
    }

    const onSearchJobPosition = (search: string) => {
        setState(currentState => ({
            ...currentState,
            jobPositionSearchKeyword: search,
        }))
    }

    const onSearchJobPositionSteps = (search: string) => {
        setState(currentState => ({
            ...currentState,
            recruitmentStepSearchKeyword: search,
        }))
    }

    const onSearchSchool = (search: string) => {
        setState(currentState => ({
            ...currentState,
            schoolSearchKeyword: search,
        }))
    }

    const onSearchCandidateStatus = (search: string) => {
        setState(currentState => ({
            ...currentState,
            candidateStatusSearchKeyword: search,
        }))
    }

    const onSelectFilter = () => {
        const { ongoing: stages, archived: archivedStepStatuses } =
            value.Decoder.foldCandidateStatuses(
                form.getValues("candidateStatuses")
            );
        const ongoingStepStatuses = form.getValues("ongoingStepStatuses");
        const schoolIds = form.getValues('schoolIds')
        const jobPositionIds = form.getValues("jobPositionIds");
        const stepIds = form.getValues("stepIds");

        navigate(
            Candidate.toRoute({
                ...queryParams,
                page: 1,
                mode: "list",
                search: {
                    ...queryParams.search,
                },
                stages,
                stepIds,
                ongoingStepStatuses,
                archivedStepStatuses,
                jobPositionIds,
                schoolIds,
            })
        );
    };

    // Note: This approach will only work if the suffix will always just be in this format - (count).
    // In case the suffix changes, this function will need to be updated or the entire implementation of getting the total count needs to be changed.
    const getTotalCount = (options: CheckboxOption[], selectedValues: string[]) => {
        const counts = options.filter(({ value }) => selectedValues.includes(value))
            .map(({ labelSuffix }) => isDefined(labelSuffix) ? parseInt(labelSuffix.replace(/\D/g, '')) : 0);

        return counts.reduce((acc, curr) => acc + curr, 0);
    };

    const getFilterLabel = (selectedValues: string[], currentOptions: CheckboxOption[]): FilterTextLabelType | undefined => {
        if (currentOptions.length === 0) {
            return undefined;
        }

        if (selectedValues.length > 1) {
            return formatOptionLabel(t(`applications.list.selected`, { count: selectedValues.length }), getTotalCount(currentOptions, selectedValues))
        } else if (selectedValues.length === 1) {
            return currentOptions.find(({ value }) => value === selectedValues[0]);
        }

        return undefined;
    };

    const candidateStatusFilterLabel = useMemo(() => {
        const selectedOptions = currentCandidateStatuses.flatMap((value): value.Decoder.FilterOption<unknown>[] => {
            switch (value.type) {
                case 'ongoing': return state.ongoingRecruitmentStages.filter(option => value.value === option.value)
                case 'archived': return state.archivedRecruitmentStepStatuses.filter(option => value.value === option.value)
                default: return (assertNever(value), [])
            }
        });
        const [head, ...rest] = selectedOptions

        if (rest.length > 0) {
            return formatOptionLabel(t(`applications.list.selected`, { count: selectedOptions.length }), value.Decoder.sumFilterOptionsCount(selectedOptions))
        } else if (head) {
            return formatOptionLabel(head.label, head.count)
        }
    }, [currentCandidateStatuses, state.ongoingRecruitmentStages, state.archivedRecruitmentStepStatuses, t]);

    const candidateStatusOptions: NestedCheckboxOption<value.Decoder.CandidateStatus>[] =
        useMemo(
            () => {
                const filteredOngoingRecruitmentStages = state.ongoingRecruitmentStages.filter(
                    option => isFilterOptionVisible(option, currentOngoingStages, state.candidateStatusSearchKeyword)
                );
                const filteredArchivedRecruitmentStepStatuses = state.archivedRecruitmentStepStatuses.filter(
                    option => isFilterOptionVisible(option, currentArchivedStepStatuses, state.candidateStatusSearchKeyword)
                );

                return [
                    {
                        value: {
                            type: "group",
                            label: formatOptionLabel(
                                t(`applications.filters.candidateStatusOptions.ONGOING`),
                                value.Decoder.sumFilterOptionsCount(filteredOngoingRecruitmentStages)
                            ),
                        },
                        children: filteredOngoingRecruitmentStages.map(option => ({
                            value: {
                                type: "value",
                                label: formatOptionLabel(option.label, option.count),
                                value: {
                                    type: "ongoing",
                                    value: option.value,
                                },
                            },
                            children: []
                        }))
                    },
                    {
                        value: {
                            type: "group",
                            label: formatOptionLabel(
                                t(`applications.filters.candidateStatusOptions.ARCHIVED`),
                                value.Decoder.sumFilterOptionsCount(filteredArchivedRecruitmentStepStatuses)
                            ),
                        },
                        children: filteredArchivedRecruitmentStepStatuses.map(option => ({
                            value: {
                                type: "value",
                                label: formatOptionLabel(option.label, option.count),
                                value: {
                                    type: "archived",
                                    value: option.value,
                                },
                            },
                            children: []
                        }))
                    },
                ]
            },
            [t, state.ongoingRecruitmentStages, state.archivedRecruitmentStepStatuses, state.candidateStatusSearchKeyword, currentOngoingStages, currentArchivedStepStatuses]
        );

    const candidateStatusOptionsCount = useMemo(() => {
        return foldForest(
            candidateStatusOptions,
            (acc, option) => option.type === 'value' ? acc + 1 : acc,
            0
        )
    }, [candidateStatusOptions]);

    useEffect(() => {
        handleResponse(jobPositionsWithCountResponse, {
            onData: (data) => {
                const jobPositionOptions = data.jobPositionWithCount
                    .filter(item => item.count > 0 || currentJobPositionIds.includes(item.jobPositionId))
                    .map((job) => ({
                        key: job.jobPositionId,
                        label: job.jobTitle,
                        labelSuffix: `(${job.count})`,
                        value: job.jobPositionId,
                        disabled: false,
                    }));

                setState(currentState => ({
                    ...currentState,
                    currentJobPositionOptions: filterOptions(jobPositionOptions, currentState.jobPositionSearchKeyword),
                }));
            },
            onError: (_error) => {
                toastError("global.error");
                setState(currentState => ({
                    ...currentState,
                    currentJobPositionOptions: [],
                }));
            },
        });
    }, [jobPositionsWithCountResponse, state.jobPositionSearchKeyword, currentJobPositionIds]);

    useEffect(() => {
        handleResponse(recruitmentStepWithCountResponse, {
            onData: (data) => {
                const steps = data.recruitmentStepWithCount.filter((step) => (
                    step.stage !== 'ARCHIVE' &&
                    (step.count > 0 || currentStepIds.includes(step.stepId))
                ));

                const stepsOptions: CheckboxOption[] = steps.reduce<CheckboxOption[]>((options, recruitmentStep) => {
                    const stageExists = options.some(option => option.value === recruitmentStep.stage);

                    !stageExists && options.push({
                        key: recruitmentStep.stage,
                        value: recruitmentStep.stage,
                        label: t(`jobPositions.panel.recruitmentFlow.stage.${recruitmentStep.stage}.name`),
                        disabled: true,
                        type: 'header',
                    })

                    options.push({
                        key: recruitmentStep.stepId,
                        value: recruitmentStep.stepId,
                        label: recruitmentStep.stepName !== 'Recruitment Result' ? recruitmentStep.stepName : t('recruitmentStepType.RecruitmentResult'),
                        labelSuffix: `(${recruitmentStep.count})`,
                        disabled: false,
                        headerKey: recruitmentStep.stage, // This should be the same as the above header key
                    })

                    return options
                }, [])

                setState(currentState => ({
                    ...currentState,
                    currentJobPositionStepsOptions: filterOptions(stepsOptions, currentState.recruitmentStepSearchKeyword),
                }))
            },
            onError: (_error) => {
                toastError('global.error');
                setState(currentState => ({
                    ...currentState,
                    currentJobPositionStepsOptions: [],
                }))
            },
        });
    }, [recruitmentStepWithCountResponse, state.recruitmentStepSearchKeyword, currentStepIds]);

    useEffect(() => {
        handleResponse(recruitmentStageWithCountResponse, {
            onData: (data) => {
                const stageOptions = data.recruitmentStageWithCount.flatMap((item) => {
                    if (!isOneOf(displayedRecruitmentStages)(item.stage)) {
                        return [];
                    }

                    return [{
                        value: item.stage,
                        label: t(`recruitmentStage.${item.stage}`),
                        count: item.count,
                    }]
                }, [])

                setState(currentState => ({
                    ...currentState,
                    ongoingRecruitmentStages: stageOptions,
                }));
            },
            onError: (_error) => {
                toastError('global.error');
                setState(currentState => ({
                    ...currentState,
                    ongoingRecruitmentStages: [],
                }));
            },
        });
    }, [recruitmentStageWithCountResponse, currentLanguage]);

    useEffect(() => {
        handleResponse(ongoingRecruitmentStepStatusWithCountResponse, {
            onData: (data) => {
                const nonEmptyStepStatuses = data.recruitmentStepStatusWithCount.filter(item => (
                    isOneOf(allOngoingStepStatuses)(item.status) &&
                    (item.count > 0 || currentOngoingStepStatuses.includes(item.status))
                ))

                const recruitmentStepStatusOptions: CheckboxOption[] = Object.entries(recruitmentStepStatusGroups)
                    .flatMap(([group, stepStatuses]) => {
                        const groupName = getStepStatusGroupKey(group);

                        if (groupName !== 'UNKNOWN') {
                            const groupOption: CheckboxOption = {
                                key: group,
                                label: t(`applications.filters.recruitmentStepStatusGroups.${groupName}`),
                                value: groupName,
                                disabled: true,
                                type: 'header'
                            };

                            const stepStatusOptions = nonEmptyStepStatuses.reduce<CheckboxOption[]>((acc, item) => {
                                if (isOneOf(stepStatuses)(item.status)) {
                                    acc.push({
                                        key: item.status,
                                        label: t(`recruitmentStepStatusV2.${item.status}`),
                                        labelSuffix: `(${item.count})`,
                                        value: item.status,
                                        disabled: false,
                                        headerKey: group, // This should be the same as the groupOption.key
                                    });
                                }

                                return acc;
                            }, [])

                            return [groupOption, ...stepStatusOptions];
                        }

                        return [];
                    });

                setState(currentState => ({
                    ...currentState,
                    currentRecruitmentStepStatusOptions: filterOptions(recruitmentStepStatusOptions, currentState.recruitmentStepStatusSearchKeyword)
                }));
            },
            onError: (_error) => {
                toastError('global.error');
                setState(currentState => ({
                    ...currentState,
                    currentRecruitmentStepStatusOptions: [],
                }));
            },
        });
    }, [ongoingRecruitmentStepStatusWithCountResponse, state.recruitmentStepStatusSearchKeyword, currentLanguage, currentOngoingStepStatuses]);

    useEffect(() => {
        handleResponse(archivedRecruitmentStepStatusWithCountResponse, {
            onData: (data) => {
                const archivedStepStatusOptions = data.recruitmentStepStatusWithCount.flatMap((item) => {
                    if (!isOneOf(allClosedStepStatuses)(item.status)) {
                        return []
                    };

                    return [{
                        value: item.status,
                        count: item.count,
                        label: t(`recruitmentStepStatusV2.${item.status}`)
                    }]
                })

                setState(currentState => ({
                    ...currentState,
                    archivedRecruitmentStepStatuses: archivedStepStatusOptions,
                }));
            },
            onError: (_error) => {
                toastError('global.error');
                setState(currentState => ({
                    ...currentState,
                    archivedRecruitmentStepStatuses: [],
                }));
            },
        });
    }, [archivedRecruitmentStepStatusWithCountResponse, t]);

    useEffect(() => {
        handleResponse(schoolWithCountResponse, {
            onData: (data) => {
                const schoolOptions = data.schoolWithCount
                    .filter(item => item.count > 0 || currentSchoolIds.includes(item.schoolId))
                    .map((school) => ({
                        key: school.schoolId,
                        label: school.schoolName,
                        labelSuffix: `(${school.count})`,
                        value: school.schoolId,
                        disabled: false,
                    }));

                setState(currentState => ({
                    ...currentState,
                    currentSchoolOptions: schoolOptions,
                }));
            },
            onError: (_error) => {
                toastError('global.error');
                setState(currentState => ({
                    ...currentState,
                    currentSchoolOptions: [],
                }));
            },
        });
    }, [schoolWithCountResponse, state.schoolSearchKeyword, currentSchoolIds]);

    useEffect(() => {
        if (currentJobPositionIds.length !== 1 && currentStepIds.length > 0) {
            form.setValue('stepIds', []);

            const stepIds = form.getValues('stepIds');
            // Navigate is called to reset the step_ids URL param when the selected job position IDs are changed.
            // Not clearing the step_ids could lead to a situation where, together with the other filters, will deem the query invalid; and could result to an empty candidate list.
            // However, this approach could lead to a navigate call every time the
            // job position IDs are changed - even just by checking or unchecking a job position filter option.
            // This should be improved.
            navigate(Candidate.toRoute({
                ...queryParams,
                page: 1,
                search: {
                    ...queryParams.search,
                },
                stepIds,
                mode: 'list'
            }));
        }
    }, [currentJobPositionIds]);

    return {
        state: { ...state },
        candidateStatusOptions,
        candidateStatusOptionsCount,
        candidateStatusFilterLabel,
        currentJobPositionIds,
        onSelectFilter,
        queryParams,
        form,
        onSearchRecruitmentStepStatus,
        onSearchJobPosition,
        onSearchJobPositionSteps,
        onSearchSchool,
        onSearchCandidateStatus,
        getFilterLabel,
    }
}

export {
    useApplicationFilter
}
