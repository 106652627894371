import { useClient } from '@helpers/hooks/unsorted/clientHook';
import { showFullName } from '@helpers/core/client';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { Loader } from '@shared/unsorted/Loader/Loader';
import { Pagination } from '@shared/unsorted/Pagination/Pagination';
import { Select } from '@shared/unsorted/Select/Select';
import { Sort } from '@shared/unsorted/Sort/Sort';
import { FunctionComponent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TabHeader } from '../TabHeader/TabHeader';
import { getRoleKey, useSettings } from '../hook';
import { getStatusKey, useUserManagement } from './hook';
import { Search } from '@shared/unsorted/Search/Search';
import { Badge } from '@shared/unsorted/Badge/Badge';

const styles = {
    subHeaderSection: Cn.c('flex flex-row items-center justify-between flex-shrink-0 pb-2 bg-surface-default'),
    searchForm: Cn.c('w-[25rem] min-w-max'),
    searchResult: Cn.c('flex items-baseline space-x-2'),
    emphasizedText: Cn.c('font-paragraph-small-semi-bold text-default'),
    normalText: Cn.c('font-paragraph-small-medium text-subdued'),
    emptyTable: Cn.c('w-full flex flex-col items-center justify-center bg-surface-default min-h-empty-table border border-subdued'),
    tableInnerContainer: Cn.c('bg-surface-default border border-subdued flex overflow-auto'),
    table: Cn.c('table-sticky'),
    tableTH: Cn.c('table-thead-row table-th-sticky top-0'),
    tableTBodyRow: (selected: boolean) =>
        Cn.join([
            Cn.c('table-tbody-row'),
            selected ? Cn.c('bg-surface-subdued') : Cn.c('bg-surface-default'),
        ]),
    userInfoCell: Cn.c('table-td w-1/4'),
    pendingUser: Cn.c('text-disabled'),
    paginationWrapper: Cn.c('py-4 flex justify-between items-end grow'),
    paginationLeft: Cn.c('flex items-center space-x-2'),
    rowsPerPage: Cn.c('font-paragraph-xsmall-regular text-subdued'),
    rowsPerPageSelect: Cn.c("w-16"),
};

const UserManagement: FunctionComponent = () => {
    const { t } = useTranslation();
    const { currentClient } = useClient();

    const { state, openUserInviteModal, openUserInfoModal } = useSettings();
    const {
        searchKeyword,
        onSubmitSearch,
        sortOptions,
        currentSortId,
        onSort,
        rowsPerPage,
        rowsPerPageOptions,
        changeRowsPerPage,
        currentPage,
        changePage,
        getPageCount,
    } = useUserManagement();

    const { displayMode } = state;

    const rowsRange = useMemo(() => {
        if (!state.loading) {
            if (state.totalClientsCount > 0) {
                return {
                    startRowIndex: rowsPerPage * (currentPage - 1) + 1,
                    endRowIndex: rowsPerPage * currentPage >= state.totalClientsCount ? state.totalClientsCount : rowsPerPage * currentPage,
                };
            }

            return { startRowIndex: 0, endRowIndex: 0 };
        }
    }, [
        rowsPerPage,
        currentPage,
        !state.loading && state.totalClientsCount,
    ]);

    const canAddUser = currentClient.clientState === 'loggedIn' &&
        (currentClient.clientInfo.isScovilleUser || currentClient.clientInfo.isScovilleAdmin || currentClient.clientInfo.isAdmin);

    return (
        <>
            <TabHeader
                title="settings.userManagement.title"
                action={canAddUser ?
                    <Button
                        size="md"
                        iconName="plus"
                        onClick={openUserInviteModal}
                    >
                        {t("settings.userManagement.addUser")}
                    </Button>
                    : undefined
                }
            />
            {state.loading
                ? <Loader />
                : <>
                    <div className={styles.subHeaderSection}>
                        <Search
                            keyword={searchKeyword}
                            onSearchAction={onSubmitSearch}
                        />
                        <div className={styles.searchResult}>
                            <p className={styles.normalText}>
                                {/* <span className={styles.emphasizedText}>{rowsRange}</span>
                                <span className={styles.normalText}> {t("settings.userManagement.list.of")} </span>
                                <span className={styles.emphasizedText}>{state.totalClientsCount}</span>
                                <span className={styles.normalText}> {t("settings.userManagement.list.users")}</span> */}
                                <Trans
                                    i18nKey={"settings.userManagement.list.usersCount"}
                                    count={state.totalClientsCount}
                                    values={{
                                        startRowIndex: rowsRange?.startRowIndex,
                                        endRowIndex: rowsRange?.endRowIndex,
                                    }}
                                    components={{ highlight: <span className={styles.emphasizedText}></span> }}
                                />
                            </p>
                            <p className={styles.emphasizedText}>|</p>
                            <p className={styles.normalText}>{t("settings.userManagement.list.orderBy")}</p>
                            <Sort
                                items={sortOptions}
                                currentSortId={currentSortId}
                                onSelect={onSort}
                            />
                        </div>
                    </div>
                    <div className={styles.tableInnerContainer}>
                        <table className={styles.table} >
                            <thead>
                                <tr>
                                    <th className={styles.tableTH}>{t("settings.userManagement.list.table.username")}</th>
                                    <th className={styles.tableTH}>{t("settings.userManagement.list.table.email")}</th>
                                    <th className={styles.tableTH}>{t("settings.userManagement.list.table.role")}</th>
                                    <th className={styles.tableTH}>{t("settings.userManagement.list.table.status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.clients.length === 0 ?
                                    <tr>
                                        <td colSpan={4}>
                                            <div className={Cn.join([styles.emptyTable])}>
                                                <EmptyStateContainer
                                                    icon="notFound"
                                                    description="settings.userManagement.list.emptySearch.description"
                                                    subDescription="settings.userManagement.list.emptySearch.subDescription">
                                                </EmptyStateContainer>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    state.clients.filter(client => {
                                        if (currentClient.clientState === 'loggedIn') {
                                            const { clientInfo: currentClientInfo } = currentClient;
                                            if (currentClientInfo.isScovilleUser || currentClientInfo.isScovilleAdmin) {
                                                return true;
                                            }
                                        }

                                        return client.roles.some(role => !['Scoville', 'ScovilleAdmin'].includes(role.name));
                                    }).map(client =>
                                        <tr
                                            className={styles.tableTBodyRow(displayMode.name === "show"
                                                && displayMode.payload.client.__typename === "RegisteredClient"
                                                && displayMode.payload.client.id === client.id)
                                            }
                                            key={client.id}
                                            onClick={_event => openUserInfoModal(client.id)}
                                        >
                                            <td className={styles.userInfoCell}>
                                                {client.__typename === "RegisteredClient"
                                                    ? showFullName(t, client)
                                                    : <span className={styles.pendingUser}>{t("settings.userManagement.list.pending")}</span>
                                                }
                                            </td>
                                            <td className={styles.userInfoCell}> {client.email} </td>
                                            <td className={styles.userInfoCell}>
                                                {client.roles.map(({ name }) => t(`settings.userManagement.list.role.${getRoleKey(name)}`))
                                                    .join(", ")
                                                }
                                            </td>
                                            <td className={styles.userInfoCell}>
                                                <Badge
                                                    variant={client.status === "INVITED" ? "warn" : "success"}
                                                    label={`settings.userManagement.list.status.${getStatusKey(client.status)}`}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody >
                        </table >
                    </div >
                    <div className={styles.paginationWrapper}>
                        <div className={styles.paginationLeft}>
                            <Select
                                name="rowsPerPage"
                                options={rowsPerPageOptions}
                                onChange={changeRowsPerPage}
                                value={rowsPerPage.toString()}
                                size="small"
                                direction="upward"
                                className={styles.rowsPerPageSelect}
                                rawPlaceholder={rowsPerPage.toString()}
                                noEmpty
                            />
                            <div className={styles.rowsPerPage}>{t("settings.userManagement.list.rowsPerPage")}</div>
                        </div>
                        <Pagination pageCount={getPageCount(state.totalClientsCount)} currentPage={currentPage} onSelectPage={changePage} />
                    </div>
                </>
            }
        </>
    );
};

export {
    UserManagement
};
