import { useParams } from "react-router-dom";

export namespace EventDetail {
    export const PATH_NAME = "/events/:id"

    interface QueryParams {
        id: string;
    }

    export const useSearchParams = (): QueryParams => {
        const { id } = useParams();

        return {
            id: id || ''
        }
    }
}