import { z } from 'zod';
import { startOfDay, startOfWeek, startOfMonth, subMonths, subYears } from 'date-fns';
import { assertNever } from '@helpers/typeHelpers';

export const dateRangeTypeSchema = z.enum([
    'all',
    'today',
    'thisWeek',
    'thisMonth',
    'within3Months',
    'within6Months',
    'within1Year',
    'atleast1Year',
]);

export type DateRangeType = z.infer<typeof dateRangeTypeSchema>;

export type DateRange = {
    from: Date | undefined;
    to: Date | undefined;
}

export const dateRangeTypeToDateRange = (label: DateRangeType, now: Date): DateRange => {
    switch (label) {
        case 'all': {
            return { from: undefined, to: undefined };
        }
        case 'today': {
            return { from: startOfDay(now), to: now };
        }
        case 'thisWeek': {
            return { from: startOfWeek(now), to: now };
        }
        case 'thisMonth': {
            return { from: startOfMonth(now), to: now };
        }
        case 'within3Months': {
            return { from: subMonths(now, 3), to: now };
        }
        case 'within6Months': {
            return { from: subMonths(now, 6), to: now };
        }
        case 'within1Year': {
            return { from: subYears(now, 1), to: now };
        }
        case 'atleast1Year': {
            return { from: undefined, to: subYears(now, 1) };
        }
        default: {
            assertNever(label);
            return { from: undefined, to: undefined };
        }
    }
}

export type ActivityLogFilter = {
    dateRange: DateRangeType;
}

