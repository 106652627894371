import { Ref, useEffect } from 'react';

import { hasProperty, isNull } from '@helpers/core/typeGuards';

const useClickOutside = (
    refs: Ref<HTMLElement>[],
    onClickOutside: VoidFunction,
) => {
    const onClick = (event: MouseEvent) => {
        const clickedElement = event.target;
        if (clickedElement instanceof Element) {
            if (refs.some(ref => !hasProperty('current')(ref) || isNull(ref.current) || !ref.current.contains(clickedElement))) {
                onClickOutside();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mousedown', onClick);

        return () => window.removeEventListener('mousedown', onClick);
    });
};

export {
    useClickOutside,
};
