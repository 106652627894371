import { DeleteEventDocument, Event_OneByIdQuery } from '@entities';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';
import { JobPositionDetail } from '@routes/jobPosition';
import { Entity } from '@typedefs/graphql';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

const useEventDetailViewMode = (event: Entity<Event_OneByIdQuery, 'event'>) => {
    const { error: toastError, success: toastSuccess } = useToast();

    const nagivate = useNavigate()

    const [isAddEventSessionModalOpen, setIsAddEventSessionModalOpen] = useState(false);

    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

    const closeDeleteConfirmationModal = () => setIsDeleteConfirmationModalOpen(false);

    const openDeleteConfirmationModal = () => setIsDeleteConfirmationModalOpen(true);

    const [deleteEventResponse, deleteEvent] = useMutation(DeleteEventDocument);

    const openAddEventSessionModal = () => setIsAddEventSessionModalOpen(true);

    const closeAddEventSessionModal = () => setIsAddEventSessionModalOpen(false);

    const onProceedDelete = (id: string) => {
        deleteEvent({ id }, getQueryContext('Event'))
        nagivate(generatePath(JobPositionDetail.PATH_NAME, { id: event.recruitmentStepV2.jobPosition.id }))
    }

    useEffect(() => {
        handleResponse(deleteEventResponse, {
            onData: (data) => {
                if (data.deleteEvent) {
                    setIsDeleteConfirmationModalOpen(false)
                    toastSuccess('event.eventDeleteConfirmationModal.deleteEventSuccessfully')
                }
            },
            onError: () => {
                setIsDeleteConfirmationModalOpen(false)
                toastError('event.eventDeleteConfirmationModal.deleteEventFailed')
            }
        })
    }, [deleteEventResponse])

    return {
        isAddEventSessionModalOpen,
        openAddEventSessionModal,
        closeAddEventSessionModal,
        isDeleteConfirmationModalOpen,
        closeDeleteConfirmationModal,
        openDeleteConfirmationModal,
        onProceedDelete,
    };
};

export {
    useEventDetailViewMode
};
