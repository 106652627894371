import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout"
import { FunctionComponent } from "react"
import { ManuallyAddCandidateForm } from "./ManuallyAddCandidateForm/ManuallyAddCandidateForm"
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader"
import { useManualAddPage } from "./hook"
import { Button } from "@shared/unsorted/Button/Button"
import { useTranslation } from "react-i18next"
import { Cn } from "@helpers/unsorted/classNames"

const styles = {
    title: Cn.c("px-6 pt-6 font-h2-bold text-emphasized"),
}

const ManualAddPage: FunctionComponent = () => {
    const { t } = useTranslation()

    const {
        switchToCSVImport,
        switchToTop
    } = useManualAddPage();

    return (
        <DefaultLayout>
            <PageHeader
                action={[
                    <Button
                        key={1}
                        size="sm"
                        variant="primaryOutline"
                        onClick={switchToCSVImport}
                    >
                        {t('applications.create.manualInput.switchToCSVImport')}
                    </Button>
                ]}
            >
                <Button
                    size="md"
                    variant="ghost"
                    isTrailingIcon={false}
                    iconName="arrowLeftLine"
                    onClick={switchToTop}
                >
                    {t('applications.create.manualInput.backToCandidateTop')}
                </Button>
            </PageHeader>
            <p className={styles.title}>{t("applications.create.manualInput.title")}</p>
            <ManuallyAddCandidateForm />
        </DefaultLayout>
    )
}

export {
    ManualAddPage
}