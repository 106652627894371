import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ToggleSwitch } from "@shared/unsorted/ToggleSwitch/ToggleSwitch";
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { useEventSessionGroupRemoveCandidateAssignmentModal } from "./hook";
import { SubMode as EventSessionGroupManipulateCandidateSubMode } from "../EventSessionGroupManipulateCandidateModal/hook";
import { getWithDefault } from "@helpers/unsorted/stringExtra";

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-critical-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-critical-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-critical-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    cancellationEmailContainer: Cn.c("flex items-center px-6"),
    cancellationEmail: Cn.c("ml-4 mr-1 font-paragraph-small-regular text-emphasized"),
    questionMark: Cn.c("w-5 h-5"),
    buttons: Cn.c("mt-6 pt-6 flex space-x-3 justify-end px-6 border-t border-default")
}

interface Props extends ModalProps {
    closeModal: VoidFunction;
    subMode: EventSessionGroupManipulateCandidateSubMode;
}

const EventSessionGroupRemoveCandidateAssignmentModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    subMode,
}) => {
    const { t } = useTranslation();

    const {
        isSendEmailChecked,
        isSubmitting,
        toggleCheckSendEmail,
        onSubmit,
    } = useEventSessionGroupRemoveCandidateAssignmentModal(closeModal, subMode)

    return (
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t('event.eventSessionGroupRemoveCandidateModal.description')}</p>
                    <p className={styles.subDescription}>{
                        t('event.eventSessionGroupRemoveCandidateModal.subDescription',
                            {
                                candidateName: getWithDefault(subMode.name === 'remove' ? subMode.payload.candidateName : undefined)
                            })}</p>
                </div>
                {/* TODO: we are not sure to entirely drop this feature, so I only commented it out and wait */}
                {/* <div className={styles.cancellationEmailContainer}>
                    <ToggleSwitch
                        id="cancellationEmail"
                        checked={isSendEmailChecked}
                        size="sm"
                        onChange={toggleCheckSendEmail}
                    />
                    <div className={styles.cancellationEmail}>{t('event.eventSessionGroupRemoveCandidateModal.cancellationEmail')}</div>
                    <ToolTipBox
                        tooltipText={t('event.eventSessionGroupRemoveCandidateModal.cancellationEmailTooltip')}
                        placement="top">
                        <div><Icon name="questionMark" className={styles.questionMark} /></div>
                    </ToolTipBox>
                </div> */}
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={closeModal}
                        isDisabled={isSubmitting}
                    >
                        {t('global.cancel')}
                    </Button>
                    <Button
                        variant="destructiveFilled"
                        size="md"
                        onClick={onSubmit}
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                    >
                        {t('global.remove')}
                    </Button>
                </div>
            </div>
        </Modal>
    );


}

export {
    EventSessionGroupRemoveCandidateAssignmentModal
}