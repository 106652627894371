import { RecruitmentProcessHistoryEventStep, RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from './hook';
import { EvaluationLabel } from './EvaluationLabel/EvaluationLabel';
import { ApplicationModalMode } from '@pages/ApplicationsPage/reducer';

const styles = {
    row: Cn.c('flex gap-x-2.5'),
    section: Cn.c('flex gap-x-2 items-center'),
    label: Cn.c('font-paragraph-small-regular text-subdued w-20 shrink-0'),
    text: Cn.c('font-paragraph-small-regular text-default'),
    evaluationLink: Cn.c('cursor-pointer text-primary-default underline'),
};

interface Props {
    applicationId: string
    history: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.history'>;
    isCurrentStep: boolean;
    isStepCompleted: boolean;
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
}

const EventHistory: FunctionComponent<Props> = ({ applicationId, history, isCurrentStep, isStepCompleted, dispatchApplicationModalMode }) => {
    const { t } = useTranslation();

    const { hasEvaluation, hasDecision, event, evaluations, decision } = history as RecruitmentProcessHistoryEventStep;

    const { schedule, examiners, decision: decisionText } = useHistory({
        isCurrentStep,
        isStepCompleted,
        hasEvaluation,
        hasDecision,
        eventSchedule: event.schedule,
        examinersHistory: event.examiners,
        decisionHistory: decision,
    });

    return (
        <>
            <div className={styles.row}>
                <div className={styles.section}>
                    <div className={styles.label}>{t("applications.panel.candidateHistory.labels.schedule")}</div>
                    <div className={styles.text}>{schedule}</div>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.section}>
                    <div className={styles.label}>{t("applications.panel.candidateHistory.labels.examiners")}</div>
                    <div className={styles.text}>{examiners}</div>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.section}>
                    <div className={styles.label}>{t("applications.panel.candidateHistory.labels.evaluation")}</div>
                    <div className={styles.text}>
                        <EvaluationLabel
                            applicationId={applicationId}
                            stepId={history.id}
                            evaluations={evaluations}
                            dispatchApplicationModalMode={dispatchApplicationModalMode}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.section}>
                    <div className={styles.label}>{t("applications.panel.candidateHistory.labels.decision")}</div>
                    <div className={styles.text}>{decisionText}</div>
                </div>
            </div>
        </>
    );
};

export {
    EventHistory,
};