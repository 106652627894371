import { ExternalEmailSignatureDocument, InternalEmailSignatureDocument } from "@entities";
import { useQueryContext } from "@helpers/unsorted/urqlExtra";
import { AsyncResponseType } from "@typedefs/aliases";
import { useEffect, useState } from "react"
import { useQuery } from "urql";

type InitialStateData = {
    emailSignatures: {
        internalEmailSignature?: string | null;
        externalEmailSignature?: string | null;
    }
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const internalEmailSignatureContext = useQueryContext(['InternalEmailSignature']);
    const [internalEmailSignatureResponse,] = useQuery({
        query: InternalEmailSignatureDocument,
        context: internalEmailSignatureContext,
    });

    const externalEmailSignatureContext = useQueryContext(['ExternalEmailSignature']);
    const [externalEmailSignatureResponse,] = useQuery({
        query: ExternalEmailSignatureDocument,
        context: externalEmailSignatureContext,
    });

    const asyncResponses = [
        internalEmailSignatureResponse,
        externalEmailSignatureResponse
    ]

    useEffect(() => {
        const isFinishedFetching = asyncResponses.every(response => !response.fetching);

        if (isFinishedFetching) {
            const isAnyResponseError = asyncResponses.some(response => response.error);

            if (isAnyResponseError) {
                setInitialState({
                    isLoading: false,
                    result: {
                        isSuccess: false
                    }
                })
            } else {
                const internalEmailSignature = internalEmailSignatureResponse.data?.internalEmailSignature;
                const externalEmailSignature = externalEmailSignatureResponse.data?.externalEmailSignature;

                setInitialState({
                    isLoading: false,
                    result: {
                        isSuccess: true,
                        data: {
                            emailSignatures: {
                                internalEmailSignature,
                                externalEmailSignature
                            }
                        }
                    }
                })
            }
        }
    }, asyncResponses);

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}