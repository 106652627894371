import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react"
import { useDeselectButton } from "./hook";
import { useTranslation } from "react-i18next";
import { SimpleApplication } from "@typedefs/aliases";

const styles = {
    container: Cn.c("flex relative justify-center"),
    deselectCheckbox: Cn.c("w-6 h-6 text-icons-on-primary cursor-pointer"),
    optionContainer: Cn.c("absolute left-1/4 top-full bg-surface-default py-2 flex flex-col space-y-2 rounded-lg shadow-md"),
    option: Cn.c("cursor-pointer text-emphasized font-paragraph-xsmall-regular whitespace-nowrap py-2 px-4 hover:bg-surface-hovered-default"),
}

interface Props {
    currentPageApplications: SimpleApplication[];
}

const DeselectButton: FunctionComponent<Props> = ({
    currentPageApplications
}) => {
    const { t } = useTranslation();

    const {
        numberOfSelectedApplications,
        numberOfSelectedApplicationsOnThisPage,
        deselectAll,
        deselectAllApplicationsOnThisPage,
        onClickDeselectButton,
        isDeselectMenuShown,
        deselectMenuRef,
    } = useDeselectButton(currentPageApplications)

    return (
        <div className={styles.container}>
            <Icon name="deselect" className={styles.deselectCheckbox} onClick={onClickDeselectButton} />
            {isDeselectMenuShown && <div className={styles.optionContainer} ref={deselectMenuRef}>
                {numberOfSelectedApplicationsOnThisPage > 0 &&
                    <div className={styles.option} onClick={deselectAllApplicationsOnThisPage}>
                        {t('applications.list.table.deselectApplicationOnThisPage', { count: numberOfSelectedApplicationsOnThisPage })}
                    </div>
                }
                <div className={styles.option} onClick={deselectAll}>
                    {t('applications.list.table.deselectAllApplication', { count: numberOfSelectedApplications })}
                </div>
            </div>}
        </div>

    );
}

export {
    DeselectButton
}