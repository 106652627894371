import { Client_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        id: string;
        firstName: string;
        lastName: string;
        firstNameKana: string;
        lastNameKana: string;
        email: string;
        roleIds: string[];
    }

    export const defaultValues = ({ id, firstName, lastName, firstNameKana, lastNameKana, email, roles }: {
        id: string,
        firstName: string,
        lastName: string,
        firstNameKana: string,
        lastNameKana: string,
        email: string,
        roles: Entity<Client_OneByIdQuery, 'client.roles'>[],
    }): EncoderType => {
        return {
            id,
            firstName,
            lastName,
            firstNameKana,
            lastNameKana,
            email,
            roleIds: roles.map(({ id }) => id),
        };
    };
}

namespace Decoder {
    const idSchema = z.string().uuid();

    export const schema = z.object({
        id: idSchema,
        email: z.string().email(),
        firstName: z.string(),
        lastName: z.string(),
        firstNameKana: z.string(),
        lastNameKana: z.string(),
        roleIds: z.array(idSchema).min(1),

    });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Decoder, Encoder
};
