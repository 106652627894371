import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import Cookie from 'js-cookie'
import { CurrentClientQuery } from '@entities'
import { Client as UrlqClient } from 'urql'
import * as Sentry from '@sentry/react';

import config from '../../../helpers/core/config'
import auth from '../../../helpers/core/auth'
import { createUrqlClient } from '@helpers/core/createClient'
import { DocumentUpload, EventRegistration } from '@routes/unauthenticated';
import { isDefined } from '@helpers/core/typeGuards';
import { useFakeProgress } from '@helpers/hooks/unsorted/fakeProgressHook';

interface Root {
    clientState: [CurrentClientQuery, Dispatch<SetStateAction<CurrentClientQuery>>];
    graphqlClient: UrlqClient;
    loadingProgress: number;
    showLoadingScreen: boolean;
}

const unauthenticatedRoutes = ['/dev/login', EventRegistration.PATH_NAME, DocumentUpload.PATH_NAME]

const useRoot = (): Root => {
    const [client, setClient] = useState<CurrentClientQuery>({ currentClient: undefined })

    const [graphqlClient, setGraphqlClient] = useState<UrlqClient>(createUrqlClient())

    const clientStatus = useMemo(() => client.currentClient?.status, [client.currentClient])

    const accessToken = client.currentClient?.accessToken

    const { start: startProgress, stop: stopProgress, progress, minDurationReached } = useFakeProgress({ timeConstant: 1000, minDuration: 2000 })
    const loadingProgress = Math.round(progress * 100);

    const isClientLoggedIn = useMemo(() => {
        return isDefined(client.currentClient)
    }, [client.currentClient])

    const [showLoadingScreen, setShowLoadingScreen] = useState(true);

    useEffect(() => {
        if (isClientLoggedIn && (minDurationReached === true || loadingProgress === 100)) {
            stopProgress()
            setShowLoadingScreen(false)
        }
    }, [isClientLoggedIn, loadingProgress, minDurationReached])

    useEffect(() => {
        if (unauthenticatedRoutes.includes(window.location.pathname)
        ) {
            setShowLoadingScreen(false) // don't show loading screen for unauthenticated routes
            return
        }

        startProgress()

        const expiredAt = Cookie.get(config.tokenExpiresAtCookie)

        let timeout = 0;
        if (expiredAt) {
            timeout = parseFloat(expiredAt) - Date.now()
        }

        timeout = Math.trunc(timeout) - config.tokenRefreshTimeoutBuffer * 1000

        timeout = timeout <= 0 ? 1000 : timeout // fixed 1 second timeout if token is expired

        const timerId = setTimeout(() => {
            auth.refreshTokens();
        }, timeout)

        return () => clearTimeout(timerId)
    }, [])

    useEffect(() => {
        if (client.currentClient) {
            Sentry.setContext('client', {
                id: client.currentClient.id,
                status: client.currentClient.status,
                appConfig: {
                    features: client.currentClient.appConfig?.features
                }
            });
        } else {
            Sentry.setContext('client', {});
        }
    }, [client]);

    useEffect(() => {
        if (clientStatus === 'INACTIVE') {
            auth.logout()
        }
    }, [clientStatus])

    useEffect(() => {
        if (accessToken) {
            setGraphqlClient(createUrqlClient(accessToken))
        }
    }, [accessToken])

    return {
        clientState: [client, setClient],
        graphqlClient,
        loadingProgress,
        showLoadingScreen,
    }
}

export {
    useRoot
}
