import { isOneOf } from "@helpers/core/typeGuards";
import { useParams } from "react-router-dom";
import { toQueryString, useRouteQuery } from "./hook";

export namespace JobPosition {
    export const PATH_NAME = "/jobs"

    interface QueryParams {
        page: number,
        rowsPerPage: number,
        search?: string | null,
        sortType: SortType;
        order: Order;
    }

    export const supportedSort = ["UPDATED_AT", "CREATED_AT", "TITLE"] as const

    export type SortType = typeof supportedSort[number]

    const order = ["asc", "desc"] as const

    export type Order = typeof order[number]

    export const useSearchParams = (): QueryParams => {
        const searchParams = useRouteQuery();
        const sortType = searchParams.get("sort_type")
        const sortOrder = searchParams.get("order")

        return {
            page: parseInt(searchParams.get('page') ?? '1'),
            rowsPerPage: parseInt(searchParams.get('rows_per_page') ?? '25'),
            search: searchParams.get('search') || "",
            sortType: isOneOf(supportedSort)(sortType) ? sortType : "CREATED_AT",
            order: isOneOf(order)(sortOrder) ? sortOrder : "desc",
        }
    }

    export const toRoute = ({
        page = 1,
        search,
        sortType = "CREATED_AT",
        order = "desc",
        rowsPerPage = 25,
    }: {
        page?: number,
        rowsPerPage?: number,
        search?: string | null,
        sortType?: SortType;
        order?: Order;
    } = {}) => {
        return {
            PATH_NAME,
            search: `?${toQueryString({
                page: page.toString(),
                rows_per_page: rowsPerPage.toString(),
                search,
                sort_type: sortType,
                order,
            })}`
        }
    }
}

export namespace JobPositionCreate {
    export const PATH_NAME = `${JobPosition.PATH_NAME}/create`;
}

export namespace JobPositionDetail {
    export const PATH_NAME = `${JobPosition.PATH_NAME}/:id`;

    interface QueryParams {
        id: string;
    }

    export const useSearchParams = (): QueryParams => {
        const { id } = useParams();

        return {
            id: id || ''
        }
    }

    export const toRoute = ({ id }: QueryParams) => {
        return PATH_NAME.replace(/:id/, id);
    }
}
