import { useContext } from 'react';

import { isUndefined } from '@helpers/core/typeGuards';
import { StepperContext, StepperContextType } from './StepperContext';

const useStepperContext = (): StepperContextType => {
    const context = useContext(StepperContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <Stepper>...</Stepper> block.');
    }

    return context;
};

export {
    useStepperContext,
};
