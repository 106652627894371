import auth from "@helpers/core/auth";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { FormEventHandler } from "react";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form"
import * as value from './value';

interface LoginDevPageHookType {
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
}

const useLoginDevPage = (): LoginDevPageHookType => {
    const { error: toastError } = useToast()

    const defaultValues = value.Encoder.defaultValues()

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            auth.devLogin(validValues.email, validValues.password)
        } catch (error) {
            toastError('global.error')
        }
    }

    return {
        form,
        onSubmit: form.handleSubmit(onSubmit)
    }
}

export {
    useLoginDevPage
}