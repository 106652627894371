import { useRef } from 'react';

// TODO: [CHECK] Is `extends HTMLElement` the intended type here?
// TODO: [CHECK] This hook might not really be very useful anymore
const useDomRef = <T extends HTMLElement>() => {
    return useRef<T>(null)
};

export {
    useDomRef,
};
