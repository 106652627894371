import { AddEventSessionDocument, Event_OneByIdQuery, GetAllRegisteredClients_AllRegisteredClientsDocument, GetAllRegisteredClients_AllRegisteredClientsQuery, ValidateSessionLocationDocument } from "@entities";
import { isDefined } from "@helpers/core/typeGuards";
import { useErrorMessage } from "@helpers/hooks/unsorted/errorMessageHook";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { range } from "@helpers/unsorted/arrayExtra";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { Entity } from "@typedefs/graphql";
import { SelectionOption } from "@typedefs/selectOption";
import { addMinutes } from "date-fns";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { SubmitHandler, UseFormReturn, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useClient, useMutation, useQuery } from "urql";

import * as value from './value';
import { CreateSettings, Error as LocationValidationError, Mode, useEventSessionUniqueLocationWarningModal } from "@shared/event/EventSessionUniqueLocationWarningModal/useEventSessionUniqueLocationWarningModal";
import { mergeTuple, uniqueLocationBetweenEvents, uniqueLocationBetweenGroup, uniqueLocationBetweenSessions } from "@shared/event/EventSessionUniqueLocationWarningModal/validations";
import { useCurrentLanguage } from "@helpers/core/i18n";
import { getEventSessionName } from "@shared/event/helpers";
import { dateTimeFormat } from "@helpers/unsorted/dateTimeFormat";

interface EventSessionCreateModalHookType {
    close: VoidFunction;
    isSubmitting: boolean;
    form: UseFormReturn<value.Encoder.Type>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    onFinalSubmit: VoidFunction;
    groupsOptions: SelectionOption[];
    startDate: string;
    startTime: string;
    endTime: string;
    dateErrors: (string | undefined)[];
    dynamicFieldsCount: number;
    clientOptions: SelectionOption[];
    mode: Mode;
    onCloseWarningModal: VoidFunction;
    isLoading: boolean;
}

const MAX_NUMBER_OF_GROUPS = 10

const useEventSessionAddModal = (event: Entity<Event_OneByIdQuery, 'event'>, closeModal: VoidFunction): EventSessionCreateModalHookType => {
    const { t } = useTranslation()

    const [clients, setClients] = useState<Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[]>([])

    const urqlClient = useClient()

    const currentLanguage = useCurrentLanguage()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [endTime, setEndTime] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const { error: toastError, success: toastSuccess } = useToast()

    const { mode, backToInit, showWarning } = useEventSessionUniqueLocationWarningModal()

    const defaultValues = value.Encoder.defaultValues(event.isOnline, MAX_NUMBER_OF_GROUPS)

    const form = useForm<value.Encoder.Type>({
        defaultValues: defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = form

    const close = () => {
        backToInit();

        closeModal()
        reset(defaultValues)
    }

    const [clientsResponse] = useQuery({ query: GetAllRegisteredClients_AllRegisteredClientsDocument })

    const [addEventSessionResponse, addEventSession] = useMutation(AddEventSessionDocument)

    const formDate = useWatch({
        control,
        name: 'date'
    })

    const formStartTime = useWatch({
        control,
        name: 'startTime'
    })

    const numberOfGroups = useWatch({
        control,
        name: 'groupCount'
    })

    const formGroups = useWatch({
        control,
        name: 'groups'
    })

    const formLocations = formGroups.map(({ location }) => location).slice(0, parseInt(numberOfGroups))

    const getDynamicFieldsCount = (count: string) => parseInt(count) || 0

    const [dynamicFieldsCount, setDynamicFieldsCount] = useState(
        getDynamicFieldsCount(numberOfGroups)
    )

    const groupsOptions: SelectionOption[] = range(MAX_NUMBER_OF_GROUPS, 1).map(val => ({
        key: val.toString(),
        value: val.toString(),
        label: val.toString(),
    }))

    const clientOptions = useMemo(() => {
        return clients.reduce(
            (options: SelectionOption[], client) => {
                switch (client.__typename) {
                    case 'InvitedClient':
                        return options
                    case 'RegisteredClient':
                        return options.concat([
                            {
                                key: client.id,
                                label: client.fullName,
                                value: client.id,
                            },
                        ])
                    default:
                        return options
                }
            },
            [],
        )
    }, [clients])

    const dateFieldErrors = useErrorMessage('date', errors, t('event.addSession.startDate'));
    const startTimeFieldErrors = useErrorMessage('startTime', errors, t('event.addSession.startTime'));
    const dateErrors = [dateFieldErrors, startTimeFieldErrors]
        .filter(error => isDefined(error))
        .reduce((errors: (string | undefined)[], error) => {
            if (errors.includes(error)) {
                return errors
            }

            return [...errors, error]
        }, [])

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validValues = await value.Decoder.schema(event.duration).parseAsync(data)

            const validationResult = await Promise.all(validValues.groups.map(async (group, index) => {
                const isUniqueLocationBetweenSessions = uniqueLocationBetweenSessions({
                    value: group.location,
                    event,
                    currentLanguage,
                });

                const isUniqueLocationBetweenGroups = uniqueLocationBetweenGroup({
                    locations: formLocations,
                    position: index,
                    value: group.location,
                })

                const isUniqueLocationBetweenEvents = await uniqueLocationBetweenEvents({
                    asyncValidateEventSessionLocation,
                    event,
                    value: group.location,
                });

                return {
                    isUniqueLocationBetweenSessions,
                    isUniqueLocationBetweenGroups,
                    isUniqueLocationBetweenEvents,
                }
            }));

            const errorReports = {
                isUniqueLocationBetweenSessions: {
                    name: 'isUniqueLocationBetweenSessions' as const,
                    payload: validationResult
                        .map(({ isUniqueLocationBetweenSessions }, index) => {
                            if (isUniqueLocationBetweenSessions) {
                                return {
                                    group: index + 1,
                                    message: `${isUniqueLocationBetweenSessions.sessionName} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenSessions.groupNo })})`
                                }
                            }
                        }).filter(isDefined)
                },
                isUniqueLocationBetweenEvents: {
                    name: 'isUniqueLocationBetweenEvents' as const,
                    payload: validationResult
                        .map(({ isUniqueLocationBetweenEvents }, index) => {
                            if (isUniqueLocationBetweenEvents) {
                                return {
                                    group: index + 1,
                                    message: `${isUniqueLocationBetweenEvents.eventName} - ${getEventSessionName(isUniqueLocationBetweenEvents.session, currentLanguage)} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenEvents.groupNo })})`,
                                }
                            }
                        }).filter(isDefined)
                },
                isUniqueLocationBetweenGroups: {
                    name: 'isUniqueLocationBetweenGroups' as const,
                    payload: {
                        duplicateGroups: mergeTuple(
                            validationResult
                                .map(({ isUniqueLocationBetweenGroups }) => isUniqueLocationBetweenGroups)
                                .filter(isDefined))
                    }
                }
            }

            let validationError: LocationValidationError | undefined = undefined

            if (errorReports.isUniqueLocationBetweenSessions.payload.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenSessions
            } else if (errorReports.isUniqueLocationBetweenGroups.payload.duplicateGroups.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenGroups
            } else if (errorReports.isUniqueLocationBetweenEvents.payload.length > 0) {
                validationError = errorReports.isUniqueLocationBetweenEvents
            }

            if (validationError) {
                showWarning(validationError, {
                    groupCount: validValues.groupCount,
                    groups: validValues.groups,
                    startTime: validValues.startTime,
                    endTime: validValues.endTime,
                })
            } else {
                addEventSession({
                    groupCount: validValues.groupCount,
                    groups: validValues.groups,
                    eventId: event.id,
                    startAt: validValues.startTime.toISOString(),
                    endAt: validValues.endTime.toISOString(),
                })
            }
        } catch (error) {
            toastError('global.error')
        }
    }

    const onFinalSubmit = () => {
        try {
            if (mode.name === 'warn') {
                const settings = mode.payload.currentSetting as CreateSettings;

                addEventSession({
                    groupCount: settings.groupCount,
                    groups: settings.groups,
                    eventId: event.id,
                    startAt: settings.startTime.toISOString(),
                    endAt: settings.endTime.toISOString(),
                })

                close()
            }
        } catch {
            toastError('global.error');
        }
    };

    const asyncValidateEventSessionLocation = async (value: string) => {
        const result = await urqlClient.query(ValidateSessionLocationDocument, {
            location: value,
            isOnline: event.isOnline
        }, {
            requestPolicy: 'network-only'
        }).toPromise()

        return result.data?.validateEventSessionLocation
    }

    useEffect(() => {
        let endTime = ""

        if (formDate.length === 0) {
            if(formStartTime.length > 0) {
                const startTime = dateFormat.parseTime(formStartTime)

                const computedEndTime = addMinutes(startTime, event.duration)

                endTime = dateFormat.simpleTime(computedEndTime)
            }
        } else {
            const startDateTime = dateTimeFormat.fromStringValid(`${formDate} ${formStartTime}`);

            if (startDateTime) {
                const computedEndTime = addMinutes(startDateTime, event.duration)

                endTime = dateFormat.simpleTime(computedEndTime)
            }
        }
        
        setEndTime(endTime)
    }, [formStartTime, formDate])

    useEffect(() => {
        setDynamicFieldsCount(getDynamicFieldsCount(numberOfGroups))
    }, [numberOfGroups])

    useEffect(() => {
        handleResponse(clientsResponse, {
            onFetching: () => {
                setIsLoading(true)
            },
            onData: (data) => {
                setIsLoading(false)
                setClients(data.clients.edges)
            },
            onError: () => {
                setIsLoading(false)
                toastError('global.error')
                setClients([])
            }
        })
    }, [clientsResponse])

    useEffect(() => {
        handleResponse(addEventSessionResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('event.addSession.createSuccessfully')
                close()
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('event.addSession.createFailed')
            }
        })
    }, [addEventSessionResponse])

    return {
        close,
        isSubmitting,
        form,
        groupsOptions,
        startDate: formDate,
        startTime: formStartTime,
        endTime,
        dateErrors,
        dynamicFieldsCount,
        clientOptions,
        onSubmit: handleSubmit(onSubmit),
        onFinalSubmit,
        mode,
        onCloseWarningModal: backToInit,
        isLoading,
    }
}

export {
    useEventSessionAddModal
};

