import { z } from 'zod';

namespace Encoder {
    export const addCandidateMethods = ['CSV', 'MANUAL'] as const;

    export interface EncoderType {
        addMethod: string;
    }

    export const defaultValues = (): EncoderType => ({
        addMethod: 'CSV',
    });
}

namespace Decoder {
    export const schema = z.object({
        addMethod: z.enum(Encoder.addCandidateMethods),
    });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
};