import { Event_OneByIdQuery, EventSession_OneByIdQuery } from "@entities";
import { isUndefined } from "@helpers/core/typeGuards";
import { Entity } from "@typedefs/graphql";
import { createContext, useContext } from "react";

interface EventSessionDetailContextType {
    eventSession: Entity<EventSession_OneByIdQuery, 'eventSession'>;
    event: Entity<Event_OneByIdQuery, 'event'>;
}

const EventSessionDetailContext = createContext<EventSessionDetailContextType | undefined>(undefined);

const useEventSessionDetailContext = () => {
    const context = useContext(EventSessionDetailContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <EventSessionDetailContext.Provider>...</EventSessionDetailContext.Provider> block.');
    }

    return context;
};

export {
    EventSessionDetailContext,
    useEventSessionDetailContext
};