import { z } from 'zod';
import { allRecruitmentStages, allRecruitmentSteps } from '@helpers/core/constants';
import { RecruitmentStageType, RecruitmentStepType } from "@typedefs/aliases";

namespace Encoder {
    export type Type = {
        recruitmentSteps: ItemType[];
    }

    export type ItemType = ReturnType<typeof makeItem>;

    type Item = {
        stage: RecruitmentStageType;
        id?: string;
        name?: string;
        type?: RecruitmentStepType;
        hasDecision?: boolean;
        hasEvaluation?: boolean;
        hasNotification?: boolean;
        previousStepId?: string | null;
        nextStepId?: string | null;
    };

    export const makeItem = (item: Item) => {
        const { stage, id, name, type, hasDecision, hasEvaluation, hasNotification, previousStepId, nextStepId } = item;

        return {
            item_id: id ?? '',
            name: name ?? '',
            type: type ?? 'DOCUMENT',
            stage,
            hasDecision: hasDecision ?? false,
            hasEvaluation: hasEvaluation ?? false,
            hasNotification: hasNotification ?? false,
            previousStepId: previousStepId ?? null,
            nextStepId: nextStepId ?? null,
        };
    }
}

namespace Decoder {
    export const recruitmentStepSchema = z.object({
        item_id: z.string().nullish(),
        name: z.string(),
        type: z.enum(allRecruitmentSteps),
        stage: z.enum(allRecruitmentStages),
        hasDecision: z.boolean(),
        hasEvaluation: z.boolean(),
        hasNotification: z.boolean()
    }).transform(({ item_id, ...restStep }) => ({ ...restStep, id: item_id }));
}

export {
    Encoder,
    Decoder,
}