import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BulkActionErrorModalPayload } from '../reducer';
import { isOneOf } from '@helpers/core/typeGuards';
import { bulkActions, needSetupUpdateStatusBulkActions } from '@helpers/core/constants';
import { useBulkActionErrorModal } from './hook';

const styles = {
    container: Cn.c('py-6 rounded-xl'),
    header: Cn.c('flex justify-between items-start pb-4 px-6'),
    outerCircle: (isPartialSuccess: boolean) => Cn.join([
        Cn.c('w-14 h-14 flex items-center justify-center rounded-full'),
        isPartialSuccess ? Cn.c('bg-surface-warning-subdued') : Cn.c('bg-surface-critical-subdued'),
    ]),
    innerCircle: (isPartialSuccess: boolean) => Cn.join([
        Cn.c('w-10 h-10 flex items-center justify-center rounded-full'),
        isPartialSuccess ? Cn.c('bg-surface-warning-default') : Cn.c('bg-surface-critical-default'),
    ]),
    warningIcon: (isPartialSuccess: boolean) => Cn.join([
        Cn.c('w-5 h-5'),
        isPartialSuccess ? Cn.c('text-icons-warning-default') : Cn.c('text-icons-critical-emphasized'),
    ]),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    body: Cn.c('pb-5 border-b border-default px-6'),
    description: Cn.c('text-emphasized font-h4-bold mb-2'),
    subDescription: Cn.c('text-subdued font-paragraph-small-regular'),
    note: Cn.c('mt-4 p-3 bg-surface-warning-subdued rounded-lg font-paragraph-xsmall-regular text-warning-emphasized'),
    highlight: Cn.c('font-paragraph-xsmall-medium'),
    resultContainer: Cn.c('mt-4 flex flex-col gap-y-4'),
    result: Cn.c('flex flex-col font-paragraph-small-regular text-emphasized gap-y-[0.53125rem]'),
    successText: Cn.c('text-success-default'),
    failedText: Cn.c('text-critical-default'),
    iconContainer: Cn.c('flex gap-x-1'),
    checkIcon: Cn.c('w-[1.125rem] h-[1.125rem] text-icons-success-default'),
    errorIcon: Cn.c('w-[1.125rem] h-[1.125rem] text-icons-critical-default'),
    failedContainer: Cn.c('flex gap-x-2'),
    retry: Cn.c('flex gap-x-0.5 font-paragraph-small-link-medium text-primary-default underline cursor-pointer'),
    retryIcon: Cn.c('w-3.5 h-3.5 text-icons-primary-default'),
    contact: Cn.c('font-paragraph-xsmall-link-medium text-primary-default underline'),
    buttons: Cn.c('flex mt-6 px-6 gap-x-3'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    payload?: BulkActionErrorModalPayload;
}

const BulkActionErrorModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    payload,
}) => {
    const { t } = useTranslation();

    const action = payload?.action;
    const failedIds = payload?.failedIds;
    const success = payload?.success;

    const { retry } = useBulkActionErrorModal({
        closeModal,
        errorPayload: payload,
    });

    const isPartialSuccess = success != 0;

    return (
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[25rem]"
        >{isOneOf(bulkActions)(action) && !isOneOf(needSetupUpdateStatusBulkActions)(action) &&
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle(isPartialSuccess)}>
                        <div className={styles.innerCircle(isPartialSuccess)}>
                            <Icon
                                name={isPartialSuccess ? "warning" : "errorWarning"}
                                className={styles.warningIcon(isPartialSuccess)}
                            />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t(`applications.bulkAction.${action}.title`)}</p>
                    <p className={styles.subDescription}>
                        {isPartialSuccess
                            ? t("applications.bulkActionError.subDescription.partialError")
                            : t(`applications.bulkActionError.subDescription.allError`, { action: t(`applications.bulkAction.${action}.title`) })
                        }
                    </p>
                    {isPartialSuccess &&
                        <div className={styles.resultContainer}>
                            <div className={styles.result}>
                                <div className={styles.iconContainer}>
                                    <Icon name="checkCircle" className={styles.checkIcon} />
                                    <p>{t("applications.bulkActionError.result.description", { count: success })}</p>
                                </div>
                                <p className={styles.successText}>{t("applications.bulkActionError.result.success")}</p>
                            </div>
                            <div className={styles.result}>
                                <div className={styles.iconContainer}>
                                    <Icon name="crossCircle" className={styles.errorIcon} />
                                    <p>{t("applications.bulkActionError.result.description", { count: failedIds?.length })}</p>
                                </div>
                                <div className={styles.failedContainer}>
                                    <p className={styles.failedText}>{t("applications.bulkActionError.result.failed")}</p>
                                    <div className={styles.retry} onClick={retry}>
                                        <Icon name="refresh" className={styles.retryIcon} />
                                        <span>{t("global.retry")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.note}>
                        <Trans
                            i18nKey={"applications.bulkActionError.note"}
                            components={{
                                highlight: <span className={styles.highlight}></span>,
                                contact: <a
                                    href="mailto:bluum@reccoo.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.contact} />,
                            }}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={closeModal}
                        isFull={true}
                    >
                        {t("global.dismiss")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        onClick={retry}
                        isFull={true}
                    >
                        {t("global.retry")}
                    </Button>
                </div>
            </div>
            }
        </Modal>
    );
};

export {
    BulkActionErrorModal,
}