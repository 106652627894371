import { Cn } from "@helpers/unsorted/classNames";
import { Input } from "@shared/unsorted/Input/Input";
import { MultiSelect } from "@shared/unsorted/MultiSelect/MultiSelect";
import { SelectionOption } from "@typedefs/selectOption";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";

import { NestedFormProps, useNestedForm } from "@helpers/hooks/unsorted/useNestedForm";

import * as groupValue from "./value";

const styles = {
  row: Cn.c("flex gap-x-4 justify-between"),
}

interface Props extends NestedFormProps {
  isOnline: boolean;
  clientOptions: SelectionOption[];
}

const EventSessionGroupSubForm: FunctionComponent<Props> = ({
  isOnline,
  clientOptions,
  path,
}) => {
  const form = useNestedForm<groupValue.Encoder.Type>(path)

  const { getPath, clearErrors, control, formState: { errors } } = form

  return (
    <div>
      <div className={styles.row}>
        <Controller
          name={getPath(`clientIds`)}
          control={control}
          render={({ field: { name, onBlur, onChange, ref, value } }) =>
            <MultiSelect
              name={name}
              label='event.addSession.examinersLabel'
              options={clientOptions}
              errors={errors}
              clearErrors={clearErrors}
              forwardedRef={ref}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />}
        />
        <Controller
          name={getPath(`location`)}
          control={control}
          rules={{ required: true }}
          render={({ field: { name, onBlur, onChange, ref, value } }) =>
            <Input
              name={name}
              label={isOnline
                ? 'event.addSession.locationOnlineLabel'
                : 'event.addSession.locationOfflineLabel'
              }
              placeholder={isOnline
                ? 'event.addSession.placeholders.locationOnline'
                : 'event.addSession.placeholders.locationOffline'
              }
              onBlur={onBlur}
              onChange={onChange}
              errors={errors}
              clearErrors={clearErrors}
              forwardedRef={ref}
              value={value}
              required
            />
          }
        />
      </div>
    </div>
  );
}

export {
  EventSessionGroupSubForm
};
