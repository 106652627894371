import { FunctionComponent } from "react"
import { InitialStateData } from "../init"
import { Cn } from "@helpers/unsorted/classNames";
import { useJobPositionsSuccessState } from "./hook";
import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout";
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader";
import { CreateButton } from "../CreateButton";
import { Search } from "@shared/unsorted/Search/Search";
import { EmptyStateContainer } from "@shared/unsorted/EmptyStateContainer/EmptyStateContainer";
import { Trans, useTranslation } from "react-i18next";
import { Sort } from "@shared/unsorted/Sort/Sort";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { Select } from "@shared/unsorted/Select/Select";
import { Pagination } from "@shared/unsorted/Pagination/Pagination";

const styles = {
    breadcrumb: Cn.c('py-4 border-b border-b-default px-6'),
    body: Cn.c("p-6 flex overflow-auto flex-col flex-1"),
    subHeaderSection: Cn.c("flex flex-row items-center justify-between flex-shrink-0 mb-2 bg-surface-default"),
    sortContainer: Cn.c("flex gap-x-1 p-1"),
    searchForm: Cn.c("w-[25rem] min-w-max"),
    normalText: Cn.c("font-paragraph-small-medium text-subdued"),
    emphasizedText: Cn.c("font-paragraph-small-semi-bold text-default"),
    emptyTable: Cn.c("w-full flex flex-col items-center justify-center bg-surface-default min-h-empty-table m-auto"),
    tableInnerContainer: Cn.c("bg-surface-default border border-subdued flex overflow-auto"),
    table: Cn.c("table-sticky"),
    tableTH: Cn.c("table-thead-row table-th-sticky top-0"),
    tableTBodyRow: Cn.c("table-tbody-row"),
    idCell: Cn.c("table-td w-1/5"),
    titleCell: Cn.c("table-td w-2/5"),
    statusCell: Cn.c("table-td w-1/5"),
    updatedDateCell: Cn.c("table-td w-1/5"),
    paginationWrapper: Cn.c("py-4 flex justify-between items-end grow"),
    paginationLeft: Cn.c("flex items-center space-x-2"),
    rowsPerPage: Cn.c("font-paragraph-xsmall-regular text-subdued"),
}

interface Props extends InitialStateData {
}

const JobPositionsSuccessState: FunctionComponent<Props> = ({
    totalRows,
    clientHasNoJobPosition,
    jobPositions
}) => {
    const {
        getPageCount,
        currentPage,
        changePage,
        rowsPerPage,
        rowsPerPageOptions,
        changeRowsPerPage,
        openJobPositionPage,
        sortOptions,
        currentSortId,
        onSort,
        getRowIndexes,
        searchKeyword,
        onSubmitSearch,
    } = useJobPositionsSuccessState();
    const { t } = useTranslation();

    const { startRowIndex, endRowIndex } = getRowIndexes(currentPage, rowsPerPage, totalRows);

    return (
        <DefaultLayout>
            <PageHeader
                action={<CreateButton size="sm" />}
            >
                <Search
                    keyword={searchKeyword}
                    onSearchAction={onSubmitSearch}
                    size={"small"}
                />
            </PageHeader>
            <div className={styles.body}>
                {clientHasNoJobPosition
                    ?
                    <div className={styles.emptyTable}>
                        <EmptyStateContainer
                            icon='emptyJobPosition'
                            description='jobPositions.list.empty.description'
                            subDescription='jobPositions.list.empty.subDescription'>
                            <CreateButton size="md" />
                        </EmptyStateContainer>
                    </div>
                    : <>
                        <div className={styles.subHeaderSection}>
                            <div className={styles.normalText}>
                                <Trans
                                    i18nKey={"jobPositions.list.jobPositionsCount"}
                                    count={totalRows}
                                    values={{
                                        startRowIndex,
                                        endRowIndex,
                                    }}
                                    components={{ highlight: <span className={styles.emphasizedText}></span> }}
                                />
                            </div>
                            <div className={styles.sortContainer}>
                                <p className={styles.normalText}>{t('jobPositions.list.orderBy')}</p>
                                <Sort
                                    items={sortOptions}
                                    currentSortId={currentSortId}
                                    onSelect={onSort}
                                />
                            </div>
                        </div>
                        <div className={styles.tableInnerContainer}>
                            <table className={styles.table} >
                                <thead>
                                    <tr>
                                        <th className={styles.tableTH}>
                                            {t('jobPositions.list.table.publicId')}
                                        </th>
                                        <th className={styles.tableTH}>
                                            {t('jobPositions.list.table.title')}
                                        </th>
                                        {/* <th className={styles.tableTH}>
                          {t('jobPositions.list.table.status')}
                        </th> */}
                                        <th className={styles.tableTH}>
                                            {t('jobPositions.list.table.updatedAt')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobPositions.length === 0 ?
                                        <tr>
                                            <td colSpan={3}>
                                                <div className={Cn.join([styles.emptyTable])}>
                                                    <EmptyStateContainer
                                                        icon='notFound'
                                                        description='jobPositions.list.emptySearch.description'
                                                        subDescription='jobPositions.list.emptySearch.subDescription'>
                                                    </EmptyStateContainer>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        jobPositions.map(jobPosition =>
                                            <tr
                                                className={styles.tableTBodyRow}
                                                key={jobPosition.id}
                                                onClick={_event => openJobPositionPage(jobPosition.id)}
                                            >
                                                <td className={styles.idCell}>
                                                    {jobPosition.publicId.toString().padStart(4, "0")}
                                                </td>
                                                <td className={styles.titleCell}> {jobPosition.title} </td>
                                                {/* <td className={styles.statusCell}>
                              <Badge
                                label={jobPosition.isDraft ? "jobPositions.shared.draftStatus.draft" : "jobPositions.shared.draftStatus.published"}
                                variant={jobPosition.isDraft ? "inactive" : "success"}
                              />
                            </td> */}
                                                <td className={styles.updatedDateCell}>
                                                    {dateFormat.simpleDateWithoutWeekday(new Date(jobPosition.updatedAt), "-")}
                                                </td>
                                            </tr>)}
                                </tbody >
                            </table >
                        </div >
                        <div className={styles.paginationWrapper}>
                            <div className={styles.paginationLeft}>
                                <Select
                                    name="rowsPerPage"
                                    options={rowsPerPageOptions}
                                    onChange={changeRowsPerPage}
                                    value={rowsPerPage.toString()}
                                    size="small"
                                    direction="upward"
                                    className="w-16"
                                    noEmpty
                                />
                                <div className={styles.rowsPerPage}>{t('jobPositions.list.rowsPerPage')}</div>
                            </div>
                            <Pagination pageCount={getPageCount(totalRows)} currentPage={currentPage} onSelectPage={changePage} />
                        </div>
                    </>
                }
            </div >
        </DefaultLayout >
    );
}

export {
    JobPositionsSuccessState
}