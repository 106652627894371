import { useStepper } from "@shared/layout/stepper/useStepper";
import { FunctionComponent, useReducer } from "react"
import { Cn } from "@helpers/unsorted/classNames";
import { CSVImportFormContext } from "./CSVImportFormContext";
import { StepContainer } from "@shared/layout/stepper/StepContainer";
import { initialState, reducer } from "./reducer";
import { CSVImportSetupStep } from "./steps/setup/CSVImportSetupStep";
import { CSVImportPreviewStep } from "./steps/preview/CSVImportPreviewStep";
import { CSVImportImportStep } from "./steps/import/CSVImportImportStep";
import { CSVImportErrorCorrectionStep } from "./steps/errorCorrection/CSVImportErrorCorrectionStep";

const styles = {
    container: Cn.c("pt-6 flex flex-col flex-1 overflow-auto"),
    tracker: Cn.c("px-6"),
    body: Cn.c("flex-1 flex flex-col overflow-auto")
}

const CSVImportForm: FunctionComponent = () => {
    const { ControlledStepper } = useStepper('setup')
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <CSVImportFormContext.Provider value={[state, dispatch]}>
            <ControlledStepper
                stepTracker={state.stepTracker}
                className={styles.container}
                trackerClassname={styles.tracker}
            >
                <StepContainer id="setup" className={styles.body}>
                    {state.currentStep.name === 'setup' &&
                        <CSVImportSetupStep />
                    }
                </StepContainer>

                <StepContainer id="errorCorrection" className={styles.body}>
                    {state.currentStep.name === 'errorCorrection' &&
                        <CSVImportErrorCorrectionStep
                            errorCSVUrl={state.currentStep.data.errorCSVUrl}
                            errorSummary={state.currentStep.data.errorSummary}
                        />
                    }
                </StepContainer>

                <StepContainer id="preview" className={styles.body}>
                    {state.currentStep.name === 'preview' &&
                        <CSVImportPreviewStep
                            validationResults={state.currentStep.data.validationResults}
                        />}
                </StepContainer>

                <StepContainer id="import" className={styles.body}>
                    {state.currentStep.name === 'import' &&
                        <CSVImportImportStep
                            totalCount={state.currentStep.data.totalCount}
                        />
                    }
                </StepContainer>
            </ControlledStepper>
        </CSVImportFormContext.Provider>
    );
}

export {
    CSVImportForm
}