import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { InitialState } from "./init";
import { useEffect } from "react";
import { useBulkActionModalContext } from "@pages/ApplicationsPage/BulkActionModalContext";
import { useBulkEventSessionAssignmentContext } from "../../BulkEventSessionAssignmentContext";

const useSessionSelectionStep = (initialState: InitialState) => {
    const { error: toastError } = useToast();
    const { dispatchBulkActionModalMode } = useBulkActionModalContext()
    const [, dispatch] = useBulkEventSessionAssignmentContext()

    const closeModal = () => {
        dispatch({
            name: 'SetSettings',
            payload: {
                settings: {
                    currentRecruitmentStepId: undefined
                }
            }
        })

        dispatchBulkActionModalMode({
            name: 'hide'
        })
    }

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])

    return {
        closeModal
    }
}

export {
    useSessionSelectionStep
}