import { useMemo, useEffect, useRef } from 'react';
import { ActivityLogsDocument, ActivityLogsInput } from '@entities';
import { useQueryContext } from '@helpers/unsorted/urqlExtra';
import { ActivityLog, parseActivityLog } from '@helpers/core/activityLog';
import { useQuery } from 'urql';

export type UseActivityLogListInput = {
    initialQueryInput: ActivityLogsInput;
    subsequentQueryInputs: ActivityLogsInput[];
    loadMoreSize: number;
    onLoadMore: (input: ActivityLogsInput) => void;
}

export const useActivityLogList = ({ initialQueryInput, subsequentQueryInputs, loadMoreSize, onLoadMore }: UseActivityLogListInput) => {
    const activityLogListEndRef = useRef<HTMLLIElement>(null);
    const { totalCount, activityLogs } = useActivityLogs({ queryInput: initialQueryInput });
    const lastQueryInput = useMemo(
        () => subsequentQueryInputs.length > 0 ? subsequentQueryInputs[subsequentQueryInputs.length - 1] : initialQueryInput,
        [initialQueryInput, subsequentQueryInputs]
    );

    useEffect(() => {
        if (!(activityLogListEndRef.current)) {
            return;
        }
        const observer = new IntersectionObserver((entries) => {
            if (lastQueryInput.offset + lastQueryInput.first >= totalCount) {
                return;
            }
            for (const entry of entries) {
                if (!entry.isIntersecting) {
                    continue;
                }
                onLoadMore({
                    ...lastQueryInput,
                    first: loadMoreSize,
                    offset: lastQueryInput.offset + lastQueryInput.first
                });
                break;
            }
        }, {
            root: activityLogListEndRef.current.parentElement
        });
        observer.observe(activityLogListEndRef.current);
        return () => {
            observer.disconnect();
        }
    }, [totalCount, activityLogs, lastQueryInput, loadMoreSize, onLoadMore])

    return {
        activityLogs,
        activityLogListEndRef
    }
}

export type UseActivityLogsInput = {
    queryInput: ActivityLogsInput;
}

export const useActivityLogs = ({ queryInput }: UseActivityLogsInput) => {
    const activityLogsContext = useQueryContext('ActivityLogs');
    const [activityLogsResponse] = useQuery({
        query: ActivityLogsDocument,
        context: activityLogsContext,
        variables: {
            input: queryInput
        }
    })

    return useMemo(() => {
        if (!activityLogsResponse.data) {
            return {
                totalCount: 0,
                activityLogs: []
            }
        }
        return {
            totalCount: activityLogsResponse.data.activityLogs.totalCount,
            activityLogs: activityLogsResponse.data.activityLogs.edges.map(parseActivityLog).filter((log): log is ActivityLog => log != undefined)
        }
    }, [activityLogsResponse.data]);
}

