import { FunctionComponent } from "react"
import { MenuContainer } from "./MenuContainer";
import { ToolTipBoxProps } from "../ToolTipBox/ToolTipBox";

const sizes = ["small", "medium", "large", "x-large"] as const;
const submenuPositions = ["right", "left"] as const;

type Size = typeof sizes[number];
type SubmenuPosition = typeof submenuPositions[number];

type MenuItemData = {
    label: string;
    value: string;
    children?: MenuGroupData[];
    isDisabled?: boolean;
    tooltip?: string
}

type MenuGroupData = {
    groupName?: string;
    items: MenuItemData[];
}

type MenuData = {
    item?: {
        tooltip?: Omit<ToolTipBoxProps, 'tooltipContent' | 'className'>;
    }
    groups: MenuGroupData[];
}

interface Props {
    menu: MenuData;
    size?: Size
    submenuPosition?: SubmenuPosition
    onChange?: (value: string) => void
    value?: string
}

const NestedMenu: FunctionComponent<Props> = ({
    menu,
    size = 'medium',
    submenuPosition = 'right',
    value,
    onChange,
}) => {
    return <MenuContainer
        groups={menu.groups}
        itemCustomization={menu.item}
        size={size}
        submenuPosition={submenuPosition}
        depthLevel={0}
        showMenu={true}
        value={value}
        onChange={onChange}
    />
}

export {
    NestedMenu,
    MenuGroupData,
    MenuItemData,
    MenuData,
    Size,
    sizes,
    submenuPositions,
    SubmenuPosition
}