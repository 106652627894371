import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { useRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { AccountForm } from "./AccountForm/AccountForm";
import { AccountFormPromptModal } from "./AccountFormPromptModal/AccountFormPromptModal";
import { TabHeader } from "../TabHeader/TabHeader";
import { useAccount } from "./hooks";

const styles = {
    actions: Cn.c("flex flex-row items-center gap-x-4"),
};

const Account: FunctionComponent = () => {
    const formId = useRef("account_update_form");
    const { t } = useTranslation();
    const {
        currentClient,
        form,
        blocker,
        isSubmitting,
        handleReset,
        handleSubmit,
        handleProceedBlocker,
        handleResetBlocker,
        discardChanges,
    } = useAccount();

    return (
        <>
            <TabHeader
                title="settings.account.title"
                action={
                    <div className={styles.actions}>
                        {form.formState.isDirty && !isSubmitting && (
                            <Button
                                type="button"
                                size="md"
                                iconName="refresh"
                                variant="ghost"
                                onClick={handleReset}
                            >
                                {t("settings.account.actions.reset.button")}
                            </Button>
                        )}
                        <Button
                            type="submit"
                            formId={formId.current}
                            isDisabled={
                                currentClient.clientState === "loading" ||
                                isSubmitting ||
                                !form.formState.isDirty
                            }
                            isLoading={isSubmitting}
                            size="md"
                        >
                            {t("settings.account.actions.save.button")}
                        </Button>
                    </div>
                }
            />
            {currentClient.clientState === "loading" && <Loader />}
            {currentClient.clientState === "loggedIn" && (
                <>
                    <AccountForm
                        formId={formId.current}
                        form={form}
                        onSubmit={form.handleSubmit(handleSubmit)}
                    />
                    <AccountFormPromptModal
                        formId={formId.current}
                        isOpen={blocker.state === "blocked"}
                        cancel={handleResetBlocker}
                        proceed={discardChanges}
                        isDisabled={isSubmitting || !form.formState.isDirty || !form.formState.isValid}
                        isSubmitting={isSubmitting}
                    />
                </>
            )}
        </>
    );
};

export { Account };
