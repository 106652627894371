import { getLocale, SupportedLanguage } from '@helpers/core/i18n';
import { parse, format, isValid, set } from "date-fns";

import { dateFormat, SimpleDateSeparator } from "./dateFormat";
// @@ocaml.doc("The date time parsers and formatters
//     - Simple date time: yyyy-MM-dd (eee) HH:mm or yyyy/MM/dd (eee) HH:mm")

// @ocaml.doc("Concate year, month, day, hour, minute into 1 full date time string using the default `/` separator for the date")
// Note: Month values are 0 indexed
// TODO: According to the usage, we may not need to use this function in the future
const simpleDateTimeFromFullDate = (
  year: number,
  month: number,
  date: number,
  hour: number,
  minute: number,
  separator: SimpleDateSeparator = "/",
  language?: SupportedLanguage,
) => {
  const locale = getLocale(language);

  return format(
    new Date(year, month, date, hour, minute),
    `yyyy${separator}MM${separator}dd, h:mm a`,
    { locale },
  );
};

// @ocaml.doc("Formats a Date to a simple date time string using the default `/` separator for the date")
const simpleDateTime = (
  date: Date,
  separator: SimpleDateSeparator = "/",
  language?: SupportedLanguage,
) => {
  const locale = getLocale(language);

  return format(date, `yyyy${separator}MM${separator}dd, h:mm a`, {
    locale,
  });
};

// @ocaml.doc("Parse simple date time strings into Date")
// Note: If the parsed date is invalid, it will return an `Invalid Date` JS Date object
const fromString = (simpleDateTime: string) =>
  parse(simpleDateTime.replace(/\//g, "-"), "yyyy-MM-dd HH:mm", new Date());

// @ocaml.doc("Parse simple date time string into Date, returns null if the date is invalid")
const fromStringValid = (simpleDate: string) => {
  const parsedDate = fromString(simpleDate);

  return isValid(parsedDate) ? parsedDate : null;
};

const dateTimeFormat = {
  simpleDateTimeFromFullDate,
  simpleDateTime,
  fromString,
  fromStringValid,
};

export { dateTimeFormat };
