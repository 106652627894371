import { useErrorMessage } from "@helpers/hooks/unsorted/errorMessageHook";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { I18nKey, useTranslation } from "react-i18next";
import { FieldValues, FieldPath, FieldErrors } from "react-hook-form";

type UseDropdownStateInput = {
    isOpen?: boolean;
    onOpen?: VoidFunction;
    onClose?: VoidFunction;
};

type UseDropdownStateOutput = {
    isOpen: boolean;
    open: VoidFunction;
    close: VoidFunction;
};

const useDropdownState = (
    input: UseDropdownStateInput
): UseDropdownStateOutput => {
    const [isOpen, setIsOpen] = useState(input.isOpen || false);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
        if (input.onOpen) {
            input.onOpen();
        }
    }, [input.onOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (input.onClose) {
            input.onClose();
        }
    }, [input.onClose]);

    useLayoutEffect(() => {
        if (input.isOpen != undefined) {
            setIsOpen(input.isOpen);
        }
    }, [input.isOpen]);

    return {
        isOpen,
        open: handleOpen,
        close: handleClose,
    };
};

type UseDropdownInput<FieldsType extends FieldValues> =
    UseDropdownStateInput & {
        name: FieldPath<FieldsType>;
        label?: I18nKey;
        errorLabel?: I18nKey;
        errors?: FieldErrors<FieldsType>;
        placeholder?: I18nKey;
    };

type UseDropdownOutput = UseDropdownStateOutput & {
    placeholder?: string;
    error?: string;
    menuRef: React.MutableRefObject<HTMLDivElement | null>;
    toggleRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const useDropdown = <FieldsType extends FieldValues>(
    input: UseDropdownInput<FieldsType>
): UseDropdownOutput => {
    const {
        name,
        label,
        errorLabel,
        errors,
        placeholder: placeholderKey,
        ...rest
    } = input;
    const { t } = useTranslation();
    const state = useDropdownState(rest);
    const menuRef = useRef<HTMLDivElement>(null);
    const toggleRef = useRef<HTMLButtonElement>(null);
    const placeholder = useMemo(
        () => placeholderKey && t(placeholderKey),
        [t, placeholderKey]
    );
    const errorFieldName =
        errorLabel != undefined
            ? t(errorLabel)
            : label != undefined
            ? t(label)
            : name;
    const error = useErrorMessage(name, errors, errorFieldName);

    const handleMouseDownEvent = useCallback(
        ({ target }: MouseEvent) => {
            const menu = menuRef.current;
            const toggle = toggleRef.current;
            if (!(target instanceof HTMLElement && menu && toggle)) {
                return;
            }
            if ([menu, toggle].every((el) => !el.contains(target))) {
                state.close();
            }
        },
        [state.close]
    );

    useEffect(() => {
        if (!state.isOpen) {
            return;
        }
        window.addEventListener("mousedown", handleMouseDownEvent);
        return () =>
            window.removeEventListener("mousedown", handleMouseDownEvent);
    }, [state.isOpen, handleMouseDownEvent]);

    useEffect(() => {
        const toggle = toggleRef.current;
        if (!(toggle && state.isOpen)) {
            return;
        }
        const observer = new ResizeObserver(() => {
            const menu = menuRef.current;
            if (!menu) {
                return;
            }
            const toggleRect = toggle.getBoundingClientRect();
            menu.style.top = `${toggle.offsetTop + toggleRect.height + 8}px`;
        });
        observer.observe(toggle);
        return () => observer.disconnect();
    }, [state.isOpen]);

    return {
        ...state,
        placeholder,
        error,
        menuRef,
        toggleRef,
    };
};

export {
    useDropdownState,
    useDropdown,
    UseDropdownStateInput,
    UseDropdownStateOutput,
    UseDropdownInput,
    UseDropdownOutput,
};
