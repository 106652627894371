import { TFunction } from "react-i18next";
import { RegisteredClient } from "@entities";

export const showFullName = (
    t: TFunction<"translation">,
    client: Pick<RegisteredClient, 'fullName' | 'deletedAt'>
): string => {
    if (client.deletedAt == undefined) {
        return client.fullName;
    } else {
        return t('users.show.deleted.fullName', { fullName: client.fullName })
    }
}

