import { ValidateBulkEvaluationCsvFileMutation } from "@entities";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Pagination } from "@shared/unsorted/Pagination/Pagination";
import { Select } from "@shared/unsorted/Select/Select";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react"
import { Trans, useTranslation } from "react-i18next";
import { useCSVBulkEvaluationPreviewStep } from "./hook";

const styles = {
    container: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    section: Cn.c('flex flex-col py-6'),
    header: Cn.c('font-paragraph-base-semi-bold text-emphasized mb-2'),
    label: Cn.c('text-subdued font-paragraph-small-regular'),
    count: Cn.c('font-paragraph-base-medium text-emphasized'),
    table: Cn.c("table-sticky mt-6 border border-subdued border-b-0"),
    tableTH: Cn.c("table-thead-row table-th-sticky top-0 text-left"),
    tableTBodyRow: Cn.c("table-tbody-row bg-surface-default"),
    tableCell: Cn.c("table-td"),
    paginationWrapper: Cn.c("pt-3 flex justify-between items-end grow"),
    paginationLeft: Cn.c("flex items-center space-x-2"),
    rowsPerPage: Cn.c("font-paragraph-xsmall-regular text-subdued"),
    rowsPerPageSelect: Cn.c("w-16"),
    footer: Cn.c("border-t border-default p-6 flex justify-between")
}

interface Props {
    validationResults: Entity<ValidateBulkEvaluationCsvFileMutation, 'validateBulkEvaluationCSVFile.result'>[];
}

const CSVBulkEvaluationPreviewStep: FunctionComponent<Props> = ({
    validationResults
}) => {
    const { t } = useTranslation()
    const {
        rowsPerPageOptions,
        rowsPerPage,
        changeRowsPerPage,
        getPageCount,
        currentPage,
        setCurrentPage,
        displayRows,
        backToPreviousStep,
        previousStepName,
        startImportingCSV,
    } = useCSVBulkEvaluationPreviewStep(validationResults)

    return (
        <>
            <div className={styles.container}>
                <div className={Cn.join([styles.section, Cn.c("grow")])}>
                    <p className={styles.header}>{t("applications.bulkEvaluation.previewStep.sectionHeader.tablePreview")}</p>
                    <p><Trans
                        className={styles.label}
                        i18nKey={"applications.bulkEvaluation.previewStep.totalCount"}
                        count={validationResults.length}
                        components={{ highlight: <span className={styles.count}></span> }}
                    /></p>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.rowNumber")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.candidateEmail")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.candidateName")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.respondentEmail")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.question")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.bulkEvaluation.previewStep.tableHeaders.answer")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayRows.map((result, index) => (
                                <tr key={index} className={styles.tableTBodyRow}>
                                    <td className={styles.tableCell}>{index + 1}</td>
                                    <td className={styles.tableCell}>{result.candidateEmail}</td>
                                    <td className={styles.tableCell}>{result.candidateName}</td>
                                    <td className={styles.tableCell}>{result.respondentEmail}</td>
                                    <td className={styles.tableCell}>{result.question}</td>
                                    <td className={styles.tableCell}>{result.answer}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.paginationWrapper}>
                        <div className={styles.paginationLeft}>
                            <Select
                                name="rowsPerPage"
                                options={rowsPerPageOptions}
                                onChange={changeRowsPerPage}
                                value={rowsPerPage.toString()}
                                size="small"
                                direction="upward"
                                className={styles.rowsPerPageSelect}
                                rawPlaceholder={rowsPerPage.toString()}
                                noEmpty
                            />
                            <div className={styles.rowsPerPage}>{t('applications.bulkEvaluation.previewStep.rowsPerPage')}</div>
                        </div>
                        <Pagination pageCount={getPageCount(validationResults.length)} currentPage={currentPage} onSelectPage={setCurrentPage} />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    variant="secondary"
                    size="md"
                    onClick={backToPreviousStep}
                    iconName="arrowLeftLine">
                    {previousStepName === 'setup'
                        ? t("applications.bulkEvaluation.previewStep.backToSetup")
                        : t("applications.bulkEvaluation.previewStep.backToErrorCorrection")}
                </Button>
                <Button
                    variant="primaryFilled"
                    size="md"
                    iconName="arrowRightLine"
                    onClick={startImportingCSV}
                    isTrailingIcon>
                    {t("applications.bulkEvaluation.previewStep.startImport")}
                </Button>
            </div>
        </>
    )
}

export {
    CSVBulkEvaluationPreviewStep
}