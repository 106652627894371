import { z } from "zod";

export const onlineLocationType = ["MANUAL", "GOOGLE_MEET", "MICROSOFT_TEAMS", "ZOOM"] as const
export const offlineLocationType = [""] as const

type OnlineLocationType = typeof onlineLocationType[number]
type OfflineLocationType = typeof offlineLocationType[number]
export type AllLocationType = OnlineLocationType | OfflineLocationType

export namespace Encoder {
    export const defaultValues = (isOnline: boolean, location: string = "", defaultClientIds: string[] = []) => {
        return {
            location: location,
            locationType: isOnline ? "MANUAL" : "",
            clientIds: defaultClientIds
        }
    }

    export type Type = ReturnType<typeof defaultValues>
}

export namespace Decoder {
    export const schema = z.object({
        location: z.string(),
        locationType: z.enum([...onlineLocationType, ...offlineLocationType]).transform(values => values === "" ? undefined : values),
        clientIds: z.array(z.string())
    })

    export type Type = z.infer<typeof schema>;
}