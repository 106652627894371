import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';
import { useApplicationFilter } from './hook';
import { Controller } from 'react-hook-form';
import { Dropdown } from '@shared/unsorted/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { NestedCheckboxGroup } from "@shared/unsorted/NestedCheckboxGroup/NestedCheckboxGroup";
import { Label } from '@shared/unsorted/Label/Label';
import { Button } from '@shared/unsorted/Button/Button';
import { Search } from '@shared/unsorted/Search/Search';
import { EmptySearchResult } from './EmptySearchResult/EmptySearchResult';
import { isDefined, isString } from '@helpers/core/typeGuards';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { Decoder } from './value';

const styles = {
    root: Cn.c('flex gap-x-2 mb-4'),
    select: Cn.c('w-[13.125rem]'),
    selectMenu: Cn.c('w-[17.625rem] pb-2'),
    option: Cn.c('px-4'),
    selectHeader: Cn.c('flex pb-1 items-baseline justify-between'),
    selectInnerLabel: Cn.c('text-subdued font-paragraph-xsmall-medium pt-4 px-4'),
    clearButton: Cn.c('font-paragraph-xsmall-medium-link underline'),
    search: Cn.c('w-full px-4 pb-2'),
    tooltip: Cn.c('py-2 px-3 w-full'),
    dropdownLabelContainer: Cn.c('flex items-center overflow-hidden'),
    textLabel: Cn.c('grow whitespace-nowrap overflow-hidden text-ellipsis'),
    textSuffix: Cn.c('shrink-0 whitespace-nowrap'),
    checkboxGroupContainer: Cn.c('block max-h-80 overflow-y-auto'),
};

const ApplicationFilter: FunctionComponent = () => {
    const {
        state: {
            currentRecruitmentStepStatusOptions,
            currentJobPositionOptions,
            currentJobPositionStepsOptions,
            currentSchoolOptions,
        },
        candidateStatusOptions,
        candidateStatusOptionsCount,
        candidateStatusFilterLabel,
        onSelectFilter,
        currentJobPositionIds,
        form: {
            control, setValue
        },
        onSearchRecruitmentStepStatus,
        onSearchJobPosition,
        onSearchJobPositionSteps,
        onSearchSchool,
        onSearchCandidateStatus,
        getFilterLabel,
    } = useApplicationFilter();

    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            <Controller
                name="candidateStatuses"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                    return (
                        <Dropdown
                            size="small"
                            name={name}
                            label="applications.filters.candidateStatus"
                            placeholder="applications.filters.candidateStatus"
                            text={candidateStatusFilterLabel}
                            className={styles.select}
                            menuClassName={styles.selectMenu}
                            onClose={onSelectFilter}
                        >
                            <div className={styles.selectHeader}>
                                <Label label='applications.filters.candidateStatus' labelClassName={styles.selectInnerLabel} />
                                <Button
                                    size='sm'
                                    variant='primaryPlain'
                                    className={styles.clearButton}
                                    onClick={() => setValue('candidateStatuses', [])}
                                >
                                    {t('applications.filters.clear')}
                                </Button>
                            </div>
                            <Search
                                size="small"
                                onSearchAction={onSearchCandidateStatus}
                                searchOnChange
                                placeholder="applications.filters.searchPlaceholder"
                                className={styles.search}
                            />
                            {candidateStatusOptionsCount > 0 ?
                                (
                                    <NestedCheckboxGroup
                                        name={name}
                                        size="sm"
                                        onChange={onChange}
                                        value={value}
                                        options={candidateStatusOptions}
                                        valueEquals={Decoder.candidateStatusEquals}
                                    />
                                ) :
                                <EmptySearchResult />
                            }
                        </Dropdown>
                    )
                }}
            />
            <Controller
                name="schoolIds"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                    const filterLabel = getFilterLabel(value, currentSchoolOptions);

                    return <Dropdown
                        size='small'
                        name={name}
                        label="applications.filters.university"
                        placeholder='applications.filters.university'
                        text={isString(filterLabel)
                            ? filterLabel
                            : isDefined(filterLabel) &&
                            <ToolTipBox tooltipContent={filterLabel.label} toolTipClassName={styles.tooltip}>
                                <span className={styles.dropdownLabelContainer}>
                                    <span className={styles.textLabel}>{filterLabel.label}</span>
                                    {isDefined(filterLabel.labelSuffix) && <span className={styles.textSuffix}>&nbsp;{filterLabel.labelSuffix}</span>}
                                </span>
                            </ToolTipBox>
                        }
                        className={styles.select}
                        menuClassName={styles.selectMenu}
                        onClose={onSelectFilter}
                    >
                        <div className={styles.selectHeader}>
                            <Label label='applications.filters.university' labelClassName={styles.selectInnerLabel} />
                            <Button
                                size='sm'
                                variant='primaryPlain'
                                className={styles.clearButton}
                                onClick={() => setValue('schoolIds', [])}
                            >
                                {t('applications.filters.clear')}
                            </Button>
                        </div>
                        <Search
                            size="small"
                            onSearchAction={onSearchSchool}
                            searchOnChange
                            placeholder="applications.filters.searchPlaceholder"
                            className={styles.search}
                        />
                        {currentSchoolOptions.length === 0
                            ? <EmptySearchResult />
                            : <CheckboxGroup
                                className={styles.checkboxGroupContainer}
                                optionClassName={styles.option}
                                name={name}
                                size="sm"
                                onChange={onChange}
                                value={value}
                                options={currentSchoolOptions}
                            />
                        }
                    </Dropdown>
                }}
            />
            <Controller
                name="ongoingStepStatuses"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                    const filterLabel = getFilterLabel(value, currentRecruitmentStepStatusOptions);

                    return <Dropdown
                        size='small'
                        name={name}
                        label="applications.filters.recruitmentStepStatus"
                        placeholder='applications.filters.recruitmentStepStatus'
                        text={isString(filterLabel)
                            ? filterLabel
                            : isDefined(filterLabel) &&
                            <ToolTipBox tooltipContent={filterLabel.label} toolTipClassName={styles.tooltip}>
                                <span className={styles.dropdownLabelContainer}>
                                    <span className={styles.textLabel}>{filterLabel.label}</span>
                                    {isDefined(filterLabel.labelSuffix) && <span className={styles.textSuffix}>&nbsp;{filterLabel.labelSuffix}</span>}
                                </span>
                            </ToolTipBox>
                        }
                        className={styles.select}
                        menuClassName={styles.selectMenu}
                        onClose={onSelectFilter}
                    >
                        <div className={styles.selectHeader}>
                            <Label label='applications.filters.recruitmentStepStatus' labelClassName={styles.selectInnerLabel} />
                            <Button
                                size='sm'
                                variant='primaryPlain'
                                className={styles.clearButton}
                                onClick={() => setValue('ongoingStepStatuses', [])}
                            >
                                {t('applications.filters.clear')}
                            </Button>
                        </div>
                        <Search
                            size="small"
                            onSearchAction={onSearchRecruitmentStepStatus}
                            searchOnChange
                            placeholder="applications.filters.searchPlaceholder"
                            className={styles.search}
                        />
                        {currentRecruitmentStepStatusOptions.length === 0
                            ? <EmptySearchResult />
                            : <CheckboxGroup
                                className={styles.checkboxGroupContainer}
                                optionClassName={styles.option}
                                name={name}
                                size="sm"
                                onChange={onChange}
                                value={value}
                                options={currentRecruitmentStepStatusOptions}
                            />
                        }
                    </Dropdown>
                }}
            />
            <Controller
                name="jobPositionIds"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                    const filterLabel = getFilterLabel(value, currentJobPositionOptions);

                    return <Dropdown
                        size='small'
                        name={name}
                        label="applications.filters.jobTitle"
                        placeholder='applications.filters.jobTitle'
                        text={isString(filterLabel)
                            ? filterLabel
                            : isDefined(filterLabel) &&
                            <ToolTipBox tooltipContent={filterLabel.label} toolTipClassName={styles.tooltip}>
                                <span className={styles.dropdownLabelContainer}>
                                    <span className={styles.textLabel}>{filterLabel.label}</span>
                                    {isDefined(filterLabel.labelSuffix) && <span className={styles.textSuffix}>&nbsp;{filterLabel.labelSuffix}</span>}
                                </span>
                            </ToolTipBox>
                        }
                        className={styles.select}
                        menuClassName={styles.selectMenu}
                        onClose={onSelectFilter}
                    >
                        <div className={styles.selectHeader}>
                            <Label label='applications.filters.jobTitle' labelClassName={styles.selectInnerLabel} />
                            <Button
                                size='sm'
                                variant='primaryPlain'
                                className={styles.clearButton}
                                onClick={() => setValue('jobPositionIds', [])}
                            >
                                {t('applications.filters.clear')}
                            </Button>
                        </div>
                        <Search
                            size="small"
                            onSearchAction={onSearchJobPosition}
                            searchOnChange
                            placeholder="applications.filters.searchPlaceholder"
                            className={styles.search}
                        />
                        {currentJobPositionOptions?.length === 0
                            ? <EmptySearchResult />
                            : <CheckboxGroup
                                className={styles.checkboxGroupContainer}
                                optionClassName={styles.option}
                                name={name}
                                size="sm"
                                onChange={onChange}
                                value={value}
                                options={currentJobPositionOptions}
                            />
                        }
                    </Dropdown>
                }}
            />
            <Controller
                name="stepIds"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                    const filterLabel = getFilterLabel(value, currentJobPositionStepsOptions);

                    return <Dropdown
                        size='small'
                        name={name}
                        label="applications.filters.stepName"
                        placeholder='applications.filters.stepName'
                        text={isString(filterLabel)
                            ? filterLabel
                            : isDefined(filterLabel) &&
                            <ToolTipBox tooltipContent={filterLabel.label} toolTipClassName={styles.tooltip}>
                                <span className={styles.dropdownLabelContainer}>
                                    <span className={styles.textLabel}>{filterLabel.label}</span>
                                    {isDefined(filterLabel.labelSuffix) && <span className={styles.textSuffix}>&nbsp;{filterLabel.labelSuffix}</span>}
                                </span>
                            </ToolTipBox>
                        }
                        className={styles.select}
                        onClose={onSelectFilter}
                        menuClassName={styles.selectMenu}
                        disabled={currentJobPositionIds.length !== 1}
                        disabledTooltip={{
                            tooltipContent: t('applications.filters.stepNameDisabledTooltip'),
                            placement: 'top',
                            toolTipClassName: styles.tooltip,
                        }}
                    >
                        <div className={styles.selectHeader}>
                            <Label label='applications.filters.stepName' labelClassName={styles.selectInnerLabel} />
                            <Button
                                size='sm'
                                variant='primaryPlain'
                                className={styles.clearButton}
                                onClick={() => setValue('stepIds', [])}
                            >
                                {t('applications.filters.clear')}
                            </Button>
                        </div>
                        <Search
                            size="small"
                            onSearchAction={onSearchJobPositionSteps}
                            searchOnChange
                            placeholder="applications.filters.searchPlaceholder"
                            className={styles.search}
                        />
                        {currentJobPositionStepsOptions?.length === 0
                            ? <EmptySearchResult />
                            : <CheckboxGroup
                                className={styles.checkboxGroupContainer}
                                optionClassName={styles.option}
                                name={name}
                                size="sm"
                                onChange={onChange}
                                value={value}
                                options={currentJobPositionStepsOptions}
                            />
                        }
                    </Dropdown>
                }}
            />
        </div>
    );
};

export {
    ApplicationFilter
};
