import { FunctionComponent, useRef } from "react";
import { InitialStateData } from "../init";
import { TabHeader } from "@pages/SettingsPage/TabHeader/TabHeader";
import { Button } from "@shared/unsorted/Button/Button";
import { useTranslation } from "react-i18next";
import { Cn } from "@helpers/unsorted/classNames";
import { Controller } from "react-hook-form";
import { TextArea } from "@shared/unsorted/TextArea/TextArea";
import { usePreferencesForm } from "./hook";
import { PreferencesFormPromptModal } from "../PreferencesFormPromptModal/PreferencesFormPromptModal";

const styles = {
    container: Cn.c('w-full'),
    header: Cn.c("flex items-center justify-between"),
    body: Cn.c("flex items-center justify-between space-x-6"),
    section: Cn.c('pb-8 w-full'),
    sectionTitle: Cn.c('font-paragraph-base-medium text-emphasized mb-2'),
    description: Cn.c('font-paragraph-base-regular text-subdued mb-6'),
    buttons: Cn.c('flex space-x-4 justify-end'),
    actions: Cn.c('flex flex-row items-center gap-x-4'),
};

interface Props extends InitialStateData {
}

const PreferencesForm: FunctionComponent<Props> = ({
    emailSignatures
}) => {
    const { t } = useTranslation()

    const {
        form,
        onSubmit,
        isSubmitting,
        isEditClientAdmin,
        blocker,
        handleResetBlocker,
        discardChanges,
        handleResetForm,
    } = usePreferencesForm(emailSignatures);

    const formId = useRef('preferences_form')

    const { control, formState: { errors, isDirty, isValid }, clearErrors } = form;

    return (
        <>
            <form onSubmit={onSubmit} className={styles.container} id={formId.current}>
                <TabHeader
                    title="settings.preferences.title"
                    action={isEditClientAdmin ?
                        <div className={styles.actions}>
                            {isDirty && !isSubmitting && (
                                <Button
                                    type="button"
                                    size="md"
                                    iconName="refresh"
                                    variant="ghost"
                                    onClick={handleResetForm}
                                >
                                    {t("settings.account.actions.reset.button")}
                                </Button>
                            )}
                            <Button
                                size="md"
                                isDisabled={!isDirty || isSubmitting}
                                isLoading={isSubmitting}
                                type="submit"
                            >
                                {t('global.save')}
                            </Button>
                        </div>
                        : undefined
                    }
                />
                <div className={styles.body}>
                    <div className={styles.section} data-section="externalEmailSignature">
                        <p className={styles.sectionTitle}>{t("settings.preferences.emailSignature.externalEmailSignature")}</p>
                        <p className={styles.description}>{t("settings.preferences.emailSignature.externalEmailSignatureDescription")}</p>
                        <Controller
                            name='externalEmailSignature'
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <TextArea
                                    name={name}
                                    orientation='vertical'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    placeholder='settings.preferences.emailSignature.externalEmailPlaceholder'
                                    disabled={!isEditClientAdmin}
                                />
                            }
                        />
                    </div>
                    <div className={styles.section} data-section="internalEmailSignature">
                        <p className={styles.sectionTitle}>{t("settings.preferences.emailSignature.internalEmailSignature")}</p>
                        <p className={styles.description}>{t("settings.preferences.emailSignature.externalEmailSignatureDescription")}</p>
                        <Controller
                            name='internalEmailSignature'
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <TextArea
                                    name={name}
                                    orientation='vertical'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    placeholder='settings.preferences.emailSignature.internalEmailPlaceholder'
                                    disabled={!isEditClientAdmin}
                                />
                            }
                        />
                    </div>
                </div>
            </form>
            <PreferencesFormPromptModal
                formId={formId.current}
                isOpen={blocker.state === "blocked"}
                cancel={handleResetBlocker}
                proceed={discardChanges}
                isDisabled={!isDirty || !isValid || isSubmitting}
                isSubmitting={isSubmitting}
            />
        </>
    )
}

export {
    PreferencesForm
}