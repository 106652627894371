import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    root: Cn.c('px-4 py-3 text-subdued font-paragraph-xsmall-regular'),
};

const EmptySearchResult: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.root}>
            {t("applications.filters.emptySearch")}
        </div>
    );
};

export {
    EmptySearchResult
};
