import { FunctionComponent } from "react"

interface Props {
    text: string
}

const Trans: FunctionComponent<Props> = ({ text }) => {
    return (
        <>{text}</>
    );
}

export {
    Trans
}