import { Event_OneByIdQuery } from "@entities";
import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
  container: Cn.c(
    "bg-default rounded-lg flex items-center p-4 space-x-3",
  ),
  item: Cn.c("flex items-center space-x-1"),
  icon: Cn.c("w-4 h-4 text-icons-disabled"),
  label: Cn.c("text-subdued font-paragraph-small-regular"),
  value: Cn.c("text-emphasized font-paragraph-small-medium")
}

interface Props {
  event: Entity<Event_OneByIdQuery, 'event'>;
}

const EventDetails: FunctionComponent<Props> = ({ event }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Icon name="time" className={styles.icon} />
        <span className={styles.label}>
          {t('event.addSession.duration')}
        </span>
        <p className={styles.value}>{`${event.duration} ${t('event.addSession.durationUnit')}`}</p>
      </div>
      <div className={styles.item}>
        <Icon name="candidates" className={styles.icon} />
        <span className={styles.label}>
          {t('event.addSession.candidateCapacity')}
        </span>
        <p className={styles.value}>{`${event.candidateCapacity} ${t('event.addSession.candidateCapacityUnit')}`}</p>
      </div>
      <div className={styles.item}>
        <Icon name="discuss" className={styles.icon} />
        <span className={styles.label}>
          {t('event.addSession.format')}
        </span>
        <p className={styles.value}>{event.isOnline
          ? t('event.format.online')
          : t('event.format.offline')}</p>
      </div>
    </div>
  );
}

export {
  EventDetails
}