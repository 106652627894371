import { assertNever } from "@helpers/typeHelpers";
import { Cn } from "@helpers/unsorted/classNames";
import { Icon, IconName } from "@shared/unsorted/Icon/Icon";
import { useCallback, forwardRef } from "react";
import { Size } from "../styles";

const styles = {
    button: (isOpen: boolean, disabled: boolean, size: Size) =>
        Cn.join([
            Cn.c(
                `w-full flex flex-row text-emphasized cursor-pointer items-center justify-between rounded-lg px-3 py-2 bg-white border border-default`
            ),
            Cn.ifTrue(isOpen, Cn.c("border-primary-default")),
            Cn.ifTrue(
                disabled,
                Cn.join([
                    Cn.c("bg-surface-disabled text-disabled"),
                    Cn.ifFalse(isOpen, Cn.c("cursor-not-allowed")),
                ])
            ),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("py-2 px-3 h-8");
                    }
                    case "medium": {
                        return Cn.c("p-4 h-10");
                    }
                    case "large": {
                        return Cn.c("p-4 h-12");
                    }
                    case "x-large": {
                        return Cn.c("py-4 px-6 h-16");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    label: (size: Size) =>
        Cn.join([
            Cn.c("truncate flex flex-row items-center"),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("font-paragraph-xsmall-regular");
                    }
                    case "medium": {
                        return Cn.c("font-paragraph-small-regular");
                    }
                    case "large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    case "x-large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    icon: (size: Size) =>
        Cn.join([
            Cn.c("inline-block mr-2 shrink-0"),
            (() => {
                switch (size) {
                    case "small":
                    case "medium": {
                        return Cn.c("w-3.5 h-3.5");
                    }
                    case "large":
                    case "x-large": {
                        return Cn.c("w-4 h-4");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    toggleIcon: (isOpen: boolean, size: Size) =>
        Cn.join([
            Cn.c(
                `shrink-0 ml-1 text-emphasis rotate-${
                    isOpen ? "180" : "0"
                } transition-all`
            ),
            (() => {
                switch (size) {
                    case "small":
                    case "medium": {
                        return Cn.c("w-4 h-4");
                    }
                    case "large":
                    case "x-large": {
                        return Cn.c("w-6 h-6");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
};

type Props = Omit<
    React.HTMLProps<HTMLButtonElement>,
    "ref" | "type" | "size" | "open" | "close"
> & {
    isOpen: boolean;
    open: VoidFunction;
    close: VoidFunction;
    size: Size;
    iconName?: IconName;
    disabled?: boolean;
};

const DropdownToggle = forwardRef<HTMLButtonElement, Props>(
    (props, forwardedRef) => {
        const {
            children,
            isOpen,
            open,
            close,
            size,
            iconName,
            className = "",
            disabled = false,
            onClick = () => undefined,
            ...rest
        } = props;

        const handleToggle = useCallback(() => {
            if (isOpen) {
                close();
            } else if (!disabled) {
                open();
            }
        }, [isOpen, disabled, open, close]);

        const handleClick = useCallback(
            (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick(event);
                handleToggle();
            },
            [onClick, handleToggle]
        );

        return (
            <button
                className={Cn.join([
                    styles.button(isOpen, disabled, size),
                    className,
                ])}
                onClick={handleClick}
                type="button"
                aria-haspopup="true"
                aria-expanded={isOpen}
                ref={forwardedRef}
                {...rest}
            >
                <span className={styles.label(size)}>
                    {iconName && (
                        <Icon name={iconName} className={styles.icon(size)} />
                    )}
                    {children}
                </span>
                <Icon
                    className={styles.toggleIcon(isOpen, size)}
                    name="arrowDown"
                />
            </button>
        );
    }
);

export { DropdownToggle, Props };
