import { Cn } from '@helpers/unsorted/classNames';
import { Input, Size } from '@shared/unsorted/Input/Input';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useSearch } from './hook';
import { I18nKey } from 'react-i18next';

const styles = {
    searchForm: Cn.c('w-72'),
};

interface Props {
    /** Keyword to be searched */
    keyword?: string | null;
    /** Function to be called when search is triggered */
    onSearchAction: (keyword: string) => void;
    /** Custom style class names for the search input field */
    className?: string;
    /** Size of the search input field */
    size?: Size;
    /** Placeholder text for the search input field. Defaults to global.searchPlaceholder */
    placeholder?: I18nKey;
    /** Whether to allow the search's own reset icon (X) to be displayed and it's own form reset to be executed. Defaults to true */
    allowReset?: boolean;
    /** Whether to trigger search action on each change in the search input field. Defaults to false which performs search on form submit */
    searchOnChange?: boolean;
}

const Search: FunctionComponent<Props> = ({
    onSearchAction,
    keyword,
    className,
    size = 'medium',
    placeholder = "global.searchPlaceholder",
    allowReset = true,
    searchOnChange = false,
}) => {
    const {
        form: { control, formState: { errors }, clearErrors, reset },
        onSubmit,
    } = useSearch(onSearchAction, searchOnChange, keyword);

    const resetForm = () => {
        reset({ searchKeyword: '' });
        onSearchAction('');
    }

    return (
        <form onSubmit={searchOnChange ? (e) => e.preventDefault() : onSubmit}
            className={Cn.join([
                styles.searchForm,
                Cn.getSome(className),
            ])}>
            <Controller
                control={control}
                name="searchKeyword"
                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                    <Input
                        iconName="search"
                        size={size}
                        name={name}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onChange={onChange}
                        errors={errors}
                        clearErrors={clearErrors}
                        forwardedRef={ref}
                        value={value}
                        reset={allowReset ? resetForm : undefined}
                    />}
            />
        </form>
    );
};

export {
    Search
};
