import { BlobReader, BlobWriter, ZipWriter } from '@zip.js/zip.js';

/**
 * Automatically downloads a file, using the provided url and file name.
 *
 * @param {string} fileName
 * @param {string} url
 */
const downloadUrl = async (fileName: string, url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const actualUrl = URL.createObjectURL(blob)

  const element = document.createElement("a");
  element.setAttribute("href", actualUrl);
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
  window.URL.revokeObjectURL(actualUrl);
};

/**
 * Automatically downloads multiple files, using the provided urls and file names.
 * The files will be downloaded as a zip file with the provided name.
 *
 * @param {{ fileName: string; url: string }[]} files
 * @param {string} archiveName
 */
const downloadMultipleUrls = async (files: { fileName: string; url: string }[], archiveName: string = 'files.zip') => {
  // Create the archive

  const zipWriter = new ZipWriter(new BlobWriter("application/zip"));

  // Sanitise the file list to avoid duplicate names

  const fileNames: Record<string, number> = {};
  const sanitisedFiles = files.map(({ fileName, url }) => {
    let sanitisedFileName = fileName;
    if (fileNames[fileName] === undefined) {
      fileNames[fileName] = 1;
    } else {
      const extension = fileName.split('.').pop();
      sanitisedFileName = fileName.replace(`.${extension}`, ` (${fileNames[fileName]}).${extension}`);
      fileNames[fileName]++;
    }

    return { fileName: sanitisedFileName, url };
  });
  
  // Add the files to the archive
  
  await Promise.all(sanitisedFiles.map(async ({ fileName, url }) => 
      fetch(url)
          .then(response => response.blob())
          .then(blob => zipWriter.add(fileName, new BlobReader(blob))),
  ));
  const zipBlob = await zipWriter.close();
  
  // Download the archive as a zip file
  
  const element = Object.assign(document.createElement('a'), {
    style: 'display: none;',
    href: URL.createObjectURL(zipBlob),
    download: archiveName,
  });
  
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(element.href);
  document.body.removeChild(element);
};

export {
    downloadUrl,
    downloadMultipleUrls,
};
