import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";
import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent, useRef, useState } from "react";
import { Icon } from "../Icon/Icon";
import { Link } from "../Link/Link";

const styles = {
    container: Cn.c("flex items-center"),
    homeIcon: Cn.c("w-4 h-4 hover:underline text-icons-disabled"),
    itemContainer: Cn.c("flex items-center"),
    arrowIcon: (isCurrentItem: boolean) => Cn.join([
        Cn.c("w-4 h-4 mx-2"),
        isCurrentItem ? Cn.c("text-icons-emphasized") : Cn.c("text-icons-disabled")
    ]),
    item: (isCurrentItem: boolean) => Cn.join([
        Cn.c("font-paragraph-base-medium hover:underline cursor-pointer"),
        isCurrentItem ? Cn.c("text-emphasized") : Cn.c("text-subdued")
    ]),
    moreIconContainer: Cn.c("relative"),
    moreIcon: Cn.c("w-4 h-4 cursor-pointer text-icons-emphasized"),
    extraMenu: (isOpened: boolean) => Cn.join([
        Cn.c("absolute top-4 left-8 bg-surface-default rounded-lg shadow-md py-2"),
        Cn.ifFalse(isOpened, Cn.c("hidden"))
    ]),
    extraMenuItem: Cn.c("block flex py-2 px-4 truncate font-paragraph-xsmall-regular text-emphasized"),
}

interface BreadcrumbLinkItem {
    type: 'link';
    label: string;
    link: string;
}

interface BreadcrumbActionItem {
    type: 'action';
    label: string;
    action: VoidFunction;
}

type BreadcrumbItem = BreadcrumbLinkItem | BreadcrumbActionItem;

interface Props {
    items: BreadcrumbItem[],
    className?: string
}

const Breadcrumb: FunctionComponent<Props> = ({ items, className }) => {
    const [isOpened, setIsOpened] = useState(false);

    const toggle = () => {
        setIsOpened(!isOpened);
    }

    const extraMenuRef = useRef<HTMLDivElement>(null);

    useClickOutside([extraMenuRef], () => {
        setIsOpened(false);
    });

    const firstItem = items[0]
    const lastItem = items.length > 1 ? items[items.length - 1] : undefined
    const remainingItems = items.slice(1, items.length - 1)

    return (
        <div className={Cn.join([styles.container, Cn.getSome(className)])}>
            {firstItem &&
                <div className={styles.itemContainer}>
                    {firstItem.type === "link"
                        ? <Link to={firstItem.link} className={styles.item(items.length === 1)}>{firstItem.label}</Link>
                        : <span className={styles.item(items.length === 1)} onClick={firstItem.action}>{firstItem.label}</span>
                    }
                </div>
            }
            {remainingItems.length > 1
                ? <div className={Cn.join([styles.itemContainer, styles.moreIconContainer])} ref={extraMenuRef}>
                    <Icon name="arrowRight" className={styles.arrowIcon(false)} />
                    <Icon name="more" className={styles.moreIcon} onClick={toggle} />
                    <div className={styles.extraMenu(isOpened)}>
                        {
                            remainingItems.map((item) => {
                                return (
                                    item.type === "link"
                                        ? <Link key={item.label} to={item.link} className={Cn.join([styles.item(false), styles.extraMenuItem])}>
                                            {item.label}
                                        </Link>
                                        : <span className={Cn.join([styles.item(false), styles.extraMenuItem])} onClick={item.action}>{item.label}</span>
                                );
                            })
                        }
                    </div>
                </div>
                : remainingItems.length === 1
                    ? <div className={styles.itemContainer}>
                        <Icon name="arrowRight" className={styles.arrowIcon(false)} />
                        {remainingItems[0].type === "link"
                            ? <Link key={remainingItems[0].label} to={remainingItems[0].link} className={styles.item(false)}>
                                {remainingItems[0].label}
                            </Link>
                            : <span className={styles.item(false)} onClick={remainingItems[0].action}>{remainingItems[0].label}</span>
                        }
                    </div>
                    : null
            }

            {lastItem &&
                <div className={styles.itemContainer}>
                    <Icon name="arrowRight" className={styles.arrowIcon(true)} />
                    {lastItem.type === "link"
                        ? <Link to={lastItem.link} className={styles.item(true)}>{lastItem.label}</Link>
                        : <span className={styles.item(true)} onClick={lastItem.action}>{lastItem.label}</span>
                    }
                </div>
            }
        </div>
    );
}

export {
    Breadcrumb
};
