import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationStepMode, State } from '../reducer';
import { EmailDisplay } from './EmailThreadItem/EmailDisplay/EmailDisplay';
import { EmailEditor } from './EmailThreadItem/EmailEditor/EmailEditor';
import { EmailThreadItem } from './EmailThreadItem/EmailThreadItem';
import { useApplicationCandidateEmail } from './hook';

const styles = {
  emailContainer: Cn.c(
    "bg-surface-default flex flex-col min-h-application-panel-tab rounded",
  ),
  emptyTable: Cn.c("w-full flex flex-col items-center justify-center flex-grow p-4"),
  addButtonWrapper: Cn.c("flex flex-row items-center space-x-1.5"),
  replyContainer: Cn.c("p-4 pt-2")
}

interface Props {
  application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
  applicationStepState: State;
  dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void;
}

const ApplicationCandidateEmail: FunctionComponent<Props> = ({ application, applicationStepState, dispatchApplicationStepMode }) => {
  const { t } = useTranslation()

  const {
    emailsHistory,
    applicationEmail,
    mapRecipients,
    reply
  } = useApplicationCandidateEmail(application, dispatchApplicationStepMode)

  return (
    <div className={styles.emailContainer}>
      {
        applicationStepState.applicationStepMode.name === 'submit'
          ? <EmailEditor key='submit' applicationId={application.id} dispatchApplicationStepMode={dispatchApplicationStepMode} />
          : applicationStepState.applicationStepMode.name === 'show' && emailsHistory.length === 0
            ? <div className={styles.emptyTable}>
              <EmptyStateContainer
                icon='emptyEmail'
                description='applications.panel.candidateEmail.emptyDescription'
                subDescription='applications.panel.candidateEmail.emptySubDescription'
              />
              <Button
                iconName='plus'
                size='md'
                className={styles.addButtonWrapper}
                onClick={_ => dispatchApplicationStepMode({ name: 'submit' })}>
                {t('applications.panel.candidateEmail.createEmail')}
              </Button>
            </div>
            : null}
      {emailsHistory.map((
        { id, subject, senderName, senderEmailAddress, recipients, body, sentAt },
        idx,
      ) =>
        // TODO: Update isReceived after implementing automatic subdomain verification
        <EmailThreadItem
          subject={subject}
          sender={senderName || ""}
          key={id}
          index={idx}
          date={new Date(sentAt)}
          isReceived={!(senderEmailAddress === applicationEmail)}>
          <EmailDisplay
            subject={subject}
            senderName={senderName || ""}
            senderEmailAddress={senderEmailAddress}
            date={new Date(sentAt)}
            body={body}
            recipients={mapRecipients(recipients)}
          />
          {
            applicationStepState.applicationStepMode.name === 'replyEmail' &&
              applicationStepState.applicationStepMode.payload.emailThreadItemId === id
              ? <EmailEditor key='reply' applicationId={application.id} dispatchApplicationStepMode={dispatchApplicationStepMode} />
              : <div className={styles.replyContainer}>
                <Button size='md' variant='primaryFilled' onClick={() => reply(id)}>
                  {t('applications.panel.candidateEmail.reply')}
                </Button>
              </div>
          }
        </EmailThreadItem>
      )}
    </div>
  );
};

export {
  ApplicationCandidateEmail,
};

