import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { BulkEventSessionAssignmentModalPayload } from "../reducer"
import { Modal } from "@shared/unsorted/Modal/Modal"
import { Cn } from "@helpers/unsorted/classNames"
import { useTranslation } from "react-i18next"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { useStepper } from "@shared/layout/stepper/useStepper"
import { StepContainer } from "@shared/layout/stepper/StepContainer"
import { SessionSelectionStep } from "./steps/sessionSelection/SessionSelectionStep"
import { SessionGroupSelectionStep } from "./steps/sessionGroupSelection/SessionGroupSelectionStep"
import { BulkEventSessionAssignmentProvider } from "./BulkEventSessionAssignmentContext"
import { useBulkEventSessionAssignmentModal } from "./hook"

interface Props extends ModalProps {
    payload?: BulkEventSessionAssignmentModalPayload
}

const styles = {
    container: Cn.c("h-[51.5rem] overflow-auto flex flex-col"),
    form: Cn.c("flex-1 overflow-auto flex flex-col"),
    header: Cn.c("p-6 flex justify-between items-center border-b border-default"),
    title: Cn.c("text-emphasized font-h4-bold"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("overflow-auto flex flex-col flex-1"),
    stepTracker: Cn.c("p-6"),
    stepBody: Cn.c("flex-1 flex flex-col overflow-auto")
}

const BulkEventSessionAssignmentModal: FunctionComponent<Props> = ({
    isOpen,
    payload,
}) => {
    const { t } = useTranslation();
    const { ControlledStepper, goToStep } = useStepper('sessionSelection')
    const {
        closeModal,
        state,
        dispatch
    } = useBulkEventSessionAssignmentModal(goToStep, payload)

    return (
        <BulkEventSessionAssignmentProvider value={[state, dispatch]}>
            <Modal
                isOpen={isOpen}
                close={closeModal}
                widthClass="w-[46.875rem]"
                className={styles.container}
            >
                <div className={styles.form}>
                    <div className={styles.header} data-section="header">
                        <p className={styles.title}>{t(`applications.bulkAction.ASSIGN_TO_SESSION_MANUALLY.title`)}</p>
                        <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                    </div>
                    <ControlledStepper
                        stepTracker={state.stepTracker}
                        trackerClassname={styles.stepTracker}
                        className={styles.body}
                    >
                        <StepContainer id="sessionSelection" className={styles.stepBody}>
                            {state.currentStep.name === 'sessionSelection' &&
                                <SessionSelectionStep />}
                        </StepContainer>
                        <StepContainer id="sessionGroupSelection" className={styles.stepBody}>
                            {state.currentStep.name === 'sessionGroupSelection' &&
                                <SessionGroupSelectionStep
                                />}
                        </StepContainer>
                    </ControlledStepper>
                </div>
            </Modal>
        </BulkEventSessionAssignmentProvider>
    )
}

export {
    BulkEventSessionAssignmentModal
}