import { NonNullableProperties } from '@typedefs/helpers';
import { If } from '@typedefs/logic';
import { TupleToUnion } from '@typedefs/tuples';
import { IsOpenString } from '@typedefs/typeChecks';

type TypeGuard<GuardedType> = (value: unknown) => value is GuardedType;

const not = <ExcludedType>(typeGuard: TypeGuard<ExcludedType>) => <T>(value: T | ExcludedType): value is T => !typeGuard(value);

const isNull = (value: unknown): value is null => value === null;
const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';
const isNullish = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

const isNonNull = not(isNull);
const isDefined = not(isUndefined);
const hasValue = not(isNullish);

const isString = (value: unknown): value is string => typeof value === 'string';
const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value) && isFinite(value);
const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

const isFunction = (value: unknown): value is Function => typeof value === 'function';

const isArray = (value: unknown): value is unknown[] => Array.isArray(value);
const isArrayOf = <ItemType>(typeGuard: TypeGuard<ItemType>) => (value: unknown): value is ItemType[] => {
    return isArray(value)
        && value
            .map(item => typeGuard(item))
            .reduce((isFullyGuarded, isItemGuarded) => isFullyGuarded && isItemGuarded, true);
};

const isObject = (value: unknown): value is Record<string, unknown> => !isNull(value) && typeof value === 'object';
const hasProperty = <Key extends string, PropertyType = unknown>(
    key: Key,
    typeGuard?: TypeGuard<PropertyType>,
) => (value: unknown): value is If<IsOpenString<Key>, unknown, { [J in Key]: PropertyType }> => {
    return !isNullish(value)
        && isObject(value)
        && Object.prototype.hasOwnProperty.call(value, key)
        && (isUndefined(typeGuard) || typeGuard(value[key]));
};

const hasNonNullableProperties = <T extends unknown>(value: T): value is NonNullableProperties<T> => {
    return isObject(value) && Object.values(value).every(property => isNonNull(property));
};

const isNullable = <GuardedType>(typeGuard: TypeGuard<GuardedType>) => isSome(isNull, isUndefined, typeGuard);

const isOneOf = <Type extends string | number | boolean>(list: readonly Type[]) =>
    (value: unknown): value is Type => list.some(item => value === item);

const isSome = <GuardedTypes extends unknown[]>(
    ...typeGuards: [...{ [Key in keyof GuardedTypes]: TypeGuard<GuardedTypes[Key]> }]
) => (value: unknown): value is TupleToUnion<GuardedTypes> => typeGuards.some(typeGuard => typeGuard(value));

const isEmptyObject = (value: unknown): value is {} => isObject(value) && Object.keys(value).length === 0;

const isPrimitive = isSome(isString, isNumber, isBoolean, isNullish);

export {
    TypeGuard,

    not,

    isNull,
    isUndefined,
    isNullish,
    isNonNull,
    isDefined,
    hasValue,

    isString,
    isNumber,
    isBoolean,

    isFunction,

    isArray,
    isArrayOf,

    isObject,
    hasProperty,
    
    hasNonNullableProperties,

    isNullable,

    isOneOf,
    isSome,

    isEmptyObject,

    isPrimitive,
};
