import { cacheExchange, createClient, dedupExchange, subscriptionExchange } from "urql";
import config from "@helpers/core/config";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { create } from "@helpers/core/subscriptionClient";

const getExchanges = (accessToken?: string) => {
  const exchanges = [dedupExchange, cacheExchange, multipartFetchExchange]

  if (!accessToken) {
    return exchanges
  }

  const protocol = config.environment === "dev" ? "ws" : "wss"

  //TODO: replace ws with wss in production
  const clientSubscription = create({
    url: `${protocol}://${config.apiDomain}/subscriptions`,
    connectionParams: { "accessToken": accessToken },
  })

  return exchanges.concat([subscriptionExchange({
    forwardSubscription: (operation) => ({
      subscribe: (sink) => ({
        unsubscribe: clientSubscription.subscribe(operation, sink)
      })
    })
  })])
}

//TODO: setup exchanges and subscriptions
const createUrqlClient = (accessToken?: string) => {
  const client = createClient({
    url: config.graphqlEndpoint,
    fetchOptions: {
      credentials: 'include'
    },
    exchanges: getExchanges(accessToken)
  });

  return client;
}

export {
  createUrqlClient
}
