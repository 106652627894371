import { useContext } from "react";
import { JobListPanelContext } from "./JobListPanelContext";
import { isUndefined } from "@helpers/core/typeGuards";

const useJobListPanelContext = () => {
    const context = useContext(JobListPanelContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <JobListPanelContext.Provider>...</JobListPanelContext.Provider> block.');
    }

    return context;
}

export {
    useJobListPanelContext
}