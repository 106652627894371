import { SelectableStepTypesQuery } from '@entities';
import { Entity } from '@typedefs/graphql';

interface LoadingState {
    loading: true
}

interface LoadedState {
    loading: false
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>
}

export type State = LoadingState | LoadedState;

interface CreateJobPositionAction {
    name: 'CreateJobPosition'
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>
}

type Action = CreateJobPositionAction

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'CreateJobPosition':

            return {
                ...state,
                loading: false,
                recruitmentStepTypes: action.recruitmentStepTypes,
            };
        default:
            return { ...state };
    }
};

const initialState: State = {
    loading: true,
};

export {
    initialState,
    reducer,
};

