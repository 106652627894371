import { RecruitmentProcess_OneByIdQuery } from "@entities";
import { Entity } from "@typedefs/graphql";
import { v4 as uuidv4 } from 'uuid'

interface PendingDocument {
    name: 'pendingDocument';
    payload: {
        id: string;
        filename: string;
        file: File;
    }
}

interface UploadedDocument {
    name: 'uploadedDocument',
    payload: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>
}

type Document = PendingDocument | UploadedDocument

interface State {
    internalMode: 'list' | 'edit';
    isUploading: boolean;
    currentDocuments: Document[];
    documentsToUpload: PendingDocument[];
    documentsToDelete: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[];
}

interface SwitchToEditAction {
    name: 'SwitchToEdit',
    payload: {
        initialDocuments: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[];
    }
}

interface SwitchToListAction {
    name: 'SwitchToList'
}

interface RemoveDocumentAction {
    name: 'RemoveDocument',
    payload: {
        documentId: string;
        initialDocuments: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[];
    }
}

interface UploadDocumentAction {
    name: 'UploadDocument',
    payload: {
        files: File[];
    }
}

interface SetLoadingAction {
    name: 'SetLoading',
    payload: {
        isUploading: boolean;
    }
}

type Action = SwitchToEditAction | SwitchToListAction | RemoveDocumentAction | UploadDocumentAction | SetLoadingAction

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'SwitchToEdit':
            return {
                ...state,
                internalMode: 'edit',
                currentDocuments: action.payload.initialDocuments.map(file => ({
                    name: 'uploadedDocument',
                    payload: file
                })),
            }
        
        case 'SwitchToList':
            return {
                ...state,
                documentsToDelete: [],
                documentsToUpload: [],
                internalMode: 'list'
            }

        case 'RemoveDocument': {
            const removedDocumentId = action.payload.documentId
            const deleteDocument = action.payload.initialDocuments.find(doc => doc.id === removedDocumentId)

            return {
                ...state,
                currentDocuments: state.currentDocuments.filter(doc => {
                    return doc.payload.id !== removedDocumentId
                }),
                documentsToUpload: state.documentsToUpload.filter(doc => doc.payload.id !== removedDocumentId),
                documentsToDelete: deleteDocument ? state.documentsToDelete.concat([deleteDocument]) : state.documentsToDelete
            }
        }
            
        case 'UploadDocument': {
            const documentsToUpload: PendingDocument[] = action.payload.files.map(file => ({
                name: 'pendingDocument',
                payload: {
                    file,
                    filename: file.name,
                    id: uuidv4()
                }
            }))

            return {
                ...state,
                documentsToUpload: state.documentsToUpload.concat(documentsToUpload),
                currentDocuments: state.currentDocuments.concat(documentsToUpload)
            }
        }

        case 'SetLoading':
            return {
                ...state,
                isUploading: action.payload.isUploading
            }
    
        default:
            return {...state}
    }
}

const initialState = (initialDocuments: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[]): State => ({
    internalMode: 'list',
    isUploading: false,
    currentDocuments: initialDocuments.map(file => ({
        name: 'uploadedDocument',
        payload: file
    })),
    documentsToUpload: [],
    documentsToDelete: []
})

export {
    reducer,
    initialState,
    Document
}