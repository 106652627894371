import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from '@helpers/unsorted/classNames';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    container: Cn.c('flex gap-x-3 bg-surface-default px-4 pt-4 text-default font-paragraph-xsmall-regular'),
    contentContainer: Cn.c('flex gap-x-2 items-center'),
    title: Cn.c('rounded font-paragraph-xsmall-medium bg-surface-emphasized py-0.5 px-1')
};

interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
}

const ApplicationInformation: FunctionComponent<Props> = ({ application }) => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const candidate = application.candidate;

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>{t("applications.panel.candidateApplicationInformationDisplay.informationAcquisition")}</div>
                <div>
                    {`${dateFormat.simpleDate(new Date(application.createdAt), "/", currentLanguage)}`}
                </div>
                <div>{getWithDefault(candidate.acquisitionChannel?.name)}</div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.title}>{t("applications.panel.candidateApplicationInformationDisplay.entryChannel")}</div>
                {/* TODO: [IMPLEMENT] Change this date to actual date when an Application is moved to Stage: Screening (new step system) */}
                <div>2023/04/17 (Mon)</div>
                <div>{getWithDefault(candidate.entryChannel?.name)}</div>
            </div>
        </div>
    );
};

export {
    ApplicationInformation,
};