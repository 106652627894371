import { isDefined } from '@helpers/core/typeGuards';
import { FunctionComponent, MouseEventHandler } from 'react';

import { assertNever } from '@helpers/typeHelpers';
import { Cn } from '@helpers/unsorted/classNames';

import Archive from "../../../../../public/icons/archive-fill.svg";
import Arrow from "../../../../../public/icons/arrow.svg";
import CalendarCheck from "../../../../../public/icons/calendar-check.svg";
import Down from "../../../../../public/icons/down.svg";
import Download from "../../../../../public/icons/download.svg";
import DragHandle from "../../../../../public/icons/drag-handle.svg";
import Edit from "../../../../../public/icons/edit.svg";
import Email from "../../../../../public/icons/email.svg";
import Form from "../../../../../public/icons/form.svg";
import JobPositions from "../../../../../public/icons/job-positions.svg";
import Location from "../../../../../public/icons/location-fill.svg";
import Mail from "../../../../../public/icons/mail.svg";
import Phone from "../../../../../public/icons/phone.svg";
import Profile from "../../../../../public/icons/profile.svg";
import RemixIconsArrowDoubleLeft from "../../../../../public/icons/remix-icons_arrow-double-left.svg";
import RemixIconsArrowDoubleRight from "../../../../../public/icons/remix-icons_arrow-double-right.svg";
import RemixIconsCaret from "../../../../../public/icons/remix-icons_arrow-down-s-fill.svg";
import RemixIconsArrowDown from "../../../../../public/icons/remix-icons_arrow-down.svg";
import RemixIconsArrowLeftDownLine from "../../../../../public/icons/remix-icons_arrow-left-down-line.svg";
import RemixIconsArrowLeftLine from "../../../../../public/icons/remix-icons_arrow-left-line.svg";
import RemixIconsArrowLeft from "../../../../../public/icons/remix-icons_arrow-left.svg";
import RemixIconsArrowRightLine from "../../../../../public/icons/remix-icons_arrow-right-line.svg";
import RemixIconsArrowRightUpLine from "../../../../../public/icons/remix-icons_arrow-right-up-line.svg";
import RemixIconsArrowRight from "../../../../../public/icons/remix-icons_arrow-right.svg";
import RemixIconsArrowUp from "../../../../../public/icons/remix-icons_arrow-up.svg";
import RemixIconsBriefcase from "../../../../../public/icons/remix-icons_briefcase-fill.svg";
import RemixIconsBriefcaseFilled from "../../../../../public/icons/remix-icons_briefcase.svg";
import RemixIconsCalendarFill from "../../../../../public/icons/remix-icons_calendar-fill.svg";
import RemixIconsCalendar from "../../../../../public/icons/remix-icons_calendar.svg";
import RemixIconsUser2Fill from "../../../../../public/icons/remix-icons_user-2-fill.svg";
import RemixIconsCandidates from "../../../../../public/icons/remix-icons_candidates.svg";
import RemixIconsCheckCircle from "../../../../../public/icons/remix-icons_check-circle.svg";
import RemixIconsCrossCircle from "../../../../../public/icons/remix-icons_cross-circle.svg";
import RemixIconsCheck from "../../../../../public/icons/remix-icons_check.svg";
import RemixIconsClose from "../../../../../public/icons/remix-icons_close.svg";
import RemixIconsCopy from "../../../../../public/icons/remix-icons_copy.svg";
import RemixIconsDashBoard from "../../../../../public/icons/remix-icons_dashboard.svg";
import RemixIconsDelete from "../../../../../public/icons/remix-icons_delete.svg";
import RemixIconsDeleteFilled from "../../../../../public/icons/remix-icons_delete_filled.svg";
import RemixIconsDiscuss from "../../../../../public/icons/remix-icons_discuss-fill.svg";
import RemixIconsDocument from "../../../../../public/icons/remix-icons_document-line.svg";
import RemixIconsDocumentNewspaperFill from "../../../../../public/icons/remix-icons_document-newspaper-fill.svg";
import RemixIconsDownloadFile from "../../../../../public/icons/remix-icons_download-file.svg";
import RemixIconsEn from "../../../../../public/icons/remix-icons_en.svg";
import RemixIconsErrorWarning from "../../../../../public/icons/remix-icons_error-warning.svg";
import RemixIconsExit from "../../../../../public/icons/remix-icons_exit.svg";
import RemixIconsFileEdit from "../../../../../public/icons/remix-icons_file-edit-fill.svg";
import RemixIconsFileList from "../../../../../public/icons/remix-icons_file-list-fill.svg";
import RemixIconsFile from "../../../../../public/icons/remix-icons_file.svg";
import RemixIconsFilter from "../../../../../public/icons/remix-icons_filter.svg";
import RemixIconsFlagFill from "../../../../../public/icons/remix-icons_flag-fill.svg";
import RemixIconsHome from "../../../../../public/icons/remix-icons_home.svg";
import RemixIconsJa from "../../../../../public/icons/remix-icons_ja.svg";
import RemixIconsJobs from "../../../../../public/icons/remix-icons_jobs.svg";
import RemixIconsLogout from "../../../../../public/icons/remix-icons_logout.svg";
import RemixIconsMenuLine from "../../../../../public/icons/remix-icons_menu-line.svg";
import RemixIconsMore from "../../../../../public/icons/remix-icons_more.svg";
import RemixIconsParty from "../../../../../public/icons/remix-icons_party.svg";
import RemixIconsPlus from "../../../../../public/icons/remix-icons_plus.svg";
import RemixIconsPencil from "../../../../../public/icons/remix-icons_pencil.svg";
import RemixIconsPreview from "../../../../../public/icons/remix-icons_preview.svg";
import RemixIconsQuestionLine from "../../../../../public/icons/remix-icons_question-line.svg";
import RemixIconsQuestionMark from "../../../../../public/icons/remix-icons_question-mark.svg";
import RemixIconsRefresh from "../../../../../public/icons/remix-icons_refresh.svg";
import RemixIconsReport from "../../../../../public/icons/remix-icons_report.svg";
import RemixIconsRocket from "../../../../../public/icons/remix-icons_rocket.svg";
import RemixIconsSave from "../../../../../public/icons/remix-icons_save.svg";
import RemixIconsSearch from "../../../../../public/icons/remix-icons_search.svg";
import RemixIconsSettings from "../../../../../public/icons/remix-icons_settings.svg";
import RemixIconsTime from "../../../../../public/icons/remix-icons_time.svg";
import RemixIconsTimerStopwatch from "../../../../../public/icons/remix-icons_timer-stopwatch.svg";
import RemixIconsTimer from "../../../../../public/icons/remix-icons_timer.svg";
import RemixIconsTrendDown from "../../../../../public/icons/remix-icons_trend-down.svg";
import RemixIconsTrendUp from "../../../../../public/icons/remix-icons_trend-up.svg";
import RemixIconsUpload from "../../../../../public/icons/remix-icons_upload-fill.svg";
import RemixIconsUserAccount from "../../../../../public/icons/remix-icons_user-account-circle.svg";
import RemixIconsUserAdd from "../../../../../public/icons/remix-icons_user-add-fill.svg";
import RemixIconsUser from "../../../../../public/icons/remix-icons_user-fill.svg";
import RemixIconsDeselect from "../../../../../public/icons/remix-icons_deselect.svg";
import RemixIconsEyeLine from "../../../../../public/icons/remix-icons_eye-line.svg";
import RemixIconsEyeOffLine from "../../../../../public/icons/remix-icons_eye-off-line.svg";
import RemixIconsInfo from "../../../../../public/icons/remix-icons_info.svg";
import RemixIconsNoNotification from "../../../../../public/icons/remix-icons_no-notification.svg";
import RemixIconsClock from "../../../../../public/icons/remix-icons_clock.svg";
import RoundPlus from "../../../../../public/icons/round-plus.svg";
import School from "../../../../../public/icons/school.svg";
import ShareBoxFill from "../../../../../public/icons/share-box-fill.svg";
import UserSearchLine from "../../../../../public/icons/user-search-line.svg";
import Warning from "../../../../../public/icons/warning.svg";
import Logo from "../../../../../public/logo.svg";

const styles = {
    icon: (isDisabled: boolean) => isDisabled
        ? Cn.c('text-icons-disabled pointer-events-none')
        : Cn.c('cursor-pointer'),
};

const iconNames = [
    'archive',
    'arrow',
    'calendar',
    'caret',
    'close',
    'delete',
    'edit',
    'more',
    'jobPositions',
    'plus',
    'profile',
    'search',
    'download',
    'upload',
    'warning',
    'directionDown',
    'arrowLeft',
    'arrowDoubleLeft',
    'arrowRight',
    'arrowDoubleRight',
    'filter',
    'document',
    'documentNewspaperFill',
    'questionMark',
    'school',
    'email',
    'calendarCheck',
    'phone',
    'copy',
    'downloadFile',
    'shareBoxFill',
    'logo',
    'roundPlus',
    'mail',
    'form',
    'dragHandle',
    'userSearchLine',
    'flagFill',
    'preview',
    'exit',
    'errorWarning',
    'checkCircle',
    'crossCircle',
    'location',
    'jobs',
    'candidateFill',
    'candidates',
    'settings',
    'logout',
    'dashboard',
    'arrowDown',
    'arrowUp',
    'home',
    'check',
    'rocket',
    'report',
    'questionLine',
    'trendUp',
    'trendDown',
    'arrowRightLine',
    'arrowLeftLine',
    'userAdd',
    'userAccount',
    'calendarFill',
    'menu',
    'briefcase',
    'briefcaseFilled',
    'fileEdit',
    'fileList',
    'file',
    'save',
    'time',
    'calendarCheck',
    'discuss',
    'stopwatch',
    'timer',
    'deleteFilled',
    'arrowRightUpLine',
    'arrowLeftDownLine',
    'user',
    'deselect',
    'ja',
    'en',
    'refresh',
    'eye',
    'eyeOff',
    'pencil',
    'info',
    'party',
    'noNotification',
    'clock'
] as const;

type IconName = typeof iconNames[number];

interface Props {
    name: IconName;
    className?: string;
    onClick?: MouseEventHandler;
    isDisabled?: boolean;
}

const Icon: FunctionComponent<Props> = ({ name, className, onClick, isDisabled = false }) => {
    const iconClassName = Cn.join([
        Cn.ifTrue(isDefined(onClick), styles.icon(isDisabled)),
        Cn.getSome(className),
    ]);

    const iconProps = { className: iconClassName, onClick };

    switch (name) {
        case 'logo': return <Logo {...iconProps} />;
        case 'archive': return <Archive {...iconProps} />;
        case 'arrow': return <Arrow {...iconProps} />;
        case 'calendar': return <RemixIconsCalendar {...iconProps} />;
        case 'caret': return <RemixIconsCaret {...iconProps} />;
        case 'close': return <RemixIconsClose {...iconProps} />;
        case 'delete': return <RemixIconsDelete {...iconProps} />;
        case 'edit': return <Edit {...iconProps} />;
        case 'more': return <RemixIconsMore {...iconProps} />;
        case 'jobPositions': return <JobPositions {...iconProps} />;
        case 'plus': return <RemixIconsPlus {...iconProps} />;
        case 'profile': return <Profile {...iconProps} />;
        case 'search': return <RemixIconsSearch {...iconProps} />;
        case 'download': return <Download {...iconProps} />;
        case 'upload': return <RemixIconsUpload {...iconProps} />;
        case 'info': return <RemixIconsInfo {...iconProps} />;
        case 'warning': return <Warning {...iconProps} />;
        case 'directionDown': return <Down {...iconProps} />;
        case 'arrowLeft': return <RemixIconsArrowLeft {...iconProps} />;
        case 'arrowRight': return <RemixIconsArrowRight {...iconProps} />;
        case 'filter': return <RemixIconsFilter {...iconProps} />;
        case 'document': return <RemixIconsDocument {...iconProps} />;
        case 'documentNewspaperFill': return <RemixIconsDocumentNewspaperFill {...iconProps} />;
        case 'questionMark': return <RemixIconsQuestionMark {...iconProps} />;
        case 'school': return <School {...iconProps} />;
        case 'email': return <Email {...iconProps} />;
        case 'calendarCheck': return <CalendarCheck {...iconProps} />;
        case 'phone': return <Phone {...iconProps} />;
        case 'copy': return <RemixIconsCopy {...iconProps} />;
        case 'downloadFile': return <RemixIconsDownloadFile {...iconProps} />;
        case 'shareBoxFill': return <ShareBoxFill {...iconProps} />;
        case 'roundPlus': return <RoundPlus {...iconProps} />;
        case 'mail': return <Mail {...iconProps} />;
        case 'form': return <Form {...iconProps} />;
        case 'dragHandle': return <DragHandle {...iconProps} />;
        case 'userSearchLine': return <UserSearchLine {...iconProps} />;
        case 'flagFill': return <RemixIconsFlagFill {...iconProps} />;
        case 'preview': return <RemixIconsPreview {...iconProps} />;
        case 'exit': return <RemixIconsExit {...iconProps} />;
        case 'errorWarning': return <RemixIconsErrorWarning {...iconProps} />;
        case 'checkCircle': return <RemixIconsCheckCircle {...iconProps} />;
        case 'crossCircle': return <RemixIconsCrossCircle {...iconProps} />;
        case 'location': return <Location {...iconProps} />;
        case 'jobs': return <RemixIconsJobs {...iconProps} />;
        case 'candidateFill': return <RemixIconsUser2Fill {...iconProps} />;
        case 'candidates': return <RemixIconsCandidates {...iconProps} />;
        case 'settings': return <RemixIconsSettings {...iconProps} />;
        case 'logout': return <RemixIconsLogout {...iconProps} />;
        case 'dashboard': return <RemixIconsDashBoard {...iconProps} />;
        case 'arrowDown': return <RemixIconsArrowDown {...iconProps} />;
        case 'arrowUp': return <RemixIconsArrowUp {...iconProps} />;
        case 'home': return <RemixIconsHome {...iconProps} />;
        case 'check': return <RemixIconsCheck {...iconProps} />;
        case 'rocket': return <RemixIconsRocket {...iconProps} />;
        case 'arrowDoubleLeft': return <RemixIconsArrowDoubleLeft {...iconProps} />;
        case 'arrowDoubleRight': return <RemixIconsArrowDoubleRight {...iconProps} />;
        case 'report': return <RemixIconsReport {...iconProps} />;
        case 'questionLine': return <RemixIconsQuestionLine {...iconProps} />;
        case 'trendUp': return <RemixIconsTrendUp {...iconProps} />;
        case 'trendDown': return <RemixIconsTrendDown {...iconProps} />;
        case 'arrowRightLine': return <RemixIconsArrowRightLine {...iconProps} />;
        case 'arrowLeftLine': return <RemixIconsArrowLeftLine {...iconProps} />;
        case 'userAdd': return <RemixIconsUserAdd {...iconProps} />;
        case 'userAccount': return <RemixIconsUserAccount {...iconProps} />;
        case 'calendarFill': return <RemixIconsCalendarFill {...iconProps} />;
        case 'menu': return <RemixIconsMenuLine {...iconProps} />;
        case 'briefcase': return <RemixIconsBriefcase {...iconProps} />;
        case 'briefcaseFilled': return <RemixIconsBriefcaseFilled {...iconProps} />;
        case 'fileEdit': return <RemixIconsFileEdit {...iconProps} />;
        case 'fileList': return <RemixIconsFileList {...iconProps} />;
        case 'file': return <RemixIconsFile {...iconProps} />;
        case 'save': return <RemixIconsSave {...iconProps} />;
        case 'time': return <RemixIconsTime {...iconProps} />;
        case 'discuss': return <RemixIconsDiscuss {...iconProps} />;
        case 'stopwatch': return <RemixIconsTimerStopwatch {...iconProps} />;
        case 'timer': return <RemixIconsTimer {...iconProps} />;
        case 'deleteFilled': return <RemixIconsDeleteFilled {...iconProps} />;
        case 'arrowRightUpLine': return <RemixIconsArrowRightUpLine {...iconProps} />;
        case 'arrowLeftDownLine': return <RemixIconsArrowLeftDownLine {...iconProps} />;
        case 'user': return <RemixIconsUser {...iconProps} />;
        case 'deselect': return <RemixIconsDeselect {...iconProps} />;
        case 'ja': return <RemixIconsJa {...iconProps} />;
        case 'en': return <RemixIconsEn {...iconProps} />;
        case 'refresh': return <RemixIconsRefresh {...iconProps} />;
        case 'eye': return <RemixIconsEyeLine {...iconProps} />;
        case 'eyeOff': return <RemixIconsEyeOffLine {...iconProps} />;
        case 'pencil': return <RemixIconsPencil {...iconProps} />;
        case 'party': return <RemixIconsParty {...iconProps} />;
        case 'noNotification': return <RemixIconsNoNotification {...iconProps} />;
        case 'clock': return <RemixIconsClock {...iconProps} />;

        default:
            assertNever(name);

            return null;
    }
};

export {
    Icon,
    IconName,
    iconNames
};
