import { FunctionComponent, ReactElement } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { SideMenu } from '@shared/layout/DefaultLayout/SideMenu';

const styles = {
    root: Cn.c('flex h-screen bg-default'),
    main: Cn.c('flex flex-row w-full flex-1 bg-surface-default'),
    content: Cn.c('flex flex-col flex-1 w-full overflow-x-hidden overflow-y-auto'),
};

interface Props extends ChildrenProps {
    extra?: ReactElement;
    menu?: ReactElement;
}

const DefaultLayout: FunctionComponent<Props> = ({ extra, menu, children }) => {
    return (
        <>
            <div className={styles.root}>
                <SideMenu />
                <div className={styles.main}>
                    {menu}
                    <div className={styles.content}>
                        {children}
                    </div>
                </div>
            </div>
            {extra}
        </>
    );
};

export {
    DefaultLayout,
};
