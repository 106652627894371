import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@shared/unsorted/Select/Select';
import { isNullish, isUndefined } from '@helpers/core/typeGuards';
import { Input } from '@shared/unsorted/Input/Input';
import { validations } from '@helpers/unsorted/validation';
import { DatePicker } from '@shared/unsorted/DatePicker/DatePicker';
import { Label } from '@shared/unsorted/Label/Label';
import { Checkbox } from '@shared/unsorted/Checkbox/Checkbox';
import { fromString } from '@helpers/unsorted/boolExtra';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { TypeAhead } from '@shared/unsorted/TypeAhead/TypeAhead';
import { TextArea } from '@shared/unsorted/TextArea/TextArea';

import * as value from '../value';
import { EditPayload } from '../hook';

const styles = {
    formContainer: Cn.c('bg-surface-default rounded-b-lg rounded-tr-lg'),
    sectionContainer: Cn.c('pb-7'),
    top: Cn.c('flex flex-row items-center pb-3 border-subdued border-b'),
    title: Cn.c('font-paragraph-base-bold text-default flex-1 text-left'),
    form: Cn.c('w-full text-default font-paragraph-small-medium border-b border-subdued'),
    formRow: Cn.c('flex flex-row justify-between flex-1 space-x-4 mb-4 first:mt-4'),
    halfRow: Cn.c('flex flex-row justify-between w-1/2 pr-2 mb-4'),
    bottomRow: Cn.c('flex flex-row justify-between flex-1 space-x-4 first:mt-4'),
    radioButtonForm: Cn.c('flex flex-row flex-1 justify-items-center'),
    option: Cn.c('flex items-center font-paragraph-small-regular text-default mr-4'),
    selectLabel: Cn.c('select-none font-paragraph-small-regular flex items-center space-x-2 cursor-pointer'),
    spacer: Cn.c('mt-3.5'),
    fieldSet: Cn.c('w-full'),
};

interface Props {
    form: UseFormReturn<value.Encoder.EncoderType>;
    candidate: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate'>;
    editPayload: EditPayload;
}

const ApplicationCandidateDetailEditMode: FunctionComponent<Props> = ({
    form,
    candidate,
    editPayload,
}) => {
    const { t } = useTranslation();

    const { control, clearErrors, formState: { errors } } = form;

    const {
        acquisitionCategoriesOptions,
        acquisitionChannelOptions,
        entryCategoriesOptions,
        entryChannelOptions,
        genderOptions,
        isLivingOverseasChecked,
        prefectureOptions,
        yearOptions,
        monthOptions,
        schoolTypeOptions,
        humanitiesScienceOptions,
        schoolOptions,
        getSchools,
        currentSchoolType,
        currentSchoolId,
        schoolDepartmentOptions,
        getSchoolDepartments,
    } = editPayload;

    const onCheckBoxChange = (value: string, onChange: (...event: any[]) => void) => {
        const newValue = !fromString(value)

        onChange(newValue.toString())
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateApplicationInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <Controller
                            name="acquisitionCategoryId"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    label={"applications.panel.candidateApplicationInformation.acquisitionCategory"} // TODO: [CHECK] Verify if label should be different from list mode
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("acquisitionCategoryId")}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    options={acquisitionCategoriesOptions}
                                />
                            )}
                        />
                        <Controller
                            name="acquisitionChannelId"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => {
                                return (
                                    <Select
                                        disabled={isUndefined(acquisitionChannelOptions)}
                                        label={"applications.panel.candidateApplicationInformation.acquisitionChannel"} // TODO: [CHECK] Verify if label should be different from list mode
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("acquisitionChannelId")}
                                        forwardedRef={ref}
                                        value={value}
                                        errors={errors}
                                        options={acquisitionChannelOptions || []}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="entryCategoryId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    required
                                    label={"applications.panel.candidateApplicationInformation.entryCategory"}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("entryCategoryId")}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    options={entryCategoriesOptions}
                                />
                            )}
                        />
                        <Controller
                            name="entryChannelId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    required
                                    disabled={isUndefined(entryChannelOptions)}
                                    label={"applications.panel.candidateApplicationInformation.entryChannel"}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("entryChannelId")}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    options={entryChannelOptions || []}
                                    errorLabel={"jobPositions.panel.recruitmentFlow.stepType"}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateBasicInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <Controller
                            name="fullName"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    required
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.name"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.name"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="fullNameKana"
                            control={control}
                            rules={{ pattern: validations.katakana }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.furigana"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.furigana"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            rules={{
                                validate: {
                                    wrongDate: (value: string) => validations.validDate(value, true),
                                }
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <DatePicker
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.dateOfBirth"
                                    orientation="vertical"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                />
                            }
                        />
                        <Controller
                            name="gender"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label="applications.panel.candidateBasicInformation.gender"
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    options={genderOptions}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true, pattern: validations.email }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    required
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.email"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.email"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="mobilePhoneNumber"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.mobilePhoneNumber"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.phoneNumber"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <div>
                            <Label
                                label="applications.panel.candidateBasicInformation.edit.residenceCountry"
                            />
                            <Controller
                                name="livesOverseas"
                                control={control}
                                render={({ field: { name, value, onChange } }) =>
                                    <label className={Cn.join([styles.selectLabel, styles.spacer])}>
                                        <Checkbox
                                            id={name}
                                            checked={isLivingOverseasChecked}
                                            onChange={() => onCheckBoxChange(value, onChange)}
                                        />
                                        <div>
                                            {t("applications.panel.candidateBasicInformation.overseas")}
                                        </div>
                                    </label>}
                            />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="zipCode"
                            control={control}
                            rules={{
                                validate: {
                                    validZipCode: (value: string) => validations.validZipCode(value, true)
                                }
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    disabled={isLivingOverseasChecked}
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.edit.zipCode"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.zipCode"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="prefectureId"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <fieldset className={styles.fieldSet} disabled={isLivingOverseasChecked}>
                                    <Select
                                        label="applications.panel.candidateBasicInformation.edit.prefecture"
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        value={value}
                                        options={prefectureOptions}
                                        disabled={isLivingOverseasChecked}
                                    />
                                </fieldset>}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="address"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    disabled={isLivingOverseasChecked}
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.edit.currentAddress"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.currentAddress"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    disabled={isLivingOverseasChecked}
                                    name={name}
                                    label="applications.panel.candidateBasicInformation.edit.phoneNumber"
                                    placeholder="applications.panel.candidateBasicInformation.edit.placeholder.phoneNumber"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateSchoolInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <Controller
                            name="graduationYear"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    name={name}
                                    label={"applications.panel.candidateSchoolInformation.edit.graduationYear"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={yearOptions}
                                />}
                        />
                        <Controller
                            name="graduationMonth"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label={"applications.panel.candidateSchoolInformation.edit.graduationMonth"}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={monthOptions}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="schoolType"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label="applications.panel.candidateSchoolInformation.edit.schoolType"
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={schoolTypeOptions}
                                />}
                        />
                        <Controller
                            name="fieldType"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label="applications.panel.candidateSchoolInformation.fieldType"
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={humanitiesScienceOptions}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="schoolId"
                            control={control}
                            render={({ field: { name, ref } }) =>
                                <fieldset className={styles.fieldSet} disabled={isUndefined(currentSchoolType)}>
                                    <TypeAhead
                                        name={name}
                                        label="applications.panel.candidateSchoolInformation.school"
                                        direction="upward"
                                        options={schoolOptions}
                                        onInputChange={getSchools}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        form={form}
                                        inputValue={candidate.school?.name}
                                        disabled={isUndefined(currentSchoolType)}
                                    />
                                </fieldset>}
                        />
                        <Controller
                            name="schoolDepartmentId"
                            control={control}
                            render={({ field: { name, ref, value } }) =>
                                <fieldset
                                    className={styles.fieldSet}
                                    disabled={
                                        isUndefined(currentSchoolType) ||
                                        isUndefined(currentSchoolId) ||
                                        (schoolOptions.length === 0 && value.length === 0)}>
                                    <TypeAhead
                                        name={name}
                                        label="applications.panel.candidateSchoolInformation.major"
                                        direction="upward"
                                        options={schoolDepartmentOptions}
                                        onInputChange={getSchoolDepartments}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        form={form}
                                        inputValue={candidate.schoolDepartment?.name}
                                        disabled={isUndefined(currentSchoolType) ||
                                            isUndefined(currentSchoolId) ||
                                            (schoolOptions.length === 0 && value.length === 0)}
                                    />
                                </fieldset>}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="seminarLab"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateSchoolInformation.seminarLab"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="researchTheme"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateSchoolInformation.researchTheme"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                    <div className={styles.halfRow}>
                        <Controller
                            name="clubActivity"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateSchoolInformation.clubActivity"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateOtherContactInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <Controller
                            name="vacationZipCode"
                            control={control}
                            rules={{
                                validate: {
                                    validZipCode: (value: string) => validations.validZipCode(value, true)
                                }
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateOtherContactInformation.edit.vacationZipcode"
                                    placeholder="applications.panel.candidateOtherContactInformation.edit.placeholder.vacationZipcode"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="vacationPrefectureId"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label="applications.panel.candidateOtherContactInformation.edit.vacationPrefecture"
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={prefectureOptions}
                                />}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <Controller
                            name="vacationAddress"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateOtherContactInformation.edit.vacationAddress"
                                    placeholder="applications.panel.candidateOtherContactInformation.edit.placeholder.vacationAddress"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="vacationPhoneNumber"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateOtherContactInformation.vacationPhoneNumber"
                                    placeholder="applications.panel.candidateOtherContactInformation.edit.placeholder.vacationPhoneNumber"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                    <div className={styles.halfRow}>
                        <Controller
                            name="mobileEmail"
                            control={control}
                            rules={{
                                pattern: validations.email
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateOtherContactInformation.mobileEmail"
                                    placeholder="applications.panel.candidateOtherContactInformation.edit.placeholder.mobileEmail"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateJobHistoryInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <Controller
                            name="lastJobPosition"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateJobHistoryInformation.lastJobPosition"
                                    placeholder="applications.panel.candidateJobHistoryInformation.edit.placeholder.lastJobPosition"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                        <Controller
                            name="departmentAndPositionTitle"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label="applications.panel.candidateJobHistoryInformation.departmentAndPositionTitle"
                                    placeholder="applications.panel.candidateJobHistoryInformation.edit.placeholder.departmentAndPositionTitle"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateOtherInformation.title")}</div>
                </div>
                <div className={styles.form}>
                    <div className={styles.bottomRow}>
                        <Controller
                            name="remarks"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <TextArea
                                    name={name}
                                    orientation="vertical"
                                    label="applications.panel.candidateOtherInformation.remarks"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    ApplicationCandidateDetailEditMode,
};