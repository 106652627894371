import { FunctionComponent, ReactElement } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { SideMenu } from './SideMenu';

const styles = {
    root: Cn.c('flex h-full bg-default'),
    main: Cn.c('bg-surface-default flex flex-col h-full flex-1'),
};

interface Props extends ChildrenProps {
    extra?: ReactElement;
}

const DefaultLayout: FunctionComponent<Props> = ({ extra, children }) => {
    return (
        <>
            <div className={styles.root}>
                <SideMenu />
                <div className={styles.main}>
                    {children}
                </div>
            </div>
            {extra}
        </>
    );
};

export {
    DefaultLayout
};
