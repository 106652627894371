import { JobPosition_OneByIdWithStepEventsQuery } from "@entities";
import * as itemValue from '@shared/job_position/RecruitmentFlow/RecruitmentFlowSubForm/itemValue';
import { Entity } from "@typedefs/graphql";
import { z } from 'zod';

namespace Encoder {
    export type Type = ReturnType<typeof defaultValues>;

    export const defaultValues = (jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>) => {
        return {
            title: jobPosition.title,
            documentRequirements: jobPosition.documentRequirements || "",
            recruitmentSteps: jobPosition.recruitmentStepsV2.map(step => {
                const { stage, id, name, type, hasDecision, hasEvaluation, previousStepId, nextStepId, hasNotification } = step;

                return itemValue.Encoder.makeItem({
                    stage,
                    id,
                    name,
                    type,
                    hasDecision,
                    hasEvaluation,
                    hasNotification,
                    previousStepId,
                    nextStepId,
                });
            }),
        }
    }
}

namespace Decoder {
    export const schema = z.object({
        title: z.string().min(1),
        recruitmentSteps: z.array(itemValue.Decoder.recruitmentStepSchema),
        documentRequirements: z.string(),
    }).transform((values) => ({
        ...values,
        documentRequirements: values.documentRequirements || undefined,
    }));
}

export {
    Decoder, Encoder
};
