import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        sessionId: string;
    }

    export const defaultValues = (sessionId?: string | null): EncoderType => {
        return {
            sessionId: sessionId || ''
        }
    }
}

namespace Decoder {
    export const schema = z.object({
        sessionId: z.string().min(1),
    })
}

export {
    Encoder,
    Decoder
}