import { useContext } from 'react';

import { isUndefined } from '@helpers/core/typeGuards';
import { CSVImportFormContext, CSVImportFormContextType } from './CSVImportFormContext';

const useCSVImportFormContext = (): CSVImportFormContextType => {
    const context = useContext(CSVImportFormContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <CSVImportFormContext.Provider>...</CSVImportFormContext.Provider> block.');
    }

    return context;
};

export {
    useCSVImportFormContext,
};
