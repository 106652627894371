import config from "@helpers/core/config";
import { Cn } from "@helpers/unsorted/classNames";
import { UserMenu } from "@shared/unsorted/DefaultLayout/UserMenu";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SideMenuItem } from "./SideMenuItem";
import { SupportedLanguage, useLanguageChange, useCurrentLanguage } from "@helpers/core/i18n";
import cookies, { WELCOME_MODAL_SHOWN_COOKIE } from "@helpers/core/cookies";
import { JobPosition } from "@routes/jobPosition";
import { Candidate } from "@routes/candidate";
import { Settings } from "@routes/setting";
import { Analytics } from "@routes/analytics";

const styles = {
    container: Cn.c("w-side-menu bg-default px-3 flex flex-col items-start h-full justify-between py-6 shrink-0"),
    section: Cn.c("w-full"),
    userMenu: Cn.c("my-14"),
    langContainer: Cn.c("flex mb-2"),
    langButton: (isSelected: boolean, lang: SupportedLanguage) => Cn.join([
        Cn.c("flex items-center p-2 font-paragraph-xsmall-regular space-x-1 bg-surface-default cursor-pointer"),
        lang === 'ja' ? Cn.c("rounded-l") : Cn.c("rounded-r"),
        isSelected ? Cn.c("bg-actions-primary-default text-on-primary") : Cn.c("border border-default"),
    ]),
    langIcon: Cn.c("w-[0.875rem] h-[0.625rem]"),
    logo: Cn.c("w-[7.75rem] h-[0.875rem]"),
};

const SideMenu: FunctionComponent = () => {
    const { t } = useTranslation();
    const jobPositionRoute = JobPosition.toRoute();
    const applicationsRoute = Candidate.toRoute();
    const location = useLocation();

    const currentLanguage = useCurrentLanguage();
    const changeLanguage = useLanguageChange();

    const JA_LANG = 'ja';
    const EN_LANG = 'en';

    const onLogout = () => cookies.remove(WELCOME_MODAL_SHOWN_COOKIE);

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <Link
                    to={`/`}
                // className={styles.logoLink}
                >
                    <Icon name='logo' className={styles.logo}/>
                </Link >
                <UserMenu className={styles.userMenu} />
                <SideMenuItem
                    to={`/`}
                    label={t('sideMenu.dashboard')}
                    isActive={location.pathname === '/'}
                    icon="dashboard"
                />
                {/* Note: Temporarily remove calendar */}
                {/* <SideMenuItem
                    to={Calendar.pathName}
                    label={t("sideMenu.calendar")}
                    isActive={location.pathname === Calendar.pathName}
                    icon="calendar"
                /> */}
                <SideMenuItem
                    to={`${applicationsRoute.PATH_NAME}${applicationsRoute.search}`}
                    label={t("sideMenu.candidates")}
                    isActive={location.pathname.startsWith(Candidate.PATH_NAME)}
                    icon="candidates"
                />
                <SideMenuItem
                    to={`${jobPositionRoute.PATH_NAME}${jobPositionRoute.search}`}
                    label={t('sideMenu.job')}
                    isActive={location.pathname === JobPosition.PATH_NAME}
                    icon="jobs"
                />
                <SideMenuItem
                    to={Analytics.PATH_NAME}
                    label={t("sideMenu.analytics")}
                    isActive={location.pathname === Analytics.PATH_NAME}
                    icon="report"
                />
                <SideMenuItem
                    to={Settings.PATH_NAME}
                    label={t('sideMenu.settings')}
                    isActive={location.pathname === Settings.PATH_NAME || location.pathname.startsWith(`${Settings.PATH_NAME}/`)}
                    icon="settings"
                />
            </div>
            <div className={styles.section}>
                <div className={styles.langContainer}>
                    <div
                        className={styles.langButton(currentLanguage === JA_LANG, JA_LANG)}
                        onClick={() => currentLanguage != JA_LANG && changeLanguage(JA_LANG)}>
                        <Icon name="ja" className={styles.langIcon} />
                        <span>{t("sideMenu.japanese")}</span>
                    </div>
                    <div
                        className={styles.langButton(currentLanguage === EN_LANG, EN_LANG)}
                        onClick={() => currentLanguage != EN_LANG && changeLanguage(EN_LANG)}>
                        {/* TODO: Might need to change the icon */}
                        <Icon name="en" className={styles.langIcon} />
                        <span>{t("sideMenu.english")}</span>
                    </div>
                </div>
                <SideMenuItem
                    to={config.oauthAccessLogoutUri}
                    label={t('sideMenu.logout')}
                    icon="logout"
                    onClick={onLogout}
                />
            </div>
        </div>
    );
}

export {
    SideMenu
};
