import { useTranslation } from 'react-i18next';

import { isOneOf } from '@helpers/core/typeGuards';

const SUPPORTED_VARIABLES = [
    'company_name',
    'job_name',
    'step_name',
    'candidate_name',
    'examiner_name',
    'internal_signature',
    'external_signature',
    'event_page_url',
    'event_date_time',
    'event_location',
    'event_examiner_location',
    'event_contact',
    'reservation_url',
    'reservation_deadline',
    'evaluation_url',
    'evaluation_deadline',
    'document_upload_url',
    'document_upload_deadline',
] as const;

type SupportedVariable = typeof SUPPORTED_VARIABLES[number];

const VARIABLE_TYPES = [
    'string',
    'multiLine',
    'url',
    'dateTime',
    'email',
    'phone',
] as const;

type VariableType = typeof VARIABLE_TYPES[number];

const VARIABLE_TYPE_MAP: Record<SupportedVariable, VariableType> = {
    company_name: 'string',
    job_name: 'string',
    step_name: 'string',
    candidate_name: 'string',
    examiner_name: 'string',
    internal_signature: 'multiLine',
    external_signature: 'multiLine',
    event_page_url: 'url',
    event_date_time: 'dateTime',
    event_location: 'string',
    event_examiner_location: 'string',
    event_contact: 'phone',
    reservation_url: 'url',
    reservation_deadline: 'dateTime',
    evaluation_url: 'url',
    evaluation_deadline: 'dateTime',
    document_upload_url: 'url',
    document_upload_deadline: 'dateTime',
};

type ReplacerFunction = (key: string, value: string) => HTMLElement[];
type DisplayMode = 'variableName' | 'sample';

const SAMPLES_BASE_PATH = 'emails.templates.variables.samples';
const VARIABLE_NAMES_BASE_PATH = 'emails.templates.variables.names'

const useVariables = () => {
    const { t } = useTranslation();
    
    const stringReplacer: ReplacerFunction = (key, value) => {
        const element = document.createElement('span');
        element.classList.add('variableSample');
        element.setAttribute('data-variable', key);
        element.textContent = value;
        
        return [ element ];
    };
    const multiLineReplacer: ReplacerFunction = (key, value) => {
        const lines = value.split('\n');

        return lines.reduce<HTMLElement[]>((accumulator, line, index) => {
            const element = document.createElement('span');
            element.classList.add('variableSample');
            element.setAttribute('data-variable', lines.length === 1 ? key : `${key}_line${index}`);
            element.textContent = line;

            if (index === lines.length - 1) {
                return [
                    ...accumulator,
                    element,
                ];
            }

            return [
                ...accumulator,
                element,
                document.createElement('br'),
            ];
        }, []);
    };
    const urlReplacer: ReplacerFunction = (key, value) => {
        const element = document.createElement('a');
        element.classList.add('variableSample');
        element.setAttribute('data-variable', key);
        element.setAttribute('href', value);
        element.setAttribute('target', '_blank');
        // TODO: Consider stripping the protocol and the query parameters to make it more readable -> Needs validation
        element.textContent = value;
        
        return [ element ];
    };
    const dateTimeReplacer: ReplacerFunction = stringReplacer;
    const emailReplacer: ReplacerFunction = (key, value) => {
        const element = document.createElement('a');
        element.classList.add('variableSample');
        element.setAttribute('data-variable', key);
        element.setAttribute('href', `mailto:${value}`);
        element.textContent = value;
        
        return [ element ];
    };
    const phoneReplacer: ReplacerFunction = (key, value) => {
        const element = document.createElement('a');
        element.classList.add('variableSample');
        element.setAttribute('data-variable', key);
        element.setAttribute('href', `tel:${value}`);
        element.textContent = value;
        
        return [ element ];
    };
    
    const replacers: Record<VariableType, ReplacerFunction> = {
        string: stringReplacer,
        multiLine: multiLineReplacer,
        url: urlReplacer,
        dateTime: dateTimeReplacer,
        email: emailReplacer,
        phone: phoneReplacer,
    };
    
    const replacerCore = (text: string, displayMode: DisplayMode): HTMLElement => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(text, 'text/html');
        
        const variables = Array.from(parsedDocument.querySelectorAll('variable'));
        variables.forEach(variable => {
            const name = variable.getAttribute('name');
            if (name === null) {
                throw new Error('Missing variable name. String expected');
            }
            if (!isOneOf(SUPPORTED_VARIABLES)(name)) {
                throw new Error(`Unsupported variable name found: ${name}`);
            }

            const basePath = displayMode === 'variableName' ? VARIABLE_NAMES_BASE_PATH : SAMPLES_BASE_PATH;
            
            const sampleValue = t(`${basePath}.${name}`);
            
            const type = VARIABLE_TYPE_MAP[name];
            const replacer = replacers[type];
            
            const elements = replacer(name, sampleValue);
            
            variable.replaceWith(...elements);
        });
        
        return parsedDocument.body;
    };
    
    const templateReplacer = (text: string, displayMode: DisplayMode) => replacerCore(text, displayMode).innerHTML;
    
    return {
        templateReplacer,
    };
};

export {
    SUPPORTED_VARIABLES,
    SupportedVariable,
    useVariables,
};
