import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { ToolTipBox, TooltipPlacement } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { FormProps } from '@typedefs/props';
import { FieldValues } from 'react-hook-form';
import { I18nKey } from 'react-i18next';
import { Checkbox, Size } from '../Checkbox/Checkbox';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { useCheckboxGroup } from './hook';

const styles = {
    singleOption: (isDisabled: boolean, size: Size) =>
        Cn.join([
            Cn.c('py-2 flex items-center space-x-2'),
            Cn.ifFalse(isDisabled, 'hover:bg-surface-hovered-default cursor-pointer'),
            isDisabled ? Cn.c('text-disabled') : Cn.c('text-emphasized'),
            size === 'sm' ? Cn.c('font-paragraph-xsmall-regular') : Cn.c('font-paragraph-small-regular'),
        ]),
    multiSelectLabel: (isDisabled: boolean) =>
        Cn.join([
            Cn.c('block select-none'),
            isDisabled ? Cn.c('text-disabled') : Cn.c('text-emphasized')
        ]),
    error: Cn.c('text-critical-default font-paragraph-small-regular'),
    questionMark: Cn.c('text-icons-default w-4 h-4'),
    header: (size: Size) =>
        Cn.join([
            Cn.c('text-subdued'),
            size === 'sm' ? Cn.c('font-paragraph-xsmall-regular') : Cn.c('font-paragraph-small-regular'),
        ])
};

type CheckboxOption = {
    key: string;
    label: string;
    value: string;
    disabled: boolean;
    tooltip?: string;
    type?: 'option' | 'header' //TODO: consider using union type if we don't break the existing code
    /** Additional information shown at the end of an option's label */
    labelSuffix?: string
    /** For option type, indicates the header's key where the option belongs to */
    headerKey?: string
};
interface Props<FieldsType extends FieldValues> extends FormProps<FieldsType> {
    options: CheckboxOption[];
    label?: I18nKey;
    className?: string;
    labelClassName?: string;
    optionClassName?: string;
    required?: boolean;
    onChange?: (event: unknown) => void;
    errorLabel?: I18nKey;
    value?: string[];
    size?: Size;
    tooltip?: I18nKey;
    tooltipClassName?: string;
    tooltipPlacement?: TooltipPlacement;
    tooltipIconClassName?: string;
}

const CheckboxGroup = <FieldsType extends FieldValues>({
    name,
    options,
    label,
    className,
    labelClassName,
    optionClassName,
    required: isRequired = false,
    onChange,
    errors,
    errorLabel,
    value,
    size = 'lg',
    tooltip,
    tooltipClassName,
    tooltipPlacement,
    tooltipIconClassName,
}: Props<FieldsType>) => {
    const { isChecked, onToggleSelect, error } =
        useCheckboxGroup(name, value, errors, label, errorLabel, onChange);

    return (
        <label className={Cn.getSome(className)}>
            {isDefined(label) && <Label label={label} labelClassName={labelClassName} required={isRequired} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
            {options.map(({ key, label: optionLabel, value: optionValue, disabled, tooltip, type = 'option', labelSuffix }) =>
                <label key={key} className={Cn.join([styles.singleOption(disabled, size), Cn.getSome(optionClassName)])}>
                    {type === 'option'
                        ? <>
                            <Checkbox
                                size={size}
                                id={optionValue}
                                checked={isChecked(optionValue)}
                                disabled={disabled}
                                onChange={() => !disabled && onToggleSelect(optionValue)}
                            />
                            <div className={styles.multiSelectLabel(disabled)}>
                                {optionLabel}
                                {isDefined(labelSuffix) && <span>&nbsp;{labelSuffix}</span>}
                            </div>
                        </>
                        : <p className={styles.header(size)}>{optionLabel}</p>
                    }
                    {isDefined(tooltip) &&
                        <ToolTipBox
                            tooltipContent={tooltip}
                            placement="right"
                            toolTipClassName={Cn.getSome(tooltipClassName)}
                        >
                            <div>
                                <Icon name="questionMark" className={Cn.join([styles.questionMark, Cn.getSome(tooltipIconClassName)])} />
                            </div>
                        </ToolTipBox>
                    }
                </label>
            )}
            {isDefined(error) && <div className={styles.error}>{error}</div>}
        </label>
    );
};

export {
    CheckboxGroup, CheckboxOption
};
