import { FunctionComponent } from "react";
import { I18nKey } from "react-i18next";
import { StepTrackerSegment, Variant as StepTrackerSegmentVariant } from '../StepTrackerSegment/StepTrackerSegment'
import { Cn } from "@helpers/unsorted/classNames";

const styles = {
    container: Cn.c("flex justify-between space-x-2 w-full"),
}

interface Props {
    segments: Array<{ label: I18nKey, variant: StepTrackerSegmentVariant }>
    className?: string
}

const SegmentedStepTracker: FunctionComponent<Props> = ({ segments, className }) => {
    return (
        <div className={Cn.join([styles.container, Cn.getSome(className)])}>
            {segments.map((segment, index) =>
                <StepTrackerSegment
                    key={index}
                    label={segment.label}
                    variant={segment.variant}
                    stepIndex={index + 1}
                />
            )}
        </div>
    )
}

export {
    SegmentedStepTracker,
    Props
}