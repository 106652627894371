import { EventSessionGroupLocationType, EventSession_OneByIdQuery } from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { showFullName } from '@helpers/core/client';
import { Cn } from '@helpers/unsorted/classNames';
import { getEventSessionName } from '@shared/event/helpers';
import { ExtraMenu } from '@shared/unsorted/ExtraMenu/ExtraMenu';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from './CopyToClipboard/CopyToClipboard';
import { GroupMenuItems } from './GroupMenuItems';
import { useEventSessionDetails } from './hook';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';

const styles = {
    metadata: Cn.c('pt-8 pb-6 border-b border-b-default'),
    name: Cn.c('font-h2-bold text-emphasized pb-3'),
    infoRow: Cn.c('flex space-x-3'),
    detailContainer: Cn.c('flex items-center space-x-1'),
    icon: Cn.c('text-icons-disabled w-3.5 h-3.5'),
    label: Cn.c('text-subdued font-paragraph-xsmall-regular'),
    detail: Cn.c('font-paragraph-xsmall-medium text-emphasized'),
    sessionGroupSection: Cn.c('pt-6 flex flex-col'),
    sessionGroup: Cn.c('font-h4-bold text-emphasized pb-3'),
    tableInnerContainer: Cn.c('bg-surface-default border border-subdued flex'),
    table: Cn.c('table-sticky'),
    tableTH: Cn.c('table-thead-row table-th-sticky top-0'),
    tableCell: Cn.c('table-td'),
    tooltipContainer: Cn.c('flex items-center gap-x-1'),
    questionMark: Cn.c('text-icons-emphasized w-3.5 h-3.5'),
    peopleContainer: Cn.c('border border-default rounded-lg px-3 py-2 w-[15.625rem] font-paragraph-xsmall-regular text-emphasized'),
    examiner: (hasAssignedExaminers: boolean) => Cn.join([
        Cn.c('font-paragraph-small-regular'),
        hasAssignedExaminers ? Cn.c('text-emphasized') : Cn.c('text-placeholder')
    ]),
};

interface Props {
    openDeleteEventSessionGroupConfirmationModal: (groupId: string) => void;
    openEditEventSessionGroupLocation: ({ groupId, groupPosition, location, locationType }: {
        groupId: string;
        groupPosition: number;
        location: string;
        locationType?: EventSessionGroupLocationType | null;
    }) => void;
    openEditEventSessionGroupExaminersModal: (
        groupId: string,
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[],
    ) => void;
    openEditEventSessionGroupCandidate: (group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>) => void;
}

const EventSessionDetails: FunctionComponent<Props> = ({
    openDeleteEventSessionGroupConfirmationModal,
    openEditEventSessionGroupLocation,
    openEditEventSessionGroupExaminersModal,
    openEditEventSessionGroupCandidate
}) => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();
    const { event, eventSession } = useEventSessionDetailContext();

    const {
        groupCount,
        registeredCount,
        groups,
    } = eventSession;

    const {
        canDeleteSessionGroup,
        onDeleteEventSessionGroup,
        onEditEventSessionGroupLocation,
        onEditEventSessionGroupExaminers,
        onEditEventSessionGroupCandidate,
    } = useEventSessionDetails({
        eventSession,
        openDeleteEventSessionGroupConfirmationModal,
        openEditEventSessionGroupLocation,
        openEditEventSessionGroupExaminersModal,
        openEditEventSessionGroupCandidate,
    });

    return (
        <>
            <div className={styles.metadata}>
                <p className={styles.name}>{getEventSessionName(eventSession, currentLanguage)}</p>
                <div className={styles.infoRow}>
                    <div className={styles.detailContainer}>
                        <Icon name="candidates" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventSession.groupCountLabel")}</span>
                        <span className={styles.detail}>{t("event.eventSession.groupCount", { count: groupCount })}</span>
                    </div>
                    <div className={styles.detailContainer}>
                        <Icon name="candidates" className={styles.icon} />
                        <span className={styles.label}>{t("event.eventSession.candidateCapacityLabel")}</span>
                        <span className={styles.detail}>{t("event.eventSession.candidateCapacity", { count: event.candidateCapacity })}</span>
                    </div>
                </div>
            </div>
            <div className={styles.sessionGroupSection}>
                <p className={styles.sessionGroup}>{t("event.eventSession.candidateCapacityLabel")}</p>
                <div className={styles.tableInnerContainer}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.tableTH}>
                                    {event.isOnline
                                        ? t("event.eventSession.table.header.sessionUrl")
                                        : t("event.eventSession.table.header.location")
                                    }
                                </th>
                                <th className={styles.tableTH}>
                                    {t("event.eventSession.table.header.examiner")}
                                </th>
                                <th className={styles.tableTH}>
                                    <div className={styles.tooltipContainer}>
                                        {t("event.eventSession.table.header.applicants", { count: registeredCount })}
                                        <ToolTipBox
                                            tooltipContent={t("event.eventSession.table.header.applicantsTooltip")}
                                            placement="top">
                                            <div> <Icon name="questionMark" className={styles.questionMark} /> </div>
                                        </ToolTipBox>
                                    </div>
                                </th>
                                <th className={styles.tableTH}>
                                    {t("event.eventSession.table.header.action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map(group => {
                                const { id, location, examinerAssignments, order, locationType } = group;

                                return (
                                    <tr key={id}>
                                        <td className={styles.tableCell}>
                                            {event.isOnline
                                                ? <CopyToClipboard text={location} />
                                                : location
                                            }
                                        </td>
                                        <td className={Cn.join([styles.tableCell, styles.examiner(examinerAssignments.length !== 0)])}>
                                            {examinerAssignments.length === 0
                                                ? t("event.eventSession.table.notAssigned")
                                                : <p>{examinerAssignments.map(assignment => showFullName(t, assignment.client)).join(", ")}</p>
                                            }
                                        </td>
                                        <td className={styles.tableCell}>
                                            <div className={styles.peopleContainer}>
                                                {t("event.eventSession.table.applicantsCount", { registered: group.recruitmentProcessAssignments.length, total: event.candidateCapacity })}
                                            </div>
                                        </td>
                                        <td className={styles.tableCell}>
                                            <ExtraMenu render={({ editMenuItemClassName, deleteMenuItemClassName, toggle }) =>
                                                <GroupMenuItems
                                                    isEditable
                                                    isDeletable={canDeleteSessionGroup}
                                                    editMenuItemClassName={editMenuItemClassName}
                                                    deleteMenuItemClassName={deleteMenuItemClassName}
                                                    onEditExaminers={() =>
                                                        onEditEventSessionGroupExaminers({ groupId: id, examinerAssignments, toggleMenu: toggle })
                                                    }
                                                    onEditLocation={() =>
                                                        onEditEventSessionGroupLocation({
                                                            groupId: id,
                                                            groupPosition: order,
                                                            location,
                                                            locationType,
                                                            toggleMenu: toggle,
                                                        })}
                                                    onEditCandidates={() => onEditEventSessionGroupCandidate({ group, toggleMenu: toggle })}
                                                    onDelete={() => onDeleteEventSessionGroup({ groupId: id, toggleMenu: toggle })}
                                                    deleteTooltip={
                                                        registeredCount > 0
                                                            ? t("event.eventSession.table.extraMenu.tooltip.candidateRegisteredTooltipText")
                                                            : groupCount === 1
                                                                ? t("event.eventSession.table.extraMenu.tooltip.lastGroupTooltip")
                                                                : ""
                                                    }
                                                />} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export {
    EventSessionDetails
};
