import { FunctionComponent } from "react";
import { EmptyStateContainer } from "@shared/unsorted/EmptyStateContainer/EmptyStateContainer";
import { Button } from "@shared/unsorted/Button/Button";
import { useTranslation } from "react-i18next";
import { Candidate } from "@routes/candidate";

interface Props {
  applicationType?: Candidate.ApplicationType;
  openCreateApplicationModal: VoidFunction
}

const EmptyApplicationsContainer: FunctionComponent<Props> = ({ applicationType, openCreateApplicationModal }) => {
  const { t } = useTranslation();

  if (applicationType === "ONGOING") {
    return (
      <EmptyStateContainer
        icon="emptyCandidate"
        description="applications.list.emptyUnderSelection.description"
        subDescription="applications.list.emptyUnderSelection.subDescription"
      >

      </EmptyStateContainer>
    );
  } else if (applicationType === "ARCHIVED") {
    return (
      <EmptyStateContainer
        icon="emptyCandidate"
        description="applications.list.emptyPastApplication.description"
        subDescription="applications.list.emptyPastApplication.subDescription"
      />
    );
  } else {
    return (
      <EmptyStateContainer
        icon="emptyCandidate"
        description="applications.list.emptyAll.description"
        subDescription="applications.list.emptyAll.subDescription"
      >
        <Button
          key="create"
          iconName="plus"
          size="md"
          onClick={openCreateApplicationModal}
        >
          {t("applications.create.button")}
        </Button>
      </EmptyStateContainer>
    );
  }
};

export default EmptyApplicationsContainer;
