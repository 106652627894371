import { FunctionComponent } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';
import { EmptyStateIcon, EmptyStateIconName } from '@shared/unsorted/EmptyStateContainer/EmptyStateIcon';

const styles = {
    root: Cn.c('flex flex-col items-center justify-center pt-10'),
    iconWrapper: Cn.c('w-[6.25rem] h-[6.25rem] bg-surface-emphasized flex items-center justify-center rounded-full'),
    icon: Cn.c('w-12 h-12 text-icons-subdued'),
    description: Cn.c('font-paragraph-base-bold text-emphasized pt-2'),
    subDescription: Cn.c('font-paragraph-xsmall-regular text-subdued pt-2 whitespace-pre-line text-center'),
};

interface Props {
    icon: EmptyStateIconName;
    description: I18nKey;
    subDescription: I18nKey;
}

// This component is used to display an empty state in a side panel.
const EmptyStateSidePanelContainer: FunctionComponent<Props> = ({ icon, description, subDescription }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.iconWrapper}>
                <EmptyStateIcon name={icon} className={styles.icon} />
            </div>
            <p className={styles.description}>{t(description)}</p>
            <p className={styles.subDescription}>{t(subDescription)}</p>
        </div>
    );
};

export {
    EmptyStateSidePanelContainer
};
