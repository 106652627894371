import { useEffect, useReducer } from "react"
import { initialState, reducer } from "./reducer"
import { BulkEventSessionAssignmentModalPayload } from "../reducer"
import { useBulkActionModalContext } from "../BulkActionModalContext"

const useBulkEventSessionAssignmentModal = (goToStep: (stepId: string) => void, payload?: BulkEventSessionAssignmentModalPayload) => {
    const [state, dispatch] = useReducer(reducer, initialState(payload?.recruitmentStepId, payload?.applicationIds))
    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const closeModal = () => {
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'sessionSelection' }
            }
        })

        dispatch({
            name: 'SetSettings',
            payload: {
                settings: {
                    currentRecruitmentStepId: undefined
                }
            }
        })

        goToStep('sessionSelection')

        dispatchBulkActionModalMode({
            name: 'hide'
        })
    }

    useEffect(() => {
        dispatch({
            name: 'SetSettings',
            payload: {
                settings: {
                    applicationIds: payload?.applicationIds,
                    currentRecruitmentStepId: payload?.recruitmentStepId
                }
            }
        })
    }, [payload])

    return {
        closeModal,
        state,
        dispatch
    }
}

export {
    useBulkEventSessionAssignmentModal
}