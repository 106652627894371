import { RadioInputItemBoxOptions } from '@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import * as value from './value';
import { FormEventHandler } from 'react';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useNavigate } from 'react-router-dom';
import { CandidateAdd } from '@routes/candidate';

interface AddCandidatesModalHookType {
    options: RadioInputItemBoxOptions[];
    form: UseFormReturn<value.Encoder.EncoderType>;
    onConfirm: FormEventHandler<HTMLFormElement>;
    onClose: VoidFunction;
}

const useAddCandidatesModal = (closeModal: VoidFunction): AddCandidatesModalHookType => {
    const navigate = useNavigate();
    const { error: toastError } = useToast();

    const options: RadioInputItemBoxOptions[] = [
        {
            label: 'applications.create.modal.options.csv.label',
            description: 'applications.create.modal.options.csv.description',
            value: 'CSV',
            icon: 'upload',
            disabled: false,
        },
        {
            label: 'applications.create.modal.options.manual.label',
            description: 'applications.create.modal.options.manual.description',
            value: 'MANUAL',
            icon: 'pencil',
            disabled: false,
        },
    ];

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues: value.Encoder.defaultValues(),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const onConfirm: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            switch (validValues.addMethod) {
                case 'CSV':
                    navigate(CandidateAdd.CSV_IMPORT_PATH_NAME);
                    break;
                case 'MANUAL':
                    navigate(CandidateAdd.MANUAL_PATH_NAME);
                    break;
                default: break;
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    const onClose = () => {
        form.reset();
        closeModal();
    };

    return {
        options,
        form,
        onConfirm: form.handleSubmit(onConfirm),
        onClose,
    };
};

export {
    useAddCandidatesModal,
};