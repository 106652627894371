import { DraggableLocation } from "@hello-pangea/dnd";
import { allRecruitmentStages, displayedRecruitmentStages } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { RecruitmentStageType } from "@typedefs/aliases";
import * as itemValue from './itemValue'
import { findLastIndex } from "@helpers/unsorted/arrayExtra";

const stageRange = (fields: itemValue.Encoder.ItemType[], stage: RecruitmentStageType) => {
    const start = fields.findIndex(step => step.stage === stage);
    const end = findLastIndex(fields, step => step.stage === stage);

    return { start, end };
}

const getNonEmptyPreviousStage = (stage: RecruitmentStageType): RecruitmentStageType => {
    const currentIndex = displayedRecruitmentStages.findIndex(displayedStage => displayedStage === stage)

    if (currentIndex <= 0) {
        return "SOURCING"
    }

    return displayedRecruitmentStages[currentIndex - 1]
}

function getLastValidStepIndex(
    fields: itemValue.Encoder.ItemType[],
    stage: RecruitmentStageType,
): number {
    let currentStage = stage;
    let currentLastIndex = -1;

    // loop over previous stages until we find a valid step
    while (currentStage !== "SOURCING" && currentLastIndex === -1) {
        currentStage = getNonEmptyPreviousStage(currentStage);

        currentLastIndex = findLastIndex(fields, step => step.stage === currentStage);
    }

    return currentLastIndex
}

const getDestinationIndex = ({ source, destination, fields, sourceOffset }: {
    source: DraggableLocation,
    destination: DraggableLocation
    fields: itemValue.Encoder.ItemType[],
    sourceOffset: number
}) => {
    if (!isOneOf(allRecruitmentStages)(destination.droppableId)) {
        return;
    }

    const filteredSteps = fields.filter((_step, index) => index !== source.index + sourceOffset)
    const destinationOffset = filteredSteps.findIndex(step => step.stage === destination.droppableId)

    // When dragging to an empty stage
    if (destinationOffset === -1) {
        const lastValidIndex = getLastValidStepIndex(filteredSteps, destination.droppableId)

        return lastValidIndex + 1
    }

    // When dragging to the "PRE_EMPLOYMENT" stage -> only allow dragging before the `Recruitment Result` step
    if (destination.droppableId === "PRE_EMPLOYMENT") {
        const recruitmentResultIndex = fields.findIndex(step => step.stage === "PRE_EMPLOYMENT" && step.name === 'Recruitment Result')

        if (recruitmentResultIndex <= (destination.index + destinationOffset)) {
            return recruitmentResultIndex - 1
        }
    }

    // When dragging within the same stage; When dragging to a different stage
    return destination.index + destinationOffset
}
export {
    stageRange,
    getLastValidStepIndex,
    getDestinationIndex,
}