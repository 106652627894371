import { AwaitableState } from "@typedefs/aliases"
import { useEffect, useState } from "react"
import { ApplicationInformationSubFormData } from "./ApplicationInformationSubForm"
import { responseHasError, useQueryContext } from "@helpers/unsorted/urqlExtra"
import { useQuery } from "urql"
import { AllAcquisitionCategories_AllDocument, AllEntryCategories_AllDocument, JobPosition_OneByIdDocument, JobPositions_AllSimpleDocument } from "@entities"
import { hasValue } from "@helpers/core/typeGuards"
import { useToast } from "@helpers/hooks/unsorted/toastHook"

const useApplicationInformationSubFormState = () => {
    const [data, setData] = useState<AwaitableState<ApplicationInformationSubFormData>>({
        isLoading: true
    })

    const [currentJobPositionId, setCurrentJobPositionId] = useState<string>()

    const { error: toastError } = useToast();

    const jobPositionsQueryContext = useQueryContext("JobPosition");
    const [jobPositionsResponse,] = useQuery({
        query: JobPositions_AllSimpleDocument,
        context: jobPositionsQueryContext,
        variables: {},
    });

    const entryCategoriesQueryContext = useQueryContext("EntryCategory");
    const [entryCategoriesResponse] = useQuery({
        query: AllEntryCategories_AllDocument,
        context: entryCategoriesQueryContext,
    });

    const acquisitionCategoriesQueryContext = useQueryContext("AcquisitionCategory");
    const [acquisitionCategoriesResponse] = useQuery({
        query: AllAcquisitionCategories_AllDocument,
        context: acquisitionCategoriesQueryContext,
    });

    const jobPositionContext = useQueryContext('JobPosition')
    const [jobPositionResponse] = useQuery({
        query: JobPosition_OneByIdDocument,
        variables: { id: currentJobPositionId || '' },
        context: jobPositionContext,
        pause: !currentJobPositionId?.length,
    });

    useEffect(() => {
        const entryCategories = entryCategoriesResponse.data?.entryCategories;
        const acquisitionCategories = acquisitionCategoriesResponse.data?.acquisitionCategories;
        const jobPositions = jobPositionsResponse.data?.jobPositions.edges;
        const recruitmentSteps = jobPositionResponse.data?.jobPosition.recruitmentStepsV2 || [];

        if (hasValue(entryCategories) && hasValue(acquisitionCategories) && hasValue(jobPositions) && hasValue(recruitmentSteps)) {
            setData({
                isLoading: false,
                data: {
                    entryCategories,
                    acquisitionCategories,
                    jobPositions,
                    recruitmentSteps,
                }
            })
        }

        if (responseHasError(entryCategoriesResponse)
            || responseHasError(acquisitionCategoriesResponse)
            || responseHasError(jobPositionsResponse)
            || responseHasError(jobPositionResponse)
        ) {
            toastError('global.error');
            //TODO: handle error
        }
    }, [
        entryCategoriesResponse,
        acquisitionCategoriesResponse,
        jobPositionsResponse,
        jobPositionResponse,
    ]);

    return {
        data,
        setCurrentJobPositionId
    }
}

export {
    useApplicationInformationSubFormState
}