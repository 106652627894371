import { range } from "@helpers/unsorted/arrayExtra";
import { useEffect, useState } from "react";

interface PaginationHookType {
    disableLoadingPreviousSequence: boolean;
    loadNextSequence: VoidFunction;
    disableLoadingNextSequence: boolean;
    loadPreviousSequence: VoidFunction;
    disableToPreviousPage: boolean;
    loadPreviousPage: VoidFunction;
    disableToNextPage: boolean;
    loadNextPage: VoidFunction;
    pageSequence: readonly number[];
}

const usePagination = (pageCount: number, currentPage: number, onSelectPage: (page: number) => void): PaginationHookType => {
    const pagesPerSequence = 10;

    const calculateStartEndPage = () => {
        const startPage = Math.floor((currentPage - 1) / pagesPerSequence) * pagesPerSequence + 1;
        const endPage = startPage + pagesPerSequence - 1 > pageCount ? pageCount : startPage + pagesPerSequence - 1;

        return [startPage, endPage] as [number, number];
    }

    const [[startPage, endPage], setStartEndPage] = useState<[number, number]>(calculateStartEndPage());

    const disableLoadingPreviousSequence = startPage === 1;

    const loadPreviousSequence = () => {
        const newStartPage = startPage - pagesPerSequence < 1 ? 1 : startPage - pagesPerSequence;
        const newEndPage = newStartPage + pagesPerSequence - 1 > pageCount ? pageCount : newStartPage + pagesPerSequence - 1;
        setStartEndPage([newStartPage, newEndPage]);
    }

    const disableLoadingNextSequence = endPage === pageCount;

    const loadNextSequence = () => {
        if (endPage + pagesPerSequence > pageCount) {
            setStartEndPage([startPage + pagesPerSequence, pageCount]);
        } else {
            setStartEndPage([startPage + pagesPerSequence, endPage + pagesPerSequence]);
        }
    }

    const disableToPreviousPage = currentPage === 1;

    const loadPreviousPage = () => {
        onSelectPage(currentPage - 1);
    }

    const disableToNextPage = currentPage === pageCount;

    const loadNextPage = () => {
        onSelectPage(currentPage + 1);
    }

    useEffect(() => {
        setStartEndPage(calculateStartEndPage());
    }, [pageCount, currentPage])

    return {
        disableLoadingPreviousSequence,
        loadNextSequence,
        disableLoadingNextSequence,
        loadPreviousSequence,
        disableToPreviousPage,
        loadPreviousPage,
        disableToNextPage,
        loadNextPage,
        pageSequence: range(endPage - startPage + 1, startPage),
    }
}

export {
    usePagination
}