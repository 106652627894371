import { addMinutes } from "date-fns";
import { z } from "zod";

import * as GroupValue from "@shared/event/event_session/EventSessionGroup/value"
import { dateTimeFormat } from "@helpers/unsorted/dateTimeFormat";

namespace Encoder {
    export type Type = ReturnType<typeof defaultValues>

    export const defaultValues = (isOnline: boolean, length: number, defaultClientIds: string[] = []) => {
        return {
            groupCount: "1",
            date: "",
            startTime: "",
            groups: Array<GroupValue.Encoder.Type>(length).fill(GroupValue.Encoder.defaultValues(isOnline, "", defaultClientIds))
        }
    }
}

namespace Decoder {
    export const schema = (eventDuration: number) => z.object({
        groupCount: z.string().min(1),
        date: z.string().min(1),
        startTime: z.string().min(1),
        groups: z.array(GroupValue.Decoder.schema)
    }).transform((values, ctx) => {
        const startTime = dateTimeFormat.fromStringValid(`${values.date} ${values.startTime}`);

        if (startTime === null) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid date or start time",
            });

            return z.NEVER;
        }

        const endTime = addMinutes(startTime, eventDuration)

        const groups = values.groups.slice(0, parseInt(values.groupCount))
        const invalidGroup = groups.find(
            group => group.location.length === 0
        )

        if (invalidGroup) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid group",
            });

            return z.NEVER;
        }

        return {
            groupCount: parseInt(values.groupCount),
            groups,
            startTime,
            endTime
        }
    })
}

export {
    Encoder,
    Decoder,
}