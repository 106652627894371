import { FunctionComponent, useMemo } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { I18nKey, useTranslation } from 'react-i18next';
import { useTabContext } from './useTabContext';

const styles = {
    container: Cn.c('flex space-x-1 box-border'),
    tab: (active: boolean, isDisabled: boolean) => Cn.join([
        Cn.c('px-6 pb-4 font-paragraph-base-medium bg-surface-default tabs-negative-margin text-emphasized cursor-pointer'),
        Cn.ifTrue(active, Cn.c('border-b-2 border-primary-emphasized text-primary-default')),
        Cn.ifTrue(isDisabled, Cn.c('text-disabled')),
    ]),
};

interface Props extends ChildrenProps {
    id: string;
    label: I18nKey;
    disabled?: boolean;
    route?: string;
}

const Tab: FunctionComponent<Props> = ({
    id,
    label,
    disabled: isDisabled = false,
    route,
    children,
}) => {
    const { t } = useTranslation();

    const { activeTab, setActiveTab } = useTabContext();

    const isActive = useMemo(() => activeTab === id, [activeTab, id]);

    return (
        <button
            className={styles.tab(isActive, isDisabled)}
            disabled={isActive || isDisabled}
            onClick={() => setActiveTab(id, route)}
            type="button"
        >
            <div className={styles.container}>
                <span>{t(label)}</span>
                <span>{children}</span>
            </div>
        </button>
    );
};

export {
    Tab
};

