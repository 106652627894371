
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { hasValue } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { useApplication } from './hook';

const styles = {
    applicationCard: (isSelected: boolean) =>
        Cn.join([
            Cn.c('bg-surface-default p-4 application-card-border relative flex flex-row cursor-pointer first:rounded-t last:rounded-b'),
            Cn.ifTrue(isSelected, 'application-card-selected-border'),
        ]),
    statusIcon: (isActive: boolean) =>
        Cn.join([
            Cn.c('h-2 w-2 rounded mt-2 mr-3'),
            isActive ? Cn.c('bg-icons-success-emphasized') : Cn.c('bg-icons-disabled'),
        ]),
    cardTitle: Cn.c('font-paragraph-small-bold text-default max-w-40'),
    cardSubtitle: Cn.c('text-subdued max-w-40'),
};

interface Props {
    isSelected: boolean;
    isActive?: boolean;
    title: string;
    id: string;
    currentStepName?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStep.name'>;
    currentStepStage?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStep.stage'>;
}

const Application: FunctionComponent<Props> = ({ isSelected, title, currentStepName, currentStepStage, id, isActive = false }) => {
    const { t } = useTranslation();

    const { onClickApplication } = useApplication();

    return (
        <div className={styles.applicationCard(isSelected)} onClick={() => onClickApplication(id)}>
            <div className={styles.statusIcon(isActive)}></div>
            <div>
                <div className={styles.cardTitle}>{title}</div>
                <div className={styles.cardSubtitle}>
                    {currentStepStage && <span>{t(`jobPositions.panel.recruitmentFlow.stage.${currentStepStage}.name`)}</span>}
                    {currentStepName && <> / <span>{currentStepName}</span></>}
                </div>
            </div>
        </div>
    );
};

export {
    Application
};