import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { FunctionComponent } from 'react';

interface CarouselItemProps extends ChildrenProps {
    className?: string;
}

const CarouselItem: FunctionComponent<CarouselItemProps> = ({ children, className }) => {
    return (
        <div className={Cn.getSome(className)}>
            {children}
        </div>
    );
};

export { 
    CarouselItem,
};