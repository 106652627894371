import { useStepperContext } from "@shared/layout/stepper/useStepperContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCSVBulkEvaluationFormContext } from "../../CSVBulkEvaluationFormContext";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { downloadUrl } from "@helpers/unsorted/downloadUrl";
import { parseSize, toSizeString } from "@helpers/unsorted/numberExtra";
import { MAX_FILE_SIZE } from "@helpers/core/constants";
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from "urql";
import { ValidateBulkEvaluationCsvFileDocument } from "@entities";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { checkError } from "@pages/application/CSVBulkEvaluationPage/helper";

const useCSVBulkEvaluationSetupStep = () => {
    const { t } = useTranslation();

    const { goToStep } = useStepperContext()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [{ csvFile }, dispatch] = useCSVBulkEvaluationFormContext()

    const [validateCsvResponse, validateBulkEvaluationCSV] = useMutation(ValidateBulkEvaluationCsvFileDocument);

    const { error: toastError } = useToast();

    const downloadTemplate = () =>
        downloadUrl(
            t('applications.bulkEvaluation.setupStep.templateName'),
            '/bulk_evaluation_template.csv',
        );

    const onUpload = (files?: File[]) => {
        if (files?.length) {
            if (files.some(file => file.size > parseSize(MAX_FILE_SIZE.CSV_IMPORT))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.CSV_IMPORT) });
                removeFile()

                return;
            }

            const fileUploaded = files[0];

            dispatch({
                name: 'SetFileUpload',
                payload: {
                    csvFile: {
                        file: fileUploaded,
                        filename: fileUploaded.name,
                        id: uuidv4(),
                    },
                },
            });
        }
    };

    const removeFile = () => dispatch({
        name: 'SetFileUpload',
        payload: {
            csvFile: undefined,
        },
    });

    const submit = () => {
        if (!csvFile) {
            return;
        }

        validateBulkEvaluationCSV({
            filename: csvFile.filename,
            content: csvFile.file as any
        })
    }

    useEffect(() => {
        handleResponse(validateCsvResponse, {
            onFetching: () => setIsSubmitting(true),
            onError: () => {
                setIsSubmitting(false);
            },
            onData: (data) => {
                setIsSubmitting(false);
                if (checkError(data.validateBulkEvaluationCSVFile)) {
                    goToStep('errorCorrection')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'errorCorrection',
                                data: {
                                    errorCSVUrl: data.validateBulkEvaluationCSVFile.errorCSVUrl,
                                    errorSummary: data.validateBulkEvaluationCSVFile.errorSummary,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.bulkEvaluation.steps.errorCorrection', variant: 'selected' },
                                        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'default' },
                                        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
                                    ],
                                    resetCSVFile: true
                                }
                            }
                        }
                    })
                } else {
                    goToStep('preview')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'preview',
                                data: {
                                    validationResults: data.validateBulkEvaluationCSVFile.result,
                                    validCSVUrl: data.validateBulkEvaluationCSVFile.validCSVUrl,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                                        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'selected' },
                                        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
                                    ],
                                }
                            }
                        }
                    })
                }
            },
        });
    }, [validateCsvResponse]);

    return {
        onUpload,
        removeFile,
        csvFile,
        isSubmitting,
        submit,
        downloadTemplate,
    }
}

export {
    useCSVBulkEvaluationSetupStep
}