import { FunctionComponent } from 'react';
import { PreferencesForm } from './PreferencesForm/PreferencesForm';
import { PreferencesLoadingState } from './PreferencesLoadingState/PreferencesLoadingState';
import { useInitialState } from './init';
import { usePreferences } from './hook';

const Preferences: FunctionComponent = () => {
    const {
        initialState
    } = useInitialState()

    usePreferences(initialState)

    return initialState.isLoading
        ? <PreferencesLoadingState />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <PreferencesForm emailSignatures={initialState.result.data.emailSignatures} />
};

export {
    Preferences
};
