import { parse, format, isValid, addHours, set } from "date-fns";
import { getLocale, SupportedLanguage } from '@helpers/core/i18n';

// @@ocaml.doc("The date formatters and parsers
//     - Simple date: yyyy-MM-dd (eee) or yyyy/MM/dd (eee)")

type SimpleDateSeparator = "/" | "-";

// @ocaml.doc("Formats a Date to a simple date string using the `/` separator")
const simpleDate = (
  date: Date,
  separator: SimpleDateSeparator = "/",
  language?: SupportedLanguage,
) => {
  const locale = getLocale(language);

  return format(date, `yyyy${separator}MM${separator}dd (eee)`, { locale });
};

// @ocaml.doc("Formats a Date to a simple time string")
const simpleTime = (date: Date) => format(date, `HH:mm`);

/** Returns a simple time with a specific hours and minutes values */
const simpleTimeFromValues = (
  { hours, minutes } : {hours: number, minutes: number},
  date?: Date,
) => {
  const dateObj = date || new Date(0, 0, 0);

  return simpleTime(set(dateObj, { hours, minutes }));
};

/** Extracts the hours and minutes values of the specified Date */
const getHoursAndMinutes = (date: Date) => {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

// @ocaml.doc("Formats values to a simple date string using the `/` separator")
// TODO: Check whether the values are intended to be numbers or strings
// Note: Month values are 0 indexed
const simpleDateFromValues = (
  year: number,
  month: number,
  date: number,
  separator: SimpleDateSeparator = "/",
  language?: SupportedLanguage,
) => {
  const locale = getLocale(language);

  return format(
    new Date(year, month, date),
    `yyyy${separator}MM${separator}dd (eee)`,
    { locale },
  );
};

// @ocaml.doc("Parse simple date strings into Date")
// Note: If the parsed date is invalid, it will return an `Invalid Date` JS Date object
const fromString = (simpleDate: string) =>
  parse(simpleDate.replace(/\//g, "-"), "yyyy-MM-dd", new Date());

// @ocaml.doc("Parse simple date strings into Date, returns null if the date is invalid")
const fromStringValid = (simpleDate: string) => {
  const parsedDate = fromString(simpleDate);

  return isValid(parsedDate) ? parsedDate : null;
};

/** Returns a valid time formatted in HH:mm from a string */
const parseTime = (timeString: string) => {
  return parse(timeString, 'HH:mm', new Date());
};

// @ocaml.doc("format the date for the date picker")
const simpleDateWithoutWeekday = (
  date: Date,
  separator: SimpleDateSeparator = "/",
) => {
  return format(date, `yyyy${separator}MM${separator}dd`);
};

/** Format date without separator */
const simpleDateNoSeparator = (
  date: Date,
) => {
  return format(date, `yyyyMMdd`);
};

const dateFormat = {
  simpleDate,
  simpleTime,
  simpleDateFromValues,
  fromString,
  fromStringValid,
  simpleDateWithoutWeekday,
  simpleDateNoSeparator,
  simpleTimeFromValues,
  getHoursAndMinutes,
  parseTime,
};

export { SimpleDateSeparator, dateFormat };
