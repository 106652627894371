import { FunctionComponent, useState } from "react";
import { MenuData, MenuItemData, Size, SubmenuPosition } from "./NestedMenu";
import { Cn } from "@helpers/unsorted/classNames";
import { MenuContainer } from "./MenuContainer";
import { Icon } from "../Icon/Icon";
import { isDefined } from "@helpers/core/typeGuards";
import { ToolTipBox } from "../ToolTipBox/ToolTipBox";

type State = 'disabled' | 'selected' | 'default';

const styles = {
    menuItems: (size: Size, state: State) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c("font-paragraph-xsmall-regular")
                break;
            case 'medium':
                sizeStyles = Cn.c("font-paragraph-small-regular")
                break;
            case 'large':
                sizeStyles = Cn.c("font-paragraph-base-regular")
                break;
            case 'x-large':
                sizeStyles = Cn.c("font-paragraph-base-regular")
                break;
        }

        let stateStyles;

        switch (state) {
            case 'disabled':
                stateStyles = Cn.c("text-disabled cursor-not-allowed")
                break;
            case 'selected':
                stateStyles = Cn.c("text-primary-default")
                break;

            default:
                stateStyles = Cn.c("text-emphasized hover:bg-surface-hovered-default")
                break;
        }

        return Cn.join([
            Cn.c("cursor-pointer py-2 px-4 block flex items-center justify-between w-full relative"),
            sizeStyles,
            stateStyles,
        ])
    },
    arrowIcon: Cn.c("h-3.5 w-3.5")
}

interface Props {
    item: MenuItemData
    itemCustomization?: MenuData['item'];
    depthLevel: number
    size?: Size
    submenuPosition?: SubmenuPosition
    value?: string
    onChange?: (value: string) => void
}

const MenuItem: FunctionComponent<Props> = ({
    item,
    itemCustomization,
    depthLevel,
    size = 'medium',
    submenuPosition = 'right',
    value,
    onChange,
}) => {
    const state = item.isDisabled ? 'disabled' : value === item.value ? 'selected' : 'default';
    const [showSubmenu, setShowSubmenu] = useState(false);

    const openSubmenu = () => setShowSubmenu(true);
    const closeSubmenu = () => setShowSubmenu(false);

    const content = (
        <li
            className={styles.menuItems(size, state)}
            onMouseEnter={openSubmenu}
            onMouseLeave={closeSubmenu}
            onClick={() => !item.isDisabled && !item.children && onChange && onChange(item.value)}
        >
            <p>{item.label}</p>
            {item.children && <Icon name='arrowRight' className={styles.arrowIcon} />}
            {
                item.children &&
                <MenuContainer
                    itemCustomization={itemCustomization}
                    groups={item.children}
                    size={size}
                    submenuPosition={submenuPosition}
                    showMenu={!item.isDisabled && item.children.length > 0 && showSubmenu}
                    depthLevel={depthLevel}
                    onChange={onChange}
                />
            }
        </li>
    )

    return isDefined(item.tooltip)
        ? <ToolTipBox
            {...itemCustomization?.tooltip}
            tooltipContent={item.tooltip}
        >
            {content}
        </ToolTipBox>
        : content
}

export {
    MenuItem,
}