import {
    RecruitmentProcessHistoryEventSchedule,
    RecruitmentProcessHistoryExaminer,
    RecruitmentProcessHistoryDecision,
    RecruitmentProcessHistoryDocumentStepDocumentConfirmation,
} from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { hasValue } from '@helpers/core/typeGuards';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface HistoryHookResult {
    schedule: string;
    examiners: string;
    decision: string;
    documentStatus: string
}

const useHistory = ({
    isCurrentStep,
    isStepCompleted,
    hasEvaluation,
    hasDecision,
    decisionHistory,
    eventSchedule,
    examinersHistory,
    documentConfirmation,
}: {
    isCurrentStep: boolean,
    isStepCompleted: boolean,
    hasEvaluation: boolean,
    hasDecision: boolean,
    decisionHistory?: RecruitmentProcessHistoryDecision | null
    eventSchedule?: RecruitmentProcessHistoryEventSchedule | null,
    examinersHistory?: RecruitmentProcessHistoryExaminer[] | null,
    documentConfirmation?: RecruitmentProcessHistoryDocumentStepDocumentConfirmation | null
}): HistoryHookResult => {
    const { t } = useTranslation();

    const currentLanguage = useCurrentLanguage();

    const [schedule, setSchedule] = useState(getWithDefault());

    const [decision, setDecision] = useState(getWithDefault());

    const [examiners, setExaminers] = useState(getWithDefault());

    const [documentStatus, setDocumentStatus] = useState(getWithDefault());

    useEffect(() => {
        if (hasValue(eventSchedule)) {
            setSchedule(`${dateFormat.simpleDate(new Date(eventSchedule.startAt), "/", currentLanguage)} ${dateFormat.simpleTime(new Date(eventSchedule.startAt))} ~ ${dateFormat.simpleTime(new Date(eventSchedule.endAt))}`)
        } else if (isStepCompleted) {
            setSchedule(getWithDefault());
        } else if (isCurrentStep) {
            setSchedule(t('applications.panel.candidateHistory.pending'));
        } else {
            setSchedule(getWithDefault());
        }
    }, [isStepCompleted, isCurrentStep, eventSchedule])

    useEffect(() => {
        if (!hasEvaluation) {
            setExaminers(getWithDefault());
        } else if (hasValue(examinersHistory) && examinersHistory.length > 0) {
            setExaminers(examinersHistory.map(examiner => examiner.fullName).join(', '));
        } else if (isStepCompleted) {
            setExaminers(getWithDefault());
        } else if (isCurrentStep) {
            setExaminers(t('applications.panel.candidateHistory.pending'));
        } else {
            setExaminers(getWithDefault());
        }
    }, [isStepCompleted, isCurrentStep, hasEvaluation, examinersHistory]);

    useEffect(() => {
        if (!hasDecision) {
            setDecision(getWithDefault());
        } else if (hasValue(decisionHistory)) {
            const { finalResult, preliminaryResult } = decisionHistory;

            if (hasValue(finalResult)) {
                setDecision(t(`applications.panel.candidateHistory.decision.final.${finalResult}`));
            } else if (hasValue(preliminaryResult)) {
                setDecision(t(`applications.panel.candidateHistory.decision.preliminary.${preliminaryResult}`));
            }
        } else if (isStepCompleted) {
            setDecision(getWithDefault());
        } else if (isCurrentStep) {
            setDecision(t('applications.panel.candidateHistory.pending'));
        } else {
            setDecision(getWithDefault());
        }
    }, [isStepCompleted, isCurrentStep, decisionHistory, hasDecision]);

    useEffect(() => {
        if (!hasValue(documentConfirmation)) {
            setDocumentStatus(getWithDefault());
        } else if (documentConfirmation.isConfirmed) {
            setDocumentStatus(t('applications.panel.candidateHistory.documentConfirmed'));
        } else if (isStepCompleted) {
            setDocumentStatus(getWithDefault());
        } else if (isCurrentStep) {
            setDocumentStatus(t('applications.panel.candidateHistory.pending'));
        } else {
            setDocumentStatus(getWithDefault());
        }
    }, [isStepCompleted, isCurrentStep, documentConfirmation]);

    return {
        schedule,
        examiners,
        decision,
        documentStatus,
    };
};

export {
    useHistory,
};