import { JobPositions_AllQuery } from '@entities';
import { AwaitableState } from '@typedefs/aliases';
import { Entity } from '@typedefs/graphql';

interface Data {
    totalRows: number;
    clientHasNoJobPosition: boolean;
    jobPositions: Entity<JobPositions_AllQuery, 'jobPositions.edges'>[];
}

interface SharedState {
    search?: string | null;
    currentPage: number;
}

type State = AwaitableState<Data, SharedState>;

interface UpdateJobPositionsAction {
    name: 'UpdateJobPositions';
    totalRows: number;
    jobPositions: Entity<JobPositions_AllQuery, 'jobPositions.edges'>[];
    clientHasNoJobPosition: boolean;
}

interface SetQueryParamsAction {
    name: 'SetQueryParams';
    search?: string | null;
    currentPage: number;
}

interface ResetAction {
    name: 'Reset';
}

type Action = UpdateJobPositionsAction | SetQueryParamsAction | ResetAction;

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'UpdateJobPositions':
            return {
                ...state,
                isLoading: false,
                data: {
                    totalRows: action.totalRows,
                    jobPositions: action.jobPositions,
                    clientHasNoJobPosition: action.clientHasNoJobPosition,
                }
            };

        case 'SetQueryParams':
            return {
                ...state,
                search: action.search,
                currentPage: action.currentPage,
            };

        case 'Reset':
            return initialState;

        default:
            return state;
    }
};


const initialState: State = {
    isLoading: true,
    currentPage: 1,
    search: undefined,
};

export {
    Action as JobListPanelAction,
    State as JobListPanelState,
    initialState,
    reducer
};

