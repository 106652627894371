import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCSVImportFormContext } from "../../../useCSVImportFormContext";
import { useStepperContext } from "@shared/layout/stepper/useStepperContext";
import { Candidate } from "@routes/candidate";

const styles = {
    container: Cn.c("flex flex-col flex-1 overflow-auto items-center justify-center space-y-8"),
    iconWrapper: Cn.c("bg-icons-success-default w-[7.5rem] h-[7.5rem] rounded-full"),
    icon: Cn.c("text-on-primary"),
    textWrapper: Cn.c("flex flex-col items-center space-y-2"),
    successText: Cn.c("font-h4-bold text-emphasized"),
    description: Cn.c("font-paragraph-small-regular text-subdued"),
    buttonWrapper: Cn.c("flex justify-center space-x-4")
}

interface Props {
    totalCount: number;
}

const CSVImportImportStepSuccess: FunctionComponent<Props> = ({
    totalCount
}) => {
    const { t } = useTranslation()

    const [_, dispatch] = useCSVImportFormContext()

    const { goToStep } = useStepperContext()

    const navigate = useNavigate();

    const addAnotherFile = () => {
        goToStep('setup')
        dispatch({
            name: 'Reset'
        })
    }

    const goToTop = () => {
        navigate(Candidate.PATH_NAME);
    }

    return (
        <div className={styles.container}>
            <div className={styles.iconWrapper}>
                <Icon name="check" className={styles.icon} />
            </div>
            <div className={styles.textWrapper}>
                <p className={styles.successText}>{t('applications.csvImport.importStep.success.importSuccess')}</p>
                <p className={styles.description}>{t('applications.csvImport.importStep.success.description', { count: totalCount })}</p>
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    size="md"
                    variant="primaryFilled"
                    onClick={goToTop}
                >
                    {t('applications.csvImport.importStep.success.viewCandidateList')}
                </Button>
                <Button
                    size="md"
                    variant="primaryOutline"
                    onClick={addAnotherFile}
                >
                    {t('applications.csvImport.importStep.success.importAnotherFile')}
                </Button>
            </div>
        </div>
    )
}

export {
    CSVImportImportStepSuccess
}