import { useTranslation } from "react-i18next";
import { BulkActionGeneralConfirmationPayload } from "../reducer"
import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useBulkActionExecution } from "@shared/application/useBulkActionExecution";
import { useBulkActionModalContext } from "../BulkActionModalContext";

const useBulkActionGeneralConfirmationModal = ({
    closeModal,
    payload,
}: {
    closeModal: VoidFunction;
    payload?: BulkActionGeneralConfirmationPayload;
}) => {
    const action = payload?.action
    const totalApplications = payload?.applicationIdBatches.reduce((acc, batch) => acc + batch.length, 0) || 0

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const { t } = useTranslation();

    const { success: toastSuccess } = useToast()

    const { setApplicationSelection, applicationSelection } = useApplicationSelectionContext()

    const { execute } = useBulkActionExecution({
        action: payload?.action,
        payload,
        onStartExecution: () => {
            if (action) {
                dispatchBulkActionModalMode({
                    name: 'showBulkActionLoadingModal',
                    payload: {
                        action,
                        total: totalApplications,
                        processed: 0
                    }
                })
            }
        },
        onCompleteExecutingBatch: (result) => {
            if (action) {
                dispatchBulkActionModalMode({
                    name: 'showBulkActionLoadingModal',
                    payload: {
                        action,
                        total: totalApplications,
                        processed: result.totalProcessed
                    }
                })
            }
        },
        onCompleteExecution: (result) => {
            if (action) {
                if (result.detail.success === totalApplications) {
                    closeModal()
                    toastSuccess('applications.bulkAction.success')
                    setApplicationSelection({})
                }

                if (result.detail.failed > 0) {
                    //TODO: update the properties of applications
                    setApplicationSelection({
                        ...applicationSelection,
                        [action]: result.detail.failedIds.reduce((acc: Record<string, true>, id) => {
                            acc[id] = true

                            return acc
                        }, {})
                    })

                    dispatchBulkActionModalMode({
                        name: 'showBulkActionErrorModal',
                        payload: {
                            action,
                            failedIds: result.detail.failedIds,
                            success: result.detail.success,
                            bulkActionPayload: payload.bulkActionPayload
                        }
                    })
                }
            }
        },
    })

    const numberOfCandidate = payload?.applicationIdBatches.reduce((acc, batch) => acc + batch.length, 0) || 0

    const note = action === 'REVERT_TO_PREVIOUS_STATUS'
        ? t(`applications.bulkAction.${action}.note`)
        : undefined

    return {
        numberOfCandidate,
        confirm: execute,
        note
    }
}

export {
    useBulkActionGeneralConfirmationModal
}