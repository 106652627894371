import { hasValue, isArrayOf, isDefined, isNullable, isString } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';
import { FieldErrors } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

// TODO: [IMPLEMENT] Fix types
const useCheckboxGroup = (
    name: string,
    value: string[] | undefined,
    errors?: FieldErrors,
    label?: I18nKey,
    errorLabel?: I18nKey,
    onChange?: (event: unknown) => void,
) => {
    const { t } = useTranslation();

    const error = useErrorMessage(
        name,
        errors,
        isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
    );

    // TODO: [CHECK] Verify if converted code is correct
    const getSelectedValues = (): string[] => isArrayOf(isNullable(isString))(value) ? value.filter(hasValue) : [];

    const isChecked = (currentValue: string): boolean => {
        const selectedValues = getSelectedValues();

        return selectedValues.includes(currentValue);
    };

    const onToggleSelect = (currentValue: string) => {
        const selectedIds = getSelectedValues();

        const newSelectedIds = isChecked(currentValue) ?
            selectedIds.filter(option => option !== currentValue) :
            [
                ...selectedIds,
                currentValue
            ];

        isDefined(onChange) && onChange(newSelectedIds);
    };

    return {
        error,
        isChecked,
        onToggleSelect,
    };
};

export {
    useCheckboxGroup,
};
