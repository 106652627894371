
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Loader } from '@shared/unsorted/Loader/Loader';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { MultiValueTypeAhead } from '@shared/unsorted/MultiValueTypeAhead/MultiValueTypeAhead';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SessionGroupManipulationMode } from '../hook';
import { useEventSessionGroupManipulateExaminersModal } from './hook';

const styles = {
    loadingContainer: Cn.c("h-[21rem] rounded-xl"),
    container: Cn.c("max-h-[95%] rounded-xl"),
    form: Cn.c("h-full flex flex-col"),
    header: Cn.c("p-6 flex justify-between items-center border-b border-default"),
    title: Cn.c("text-emphasized font-h4-bold"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("p-6 flex flex-col space-y-6 flex-1"),
    loadingBody: Cn.c("flex items-center justify-center flex-1"),
    groupContainer: Cn.c("mt-6"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
};

interface Props extends ModalProps {
    manipulationMode: SessionGroupManipulationMode;
    closeModal: VoidFunction;
}

const EventSessionGroupManipulateExaminersModal: FunctionComponent<Props> = ({ isOpen, manipulationMode, closeModal }) => {
    const { t } = useTranslation();

    const {
        onCloseModal,
        isLoading,
        isSubmitting,
        clientOptions,
        form,
        initClients,
        filterSelectedClients,
        filterMatchingClients,
        onSubmit,
        canSave,
    } = useEventSessionGroupManipulateExaminersModal(manipulationMode, closeModal);

    const { control, clearErrors, formState: { errors } } = form;

    return isLoading
        ? <Modal
            isOpen={isOpen}
            close={onCloseModal}
            widthClass="w-[50rem]"
            className={styles.loadingContainer}
        >
            <div className={styles.form}>
                <div className={styles.header}>
                    <p className={styles.title}>{t("event.eventSessionGroupManipulateExaminersModal.title")}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
                </div>
                <div className={styles.loadingBody}>
                    <Loader
                        variant="primaryDefault"
                        size="lg"
                    />
                </div>
            </div>
        </Modal>
        : <Modal
            isOpen={isOpen}
            close={onCloseModal}
            widthClass="w-[50rem]"
            className={styles.container}
        >
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.header}>
                    <p className={styles.title}>{t("event.eventSessionGroupManipulateExaminersModal.title")}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
                </div>
                <div className={styles.body}>
                    <Controller
                        name='clientIds'
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { name, ref } }) =>
                            <MultiValueTypeAhead
                                name={name}
                                label="event.eventSessionGroupManipulateExaminersModal.examinersLabel"
                                listOrientation="downwards"
                                options={clientOptions}
                                onInputChange={filterMatchingClients}
                                onValueChange={filterSelectedClients}
                                inputValue={initClients}
                                errors={errors}
                                clearErrors={clearErrors}
                                forwardedRef={ref}
                                form={form}
                                enableInsert={false}
                            />}
                    />
                </div>
                <div className={styles.footer}>
                    <Button
                        variant="secondary"
                        onClick={onCloseModal}
                        size="md"
                        isFull={true}
                        isDisabled={isSubmitting}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        isFull={true}
                        type="submit"
                        isDisabled={isSubmitting || !canSave}
                        isLoading={isSubmitting}
                    >
                        {t("global.save")}
                    </Button>
                </div>
            </form>
        </Modal>;

};

export {
    EventSessionGroupManipulateExaminersModal
};
