import { ValueItem as MultiValueTypeAheadValue } from '@shared/unsorted/MultiValueTypeAhead/value'
import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        subject: string;
        content: string;
        cc: MultiValueTypeAheadValue.ValueItemType[];
        bcc: MultiValueTypeAheadValue.ValueItemType[];
    }

    export const defaultValues = (): EncoderType => ({
        subject: '',
        content: '',
        cc: [],
        bcc: []
    })
}

namespace Decoder {
    export const schema = z.object({
        subject: z.string(),
        content: z.string(),
        cc: z.array(MultiValueTypeAheadValue.schema),
        bcc: z.array(MultiValueTypeAheadValue.schema)
    })
}

export {
    Encoder,
    Decoder
}