import { useErrorMessage } from "@helpers/hooks/unsorted/errorMessageHook";
import { Tree, Forest } from "@helpers/data/tree";
import { useMemo } from "react";
import { FieldErrors } from "react-hook-form";
import { I18nKey, useTranslation } from "react-i18next";

type CheckboxGroupOption = {
    type: "group";
    label: string;
    disabled?: boolean;
    tooltip?: string;
};

type CheckboxValueOption<Value> = {
    type: "value";
    value: Value;
    label: string;
    disabled?: boolean;
    tooltip?: string;
};

type CheckboxOption<Value> = CheckboxGroupOption | CheckboxValueOption<Value>;

type NestedCheckboxOption<Value> = Tree<CheckboxOption<Value>>;

type CheckboxState<Value> = CheckboxOption<Value> & {
    key: string;
    disabled: boolean;
    checked: boolean;
    values: Value[];
};

const stateFromOptions = <Value>(
    options: NestedCheckboxOption<Value>[],
    values: Value[],
    valueEquals: (x: Value, y: Value) => boolean,
    key: string
): Forest<CheckboxState<Value>> => {
    const iter = (
        option: NestedCheckboxOption<Value>,
        key: string,
        parentDisabled: boolean
    ): Forest<CheckboxState<Value>> => {
        let disabled = parentDisabled || !!option.value.disabled;
        const children = option.children.flatMap((child, i) =>
            iter(child, `${key}_${i}`, disabled)
        );
        if (option.value.type === "group") {
            if (!children.length) {
                return [];
            }
            disabled =
                disabled || children.every((state) => state.value.disabled);
            return [
                {
                    value: {
                        ...option.value,
                        key,
                        disabled,
                        values: disabled
                            ? []
                            : children.flatMap((child) => child.value.values),
                        checked: children.every((state) =>
                            disabled
                                ? state.value.checked
                                : state.value.disabled || state.value.checked
                        ),
                    },
                    children,
                },
            ];
        } else {
            const { value } = option.value;
            return [
                {
                    value: {
                        ...option.value,
                        key,
                        disabled,
                        values: disabled ? [] : [option.value.value],
                        checked: values.some((x) => valueEquals(x, value)),
                    },
                    children,
                },
            ];
        }
    };

    return options.flatMap((option, i) => iter(option, `${key}_${i}`, false));
};

type UseCheckboxGroupNestedInput<Value> = {
    name: string;
    value: Value[];
    valueEquals: (x: Value, y: Value) => boolean;
    options: NestedCheckboxOption<Value>[];
    label?: I18nKey;
    errors?: FieldErrors;
    errorLabel?: I18nKey;
};

const useNestedCheckboxGroup = <Value>(
    input: UseCheckboxGroupNestedInput<Value>
) => {
    const { name, value, valueEquals, options, label, errors, errorLabel } =
        input;
    const { t } = useTranslation();
    const error = useErrorMessage(
        name,
        errors,
        errorLabel != undefined
            ? t(errorLabel)
            : label != undefined
            ? t(label)
            : name
    );
    const state = useMemo(
        () => stateFromOptions(options, value, valueEquals, name),
        [name, options, value]
    );
    return { state, error };
};

export {
    useNestedCheckboxGroup,
    CheckboxOption,
    CheckboxState,
    NestedCheckboxOption,
};
