import { useContext } from "react";
import { EventSettingContext } from "./EventSettingContext";
import { isUndefined } from "@helpers/core/typeGuards";

const useEventSettingContext = () => {
    const context = useContext(EventSettingContext)

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <EventSettingContext.Provider>...</EventSettingContext.Provider> block.');
    }

    return context
}

export {
    useEventSettingContext,
}