import { FunctionComponent } from 'react';
import { ChildrenProps } from '@typedefs/props';

interface Props extends ChildrenProps {
    className?: string;
}

// @ocaml.doc("The header of panel component, contains the close area on the left.")
// TODO: [WORDING] Consider renaming component to `Body`
const Content: FunctionComponent<Props> = ({ children, className }) => <div className={className}>{children}</div>;

export {
    Content,
};
