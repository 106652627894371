import { Cn } from "@helpers/unsorted/classNames";
import { validations } from "@helpers/unsorted/validation";
import { ApplicationStepMode } from "@pages/ApplicationsPage/ApplicationPanel/reducer";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Input } from "@shared/unsorted/Input/Input";
import { MultiValueTypeAhead } from "@shared/unsorted/MultiValueTypeAhead/MultiValueTypeAhead";
import { TextArea } from "@shared/unsorted/TextArea/TextArea";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEmailEditor } from "./hook";

const styles = {
  emailEditorContainer: Cn.c("m-4 space-y-6 flex flex-col"),
  emailEditorTop: Cn.c("flex flex-row items-center pb-4 border-b-subdued border-b"),
  title: Cn.c("font-paragraph-base-bold text-default flex-1 text-left"),
  form: Cn.c("space-y-3"),
  addCcBccWrapper: Cn.c("flex items-center gap-x-2 cursor-pointer"),
  addIcon: Cn.c("text-icons-primary-default w-5 h-5"),
  addCcBccTitle: Cn.c("font-paragraph-small-medium text-default"),
  formButtons: Cn.c("space-x-2 flex mt-6")
}

interface Props {
  applicationId: string;
  dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void;
}

const EmailEditor: FunctionComponent<Props> = ({ applicationId, dispatchApplicationStepMode }) => {
  const { t } = useTranslation()

  const {
    form,
    onSubmit,
    isSubmitting,
    ccBccMode,
    displayCcBcc,
    emailContactOptions,
    searchEmail,
    setRemainingOptions,
    toShowMode
  } = useEmailEditor(applicationId, dispatchApplicationStepMode)

  const { control, clearErrors, formState: { errors } } = form

  return (
    <div className={styles.emailEditorContainer}>
      <div className={styles.emailEditorTop}>
        <div className={styles.title}>
          {t('applications.panel.candidateEmail.title')}
        </div>
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <Controller
          name='subject'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, onBlur, onChange, ref, value } }) =>
            <Input
              required
              name={name}
              label='applications.panel.candidateEmail.subject'
              onBlur={onBlur}
              onChange={onChange}
              errors={errors}
              clearErrors={clearErrors}
              forwardedRef={ref}
              value={value}
            />}
        />
        {
          ccBccMode === 'hide'
            ?
            <div className={styles.addCcBccWrapper} onClick={displayCcBcc}>
              <Icon name='plus' className={styles.addIcon} />
              <p className={styles.addCcBccTitle}>
                {t('applications.panel.candidateEmail.addCcBcc')}
              </p>
            </div>
            :
            <>
              <Controller
                name='cc'
                control={control}
                rules={{
                  validate: {
                    isEmailArray: validations.emailArray
                  }
                }}
                render={({ field: { name, ref } }) =>
                  <MultiValueTypeAhead
                    name={name}
                    label='applications.panel.candidateEmail.cc'
                    listOrientation='downwards'
                    options={emailContactOptions}
                    onInputChange={searchEmail}
                    onValueChange={setRemainingOptions}
                    errors={errors}
                    clearErrors={clearErrors}
                    forwardedRef={ref}
                    form={form}
                    enableInsert
                  />}
              />
              <Controller
                name='bcc'
                control={control}
                rules={{
                  validate: {
                    isEmailArray: validations.emailArray
                  }
                }}
                render={({ field: { name, ref } }) =>
                  <MultiValueTypeAhead
                    name={name}
                    label='applications.panel.candidateEmail.bcc'
                    listOrientation='downwards'
                    options={emailContactOptions}
                    onInputChange={searchEmail}
                    onValueChange={setRemainingOptions}
                    errors={errors}
                    clearErrors={clearErrors}
                    forwardedRef={ref}
                    form={form}
                    enableInsert
                  />}
              />
            </>
        }
        <Controller
          name='content'
          control={control}
          rules={{ required: true }}
          render={({ field: { name, onBlur, onChange, value, ref } }) =>
            <TextArea
              required
              name={name}
              orientation='vertical'
              label='applications.panel.candidateEmail.content'
              onBlur={onBlur}
              onChange={onChange}
              errors={errors}
              clearErrors={clearErrors}
              forwardedRef={ref}
              value={value}
            />
          }
        />
        <div className={styles.formButtons}>
          <Button
            size='md' variant='secondary' isDisabled={isSubmitting} onClick={toShowMode}>
            {t('global.cancel')}
          </Button>
          <Button size='md' type='submit' variant='primaryFilled' isLoading={isSubmitting}>
            {t('applications.panel.candidateEmail.sendEmail')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export {
  EmailEditor
};

