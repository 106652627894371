import { EventSession_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Label } from '@shared/unsorted/Label/Label';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { MultiValueTypeAhead } from '@shared/unsorted/MultiValueTypeAhead/MultiValueTypeAhead';
import { ToggleSwitch } from '@shared/unsorted/ToggleSwitch/ToggleSwitch';
import { Entity } from '@typedefs/graphql';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubMode as EventSessionGroupManipulateCandidateSubMode } from "../EventSessionGroupManipulateCandidateModal/hook";
import { useEventSessionGroupAddCandidateModal } from './hook';

const styles = {
    loadingContainer: Cn.c('h-[21rem] rounded-xl'),
    container: Cn.c('max-h-[95%] rounded-xl'),
    form: Cn.c('h-full flex flex-col'),
    header: Cn.c('p-6 flex justify-between items-center border-b border-default'),
    title: Cn.c('text-emphasized font-h4-bold'),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    body: Cn.c('p-6 flex flex-col space-y-6 flex-1'),
    loadingBody: Cn.c('flex items-center justify-center flex-1'),
    footer: Cn.c('p-6 border-t border-default flex justify-between items-center space-x-3'),
    description: Cn.c('font-paragraph-base-regular text-emphasized'),
    jobPositionDetail: Cn.c('bg-default rounded-lg p-4'),
    infoRow: Cn.c('flex space-x-3'),
    detailContainer: Cn.c('flex items-center space-x-1'),
    icon: Cn.c('text-icons-disabled w-4 h-4'),
    label: Cn.c('text-subdued font-paragraph-small-regular'),
    detail: Cn.c('font-paragraph-small-medium text-emphasized'),
    separator: Cn.c('border-b border-default'),
    sectionContainer: Cn.c('font-paragraph-base-medium text-emphasized flex flex-col space-y-6'),
    toggleContainer: Cn.c('flex items-center space-x-4'),
    toggleLabel: Cn.c('font-paragraph-base-medium text-emphasized'),
    candidates: Cn.c('flex justify-between'),
    candidatesCounter: Cn.c('flex items-center space-x-1 bg-default rounded-lg p-2'),
    countLabel: (isOverCapacity: boolean) => Cn.join([
        Cn.c('font-paragraph-small-medium'),
        isOverCapacity ? Cn.c('text-critical-default') : Cn.c('text-emphasized'),
    ]),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    subMode: EventSessionGroupManipulateCandidateSubMode;
    recruitmentProcessAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.recruitmentProcessAssignments'>[];
}

const EventSessionGroupAddCandidateModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    recruitmentProcessAssignments,
    subMode,
}) => {
    const { t } = useTranslation();

    const { event } = useEventSessionDetailContext();

    const {
        isSubmitting,
        onCloseModal,
        form,
        applicationOptions,
        filterMatchingCandidates,
        filterSelectedApplications,
        selectedApplications, // TODO: Might be needed for counting the assigned/allocated candidates vs the event capacity limit
        onSubmit,
        numOfAssignedCandidates,
        onValidateAllocatedCandidates,
    } = useEventSessionGroupAddCandidateModal(
        closeModal,
        recruitmentProcessAssignments,
        subMode,
    );

    const { clearErrors, control, formState: { errors } } = form;

    return (
        <Modal
            isOpen={isOpen}
            close={onCloseModal}
            widthClass="w-[50rem]"
            className={styles.container}
        >
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.header}>
                    <p className={styles.title}>{t("event.eventSessionGroupAddCandidateModal.title")}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
                </div>
                <div className={styles.body}>
                    <div className={styles.description}>{t("event.eventSessionGroupAddCandidateModal.description")} </div>
                    <div className={styles.jobPositionDetail}>
                        <div className={styles.infoRow}>
                            <div className={styles.detailContainer}>
                                <Icon name="briefcaseFilled" className={styles.icon} />
                                <span className={styles.label}>{t("event.eventSessionGroupAddCandidateModal.appliedJobPositionLabel")}</span>
                                <span className={styles.detail}>{event.recruitmentStepV2.jobPosition.title}</span>
                            </div>
                            <div className={styles.detailContainer}>
                                <Icon name="flagFill" className={styles.icon} />
                                <span className={styles.label}>{t("event.eventSessionGroupAddCandidateModal.currentRecruitmentStep")}</span>
                                <span className={styles.detail}>{event.recruitmentStepV2.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator} />
                    <div className={styles.sectionContainer}>
                        <div className={styles.candidates}>
                            <p>{t("event.eventSessionGroupAddCandidateModal.selectCandidate")}</p>
                            <div className={styles.candidatesCounter}>
                                <Icon name="candidates" className={styles.icon} />
                                <span className={styles.label}>{t("event.eventSessionGroupManipulateCandidatesModal.allocatedCandidate")}</span>
                                <span className={styles.countLabel(numOfAssignedCandidates > event.candidateCapacity)}>
                                    {t("event.eventSessionGroupManipulateCandidatesModal.candidateCount",
                                        { registered: numOfAssignedCandidates, total: event.candidateCapacity })}
                                </span>
                            </div>
                        </div>
                        <Controller
                            name="recruitmentProcessIds"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, ref } }) =>
                                <MultiValueTypeAhead
                                    name={name}
                                    label="event.eventSessionGroupAddCandidateModal.candidateToAdd"
                                    placeholder="event.eventSessionGroupAddCandidateModal.candidateToAddPlaceholder"
                                    required
                                    listOrientation='downwards'
                                    options={applicationOptions}
                                    onInputChange={filterMatchingCandidates}
                                    onValueChange={filterSelectedApplications}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    form={form}
                                    enableInsert={false}
                                    warnValidator={onValidateAllocatedCandidates}
                                />}
                        />
                    </div>
                    <div className={styles.sectionContainer}>
                        <p>{t("event.eventSessionGroupAddCandidateModal.emailDelivery")}</p>
                        <div className={styles.toggleContainer}>
                            <Controller
                                name="sendReservationEmail"
                                control={control}
                                render={({ field: { name, value, onChange } }) =>
                                    <ToggleSwitch
                                        id={name}
                                        checked={value}
                                        onChange={() => onChange(!value)}
                                    />
                                }
                            />
                            <Label
                                className={styles.toggleLabel}
                                label="event.eventSessionGroupAddCandidateModal.sendEventReservation"
                                tooltip="event.eventSessionGroupAddCandidateModal.sendEventReservationTooltip"
                                tooltipPlacement="top">
                            </Label>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button
                        variant="secondary"
                        onClick={onCloseModal}
                        size="md"
                        isFull={true}
                        isDisabled={isSubmitting}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        isFull={true}
                        type="submit"
                        isDisabled={isSubmitting || selectedApplications.length === 0}
                        isLoading={isSubmitting}
                    >
                        {t("global.add")}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export {
    EventSessionGroupAddCandidateModal
};
