import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    topBar: Cn.c(
        "flex flex-shrink-0 px-10 items-center justify-center w-full h-12 bg-surface-default shadow-md fixed z-50 text-black",
    ),
    wrapper: Cn.c("pt-28 m-auto max-w-144"),
    form: Cn.c("flex flex-col bg-surface-default rounded shadow-base p-6 pb-8"),
    candidateName: Cn.c("text-black"),
    eventName: Cn.c("font-h4-medium pt-1 text-subdued"),
    requestTitle: Cn.c("font-h2-bold text-emphasized pt-1 pb-3"),
    warningContainer: Cn.c("flex justify-center pt-8"),
    warningIcon: Cn.c("text-icons-critical-default w-14 h-14"),
    warningTitle: Cn.c("font-h4-medium text-center text-critical-emphasized pt-4"),
    warningDescription: Cn.c("pt-2 text-critical-default text-center"),
    spacing: Cn.c("pt-8"),
}

interface Props {
    contactEmail?: string
    candidateName?: string
    companyName?: string
    eventDisplayName?: string
}

const ErrorMode: FunctionComponent<Props> = ({ contactEmail, candidateName, companyName, eventDisplayName }) => {
    const { t } = useTranslation()

    const onClick = () => {
        window.location.href = `mailto:${contactEmail}`
    }

    return (
        <>
            {companyName && <div className={styles.topBar}> {companyName} </div>}
            <div className={styles.wrapper}>
                <div className={styles.form}>
                    {candidateName && <span className={styles.candidateName}> {candidateName} </span>}
                    {eventDisplayName && <span className={styles.eventName}> {eventDisplayName} </span>}
                    <span className={styles.requestTitle}> {t('eventRegistration.title')}</span>
                    <span> {t('eventRegistration.instruction')} </span>
                    <div className={styles.warningContainer}><Icon name="errorWarning" className={styles.warningIcon} /></div>
                    <span className={styles.warningTitle}>{t('eventRegistration.invalidLinkTitle')}</span>
                    <span className={styles.warningDescription}>
                        {t('eventRegistration.invalidLinkDescription')}
                    </span>
                    <div className={styles.spacing}>
                        {
                            contactEmail
                            && <Button size='lg' variant='primaryFilled' isFull={true} onClick={onClick}>
                                {t('eventRegistration.contactRecruiter')}
                            </Button>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export {
    ErrorMode
};
