import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { InitialState } from "./init";
import { useEffect } from "react";

const useEventSessionDetailPage = (initialState: InitialState) => {
    const { error: toastError } = useToast();

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])
}

export {
    useEventSessionDetailPage
}