import { JobListPanel } from "@shared/job_position/JobListPanel/JobListPanel";
import { JobListPanelContext } from "@shared/job_position/JobListPanel/JobListPanelContext";
import { FunctionComponent } from "react";
import { useJobPositionDetailPage } from "./hook";
import { useInitialState } from "./init";
import { JobPositionDetailErrorState } from "./JobPositionDetailErrorState/JobPositionDetailErrorState";
import { JobPositionDetailLoadingState } from "./JobPositionDetailLoadingState/JobPositionDetailLoadingState";
import { JobPositionDetailSuccessState } from "./JobPositionDetailSuccessState/JobPositionDetailSuccessState";
import { useJobListPanelDisplay } from "@shared/job_position/JobListPanel/useJobListPanelDisplay";
import { JobPositionDetail } from "@routes/jobPosition";

const JobPositionDetailPage: FunctionComponent = () => {
    const {
        initialState
    } = useInitialState()

    const { hideJobListPanel, showJobListPanel, displayMode: jobListPanelDisplayMode } = useJobListPanelDisplay()

    const { id: currentJobId } = JobPositionDetail.useSearchParams();

    useJobPositionDetailPage(initialState);

    return (
        <JobListPanelContext.Provider value={{ displayMode: jobListPanelDisplayMode, showJobListPanel, hideJobListPanel }}>
            <JobListPanel
                isOpen={jobListPanelDisplayMode === 'show'}
                currentJobId={currentJobId}
            />
            {
                initialState.isLoading
                    ? <JobPositionDetailLoadingState />
                    : !initialState.result.isSuccess
                        ? <JobPositionDetailErrorState />
                        : <JobPositionDetailSuccessState jobPosition={initialState.result.data.jobPosition} />
            }
        </JobListPanelContext.Provider>
    )
}

export {
    JobPositionDetailPage
};
