import { AllRoless_AllRolesQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { CheckboxGroup } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Input } from '@shared/unsorted/Input/Input';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { Entity } from '@typedefs/graphql';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserInviteModal } from './hook';
import { UserInviteErrorModal } from './UserInviteErrorModal/UserInviteErrorModal';
import { validations } from '@helpers/unsorted/validation';

const styles = {
    modal: Cn.c('w-[25rem]'),
    header: Cn.c('font-h4-bold text-emphasized px-6 pt-6 pb-5 border-b border-b-default flex justify-between'),
    titleContainer: Cn.c('flex flex-col space-y-4'),
    iconContainer: Cn.c('border border-default rounded w-fit p-3'),
    icon: Cn.c('text-icons-emphasized w-6 h-6'),
    body: Cn.c('p-6 space-y-6 border-b border-b-default'),
    description: Cn.c('font-paragraph-small-regular text-subdued'),
    buttons: Cn.c('flex flex-row gap-x-3 justify-center p-6'),
    sendInviteBtn: Cn.c('whitespace-nowrap'),
    tooltip: Cn.c('w-fit'),
    tooltipIcon: Cn.c('w-4 h-4 text-icons-subdued'),
};

interface Props extends ModalProps {
    roles: Entity<AllRoless_AllRolesQuery, 'roles'>[];
}

const UserInviteModal: FunctionComponent<Props> = ({ roles, isOpen = false }) => {
    const { t } = useTranslation();

    const {
        close,
        form: { clearErrors, control, formState: { errors, isDirty } },
        onSubmit,
        isLoading,
        roleOptions,
        showAddUserError,
        hideAddUserError,
    } = useUserInviteModal(roles);

    const onCloseModal = () => !showAddUserError && close();

    return (
        <Modal isOpen={isOpen} close={onCloseModal} widthClass={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}>
                        <Icon name="userAdd" className={styles.icon} />
                    </div>
                    <span>{t("settings.userManagement.create.title")}</span>
                </div>
                <Icon name="close" className={styles.icon} onClick={onCloseModal} />
            </div>
            <form onSubmit={onSubmit}>
                <div className={styles.body}>
                    <p className={styles.description}>{t("settings.userManagement.create.description")}</p>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: true, pattern: validations.email }}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Input
                                size="medium"
                                label="settings.userManagement.create.form.email.title"
                                placeholder="settings.userManagement.create.form.email.placeholder"
                                required
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={errors}
                                clearErrors={() => clearErrors("email")}
                                forwardedRef={ref}
                                value={value}
                            />
                        )}
                    />
                    <div>
                        <Controller
                            name="roles"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <CheckboxGroup
                                    name="roles"
                                    label="settings.userManagement.create.form.role"
                                    required
                                    onChange={onChange}
                                    value={value}
                                    options={roleOptions}
                                    errors={errors}
                                    tooltipClassName={styles.tooltip}
                                    tooltipIconClassName={styles.tooltipIcon}
                                    size="lg"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        size="md"
                        variant="secondary"
                        isDisabled={isLoading}
                        onClick={onCloseModal}
                        isFull
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        size="md"
                        type="submit"
                        isLoading={isLoading}
                        isDisabled={!isDirty}
                        isFull
                        className={styles.sendInviteBtn}
                    >
                        {t("settings.userManagement.create.sendInvite")}
                    </Button>
                </div>
            </form>

            <UserInviteErrorModal isOpen={showAddUserError} onClose={hideAddUserError} />
        </Modal>
    );
};

export {
    UserInviteModal
};
