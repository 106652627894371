import { JobPosition_OneByIdWithStepEventsQuery, UpdateJobPositionDocument } from "@entities";
import { showFullName } from '@helpers/core/client';
import { useClient } from "@helpers/hooks/unsorted/clientHook";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { Entity } from "@typedefs/graphql";
import { useTranslation } from "react-i18next";

import * as value from './value';
import { SubmitHandler, UseFormReturn, useForm } from "react-hook-form";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { useMutation } from "urql";
import { handleResponse, hasErrorCode } from "@helpers/unsorted/urqlExtra";
import { useBackToPreviousListPage } from "@helpers/hooks/useBackToPreviousListPage";

interface JobPositionFormHookType {
    getCreator: () => string;
    form: UseFormReturn<value.Encoder.Type>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    onCancel: VoidFunction;
    onClose: VoidFunction;
    closeConfirmationModal: VoidFunction;
    discardAndClose: VoidFunction;
    saveAndExit: VoidFunction;
    showWarningModal: boolean;
}


const useJobPositionForm = (jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>, switchToViewMode: VoidFunction): JobPositionFormHookType => {
    const { currentClient } = useClient();

    const { backToPreviousListPage } = useBackToPreviousListPage()

    const { t } = useTranslation();

    const { error: toastError, success: toastSuccess } = useToast();

    const [updateJobPositionResponse, updateJobPosition] = useMutation(UpdateJobPositionDocument);

    const [showWarningModal, setShowWarningModal] = useState(false);

    const formAction = useRef<'switchToViewMode' | 'closeModal'>();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const defaultValues = value.Encoder.defaultValues(jobPosition);

    const form = useForm<value.Encoder.Type>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: defaultValues,
    });

    const { formState: { isDirty }, reset } = form

    const getCreator = () => {
        if (currentClient.clientState === "loggedIn" && jobPosition.createdBy === currentClient.clientInfo.id) {
            return t('global.you')
        }

        const { creator } = jobPosition;

        return getWithDefault(creator?.fullName && showFullName(t, creator));
    }

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            updateJobPosition({
                id: jobPosition.id,
                title: validValues.title,
                recruitmentSteps: validValues.recruitmentSteps,
                documentRequirements: validValues.documentRequirements,
                isDraft: true, // TODO: This should depend on which save option the user chooses. For now, always save as draft.
            });

        } catch (error) {
            toastError('global.error')
        }
    }

    const onCancel = () => {
        formAction.current = 'switchToViewMode'
        if (isDirty) {
            setShowWarningModal(true)
        } else {
            switchToViewMode()
        }
    }

    const onClose = () => {
        formAction.current = 'closeModal'
        if (isDirty) {
            setShowWarningModal(true)
        } else {

        }
    }

    const submit = form.handleSubmit(onSubmit)

    const closeConfirmationModal = () => setShowWarningModal(false)

    const discardAndClose = () => {
        reset(defaultValues)
        if (formAction.current === 'switchToViewMode') {
            switchToViewMode()
        }
        if (formAction.current === 'closeModal') {
            backToPreviousListPage()
        }
    }

    const saveAndExit = () => {
        closeConfirmationModal()
        submit()
    }

    useEffect(() => {
        handleResponse(updateJobPositionResponse, {
            onFetching: () => {
                setIsSubmitting(true);
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('jobPositions.modify.jobPositionUpdated');
                switchToViewMode();
            },
            onError: (error) => {
                setIsSubmitting(false)

                // TODO: Validate this error message is actually thrown by the API
                // https://github.com/scoville/ats-api/blob/e1be373c0dba92083c74696b03feca05cb155478/src/services/JobPositionService.ts#L199-L293
                if (hasErrorCode(error, "RECORD_ALREADY_EXISTS")) {
                    return toastError('jobPositions.modify.stepNameExists')
                }

                toastError('global.error');
            }
        })
    }, [updateJobPositionResponse]);

    return {
        getCreator,
        form,
        onSubmit: submit,
        isSubmitting,
        onCancel,
        onClose,
        closeConfirmationModal,
        discardAndClose,
        saveAndExit,
        showWarningModal,
    }
}

export {
    useJobPositionForm
}
