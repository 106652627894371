import { z } from "zod";
import { validations } from "./validation";

const katakana = /^[\u30a0-\u30ff\s]*$/;

const nonNumberRegexp = /^[^\d]+$/;

const katakanaSchema = z.string().regex(katakana)

const nonNumberSchema = z.string().regex(nonNumberRegexp)

const idSchema = z.string().uuid();

const datetimeSchema = z.preprocess((arg) => {
    if (typeof arg == "string" || arg instanceof Date) return new Date(arg)
}, z.date());

const validBirthDate = (val: Date): boolean => {
    const year = val.getFullYear();

    return year >= 1900 && year <= 2100;
};

const birthdateSchema = datetimeSchema.refine(validBirthDate, {
    message: "Birth date is out of bound",
});

const genderSchema = z.enum([
    "FEMALE",
    "MALE",
    "NOT_APPLICABLE",
    "UNSPECIFIED",
    ""
]);

const yearSchema = z.string().transform((value, ctx) => {
    const year = parseInt(validations.convertDoubleWidthToRoman(value));

    if (year < 1900 || year > 2100) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid year",
        });

        return z.NEVER;
    }

    return year;
});

const monthSchema = z.string().transform((value, ctx) => {
    const month = parseInt(value);

    if (month < 0 || month > 11) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid month",
        });

        return z.NEVER;
    }

    return month;
});

const graduationYearSchema = z.string().transform((value, ctx) => {
    const year = parseInt(value);

    if (year < 1950 || year > 2030) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid year",
        });

        return z.NEVER;
    }

    return year;
});

export {
    genderSchema,
    datetimeSchema,
    birthdateSchema,
    idSchema,
    nonNumberSchema,
    katakanaSchema,
    yearSchema,
    monthSchema,
    graduationYearSchema,
}