import { Event_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { JobPositionDetail } from '@routes/jobPosition';
import { CloseIcon } from '@shared/functional_components/CloseIcon';
import { OpenJobListPanelButton } from '@shared/job_position/JobListPanel/OpenJobListPanelButton';
import { Breadcrumb } from '@shared/unsorted/Breadcrumb/Breadcrumb';
import { Button } from '@shared/unsorted/Button/Button';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { EventDeleteConfirmationModal } from './EventDeleteConfirmationModal/EventDeleteConfirmationModal';
import { EventSessionAddModal } from './EventSessionAddModal/EventSessionAddModal';
import { EventDetails } from './EventDetails/EventDetails';
import { EventSettingsActions } from './EventSettingsActions/EventSettingsActions';
import { useEventDetailViewMode } from './hook';

const styles = {
    container: Cn.c('flex flex-col bg-surface-default h-full'),
    header: Cn.c('px-6 py-4 flex items-center justify-between'),
    jobListContainer: Cn.c('flex content-center items-center space-x-4'),
    buttons: Cn.c('flex items-center justify-between space-x-4'),
    closeIcon: Cn.c('w-6 h-6 text-icons-emphasized cursor-pointer'),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
    loadingContainer: Cn.c('flex items-center justify-center flex-1'),
};

interface Props {
    event: Entity<Event_OneByIdQuery, 'event'>;
}

const EventDetailViewMode: FunctionComponent<Props> = ({
    event
}) => {
    const { t } = useTranslation();

    const {
        isAddEventSessionModalOpen,
        openAddEventSessionModal,
        closeAddEventSessionModal,
        isDeleteConfirmationModalOpen,
        closeDeleteConfirmationModal,
        openDeleteConfirmationModal,
        onProceedDelete,
    } = useEventDetailViewMode(event);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header} data-section="modalHeader">
                    <div className={styles.jobListContainer}>
                        <OpenJobListPanelButton />
                        <Breadcrumb
                            items={[
                                {
                                    type: "link",
                                    label: event.recruitmentStepV2.jobPosition.title,
                                    link: generatePath(JobPositionDetail.PATH_NAME, { id: event.recruitmentStepV2.jobPosition.id }),
                                },
                                {
                                    type: "link",
                                    label: event.name,
                                    link: "", // TODO: Currently no link is provided to avoid refresh. Confirm if it's needed
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <EventSettingsActions event={event} openDeleteConfirmationModal={openDeleteConfirmationModal} />
                        <Button size="md" variant="primaryFilled" onClick={openAddEventSessionModal}>{t("event.eventDetail.addSession")}</Button>
                        <CloseIcon />
                    </div>
                </div>
                <div className={styles.body} data-section="modalBody">
                    <EventDetails event={event} openAddEventSessionModal={openAddEventSessionModal} />
                </div>
            </div>
            <EventSessionAddModal
                event={event}
                closeModal={closeAddEventSessionModal}
                isOpen={isAddEventSessionModalOpen}
            />
            <EventDeleteConfirmationModal
                closeModal={closeDeleteConfirmationModal}
                isOpen={isDeleteConfirmationModalOpen}
                onProceed={() => onProceedDelete(event.id)}
                eventName={event.name}
            />
        </>

    );
};

export {
    EventDetailViewMode
};

