const randomNumber = (min: number, max: number) => {
    if (min > max) {
        throw new Error('min must be less than or equal to max');
    }

    return Math.random() * (max - min) + min;
};

const randomInteger = (min: number, max: number) => {
    const minInt = Math.ceil(min);
    const maxInt = Math.floor(max);

    return Math.floor(randomNumber(minInt, maxInt + 1));
};

/**
 * Parse a size tuple into bytes
 * @param size - The size tuple to parse.
 * @returns The size in bytes.
 */
const SUPPORTED_UNITS = ['KB', 'MB', 'GB'] as const;
type SupportedUnits = typeof SUPPORTED_UNITS[number];
type ValidSize = [ number, SupportedUnits ];
const parseSize = (size: ValidSize): number => {
    const [ number, unit ] = size;

    const multiplierMap: Record<SupportedUnits, number> = {
        'KB': Math.pow(1000, 1),
        'MB': Math.pow(1000, 2),
        'GB': Math.pow(1000, 3),
    };

    return number * multiplierMap[unit];
};

const toSizeString = (size: ValidSize): string => `${size[0]} ${size[1]}`;

export type {
    ValidSize,
};

export {
    randomNumber,
    randomInteger,
    parseSize,
    toSizeString,
};
