import { useCallback, useRef, useState } from "react";

import { Tabs, Props as TabsProps } from "./Tabs";

type PrefilledProps = "defaultTab" | "onTabChange" | "setActiveTabRef";
type PassedProps = Omit<TabsProps, PrefilledProps>;

const useTabs = (defaultTab?: string) => {
    const [activeTab, setActiveTab] = useState<string | undefined>(defaultTab);

    const setActiveTabRef = useRef<(newActiveTab?: string) => void>();
    const handleTabChange = (newActiveTab?: string) => {
        if (setActiveTabRef.current) {
            setActiveTabRef.current(newActiveTab);
        }
    };

    const PrefilledTabs = useCallback(
        ({
            children,
            className,
            tabListClassName,
            tabChildClassName,
        }: PassedProps) => (
            <Tabs
                defaultTab={defaultTab}
                onTabChange={setActiveTab}
                setActiveTabRef={setActiveTabRef}
                className={className}
                tabListClassName={tabListClassName}
                tabChildClassName={tabChildClassName}
            >
                {children}
            </Tabs>
        ),
        [defaultTab, setActiveTab, setActiveTabRef]
    );

    return {
        activeTab,
        setActiveTab: handleTabChange,
        ControlledTabs: PrefilledTabs,
    };
};

export { useTabs };
