import { AllLocationType, offlineLocationType, onlineLocationType } from '@shared/event/event_session/EventSessionGroup/value';
import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        locationType: AllLocationType;
        location: string;
    }

    export const defaultValues = (location: string, locationType: AllLocationType = ''): EncoderType => ({
        location,
        locationType: locationType || 'MANUAL'
    })
}

namespace Decoder {
    export const schema = z.object({
        location: z.string(),
        locationType: z.enum([...onlineLocationType, ...offlineLocationType]).transform(values => values === '' ? undefined : values)
    })
}

export {
    Decoder, Encoder
};
