import { Cn } from "@helpers/unsorted/classNames";
import Tippy, { TippyProps } from "@tippyjs/react";
import { motion, useSpring } from "framer-motion";
import { FunctionComponent, ReactElement } from "react";

const styles = {
    tooltip: Cn.c("py-2 px-3 w-80 font-paragraph-xsmall-medium text-on-primary rounded-lg bg-surface-dark-emphasized shadow-lg"),
    arrow: Cn.join([
        Cn.c('invisible absolute w-3 h-3 bg-surface-dark-emphasized'),
        Cn.c('before:visible before:absolute before:w-3 before:h-3 before:rotate-45 before:bg-surface-dark-emphasized'),
    ]),
}

type TooltipPlacement = TippyProps['placement'];

interface Props extends TippyProps {
    toolTipClassName?: string;
    arrowClassName?: string;
    tooltipContent: string | ReactElement<unknown> | ReactElement<unknown>[];
}

const ToolTipBox: FunctionComponent<Props> = ({
    toolTipClassName,
    arrowClassName,
    tooltipContent,
    children,
    ...tippyProps
}) => {
    const springConfig = { damping: 15, stiffness: 300 };
    const initialScale = 0.5;
    const opacity = useSpring(0, springConfig);
    const scale = useSpring(initialScale, springConfig);

    const onMount = () => {
        scale.set(1);
        opacity.set(1);
    };

    const onHide: TippyProps['onHide'] = ({ unmount }) => {
        const cleanup = scale.onChange((value: number) => {
            if (value <= initialScale) {
                cleanup();
                unmount();
            }
        });

        scale.set(initialScale);
        opacity.set(0);
    };

    return (
        <Tippy
            animation={true}
            onMount={onMount}
            onHide={onHide}
            {...tippyProps}
            render={(attrs) => (
                <motion.div
                    tabIndex={-1}
                    id="tooltip"
                    className={Cn.join([styles.tooltip, Cn.getSome(toolTipClassName)])}
                    style={{ scale, opacity }}
                    {...attrs}
                >
                    {tooltipContent}
                    <div id="arrow" data-popper-arrow="" className={Cn.join([styles.arrow, Cn.getSome(arrowClassName)])} />
                </motion.div>
            )}
        >
            {children}
        </Tippy>
    );
};

export {
    ToolTipBox,
    Props as ToolTipBoxProps,
    TooltipPlacement
};

