import { FunctionComponent } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps, HTMLPassedProps } from '@typedefs/props';

const styles = {
    root: Cn.c('cursor-pointer'),
};

interface Props extends ChildrenProps, HTMLPassedProps<'className'> {
    href: string;
    sameTab?: boolean;
}

const ExternalLink: FunctionComponent<Props> = ({ sameTab = false, children, ...passedProps }) => {
    return (
        <a
            className={Cn.join([ styles.root, Cn.getSome(passedProps.className) ])}
            href={passedProps.href}
            target={sameTab ? undefined : '_blank'}
            rel={sameTab ? undefined : 'noopener noreferrer'}
        >
            {children}
        </a>
    );
};

export {
    ExternalLink,
};
