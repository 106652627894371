import { useJobPositionsPage } from "./hook";
import { useInitialState } from "./init";
import { JobPositionsLoadingState } from "./JobPositionsLoadingState/JobPositionsLoadingState";
import { JobPositionsSuccessState } from "./JobPositionsSuccessState/JobPositionsSuccessState";

const JobPositionsPage = () => {
  const {
    initialState
  } = useInitialState()

  useJobPositionsPage(initialState);

  return initialState.isLoading
    ? <JobPositionsLoadingState />
    : !initialState.result.isSuccess
      ? null //TODO: Add error component
      : <JobPositionsSuccessState {...initialState.result.data} />
};

export {
  JobPositionsPage
};
