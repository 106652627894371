import { createContext } from 'react';

type DisplayMode = 'show' | 'hide';

interface JobListPanelContextType {
    displayMode: DisplayMode;
    showJobListPanel: VoidFunction;
    hideJobListPanel: VoidFunction;
}

const JobListPanelContext = createContext<JobListPanelContextType | undefined>(undefined);

export {
    JobListPanelContext,
    JobListPanelContextType,
    DisplayMode,
};