const isStepEditable = (step: {
    name: string,
    type: string,
}) => {
    const stepName = step.name.toLowerCase().split(" ").join("")

    return step.type !== 'SYSTEM' || stepName !== "recruitmentresult"
}

export {
    isStepEditable
}