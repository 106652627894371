import { z } from 'zod';
import { ValueItem as MultiValueTypeAheadItem } from '@shared/unsorted/MultiValueTypeAhead/value';

namespace Encoder {
    export interface EncoderType {
        sendReservationEmail: boolean;
        recruitmentProcessIds: MultiValueTypeAheadItem.ValueItemType[];
    }

    export const defaultValues = (): EncoderType => ({
        sendReservationEmail: false,
        recruitmentProcessIds: [],
    });
}

namespace Decoder {
    export const schema = z.object({
        sendReservationEmail: z.boolean().nullish(),
        recruitmentProcessIds: z.array(MultiValueTypeAheadItem.schema).min(1)
    }).transform((values, ctx) => {
        const recruitmentProcessIds = values.recruitmentProcessIds.map(({ value }) => value || "")

        if (recruitmentProcessIds.length === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'No application ids found',
            });

            return z.NEVER;
        }

        return {
            ...values,
            recruitmentProcessIds,
        };
    });
}

export {
    Decoder,
    Encoder
};

