import { useTranslation } from 'react-i18next';
import { Button } from '@shared/unsorted/Button/Button';
import { DefaultLayout } from '@shared/unsorted/DefaultLayout/DefaultLayout';
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { useNavigate } from 'react-router-dom';
import { Home } from '@routes/home';

const styles = {
    root: 'absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-default',
};

// @ocaml.doc("The not found page, displayed as 404 when the url matches no routes")
const NotFoundPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DefaultLayout>
            <div className={styles.root}>
                <EmptyStateContainer
                    icon="notFound"
                    description={"notFound.description"}
                    subDescription={"notFound.subDescription"}
                >
                    <Button size="md" onClick={() => navigate(Home.PATH_NAME)}>
                        {t("notFound.backToTopPage")}
                    </Button>
                </EmptyStateContainer>
            </div>
        </DefaultLayout>
    );
};

export {
    NotFoundPage,
};
