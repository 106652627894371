import { Cn } from "@helpers/unsorted/classNames"
import { UploadDragAndDrop } from "@pages/application/CSVImportPage/CSVImportForm/UploadDragAndDrop/UploadDragAndDrop"
import { FunctionComponent } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useCSVBulkEvaluationSetupStep } from "./hook"
import { Button } from "@shared/unsorted/Button/Button"
import { isUndefined } from "@helpers/core/typeGuards"

const styles = {
    footer: Cn.c("border-t border-default p-6 flex justify-end"),
    container: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    section: Cn.c('flex flex-col py-6'),
    uploadFileRequirement: Cn.c('font-paragraph-small-regular text-subdued mt-2'),
    downloadCSVText: Cn.c("text-primary-default font-paragraph-small-regular cursor-pointer underline"),
    header: Cn.c('font-paragraph-base-semi-bold text-emphasized'),
}

const CSVBulkEvaluationSetupStep: FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        csvFile,
        onUpload,
        removeFile,
        isSubmitting,
        submit,
        downloadTemplate
    } = useCSVBulkEvaluationSetupStep()

    return (
        <>
            <div className={styles.container}>
                <div className={styles.section}>
                    <p className={styles.header}>{t("applications.bulkEvaluation.setupStep.sectionHeader.uploadFile")}</p>
                    <p className={styles.uploadFileRequirement}>
                        <Trans
                            i18nKey={"applications.bulkEvaluation.setupStep.uploadFileRequirement"}
                            components={{
                                highlight: <span
                                    className={styles.downloadCSVText}
                                    onClick={downloadTemplate}
                                >
                                </span>
                            }}
                        />
                    </p>
                    <UploadDragAndDrop
                        onUpload={onUpload}
                        onRemove={removeFile}
                        csvFile={csvFile}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    variant="primaryFilled"
                    size="md"
                    iconName="arrowRightLine"
                    isTrailingIcon
                    isLoading={isSubmitting}
                    onClick={submit}
                    isDisabled={isUndefined(csvFile)}>
                    {t("applications.bulkEvaluation.moveToPreview")}
                </Button>
            </div>
        </>
    )
}

export {
    CSVBulkEvaluationSetupStep
}