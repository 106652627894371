import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

const badgeVariants = ['success', 'warn', 'destructive', 'neutral', 'inactive'] as const;

const size = ['sm', 'md', 'lg'] as const;

type Variant = typeof badgeVariants[number];

type Size = typeof size[number];

const styles = {
    base: (variant: Variant, size: Size) => {
        let variantStyle: string;

        switch (variant) {
            case 'success':
                variantStyle = Cn.c('text-success-emphasized bg-surface-success-default');
                break;
            case 'warn':
                variantStyle = Cn.c('text-warning-emphasized bg-surface-warning-default');
                break;
            case 'destructive':
                variantStyle = Cn.c('text-critical-emphasized bg-surface-critical-default');
                break;
            case 'neutral':
                variantStyle = Cn.c('text-info-emphasized bg-surface-info-default');
                break;
            case 'inactive':
                variantStyle = Cn.c('text-disabled bg-surface-disabled');
                break;
        }

        let sizeStyles: string;

        switch (size) {
            case 'sm':
                sizeStyles = Cn.c('font-paragraph-xsmall-regular');
                break;
            case 'md':
                sizeStyles = Cn.c('font-paragraph-small-regular');
                break;
            case 'lg':
                sizeStyles = Cn.c('text-paragraph-base-regular');
                break;
        }

        return Cn.join([
            Cn.c('py-1 px-2 rounded w-fit'),
            sizeStyles,
            variantStyle,
        ]);
    },
};

interface Props {
    /** Displayed text */
    label: I18nKey;
    /** Defines the look of the badge */
    variant?: Variant;
    /** Defines the size of the badge */
    size?: Size;
}

const Badge: FunctionComponent<Props> = ({ label, variant = 'success', size = 'md' }) => {
    const { t } = useTranslation();

    return (<span className={styles.base(variant, size)}>{t(label)}</span>);
};

export {
    Badge,
    badgeVariants,
    size
};

