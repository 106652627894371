import { Event_OneByIdDocument, Event_OneByIdQuery, EventSession_OneByIdDocument, EventSession_OneByIdQuery } from "@entities";
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { EventSessionDetail } from "@routes/eventSession";
import { AsyncResponseType } from "@typedefs/aliases";
import { Entity } from "@typedefs/graphql";
import { useEffect, useState } from "react";
import { useQuery } from "urql";

interface InitialStateData {
    eventSession: Entity<EventSession_OneByIdQuery, 'eventSession'>;
    event: Entity<Event_OneByIdQuery, 'event'>;
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const [eventId, setEventId] = useState<string | null>(null);

    const { id } = EventSessionDetail.useSearchParams();

    const eventSessionContext = useQueryContext(['EventSession', 'EventSessionGroup'])
    const [eventSessionResponse,] = useQuery({
        query: EventSession_OneByIdDocument,
        variables: {
            id,
        },
        context: eventSessionContext,
    });

    const eventQueryContext = useQueryContext(['Event', 'EventSession']);
    const [eventResponse,] = useQuery({
        query: Event_OneByIdDocument,
        variables: {
            id: eventId || '',
        },
        context: eventQueryContext,
        pause: !eventId
    });

    const asyncResponses = [
        eventSessionResponse,
        eventResponse
    ]

    useEffect(() => {
        handleResponse(
            eventSessionResponse,
            {
                onData: (data) => {
                    setEventId(data.eventSession.eventId)
                },
            }
        )
    }, [eventSessionResponse])

    useEffect(() => {
        const isFinishedFetching = asyncResponses.every(response => !response.fetching);

        if (isFinishedFetching) {
            const isAnyResponseError = asyncResponses.some(response => response.error);

            if (isAnyResponseError) {
                setInitialState({
                    isLoading: false,
                    result: {
                        isSuccess: false
                    }
                })
            } else {
                const eventSession = eventSessionResponse.data?.eventSession;
                const event = eventResponse.data?.event;

                if (eventSession && event) {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                eventSession,
                                event
                            }
                        }
                    })
                } else {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        }
    }, asyncResponses)

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}