import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useApplicationPanelHeader } from './hook';
import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/unsorted/Button/Button';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { Controller } from 'react-hook-form';
import { MultiValueTypeAhead } from '@shared/unsorted/MultiValueTypeAhead/MultiValueTypeAhead';

const styles = {
    headerContainer: Cn.c('bg-surface-default w-full py-4'),
    topHeaderContainer: Cn.c('flex flex-row justify-between mr-8 border-b border-b-default pb-2 mb-2'),
    infoContainer: Cn.c('space-y-1 flex-grow'),
    nameContainer: Cn.c('space-x-2 flex flex-row items-center'),
    fullName: Cn.c('font-h4-bold text-default'),
    katakanaName: Cn.c('text-subdued font-paragraph-small-medium'),
    row: Cn.c('flex flex-row mb-1'),
    section: Cn.c('flex flex-row items-center space-x-2 flex-1'),
    icon: Cn.c('w-4 h-4 text-icons-default'),
    infoText: Cn.c('font-paragraph-small-regular text-default'),
    addTagButton: Cn.c('flex flex-row space-x-2 items-center cursor-pointer'),
    addTagIcon: Cn.c('text-icons-primary-default w-5 h-5'),
    addTagText: Cn.c('text-primary-default font-paragraph-small-medium'),
    tagContainer: Cn.c("flex flex-row justify-between mr-8 items-center"),
    tagList: Cn.c("flex flex-row space-x-1"),
    tagForm: Cn.c("mr-8"),
    tagFormButtons: Cn.c("space-x-2 flex mt-4"),
    tag: Cn.c(
        "font-paragraph-xsmall-medium text-primary-default bg-surface-primary-default p-1 rounded",
    ),
    editIcon: Cn.c("text-icons-primary-default")
};

interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    openApplicationDetailPanel: VoidFunction;
}

const ApplicationPanelHeader: FunctionComponent<Props> = ({
    application,
    openApplicationDetailPanel,
}) => {
    const { t } = useTranslation();

    const { tagMode } = useApplicationPanelHeader(application);

    const { candidate } = application;

    return (
        <div className={styles.headerContainer}>
            <div className={styles.topHeaderContainer}>
                <div className={styles.infoContainer}>
                    <div className={styles.nameContainer}>
                        <div className={styles.fullName}>{candidate.name}</div>
                        {/* TODO: [CHECK] Temporary display only. Remove/replace getWithDefault if needed */}
                        <div className={styles.katakanaName}>({getWithDefault(candidate.nameKana)})</div>
                    </div>
                    <div>
                        <div className={styles.row}>
                            <div className={styles.section}>
                                <Icon name="school" className={styles.icon} />
                                <div className={styles.infoText}>
                                    {getWithDefault([
                                        candidate.school?.name || "",
                                        candidate.schoolDepartment?.name || "",
                                    ].join(" "))}
                                </div>
                            </div>
                            <div className={styles.section}>
                                <Icon name="email" className={styles.icon} />
                                <div className={styles.infoText}>{candidate.email}</div>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.section}>
                                <Icon name="calendarCheck" className={styles.icon} />
                                <div className={styles.infoText}>
                                    {candidate.graduationMonth && candidate.graduationYear
                                        ? t("applications.panel.graduationYearMonth",
                                            {
                                                month: candidate.graduationMonth.toString(),
                                                year: candidate.graduationYear.toString(),
                                            })
                                        : "—"}
                                </div>
                            </div>
                            <div className={styles.section}>
                                <Icon name="phone" className={styles.icon} />
                                {/* TODO: [CHECK] Temporary display only. Remove/replace getWithDefault if needed */}
                                <div className={styles.infoText}>{getWithDefault(candidate.mobilePhoneNumber)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button size="sm" variant="secondary" onClick={openApplicationDetailPanel}>
                    {t("applications.panel.moreInfo")}
                </Button>
            </div>
            <div>
                {
                    tagMode.name === 'list'
                        ? application.tags.length === 0
                            ?
                            <div className={styles.addTagButton} onClick={tagMode.payload.openTagsForm}>
                                <Icon name="plus" className={styles.addTagIcon} />
                                <p className={styles.addTagText}>{t("applications.panel.addTag")}</p>
                            </div>
                            :
                            <div className={styles.tagContainer}>
                                <div className={styles.tagList}>
                                    {application.tags.map(tag =>
                                        <div key={tag.id} className={styles.tag}> <p> {tag.value} </p> </div>
                                    )}
                                </div>
                                <Icon name='edit' className={styles.editIcon} onClick={tagMode.payload.openTagsForm} />
                            </div>
                        :
                        <form className={styles.tagForm} onSubmit={tagMode.payload.onSubmit}>
                            <Controller
                                name='tags'
                                control={tagMode.payload.form.control}
                                render={({ field: { name, ref } }) =>
                                    <MultiValueTypeAhead
                                        name={name}
                                        listOrientation='downwards'
                                        options={tagMode.payload.tagOptions}
                                        onInputChange={tagMode.payload.searchTags}
                                        inputValue={tagMode.payload.initialInputValue}
                                        errors={tagMode.payload.form.formState.errors}
                                        clearErrors={tagMode.payload.form.clearErrors}
                                        forwardedRef={ref}
                                        form={tagMode.payload.form}
                                        enableInsert
                                    />}
                            />
                            <div className={styles.tagFormButtons}>
                                <Button size='md' variant='secondary' onClick={tagMode.payload.closeTagsForm}>
                                    {t('global.cancel')}
                                </Button>
                                <Button size='md' type='submit' variant='primaryFilled' isLoading={tagMode.payload.isSubmitting}>
                                    {t("applications.panel.saveTag")}
                                </Button>

                            </div>
                        </form>
                }

            </div>
        </div>
    );
};

export {
    ApplicationPanelHeader,
};