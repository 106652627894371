import { isDefined, isNonNull } from '@helpers/core/typeGuards';
import { useEffect, useRef, useState } from 'react';

interface Options {
    /** Controls the rate of progress using a time constant (default: 1000). */
    timeConstant?: number;
    /** Interval in milliseconds for publishing the progress (default: 250). */
    intervalTime?: number;
    /** Minimum duration in milliseconds for the progress to run. */
    minDuration?: number;
}

const useFakeProgress = (options?: Options) => {
    const timeConstant = options?.timeConstant || 1000;
    const intervalTime = options?.intervalTime || 250;
    const minDuration = options?.minDuration || undefined;

    const [progress, setProgress] = useState(0);
    const interval = useRef<NodeJS.Timer | null>(null);

    const minDurationTimeout = useRef<NodeJS.Timeout | null>(null);
    const [minDurationReached, setMinDurationReached] = useState(false);

    const cleanup = () => {
        stopInterval();
        stopMinDurationTimeout();
    };

    const start = () => {
        cleanup();
        setProgress(0); // Ensure that every time start is called, progress is reset to 0
        setMinDurationReached(false);

        let time = 0;

        if (isDefined(minDuration)) {
            minDurationTimeout.current = setTimeout(() => {
                setMinDurationReached(true);
            }, minDuration);
        }

        interval.current = setInterval(() => {
            time += intervalTime;
            setProgress(1 - Math.exp((-1 * time) / timeConstant));
        }, intervalTime);
    };

    const stopInterval = () => {
        if (isNonNull(interval.current)) {
            clearInterval(interval.current);
            interval.current = null;
        }
    };

    const stopMinDurationTimeout = () => {
        if (isNonNull(minDurationTimeout.current)) {
            clearTimeout(minDurationTimeout.current);
            minDurationTimeout.current = null;
        }
    };

    const stop = () => {
        cleanup();

        // Total time (1000) to reach 100% progress when end is called. This can be adjusted as needed.
        const steps = 1000 / intervalTime;
        const increment = (1 - progress) / steps;

        interval.current = setInterval(() => {
            setProgress((previousProgress) => {
                const newProgress = previousProgress + increment;

                if (newProgress >= 1) {
                    cleanup();
                    return 1; // Ensure progress is set to 1
                }

                return newProgress;
            });
        }, intervalTime);
    };

    useEffect(() => {
        return () => cleanup();
    }, []);

    return {
        start,
        stop,
        progress,
        minDurationReached,
    };
};

export {
    useFakeProgress,
};
