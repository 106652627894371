import { useState } from 'react';

type DisplayMode = 'show' | 'hide';

interface JobListPanelDisplayHookType {
    displayMode: DisplayMode;
    showJobListPanel: VoidFunction;
    hideJobListPanel: VoidFunction;
}

const useJobListPanelDisplay = (): JobListPanelDisplayHookType => {
    const [displayMode, setDisplayMode] = useState<DisplayMode>('hide');

    const showJobListPanel = () => setDisplayMode('show');

    const hideJobListPanel = () => setDisplayMode('hide');

    return {
        displayMode,
        showJobListPanel,
        hideJobListPanel,
    };
};

export {
    useJobListPanelDisplay
};
