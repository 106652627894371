import { Cn } from "@helpers/unsorted/classNames";
import { EventSessionUniqueLocationWarningModal } from "@shared/event/EventSessionUniqueLocationWarningModal/EventSessionUniqueLocationWarningModal";
import { EventDetails } from "@shared/event/event_session/EventDetails";
import { EventSessionGroupSubForm } from "@shared/event/event_session/EventSessionGroup/EventSessionGroupSubForm";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEventSessionGroupAddModal } from "./hook";
import { useEventSessionDetailContext } from "@pages/EventSessionDetailPage/EventSessionDetailContext";

const styles = {
    loadingContainer: Cn.c("h-[21rem] rounded-xl"),
    container: Cn.c("max-h-[95%] rounded-xl"),
    form: Cn.c("h-full flex flex-col"),
    header: Cn.c("p-6 flex justify-between items-center border-b border-default"),
    title: Cn.c("text-emphasized font-h4-bold"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("p-6 flex flex-col space-y-6 flex-1"),
    loadingBody: Cn.c("flex items-center justify-center flex-1"),
    groupContainer: Cn.c("mt-6"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
}

interface Props extends ModalProps {
    closeModal: VoidFunction;
}

const EventSessionGroupAddModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
}) => {
    const { t } = useTranslation()
    const { event } = useEventSessionDetailContext()

    const {
        isLoading,
        isSubmitting,
        clientOptions,
        mode,
        close,
        form,
        onCloseWarningModal,
        onSubmit,
        onFinalSubmit,
    } = useEventSessionGroupAddModal(closeModal)

    const { formState: { isDirty } } = form

    return isLoading
        ? <Modal
            isOpen={isOpen}
            close={close}
            widthClass="w-[50rem]"
            className={styles.loadingContainer}
        >
            <div className={styles.form}>
                <div className={styles.header}>
                    <p className={styles.title}>{t('event.addSessionGroup.title')}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={close} />
                </div>
                <div className={styles.loadingBody}>
                    <Loader
                        variant="primaryDefault"
                        size="lg"
                    />
                </div>
            </div>
        </Modal>
        : mode.name === 'init'
            ? <Modal
                isOpen={isOpen}
                close={close}
                widthClass="w-[50rem]"
                className={styles.container}
            >
                <form className={styles.form} onSubmit={onSubmit}>
                    <div className={styles.header}>
                        <p className={styles.title}>{t('event.addSessionGroup.title')}</p>
                        <Icon name="close" className={styles.closeIcon} onClick={close} />
                    </div>
                    <div className={styles.body}>
                        <EventDetails event={event} />
                        <div className={styles.groupContainer}>
                            <FormProvider {...form}>
                                <EventSessionGroupSubForm
                                    isOnline={event.isOnline}
                                    clientOptions={clientOptions}
                                    path="groups.0"
                                />
                            </FormProvider>

                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button
                            variant="secondary"
                            onClick={close}
                            size="md"
                            isFull={true}
                            isDisabled={isSubmitting}
                        >
                            {t("global.cancel")}
                        </Button>
                        <Button
                            variant="primaryFilled"
                            size="md"
                            isFull={true}
                            type="submit"
                            isDisabled={isSubmitting || !isDirty}
                            isLoading={isSubmitting}
                        >
                            {t("global.save")}
                        </Button>
                    </div>
                </form>
            </Modal>
            : <EventSessionUniqueLocationWarningModal
                onClose={onCloseWarningModal}
                isOpen={isOpen}
                revertToPreviousMode={mode.payload.revertToPreviousMode}
                onSubmit={onFinalSubmit}
                error={mode.payload.error}
            />;
}

export {
    EventSessionGroupAddModal
};
