import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook"
import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext"
import { SimpleApplication } from "@typedefs/aliases"
import { useEffect, useRef, useState } from "react"

const useDeselectButton = (currentPageApplications: SimpleApplication[]) => {
    const {
        selectedApplicationIds,
        setApplicationSelection,
        removeApplications
    } = useApplicationSelectionContext()

    const [isDeselectMenuShown, setIsDeselectMenuShown] = useState(false)

    const isApplicationSelected = (applicationId: string) => {
        return selectedApplicationIds.includes(applicationId)
    }

    const deselectMenuRef = useRef<HTMLDivElement>(null)

    useClickOutside([deselectMenuRef], () => setIsDeselectMenuShown(false));

    const numberOfSelectedApplications = selectedApplicationIds.length

    const numberOfSelectedApplicationsOnThisPage = currentPageApplications.reduce((acc, application) => {
        return acc + (isApplicationSelected(application.id) ? 1 : 0)
    }, 0)

    const onClickDeselectButton = () => {
        if (numberOfSelectedApplications > numberOfSelectedApplicationsOnThisPage) {
            setIsDeselectMenuShown(true)

            return
        }

        deselectAllApplicationsOnThisPage()
    }

    const deselectAll = () => setApplicationSelection({})

    const deselectAllApplicationsOnThisPage = () => {
        removeApplications(currentPageApplications)
    }

    useEffect(() => {
        setIsDeselectMenuShown(false)
    }, [currentPageApplications])

    return {
        numberOfSelectedApplications,
        numberOfSelectedApplicationsOnThisPage,
        deselectAll,
        deselectAllApplicationsOnThisPage,
        onClickDeselectButton,
        isDeselectMenuShown,
        deselectMenuRef,
    }
}

export {
    useDeselectButton
}