import { InfoToaster } from '@shared/unsorted/Toaster/InfoToaster';
import toast from 'react-hot-toast';
import { I18nKey, useTranslation } from 'react-i18next';

interface I18nValues {
    [key: string]: string | number; // Can add more types if needed
}

const useToast = () => {
    const { t } = useTranslation();

    const error = (id: I18nKey, values?: I18nValues) => {
        toast.remove();
        toast.error(t(id, values));
    }

    const success = (id: I18nKey, values?: I18nValues) => {
        toast.remove();
        toast.success(t(id, values));
    }

    const info = (id: I18nKey, values?: I18nValues) => {
        toast.remove();
        toast.custom(<InfoToaster message={t(id, values)} />)
    }

    return {
        error,
        success,
        info,
    };
};

export {
    useToast
};

