import { ValueItem as MultiValueTypeAheadValue} from '@shared/unsorted/MultiValueTypeAhead/value';
import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        tags: MultiValueTypeAheadValue.ValueItemType[];
    }

    export const defaultValues = (tags: MultiValueTypeAheadValue.ValueItemType[]) => ({
        tags
    })
}

namespace Decoder {
    export const schema = z.object({
        tags: z.array(MultiValueTypeAheadValue.schema)
    })
}

export {
    Encoder,
    Decoder
}