import { FunctionComponent, MouseEventHandler } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { Icon } from '../Icon/Icon';

const styles = {
    headerContainer: Cn.c('flex flex-row shadow-base'),
    closeContainer: Cn.c('bg-surface-default cursor-pointer'),
    close: Cn.c('mt-6 mx-4 text-icons-default hover:text-icons-emphasized w-3 h-3'),
};

interface Props extends ChildrenProps {
    close: MouseEventHandler<HTMLDivElement>;
}

// @ocaml.doc("The header of panel component, contains the close area on the left.")
const Header: FunctionComponent<Props> = ({ children, close }) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.closeContainer} onClick={close}>
                <Icon className={styles.close} name="close" onClick={close} />
            </div>
            {children}
        </div>
    );
};

export {
    Header,
};
