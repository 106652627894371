import { Event_OneByRecruitmentStepIdQuery } from "@entities"
import { Cn } from "@helpers/unsorted/classNames"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const styles = {
    container: Cn.c("p-4 rounded-lg bg-default flex flex-col space-y-3"),
    row: Cn.c("flex space-x-3 items-center"),
    line: Cn.c("flex space-x-1 items-center"),
    icon: Cn.c("w-4 h-4 text-icons-disabled"),
    label: Cn.c("text-subdued font-paragraph-small-regular"),
    value: Cn.c("text-emphasized font-paragraph-small-medium")
}

interface Props {
    jobTitle: string
    stepName: string
    eventName: string
}

const EventDetail: FunctionComponent<Props> = ({
    jobTitle,
    stepName,
    eventName
}) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.line}>
                    <Icon name="briefcase" className={styles.icon} />
                    <span className={styles.label}>{t('applications.bulkEventSessionAssignment.sessionSelection.jobTitle')}</span>
                    <span className={styles.value}>{jobTitle}</span>
                </div>
                <div className={styles.line}>
                    <Icon name="flagFill" className={styles.icon} />
                    <span className={styles.label}>{t('applications.bulkEventSessionAssignment.sessionSelection.currentRecruitmentStep')}</span>
                    <span className={styles.value}>{stepName}</span>
                </div>
            </div>
            <div>
                <div className={styles.line}>
                    <Icon name="calendar" className={styles.icon} />
                    <span className={styles.label}>{t('applications.bulkEventSessionAssignment.sessionSelection.eventName')}</span>
                    <span className={styles.value}>{eventName}</span>
                </div>
            </div>
        </div>
    )
}

export {
    EventDetail
}