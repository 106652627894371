import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent, useRef, useState } from "react"
import { useTranslation } from "react-i18next";

const styles = {
    container: (isOpened: boolean) => Cn.join([
        Cn.c("flex relative rounded-lg"),
        Cn.ifTrue(isOpened, "bg-surface-hovered-emphasized")
    ]),
    button: Cn.c("flex items-center space-x-1 cursor-pointer"),
    arrow: (isOpened: boolean) => Cn.join([
        Cn.c('w-6 h-6 text-emphasized transform transition-all duration-200'),
        isOpened ? Cn.c('rotate-180') : Cn.c('rotate-0'),
    ]),
    menu: (isOpened: boolean) => Cn.join([
        Cn.c("absolute z-10 top-11 w-44 rounded-lg bg-surface-default shadow-md overflow-hidden flex flex-col space-y-4 py-4"),
        isOpened ? Cn.c("block") : Cn.c("hidden"),
    ]),
    item: Cn.c("px-4 font-paragraph-small-regular cursor-pointer hover:bg-surface-hovered-default text-emphasized"),
};

interface Props {
    openPassDecisionEmailTemplateUpdateModal: VoidFunction;
    openFailDecisionEmailTemplateUpdateModal: VoidFunction;
}

const ViewDecisionEmailsDropdown: FunctionComponent<Props> = ({
    openPassDecisionEmailTemplateUpdateModal,
    openFailDecisionEmailTemplateUpdateModal
}) => {
    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState(false);

    const toggle = () => {
        setIsOpened(isOpened => !isOpened);
    }

    const onAction = (action: VoidFunction) => {
        action();
        setIsOpened(false);
    };

    const extraMenuRef = useRef<HTMLDivElement>(null);

    useClickOutside([extraMenuRef], () => {
        setIsOpened(false);
    });

    return (
        <div className={styles.container(isOpened)} ref={extraMenuRef}>
            <Button
                iconName="mail"
                size="md"
                variant="ghost"
                onClick={toggle}>
                <div className={styles.button}>
                    <p>{t("jobPositions.detail.viewEmails")}</p>
                    <Icon className={styles.arrow(isOpened)} name="arrowDown" />
                </div>
            </Button>
            <ul className={styles.menu(isOpened)}>
                <li className={styles.item} onClick={() => onAction(openPassDecisionEmailTemplateUpdateModal)}>
                    {t('jobPositions.detail.viewPassDecisionEmail')}
                </li>
                <li className={styles.item} onClick={() => onAction(openFailDecisionEmailTemplateUpdateModal)}>
                    {t('jobPositions.detail.viewFailDecisionEmail')}
                </li>
            </ul>
        </div>
    );
}

export {
    ViewDecisionEmailsDropdown
}