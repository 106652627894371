import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from './hook';

const styles = {
  urlCopyContainer: Cn.c('w-[15.625rem] px-3 py-2 border border-default rounded-lg select-none flex justify-between'),
  sessionUrlText: Cn.c('text-subdued font-paragraph-xsmall-regular inline-block truncate'),
  tooltip: Cn.c('w-fit'),
  copyContainer: Cn.c('cursor-pointer ml-2'),
  copyIcon: Cn.c('w-3.5 h-3.5 text-icons-primary-default')
}

interface Props {
  text: string;
}

const CopyToClipboard: FunctionComponent<Props> = ({ text }) => {
  const { t } = useTranslation();
  const { mode, copyToClipboard } = useCopyToClipboard();

  return (
    <div className={styles.urlCopyContainer}>
      <span className={styles.sessionUrlText}> {text} </span>
      <ToolTipBox
        toolTipClassName={styles.tooltip}
        tooltipContent={mode === "copy"
          ? t("event.eventSession.table.copyToClipboard.copyTooltip")
          : t("event.eventSession.table.copyToClipboard.copiedTooltip")
        }
        placement="top">
        <div className={styles.copyContainer} onClick={() => copyToClipboard(text)}>
          <Icon name="copy" className={styles.copyIcon} />
        </div>
      </ToolTipBox>
    </div>
  );
}

export {
  CopyToClipboard
};
