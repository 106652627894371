import { UpdateEventSessionGroupLocationDocument, ValidateSessionLocationDocument } from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { hasValue, isDefined } from '@helpers/core/typeGuards';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';
import { Error as LocationValidationError, Mode, UpdateLocationSettings, useEventSessionUniqueLocationWarningModal } from '@shared/event/EventSessionUniqueLocationWarningModal/useEventSessionUniqueLocationWarningModal';
import { mergeTuple, uniqueLocationBetweenEvents, uniqueLocationBetweenGroup, uniqueLocationBetweenSessions } from '@shared/event/EventSessionUniqueLocationWarningModal/validations';
import { getEventSessionName } from '@shared/event/helpers';
import { FormEventHandler, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClient, useMutation } from 'urql';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';
import { SessionGroupManipulationMode } from '../hook';
import * as value from './value';

interface EventSessionGroupManipulateLocationModalHookType {
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    onFinalSubmit: VoidFunction;
    isSubmitting: boolean;
    onCloseWarningModal: VoidFunction;
    mode: Mode;
    onCloseModal: VoidFunction;
}

const useEventSessionGroupManipulateLocationModal = (manipulationMode: SessionGroupManipulationMode, closeModal: VoidFunction): EventSessionGroupManipulateLocationModalHookType => {
    const { error: toastError, success: toastSuccess } = useToast();

    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const urqlClient = useClient();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = manipulationMode.name === 'manipulateEventSessionGroupLocation' ? manipulationMode.payload.location : '';
    const locationType = manipulationMode.name === 'manipulateEventSessionGroupLocation' && manipulationMode.payload.locationType || '';

    const defaultValues = value.Encoder.defaultValues(location, locationType);

    const { mode, backToInit, showWarning } = useEventSessionUniqueLocationWarningModal();

    const { event } = useEventSessionDetailContext()

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const {
        reset,
        handleSubmit,
    } = form;

    const [updateGroupLocationResponse, updateGroupLocation] = useMutation(UpdateEventSessionGroupLocationDocument)

    const existingLocation = manipulationMode.name === 'manipulateEventSessionGroupLocation'
        ? event.sessions
            .filter(session => session.groups.find(group => group.id === manipulationMode.payload.groupId))
            .flatMap(session => session.groups.sort((a, b) => a.order - b.order).map(({ location }) => location))
        : [];

    const groupPosition = manipulationMode.name === 'manipulateEventSessionGroupLocation'
        ? manipulationMode.payload.groupPosition
        : -1;

    const asyncValidateEventSessionLocation = async (value: string) => {
        if (manipulationMode.name === 'manipulateEventSessionGroupLocation') {
            const result = await urqlClient.query(ValidateSessionLocationDocument, {
                location: value,
                isOnline: event.isOnline,
            }, {
                requestPolicy: 'network-only'
            }).toPromise();

            return result.data?.validateEventSessionLocation;
        }

        return null;
    };

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            if (manipulationMode.name === 'manipulateEventSessionGroupLocation') {
                const validValues = await value.Decoder.schema.parseAsync(data);

                const isUniqueLocationBetweenSessions = uniqueLocationBetweenSessions({
                    value: validValues.location,
                    event: event,
                    initLocation: manipulationMode.payload.location,
                    currentLanguage
                });

                const isUniqueLocationBetweenGroups = uniqueLocationBetweenGroup({
                    locations: existingLocation,
                    position: groupPosition,
                    value: validValues.location,
                });

                const isUniqueLocationBetweenEvents = await uniqueLocationBetweenEvents({
                    asyncValidateEventSessionLocation,
                    event: event,
                    value: validValues.location,
                });

                let validationError: LocationValidationError | undefined = undefined;

                if (hasValue(isUniqueLocationBetweenSessions)) {
                    validationError = {
                        name: 'isUniqueLocationBetweenSessions',
                        payload: [
                            {
                                group: isUniqueLocationBetweenSessions.groupNo,
                                message: `${isUniqueLocationBetweenSessions.sessionName} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenSessions.groupNo })})`,
                            },
                        ],
                    };
                } else if (hasValue(isUniqueLocationBetweenGroups)) {
                    validationError = {
                        name: 'isUniqueLocationBetweenGroups' as const,
                        payload: {
                            duplicateGroups: mergeTuple([isUniqueLocationBetweenGroups]),
                        },
                    };
                } else if (hasValue(isUniqueLocationBetweenEvents)) {
                    validationError = {
                        name: 'isUniqueLocationBetweenEvents' as const,
                        payload: [
                            {
                                group: isUniqueLocationBetweenEvents.groupNo,
                                message: `${isUniqueLocationBetweenEvents.eventName} - ${getEventSessionName(isUniqueLocationBetweenEvents.session, currentLanguage)} (${t('event.addSession.groupLabel', { count: isUniqueLocationBetweenEvents.groupNo })})`,
                            }
                        ],
                    };
                }

                if (isDefined(validationError)) {
                    showWarning(validationError, {
                        groupId: manipulationMode.payload.groupId,
                        location: validValues.location,
                        locationType: validValues.locationType,
                    });
                } else {
                    updateGroupLocation({
                        groupId: manipulationMode.payload.groupId,
                        location: validValues.location,
                        locationType: validValues.locationType
                    }, getQueryContext('EventSessionGroup'));
                }
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    const close = () => {
        backToInit();

        closeModal();
        reset(defaultValues);
    };

    const onCloseWarningModal = () => backToInit();

    const onFinalSubmit = () => {
        try {
            if (mode.name === 'warn' && manipulationMode.name === 'manipulateEventSessionGroupLocation') {
                const settings = mode.payload.currentSetting as UpdateLocationSettings;

                updateGroupLocation({
                    groupId: manipulationMode.payload.groupId,
                    location: settings.location,
                    locationType: settings.locationType
                }, getQueryContext('EventSessionGroup'));

                close();
            }
        } catch {
            toastError('global.error');
        }
    };

    useEffect(() => {
        reset(defaultValues);
    }, [manipulationMode]);

    useEffect(() => {
        handleResponse(updateGroupLocationResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false);
                if (data.updateEventSessionGroupLocation) {
                    close();
                    toastSuccess('event.eventSessionGroupManipulateLocationModal.successes.update');
                } else {
                    toastError('event.eventSessionGroupManipulateLocationModal.errors.update');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('event.eventSessionGroupManipulateLocationModal.errors.update');
            },
        });
    }, [updateGroupLocationResponse]);

    return {
        form,
        onSubmit: handleSubmit(onSubmit),
        onFinalSubmit,
        isSubmitting,
        onCloseWarningModal,
        mode,
        onCloseModal: close,
    };
};

export {
    useEventSessionGroupManipulateLocationModal
};
