import { EmailContactsDocument, SendEmailToCandidateDocument } from "@entities";
import { useToast } from "@helpers/hooks/unsorted/toastHook"
import { getQueryContext, handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { SelectionOption } from "@typedefs/selectOption";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, UseFormReturn, useWatch } from "react-hook-form"
import { useMutation, useQuery } from "urql";
import { ValueItem as MultiValueTypeAheadItem } from '@shared/unsorted/MultiValueTypeAhead/value'

import * as value from './value'
import { isDefined } from "@helpers/core/typeGuards";
import { ApplicationStepMode } from "@pages/ApplicationsPage/ApplicationPanel/reducer";

interface EmailEditorHookType {
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    ccBccMode: 'hide' | 'show';
    displayCcBcc: VoidFunction;
    emailContactOptions: SelectionOption[];
    searchEmail: (currentValues: MultiValueTypeAheadItem.ValueItemType[], input?: string) => void;
    setRemainingOptions: (currentValues: MultiValueTypeAheadItem.ValueItemType[]) => void;
    toShowMode: VoidFunction;
}

const useEmailEditor = (applicationId: string, dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void): EmailEditorHookType => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const emailContactOptionRef = useRef<SelectionOption[]>([])

    const [emailContactOptions, setEmailContactOptions] = useState<SelectionOption[]>([])

    const [ccBccMode, setCcBccMode] = useState<'hide' | 'show'>('hide')

    const { error: toastError, success: toastSuccess } = useToast()

    const defaultValues = value.Encoder.defaultValues()

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const { handleSubmit, control } = form

    const ccs = useWatch({
        control,
        name: 'cc'
    })

    const bccs = useWatch({
        control,
        name: 'bcc'
    })

    const emailContactQueryContext = useQueryContext(['EmailContact'])

    const [emailContactsResponse] = useQuery({ query: EmailContactsDocument, context: emailContactQueryContext, requestPolicy: 'network-only' })

    const [sendEmailResponse, sendEmail] = useMutation(SendEmailToCandidateDocument)

    const displayCcBcc = () => setCcBccMode('show')

    const toCCArray = (emailAddresses: MultiValueTypeAheadItem.ValueItemType[]) => {
        return emailAddresses.map(email => ({
            email: email.label,
        }))
    }

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)
            sendEmail({
                recruitmentProcessId: applicationId,
                subject: validValues.subject,
                body: validValues.content,
                ccs: toCCArray(validValues.cc),
                bccs: toCCArray(validValues.bcc),
                draft: false
            }, getQueryContext('RecruitmentProcess'))
        } catch (error) {
            toastError('global.error')
        }
    }

    const toShowMode = () => {
        dispatchApplicationStepMode({ name: 'show' })
    }

    const getRemainingOptions = () => {
        return emailContactOptionRef.current.filter(({ value }) =>
            !ccs.concat(bccs).map(({ label }) => label).includes(value)
        )
    }

    const searchEmail = (currentValues: MultiValueTypeAheadItem.ValueItemType[], input?: string) => {
        if (isDefined(input)) {
            const searchEmail = input.toLowerCase().trim()
            const newOptions = getRemainingOptions()
                .filter(({ value }) => value.includes(searchEmail))

            setEmailContactOptions(newOptions)
        }
    }

    const setRemainingOptions = () => {
        const remainingOptions = getRemainingOptions()
        setEmailContactOptions(remainingOptions)
    }

    useEffect(() => {
        handleResponse(sendEmailResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: ({ sendEmailToCandidate }) => {
                setIsSubmitting(false)
                if (sendEmailToCandidate) {
                    toastSuccess('applications.panel.candidateEmail.successes.sendEmail')
                    toShowMode()
                } else {
                    toastError('applications.panel.candidateEmail.errors.sendEmail')
                }
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('applications.panel.candidateEmail.errors.sendEmail')
            }
        })
    }, [sendEmailResponse])

    useEffect(() => {
        handleResponse(emailContactsResponse, {
            onData: (data) => {
                const emailContactOptions: SelectionOption[] = data
                    .emailContacts
                    .map(({ emailAddress }) => ({
                        key: emailAddress,
                        label: emailAddress,
                        value: emailAddress
                    }))
                emailContactOptionRef.current = emailContactOptions
                setEmailContactOptions(emailContactOptions)
            }
        })
    }, [emailContactsResponse])

    return {
        form,
        onSubmit: handleSubmit(onSubmit),
        isSubmitting,
        ccBccMode,
        displayCcBcc,
        emailContactOptions,
        searchEmail,
        setRemainingOptions,
        toShowMode
    }
}

export {
    useEmailEditor
}