import { nub } from "@helpers/unsorted/arrayExtra";
import { assertNever } from "@helpers/typeHelpers";
import { Tree } from "@helpers/data/tree";
import { Cn } from "@helpers/unsorted/classNames";
import { Checkbox, Size } from "@shared/unsorted/Checkbox/Checkbox";
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { useCallback } from "react";
import { CheckboxState } from "../hook";

const styles = {
    checkbox: (isDisabled: boolean, size: Size) =>
        Cn.join([
            Cn.c("py-2 px-4 flex items-center space-x-2"),
            Cn.ifFalse(
                isDisabled,
                "hover:bg-surface-hovered-default cursor-pointer"
            ),
            isDisabled ? Cn.c("text-disabled") : Cn.c("text-emphasized"),
            (() => {
                switch (size) {
                    case "sm":
                    case "md": { // temporarily set this font size for new md size
                        return Cn.c("font-paragraph-xsmall-regular");
                    }
                    case "lg": {
                        return Cn.c("font-paragraph-small-regular");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    label: (isDisabled: boolean) =>
        Cn.join([
            Cn.c("block select-none"),
            isDisabled ? Cn.c("text-disabled") : Cn.c("text-emphasized"),
        ]),
    tooltipIcon: Cn.c("text-icons-default w-4 h-4"),
    list: Cn.c("ml-4"),
};

type Props<Value> = {
    tree: Tree<CheckboxState<Value>>;
    value: Value[];
    valueEquals: (x: Value, y: Value) => boolean;
    size: Size;
    depth: number;
    onChange: (value: Value[]) => void;
    optionClassName?: string;
};

const NestedCheckboxGroupItem = <Value,>(props: Props<Value>) => {
    const { tree, size, value, valueEquals, depth, onChange, optionClassName = '' } = props;

    const handleChange = useCallback(() => {
        if (tree.value.disabled) {
            return;
        }
        if (tree.value.checked) {
            onChange(
                value.filter(
                    (x) => !tree.value.values.some((y) => valueEquals(x, y))
                )
            );
        } else {
            onChange(nub([...value, ...tree.value.values], valueEquals));
        }
    }, [
        onChange,
        value,
        tree.value.disabled,
        tree.value.checked,
        tree.value.values,
    ]);

    return (
        <li>
            <label className={Cn.join([styles.checkbox(tree.value.disabled, size), optionClassName])}>
                <Checkbox
                    size={size}
                    id={tree.value.key}
                    checked={tree.value.checked}
                    disabled={tree.value.disabled}
                    onChange={handleChange}
                />
                <span className={styles.label(tree.value.disabled)}>
                    {tree.value.label}
                </span>
                {tree.value.tooltip && (
                    <ToolTipBox
                        tooltipContent={tree.value.tooltip}
                        placement="right"
                    >
                        <div>
                            <Icon
                                name="questionMark"
                                className={Cn.join([styles.tooltipIcon])}
                            />
                        </div>
                    </ToolTipBox>
                )}
            </label>
            {tree.children.length > 0 && (
                <ul className={styles.list}>
                    {tree.children.map((child) => (
                        <NestedCheckboxGroupItem
                            key={child.value.key}
                            tree={child}
                            value={value}
                            valueEquals={valueEquals}
                            size={size}
                            depth={depth + 1}
                            onChange={onChange}
                            optionClassName={optionClassName}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export { NestedCheckboxGroupItem };
