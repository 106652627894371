import { Emails_AllQuery } from "@entities";
import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from "@helpers/unsorted/classNames";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Letter } from "react-letter";

const styles = {
  container: Cn.c("p-4 pt-3 text-default"),
  subject: Cn.c("font-paragraph-small-bold"),
  tableContainer: Cn.c("border-b border-subdued pb-4 mb-4"),
  table: Cn.c("font-paragraph-xsmall-medium text-subdued mt-2"),
  tableHeading: Cn.c("text-right align-text-top w-1/12"),
  tableContent: Cn.c("pl-4"),
  body: Cn.c("font-paragraph-small-regular")
}

interface Props {
    subject: string;
    senderName?: string;
    senderEmailAddress: string;
    recipients: Entity<Emails_AllQuery, 'emails.recipients'>[];
    date: Date;
    body: string;
}

const EmailDisplay: FunctionComponent<Props> = ({subject, senderName, senderEmailAddress, recipients, date, body}) => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const toRecipients =
        recipients
        .filter(recipient => recipient.type === 'TO')
        .map(item => item.recipientEmailAddress)

    const ccRecipients =
        recipients
        .filter(recipient => recipient.type === 'CC')
        .map(item =>
        `${item.recipientName || ""} <${item.recipientEmailAddress}>`
        )

    const bccRecipients =
        recipients
        .filter(recipient => recipient.type === 'BCC')
        .map(item =>
            `${item.recipientName || ""} <${item.recipientEmailAddress}>`
        )

    return (
      <div className={styles.container}>
        <div className={styles.tableContainer}>
          <span className={styles.subject}> {subject} </span>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={styles.tableHeading}>
                    {t('applications.panel.candidateEmail.from')}
                </td>
                <td className={styles.tableContent}>
                  <span>
                    {`${senderName || ""} <${senderEmailAddress}>`}
                  </span>
                </td>
              </tr>
              <tr>
                <td className={styles.tableHeading}>
                    {t('applications.panel.candidateEmail.to')}
                </td>
                <td className={styles.tableContent}>
                  <span> {toRecipients.join(", ")} </span>
                </td>
              </tr>
              {ccRecipients.length !== 0 &&
              <>
                <tr>
                    <td className={styles.tableHeading}>
                    {t('applications.panel.candidateEmail.cc')}
                    </td>
                    <td className={styles.tableContent}>
                    <span> {ccRecipients.join(", ")} </span>
                    </td>
                </tr>
                </>}
              {bccRecipients.length !== 0 &&
              <>
                <tr>
                    <td className={styles.tableHeading}>
                    {t('applications.panel.candidateEmail.bcc')}
                    </td>
                    <td className={styles.tableContent}>
                    <span> {bccRecipients.join(", ")} </span>
                    </td>
                </tr>
                </>}
              <tr>
                <td className={styles.tableHeading}>
                {t('applications.panel.candidateEmail.date')}
                </td>
                <td className={styles.tableContent}>
                  <span>
                    {`${dateFormat.simpleDate(date, "/", currentLanguage)} ${dateFormat.simpleTime(date)}`}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.body}> <Letter html={body} /> </div>
      </div>
    );
};

export {
    EmailDisplay,
};
