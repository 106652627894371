import { assertNever } from "@helpers/typeHelpers";
import { ClosedStepStatus, OngoingStepStatus } from "@helpers/core/constants";
import { RecruitmentStageType } from "@typedefs/aliases"

namespace Encoder {
    export type Type = ReturnType<typeof defaultValues>

    export const defaultValues = ({
        stages = [],
        ongoingStepStatuses = [],
        archivedStepStatuses = [],
        jobPositionIds = [],
        stepIds = [],
        schoolIds = [],
    }: {
        stages?: RecruitmentStageType[];
        ongoingStepStatuses?: OngoingStepStatus[];
        archivedStepStatuses?: ClosedStepStatus[];
        jobPositionIds: string[];
        stepIds?: string[];
        schoolIds?: string[];
    }) => {
        const candidateStatuses: Decoder.CandidateStatus[] = [
            ...stages.map(
                (status): Decoder.CandidateStatus => ({
                    type: "ongoing",
                    value: status,
                })
            ),
            ...archivedStepStatuses.map(
                (status): Decoder.CandidateStatus => ({
                    type: "archived",
                    value: status,
                })
            ),
        ];

        return {
            candidateStatuses,
            stages,
            ongoingStepStatuses,
            jobPositionIds,
            stepIds,
            schoolIds,
        }
    }
}

namespace Decoder {
    export type FilterOption<A> = {
        value: A;
        label: string;
        count: number;
    }

    export const sumFilterOptionsCount = <A>(options: FilterOption<A>[]): number => {
        return options.reduce((acc, option) => acc + option.count, 0);
    }

    export type CandidateStatus =
        | {
            type: "ongoing";
            value: RecruitmentStageType;
        }
        | {
            type: "archived";
            value: ClosedStepStatus;
        };

    export const candidateStatusEquals = (
        x: CandidateStatus,
        y: CandidateStatus
    ) => {
        return x.type === y.type && x.value === y.value;
    };

    export const foldCandidateStatuses = (
        statuses: CandidateStatus[]
    ): {
        ongoing: Extract<CandidateStatus, { type: "ongoing" }>["value"][];
        archived: Extract<
            CandidateStatus,
            { type: "archived" }
        >["value"][];
    } => {
        return statuses.reduce(
            (acc, status) => {
                if (status.type === "ongoing") {
                    acc.ongoing.push(status.value);
                } else if (status.type === "archived") {
                    acc.archived.push(status.value);
                } else {
                    assertNever(status);
                }
                return acc;
            },
            { ongoing: [], archived: [] } as ReturnType<
                typeof foldCandidateStatuses
            >
        );
    };
}

export { Encoder, Decoder }
