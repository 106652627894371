import { isNullish } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { Children, FunctionComponent, isValidElement, useCallback, useEffect, useState } from 'react';
import { CarouselItem } from './CarouselItem/CarouselItem';

const styles = {
    container: Cn.c('flex flex-col items-center justify-center content-between'),
    slidesContainer: Cn.c('flex h-full'),
    slide: (isShown: boolean) => Cn.join([
        Cn.c('transition-opacity'),
        isShown ? Cn.c('flex') : Cn.c('hidden'),
    ]),
    navigation: Cn.c('flex justify-center space-x-2 mt-4 content-end'),
    circle: (isSelected: boolean) => Cn.join([
        Cn.c('w-2 h-2 rounded-full cursor-pointer'),
        isSelected ? Cn.c('bg-icons-primary-default') : Cn.c('bg-icons-subdued'),
    ]),
};

interface Props extends ChildrenProps {
    /** Duration in milliseconds between automatic slide transitions.
    Default value set to 3000.
    Set to 0 or negative number to disable auto-advance to next slide */
    interval?: number;
}

// TODO: Improve layout.
// Currently, the slide divs height is not consistent so the bottom navigation dots moves with it if there's no pre-defined height for the CarouselItem content.
const Carousel: FunctionComponent<Props> = ({ children: carouselItems, interval = 3000 }) => {
    if (isNullish(carouselItems)) {
        throw new Error('Carousel component requires CarouselItem children');
    }

    const slides = Children.toArray(carouselItems);

    const isAllSlidesValid = slides.every((slide) => isValidElement(slide) && slide.type === CarouselItem);

    if (!isAllSlidesValid) {
        throw new Error('Carousel component only accepts CarouselItem as child');
    }

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    useEffect(() => {
        if (interval > 0) {
            const intervalId = setInterval(() => {
                setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
            }, interval);

            return () => clearInterval(intervalId);
        }
    }, [slides.length, interval]);

    const goToSlide = useCallback((index: number) => {
        setCurrentSlideIndex(index);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.slidesContainer}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={styles.slide(index === currentSlideIndex)}
                    >
                        {slide}
                    </div>
                ))}
            </div>
            <div className={styles.navigation}>
                {slides.map((_, index) => (
                    <div
                        key={index}
                        className={styles.circle(index === currentSlideIndex)}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export {
    Carousel,
};