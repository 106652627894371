import { useRef, useState } from 'react';

import { isNonNull } from '@helpers/core/typeGuards';

// @ocaml.doc("This hook will return the currently selected `files` and an imperative `openFolder` function.
// It also returns the `openFolderProps` which must be passed down to the `Input` component provided with this hook.
//
// Be aware that the `files` object reference will always be the same as long as the user doesn't upload any
// new files, and can safely be used in a `React.useEffect` dependencies array.
//
// Also, you should use `openFolder` only in events or in `React.useEffect` hooks.
//
// _This hook works only on the client side._
//
// ```
// let {files, openFolder, openFolderProps} = OpenFolderHook.use()
//
// React.useEffect1(() => {
//   // Handle newly loaded files
// }, [files])
//
// <div>
//   <OpenFolderHook.Input openFolderProps />
//   <button onClick={_ => openFolder()}>
//     {\"Click to open the folder\"->React.string}
//   </button>
// </div>
// ```
// ")
const useOpenFolder = () => {
    const ref = useRef<HTMLInputElement>(null);
    const [ files, setFiles ] = useState<File[]>();

    const openFolder = () => {
        const input = ref.current;
        if (isNonNull(input) && !input.hasAttribute('disabled')) {
            input.click();
        }
    };

    return {
        files,
        openFolder,
        openFolderProps: { ref, setFiles },
    };
};

export {
    useOpenFolder,
};
