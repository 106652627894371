import { EmailTemplate, JobPosition_OneByIdWithStepEventsQuery } from '@entities';
import { selectableRecruitmentSteps } from '@helpers/core/constants';
import { hasValue, isOneOf } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { RecruitmentStageHeader } from '@shared/job_position/RecruitmentFlow/RecruitmentStageHeader';
import { isStepEditable } from '@shared/job_position/RecruitmentFlow/helper';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailTemplateMode } from './EmailTemplateUpdateModal/hook';
import { ViewDecisionEmailsDropdown } from './ViewDecisionEmailsDropdown';
import { useEventSettingContext } from '@shared/event/EventSettingsModal/useEventSettingContext';
import { generatePath, useNavigate } from 'react-router-dom';
import { EventDetail } from '@routes/event';

interface Props {
    recruitmentSteps: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>[];
    openEmailTemplateUpdateModal: (modalType: EmailTemplateMode['name'], payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        emailTemplate: EmailTemplate
    }) => void;
    openEvaluationFormUpdateModal: (payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        questions?: string | null,
        isModifiable: boolean
    }) => void;
}

const styles = {
    table: Cn.c('w-full'),
    stepContainer: Cn.c("py-6 px-10 flex flex-col space-y-6"),
    row: Cn.c('flex'),
    baseCellStyle: Cn.c('flex items-center'),
    cell: (columnNumber: number) => Cn.join([
        styles.baseCellStyle,
        Cn.ifTrue(columnNumber === 1, Cn.c('justify-start flex-[1.2] space-x-1')),
        Cn.ifTrue(columnNumber === 2, Cn.c('justify-start flex-[0.8]')),
        Cn.ifTrue(columnNumber >= 3, Cn.c('justify-center w-[12rem]')),
    ]),
    stepName: Cn.c("font-paragraph-small-medium text-emphasized"),
    tooltip: Cn.c('w-[34rem]'),
    questionMark: Cn.c('text-icons-emphasized w-4 h-4'),
    stepType: Cn.c("text-emphasized font-paragraph-small-regular mb-2"),
    eventTypeContainer: Cn.c('flex gap-x-1 cursor-pointer items-center'),
    eventIcon: Cn.c("w-4 h-4 text-icons-primary-default"),
    setEvent: Cn.c('p-0'),
    noStepsContainer: Cn.c('font-paragraph-small-regular text-subdued'),
};

const ReadOnlyRecruitmentStage: FunctionComponent<Props> = ({
    recruitmentSteps,
    openEmailTemplateUpdateModal,
    openEvaluationFormUpdateModal
}) => {
    const { t } = useTranslation();

    const [_, setEventSettingModalMode] = useEventSettingContext()

    const navigate = useNavigate()

    return (
        <>
            <div className={styles.table}>
                <RecruitmentStageHeader />
                <div className={styles.stepContainer}>
                    {recruitmentSteps.map(step => {
                        const { notificationEmailTemplate, decisionPassEmailTemplate, decisionFailEmailTemplate, evaluationForm, type } = step

                        return (
                            <div className={styles.row} key={step.id}>
                                <div className={styles.cell(1)}>
                                    <p className={styles.stepName}>{step.name === 'Recruitment Result' ? t('recruitmentStepType.RecruitmentResult') : step.name}</p>
                                    {!isStepEditable(step) && <ToolTipBox
                                        toolTipClassName={styles.tooltip}
                                        tooltipContent={t("jobPositions.shared.recruitmentFlow.mandatoryStepTooltipText")}
                                        placement="right">
                                        <div>
                                            <Icon name="questionMark" className={styles.questionMark} />
                                        </div>
                                    </ToolTipBox>}
                                </div>
                                <div className={styles.cell(2)}>
                                    <div>
                                        {
                                            isOneOf(selectableRecruitmentSteps)(type) ?
                                                <p className={styles.stepType}>{t(`recruitmentStepTypeV2.${type}`)}</p>
                                                : getWithDefault()
                                        }
                                        {type === 'EVENT'
                                            ? hasValue(step.event)
                                                ? <Button
                                                    variant="primaryPlain"
                                                    iconName="calendarFill"
                                                    size="md"
                                                    className={styles.setEvent}
                                                    onClick={() => {
                                                        if (hasValue(step.event)) {
                                                            const path = generatePath(EventDetail.PATH_NAME, { id: step.event.id })
                                                            navigate(path)
                                                        }
                                                    }}>
                                                    {t("jobPositions.detail.viewEventDetails")}
                                                </Button>
                                                : <Button
                                                    variant="primaryPlain"
                                                    iconName="plus"
                                                    size="md"
                                                    className={styles.setEvent}
                                                    onClick={() => setEventSettingModalMode({ name: 'create', payload: { recruitmentStepId: step.id } })}>
                                                    {t("jobPositions.detail.setEvent")}
                                                </Button>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className={styles.cell(3)}>
                                    {step.hasNotification
                                        ? notificationEmailTemplate
                                            ? <Button
                                                iconName="mail"
                                                size="md"
                                                variant="ghost"
                                                onClick={() =>
                                                    openEmailTemplateUpdateModal('updateNotificationEmailTemplate', {
                                                        step,
                                                        emailTemplate: notificationEmailTemplate
                                                    })}>
                                                {t("jobPositions.detail.viewEmail")}
                                            </Button>
                                            : <Button
                                                iconName="plus"
                                                size="md"
                                                variant="primaryOutline">
                                                {t("jobPositions.detail.addEmail")}
                                            </Button>
                                        : getWithDefault()}
                                </div>
                                <div className={styles.cell(4)}>
                                    {step.hasEvaluation
                                        ? evaluationForm && evaluationForm.questions
                                            ? <Button
                                                iconName="form"
                                                size="md"
                                                variant="ghost"
                                                onClick={() => openEvaluationFormUpdateModal({
                                                    step,
                                                    questions: evaluationForm.questions,
                                                    isModifiable: evaluationForm.isModifiable
                                                })}>
                                                {t("jobPositions.detail.viewForm")}
                                            </Button>
                                            : <Button
                                                iconName="plus"
                                                size="md"
                                                variant="primaryOutline"
                                                onClick={() => openEvaluationFormUpdateModal({
                                                    step,
                                                    isModifiable: true
                                                })}>
                                                {t("jobPositions.detail.addForm")}
                                            </Button>
                                        : getWithDefault()}
                                </div>
                                <div className={styles.cell(5)}>
                                    {step.hasDecision
                                        //TODO: in the future when we opt to ignore default email template, we will need to change this logic
                                        && decisionPassEmailTemplate && decisionFailEmailTemplate
                                        ? <ViewDecisionEmailsDropdown
                                            openPassDecisionEmailTemplateUpdateModal={
                                                () => openEmailTemplateUpdateModal('updatePassDecisionEmailTemplate', {
                                                    step,
                                                    emailTemplate: decisionPassEmailTemplate
                                                })
                                            }
                                            openFailDecisionEmailTemplateUpdateModal={
                                                () => openEmailTemplateUpdateModal('updateFailDecisionEmailTemplate', {
                                                    step,
                                                    emailTemplate: decisionFailEmailTemplate
                                                })
                                            }
                                        />
                                        : getWithDefault()}
                                </div>
                            </div>
                        );
                    })}
                    {recruitmentSteps.length === 0 && <div className={styles.noStepsContainer}>{t("jobPositions.shared.recruitmentFlow.noStepsToShow")}</div>}
                </div>
            </div>
        </>
    );
};

export {
    ReadOnlyRecruitmentStage
};
