import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { isDefined } from '@helpers/core/typeGuards';
import { Entity } from '@typedefs/graphql';
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationStepMode, State, initialState, reducer } from './reducer';
import { ApplicationModalMode } from '../reducer';
import { Candidate } from '@routes/candidate';

interface ApplicationPanelHookResult {
    closePanel: VoidFunction;
    hasAnyUnderSelectionApplication: boolean;
    openApplicationDetailPanel: VoidFunction;
    applicationStepState: State;
    dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void;
}

const useApplicationPanel = (
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void,
    activeApplication?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>,
): ApplicationPanelHookResult => {
    const navigate = useNavigate();

    const queryParams = Candidate.useSearchParams();

    const [state, dispatch] = useReducer(reducer, initialState);

    const hasAnyUnderSelectionApplication = isDefined(activeApplication?.relatedRecruitmentProcesses.find(({ currentStep }) => currentStep.stage !== 'ARCHIVE'));

    const closePanel = () => {
        const { mode, ...restQueryParams } = queryParams;

        navigate(Candidate.toRoute({
            ...restQueryParams,
            mode: 'list',
        }));
    };

    const openApplicationDetailPanel = () => {
        dispatchApplicationModalMode({
            name: 'showApplicationDetail',
        })
    }

    const dispatchApplicationStepMode = (applicationStepMode: ApplicationStepMode) => dispatch({ name: 'SetApplicationStepMode', applicationStepMode });

    return {
        closePanel,
        hasAnyUnderSelectionApplication,
        openApplicationDetailPanel,
        applicationStepState: state,
        dispatchApplicationStepMode,
    };
};

export {
    useApplicationPanel,
};