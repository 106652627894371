import { createClient } from "graphql-ws";

// Ping server every 10 seconds
const keepAlive = 10_000;

// Ping timeout, socket will be closed after 5 seconds
const pingTimeout = 5_000;

// Current active socket
let activeSocket: any; //TODO: in graphql-ws it's of type unknown

// Set timeout id
let timedOut: NodeJS.Timeout;

// Last ping sent at timestamp
// let pingSentAt = 0; // TODO: [CHECK] Temporarily commented this since there's really no use as of now. Activate once needed

// MS between the ping has been sent the pong's received
// Useful for metrics
// let latency = 0; // TODO: [CHECK] Temporarily commented this since there's really no use as of now. Activate once needed

export const create = ({ url, connectionParams }: { url: string, connectionParams: Record<string, unknown> }) =>
  createClient({
    url,
    connectionParams,
    keepAlive,
    on: {
      connected: (socket) => (activeSocket = socket),
      ping: (received) => {
        if (!received) {
          // pingSentAt = Date.now(); // TODO: [CHECK] Temporarily commented this since there's really no use as of now. Activate once needed

          // ping is sent, close if no pong is received
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, "Request Timeout");
          }, pingTimeout);
        }
      },
      pong: (received) => {
        if (received) {
          // latency = Date.now() - pingSentAt; // TODO: [CHECK] Temporarily commented this since there's really no use as of now. Activate once needed

          // pong is received, clear connection close timeout
          clearTimeout(timedOut);
        }
      },
    },
  });
