import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react";
import { Icon } from "../Icon/Icon";

const styles = {
    container: Cn.c('bg-surface-info-subdued flex p-4 space-x-2 items-center justify-center rounded shadow-lg'),
    icon: Cn.c("text-icons-info-emphasized w-5 h-5"),
    message: Cn.c("text-info-emphasized font-paragraph-small-bold")
}

interface Props {
    message: string
}

const InfoToaster: FunctionComponent<Props> = ({
    message
}) => {
    return (
        <div className={styles.container}>
            <Icon name="info" className={styles.icon} />
            <span className={styles.message}>{message}</span>
        </div>
    )
}

export {
    InfoToaster
}