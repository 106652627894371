import { EvaluationsImportedFromCsvDocument } from "@entities"
import { hasValue } from "@helpers/core/typeGuards"
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { useEffect, useState } from "react"
import { useSubscription } from "urql"

const useCSVBulkEvaluationImportStepInProgess = () => {
    const [imported, setImported] = useState(0)

    const [evaluationsImportResponse] = useSubscription({ query: EvaluationsImportedFromCsvDocument })

    useEffect(() => {
        handleResponse(evaluationsImportResponse, {
            onFetching: () => {
                setImported(0)
            },
            onData: ({ evaluationsImportedFromCsv }) => {
                if (hasValue(evaluationsImportedFromCsv)) {
                    const imported = evaluationsImportedFromCsv.imported

                    setImported(imported)
                }
            }
        })
    }, [evaluationsImportResponse])

    return {
        imported,
    }
}

export {
    useCSVBulkEvaluationImportStepInProgess
}
