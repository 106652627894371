import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Carousel } from '@shared/unsorted/Carousel/Carousel';
import { Checkbox } from '@shared/unsorted/Checkbox/Checkbox';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Welcome1 from '../../../../../public/images/welcome1.svg';
import Welcome2 from '../../../../../public/images/welcome2.svg';
import Welcome3 from '../../../../../public/images/welcome3.svg';
import { CarouselItem } from '@shared/unsorted/Carousel/CarouselItem/CarouselItem';
import { useWelcomeModal } from './hook';

const styles = {
    container: Cn.c('rounded-xl py-6'),
    header: Cn.c('px-6 pb-5 flex justify-center items-center border-b border-default'),
    closeIcon: Cn.c('absolute top-6 right-6 w-6 h-6 text-emphasized'),
    outerCircle: Cn.c('w-14 h-14 flex items-center bg-surface-success-subdued justify-center rounded-full'),
    innerCircle: Cn.c('w-10 h-10 flex items-center bg-surface-success-default justify-center rounded-full'),
    partyIcon: Cn.c('w-6 h-6 text-icons-success-default absolute'),
    headerDescriptionContainer: Cn.c('flex flex-col items-center space-y-4'),
    descriptionContainer: Cn.c('flex flex-col items-center space-y-2'),
    headerDescription: Cn.c('font-h3-bold text-emphasized'),
    headerSubDescription: Cn.c('font-paragraph-small-regular text-subdued text-center'),
    body: Cn.c('p-6'),
    footer: Cn.c('px-6 pt-6 flex border-t border-default justify-between'),
    dontShowContainer: Cn.c('flex space-x-2 items-center'),
    dontShowAgain: Cn.c('text-emphasized font-paragraph-small-medium'),
    buttons: Cn.c('flex space-x-3'),
    slide: Cn.c('flex flex-col items-center justify-center content-between'),
    slideTitle: Cn.c('font-paragraph-base-bold text-emphasized text-center mt-6'),
    slideCaption: Cn.c('font-paragraph-small-regular text-subdued text-center mt-1'),
    img: Cn.c('w-[27rem] h-[23.25rem]'),
};

interface Props extends ModalProps {
    onCloseModal: (dontShowAgain: boolean) => void;
}

const WelcomeModal: FunctionComponent<Props> = ({
    isOpen,
    onCloseModal,
}) => {
    const { t } = useTranslation();

    const {
        isDontShowAgainChecked,
        onSetDontShowAgain,
        onClose,
        onCreateFirstJob,
    } = useWelcomeModal(onCloseModal);

    return (
        <Modal isOpen={isOpen} close={onClose}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                    <div className={styles.headerDescriptionContainer}>
                        <div className={styles.outerCircle}>
                            <div className={styles.innerCircle}>
                                <Icon name="party" className={styles.partyIcon} />
                            </div>
                        </div>
                        <div className={styles.descriptionContainer}>
                            <p className={styles.headerDescription}>{t('welcome.description')}</p>
                            <p className={styles.headerSubDescription}>{t('welcome.subDescription')}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    <Carousel>
                        <CarouselItem className={styles.slide}>
                            <Welcome1 className={styles.img} />
                            <div>
                                <p className={styles.slideTitle}>{t('welcome.content.slide1.title')}</p>
                                <p className={styles.slideCaption}>{t('welcome.content.slide1.caption')}</p>
                            </div>
                        </CarouselItem>
                        <CarouselItem className={styles.slide}>
                            <Welcome2 className={styles.img} />
                            <div>
                                <p className={styles.slideTitle}>{t('welcome.content.slide2.title')}</p>
                                <p className={styles.slideCaption}>{t('welcome.content.slide2.caption')}</p>
                            </div>
                        </CarouselItem>
                        <CarouselItem className={styles.slide}>
                            <Welcome3 className={styles.img} />
                            <div>
                                <p className={styles.slideTitle}>{t('welcome.content.slide3.title')}</p>
                                <p className={styles.slideCaption}>{t('welcome.content.slide3.caption')}</p>
                            </div>
                        </CarouselItem>
                    </Carousel>
                </div>
                <div className={styles.footer}>
                    <div className={styles.dontShowContainer}>
                        <Checkbox
                            size="sm"
                            id="dontShowAgain"
                            checked={isDontShowAgainChecked}
                            onChange={onSetDontShowAgain} />
                        <p className={styles.dontShowAgain}>{t('welcome.dontShowAgain')}</p>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant="secondary"
                            size="md"
                            onClick={onClose}
                        >
                            {t('global.close')}
                        </Button>
                        <Button
                            variant="primaryFilled"
                            size="md"
                            onClick={onCreateFirstJob}
                        >
                            {t('welcome.createFirstJob')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export {
    WelcomeModal
};