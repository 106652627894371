import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    rowHeader: Cn.c('flex px-10 bg-surface-emphasized font-paragraph-xsmall-semi-bold text-subdued'),
    baseCellStyle: Cn.c('flex items-center py-4'),
    cell: (columnNumber: number) => Cn.join([
        styles.baseCellStyle,
        Cn.ifTrue(columnNumber === 1, Cn.c('justify-start flex-[1.2]')),
        Cn.ifTrue(columnNumber === 2, Cn.c('justify-start flex-[0.8]')),
        Cn.ifTrue(columnNumber >= 3, Cn.c('justify-center w-[12rem]')),
    ]),
    tooltipContainer: Cn.c('flex items-center gap-x-1'),
    questionMark: Cn.c('text-icons-subdued w-4 h-4'),
}

const RecruitmentStageHeader: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.rowHeader}>
            <div className={styles.cell(1)}>{t("jobPositions.shared.recruitmentFlow.stepName")}</div>
            <div className={styles.cell(2)}>{t("jobPositions.shared.recruitmentFlow.stepType")}</div>
            <div className={styles.cell(3)}>
                <div className={styles.tooltipContainer}>
                    <p> {t("jobPositions.shared.recruitmentFlow.notification")}</p>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.shared.recruitmentFlow.notificationTooltip")}
                        placement="top">
                        <div><Icon name="questionMark" className={styles.questionMark} /></div>
                    </ToolTipBox>
                </div>
            </div>
            <div className={styles.cell(4)}>
                <div className={styles.tooltipContainer}>
                    <span> {t("jobPositions.shared.recruitmentFlow.evaluation")}</span>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.shared.recruitmentFlow.evaluationTooltip")}
                        placement="top">
                        <div><Icon name="questionMark" className={styles.questionMark} /></div>
                    </ToolTipBox>
                </div>
            </div>
            <div className={styles.cell(5)}>
                <div className={styles.tooltipContainer}>
                    <span> {t("jobPositions.shared.recruitmentFlow.decision")}</span>
                    <ToolTipBox
                        tooltipContent={t("jobPositions.shared.recruitmentFlow.decisionTooltip")}
                        placement="top">
                        <div><Icon name="questionMark" className={styles.questionMark} /></div>
                    </ToolTipBox>
                </div>
            </div>
        </div>
    );
};


export {
    RecruitmentStageHeader
};
