import { BasicCandidateQuery, UnreservedEventQuery } from "@entities";
import { RadioInputOptions } from "@shared/unsorted/RadioInput/RadioInputGroup/RadioInputGroup";
import { Entity } from "@typedefs/graphql";

interface LoadedMode {
    name: 'loaded'
    unreservedEvent: Entity<UnreservedEventQuery, 'unreservedEvent'>
    slots: RadioInputOptions[]
    candidate: Entity<BasicCandidateQuery, 'basicCandidate'>
    canSubmit: boolean
}

interface ErrorMode {
    name: 'error'
    payload: {
        contactEmail?: string
        candidateName?: string
        companyName?: string
        eventDisplayName?: string
    }
}

interface SuccessMode {
    name: 'success',
    payload: {
        candidateName: string
        companyName: string
        eventDisplayName: string
    }
}

interface LoadingState {
    isSubmitting: boolean;
    isLoading: true;
}

interface LoadedState {
    isSubmitting: boolean;
    isLoading: false;
    mode: LoadedMode | ErrorMode | SuccessMode
}

type State = LoadingState | LoadedState;

interface UpdateUnreservedEventAction {
    name: 'UpdateUnreservedEvent',
    payload: {
        unreservedEvent: Entity<UnreservedEventQuery, 'unreservedEvent'>
        slots: RadioInputOptions[]
        candidate: Entity<BasicCandidateQuery, 'basicCandidate'>
        canSubmit: boolean
    }
}

interface StartLoadingAction {
    name: 'StartLoading'
}

interface SetErrorAction {
    name: 'SetError'
    payload: {
        contactEmail?: string
        candidateName?: string
        companyName?: string
        eventDisplayName?: string
    }
}

interface SetSuccessAction {
    name: 'SetSuccess',
    payload: {
        candidateName: string
        companyName: string
        eventDisplayName: string
    }
}

interface SetIsSubmittingAction {
    name: 'SetIsSubmitting',
    isSubmitting: boolean
}

type Action = UpdateUnreservedEventAction | StartLoadingAction | SetErrorAction | SetSuccessAction | SetIsSubmittingAction

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'UpdateUnreservedEvent':
            return {
                ...state,
                isLoading: false,
                mode: {
                    name: 'loaded',
                    unreservedEvent: action.payload.unreservedEvent,
                    slots: action.payload.slots,
                    candidate: action.payload.candidate,
                    canSubmit: action.payload.canSubmit,
                }
            }

        case 'SetError':
            return {
                ...state,
                isLoading: false,
                mode: {
                    name: 'error',
                    payload: action.payload
                }
            }

        case 'SetSuccess':
            return {
                ...state,
                isLoading: false,
                mode: {
                    name: 'success',
                    payload: {
                        ...action.payload
                    }
                }
            }

        case 'StartLoading':
            return {
                ...state,
                isLoading: true
            }

        case 'SetIsSubmitting':
            return {
                ...state,
                isSubmitting: action.isSubmitting
            }
    
        default:
            return { ...state }
    }
}

const initialState: State = {
    isLoading: true,
    isSubmitting: false
}

export {
    State,
    initialState,
    reducer
}