import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentLanguage } from '@helpers/core/i18n';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { useClient } from '@helpers/hooks/unsorted/clientHook';
import { Cn } from '@helpers/unsorted/classNames';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { Icon } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';
import { ToolTipBox } from '../ToolTipBox/ToolTipBox';

const styles = {
    menuContainer: Cn.c('relative flex cursor-pointer space-x-2'),
    avatar: Cn.c('h-8 w-8 flex rounded-full bg-surface-dark-default justify-center items-center'),
    avatarIcon: Cn.c('w-4 h-4 text-icons-on-primary'),
    clientName: Cn.c('font-paragraph-xsmall-medium'),
    clientRoles: Cn.c('text-[10px] leading-3 text-subdued truncate w-[7.75rem]'),
    icons: Cn.c('flex flex-col justify-between'),
    icon: Cn.c('text-icons-emphasized w-6 h-3'),
};

type MenuState = 'show' | 'hide';

interface Props {
    className?: string
}

const UserMenu: FunctionComponent<Props> = ({ className }) => {
    const { currentClient } = useClient();
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const [menuState, setMenuState] = useState<MenuState>(() => 'hide');

    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        const newState = menuState === 'hide' ? 'show' : 'hide';
        setMenuState(newState);
    };

    useClickOutside([menuRef], () => {
        setMenuState('hide');
    });

    const userRoles = useMemo(() => {
        if (currentClient.clientState === 'loggedIn') {
            const roles = currentClient.clientInfo.roles.map(role =>
                t(`settings.userManagement.list.role.${role.name}`));

            let userRolesTooltip;

            if (currentLanguage === 'en') {
                userRolesTooltip = roles.map(role => `"${role}"`).join(', ').replace(/,\s([^,]*)$/, ' and $1');
            } else {
                userRolesTooltip = roles.map(role => `「${role}」`).join('、');
            }

            return {
                list: roles.join(', '),
                userRolesTooltip,
            };
        }
    }, [currentClient, currentLanguage]);


    switch (currentClient.clientState) {
        case 'loading':
            return <Loader />;
        case 'loggedOut':
            // TODO: [IMPLEMENT] Replace with correct login url when available
            // TODO: discuss with team either we redirect user to login page or we show a login button
            return null;
        case 'loggedIn':
            return (
                <div
                    className={Cn.join([styles.menuContainer, Cn.getSome(className)])}
                    ref={menuRef}
                    onClick={() => toggleMenu()}>
                    <div className={styles.avatar}>
                        <Icon name="user" className={styles.avatarIcon} />
                    </div>
                    <div>
                        <div className={styles.clientName}>{currentClient.clientInfo.fullName}</div>
                        <div>
                            <ToolTipBox
                                tooltipContent={t('sideMenu.userRolesTooltip', { roles: userRoles?.userRolesTooltip })}
                                placement="right">
                                <div className={styles.clientRoles}>{getWithDefault(userRoles?.list)}</div>
                            </ToolTipBox>
                        </div>
                    </div>
                    {/* TODO: Temporarily remove the menu since functionality is not yet available */}
                    {/* <div className={styles.icons}>
                        <Icon name='arrowUp' className={styles.icon} />
                        <Icon name='arrowDown' className={styles.icon} />
                    </div> */}
                </div>
            );
    }
};

export {
    UserMenu
};

