import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { CandidateInformationField } from '@shared/unsorted/CandidateInformationField/CandidateInformationField';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    container: Cn.c('bg-surface-default rounded-b-lg rounded-tr-lg'),
    sectionContainer: Cn.c('pb-7'),
    top: Cn.c('flex flex-row items-center pb-3 border-subdued border-b'),
    title: Cn.c('font-paragraph-base-bold text-default flex-1 text-left'),
    table: Cn.c("w-full"),
    row: Cn.c("border-b border-subdued text-default flex flex-row items-center py-3 gap-x-3"),
};

interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
}

const ApplicationCandidateDetailListMode: FunctionComponent<Props> = ({ application }) => {
    const { t } = useTranslation();

    const candidate = application.candidate;

    return (
        <div className={styles.container}>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateApplicationInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateApplicationInformation.acquisitionCategory'
                            value={getWithDefault(candidate.acquisitionCategory?.name)}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateApplicationInformation.acquisitionChannel'
                            value={getWithDefault(candidate.acquisitionChannel?.name)}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateApplicationInformation.entryCategory'
                            value={getWithDefault(candidate.entryCategory?.name)}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateApplicationInformation.entryChannel'
                            value={getWithDefault(candidate.entryChannel?.name)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateBasicInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.name'
                            value={candidate.name}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.furigana'
                            value={getWithDefault(candidate.nameKana)} // TODO: Remove/replace getWithDefault if necessary
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.dateOfBirth'
                            // TODO: [CHECK] Remove/replace getWithDefault if necessary
                            value={candidate.dateOfBirth ? dateFormat.simpleDateWithoutWeekday(new Date(candidate.dateOfBirth)) : getWithDefault()}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.gender'
                            value={candidate.gender ? t(`gender.${candidate.gender}`) : getWithDefault()}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.email'
                            value={candidate.email}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.mobilePhoneNumber'
                            value={getWithDefault(candidate.mobilePhoneNumber)} // TODO: [CHECK] Temporary display only. Remove/replace getWithDefault if necessary
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.currentAddress'
                            value={candidate.livesInJapan
                                ? getWithDefault([
                                    candidate.zipCode || "",
                                    candidate.prefecture?.name || "",
                                    candidate.address || "",
                                ]
                                    .join(" "))
                                : t('applications.panel.candidateBasicInformation.overseas')}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateBasicInformation.phoneNumber'
                            value={getWithDefault(candidate.phoneNumber)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateSchoolInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.expectedGraduation'
                            value={
                                candidate.graduationYear && candidate.graduationMonth
                                    ? t('applications.panel.candidateSchoolInformation.graduationYearMonth', { year: candidate.graduationYear, month: candidate.graduationMonth })
                                    : getWithDefault()
                            }
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.fieldType'
                            value={candidate.fieldType === 'UNSPECIFIED' ? getWithDefault() : t(`fieldTypes.${candidate.fieldType}`)}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.school'
                            value={getWithDefault(candidate.school?.name)}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.major'
                            value={getWithDefault(candidate.schoolDepartment?.name)}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.seminarLab'
                            value={getWithDefault(candidate.seminarLaboratory)}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.researchTheme'
                            value={getWithDefault(candidate.majorTheme)}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateSchoolInformation.clubActivity'
                            value={getWithDefault(candidate.clubActivity)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateOtherContactInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateOtherContactInformation.vacationContactAddress'
                            value={getWithDefault(
                                [
                                    candidate.vacationZipCode || '',
                                    candidate.vacationPrefecture?.name || '',
                                    candidate.vacationAddress || '',
                                ].join(' ')
                            )}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateOtherContactInformation.vacationPhoneNumber'
                            value={getWithDefault(candidate.vacationPhoneNumber)}
                        />
                    </div>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateOtherContactInformation.mobileEmail'
                            value={getWithDefault(candidate.mobileEmail)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sectionContainer}>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateJobHistoryInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateJobHistoryInformation.lastJobPosition'
                            value={getWithDefault(candidate.lastJobPosition)}
                        />
                        <CandidateInformationField
                            label='applications.panel.candidateJobHistoryInformation.departmentAndPositionTitle'
                            value={getWithDefault(candidate.departmentAndPositionTitle)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.top}>
                    <div className={styles.title}>{t("applications.panel.candidateOtherInformation.title")}</div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <CandidateInformationField
                            label='applications.panel.candidateOtherInformation.remarks'
                            value={getWithDefault(candidate.remarks)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    ApplicationCandidateDetailListMode,
};