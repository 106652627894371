import { BasicCandidateDocument, BasicEventSessionChoiceDocument, RegisterCandidateForEventSessionDocument, UnreservedEventDocument } from "@entities"
import { useCurrentLanguage } from '@helpers/core/i18n';
import { FormEventHandler, useEffect, useReducer } from "react"
import { State, initialState, reducer } from "./reducer"
import { handleResponse, responseHasError, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { useMutation, useQuery } from "urql";
import { EventRegistration } from "@routes/unauthenticated";
import { SubmitHandler, UseFormReturn, useForm } from "react-hook-form";
import * as value from './value'
import { Trans } from "@shared/unsorted/Trans/Trans";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { hasValue } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Cn } from "@helpers/unsorted/classNames";
import { useTranslation } from "react-i18next";

const styles = {
    optionsContainer: Cn.c('flex flex-col'),
    locationsContainer: Cn.c('flex flex-col font-paragraph-xsmall-regular'),
    locationIcon: Cn.c('w-3 h-3'),
    groupLocationContainer: Cn.c('flex flex-row items-center gap-x-1'),
};

interface EventRegistrationPageHookType {
    state: State;
    onSubmit: FormEventHandler<HTMLFormElement>;
    form: UseFormReturn<value.Encoder.EncoderType>;
}

const useEventRegistrationPage = (): EventRegistrationPageHookType => {
    const { error: toastError } = useToast()

    const [state, dispatch] = useReducer(reducer, initialState)

    const queryParams = EventRegistration.useSearchParams()

    const { t } = useTranslation();

    const currentLanguage = useCurrentLanguage();

    const characterLimit = currentLanguage === 'en' ? 30 : 20;

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues: value.Encoder.defaultValues(),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    })

    const { handleSubmit } = form

    const unreservedEventQueryContext = useQueryContext("UnreservedEvent");
    const [unreservedEventResponse,] = useQuery({
        query: UnreservedEventDocument,
        context: unreservedEventQueryContext,
        variables: {
            emailIdentifier: queryParams.emailIdentifier,
            recruitmentProcessId: queryParams.applicationId,
        }
    });

    const [registerCandidateForEventSessionResponse, registerCandidateForEventSession] = useMutation(RegisterCandidateForEventSessionDocument)

    const candidateQueryContext = useQueryContext("BasicCandidate");
    const [candidateResponse,] = useQuery({
        query: BasicCandidateDocument,
        context: candidateQueryContext,
        variables: {
            recruitmentProcessId: queryParams.applicationId
        }
    })

    const basicEventSessionChoice = useQueryContext("BasicEventSessionChoice");
    const [basicEventSessionChoiceResponse,]= useQuery({
        query: BasicEventSessionChoiceDocument,
        context: basicEventSessionChoice,
        variables: {
            emailIdentifier: queryParams.emailIdentifier,
        }
    })

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            registerCandidateForEventSession({
                emailIdentifier: queryParams.emailIdentifier,
                recruitmentProcessId: queryParams.applicationId,
                eventSessionId: validValues.sessionId
            })
        } catch (error) {
            toastError('global.error')
        }
    }

    useEffect(() => {
        handleResponse(registerCandidateForEventSessionResponse, {
            onFetching: () => {
                dispatch({
                    name: 'SetIsSubmitting',
                    isSubmitting: true
                })
            },
            onData: () => {
                if (!state.isLoading && state.mode.name === 'loaded') {
                    dispatch({
                        name: 'SetSuccess',
                        payload: {
                            eventDisplayName: state.mode.unreservedEvent.displayName,
                            candidateName: state.mode.candidate.name,
                            companyName: state.mode.unreservedEvent.company.companyName
                        }
                    })
                }
            },
            onError: () => {
                if (!state.isLoading && state.mode.name === 'loaded') {
                    const { candidate, unreservedEvent } = state.mode
                    dispatch({
                        name: 'SetError',
                        payload: {
                            contactEmail: candidate.applicationEmail,
                            candidateName: candidate.name,
                            companyName: unreservedEvent.company.companyName,
                            eventDisplayName: unreservedEvent.displayName
                        }
                    })
                }
            }
        })
    }, [registerCandidateForEventSessionResponse])

    useEffect(() => {
        const unreservedEvent = unreservedEventResponse.data?.unreservedEvent
        const candidate = candidateResponse.data?.basicCandidate

        if (hasValue(unreservedEvent) && hasValue(candidate) && hasValue(basicEventSessionChoiceResponse)) {
            form.reset(value.Encoder.defaultValues(basicEventSessionChoiceResponse.data?.basicEventSessionChoice.sessionId))

            dispatch({
                name: 'UpdateUnreservedEvent',
                payload: {
                    unreservedEvent: unreservedEvent,
                    slots: unreservedEvent.sessions.map(session => {
                        const label = <div className={styles.optionsContainer}>
                            <Trans text={`${dateFormat.simpleDate(new Date(session.startAt), "/", currentLanguage)} ${dateFormat.simpleTime(new Date(session.startAt))} ~ ${dateFormat.simpleTime(new Date(session.endAt))}`} />
                            {unreservedEvent.isOnline
                                ? <span>{t('eventRegistration.online')}</span>
                                : <div className={styles.locationsContainer}>
                                    {session.groups.map(group => {
                                        const location = group.location.length > characterLimit
                                            ? `${group.location.substring(0, characterLimit)}...`
                                            : group.location;

                                        return (
                                            <div key={group.id} className={styles.groupLocationContainer}>
                                                <Icon name='location' className={styles.locationIcon} />
                                                <span>{location}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>

                        return {
                            value: session.id,
                            label,
                            disabled: false
                        }
                    }),
                    candidate,
                    canSubmit: !hasValue(basicEventSessionChoiceResponse.data?.basicEventSessionChoice.sessionId)
                }
            });
        }

        if (responseHasError(unreservedEventResponse)
            || responseHasError(candidateResponse)
            || responseHasError(basicEventSessionChoiceResponse)
        ) {
            dispatch({
                name: 'SetError',
                payload: {
                    contactEmail: candidateResponse.data?.basicCandidate.applicationEmail,
                    candidateName: candidateResponse.data?.basicCandidate.name,
                    companyName: unreservedEventResponse.data?.unreservedEvent.company.companyName,
                    eventDisplayName: unreservedEventResponse.data?.unreservedEvent.displayName
                }
            })
        }
    }, [
        unreservedEventResponse,
        candidateResponse,
        basicEventSessionChoiceResponse,
    ]);



    return {
        state,
        onSubmit: handleSubmit(onSubmit),
        form
    }
}

export {
    useEventRegistrationPage
}