import { JobPositions_AllDocument } from '@entities';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { handleResponse, useQueryContext } from '@helpers/unsorted/urqlExtra';
import { JobPosition } from '@routes/jobPosition';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import { JobListPanelState, initialState, reducer } from './reducer';
import { useJobListPanelContext } from './useJobListPanelContext';

interface JobListPanelHookType {
    openJobPosition: (jobId: string) => void;
    getPageCount: (totalRows: number) => number;
    onChangePage: (currentPage: number) => void;
    onSubmitSearch: (keyword: string) => void;
    state: JobListPanelState;
}

const ROW_PER_LIST = 9; // TODO: This is just an arbitrary number. Update this

const useJobListPanel = (
    isJobListPanelDisplayed: boolean,
    currentJobId?: string,
    onSelectNewJobPosition?: VoidFunction,
): JobListPanelHookType => {
    const navigate = useNavigate();

    const { error: toastError } = useToast();

    const [state, dispatch] = useReducer(reducer, initialState);

    const { hideJobListPanel } = useJobListPanelContext()

    const jobPositionListQueryContext = useQueryContext('JobPosition');
    const [jobPositionListResponse,] = useQuery({
        query: JobPositions_AllDocument,
        context: jobPositionListQueryContext,
        variables: {
            offset: ROW_PER_LIST * (state.currentPage - 1),
            first: ROW_PER_LIST,
            jobName: state.search || undefined,
            sortType: 'PUBLIC_ID',
            order: 'asc'
        },
        pause: !isJobListPanelDisplayed,
    });

    const onSubmitSearch = (keyword: string) => {
        dispatch({
            name: 'SetQueryParams',
            search: keyword,
            currentPage: 1,
        });
    };

    const openJobPosition = (jobId: string) => {
        hideJobListPanel();

        if (currentJobId !== jobId) {
            dispatch({ name: 'Reset' });

            // TODO: Add logic for showing the warning modal if the user has unsaved changes
            onSelectNewJobPosition && onSelectNewJobPosition();

            navigate(`${JobPosition.PATH_NAME}/${jobId}`);
        }
    };

    const onChangePage = (currentPage: number) => dispatch({
        name: 'SetQueryParams',
        search: state.search,
        currentPage,
    });

    useEffect(() => {
        handleResponse(
            jobPositionListResponse,
            {
                onData: (data) => dispatch({
                    name: 'UpdateJobPositions',
                    totalRows: data.jobPositions.totalCount,
                    jobPositions: data.jobPositions.edges,
                    clientHasNoJobPosition: data.jobPositions.clientHasNoJobPosition,
                }),
                onError: (_error) => {
                    toastError('global.error');
                    dispatch({
                        name: 'UpdateJobPositions',
                        totalRows: 0,
                        jobPositions: [],
                        clientHasNoJobPosition: true,
                    });
                }
            }
        )
    }, [jobPositionListResponse]);

    return {
        openJobPosition,
        getPageCount: (totalRows: number) => Math.ceil(totalRows / ROW_PER_LIST),
        onChangePage,
        onSubmitSearch,
        state,
    };
};

export {
    useJobListPanel
};
