import { FunctionComponent } from "react";
import { useCSVImportImportStep } from "./hook";
import { CSVImportImportStepInProgess } from "./inProgess/CSVImportImportStepInProgess";
import { CSVImportImportStepSuccess } from "./success/CSVImportImportStepSuccess";
import { CSVImportImportStepFailure } from "./failure/CSVImportImportStepFailure";

interface Props {
    totalCount: number;
}

const CSVImportImportStep: FunctionComponent<Props> = ({
    totalCount
}) => {
    const { mode, goToInProgress } = useCSVImportImportStep()

    return mode === 'inProgress'
        ? <CSVImportImportStepInProgess
            totalCount={totalCount}
        />
        : mode === 'success'
            ? <CSVImportImportStepSuccess totalCount={totalCount} />
            : <CSVImportImportStepFailure goToInProgress={goToInProgress} />
}

export {
    CSVImportImportStep
}