import { JobPosition_OneByIdWithStepEventsQuery, UpdateRecruitmentStepEvaluationFormDocument } from "@entities";
import { isDefined } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { Entity } from "@typedefs/graphql";
import { FormEventHandler, useEffect, useState } from "react";
import { SubmitHandler, UseFormReturn, useForm, useWatch } from "react-hook-form";
import { useMutation } from "urql";
import * as value from './value';

type InternalMode = 'show' | 'edit'

type ShowMode = {
    name: 'show',
    payload: {
        questions: string;
    }
}

type EditMode = {
    name: 'edit',
    payload: {
        form: UseFormReturn<value.Encoder.EncoderType>;
    }
}

interface EvaluationFormUpdateMode {
    name: 'updateEvaluationForm',
    payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
        questions?: string | null;
        isModifiable: boolean;
    }
}

type Mode = ShowMode | EditMode

interface EvaluationFormUpdateModalHookType {
    step?: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
    mode: Mode;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    canSave: boolean;
    isFormModifiable: boolean;
    moveToEdit: VoidFunction;
    moveToShow: VoidFunction;
    closeConfirmationModal: VoidFunction;
    discardAndClose: VoidFunction;
    saveAndExit: VoidFunction;
    showWarningModal: boolean;
}

const useEvaluationFormUpdateModal = (
    openEvaluationFormUpdateModal: (payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        questions?: string | null,
        isModifiable: boolean
    }) => void,
    closeModal: VoidFunction,
    evaluationFormMode?: EvaluationFormUpdateMode
): EvaluationFormUpdateModalHookType => {
    const payload = evaluationFormMode?.name === 'updateEvaluationForm' ? evaluationFormMode.payload : undefined

    const isFormModifiable = payload?.isModifiable

    const getInitialMode = (): InternalMode => payload?.questions ? 'show' : 'edit'

    const [internalMode, setInternalMode] = useState<InternalMode>(getInitialMode())

    const { success: toastSuccess, error: toastError } = useToast();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showWarningModal, setShowWarningModal] = useState(false);

    const defaultValues = value.Encoder.defaultValues(payload?.questions)

    const [saveEvaluationFormResponse, saveEvaluationForm] = useMutation(UpdateRecruitmentStepEvaluationFormDocument);

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: defaultValues
    });

    const { reset, control, handleSubmit, formState: { isDirty } } = form

    const currentQuestions = useWatch({
        control,
        name: 'questions'
    })

    const moveToEdit = () => setInternalMode('edit')

    const moveToShow = () => {
        if (isDirty) {
            setShowWarningModal(true)
        } else {
            if (payload?.step && payload.step.evaluationForm && payload.step.evaluationForm.questions) {
                setInternalMode('show')
            }

            reset(defaultValues)
            closeModal()
        }
    }

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)

            if (payload?.step) {
                saveEvaluationForm({
                    stepId: payload.step.id,
                    questions: validValues.questions
                }, getQueryContext('JobPosition'))
            }

        } catch (error) {
            toastError('global.error')
        }
    }

    const submit = handleSubmit(onSubmit)

    const closeConfirmationModal = () => setShowWarningModal(false)

    const discardAndClose = () => {
        reset(defaultValues)
        setShowWarningModal(false)
        closeModal()
    }

    const saveAndExit = () => {
        closeConfirmationModal()
        submit()
    }

    useEffect(() => {
        setInternalMode(getInitialMode())
        reset(defaultValues)
    }, [payload])

    useEffect(() => {
        handleResponse(saveEvaluationFormResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                if (data.updateStepEvaluationForm) {
                    setIsSubmitting(false)
                    toastSuccess('jobPositions.evaluationFormUpdateModal.success')
                    if (payload?.step) {
                        openEvaluationFormUpdateModal({
                            step: payload.step,
                            questions: currentQuestions,
                            isModifiable: payload.isModifiable,
                        })
                    }
                }
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('jobPositions.evaluationFormUpdateModal.error')
            }
        })

    }, [saveEvaluationFormResponse]);

    return {
        step: payload?.step,
        mode: internalMode === 'show' ? {
            name: 'show',
            payload: {
                questions: payload?.questions || "",
            }
        } : {
            name: 'edit',
            payload: {
                form
            }
        },
        onSubmit: submit,
        isSubmitting,
        isFormModifiable: isDefined(isFormModifiable) ? isFormModifiable : true,
        canSave: !!currentQuestions && isDirty,
        moveToEdit,
        moveToShow,
        showWarningModal,
        closeConfirmationModal,
        discardAndClose,
        saveAndExit,
    }
}

export {
    EvaluationFormUpdateMode, useEvaluationFormUpdateModal
};
