import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        searchKeyword: string;
    }

    export const defaultValues = (keyword?: string | null): EncoderType => {
        return {
            searchKeyword: keyword || '',
        };
    }
}

namespace Decoder {
    export const schema = z.object({ searchKeyword: z.string() });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Decoder,
    Encoder
};

