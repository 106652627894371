import { FormEventHandler, useEffect, useRef, useState } from "react"

import * as value from './value'
import { EvaluateApplicationPayload } from "../reducer";
import { SubmitHandler, UseFormReturn, useForm, useWatch } from "react-hook-form";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useMutation } from "urql";
import { UpdateStepEvaluationDocument } from "@entities";
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { hasValue } from "@helpers/core/typeGuards";

interface ApplicationEvaluationModalHookType {
    isSubmitting: boolean;
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    currentEvaluationResponses: value.Encoder.EncoderType['evaluationResponses'];
    saveDraft: () => Promise<void>;
    canSaveDraft: boolean;
    canSubmit: boolean;
    showCancelConfirmation: boolean;
    onCancel: VoidFunction;
    onCancelReject: VoidFunction;
    onCancelConfirm: VoidFunction;
    showSubmitConfirmation: boolean;
    onSubmitReject: VoidFunction;
    onSubmitConfirm: VoidFunction;
}

const useApplicationEvaluationModal = (closeModal: VoidFunction, evaluationResponses?: EvaluateApplicationPayload): ApplicationEvaluationModalHookType => {
    const [ isSubmitting, setIsSubmitting] = useState(false)

    const [ showCancelConfirmation, setShowCancelConfirmation ] = useState(false)

    const [ showSubmitConfirmation, setShowSubmitConfirmation ] = useState(false)

    const { success: toastSuccess, error: toastError } = useToast();

    const formData = useRef<{
        clientId: string,
        stepId: string,
        recruitmentProcessId: string,
        isDraft: boolean,
        responses: value.Decoder.DecoderType
    }>()

    const defaultValues = value.Encoder.defaultValues(evaluationResponses?.responses || [])

    const [saveEvaluationResponse, saveEvaluation] = useMutation(UpdateStepEvaluationDocument)

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: defaultValues,
    });

    const { reset, control, handleSubmit, formState: { isDirty } } = form

    const currentEvaluationResponses = useWatch({
        control,
        name: 'evaluationResponses'
    })

    const canSubmit = currentEvaluationResponses.reduce((allQuestionsAnswered, { response }) => allQuestionsAnswered && response !== "", true)

    const canSaveDraft = isDirty

    const onCancel = () => {
        if(canSaveDraft) {
            setShowCancelConfirmation(true)

            return
        }
        close()
    }

    const onCancelReject = () => {
        setShowCancelConfirmation(false)
    }

    const onCancelConfirm = () => {
        setShowCancelConfirmation(false)
        close()
    }

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data.evaluationResponses)

            if(evaluationResponses) {
                formData.current = {
                    clientId: evaluationResponses.clientId,
                    stepId: evaluationResponses.stepId,
                    recruitmentProcessId: evaluationResponses.applicationId,
                    isDraft: false,
                    responses: validValues
                }

                setShowSubmitConfirmation(true)
            }
        } catch (error) {
            toastError('global.error')
        }
    }

    const onSubmitReject = () => {
        setShowSubmitConfirmation(false)
    }

    const onSubmitConfirm = () => {
        if (hasValue(formData.current)) {
            const { clientId, stepId, recruitmentProcessId, isDraft, responses } = formData.current;

            saveEvaluation({
                clientId,
                stepId,
                recruitmentProcessId,
                isDraft,
                responses,
            }, getQueryContext('RecruitmentProcess'))

            setShowSubmitConfirmation(false)
        }
    }

    const saveDraft = async () => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(currentEvaluationResponses)

            if(evaluationResponses) {
                saveEvaluation({
                    clientId: evaluationResponses.clientId,
                    stepId: evaluationResponses.stepId,
                    recruitmentProcessId: evaluationResponses.applicationId,
                    isDraft: true,
                    responses: validValues
                }, getQueryContext('RecruitmentProcess'))
            }
        } catch (error) {
            toastError('global.error')
        }
    }

    const close = () => {
        reset(defaultValues)
        closeModal()
    }

    useEffect(() => {
        handleResponse(saveEvaluationResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: (data) => {
                setIsSubmitting(false)
                if(data.updateStepEvaluation) {
                    toastSuccess("applications.panel.evaluateApplication.success")
                    closeModal()

                    return 
                }
                toastError("applications.panel.evaluateApplication.error")
            },
            onError: () => {
                toastError("global.error")
            },
        });
    }, [saveEvaluationResponse]);

    useEffect(() => {
        reset(defaultValues)
    }, [evaluationResponses])

    return {
        isSubmitting,
        form,
        onSubmit: handleSubmit(onSubmit),
        currentEvaluationResponses,
        canSaveDraft,
        saveDraft,
        canSubmit,
        showCancelConfirmation,
        onCancel,
        onCancelReject,
        onCancelConfirm,
        showSubmitConfirmation,
        onSubmitReject,
        onSubmitConfirm,
    }
}

export {
    useApplicationEvaluationModal
}