import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { useManualInput } from "./hook";
import { Controller, FormProvider } from "react-hook-form";
import { Select } from "@shared/unsorted/Select/Select";
import { Input } from "@shared/unsorted/Input/Input";
import { validations } from "@helpers/unsorted/validation";
import { DatePicker } from "@shared/unsorted/DatePicker/DatePicker";
import { Button } from "@shared/unsorted/Button/Button";
import { ApplicationInformationSubForm } from "@shared/application/ApplicationInformationSubForm/ApplicationInformationSubForm";
import { Loader } from "@shared/unsorted/Loader/Loader";

const styles = {
    emptyContainer: Cn.c("flex justify-center items-center h-full"),
    container: Cn.c("px-6 h-full"),
    section: Cn.c("py-6"),
    header: Cn.c("font-paragraph-base-semi-bold text-emphasized"),
    row: Cn.c('flex justify-between space-x-4 pt-6'),
    footer: Cn.c('flex justify-end space-x-4 pt-6'),
}

const ManuallyAddCandidateForm: FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        monthOptions,
        yearOptions,
        genderOptions,
        form,
        onSubmit,
        isSubmitting,
        state,
        onSubFormChange,
    } = useManualInput();

    const { control, formState: { errors, isDirty }, clearErrors } = form;

    return state.isLoading
        ? <div className={styles.emptyContainer}><Loader variant="primaryDefault" size="lg" /></div>
        : (
            <form className={styles.container} onSubmit={onSubmit}>
                <div className={Cn.join([styles.section, Cn.c("border-b border-b-default")])}>
                    <p className={styles.header}>{t("applications.create.sectionHeader.candidateInformation")}</p>
                    <div className={styles.row}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    required
                                    name={name}
                                    label={"applications.create.manualInput.label.name"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("name")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                        <Controller
                            name="nameKana"
                            control={control}
                            rules={{ pattern: validations.katakana }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    name={name}
                                    label={"applications.create.manualInput.label.nameKana"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("nameKana")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true, pattern: validations.email }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    required
                                    name={name}
                                    label={"applications.create.manualInput.label.email"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("email")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                        <Controller
                            name="mobilePhoneNumber"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    name={name}
                                    label={"applications.create.manualInput.label.phoneNumber"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("mobilePhoneNumber")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="graduationYear"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    name={name}
                                    label={"applications.create.manualInput.label.graduationYear"}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("graduationYear")}
                                    forwardedRef={ref}
                                    value={value}
                                    options={yearOptions}
                                />
                            )}
                        />
                        <Controller
                            name="graduationMonth"
                            control={control}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    label={"applications.create.manualInput.label.graduationMonth"}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("graduationMonth")}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    options={monthOptions}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name="dateOfBirth"
                            control={control}
                            rules={{
                                validate: {
                                    wrongDate: value => validations.validDate(value, true),
                                }
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <DatePicker
                                    name={name}
                                    label="applications.create.manualInput.label.dateOfBirth"
                                    orientation="vertical"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={() => clearErrors("dateOfBirth")}
                                    value={value}
                                    forwardedRef={ref}
                                />
                            )}
                        />
                        <Controller
                            name="gender"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Select
                                    label={"applications.create.manualInput.label.gender"}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("gender")}
                                    forwardedRef={ref}
                                    value={value}
                                    errors={errors}
                                    options={genderOptions}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.section}>
                    <p className={styles.header}>{t("applications.create.sectionHeader.applicationInformation")}</p>
                    <FormProvider {...form}>
                        <ApplicationInformationSubForm
                            jobPositions={state.data.jobPositions}
                            entryCategories={state.data.entryCategories}
                            acquisitionCategories={state.data.acquisitionCategories}
                            recruitmentSteps={state.data.recruitmentSteps}
                            onChange={onSubFormChange}
                        />
                    </FormProvider>
                </div>
                <div className={styles.footer}>
                    <Button
                        type="submit"
                        size="md"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >{t("applications.create.buttons.add")}</Button>
                </div>
            </form>
        );
}

export {
    ManuallyAddCandidateForm,
}