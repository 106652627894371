import { FormProps } from '@typedefs/props';
import { MouseEventHandler, ReactNode, useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

import { hasValue, isDefined } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';

import { Cn } from '@helpers/unsorted/classNames';

const selectSize = ["small", "medium", "large", "x-large"] as const;

type Size = typeof selectSize[number];

const styles = {
    root: Cn.c('w-full'),
    input: (disabled: boolean, state: State, size: Size, error?: string, warnMessage?: string) => {
        let sizeStyles;

        switch (size) {
            case "small":
                sizeStyles = Cn.c("py-2 px-3 h-8 font-paragraph-xsmall-regular")
                break;
            case "medium":
                sizeStyles = Cn.c("p-4 h-10 font-paragraph-small-regular")
                break;
            case "large":
                sizeStyles = Cn.c("p-4 h-12 font-paragraph-base-regular")
                break;
            case "x-large":
                sizeStyles = Cn.c("py-4 px-6 h-16 font-paragraph-base-regular")
                break;
        }

        return Cn.join([
            Cn.c("border border-default rounded-lg bg-surface-default truncate overflow-ellipsis cursor-pointer w-full text-emphasized placeholder-default flex items-center justify-start"),
            sizeStyles,
            Cn.ifTrue(isDefined(warnMessage), Cn.c('border-warning-default mb-1')),
            Cn.ifTrue(isDefined(error), Cn.c('border-critical-default mb-1')),

            Cn.ifFalse(isDefined(error) || isDefined(warnMessage), Cn.join([
                Cn.ifTrue(state === 'highlighted', Cn.c('border-primary-default')),
                Cn.ifTrue(state === 'normal', Cn.c('border-default')),
            ])),
            Cn.ifTrue(disabled, Cn.c('bg-surface-disabled')),
        ]);
    },
    error: Cn.c('text-critical-default font-paragraph-xsmall-regular'),
    warning: Cn.c('text-warning-default font-paragraph-xsmall-regular'),
    placeholder: (isDisabled: boolean, placeholderClassName?: string) => 
        isDisabled ? Cn.c('text-disabled') : Cn.join([Cn.c('text-placeholder'), Cn.getSome(placeholderClassName)]),
};

type State = 'highlighted' | 'normal';

interface Props<FieldsType extends FieldValues> extends FormProps<FieldsType> {
    label?: I18nKey;
    className?: string;
    pseudoInputClassName?: string;
    disabled?: boolean;
    placeholder?: I18nKey;
    rawPlaceholder?: string;
    onClick?: MouseEventHandler;
    errorLabel?: I18nKey;
    state?: State;
    title?: string;
    text?: string;
    size?: Size;
    warnValidator?: (value: string | undefined) => I18nKey | undefined;
    placeholderClassName?: string;
}

// @ocaml.doc("A PseudoInput looks and feels like an input but is not an input.
// It's useful for complex, composed, inputs like MultiSelect for instance,
// when the input related behavior (value, onChange, etc...) is handled by another
// component and the UI is delegated to this component.
//
// - `name`: name of the input
// - `orientation`: [#horizontal | #vertical] - direction between label and pseudo input
// - `required`: bool - show the * for required pseudo input - false by default
// - `label`: optional - the label to display for the pseudo input
// - `className`: optional - class to apply
// - `pseudoInputClassName`: optional - class to apply to the pseudo input
// - `labelClassName`: optional - class to apply to the label
// - `disabled`: optional - is the pseudo input disabled or not
// - `placeholder`: optional - placeholder for the pseudo input
// - `onClick`: optional - onClick callback
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `state`: optional - the pseudo input looks (defaults to normal)
// - `title`: optional - an HTML title
// - `text`: optional - text to display, if not provided the placeholder is used, if empty an empty string is displayed
// - `placeholderClassName`: optional - class to apply to the placeholder when the pseudo input is enabled
// ")
const PseudoInput = <FieldsType extends FieldValues>(
    {
        name,
        label,
        className,
        pseudoInputClassName,
        disabled = false,
        placeholder,
        rawPlaceholder,
        onClick,
        errors,
        errorLabel,
        state = 'normal',
        title,
        text,
        size = 'medium',
        warnValidator,
        placeholderClassName,
    }: Props<FieldsType>,
) => {
    const { t } = useTranslation();

    const errorFieldName = isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name;
    
    const displayedPlaceholder = isDefined(rawPlaceholder)
        ? rawPlaceholder
        : isDefined(placeholder)
            ? t(placeholder)
            : undefined;

    const error = useErrorMessage(
        name,
        errors,
        errorFieldName,
    );

    const [warnMessage, setWarnMessage] = useState<string | undefined>(undefined);

    const textValue = () => {
        if (isDefined(text) && text.length > 0) {
            return text;
        } else if (isDefined(displayedPlaceholder)) {
            return <p className={styles.placeholder(disabled, placeholderClassName)}>{displayedPlaceholder}</p>;
        }

        return '';
    };

    useEffect(() => {
        if (isDefined(warnValidator)) {
            const result = warnValidator(text);
            setWarnMessage(result ? t(result, { fieldName: errorFieldName }) : undefined)
        }
    }, [text])

    return (
        <div className={Cn.join([styles.root, Cn.getSome(className)])} onClick={onClick}>
            <div
                className={Cn.join([
                    styles.input(disabled, state, size, error, warnMessage),
                    Cn.getSome(pseudoInputClassName),
                ])}
                placeholder={displayedPlaceholder}
                title={title}
            >
                {textValue()}
            </div>
            {hasValue(error) && <div className={styles.error}>{error}</div>}
            {hasValue(warnMessage) && <div className={styles.warning}>{warnMessage}</div>}
        </div>
    );
};

export {
    PseudoInput,
    selectSize,
    Size,
};
