import { useCallback, useState } from "react";
import { Stepper, Props as StepperProps } from "./Stepper";
import { StepperContext } from "./StepperContext";

const useStepper = (defaultStep?: string) => {
    const [currentStepId, setCurrentStepId] = useState(defaultStep);

    const goToStep = (stepId: string) => setCurrentStepId(stepId);

    const ControlledStepper = useCallback((props: StepperProps) => {
        return (
            <StepperContext.Provider value={{ currentStepId, setCurrentStepId, goToStep }}>
                <Stepper {...props}>
                    {props.children}
                </Stepper>
            </StepperContext.Provider>

        )
    }, [currentStepId])

    return { currentStepId, goToStep, ControlledStepper };
}

export { useStepper };