import { FunctionComponent, ReactElement } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';

const styles = {
    root: Cn.c('py-8 flex gap-x-4 justify-between items-center'),
    title: Cn.c('font-h3-bold text-emphasized mb-2'),
    subtitle: Cn.c('font-paragraph-small-regular text-subdued'),
};

interface Props {
    title: I18nKey;
    subtitle?: I18nKey;
    action?: ReactElement;
}

// @ocaml.doc("The reusable page header component.")
const TabHeader: FunctionComponent<Props> = ({ title, subtitle, action }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div>
                <p className={styles.title}>{t(title)}</p>
                {subtitle && <p className={styles.subtitle}>{t(subtitle)}</p>}
            </div>
            <div>{action}</div>
        </div>
    );
};

export {
    TabHeader
};

