import { CurrentClientDocument, CurrentClientQuery } from '@entities'
import { Entity } from '@typedefs/graphql';
import { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'urql'

import { useQueryContext } from '../../unsorted/urqlExtra';
import { ClientContext } from '../../../contexts/ClientContext'
import auth from '../../../helpers/core/auth'

interface Loading {
    clientState: 'loading'
}

interface LoggedOut {
    clientState: 'loggedOut'
}

type ClientRoles = {
    isExaminer: boolean;
    isCoordinator: boolean;
    isAdmin: boolean;
    isScovilleUser: boolean;
    isScovilleAdmin: boolean;
};

type ClientInfo = NonNullable<CurrentClientQuery['currentClient']> & ClientRoles;

interface LoggedIn {
    clientState: 'loggedIn'
    clientInfo: ClientInfo;
}

type Client = Loading | LoggedOut | LoggedIn;

const extractClientRoles = (roles: Entity<CurrentClientQuery, 'currentClient.roles'>[]): ClientRoles => {
    const isExaminer = roles.some(role => role.name === 'Examiner');
    const isCoordinator = roles.some(role => role.name === 'Coordinator');
    const isAdmin = roles.some(role => role.name === 'Admin');
    const isScovilleUser = roles.some(role => role.name === 'Scoville');
    const isScovilleAdmin = roles.some(role => role.name === 'ScovilleAdmin');

    return {
        isExaminer,
        isCoordinator,
        isAdmin,
        isScovilleUser,
        isScovilleAdmin,
    };
};

const useClient = () => {
    const [client, setClient] = useContext(ClientContext)

    const [{ data }, refreshCurrentClient] = useQuery({
        query: CurrentClientDocument,
        context: useQueryContext(['CurrentClient'])
    });

    useEffect(() => {
        if (data) {
            if (data.currentClient) {
                setClient(data)
            } else {
                auth.logout() //TODO: discuss with team either we redirect user to login page or we show a login button
            }
        }
    }, [data])

    const currentClient = useMemo<Client>(() => {
        if (client.currentClient) {
            return {
                clientState: 'loggedIn',
                clientInfo: {
                    ...client.currentClient,
                    ...extractClientRoles(client.currentClient.roles),
                },
            }
        }

        if (!data) {
            return {
                clientState: 'loading'
            }
        }

        if (data.currentClient) {
            return {
                clientState: 'loggedIn',
                clientInfo: {
                    ...data.currentClient,
                    ...extractClientRoles(data.currentClient.roles),
                },
            }
        } else {
            return {
                clientState: 'loggedOut'
            }
        }

    }, [client.currentClient, data]);

    return {
        currentClient,
        refreshCurrentClient,
    };
}

export {
    extractClientRoles,
    useClient,
    Client,
    ClientInfo
};
