import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-critical-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-critical-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-critical-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    buttons: Cn.c("mt-6 flex space-x-3 justify-end px-6")
}


interface Props extends ModalProps {
    closeModal: VoidFunction
    onRetry: VoidFunction
}

const CSVExportErrorModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    onRetry,
}) => {
    const { t } = useTranslation()
    
    return (
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t('csvExportErrorModal.description')}</p>
                    <p className={styles.subDescription}>{t('csvExportErrorModal.subDescription')}</p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={closeModal}
                        isFull={true}
                    >
                        {t('global.dismiss')}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        onClick={() => {
                            onRetry();
                            closeModal();
                        }}
                        isFull={true}
                    >
                        {t('global.retry')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export {
    CSVExportErrorModal
}