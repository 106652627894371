import { Cn } from "@helpers/unsorted/classNames";
import { Input } from "@shared/unsorted/Input/Input";
import { PasswordInput } from "@shared/unsorted/PasswordInput/PasswordInput";
import { validations } from "@helpers/unsorted/validation";
import { FunctionComponent, FormEventHandler } from "react";
import { UseFormReturn, Controller } from "react-hook-form";
import { useAccountForm } from "./hooks";
import { Encoder } from "./value";

type Props = {
    formId: string;
    form: UseFormReturn<Encoder.Type>;
    onSubmit: FormEventHandler<HTMLFormElement>;
};

const styles = {
    sectionHeader: Cn.c("mb-8"),
    sectionHeading: Cn.c("font-h4-bold text-emphasized mb-2"),
    sectionSubheading: Cn.c("font-paragraph-small-regular text-subdued"),
    sectionDivier: Cn.c("my-10 border-default"),
    formFields: Cn.c("grid md:grid-cols-2 gap-x-6 gap-y-8"),
    passwordPatterns: Cn.c(
        "grid lg:grid-cols-[auto_1fr] list-disc list-inside gap-y-1 gap-x-2 marker:text-marker-subdued text-xs text-subdued"
    ),
    passwordPatternResult: (success: boolean) => {
        return success
            ? Cn.c("text-success-default marker:text-success-default")
            : Cn.c("text-critical-default marker:text-critical-default");
    }
};

const AccountForm: FunctionComponent<Props> = ({ formId, form, onSubmit }) => {
    const {
      t,
      oldPassword,
      newPassword,
      validateNewPassword,
      validateNewPasswordConfirm
    } = useAccountForm({ form });

    return (
        <form id={formId} onSubmit={onSubmit}>
            <section>
                <div className={styles.formFields}>
                    <Controller
                        name="lastName"
                        control={form.control}
                        rules={{
                            required: true,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                label="settings.account.form.lastName.title"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                    <Controller
                        name="firstName"
                        control={form.control}
                        rules={{
                            required: true,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                label="settings.account.form.firstName.title"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                    <Controller
                        name="lastNameKana"
                        control={form.control}
                        rules={{
                            required: true,
                            pattern: validations.katakana,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                label="settings.account.form.lastNameKana.title"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                    <Controller
                        name="firstNameKana"
                        control={form.control}
                        rules={{
                            required: true,
                            pattern: validations.katakana,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                label="settings.account.form.firstNameKana.title"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={form.control}
                        rules={{
                            required: true,
                            pattern: validations.email,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                label="settings.account.form.email.title"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                </div>
            </section>
            <hr className={styles.sectionDivier} />
            <section>
                <div className={styles.sectionHeader}>
                    <h2 className={styles.sectionHeading}>
                        {t("settings.account.section.password.heading")}
                    </h2>
                    <p className={styles.sectionSubheading}>
                        {t("settings.account.section.password.subheading")}
                    </p>
                </div>
                <div className={styles.formFields}>
                    <Controller
                        name="oldPassword"
                        control={form.control}
                        rules={{
                            required: newPassword.length > 0,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <PasswordInput
                                label="settings.account.form.oldPassword.title"
                                placeholder="settings.account.form.oldPassword.placeholder"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                    <div className="col-start-1">
                        <Controller
                            name="newPassword"
                            control={form.control}
                            rules={{
                                required: oldPassword.length > 0,
                                validate: validateNewPassword
                            }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                            }) => (
                                <PasswordInput
                                    label="settings.account.form.newPassword.title"
                                    placeholder="settings.account.form.newPassword.placeholder"
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={form.formState.errors}
                                    clearErrors={form.clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    size="medium"
                                />
                            )}
                        />
                        <ul
                            className={Cn.join([
                                "mt-2 ml-1",
                                styles.passwordPatterns,
                            ])}
                        >
                            <li
                                className={Cn.join([
                                    Cn.ifTrue(
                                        newPassword.length > 0,
                                        styles.passwordPatternResult(
                                            validations.containsNumber.test(
                                                newPassword
                                            )
                                        )
                                    ),
                                ])}
                            >
                                {t(
                                    "settings.account.form.newPassword.patterns.halfWidthNumbers"
                                )}
                            </li>
                            <li
                                className={Cn.join([
                                    Cn.ifTrue(
                                        newPassword.length > 0,
                                        styles.passwordPatternResult(
                                            validations.minimumPasswordLength.test(
                                                newPassword
                                            )
                                        )
                                    ),
                                ])}
                            >
                                {t(
                                    "settings.account.form.newPassword.patterns.minimumLength"
                                )}
                            </li>
                            <li
                                className={Cn.join([
                                    Cn.ifTrue(
                                        newPassword.length > 0,
                                        styles.passwordPatternResult(
                                            validations.containsUpperCase.test(
                                                newPassword
                                            )
                                        )
                                    ),
                                ])}
                            >
                                {t(
                                    "settings.account.form.newPassword.patterns.halfWidthUpperCaseLetters"
                                )}
                            </li>
                            <li
                                className={Cn.join([
                                    Cn.ifTrue(
                                        newPassword.length > 0,
                                        styles.passwordPatternResult(
                                            validations.containsLowerCase.test(
                                                newPassword
                                            )
                                        )
                                    ),
                                ])}
                            >
                                {t(
                                    "settings.account.form.newPassword.patterns.halfWidthLowerCaseLetters"
                                )}
                            </li>
                        </ul>
                    </div>
                    <Controller
                        name="newPasswordConfirm"
                        control={form.control}
                        rules={{
                            required: newPassword.length > 0,
                            validate: validateNewPasswordConfirm
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <PasswordInput
                                label="settings.account.form.newPasswordConfirm.title"
                                placeholder="settings.account.form.newPasswordConfirm.placeholder"
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={form.formState.errors}
                                clearErrors={form.clearErrors}
                                forwardedRef={ref}
                                value={value}
                                size="medium"
                            />
                        )}
                    />
                </div>
            </section>
        </form>
    );
};

export { AccountForm };
