import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BulkActionLoadingModalPayload } from "../reducer";
import { isOneOf } from "@helpers/core/typeGuards";
import { bulkActions, needSetupUpdateStatusBulkActions } from "@helpers/core/constants";
import { Progress } from "@shared/unsorted/Progress/Progress";

const styles = {
    container: Cn.c("p-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    content: Cn.c("mt-5 p-4 border border-subdued rounded-xl"),
    contentHeader: Cn.c("flex items-start pb-4"),
    outerCircle: Cn.c("w-9 h-9 flex items-center bg-surface-primary-subdued justify-center rounded-full"),
    progressBarContainer: Cn.c("mt-3"),
}

interface Props extends ModalProps {
    payload?: BulkActionLoadingModalPayload
}

const BulkActionLoadingModal: FunctionComponent<Props> = ({
    isOpen,
    payload
}) => {
    const { t } = useTranslation()
    const total = payload?.total || 0
    const processed = payload?.processed || 0
    const action = payload?.action

    const percentage = Math.floor((processed / total) * 100)

    return (
        <Modal
            isOpen={isOpen}
            close={() => { }}
            widthClass="w-[30rem]"
        >
            {isOneOf(bulkActions)(action) &&
                <div className={styles.container}>
                    <p className={styles.description}>{t(`applications.bulkAction.${action}.title`)}</p>
                    <div className={styles.content}>
                        <p className={styles.subDescription}>
                            {t('applications.bulkActionLoading.processCandidates', { total, processed })}
                        </p>
                        <div className={styles.progressBarContainer}>
                            <Progress
                                progressValue={percentage}
                                showPercentage
                            />
                        </div>
                    </div>
                </div>
            }
        </Modal>
    );
}

export {
    BulkActionLoadingModal
}