import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RelinquishedPermissionsState } from '../hook';

const styles = {
    title: Cn.c('font-h3-bold text-emphasized px-6 py-4 border-b border-b-default'),
    body: Cn.c('p-6 space-y-4'),
    description: Cn.c('font-paragraph-small-regular text-default'),
    permissions: Cn.c('list-disc list-inside font-paragraph-small-regular pl-2'),
    buttons: Cn.c('flex flex-row space-x-4 justify-end px-6 py-4 bg-surface-subdued'),
    invisiblePermission: Cn.c('font-paragraph-small-bold text-critical-default'),
};

interface Props extends ModalProps {
    onReject: VoidFunction;
    onConfirm: VoidFunction;
    relinquishedPermissionsState: RelinquishedPermissionsState;
}

// @ocaml.doc(
//   "The modal component that asks for confirmation to relinquish administrative permissions."
// )
const UserRelinquishPermissionConfirmModal: FunctionComponent<Props> = ({ isOpen, onReject, onConfirm, relinquishedPermissionsState }) => {
    const { t } = useTranslation();

    const { relinquishedRoles, lostPermissions } = relinquishedPermissionsState;

    return (
        <Modal close={() => { return }} isOpen={isOpen}>
            <div className={styles.title}>{t("users.edit.confirmation.title")}</div>
            <div className={styles.body}>
                <p className={styles.description}>
                    {t("users.edit.confirmation.description.part1", { roles: relinquishedRoles.map(({ name }) => `"${t(`users.role.${name}`)}"`) })}
                </p>
                <p className={styles.description}>
                    {t("users.edit.confirmation.description.part2")}
                </p>
                <ul className={styles.permissions}>
                    {lostPermissions.map(permission =>
                        <li key={permission} className={permission === 'INVISIBLE' ? styles.invisiblePermission : undefined}>
                            {t(`users.edit.confirmation.warning.${permission}`)}
                        </li>
                    )}
                </ul>
                <p className={styles.description}>
                    {t("users.edit.confirmation.question")}
                </p>
            </div>
            <div className={styles.buttons}>
                <Button size="md" variant="secondary" onClick={onReject}>
                    {t("global.cancel")}
                </Button>
                <Button size="md" isLoading={false} variant="destructiveFilled" onClick={onConfirm}>
                    {t("users.edit.buttons.relinquish")}
                </Button>
            </div>
        </Modal>
    );
};

export {
    UserRelinquishPermissionConfirmModal,
};
