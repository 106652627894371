import { RemoveApplicationFromEventSessionGroupDocument } from "@entities";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { useEffect, useState } from "react"
import { useMutation } from "urql";
import { SubMode as EventSessionGroupManipulateCandidateSubMode } from "../EventSessionGroupManipulateCandidateModal/hook";

interface EventSessionGroupRemoveCandidateAssignmentModalHookType {
    isSendEmailChecked: boolean;
    isSubmitting: boolean;
    toggleCheckSendEmail: VoidFunction;
    onSubmit: VoidFunction;
}

const useEventSessionGroupRemoveCandidateAssignmentModal = (closeModal: VoidFunction, subMode: EventSessionGroupManipulateCandidateSubMode): EventSessionGroupRemoveCandidateAssignmentModalHookType => {
    const [isSendEmailChecked, setIsSendEmailChecked] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const { error: toastError, success: toastSuccess } = useToast()

    const toggleCheckSendEmail = () => setIsSendEmailChecked(current => !current)

    const [deleteGroupCandidatesResponse, deleteGroupCandidates] = useMutation(RemoveApplicationFromEventSessionGroupDocument)

    const onSubmit = () => {
        if (subMode.name === 'remove') {
            deleteGroupCandidates({
                recruitmentProcessId: subMode.payload.recruitmentProcessId,
                stepId: subMode.payload.stepId,
                groupId: subMode.payload.groupId
            }, getQueryContext(['EventSessionGroup', 'RecruitmentProcesses', 'RecruitmentProcess']))
        }
    }

    useEffect(() => {
        handleResponse(deleteGroupCandidatesResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: (data) => {
                setIsSubmitting(false)
                if (data.removeApplicationFromEventSessionGroup) {
                    toastSuccess('event.eventSessionGroupRemoveCandidateModal.successfullyRemove')
                    closeModal()
                } else {
                    toastError('event.eventSessionGroupRemoveCandidateModal.failedToRemove')
                }
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('event.eventSessionGroupRemoveCandidateModal.failedToRemove')
            }
        })
    }, [deleteGroupCandidatesResponse])

    return {
        isSendEmailChecked,
        isSubmitting,
        toggleCheckSendEmail,
        onSubmit
    }
}

export {
    useEventSessionGroupRemoveCandidateAssignmentModal
}