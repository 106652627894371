import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        externalEmailSignature: string;
        internalEmailSignature: string;
    }

    export const defaultValues = (emailSignatures?: {
        internalEmailSignature?: string | null;
        externalEmailSignature?: string | null;
    }): EncoderType => {
        return {
            externalEmailSignature: emailSignatures?.externalEmailSignature ?? '',
            internalEmailSignature: emailSignatures?.internalEmailSignature ?? '',
        };
    };
}

namespace Decoder {
    export const schema = z.object({
        externalEmailSignature: z.string(),
        internalEmailSignature: z.string(),
    }).transform((values) => ({
        externalEmailSignature: values.externalEmailSignature || null,
        internalEmailSignature: values.internalEmailSignature || null,
    }));

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Decoder, Encoder
};
