import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';

interface ApplicationHistoryHookResult {
    historyEntries: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.history'>[],
    currentStepIndex: number,
}

const useApplicationHistory = (
    history: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.history'>[],
    currentStepId: string,
): ApplicationHistoryHookResult => {

    const historyEntries = [...history]
        .filter(historyItem => historyItem.stage !== 'ARCHIVE')
        .reverse();

    const currentStepIndex = historyEntries.findIndex(historyItem => historyItem.id === currentStepId);

    return {
        historyEntries,
        currentStepIndex,
    };
};

export {
    useApplicationHistory,
};