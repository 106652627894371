import { Cn } from "@helpers/unsorted/classNames"
import { CloseIcon } from "@shared/functional_components/CloseIcon"
import { Loader } from "@shared/unsorted/Loader/Loader"
import { FunctionComponent } from "react"

const styles = {
    container: Cn.c("flex flex-col bg-surface-default h-full"),
    header: Cn.c("px-6 py-4 flex items-center justify-between"),
    buttons: Cn.c("flex items-center justify-between space-x-5"),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
    loadingContainer: Cn.c("flex items-center justify-center flex-1"),
}

const EventDetailLoadingState: FunctionComponent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div></div>
                <div className={styles.buttons}>
                    <CloseIcon />
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.loadingContainer}>
                    <Loader
                        variant="primaryDefault"
                        size="lg" />
                </div>
            </div>
        </div>
    )
}

export {
    EventDetailLoadingState
}