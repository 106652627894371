import { Cn } from "@helpers/unsorted/classNames"
import { CloseIcon } from "@shared/functional_components/CloseIcon";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { useTranslation } from "react-i18next";
import { useJobPositionDetailViewMode } from "./hook";
import { Entity } from "@typedefs/graphql";
import { JobPosition_OneByIdWithStepEventsQuery } from "@entities";
import { FunctionComponent } from "react";
import { EmailTemplateUpdateModal } from "./EmailTemplateUpdateModal/EmailTemplateUpdateModal";
import { EvaluationFormUpdateModal } from "./EvaluationFormUpdateModal/EvaluationFormUpdateModal";
import { OpenJobListPanelButton } from "@shared/job_position/JobListPanel/OpenJobListPanelButton";
import { JobPositionDetail } from "./JobPositionDetail";

const styles = {
    container: Cn.c("flex flex-col bg-surface-default h-full"),
    header: Cn.c("px-6 py-4 flex items-center justify-between"),
    jobListContainer: Cn.c("flex content-center items-center space-x-4"),
    buttons: Cn.c("flex items-center justify-between space-x-5"),
    modalTitle: Cn.c("font-paragraph-base-medium text-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-icons-emphasized cursor-pointer"),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
}

interface Props {
    jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>
    switchToEditMode: VoidFunction
}

const JobPositionDetailViewMode: FunctionComponent<Props> = ({
    jobPosition,
    switchToEditMode,
}) => {
    const { t } = useTranslation();

    const {
        emailTemplateMode,
        openEmailTemplateUpdateModal,
        openEvaluationFormUpdateModal,
        closeSubModal,
    } = useJobPositionDetailViewMode();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.jobListContainer}>
                    <OpenJobListPanelButton />
                    <p className={styles.modalTitle}>{
                        jobPosition.title
                    }</p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        onClick={switchToEditMode}
                        variant="primaryFilled"
                        size='md'>
                        {t('global.edit')}
                    </Button>
                    <CloseIcon />
                </div>
            </div>
            <div className={styles.body}>
                <JobPositionDetail
                    jobPosition={jobPosition}
                    openEmailTemplateUpdateModal={openEmailTemplateUpdateModal}
                    openEvaluationFormUpdateModal={openEvaluationFormUpdateModal}
                />
            </div>
            <EmailTemplateUpdateModal
                isOpen={
                    emailTemplateMode?.name === 'updateFailDecisionEmailTemplate' ||
                    emailTemplateMode?.name === 'updatePassDecisionEmailTemplate' ||
                    emailTemplateMode?.name === 'updateNotificationEmailTemplate'
                }
                emailTemplateMode={emailTemplateMode?.name === 'updateFailDecisionEmailTemplate' ||
                    emailTemplateMode?.name === 'updatePassDecisionEmailTemplate' ||
                    emailTemplateMode?.name === 'updateNotificationEmailTemplate'
                    ? emailTemplateMode
                    : undefined
                }
                closeModal={closeSubModal}
            />
            <EvaluationFormUpdateModal
                isOpen={emailTemplateMode?.name === 'updateEvaluationForm'}
                evaluationFormMode={emailTemplateMode?.name === 'updateEvaluationForm' ? emailTemplateMode : undefined}
                closeModal={closeSubModal}
                openEvaluationFormUpdateModal={openEvaluationFormUpdateModal}
            />
        </div>
    );
}

export {
    JobPositionDetailViewMode
}