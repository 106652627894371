import { AllRoless_AllRolesQuery, Client_OneByIdQuery, GetAllClients_AllClientsQuery } from '@entities';
import { Entity } from '@typedefs/graphql';

interface CreateMode {
    name: 'create';
    payload: {
        roles: Entity<AllRoless_AllRolesQuery, 'roles'>[],
    };
}

interface ShowMode {
    name: 'show';
    payload: {
        client: Entity<Client_OneByIdQuery, 'client'>,
        roles: Entity<AllRoless_AllRolesQuery, 'roles'>[],
    };
}

interface ListMode {
    name: 'list';
}

type DisplayMode = CreateMode | ShowMode | ListMode;

interface LoadingState {
    loading: true;
    displayMode: DisplayMode;
}

interface LoadedState {
    loading: false;
    clients: Entity<GetAllClients_AllClientsQuery, 'clients.edges'>[];
    totalClientsCount: number;
    displayMode: DisplayMode;
    isOnlyAdmin: boolean;
}

export type State = LoadingState | LoadedState;

interface UpdateClientsAction {
    name: 'UpdateClients';
    clients: Entity<GetAllClients_AllClientsQuery, 'clients.edges'>[];
    totalClientsCount: number;
    isOnlyAdmin: boolean;
}

interface StartLoadingAction {
    name: 'StartLoading';
}

interface SetModeAction {
    name: 'SetMode'
    displayMode: DisplayMode;
}

type Action = UpdateClientsAction | StartLoadingAction | SetModeAction;

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'UpdateClients':
            return {
                ...state,
                loading: false,
                clients: action.clients,
                totalClientsCount: action.totalClientsCount,
                isOnlyAdmin: action.isOnlyAdmin,
            };
        case 'StartLoading':
            return {
                ...state,
                loading: true,
            };
        case 'SetMode':
            return {
                ...state,
                displayMode: action.displayMode,
            };
        default: return { ...state };
    }
};

const initialState: State = {
    loading: false,
    clients: [],
    totalClientsCount: 0,
    displayMode: {
        name: 'list',
    },
    isOnlyAdmin: false,
};

export {
    reducer,
    initialState,
    DisplayMode,
    Action,
};

