import { Emails_AllQuery, RecruitmentProcess_OneByIdQuery } from "@entities"
import { Entity } from "@typedefs/graphql"
import { ApplicationStepMode } from "../reducer";

interface ApplicationCandidateEmailHookType {
    emailsHistory:  Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.emails'>[];
    applicationEmail: string;
    mapRecipients: (
        originalRecipients: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.emails.recipients'>[],
    ) => Entity<Emails_AllQuery, 'emails.recipients'>[];
    reply: (emailThreadItemId: string) => void;
}

const useApplicationCandidateEmail = (application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>, dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void): ApplicationCandidateEmailHookType => {
    const mapRecipients = (
        originalRecipients: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.emails.recipients'>[],
      ) => {
        return originalRecipients.map((originalRecipient) => 
          ({
            recipientName: originalRecipient.recipientName,
            recipientEmailAddress: originalRecipient.recipientEmailAddress,
            type: originalRecipient.type,
          })
        )
      }

    const reply = (emailThreadItemId: string) => dispatchApplicationStepMode({
        name: 'replyEmail',
        payload: {
            emailThreadItemId
        }
    })

    return {
        mapRecipients,
        reply,
        emailsHistory: application.emails,
        applicationEmail: `${application.emailAddressId}@bluum.jp`, // TODO: Update this after implementing automatic subdomain verification. Should be {emailAddressId}@{company subdomain}.bluum.jp
    }
}

export {
    useApplicationCandidateEmail
}