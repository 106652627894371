import { Props as StepperProps } from "@shared/layout/stepper/Stepper";

interface SelectSessionStep {
    name: "sessionSelection"
}

interface SelectSessionGroupStep {
    name: "sessionGroupSelection"
}

interface Settings {
    applicationIds?: string[]
    currentRecruitmentStepId?: string
    sessionId?: string
    groupId?: string
}

type Step = SelectSessionStep | SelectSessionGroupStep

interface State {
    stepTracker: StepperProps['stepTracker']
    currentStep: Step
    settings: Settings
}

interface SetStepTrackerAction {
    name: 'SetStepTracker';
    payload: {
        stepTracker: StepperProps['stepTracker'];
    }
}

interface SetStepAction {
    name: 'SetStep';
    payload: {
        step: Step;
    }
}

interface SetSettingsAction {
    name: 'SetSettings';
    payload: {
        settings: Settings;
    }
}

type Action = SetStepTrackerAction | SetStepAction | SetSettingsAction;

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'SetStepTracker':
            return {
                ...state,
                stepTracker: action.payload.stepTracker,
            }

        case 'SetStep':
            if (action.payload.step.name === 'sessionGroupSelection') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    stepTracker: [
                        { id: 'sessionSelection', label: 'applications.bulkEventSessionAssignment.sessionSelection.title', variant: 'success' },
                        { id: 'sessionGroupSelection', label: 'applications.bulkEventSessionAssignment.sessionGroupSelection.title', variant: 'selected' },
                    ]
                }
            }
            if (action.payload.step.name === 'sessionSelection') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    stepTracker: [
                        { id: 'sessionSelection', label: 'applications.bulkEventSessionAssignment.sessionSelection.title', variant: 'selected' },
                        { id: 'sessionGroupSelection', label: 'applications.bulkEventSessionAssignment.sessionGroupSelection.title', variant: 'default' },
                    ]
                }
            }

            return {
                ...state,
                currentStep: action.payload.step,
            }

        case 'SetSettings':
            return {
                ...state,
                settings: action.payload.settings,
            }

        default:
            return { ...state };
    }
}

const initialState = (currentRecruitmentStepId?: string, applicationIds?: string[]): State => ({
    stepTracker: [
        { id: 'sessionSelection', label: 'applications.bulkEventSessionAssignment.sessionSelection.title', variant: 'selected' },
        { id: 'sessionGroupSelection', label: 'applications.bulkEventSessionAssignment.sessionGroupSelection.title', variant: 'default' },
    ],
    currentStep: { name: 'sessionSelection' },
    settings: {
        currentRecruitmentStepId,
        applicationIds
    }
})

export {
    Action,
    State,
    reducer,
    initialState,
}