import { ImportEvaluationsFromCsvUrlDocument, ValidateBulkEvaluationCsvFileMutation } from "@entities"
import { Entity } from "@typedefs/graphql"
import { useState } from "react"
import { useCSVBulkEvaluationFormContext } from "../../CSVBulkEvaluationFormContext"
import { useStepperContext } from "@shared/layout/stepper/useStepperContext"
import { useToast } from "@helpers/hooks/unsorted/toastHook"
import { SelectionOption } from "@typedefs/selectOption"
import { useMutation } from "urql"
import { isDefined } from "@helpers/core/typeGuards"

const useCSVBulkEvaluationPreviewStep = (
    validationResults: Entity<ValidateBulkEvaluationCsvFileMutation, 'validateBulkEvaluationCSVFile.result'>[]
) => {
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [{ stepTracker, validCSVUrl }, dispatch] = useCSVBulkEvaluationFormContext()
    const { goToStep } = useStepperContext()

    const [_, importEvaluationCsv] = useMutation(ImportEvaluationsFromCsvUrlDocument);

    const displayRows = validationResults.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)

    const rowsPerPageOptions: SelectionOption[] = [
        {
            label: "10",
            value: "10",
            key: "10"
        },
        {
            label: "25",
            value: "25",
            key: "25"
        },
        {
            label: "50",
            value: "50",
            key: "50"
        },
    ]

    const previousStepName = stepTracker.some(step => step.id === 'errorCorrection') ? 'errorCorrection' : 'setup'

    const changeRowsPerPage = (value: string) => {
        setRowsPerPage(parseInt(value))
        setCurrentPage(1)
    }

    const backToSetup = () => {
        goToStep('setup')
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'setup' }
            }
        })
    }

    const backToErrorCorrection = () => {
        goToStep('errorCorrection')
        dispatch({
            name: 'SetStep',
            payload: {
                step: {
                    name: 'errorCorrection',
                    data: {
                        stepTracker: [
                            { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                            { id: 'errorCorrection', label: 'applications.bulkEvaluation.steps.errorCorrection', variant: 'success' },
                            { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'default' },
                            { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
                        ],
                        resetCSVFile: false
                    }
                }
            }
        })
    }


    const backToPreviousStep = () => {
        if (previousStepName === 'errorCorrection') {
            backToErrorCorrection()
        } else {
            backToSetup()
        }
    }

    const startImportingCSV = () => {
        if (isDefined(validCSVUrl)) {
            importEvaluationCsv({
                fileUrl: validCSVUrl,
            });

            dispatch({
                name: 'SetStep',
                payload: {
                    step: {
                        name: 'import', data: { totalCount: validationResults.length }
                    }
                }
            })
            goToStep('import')
        }
    };

    return {
        rowsPerPageOptions,
        rowsPerPage,
        changeRowsPerPage,
        getPageCount: (totalRows: number) => Math.ceil(totalRows / rowsPerPage),
        currentPage,
        setCurrentPage,
        displayRows,
        backToPreviousStep,
        previousStepName,
        startImportingCSV,
    }
}

export {
    useCSVBulkEvaluationPreviewStep
}