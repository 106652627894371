import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    container: Cn.c('py-6 rounded-xl'),
    header: Cn.c('flex justify-between items-start pb-4 px-6'),
    outerCircle: Cn.c('w-14 h-14 flex items-center bg-surface-critical-subdued justify-center rounded-full'),
    innerCircle: Cn.c('w-10 h-10 flex items-center bg-surface-critical-default justify-center rounded-full'),
    warningIcon: Cn.c('w-6 h-6 text-icons-critical-emphasized'),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    body: Cn.c('pb-5 border-b border-default px-6'),
    description: Cn.c('text-emphasized font-h4-bold mb-2'),
    subDescription: Cn.c('text-subdued font-paragraph-small-regular'),
    buttons: Cn.c('mt-6 mx-6 flex items-center justify-between'),
    contact: Cn.c('text-primary-default font-paragraph-base-link-medium underline cursor-pointer'),
};

interface Props extends ModalProps {
    onClose: VoidFunction;
}

const UserInviteErrorModal: FunctionComponent<Props> = ({ isOpen = false, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} close={onClose}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t("settings.userManagement.addUserError.description")}</p>
                    <p className={styles.subDescription}>{t("settings.userManagement.addUserError.subDescription")}</p>
                </div>
                <div className={styles.buttons}>
                    <div>
                        <a href="mailto:bluum@reccoo.com" className={styles.contact}>
                            {t("settings.userManagement.addUserError.contactUs")}
                        </a>
                    </div>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={onClose}
                    >
                        {t("global.close")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export {
    UserInviteErrorModal
};