import { AllAcquisitionCategories_AllQuery, AllEntryCategories_AllQuery, JobPosition_OneByIdQuery, JobPositions_AllSimpleQuery } from "@entities"
import { Entity } from "@typedefs/graphql"
import { SelectionOption } from "@typedefs/selectOption"

import { allRecruitmentStages } from "@helpers/core/constants"
import { isDefined, isOneOf } from "@helpers/core/typeGuards"
import { useNestedForm } from "@helpers/hooks/unsorted/useNestedForm"
import { Cn } from "@helpers/unsorted/classNames"
import { useEffect, useMemo, useState } from "react"
import { useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as value from './value'

interface ApplicationInformationFormHookType {
    jobPositionOptions: SelectionOption[]
    acquisitionCategoryOptions: SelectionOption[]
    entryCategoryOptions: SelectionOption[]
    recruitmentStepOptions: SelectionOption[]
    currentAcquisitionChannelOptions?: SelectionOption[]
    currentEntryChannelOptions?: SelectionOption[]
    isCurrentRecruitmentStageSourcing: boolean
    form: ReturnType<typeof useNestedForm<value.Encoder.Type>>
    hasDisabledJobPosition: boolean;
    currentJobPositionId: string;
    currentRecruitmentStepStage: string | undefined;
    showJobPositionExplanation: boolean
    setShowJobPositionExplanation: (value: boolean) => void
}

const useApplicationInformationSubForm = ({
    jobPositions,
    entryCategories,
    acquisitionCategories,
    recruitmentSteps,
    onChange,
    path,
}: {
    jobPositions: Entity<JobPositions_AllSimpleQuery, 'jobPositions.edges'>[]
    entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[]
    acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[]
    recruitmentSteps: Entity<JobPosition_OneByIdQuery, 'jobPosition.recruitmentStepsV2'>[]
    onChange?: (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => void
    path?: string
}): ApplicationInformationFormHookType => {
    const [showJobPositionExplanation, setShowJobPositionExplanation] = useState(false)

    const form = useNestedForm<value.Encoder.Type>(path);

    const { control, getPath } = form;

    const { t } = useTranslation();

    const hasDisabledJobPosition = jobPositions.some(jobPosition => jobPosition.hasUnconfiguredSteps);

    const currentJobPositionId = useWatch({
        control,
        name: getPath("jobPositionId")
    })

    const currentRecruitmentStepId = useWatch({
        control,
        name: getPath('recruitmentStepId')
    });

    const currentAcquisitionCategoryId = useWatch({
        control: form.control,
        name: getPath('acquisitionCategoryId'),
    });

    const currentAcquisitionChannelId = useWatch({
        control: form.control,
        name: getPath('acquisitionChannelId'),
    });

    const currentEntryCategoryId = useWatch({
        control: form.control,
        name: getPath('entryCategoryId'),
    });

    const currentEntryChannelId = useWatch({
        control: form.control,
        name: getPath('entryChannelId'),
    });

    const jobPositionOptions: SelectionOption[] = jobPositions.map(({ id, title, hasUnconfiguredSteps }) => ({
        key: id,
        value: id,
        label: title,
        disabled: hasUnconfiguredSteps,
        ...(hasUnconfiguredSteps ? {
            disabledTooltip: {
                tooltipContent: t('applications.create.label.jobPositionOptionDisabledTooltip'),
                placement: 'right',
                toolTipClassName: Cn.c('w-80'),
            }
        } : {}),
    }));

    const acquisitionCategoryOptions: SelectionOption[] = acquisitionCategories.map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
    }));

    const entryCategoryOptions: SelectionOption[] = entryCategories.map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
    }));

    const currentAcquisitionChannelOptions: SelectionOption[] | undefined = currentAcquisitionCategoryId
        ? acquisitionCategories.find(acquisitionCategory => acquisitionCategory.id === currentAcquisitionCategoryId)
            ?.channels.map(({ id, name }) => ({
                key: id,
                value: id,
                label: name,
            }))
        : undefined;

    const currentEntryChannelOptions: SelectionOption[] | undefined = currentEntryCategoryId
        ? entryCategories.find(entryCategory => entryCategory.id === currentEntryCategoryId)
            ?.channels?.map(({ id, name }) => ({
                key: id,
                value: id,
                label: name,
            }))
        : undefined;

    const recruitmentStepOptions: SelectionOption[] = useMemo(() => {
        if (currentJobPositionId.length === 0) {
            return [];
        }

        return recruitmentSteps.reduce<SelectionOption[]>((options, recruitmentStep) => {
            const stageExists = options.some(option => option.value === recruitmentStep.stage);

            if (!stageExists) {
                options.push({
                    key: recruitmentStep.stage,
                    value: recruitmentStep.stage,
                    label: `----- ${t(`jobPositions.panel.recruitmentFlow.stage.${recruitmentStep.stage}.name`)} -----`.toUpperCase(),
                    disabled: true,
                });
            }

            options.push({
                key: recruitmentStep.id,
                value: recruitmentStep.id,
                label: recruitmentStep.name,
            });

            return options;
        }, []);
    }, [currentJobPositionId, recruitmentSteps])

    const currentRecruitmentStepStage = recruitmentStepOptions.findLast(
        (option, index) => {
            const stepIndex = recruitmentStepOptions.findIndex(option => option.value === currentRecruitmentStepId);

            const value = option.value;

            return isOneOf(allRecruitmentStages)(value) && stepIndex > -1 && index <= stepIndex
        })?.value;

    const isCurrentRecruitmentStageSourcing = currentRecruitmentStepStage === 'SOURCING';

    useEffect(() => {
        if (isDefined(currentRecruitmentStepStage) && isCurrentRecruitmentStageSourcing) {
            form.setValue(getPath('entryCategoryId'), '');
            form.setValue(getPath('entryChannelId'), '');
        }
    }, [isCurrentRecruitmentStageSourcing]);

    useEffect(() => {
        if (currentAcquisitionCategoryId.length === 0) {
            form.setValue(getPath('acquisitionChannelId'), '');
        }
    }, [currentAcquisitionCategoryId])

    useEffect(() => {
        if (currentEntryCategoryId.length === 0) {
            form.setValue(getPath('entryChannelId'), '');
        }
    }, [currentEntryCategoryId])

    useEffect(() => {
        onChange?.({
            jobPositionId: currentJobPositionId,
            recruitmentStepId: currentRecruitmentStepId,
            acquisitionCategoryId: currentAcquisitionCategoryId,
            acquisitionChannelId: currentAcquisitionChannelId,
            entryCategoryId: currentEntryCategoryId,
            entryChannelId: currentEntryChannelId,
        });
    }, [
        currentJobPositionId,
        currentRecruitmentStepId,
        currentAcquisitionCategoryId,
        currentEntryCategoryId,
        currentAcquisitionChannelId,
        currentEntryChannelId,
    ])

    return {
        jobPositionOptions,
        acquisitionCategoryOptions,
        entryCategoryOptions,
        recruitmentStepOptions,
        currentAcquisitionChannelOptions,
        currentEntryChannelOptions,
        currentRecruitmentStepStage,
        isCurrentRecruitmentStageSourcing,
        form,
        hasDisabledJobPosition,
        currentJobPositionId,
        showJobPositionExplanation,
        setShowJobPositionExplanation,
    };
}

export {
    useApplicationInformationSubForm
}
