import { AllTagsQuery, JobPositions_AllSimpleQuery } from "@entities";
import { Cn } from "@helpers/unsorted/classNames";
import { Sort } from "@shared/unsorted/Sort/Sort";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useApplicationSort } from "./hook";

const styles = {
  subHeaderSection: Cn.c("flex items-center justify-between flex-shrink-0 mb-2 bg-surface-default"),
  normalText: Cn.c("font-paragraph-small-medium text-subdued"),
  emphasizedText: Cn.c("font-paragraph-small-semi-bold text-default"),
  sortContainer: Cn.c("flex gap-x-1 p-1")
};

interface Props {
  totalRows: number;
}

const ApplicationSort: FunctionComponent<Props> = ({ totalRows }) => {
  const { t } = useTranslation();
  const {
    sortOptions,
    currentSortId,
    onSort,
    rowsPerPage,
    currentPage,
  } = useApplicationSort();

  const endRowIndex = rowsPerPage * currentPage >= totalRows ? totalRows : rowsPerPage * currentPage
  const startRowIndex = rowsPerPage * (currentPage - 1) + 1 >= endRowIndex ? endRowIndex : rowsPerPage * (currentPage - 1) + 1

  return (
    <div className={styles.subHeaderSection}>
      <div className={styles.normalText}>
        <Trans
          i18nKey={"applications.list.candidatesCount"}
          count={totalRows}
          values={{
            startRowIndex,
            endRowIndex,
          }}
          components={{ highlight: <span className={styles.emphasizedText}></span> }}
        />
      </div>
      <div className={styles.sortContainer}>
        <p className={styles.normalText}>{t('applications.list.orderBy')}</p>
        <Sort
          items={sortOptions}
          currentSortId={currentSortId}
          onSelect={onSort}
        />
      </div>
    </div>
  );
};

export { ApplicationSort };
