import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { InitialState } from "./init";
import { useEffect } from "react";
import { useBulkActionModalContext } from "@pages/ApplicationsPage/BulkActionModalContext";
import { useBulkEventSessionAssignmentContext } from "../../BulkEventSessionAssignmentContext";
import { useStepperContext } from "@shared/layout/stepper/useStepperContext";

const useSessionGroupSelectionStep = (initialState: InitialState) => {
    const { error: toastError } = useToast();

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()
    const [{ settings }, dispatch] = useBulkEventSessionAssignmentContext()
    const { goToStep } = useStepperContext()

    const closeModal = () => {
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'sessionSelection' }
            }
        })

        dispatch({
            name: 'SetSettings',
            payload: {
                settings: {
                    currentRecruitmentStepId: undefined
                }
            }
        })

        goToStep('sessionSelection')

        dispatchBulkActionModalMode({
            name: 'hide'
        })
    }

    const goToSessionSelection = () => {
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'sessionSelection' }
            }
        })

        dispatch({
            name: 'SetSettings',
            payload: {
                settings: {
                    ...settings,
                    groupId: undefined
                }
            }
        })

        goToStep('sessionSelection')
    }

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])

    return {
        closeModal,
        goToSessionSelection
    }
}

export {
    useSessionGroupSelectionStep
}