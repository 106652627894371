import { useContext } from 'react';

import { isUndefined } from '@helpers/core/typeGuards';
import { TabContext, TabContextType } from './TabContext';

const useTabContext = (): TabContextType => {
    const context = useContext(TabContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <Tabs>...</Tabs> block.');
    }

    return context;
};

export {
    useTabContext,
};
