import { createRoot } from 'react-dom/client';

import { Root } from '@shared/core/Root';
import '@helpers/core/i18n';
import './styles/root.css';

const rootElementId = 'root';
const rootElement = document.getElementById(rootElementId);

if (!rootElement) {
    throw new Error(`Cannot find the "#${rootElementId}" element.`);
}

const root = createRoot(rootElement);

root.render(<Root />);
