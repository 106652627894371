import { ValueItem } from "./value"

interface FocusInputAction {
    name: 'FocusInput'
}

interface BlurInputAction {
    name: 'BlurInput'
}

interface SetNewItemsAction {
    name: 'SetNewItems',
    payload: {
        items: ValueItem.ValueItemType[]
    }
}

interface EditInputAction {
    name: 'EditInput',
    payload: {
        text: string;
    }
}

type Action = FocusInputAction | BlurInputAction | SetNewItemsAction | EditInputAction

interface ShowMode {
    name: 'show'
}

interface EditMode {
    name: 'edit',
    payload: {
        value: string
    }
}

type Mode = ShowMode | EditMode

type State = {items: ValueItem.ValueItemType[], mode: Mode}

const typeAheadReducer = (state: State, action: Action): State => {
  switch (action.name) {
    case 'FocusInput':
        return {
            ...state,
            mode: {
                name: 'edit',
                payload: {
                    value: ""
                }
            }
        }
    case 'BlurInput':
        return {
            ...state,
            mode: {
                name: 'show'
            }
        }
    case 'SetNewItems': 
        return {
            items: action.payload.items,
            mode: state.mode.name === 'edit' 
            ? {
                name: 'edit',
                payload: {
                    value: ""
                }
            }
            : {
                name: 'show'
            }
        }
    case 'EditInput':
        return {
            ...state,
            mode: {
                name: 'edit',
                payload: {
                    value: action.payload.text
                }
            }
        }
    default:
        return {
            ...state
        }
  }
}

const initialState = (inputValue?: ValueItem.ValueItemType[]): State => ({
    items: inputValue || [],
    mode: {
        name: 'show'
    }
})

export {
    Mode,
    State,
    typeAheadReducer,
    initialState
}