import { createContext } from 'react';

interface StepperContextType {
    currentStepId?: string;
    setCurrentStepId: (currentStepId: string) => void;
    goToStep: (stepId: string) => void;
}

const StepperContext = createContext<StepperContextType | undefined>(undefined);

export {
    StepperContext,
    StepperContextType,
};