import { isDefined } from "@helpers/core/typeGuards";
import { Cn } from "@helpers/unsorted/classNames";
import { FormProps, ForwardedRefProps, HTMLPassedProps } from '@typedefs/props';
import { FocusEventHandler, ReactElement } from 'react';
import { FieldValues } from 'react-hook-form';
import { I18nKey } from "react-i18next";
import { Label, Variant as LabelVariant } from '../../Label/Label'
import { RadioInput, Size } from "../RadioInput";
import { useRadioInputGroup } from "./hook";
import { TooltipPlacement } from "../../ToolTipBox/ToolTipBox";

type Orientation = 'horizontal' | 'vertical';

interface RadioInputOptions {
  label: I18nKey | ReactElement<unknown>;
  value: string;
  disabled: boolean;
}

const styles = {
  container: Cn.c("flex flex-col gap-y-1"),
  radioInputsContainer: (containerOrientation: Orientation, className?: string) =>
    Cn.join([
      Cn.c("flex"),
      containerOrientation === 'horizontal' ? Cn.c("flex-row gap-x-3") : Cn.c("flex-col gap-y-4"), 
      Cn.getSome(className)
  ]),
  error: Cn.c("text-critical-default font-paragraph-small-regular")
}

interface Props<FieldsType extends FieldValues>
  extends
  HTMLPassedProps<'value'>,
  FormProps<FieldsType>,
  ForwardedRefProps<HTMLInputElement> {
  label?: I18nKey;
  tooltip?: I18nKey;
  tooltipPlacement?: TooltipPlacement;
  labelVariant?: LabelVariant;
  className?: string;
  labelClassName?: string;
  inputLabelClassName?: string;
  inputClassName?: string;
  required?: boolean;
  onChange?: (event: any) => void; //TODO: rebind proper type
  onBlur?: VoidFunction;
  onFocus?: FocusEventHandler;
  options: RadioInputOptions[];
  errorLabel?: I18nKey;
  orientation?: Orientation;
  containerOrientation?: Orientation;
  value?: string;
  inputSize?: Size;
}

// TODO: implement logic to disable inputs
// @ocaml.doc("A group of Radio Inputs used to select a particular value
//   - `name`: name of the entire group of radio inputs. will not be passed down to the single Radio Input Component
//   - `label`: optional - the label to display for the group of radio inputs
//   - `labelVariant`: optional - a polymorphic variant to define the size of the Radio Input Group label
//   - `className`: optional - class to apply to the container
//   - `labelClassName`: optional - class to apply to the label of the group of radio inputs
//   - `inputLabelClassName`: optional - class to apply to the labels of each radio input component
//   - `onBlur`: optional - function called when the input is blurred
//   - `onFocus`: optional - function called when the input is focused
//   - `inputClassName`: optional - class to be applied to each radio input (the containing div)
//   - `required`: bool - show the * for required input - false by default
//   - `onChange`: optional - function called when a value is selected/unselected
//   - `options`: List of labels and values to be assigned to each Radio Input Component
//   - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
//   - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
//   - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
//   - `orientation`: optional - how to display the radio inputs and their label, either horizontally or vertically
//   - `containerOrientation`: optional - how to display the options, either horizontally or vertically
//   - `value`: optional - value of the input
//   - `inputSize`: optional - size of the radio input
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// ")
const RadioInputGroup = <FieldsType extends FieldValues>(
  {
    name,
    label,
    labelVariant = 'primary',
    labelClassName,
    inputLabelClassName,
    className,
    inputClassName,
    required = false,
    onChange,
    options,
    value,
    errors,
    clearErrors,
    errorLabel,
    onBlur,
    onFocus,
    orientation,
    containerOrientation = 'horizontal',
    forwardedRef,
    inputSize,
    tooltip,
    tooltipPlacement,
  }: Props<FieldsType>) => {
  const { error, getIsChecked } = useRadioInputGroup(name, value, label, errorLabel, errors)

  return (
    <div className={styles.container}>
      {isDefined(label) && <Label label={label} labelClassName={labelClassName} required={required} variant={labelVariant} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
      <div
        tabIndex={0}
        className={styles.radioInputsContainer(containerOrientation, className)}
        onBlur={() => onBlur && onBlur()}
        onFocus={event => {
          clearErrors && clearErrors(name);
          onFocus && onFocus(event);
        }}>
        {options.map(({ label, value: radioInputValue, disabled }, index) => {
          return (<RadioInput
            label={label}
            labelClassName={inputLabelClassName}
            className={inputClassName}
            value={radioInputValue}
            onChange={onChange}
            checked={getIsChecked(radioInputValue)}
            disabled={disabled}
            key={index}
            prefix={name}
            orientation={orientation}
            forwardedRef={forwardedRef}
            size={inputSize}
          />)
        })}
      </div>
      {isDefined(error) && <div className={styles.error}>{error}</div>}
    </div>)
};

export {
  RadioInputGroup,
  RadioInputOptions,
};

