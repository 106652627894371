import { Candidate } from "@routes/candidate";
import { useNavigate } from "react-router-dom";

const useCSVBulkEvaluationPage = () => {
    const navigate = useNavigate();

    const switchToTop = () => {
        const routes = Candidate.toRoute()
        navigate(`${routes.PATH_NAME}${routes.search}`)
    }

    return {
        switchToTop
    }
}

export {
    useCSVBulkEvaluationPage
}