import { isOneOf } from "@helpers/core/typeGuards";
import { toQueryString, useRouteQuery } from "./hook";
import { useMemo } from "react";

export namespace Settings {
    export const PATH_NAME = "/settings";
}

export namespace UserManagement {
    export const PATH_NAME = `${Settings.PATH_NAME}/users`;

    interface CreateMode {
        name: 'create';
    }

    interface ShowMode {
        name: 'show';
        payload: {
            id: string;
        };
    }

    interface ListMode {
        name: 'list';
    }

    export type Mode = CreateMode | ListMode | ShowMode;

    interface QueryParams {
        id: string;
        mode: Mode;
        page: number,
        rowsPerPage: number,
        search?: string | null,
        sortType: SortType;
        order: Order;
    }


    export const supportedSort = ['NAME'] as const;
    export type SortType = typeof supportedSort[number];

    const order = ['asc', 'desc'] as const;
    export type Order = typeof order[number];

    const resolveMode = (searchParams: URLSearchParams): Mode => {
        switch (searchParams.get('mode')) {
            case 'create':
                return {
                    name: 'create',
                };

            case 'list':
                return {
                    name: 'list',
                };

            case 'show': {
                const id = searchParams.get('id');

                if (id) {
                    return {
                        name: 'show',
                        payload: {
                            id,
                        },
                    };
                }

                return {
                    name: 'list',
                };
            }

            default:
                return {
                    name: 'list',
                };
        }
    };

    export const useSearchParams = (): QueryParams => {
        const searchParams = useRouteQuery();
        const mode = useMemo(() => resolveMode(searchParams), [searchParams]);
        const sortType = searchParams.get('sort_type');
        const sortOrder = searchParams.get('order');

        return {
            id: searchParams.get('id') ?? '',
            mode,
            page: parseInt(searchParams.get('page') ?? '1'),
            rowsPerPage: parseInt(searchParams.get('rows_per_page') ?? '25'),
            search: searchParams.get('search') || '',
            sortType: isOneOf(supportedSort)(sortType) ? sortType : "NAME",
            order: isOneOf(order)(sortOrder) ? sortOrder : 'desc',
        };
    };

    export const toRoute = ({
        mode,
        id,
        page = 1,
        rowsPerPage = 25,
        search,
        sortType = 'NAME',
        order = 'desc',
    }:
        {
            mode?: string | null,
            id?: string | null,
            page?: number,
            rowsPerPage?: number,
            search?: string | null,
            sortType?: SortType;
            order?: Order;
        } = {}) => {
        return {
            PATH_NAME,
            search: `?${toQueryString({
                mode,
                id,
                page: page.toString(),
                rows_per_page: rowsPerPage.toString(),
                search,
                sort_type: sortType,
                order,
            })}`,
        };
    };
}

// TODO: [IMPLEMENT] Update these routes when the feature is implemented
export namespace Account {
    export const PATH_NAME = `${Settings.PATH_NAME}/account`;
}

export namespace Preferences {
    export const PATH_NAME = `${Settings.PATH_NAME}/preferences`;
}