import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { FunctionComponent, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  editMenuItemClassName: (isEnabled: boolean) => string;
  deleteMenuItemClassName: (isEnabled: boolean) => string;
  isEditable: boolean;
  isDeletable: boolean;
  onEditCandidates: (event: MouseEvent<HTMLElement>) => void;
  onEditExaminers: (event: MouseEvent<HTMLElement>) => void;
  onEditLocation: (event: MouseEvent<HTMLElement>) => void;
  onDelete: (event: MouseEvent<HTMLElement>) => void;
  deleteTooltip: string;
}

const GroupMenuItems: FunctionComponent<Props> = ({
  editMenuItemClassName,
  deleteMenuItemClassName,
  isEditable,
  isDeletable,
  onEditCandidates,
  onEditExaminers,
  onEditLocation,
  onDelete,
  deleteTooltip,
}) => {
  const { t } = useTranslation()

  const editCandidates =
    <li
      className={editMenuItemClassName(isEditable)}
      onClick={event => onEditCandidates && onEditCandidates(event)}>
      {t('event.eventSession.table.extraMenu.editCandidates')}
    </li>

  const editExaminers =
    <li
      className={editMenuItemClassName(isEditable)}
      onClick={event => onEditExaminers && onEditExaminers(event)}>
      {t('event.eventSession.table.extraMenu.editExaminers')}
    </li>

  const editLocation =
    <li
      className={editMenuItemClassName(isEditable)}
      onClick={event => onEditLocation && onEditLocation(event)}>
      {t('event.eventSession.table.extraMenu.editLocation')}
    </li>

  const deleteGroup =
    <li
      className={deleteMenuItemClassName(isDeletable)}
      onClick={event => onDelete && onDelete(event)}>
      {t('event.eventSession.table.extraMenu.delete')}
    </li>

  return (
    <>
      {isEditable
        ? editCandidates
        : <ToolTipBox
          tooltipContent={t('event.eventSession.table.extraMenu.tooltip.editTooltipText')}
          placement="left">
          {editCandidates}
        </ToolTipBox>}
      {isEditable
        ? editExaminers
        : <ToolTipBox
          tooltipContent={t('event.eventSession.table.extraMenu.tooltip.editTooltipText')}
          placement="left">
          {editExaminers}
        </ToolTipBox>}
      {isEditable
        ? editLocation
        : <ToolTipBox
          tooltipContent={t('event.eventSession.table.extraMenu.tooltip.editTooltipText')}
          placement="left">
          {editLocation}
        </ToolTipBox>}
      {isDeletable
        ? deleteGroup
        : <ToolTipBox
          tooltipContent={deleteTooltip}
          placement="left">
          {deleteGroup}
        </ToolTipBox>}
    </>
  );
}

export {
  GroupMenuItems
};
