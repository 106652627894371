import { Cn } from '@helpers/unsorted/classNames';
import { EmptyStateSidePanelContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateSidePanelContainer';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { JobCard } from '@shared/unsorted/JobCard/JobCard';
import { Loader } from '@shared/unsorted/Loader/Loader';
import { Pagination } from '@shared/unsorted/Pagination/Pagination';
import { Search } from '@shared/unsorted/Search/Search';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobListPanel } from './hook';
import { useJobListPanelContext } from './useJobListPanelContext';

const styles = {
    root: (isOpen: boolean) => Cn.join([
        Cn.c('lg:w-job-side-panel w-1/3 p-6 space-y-2'),
        Cn.c('absolute left-0 top-0 bottom-0 z-[100] shadow-modal-drop-shadow'), // TODO: Confirm width
        Cn.c('transition-all transform duration-300 bg-default lg:overflow-x-hidden overflow-y-auto'), // TODO: Confirm the scroll behavior
        isOpen ? Cn.c('visible -translate-x-0') : Cn.c('invisible -translate-x-full'),
    ]),
    header: Cn.c('flex justify-between items-center font-h3-bold text-black'),
    closeIcon: Cn.c('w-6 h-6 text-icons-emphasized cursor-pointer'),
    pagination: Cn.c('pt-4'),
};

interface Props {
    isOpen: boolean;
    onSelectNewJobPosition?: VoidFunction;
    currentJobId?: string;
}

const JobListPanel: FunctionComponent<Props> = ({
    isOpen,
    onSelectNewJobPosition,
    currentJobId,
}) => {
    const { t } = useTranslation();

    const {
        openJobPosition,
        getPageCount,
        onChangePage,
        onSubmitSearch,
        state,
    } = useJobListPanel(
        isOpen,
        currentJobId,
        onSelectNewJobPosition,
    );

    const { hideJobListPanel } = useJobListPanelContext()

    return (
        <div className={styles.root(isOpen)}>
            <div className={styles.header}>
                <span> {t("jobPositions.detail.jobsList")}</span>
                <Icon className={styles.closeIcon} name="close" onClick={hideJobListPanel} />
            </div>
            <Search keyword={state.search} onSearchAction={onSubmitSearch} className={Cn.c('w-full')} />
            {state.isLoading
                ? <Loader />
                : <>
                    {state.data.jobPositions.length === 0
                        ? <EmptyStateSidePanelContainer
                            icon="notFound"
                            description="jobPositions.list.emptySearch.description"
                            subDescription="jobPositions.list.emptySearch.subDescription" />
                        : state.data.jobPositions.map((jobPosition) => <JobCard
                            key={jobPosition.id}
                            variant={currentJobId === jobPosition.id ? "selected" : "default"}
                            publicId={jobPosition.publicId}
                            title={jobPosition.title}
                            updatedAt={jobPosition.updatedAt}
                            onClick={() => openJobPosition(jobPosition.id)}
                        />)
                    }
                    <div className={styles.pagination}>
                        <Pagination
                            pageCount={getPageCount(state.data.totalRows)}
                            currentPage={state.currentPage}
                            onSelectPage={onChangePage}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export {
    JobListPanel
};
