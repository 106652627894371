import { useMemo } from 'react';
import { useTranslation, TFunction } from "react-i18next";
import { getSupportedLanguage, getLocale } from '@helpers/core/i18n';
import { capitalizeFirstLetter } from '@helpers/unsorted/stringExtra';
import { Locale, differenceInDays, format } from 'date-fns';
import { ActivityLog } from '@helpers/core/activityLog';

export type UseActivityLogEntryInput = {
  relativeDate?: Date;
  activityLog: ActivityLog;
}

export const useActivityLogEntry = ({ relativeDate, activityLog }: UseActivityLogEntryInput) => {
  const { t, i18n } = useTranslation();
  const currentDate = useMemo(() => relativeDate || new Date(), [relativeDate?.getTime()])
  const language = useMemo(() => getSupportedLanguage(i18n.language), [i18n.language])
  const formattedActivityLogTimestamp = useMemo(() => {
    const locale = getLocale(language);
    const timeFormat = new Intl.RelativeTimeFormat(language, { numeric: 'auto' });

    return formatActivityLogTimestamp(currentDate, activityLog.actionTimestamp, { t, locale, timeFormat })
  }, [t, currentDate.getTime(), activityLog.actionTimestamp.getTime(), language]);

  return {
    formattedActivityLogTimestamp
  }
}

export const formatActivityLogTimestamp = (
  currentDate: Date,
  actionDate: Date,
  {
    t,
    locale,
    timeFormat
  }: {
    t: TFunction<"translation">;
    locale: Locale;
    timeFormat: Intl.RelativeTimeFormat
  }
) => {
  const formattedTime = format(actionDate, `HH:mm`, { locale })
  const diff = differenceInDays(actionDate, currentDate);
  if (Math.abs(diff) <= 1) {
    /* using Intl.RelativeTimeFormat opposed to formatRelative from date-fns due
     * to date-fns adding time to the formatted date
    */
    return t('activityLog.timestamp', {
      date: capitalizeFirstLetter(timeFormat.format(diff, 'day')),
      time: formattedTime,
    })
  }
  return t('activityLog.timestamp', {
    date: format(actionDate, 'yyyy/MM/dd', { locale }),
    time: formattedTime,
  })
}


