import { FormProps } from '@typedefs/props';
import { useMemo, useRef, useState } from 'react';
import { hasValue, isArrayOf, isDefined, isNullable, isString } from '@helpers/core/typeGuards';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { SelectionOption } from '@typedefs/selectOption';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

type Mode = 'show' | 'hide';

const useMultiselect = <FieldsType extends FieldValues>(
    name: FormProps<FieldsType>['name'],
    options: SelectionOption[],
    placeholder: I18nKey,
    clearErrors?: FormProps<FieldsType>['clearErrors'],
    value?: string[],
    onChange?: (value: string[]) => void,
    onBlur?: VoidFunction,
    shortHandText?: string,
    shortHandTextTriggerThreshold?: number,
) => {

    const { t } = useTranslation();

    const [mode, setMode] = useState<Mode>('hide');

    const multiSelectRef = useRef<HTMLDivElement>(null);

    // TODO: [CHECK] Once RescriptHookForm is replaced, check if this is still valid
    // Note: Filter = Make sure that only string values are processed
    // Make sure to validate the result fo `JSON.parse` and use a type guard for the filter operation
    const getSelectedValues = (): string[] => isArrayOf(isNullable(isString))(value) ? value.filter(hasValue) : [];

    const getSelectedLabels = (options: SelectionOption[], selectedIds: string[]) => {
        return options
            .filter(option => selectedIds.includes(option.value))
            .map(option => option.label)
            .join(", ");
    };

    const isSelected = (currentValue: string): boolean => {
        const selectedValues = getSelectedValues();

        return selectedValues.includes(currentValue);
    };

    const refs = [multiSelectRef]; //TODO: comeback later because wierd behavior between rescript and this

    useClickOutside(refs, () => {
        if (mode === 'show') {
            setMode('hide');
            isDefined(onBlur) && onBlur();
        }

    });

    const open = () => {
        setMode('show');

        isDefined(clearErrors) && clearErrors(name);
    };

    const onToggleSelect = (currentValue: string) => {
        const selectedIds = getSelectedValues();

        const newSelectedIds = isSelected(currentValue) ?
            selectedIds.filter(option => option !== currentValue) :
            [
                ...selectedIds,
                currentValue
            ];

        isDefined(onChange) && onChange(newSelectedIds);
    };

    const selectedValuesLabel = useMemo(() => getSelectedLabels(options, getSelectedValues()), [options, getSelectedValues()]);

    const shouldShowShortHandText = useMemo(() => {
        const selectedValues = getSelectedValues();

        return !!shortHandTextTriggerThreshold && selectedValues.length > shortHandTextTriggerThreshold;
    }, [getSelectedValues()])

    const textToShow = shouldShowShortHandText
        ? shortHandText
            ? `${shortHandText} (${getSelectedValues().length})`
            : `${t(placeholder)} (${getSelectedValues().length})`
        : selectedValuesLabel;

    return {
        mode,
        multiSelectRef,
        isSelected,
        open,
        onToggleSelect,
        selectedValuesLabel,
        shouldShowShortHandText,
        textToShow,
    };
};

export {
    useMultiselect,
    Mode,
};