import {
    RecruitmentProcessHistoryDocumentStep,
    RecruitmentProcessHistoryEventStep,
    RecruitmentProcess_OneByIdQuery,
    SaveFinalDecisionsDocument,
    SendDocumentNotificationsDocument,
    SendEventAnnouncementsDocument,
    PerformActionToApplicationsDocument,
    ArchiveApplicationsDocument,
} from '@entities';
import { generatePath, useNavigate } from 'react-router-dom';
import { ApplicationStepMode } from '../reducer';
import { Entity } from '@typedefs/graphql';
import { hasValue } from '@helpers/core/typeGuards';
import { useMutation } from 'urql';
import { getQueryContext } from '@helpers/unsorted/urqlExtra';
import { MappedEvaluationsType, ApplicationModalMode } from '@pages/ApplicationsPage/reducer';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useContext } from 'react';
import { ClientContext } from '../../../../../contexts/ClientContext';
import { getPublishedEvaluations } from '@shared/application/getPublishedEvaluations';
import { JobPositionDetail } from '@routes/jobPosition';
import { EventDetail } from '@routes/event';

type ActionType = Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.mainActions'>[number]
    | Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.otherActions'>[number];

interface JobPositionInformationHookResult {
    openEmailEditor: VoidFunction;
    onAction: (otherAction: ActionType) => void;
    onRevert: (applicationId: string) => void;
}

const useJobPositionInformation = (
    setActiveTab: (newActiveTab?: string) => void,
    dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void,
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void,
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>,
): JobPositionInformationHookResult => {
    const navigate = useNavigate();

    const [client, setClient] = useContext(ClientContext)

    const { error: toastError } = useToast();

    const [, sendDocumentNotifications] = useMutation(SendDocumentNotificationsDocument);
    const [, sendEventAnnouncements] = useMutation(SendEventAnnouncementsDocument);
    const [, saveFinalDecisions] = useMutation(SaveFinalDecisionsDocument);
    const [, archiveApplications] = useMutation(ArchiveApplicationsDocument);
    const [, performActionToApplications] = useMutation(PerformActionToApplicationsDocument)

    const getCurrentStepHistory = (stepId: string) => {
        const currentStep = application.history.find(({ id }) => id === stepId);

        let currentStepHistory;
        if (currentStep?.type === 'EVENT') {
            currentStepHistory = currentStep as RecruitmentProcessHistoryEventStep;
        } else {
            currentStepHistory = currentStep as RecruitmentProcessHistoryDocumentStep;
        }

        return currentStepHistory;
    }

    const getCurrentStepDecision = (stepId: string) => {
        const currentStepHistory = getCurrentStepHistory(stepId);

        return currentStepHistory.decision;
    };

    const moveToEventDetail = () => {
        const currentEventStep = application.history
            .find(item => item.id === application.currentStep.id)

        if (currentEventStep?.__typename === 'RecruitmentProcessHistoryEventStep') {
            const path = generatePath(EventDetail.PATH_NAME, { id: currentEventStep.event.id })

            navigate(path);
        }
    };

    const onAction = (action: ActionType) => {
        const {
            id: applicationId,
            currentStep: { id: stepId },
        } = application;

        const queryContext = getQueryContext([
            'RecruitmentProcess',
            'RecruitmentProcesses',
            'ApplicationApplicableAction',
            'OngoingRecruitmentProcessSummary',
            'ArchivedRecruitmentProcessSummary',
        ])

        switch (action) {
            case 'SEND_DOCUMENT_NOTIFICATION':
                sendDocumentNotifications({
                    applicationIds: [applicationId],
                    stepId,
                }, getQueryContext(['RecruitmentProcess', 'RecruitmentProcesses', 'ApplicationApplicableAction']));
                break;
            case 'CONFIRM_DOCUMENTS':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'CONFIRM_DOCUMENTS',
                    }
                }, queryContext);
                break;
            case 'SEND_FOLLOW_UP_EMAIL': // TODO: [IMPLEMENT] Add implementation for SEND_FOLLOW_UP_EMAIL case when API becomes available
                break;
            case 'SEND_EVENT_ANNOUNCEMENT':
                sendEventAnnouncements({
                    applicationIds: [applicationId],
                    stepId,
                }, getQueryContext(['RecruitmentProcess', 'RecruitmentProcesses', 'ApplicationApplicableAction']));
                break;
            case 'SEND_REMINDER_EMAIL': // TODO: [IMPLEMENT] Add implementation for SEND_REMINDER_EMAIL case when API becomes available
                break;
            case 'ASSIGN_TO_SESSION_MANUALLY':
                moveToEventDetail()
                break;
            case 'REGISTER_ATTENDANCE':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'REGISTER_ATTENDANCE',
                    }
                }, queryContext);
                break;
            case 'MOVE_TO_NEXT_STATUS': // TODO: [IMPLEMENT] Add implementation for MOVE_TO_NEXT_STATUS case when API becomes available
                break;
            case 'COMPLETE_EVALUATION':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'COMPLETE_EVALUATION',
                    }
                }, queryContext);
                break;
            case 'SEND_REMINDER_TO_PENDING_EXAMINERS': // TODO: [IMPLEMENT] Add implementation for SEND_REMINDER_TO_PENDING_EXAMINERS case when API becomes available
                break;
            case 'FILL_EVALUATION': {
                const currentStep = getCurrentStepHistory(stepId)
                const currentStepEvaluationForm = currentStep?.evaluationForm;
                const currentStepEvaluationResponses = currentStep?.evaluations.find(evaluation => evaluation.clientId === client.currentClient?.id)?.responses || []

                if (hasValue(client.currentClient) && hasValue(currentStepEvaluationForm)) {
                    dispatchApplicationModalMode({
                        name: 'evaluateApplication',
                        payload: {
                            applicationId: application.id,
                            stepId,
                            clientId: client.currentClient?.id,
                            responses: currentStepEvaluationResponses.length > 0
                                ? currentStepEvaluationResponses
                                : currentStepEvaluationForm.questionList.map(question => ({
                                    question,
                                    response: ""
                                }))
                        }
                    })
                } else {
                    toastError('applications.panel.fillEvaluation.error');
                }

                break;
            }
            case 'VIEW_EVALUATIONS': {
                const currentStep = getCurrentStepHistory(stepId);
                const { respondents, mappedEvaluations } = getPublishedEvaluations(currentStep.evaluations)

                dispatchApplicationModalMode({
                    name: 'viewAllEvaluations',
                    payload: {
                        applicationId,
                        stepId,
                        respondents: Array.from(respondents).sort(),
                        evaluations: mappedEvaluations,
                    },
                });

                break;
            }
            case 'MARK_AS_PASS':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'MARK_AS_PASS',
                    }
                }, queryContext);
                break;
            case 'MARK_AS_FAIL':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'MARK_AS_FAIL',
                    }
                }, queryContext);
                break;
            case 'MARK_AS_UNDECIDED':
                performActionToApplications({
                    input: {
                        applicationIds: [applicationId],
                        action: 'MARK_AS_UNDECIDED',
                    }
                }, queryContext);
                break;
            case 'FINALIZE_PASS': {
                const currentStepDecision = getCurrentStepDecision(stepId);

                if (hasValue(currentStepDecision)) {
                    switch (currentStepDecision.preliminaryResult) {
                        case 'PASSED':
                        case 'UNDECIDED':
                            saveFinalDecisions({
                                applicationIds: [applicationId],
                                stepId,
                                result: 'PASSED',
                            }, getQueryContext(['RecruitmentProcess', 'RecruitmentProcesses', 'ApplicationApplicableAction']));
                            break;
                        case 'FAILED':
                            dispatchApplicationModalMode({
                                name: 'finalizeDecision',
                                payload: {
                                    applicationId,
                                    stepId,
                                    preliminaryResult: hasValue(currentStepDecision)
                                        ? currentStepDecision.preliminaryResult
                                        : undefined,
                                    finalResult: 'PASSED',
                                },
                            });
                            break;
                    }
                }

                break;
            }
            case 'FINALIZE_FAIL': {
                const currentStepDecision = getCurrentStepDecision(stepId);

                dispatchApplicationModalMode({
                    name: 'finalizeDecision',
                    payload: {
                        applicationId,
                        stepId,
                        preliminaryResult: hasValue(currentStepDecision)
                            ? currentStepDecision.preliminaryResult
                            : undefined,
                        finalResult: 'FAILED',
                    },
                });
                break;
            }
            case 'MARK_AS_JOINED':
                archiveApplications({
                    input: {
                        applicationIds: [applicationId],
                        reason: 'JOINED',
                    }
                }, queryContext)
                break;
            default:
                break;
        }
    };

    const onRevert = (applicationId: string) => dispatchApplicationModalMode({
        name: 'revertApplication',
        payload: { applicationId },
    });

    const openEmailEditor = () => {
        setActiveTab('email');
        dispatchApplicationStepMode({ name: 'submit' });
    };

    return {
        openEmailEditor,
        onAction,
        onRevert,
    };
};

export {
    useJobPositionInformation,
    ActionType,
};