import Cookies from 'js-cookie';
import { isUndefined } from './typeGuards';

export const WELCOME_MODAL_SHOWN_COOKIE = 'ats-welcome-modal-shown';

const setCookie = <T>(name: string, value: T) => {
    if (isUndefined(value)) {
        return;
    }

    try {
        const stringValue = JSON.stringify(value);
        Cookies.set(name, stringValue);
    } catch (error) {
        throw new Error(`Failed to stringify value for cookie ${name}`);
    }
};

const getCookie = (name: string): unknown => {
    const cookieValue = Cookies.get(name);

    if (isUndefined(cookieValue)) {
        return undefined;
    }

    try {
        return JSON.parse(cookieValue) as unknown;
    } catch (error) {
        throw new Error(`Failed to parse value for cookie ${name}`);
    }
};

const removeCookie = (name: string) => Cookies.remove(name);

const cookies = {
    set: setCookie,
    get: getCookie,
    remove: removeCookie
};

export default cookies;
