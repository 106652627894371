import { FunctionComponent } from "react"
import { useCSVImportPreviewStep } from "./hook"
import { Cn } from "@helpers/unsorted/classNames"
import { Trans, useTranslation } from "react-i18next"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { isDefined } from "@helpers/core/typeGuards"
import { Entity } from "@typedefs/graphql"
import { ValidateCandidateCsvImportFileMutation } from "@entities"
import { Select } from "@shared/unsorted/Select/Select"
import { Pagination } from "@shared/unsorted/Pagination/Pagination"
import { Button } from "@shared/unsorted/Button/Button"
import { FormProvider } from "react-hook-form"
import { ApplicationInformationSubForm } from "@shared/application/ApplicationInformationSubForm/ApplicationInformationSubForm"
import { Loader } from "@shared/unsorted/Loader/Loader"

const styles = {
    emptyContainer: Cn.c("flex justify-center items-center h-full"),
    container: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    section: Cn.c('flex flex-col py-6'),
    sectionBorder: Cn.c('border-b border-default'),
    header: Cn.c('font-paragraph-base-semi-bold text-emphasized mb-2'),
    importInfo: Cn.c('flex space-x-6'),
    detailContainer: Cn.c('flex items-center space-x-1'),
    label: Cn.c('text-subdued font-paragraph-small-regular'),
    detail: Cn.c('text-emphasized font-paragraph-small-regular'),
    changeSettingButtonWrapper: Cn.c('mt-6'),
    changeSettingButton: Cn.c("p-0 h-0"),
    form: Cn.c("pb-6"),
    saveButton: Cn.c("h-0 mt-6"),
    icon: Cn.c('text-icons-disabled w-[1.125rem] h-[1.125rem]'),
    count: Cn.c('font-paragraph-base-medium text-emphasized'),
    table: Cn.c("table-sticky mt-6 border border-subdued border-b-0"),
    tableTH: Cn.c("table-thead-row table-th-sticky top-0 text-left"),
    tableTBodyRow: Cn.c("table-tbody-row bg-surface-default"),
    tableCell: Cn.c("table-td"),
    paginationWrapper: Cn.c("pt-3 flex justify-between items-end grow"),
    paginationLeft: Cn.c("flex items-center space-x-2"),
    rowsPerPage: Cn.c("font-paragraph-xsmall-regular text-subdued"),
    tabContainer: Cn.c('overflow-auto flex flex-col flex-1'),
    rowsPerPageSelect: Cn.c("w-16"),
    footer: Cn.c("border-t border-default p-6 flex justify-between")
}

interface Props {
    validationResults: Entity<ValidateCandidateCsvImportFileMutation, 'validateCandidateCSVImportFile.result'>[];
}

const CSVImportPreviewStep: FunctionComponent<Props> = ({
    validationResults,
}) => {
    const {
        rowsPerPageOptions,
        rowsPerPage,
        changeRowsPerPage,
        getPageCount,
        currentPage,
        setCurrentPage,
        displayRows,
        setting,
        backToPreviousStep,
        showSetting,
        setShowSetting,
        previousStepName,
        form,
        onSubmit,
        onSubFormChange,
        applicationInformationSubFormData,
        startImportingCSV
    } = useCSVImportPreviewStep(validationResults)

    const { t } = useTranslation()

    return applicationInformationSubFormData.isLoading
        ? <div className={styles.emptyContainer}><Loader variant="primaryDefault" size="lg" /></div>
        :
        <>
            <div className={styles.container}>
                {!showSetting
                    ? <div className={Cn.join([styles.section, styles.sectionBorder])}>

                        <p className={styles.header}>{t("applications.csvImport.previewStep.sectionHeader.importSettings")}</p>
                        <div className={styles.importInfo}>
                            {isDefined(setting?.jobPosition) &&
                                <div className={styles.detailContainer}>
                                    <Icon name="briefcase" className={styles.icon} />
                                    <span className={styles.label}>{t("applications.csvImport.previewStep.jobPosition")}</span>
                                    <span className={styles.detail}>{setting?.jobPosition.title}</span>
                                </div>
                            }
                            {isDefined(setting?.acquisitionChannel) &&
                                <div className={styles.detailContainer}>
                                    <Icon name="fileEdit" className={styles.icon} />
                                    <span className={styles.label}>{t("applications.csvImport.previewStep.acquisitionChannel")}</span>
                                    <span className={styles.detail}>{setting?.acquisitionChannel.name}</span>
                                </div>
                            }
                            {isDefined(setting?.entryChannel) &&
                                <div className={styles.detailContainer}>
                                    <Icon name="fileList" className={styles.icon} />
                                    <span className={styles.label}>{t("applications.csvImport.previewStep.entryChannel")}</span>
                                    <span className={styles.detail}>{setting?.entryChannel.name}</span>
                                </div>
                            }
                            {isDefined(setting?.recruitmentStep) &&
                                <div className={styles.detailContainer}>
                                    <Icon name="flagFill" className={styles.icon} />
                                    <span className={styles.label}>{t("applications.csvImport.previewStep.recruitmentStep")}</span>
                                    <span className={styles.detail}>{setting?.recruitmentStep.name}</span>
                                </div>
                            }
                        </div>
                        <div className={styles.changeSettingButtonWrapper}>
                            <Button
                                iconName="edit"
                                isTrailingIcon={false}
                                size="md"
                                variant="primaryPlain"
                                className={styles.changeSettingButton}
                                onClick={() => setShowSetting(true)}
                            >
                                {t('applications.csvImport.previewStep.changeImportSettings')}
                            </Button>
                        </div>
                    </div>
                    : <form className={Cn.join([styles.sectionBorder, styles.form])} onSubmit={onSubmit}>
                        <FormProvider {...form}>
                            <ApplicationInformationSubForm
                                jobPositions={applicationInformationSubFormData.data.jobPositions}
                                entryCategories={applicationInformationSubFormData.data.entryCategories}
                                acquisitionCategories={applicationInformationSubFormData.data.acquisitionCategories}
                                recruitmentSteps={applicationInformationSubFormData.data.recruitmentSteps}
                                onChange={onSubFormChange}
                            />
                        </FormProvider>
                        <Button
                            type="submit"
                            className={styles.saveButton}
                            size="md"
                            variant="primaryPlain"
                            isTrailingIcon={false}
                            iconName="save"
                        >
                            {t('global.save')}
                        </Button>
                    </form>
                }
                <div className={Cn.join([styles.section, Cn.c("grow")])}>
                    <p className={styles.header}>{t("applications.csvImport.previewStep.sectionHeader.tablePreview")}</p>
                    <p><Trans
                        className={styles.label}
                        i18nKey={"applications.csvImport.previewStep.totalCount"}
                        count={validationResults.length}
                        components={{ highlight: <span className={styles.count}></span> }}
                    /></p>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.tableTH}>
                                    {t("applications.csvImport.previewStep.tableHeaders.rowNumber")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.csvImport.previewStep.tableHeaders.candidateName")}
                                </th>
                                <th className={styles.tableTH}>
                                    {t("applications.csvImport.previewStep.tableHeaders.university")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayRows.map((result, index) => (
                                <tr key={index} className={styles.tableTBodyRow}>
                                    <td className={styles.tableCell}>{index + 1}</td>
                                    <td className={styles.tableCell}>{result.name}</td>
                                    <td className={styles.tableCell}>{""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.paginationWrapper}>
                        <div className={styles.paginationLeft}>
                            <Select
                                name="rowsPerPage"
                                options={rowsPerPageOptions}
                                onChange={changeRowsPerPage}
                                value={rowsPerPage.toString()}
                                size="small"
                                direction="upward"
                                className={styles.rowsPerPageSelect}
                                rawPlaceholder={rowsPerPage.toString()}
                                noEmpty
                            />
                            <div className={styles.rowsPerPage}>{t('applications.csvImport.previewStep.rowsPerPage')}</div>
                        </div>
                        <Pagination pageCount={getPageCount(validationResults.length)} currentPage={currentPage} onSelectPage={setCurrentPage} />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    variant="secondary"
                    size="md"
                    onClick={backToPreviousStep}
                    iconName="arrowLeftLine">
                    {previousStepName === 'setup'
                        ? t("applications.csvImport.previewStep.backToSetup")
                        : t("applications.csvImport.previewStep.backToErrorCorrection")}
                </Button>
                <Button
                    variant="primaryFilled"
                    size="md"
                    iconName="arrowRightLine"
                    onClick={() => startImportingCSV()}
                    isTrailingIcon>
                    {t("applications.csvImport.previewStep.startImport")}
                </Button>
            </div>
        </>

}

export {
    CSVImportPreviewStep
}