import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Mode, useOtherActions } from './hook';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { ActionType } from '../hook';

type Direction = 'upward' | 'downward';

const styles = {
    button: Cn.c('ml-px flex-1'),
    caretIcon: (mode: Mode) => Cn.join([
        Cn.c('ml-2 w-6 h-6'),
        Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180'))
    ]),
    list: (direction: Direction) => Cn.join([
        Cn.c('w-full absolute z-10 bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md'),
        direction === 'upward' ? Cn.c('bottom-16') : Cn.c('right-0 top-12'),
    ]),
    listRow: Cn.c('block cursor-pointer font-paragraph-small-regular px-4 py-2 hover:bg-surface-hovered-default'),
    actionsContent: Cn.c('flex flex-row items-center justify-around'),
};

// @ocaml.doc("Button to display other actions on recruitment process
//     - `listOrientation`[#upwards | #downwards] - absolute positioning of the list, whether to open upwards or downwards
// ")
interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    onAction: (otherAction: ActionType) => void;
    direction?: Direction;
}

const OtherActions: FunctionComponent<Props> = ({
    application,
    onAction,
    direction = 'downward',
}) => {
    const { t } = useTranslation();

    const {
        id,
        currentStep,
        currentStepStatusActions: { otherActions },
    } = application;

    const {
        actionListDisplayMode,
        toggleOtherActionList,
        otherActionsRef,
        onClickOtherAction,
    } = useOtherActions(onAction);

    return (
        <div ref={otherActionsRef}>
            <Button
                size="md"
                variant="secondary"
                onClick={toggleOtherActionList}
                isDisabled={otherActions.length === 0}
                className={styles.button}
            >
                <div className={styles.actionsContent}>
                    {t("applications.panel.recruitmentStepOtherActions")}
                    <Icon name="caret" className={styles.caretIcon(actionListDisplayMode)} />
                </div>
            </Button>
            {actionListDisplayMode === "show" &&
                <div className={styles.list(direction)} hidden={otherActions.length === 0}>
                    {otherActions.map((otherAction, index) =>
                        <div className={styles.listRow} key={index} onClick={() => onClickOtherAction(otherAction)}>
                            {t(`recruitmentStepStatusAction.${otherAction}`)}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export {
    OtherActions,
};
