import { z } from "zod";

namespace ValueItem {
  export interface ValueItemType {
    value?: string | null;
    label: string;
  }

  export const schema = z.object({
    value: z.string().nullish(),
    label: z.string()
  })
}

export {
  ValueItem
}