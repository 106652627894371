import { Cn } from '@helpers/unsorted/classNames';
import { FunctionComponent } from 'react';

const styles = {
    root: Cn.c('flex gap-3 items-center w-full'),
    track: Cn.c('bg-surface-emphasized h-2 rounded grow'),
    progressBar: Cn.c('bg-surface-dark-default h-2 rounded'),
    percentage: Cn.c('text-emphasized font-paragraph-small-medium w-9 text-right shrink-0'),
};

interface Props {
    /** The current progress percentage, ranging from 0 to 100 */
    progressValue: number;
    /** Whether to show the percentage of the progress. Defaults to false */
    showPercentage?: boolean;
    /** Optional class name for the root container of the progress bar */
    className?: string;
    /** Optional class name for the progress bar's track */
    trackClassName?: string;
}

const Progress: FunctionComponent<Props> = ({
    progressValue,
    showPercentage = false,
    className,
    trackClassName,
}) => {
    progressValue = Math.min(Math.max(0, progressValue), 100);

    return (
        <div className={Cn.join([styles.root, Cn.getSome(className)])}>
            <div className={Cn.join([styles.track, Cn.getSome(trackClassName)])}>
                <div
                    className={styles.progressBar}
                    style={{ width: `${progressValue}%` }}
                />
            </div>
            {showPercentage && <p className={styles.percentage}>{progressValue}%</p>}
        </div>
    );

};

export {
    Progress,
};