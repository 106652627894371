import { isDefined } from '@helpers/core/typeGuards';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { range } from '@helpers/unsorted/arrayExtra';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { FormProps } from '@typedefs/props';
import { SelectionOption } from '@typedefs/selectOption';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Mode = 'show' | 'hide';

interface TimePickerHookType {
    selectRef: React.RefObject<HTMLDivElement>;
    toggle: VoidFunction;
    selectedValueLabel: string;
    mode: Mode;
    hourOptions: SelectionOption[];
    minuteOptions: SelectionOption[];
    selectedHours: string;
    selectedMinutes: string;
    onHourSelect: (hourValue: string) => void;
    onMinuteSelect: (minuteValue: string) => void;
    onConfirmTimeSelection: VoidFunction;
    onCancel: VoidFunction;
}

const useTimePicker = <FieldsType extends FieldValues>({
    name,
    value,
    onBlur,
    clearErrors,
    onChange,
    minuteStep,
}:{
    name: FormProps<FieldsType>['name'];
    value?: string;
    onBlur?: VoidFunction;
    clearErrors?: FormProps<FieldsType>['clearErrors'];
    onChange?: (value: string) => void;
    minuteStep: number;
}): TimePickerHookType => {
    // TODO: This is temporary implementation for now. Need to refactor this to use the date format helper
    const splitTimeString = (time: string) => {
        const split = time.split(":").map(value => parseInt(value).toString());

        return {
            hours: split[0],
            minutes: split[1],
        };
    };

    const defaultTime = splitTimeString(value || '');

    const [mode, setMode] = useState<Mode>('hide');

    const [selectedValueLabel, setSelectedValueLabel] = useState(value || '');
    const [selectedHours, setSelectedHours] = useState(defaultTime.hours || '');
    const [selectedMinutes, setSelectedMinutes] = useState(defaultTime.minutes || '');
    
    const selectRef = useRef<HTMLDivElement>(null);

    useClickOutside([selectRef], () => {
        if(mode === 'show') {
            setMode('hide');
            isDefined(onBlur) && onBlur();
        }
    });

    const toggle = () => {
        setMode(mode === 'show' ? 'hide' : 'show');

        isDefined(clearErrors) && clearErrors(name);
    };

    const hourOptions: SelectionOption[] = range(24).map(hour => ({
        key: hour.toString(),
        value: hour.toString(),
        label: hour.toString(),
    }));

    const minuteOptions = useMemo<SelectionOption[]>(() => range(Math.ceil(60 / minuteStep))
        .map(minute => {
            const minuteValue = minute * minuteStep;

            return {
                key: minuteValue.toString(),
                value: minuteValue.toString(),
                label: minuteValue.toString().padStart(2, '0'),
            };
    }), [minuteStep]);

    const onHourSelect = (hourValue: string) => setSelectedHours(hourValue); 
    
    const onMinuteSelect = (minuteValue: string) => setSelectedMinutes(minuteValue); 

    const onConfirmTimeSelection = () => {
        const newValue = dateFormat.simpleTimeFromValues(
            {
                hours: parseInt(selectedHours),
                minutes: parseInt(selectedMinutes),
            },
        );

        setSelectedValueLabel(newValue);
        isDefined(onChange) && onChange(newValue);
        setMode('hide');
    };

    const onCancel = () => {
        const newSelectedValue = dateFormat.simpleTimeFromValues(
            {
                hours: parseInt(selectedHours),
                minutes: parseInt(selectedMinutes),
            },
        );

        // Reset selection to previous value if there is a selection but cancelled
        if(selectedValueLabel !== newSelectedValue) {
            const { hours, minutes } = splitTimeString(selectedValueLabel);
            setSelectedHours(hours);
            setSelectedMinutes(minutes);
        }

        setMode('hide');
    };

    useEffect(() => {
        setSelectedValueLabel(value || '');

        const { hours, minutes } = splitTimeString(value || '');
        setSelectedHours(hours);
        setSelectedMinutes(minutes);
    }, [value]);

    return {
        selectRef,
        toggle,
        selectedValueLabel,
        mode,
        hourOptions,
        minuteOptions,
        selectedHours,
        selectedMinutes,
        onHourSelect,
        onMinuteSelect,
        onConfirmTimeSelection,
        onCancel,
    }; 
};

export {
    useTimePicker,
    Mode,
};