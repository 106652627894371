import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Error as LocationValidationError } from './useEventSessionUniqueLocationWarningModal';

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-warning-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-warning-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-warning-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular whitespace-pre-line flex flex-col space-y-6"),
    list: Cn.c("list-disc px-6"),
    highlightGroup: Cn.c("font-paragraph-small-semi-bold text-emphasized"),
    duplicateEvent: Cn.c("font-paragraph-small-regular text-emphasized"),
    buttons: Cn.c("mt-6 flex space-x-3 justify-end px-6")
};

interface Props extends ModalProps {
    onClose: VoidFunction;
    revertToPreviousMode: VoidFunction;
    onSubmit: VoidFunction;
    error: LocationValidationError;
}

const EventSessionUniqueLocationWarningModal: FunctionComponent<Props> = ({ isOpen, onClose, revertToPreviousMode, onSubmit, error }) => {
    const { t } = useTranslation();

    const errorTitle = useMemo(() => {
        if (isDefined(error)) {
            switch (error.name) {
                case 'isUniqueLocationBetweenSessions':
                    return t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenGroupTitle');
                case 'isUniqueLocationBetweenGroups':
                case 'isUniqueLocationBetweenEvents':
                    return t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenEventTitle');
            }
        }
    }, [error]);

    const errorMessage = useMemo(() => {
        if (isDefined(error)) {
            switch (error.name) {
                case 'isUniqueLocationBetweenGroups':
                    return <>
                        <p>{t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenGroupDescription1')}</p>
                        {error.payload.duplicateGroups.map((group, index) => {
                            return <ul key={index} className={styles.list}>
                                {group.map((item, index) => {
                                    return <li key={index}>{t('event.addSession.groupLabel', { count: item + 1, ordinal: true })}</li>;
                                })}
                            </ul>;
                        })}
                        <p>{t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenGroupDescription2')}</p>
                    </>;

                case 'isUniqueLocationBetweenSessions':
                case 'isUniqueLocationBetweenEvents':
                    return <>
                        <p>{t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenEventDescription1')}</p>
                        <ul className={styles.list}>
                            {
                                error.payload.map((group, index) => {
                                    return <li key={index}>
                                        <span className={styles.highlightGroup}>{
                                            t('event.addSession.groupLabel', { count: group.group, ordinal: true })
                                        }</span>
                                        <Trans
                                            i18nKey={"event.eventSessionUniqueLocationWarningModal.groupError"}
                                            values={{ path: group.message }}
                                            components={{ highlight: <span className={styles.duplicateEvent}></span> }}
                                        />
                                    </li>
                                })
                            }
                        </ul>

                        <p>{t('event.eventSessionUniqueLocationWarningModal.sameUrlBetweenEventDescription2')}</p>
                    </>;
            }
        }
    }, [error]);

    return (
        <Modal
            isOpen={isOpen}
            close={onClose}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="warning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{errorTitle}</p>
                    <div className={styles.subDescription}>{errorMessage}</div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        iconName='arrowLeft'
                        variant="secondary"
                        size="md"
                        onClick={revertToPreviousMode}
                    >
                        {t("event.eventSessionUniqueLocationWarningModal.buttons.back")}
                    </Button>
                    <Button
                        size="md"
                        onClick={onSubmit}
                    >
                        {t("event.eventSessionUniqueLocationWarningModal.buttons.continue")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export {
    EventSessionUniqueLocationWarningModal
};
