import { ValidateBulkEvaluationCsvFileMutation } from "@entities";
import { Props as StepperProps } from "@shared/layout/stepper/Stepper";
import { Entity } from "@typedefs/graphql";

interface PendingDocument {
    id: string;
    filename: string;
    file: File;
}

interface SetupStep {
    name: "setup";
}

interface ErrorCorrectionStep {
    name: "errorCorrection";
    data: {
        errorCSVUrl?: string
        errorSummary?: Entity<ValidateBulkEvaluationCsvFileMutation, 'validateBulkEvaluationCSVFile.errorSummary'>
        stepTracker: StepperProps['stepTracker'];
        resetCSVFile: boolean
    }
}

interface PreviewStep {
    name: "preview";
    data: {
        validationResults: Entity<ValidateBulkEvaluationCsvFileMutation, 'validateBulkEvaluationCSVFile.result'>[];
        validCSVUrl: string;
        stepTracker: StepperProps['stepTracker'];
    }
}

interface ImportStep {
    name: "import";
    data: {
        totalCount: number;
    }
}

type Step = SetupStep | ErrorCorrectionStep | PreviewStep | ImportStep

interface State {
    stepTracker: StepperProps['stepTracker']
    currentStep: Step
    csvFile?: PendingDocument
    validCSVUrl?: string
}

interface SetStepTrackerAction {
    name: 'SetStepTracker';
    payload: {
        stepTracker: StepperProps['stepTracker'];
    }
}

interface SetFileUploadAction {
    name: 'SetFileUpload';
    payload: {
        csvFile?: PendingDocument;
    }
}

interface SetStepAction {
    name: 'SetStep';
    payload: {
        step: Step;
    }
}

interface SetValidCSVUrlAction {
    name: 'SetValidCSVUrl';
    payload: {
        validCSVUrl?: string;
    }
}

interface ResetAction {
    name: 'Reset'
}

type Action =
    | SetStepTrackerAction
    | SetFileUploadAction
    | SetStepAction
    | SetValidCSVUrlAction
    | ResetAction;

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'SetStepTracker':
            return {
                ...state,
                stepTracker: action.payload.stepTracker,
            }

        case 'SetFileUpload':
            return {
                ...state,
                csvFile: action.payload.csvFile,
            }

        case 'SetStep':
            if (action.payload.step.name === 'setup') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    validCSVUrl: undefined,
                    stepTracker: initialState.stepTracker
                }
            }
            if (action.payload.step.name === 'errorCorrection') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    csvFile: action.payload.step.data.resetCSVFile ? undefined : state.csvFile,
                    stepTracker: action.payload.step.data.stepTracker,
                }
            }
            if (action.payload.step.name === 'preview') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    validCSVUrl: action.payload.step.data.validCSVUrl,
                    stepTracker: action.payload.step.data.stepTracker,
                }
            }
            if (action.payload.step.name === 'import') {
                return {
                    ...state,
                    currentStep: action.payload.step,
                    stepTracker: [
                        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'success' },
                        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'selected' },
                    ],
                }
            }
            return {
                ...state,
                currentStep: action.payload.step,
            }

        case 'SetValidCSVUrl':
            return {
                ...state,
                validCSVUrl: action.payload.validCSVUrl,
            }

        case 'Reset':
            return initialState

        default:
            return { ...state };
    }
}

const initialState: State = {
    stepTracker: [
        { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'selected' },
        { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'default' },
        { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'default' },
    ],
    currentStep: { name: 'setup' },
}

export {
    reducer,
    initialState,
    State,
    PendingDocument,
    Action,
}