import { RefObject, useRef, useState } from 'react';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { ArchiveApplicationsDocument, RecruitmentStepStatus } from '@entities';
import { useMutation } from 'urql';
import { getQueryContext } from '@helpers/unsorted/urqlExtra';
import { allClosedStepStatuses, archivedReasonGroups, ClosedStepStatus } from '@helpers/core/constants';
import { isOneOf } from '@helpers/core/typeGuards';
import { useTranslation } from 'react-i18next';

type Mode = 'show' | 'hide';

interface ArchiveActionsHookResult {
    actionListDisplayMode: Mode;
    archiveActionsRef: RefObject<HTMLDivElement>;
    toggleArchiveActionList: VoidFunction;
    onOptionClick: (archiveAction: RecruitmentStepStatus, applicationId: string) => void;
    dropdownOptions: DropdownItem[];
}

type DropdownItem = {
    type: 'option',
    status: RecruitmentStepStatus,
} | {
    type: 'header'
    label: string
}

const useArchiveActions = (): ArchiveActionsHookResult => {
    const [actionListDisplayMode, setActionListDisplayMode] = useState<Mode>('hide');

    const { t } = useTranslation()

    const archiveActionsRef = useRef<HTMLDivElement>(null);

    const [, archiveApplications] = useMutation(ArchiveApplicationsDocument);

    const queryContext = getQueryContext([
        'RecruitmentProcess',
        'RecruitmentProcesses',
        'ApplicationApplicableAction',
        'OngoingRecruitmentProcessSummary',
        'ArchivedRecruitmentProcessSummary',
    ])

    useClickOutside([archiveActionsRef], () => setActionListDisplayMode('hide'));

    const toggleArchiveActionList = () => actionListDisplayMode === 'hide' ? setActionListDisplayMode('show') : setActionListDisplayMode('hide');

    const onOptionClick = (archiveAction: RecruitmentStepStatus, applicationId: string) => {
        archiveApplications({
            input: {
                applicationIds: [applicationId],
                reason: archiveAction
            }
        }, queryContext)

        setActionListDisplayMode('hide');
    };

    const getArchivedReasoneGroup = (stepStatus: ClosedStepStatus) => {
        const allClosedStepStatusGroups = ['REJECTED', 'DECLINED', 'OTHER'] as const

        const groupOfCurrentStatus = Object.entries(archivedReasonGroups).find(([_, statuses]) => isOneOf(statuses)(stepStatus))?.[0]

        return isOneOf(allClosedStepStatusGroups)(groupOfCurrentStatus) ? groupOfCurrentStatus : 'UNKNOWN'
    }

    const dropdownOptions = allClosedStepStatuses.reduce((acc: DropdownItem[], closedStepStatus) => {
        const archivedReasonGroups = getArchivedReasoneGroup(closedStepStatus)

        if (archivedReasonGroups !== 'UNKNOWN') {
            const existingHeader = acc
                .find(item => item.type === 'header' && item.label === `${t(`archiveReasonGroup.${archivedReasonGroups}`)}`)

            if (!existingHeader) {
                acc.push({
                    type: 'header',
                    label: `${t(`archiveReasonGroup.${archivedReasonGroups}`)}`,
                })

            }

            acc.push({
                type: 'option',
                status: closedStepStatus,
            })
        }

        return acc
    }, [])

    return {
        actionListDisplayMode,
        archiveActionsRef,
        toggleArchiveActionList,
        onOptionClick,
        dropdownOptions,
    };
};

export {
    Mode,
    useArchiveActions,
};
