import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { I18nKey, useTranslation } from "react-i18next";
import { Icon } from "../Icon/Icon";
import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";

const styles = {
    container: Cn.c("cursor-pointer relative"),
    selectedItemContainer: Cn.c("flex items-center justify-center space-x-1"),
    selectedItem: Cn.c("text-primary-default font-paragraph-small-medium"),
    filterIcon: Cn.c("w-4 h-4 text-primary-default"),
    list: Cn.c("absolute top-6 right-0 bg-surface-default shadow-md rounded-lg z-[99] flex flex-col  w-52"),
    listItem: (isSelected: boolean) => {
        return Cn.join([
            Cn.c("font-paragraph-xsmall-regular flex items-center justify-between cursor-pointer hover:bg-surface-hovered-default p-2"),
            isSelected ? Cn.c("text-primary-default") : Cn.c("text-emphasized") 
        ])
    },
    checkIcon: Cn.c("w-3.5 h-3.5 text-primary-default")
}

type SortItem = {
    id: string
    label: I18nKey
    value: {
        field: string
        direction: "asc" | "desc"
    }
}

interface Props {
    items: SortItem[]
    currentSortId: string
    onSelect?: (id: string) => void
}

const Sort: FunctionComponent<Props> = ({ items, currentSortId, onSelect }) => {
    const [currentId, setCurrentId] = useState(currentSortId)

    const [mode, setMode] = useState<'hide'| 'show'>('hide')

    const sortRef = useRef<HTMLDivElement>(null)
    
    const { t } = useTranslation()

    const currentItem = useMemo(() => items.find(item => item.id === currentId) || items[0], [currentId])

    const selectItem = (id: string) => {  
        setCurrentId(id)
        if(onSelect) {
            onSelect(id)
        }
     }

    useClickOutside([sortRef], () => setMode('hide'));

    useEffect(() => {
        setCurrentId(currentSortId)
      }, [currentSortId])

    return (
        <div className={styles.container} onClick={() => setMode(mode === 'hide' ? 'show' : 'hide')} ref={sortRef}>
            <div className={styles.selectedItemContainer}>
                <p className={styles.selectedItem}>{t(currentItem.label)}</p>
                <Icon name="filter" className={styles.filterIcon}/>
            </div>
            {
                mode === 'show' && (
                    <ul className={styles.list}>
                        {
                            items.map(item => {
                                return (
                                    <li 
                                        key={item.id} 
                                        onClick={() => selectItem(item.id)}
                                        className={styles.listItem(item.id === currentId)}
                                    >
                                        {t(item.label)}
                                        {item.id === currentId && <Icon name='check' className={styles.checkIcon} />}
                                    </li>
                                    
                                );
                            })
                        }
                    </ul>
                )
            }
        </div>
    );
}

export {
    Sort,
    SortItem
}