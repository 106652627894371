import { EventSessionGroupLocationType } from "@entities";
import { useState } from "react";
import { Group as EventSessionGroupCreateModalEncoder } from "./value";

type ErrorType = 'isUniqueLocationBetweenSessions' | 'isUniqueLocationBetweenGroups' | 'isUniqueLocationBetweenEvents';

type Error = {
    name: 'isUniqueLocationBetweenSessions',
    payload: {
        group: number,
        message: string,
    }[]
} | {
    name: 'isUniqueLocationBetweenGroups',
    payload: {
        duplicateGroups: number[][]
    }
} | {
    name: 'isUniqueLocationBetweenEvents',
    payload: {
        group: number,
        message: string,
    }[]
}

interface CreateSettings {
    groupCount: number;
    groups: EventSessionGroupCreateModalEncoder.GroupDecoder.GroupDecoderType[];
    startTime: Date;
    endTime: Date;
}

interface UpdateLocationSettings {
    groupId: string;
    location: string;
    locationType?: EventSessionGroupLocationType;
}

interface AddGroupSettings {
    groups: EventSessionGroupCreateModalEncoder.GroupDecoder.GroupDecoderType[];
}

type Settings = CreateSettings | UpdateLocationSettings | AddGroupSettings;

interface InitMode {
    name: 'init';
}

interface WarnMode {
    name: 'warn';
    payload: {
        error: Error;
        currentSetting: Settings;
        revertToPreviousMode: VoidFunction;
    };
}

type Mode = InitMode | WarnMode;

interface EventSessionUniqueLocationWarningModalHookType {
    mode: Mode;
    backToInit: VoidFunction;
    showWarning: (error: Error, currentSetting: Settings) => void;
}

const useEventSessionUniqueLocationWarningModal = (): EventSessionUniqueLocationWarningModalHookType => {
    const [mode, setMode] = useState<Mode>({
        name: 'init',
    })

    const backToInit = () => {
        setMode({
            name: 'init',
        })
    }

    const showWarning = (error: Error, currentSetting: Settings) => {
        setMode({
            name: 'warn',
            payload: {
                error,
                revertToPreviousMode: () => {
                    setMode({
                        name: 'init',
                    })
                },
                currentSetting,
            }
        })
    }

    return {
        mode,
        backToInit,
        showWarning,
    }
}

export {
    AddGroupSettings, CreateSettings, Error, ErrorType, Mode, UpdateLocationSettings, useEventSessionUniqueLocationWarningModal
};
