import { RecruitmentProcessHistoryEvaluation, RecruitmentStepEvaluation } from '@entities';
import { hasValue } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { ApplicationModalMode } from '@pages/ApplicationsPage/reducer';
import { getPublishedEvaluations } from '@shared/application/getPublishedEvaluations';
import { Button } from '@shared/unsorted/Button/Button';
import { ExternalLink } from '@shared/unsorted/ExternalLink/ExternalLink';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    button: Cn.c('px-0 py-0'),
};

interface Props {
    applicationId: string
    stepId: string
    evaluations: RecruitmentStepEvaluation[]
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
}

const EvaluationLabel: FunctionComponent<Props> = ({ evaluations, applicationId, stepId, dispatchApplicationModalMode }) => {
    const { t } = useTranslation();

    const publishedEvaluations = useMemo(() => evaluations.filter(evaluation => !evaluation.isDraft), [evaluations]);
    const { respondents, mappedEvaluations } = getPublishedEvaluations(evaluations)

    const viewEvaluations = () => {
        dispatchApplicationModalMode({
            name: "viewAllEvaluations",
            payload: {
                applicationId,
                stepId,
                respondents: Array.from(respondents).sort(),
                evaluations: mappedEvaluations,
            }
        })
    }

    return publishedEvaluations.length > 0
        ? <Button
            variant='primaryPlain'
            size='sm'
            className={styles.button}
            onClick={viewEvaluations}
        >
            {t('applications.panel.candidateHistory.viewEvaluations')}
        </Button>
        : <>{getWithDefault()}</>
};

export {
    EvaluationLabel,
};

