import { FunctionComponent } from 'react';

import { ChildrenProps } from '@typedefs/props';
import { useTabContext } from './useTabContext';

interface Props extends ChildrenProps {
    id: string;
}

const Content: FunctionComponent<Props> = ({ children, id }) => {
    const { activeTab } = useTabContext();

    return activeTab === id ? <>{children}</> : null;
};

export {
    Content,
};
