import { EmailTemplate, JobPosition_OneByIdWithStepEventsQuery } from "@entities";
import { useVariables } from "@helpers/hooks/unsorted/useVariables";
import { Entity } from "@typedefs/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface NotificationEmailTemplateUpdateMode {
    name: 'updateNotificationEmailTemplate',
    payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
        emailTemplate: {
            subject: Entity<EmailTemplate, 'subject'>;
            body: Entity<EmailTemplate, 'body'>;
        }
    }
}

interface PassDecisionEmailTemplateUpdateMode {
    name: 'updatePassDecisionEmailTemplate',
    payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
        emailTemplate: {
            subject: Entity<EmailTemplate, 'subject'>;
            body: Entity<EmailTemplate, 'body'>;
        }
    }
}

interface FailDecisionEmailTemplateUpdateMode {
    name: 'updateFailDecisionEmailTemplate',
    payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
        emailTemplate: {
            subject: Entity<EmailTemplate, 'subject'>;
            body: Entity<EmailTemplate, 'body'>;
        }
    }
}

type EmailTemplateMode = NotificationEmailTemplateUpdateMode | PassDecisionEmailTemplateUpdateMode | FailDecisionEmailTemplateUpdateMode

interface EmailTemplateUpdateModalHookType {
    payload?: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>;
        emailTemplate: EmailTemplate;
    };
    title: string;
    close: VoidFunction;
    switchToPreviewMode: (isPreviewMode: boolean) => void;
    isPreviewMode: boolean;
    isFullScreen: boolean;
    switchToFullScreen: VoidFunction;
    switchToModal: VoidFunction;
}

const useEmailTemplateUpdateModal = (closeModal: VoidFunction, emailTemplateMode?: EmailTemplateMode): EmailTemplateUpdateModalHookType => {
    const { t } = useTranslation()

    const { templateReplacer } = useVariables();

    const [isPreviewMode, setIsPreviewMode] = useState(false);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const getEmailTemplatePayload = () => {
        if (
            emailTemplateMode &&
            (emailTemplateMode.name === 'updateNotificationEmailTemplate' ||
                emailTemplateMode.name === 'updatePassDecisionEmailTemplate' ||
                emailTemplateMode.name === 'updateFailDecisionEmailTemplate')
        ) {
            const templateDisplayMode = isPreviewMode ? 'sample' : 'variableName';

            return {
                ...emailTemplateMode.payload,
                emailTemplate: {
                    body: templateReplacer(emailTemplateMode.payload.emailTemplate.body, templateDisplayMode),
                    subject: templateReplacer(emailTemplateMode.payload.emailTemplate.subject, templateDisplayMode),
                },
            };
        }
    }

    const getTitle = () => {
        switch (emailTemplateMode?.name) {
            case 'updateNotificationEmailTemplate':
                return t('jobPositions.emailTemplateUpdateModal.notificationTitle')

            case 'updatePassDecisionEmailTemplate':
                return t('jobPositions.emailTemplateUpdateModal.passDecisionTitle')

            case 'updateFailDecisionEmailTemplate':
                return t('jobPositions.emailTemplateUpdateModal.failDecisionTitle')

            default:
                return "";
        }
    }

    const switchToPreviewMode = (isPreviewMode: boolean) => setIsPreviewMode(isPreviewMode);

    const switchToFullScreen = () => setIsFullScreen(true);

    const switchToModal = () => setIsFullScreen(false);

    const close = () => {
        setIsPreviewMode(false);
        setIsFullScreen(false);
        closeModal();
    };

    return {
        payload: getEmailTemplatePayload(),
        title: getTitle(),
        close,
        switchToPreviewMode,
        isPreviewMode,
        isFullScreen,
        switchToFullScreen,
        switchToModal,
    }
}

export {
    EmailTemplateMode, useEmailTemplateUpdateModal
};
