import { FunctionComponent } from "react"
import { InitialStateData } from "../init"
import { Cn } from "@helpers/unsorted/classNames"
import { Button } from "@shared/unsorted/Button/Button"
import { useSessionSelectionStepSuccessState } from "./hook"
import { useTranslation } from "react-i18next"
import { EventDetail } from "../../EventDetail/EventDetail"
import { RadioInputItemBoxGroup } from "@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup"
import { useBulkEventSessionAssignmentContext } from "@pages/ApplicationsPage/BulkEventSessionAssignmentModal/BulkEventSessionAssignmentContext"

const styles = {
    body: Cn.c("p-6 pt-0 flex flex-col flex-1 overflow-auto"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
    selectSessionText: Cn.c("text-emphasized font-paragraph-small-regular py-6"),
    scrollable: Cn.c("flex flex-col flex-1 overflow-auto"),
}

interface Props extends InitialStateData {
    closeModal: VoidFunction
}

const SessionSelectionStepSuccessState: FunctionComponent<Props> = ({
    closeModal,
    event,
}) => {
    const {
        sessionOptions,
        onSessionChange,
        goToSessionGroupSelection,
    } = useSessionSelectionStepSuccessState(event)
    const [{ settings: { sessionId } }] = useBulkEventSessionAssignmentContext()
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.body}>
                <EventDetail
                    jobTitle={event.recruitmentStepV2.jobPosition.title}
                    stepName={event.recruitmentStepV2.name}
                    eventName={event.name}
                />
                <p className={styles.selectSessionText}>
                    {t('applications.bulkEventSessionAssignment.sessionSelection.selectSession')}
                </p>
                <RadioInputItemBoxGroup
                    name={"sessionId"}
                    layout="grid"
                    gridColumns={2}
                    options={sessionOptions}
                    onChange={onSessionChange}
                    value={sessionId || ''}
                    className={styles.scrollable}
                />
            </div>
            <div className={styles.footer}>
                <Button
                    size="md"
                    variant="secondary"
                    onClick={closeModal}
                >
                    {t('global.cancel')}
                </Button>
                <Button
                    size="md"
                    variant="primaryFilled"
                    onClick={() => sessionId && goToSessionGroupSelection()}
                    isTrailingIcon={true}
                    iconName="arrowRightLine"
                    isDisabled={!sessionId}
                >
                    {t('applications.bulkEventSessionAssignment.sessionSelection.chooseGroup')}
                </Button>
            </div>
        </>
    )
}

export {
    SessionSelectionStepSuccessState
}