import { AllTagsDocument, RecruitmentProcess_OneByIdQuery, UpdateRecruitmentProcessDocument } from '@entities';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { Entity } from '@typedefs/graphql';
import { SelectionOption } from '@typedefs/selectOption';
import { FormEventHandler, useEffect, useState } from 'react';
import { ValueItem as MultiValueTypeAheadValue } from '@shared/unsorted/MultiValueTypeAhead/value';
import { useClient, useMutation } from 'urql';

import * as value from './value'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { isDefined } from '@helpers/core/typeGuards';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';

type TagListMode = {
    name: 'list',
    payload: {
        openTagsForm: VoidFunction;
    }
}

type TagEditMode = {
    name: 'edit',
    payload: {
        tagOptions: SelectionOption[];
        isSubmitting: boolean;
        closeTagsForm: VoidFunction;
        initialInputValue: MultiValueTypeAheadValue.ValueItemType[];
        searchTags: (currentValues: MultiValueTypeAheadValue.ValueItemType[], input?: string) => void;
        form: UseFormReturn<value.Encoder.EncoderType>;
        onSubmit: FormEventHandler<HTMLFormElement>;
    }
}

type TagMode = TagListMode | TagEditMode

interface ApplicationPanelHeaderHookResult {
    tagMode: TagMode;
}

const useApplicationPanelHeader = (application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>): ApplicationPanelHeaderHookResult => {
    const [tagOptions, setTagOptions] = useState<SelectionOption[]>([])

    const [isSubmitting, setIsSubmitting] = useState(false)

    const { error: toastError, success: toastSuccess } = useToast();

    const [internalMode, setInternalMode] = useState<'list' | 'edit'>('list')

    const urqlClient = useClient()

    const initialInputValue: MultiValueTypeAheadValue.ValueItemType[] = application.tags.map(tag => ({
        value: tag.id,
        label: tag.value,
    }))

    const defaultValues = value.Encoder.defaultValues(initialInputValue)

    const [updateApplicationResponse, updateApplication] = useMutation(UpdateRecruitmentProcessDocument)

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues,
    });

    const { reset, handleSubmit } = form

    const openTagsForm = () => {
        reset(defaultValues)
        setInternalMode('edit')
    };

    const closeTagsForm = () => setInternalMode('list')

    const getTags = async (value = "") => {
        const result = await urqlClient.query(AllTagsDocument, {
            value,
            type: 'RECRUITMENT_PROCESS'
        }, {
            requestPolicy: 'network-only'
        }).toPromise()

        if (isDefined(result.data)) {
            setTagOptions(result.data.tags.map(tag => ({
                key: tag.id,
                value: tag.id,
                label: tag.value
            })))
        }
    }

    const searchTags = (currentValues: MultiValueTypeAheadValue.ValueItemType[], input?: string) => {
        getTags(input)
    }

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data)
            updateApplication({
                id: application.id,
                tags: validValues.tags.map(tag => ({
                    id: tag.value,
                    value: tag.label
                }), getQueryContext('Tag'))
            })


        } catch (error) {
            toastError('global.error')
        }
    }

    const tagMode: TagMode = internalMode === 'list'
        ? { name: 'list', payload: { openTagsForm } }
        : {
            name: 'edit', payload: {
                tagOptions,
                isSubmitting,
                closeTagsForm,
                initialInputValue,
                searchTags,
                form,
                onSubmit: handleSubmit(onSubmit)
            }
        }

    useEffect(() => {
        setInternalMode('list')
        reset(defaultValues)
    }, [application])

    useEffect(() => {
        handleResponse(updateApplicationResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: () => {
                setIsSubmitting(false)
                toastSuccess('applications.panel.editTags.success')
                closeTagsForm()
            },
            onError: () => {
                setIsSubmitting(false)
                toastError('applications.panel.editTags.error')
            }
        })
    }, [updateApplicationResponse])

    return {
        tagMode
    };

};

export {
    useApplicationPanelHeader,
};