import {
    birthdateSchema,
    genderSchema,
    graduationYearSchema,
    katakanaSchema,
    monthSchema,
    nonNumberSchema,
} from '@helpers/unsorted/zodSchema';
import { z } from 'zod';
import * as candidateApplicationInformation from '@shared/application/ApplicationInformationSubForm/value'

namespace Encoder {
    export type Type = ReturnType<typeof defaultValues>;

    export const defaultValues = () => {
        return {
            name: '',
            nameKana: '',
            email: '',
            mobilePhoneNumber: '',
            gender: '',
            dateOfBirth: '',
            graduationYear: '',
            graduationMonth: '',
            ...candidateApplicationInformation.Encoder.defaultValue(),
        };
    };
}

namespace Decoder {
    export const schema = candidateApplicationInformation.Decoder.schema.extend({
        name: nonNumberSchema,
        nameKana: katakanaSchema.nullish(),
        email: z.string().email(),
        mobilePhoneNumber: z.string().nullish(),
        gender: genderSchema.nullish(),
        dateOfBirth: z.union([z.string().length(0), birthdateSchema]),
        graduationYear: graduationYearSchema.nullish(),
        graduationMonth: monthSchema.nullish(),
    }).transform((values) => ({
        ...values,
        nameKana: values.nameKana || null,
        mobilePhoneNumber: values.mobilePhoneNumber || null,
        entryCategoryId: values.entryCategoryId || null,
        entryChannelId: values.entryChannelId || null,
        acquisitionCategoryId: values.acquisitionCategoryId || null,
        acquisitionChannelId: values.acquisitionChannelId || null,
        gender: values.gender || null,
        dateOfBirth: values.dateOfBirth || null,
        graduationMonth: values.graduationMonth || null,
        graduationYear: values.graduationYear || null
    }));

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
}