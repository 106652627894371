import { Cn } from "@helpers/unsorted/classNames";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { Badge } from "@shared/unsorted/Badge/Badge";
import { Checkbox } from "@shared/unsorted/Checkbox/Checkbox";
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { SimpleApplication } from "@typedefs/aliases";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import EmptyApplicationsContainer from '../EmptyStateContainers/EmptyApplicationsContainer';
import { BulkActionMenu } from "./BulkActionMenu/BulkActionMenu";
import { DeselectButton } from "./DeselectButton/DeselectButton";
import { useApplicationTableHook } from "./hook";

const styles = {
  recruitmentProcessSelection: Cn.c(
    "bg-surface-primary-emphasized px-2.5 flex justify-between py-2 items-center",
  ),
  tableInnerContainer: (numOfApplications: number) => Cn.join([
    Cn.c("bg-surface-default border border-subdued flex"),
    Cn.c(numOfApplications < 4 ? "overflow-visible" : "overflow-auto"),
    Cn.ifTrue(numOfApplications > 0, "border-b-0")
  ]),
  table: Cn.c("table-sticky"),
  tableTHeadRow: Cn.c("table-thead-row"),
  tableTH: Cn.c("table-thead-row table-th-sticky top-0 text-left"),
  selectedTableTHeadRow: Cn.c("bg-actions-primary-default"),
  selectedTableTH: Cn.c("bg-actions-primary-default py-3 px-4 table-th-sticky top-0 text-left w-full"),
  thContainer: Cn.c("flex justify-between items-center"),
  leftSelection: Cn.c("flex space-x-3 items-center"),
  rightSelection: Cn.c("flex space-x-3 items-center"),
  normalBulkActionContainer: Cn.c("w-52 right-0"),
  archiveActionContainer: Cn.c("w-52 left-[-0.5rem]"),
  actionContent: Cn.c("flex-row items-center rounded-lg border border-default pr-3"),
  actionSelectText: Cn.c("text-emphasized pr-1"),
  actionInput: Cn.c("border-0 pr-0 py-3"),
  actionIcon: Cn.c("static"),
  selectedApplicationText: Cn.c("text-on-primary font-paragraph-small-semi-bold"),
  selectAllText: Cn.c("text-on-primary font-paragraph-small-link-medium underline cursor-pointer"),
  topText: Cn.c("font-paragraph-xsmall-semi-bold text-subdued mb-1"),
  bottomText: Cn.c("font-paragraph-xsmall-regular text-subdued"),
  emptyTable: Cn.c(
    "w-full flex flex-col items-center justify-center bg-surface-default min-h-empty-table",
  ),
  checkbox: Cn.c("table-td text-center"),
  tableTBodyRow: (isSelected: boolean) =>
    Cn.join([
      Cn.c("table-tbody-row"),
      isSelected ? Cn.c("bg-surface-emphasized") : Cn.c("bg-surface-default"),
    ]),
  tableCell: Cn.c("table-td"),
  tdContentContainer: Cn.c("flex flex-col"),
  upperText: Cn.c("font-paragraph-small-regular text-emphasized mb-1"),
  lowerText: Cn.c("font-paragraph-xsmall-regular text-subdued"),
  defaultText: Cn.c("font-paragraph-small-regular text-emphasized"),
  checkboxCol: Cn.c("w-[4.32%]"),
  wideCol: Cn.c("w-[20.77%]"),
  narrowCol: Cn.c("w-[12.62%]"),
};

interface Props {
  clientHasNoProcess: boolean;
  applications: SimpleApplication[];
  totalSearchResults: number;
  openCreateApplicationModal: VoidFunction;
}

const ApplicationsTable: FunctionComponent<Props> = ({
  applications,
  clientHasNoProcess,
  totalSearchResults,
  openCreateApplicationModal,
}) => {
  const {
    isPageSelected,
    isSelectedApplication,
    toggleSelect,
    openRecruitmentProcessPanel,
    currentApplicationId,
    isAnyApplicationSelected,
    numberOfSelectedApplications,
    selectAll,
    selectPage,
    applicationType,
  } = useApplicationTableHook(applications);

  const { t } = useTranslation();

  return (
    <div className={styles.tableInnerContainer(applications.length)}>
      <table className={styles.table}>
        <thead>
          {!isAnyApplicationSelected
            ?
            <tr className={styles.tableTHeadRow}>
              <th className={Cn.join([styles.tableTH, styles.checkbox, styles.checkboxCol])}>
                <Checkbox
                  id="applicationDeselection"
                  size="md"
                  checked={isPageSelected}
                  onChange={selectPage}
                />
              </th>
              <th className={Cn.join([styles.tableTH, styles.wideCol])}>
                <p className={styles.topText}>{t("applications.list.table.name")}</p>
                <p className={styles.bottomText}>{t("applications.list.table.university")}</p>
              </th>
              <th className={Cn.join([styles.tableTH, styles.narrowCol])}>
                <p className={styles.topText}>{t("applications.list.table.recruitmentState")}</p>
              </th>
              <th className={Cn.join([styles.tableTH, styles.wideCol])}>
                <p className={styles.topText}>{t("applications.list.table.jobTitle")}</p>
                <p className={styles.bottomText}>{t("applications.list.table.entryChannel")}</p>
              </th>
              <th className={Cn.join([styles.tableTH, styles.wideCol])}>
                <p className={styles.topText}>{t("applications.list.table.recruitmentStage")}</p>
              </th>
              <th className={Cn.join([styles.tableTH, styles.wideCol])}>
                <p className={styles.topText}>{t("applications.list.table.currentStep")}</p>
                <p className={styles.bottomText}>{t("applications.list.table.stepStatus")}</p>
              </th>
            </tr>
            :
            <tr className={styles.selectedTableTHeadRow}>
              <th className={Cn.join([styles.selectedTableTH, styles.checkboxCol, Cn.c("pr-3 z-[55]")])}>
                <DeselectButton
                  currentPageApplications={applications}
                />
              </th>
              <th className={Cn.join([styles.selectedTableTH, Cn.c("pl-0")])} colSpan={5}>
                <div className={styles.thContainer}>
                  <div className={styles.leftSelection}>
                    <p className={styles.selectedApplicationText}>
                      {
                        numberOfSelectedApplications === totalSearchResults
                          ? t('applications.list.table.allApplicationsSelected', { count: numberOfSelectedApplications })
                          : t('applications.list.table.applicationSelected', { count: numberOfSelectedApplications })
                      }
                    </p>
                    {numberOfSelectedApplications !== totalSearchResults &&
                      <p className={styles.selectAllText} onClick={selectAll}>
                        {t('applications.list.table.selectAllCandidates', { count: totalSearchResults })}
                      </p>
                    }
                  </div>
                  <div className={styles.rightSelection}>
                    <BulkActionMenu />
                  </div>
                </div>
              </th>
            </tr>
          }
        </thead>
        <tbody>
          {
            clientHasNoProcess
              ? <tr>
                <td colSpan={6}>

                  <div className={styles.emptyTable}>
                    <EmptyApplicationsContainer
                      applicationType={applicationType}
                      openCreateApplicationModal={openCreateApplicationModal}
                    />
                  </div>
                </td>
              </tr>
              : applications.length === 0 ?
                <tr>
                  <td colSpan={6}>
                    <div className={styles.emptyTable}>
                      <EmptyStateContainer
                        icon='notFound'
                        description='applications.list.mismatchedUnderSelection.description'
                        subDescription='applications.list.mismatchedUnderSelection.subDescription'
                      />
                    </div>
                  </td>
                </tr>
                : applications.map((edge) => (
                  <tr
                    className={styles.tableTBodyRow(edge.id === currentApplicationId)}
                    key={edge.id}
                    onClick={() => openRecruitmentProcessPanel(edge.id)}
                  >
                    <td
                      className={Cn.join([styles.checkbox, styles.checkboxCol])}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Checkbox
                        size="md"
                        id="applicationIds"
                        checked={isSelectedApplication(edge)}
                        onChange={() => toggleSelect(edge)}
                      />
                    </td>
                    <td className={Cn.join([styles.tableCell, styles.wideCol])}>
                      <div className={styles.tdContentContainer}>
                        <span className={styles.upperText}>
                          {edge.candidate.name}
                        </span>
                        <span className={styles.lowerText}>
                          {getWithDefault(edge.candidate?.lastAffiliation)}
                        </span>
                      </div>
                    </td>
                    <td className={Cn.join([styles.tableCell, styles.narrowCol])}>
                      <div className={styles.tdContentContainer}>
                        <Badge
                          label={edge.currentStep.stage === 'ARCHIVE' ? "applications.list.table.archived" : "applications.list.table.active"}
                          variant={edge.currentStep.stage === 'ARCHIVE' ? 'inactive' : 'success'}
                        />
                      </div>
                    </td>
                    <td className={Cn.join([styles.tableCell, styles.wideCol])}>
                      <div className={styles.tdContentContainer}>
                        <span className={styles.upperText}>
                          {edge.jobPosition.title}
                        </span>
                        <span className={styles.lowerText}>
                          {getWithDefault(edge.candidate.entryChannel?.name)}
                        </span>
                      </div>
                    </td>
                    <td className={Cn.join([styles.tableCell, styles.wideCol])}>
                      <div className={styles.tdContentContainer}>
                        <span className={styles.defaultText}>
                          {edge.currentStep.stage != 'ARCHIVE' ? t(`recruitmentStage.${edge.currentStep.stage}`) : getWithDefault()}
                        </span>
                      </div>
                    </td>
                    <td className={Cn.join([styles.tableCell, styles.wideCol])}>
                      <div className={styles.tdContentContainer}>
                        <span className={styles.upperText}>
                          {edge.currentStep.stage != 'ARCHIVE'
                            ? edge.currentStep.name
                            : t("applications.list.table.archived")
                          }
                        </span>
                        <span className={styles.lowerText}>
                          {t(`recruitmentStepStatusV2.${edge.currentStepStatusV2}`)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default ApplicationsTable;
