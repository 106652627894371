import { isDefined } from "@helpers/core/typeGuards";
import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";
import { FormProps } from "@typedefs/props";
import { SelectionOption } from "@typedefs/selectOption";
import { useMemo, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";

type Mode = 'show' | 'hide';

const useSelect = <FieldsType extends FieldValues>(
    name: FormProps<FieldsType>['name'],
    options: SelectionOption[],
    noEmpty: boolean,
    clearErrors?: FormProps<FieldsType>['clearErrors'],
    value?: string,
    onChange?: (value: string) => void,
    onBlur?: VoidFunction
) => {
    const [mode, setMode] = useState<Mode>('hide');

    const selectRef = useRef<HTMLDivElement>(null);

    useClickOutside([selectRef], () => {
        if(mode === 'show') {
            setMode('hide');
            isDefined(onBlur) && onBlur();
        }
        
    });

    const toggle = () => {
        setMode(mode === 'show' ? 'hide' : 'show');

        isDefined(clearErrors) && clearErrors(name);
    }

    const onToggleSelect = (currentValue: string) => {
        if (noEmpty && currentValue === value) {
            return;
        }

        const newValue = currentValue === value ? '' : currentValue;

        isDefined(onChange) && onChange(newValue);
        setMode('hide');
    };

    const getSelectedLabel = (options: SelectionOption[], selectedId?: string) => {
        const selectedOption = options.find(option => selectedId === option.value);

        if (isDefined(selectedOption)) {
            return isDefined(selectedOption.labelSuffix)
                ? `${selectedOption.label} ${selectedOption.labelSuffix}`
                : selectedOption.label;
        }

        return '';
    };

    const selectedValueLabel = useMemo(() => getSelectedLabel(options, value), [options, value]);

    return {
        mode,
        selectRef,
        onToggleSelect,
        selectedValueLabel,
        toggle,
    };
}

export {
    useSelect,
    Mode
}