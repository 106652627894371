import { idSchema } from "@helpers/unsorted/zodSchema";
import { z } from "zod";

namespace Encoder {
    export type Type = ReturnType<typeof defaultValue>;

    export const defaultValue = (applicationInformation?: {
        jobPositionId: string;
        recruitmentStepId: string;
        entryCategoryId: string;
        entryChannelId: string;
        acquisitionCategoryId: string;
        acquisitionChannelId: string;
    }) => ({
        jobPositionId: applicationInformation?.jobPositionId || '',
        recruitmentStepId: applicationInformation?.recruitmentStepId || '',
        entryCategoryId: applicationInformation?.entryCategoryId || '',
        entryChannelId: applicationInformation?.entryChannelId || '',
        acquisitionCategoryId: applicationInformation?.acquisitionCategoryId || '',
        acquisitionChannelId: applicationInformation?.acquisitionChannelId || '',
    })
}

namespace Decoder {
    export const schema = z.object({
        jobPositionId: idSchema,
        recruitmentStepId: idSchema,
        entryCategoryId: z.string().nullish(),
        entryChannelId: z.string().nullish(),
        acquisitionCategoryId: z.string().nullish(),
        acquisitionChannelId: z.string().nullish(),
    });

    export type Type = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
}