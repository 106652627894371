import { Entity } from '@typedefs/graphql';
import { RecruitmentProcess_OneByIdQuery, SchoolType as SchoolTypeEntity } from '@entities';
import { hasValue } from '@helpers/core/typeGuards';
import { z } from 'zod';
import {
    birthdateSchema,
    genderSchema,
    graduationYearSchema,
    katakanaSchema,
    monthSchema,
    nonNumberSchema,
} from '@helpers/unsorted/zodSchema';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { fromString } from '@helpers/unsorted/boolExtra';
import { validations } from '@helpers/unsorted/validation';

namespace SchoolType {
    export const schoolTypes = ['GRADUATE_SCHOOL', 'HIGH_SCHOOL', 'JUNIOR_COLLEGE', 'TECHNICAL_COLLEGE', 'UNIVERSITY', 'VOCATIONAL_SCHOOL'] as const;
    export const noSchoolType = [''] as const;

    export type AllSchoolType = SchoolTypeEntity | '';

    export namespace SchoolTypeDecoder {
        export const schema = z.enum([...schoolTypes, ...noSchoolType]).transform(values => values === '' ? undefined : values);
    }
}

namespace FieldType {
    export const fieldTypes = ['HUMANITIES', 'SCIENCE'] as const;
    export const noFieldType = [''] as const;

    export type AllFieldType = typeof fieldTypes[number] | '';

    export namespace FieldTypeDecoder {
        export const schema = z.enum([...fieldTypes, ...noFieldType]).transform(values => values === '' ? undefined : values);
    }
}

namespace Encoder {
    export interface EncoderType {
        acquisitionCategoryId: string;
        acquisitionChannelId: string;
        entryCategoryId: string;
        entryChannelId: string;
        fullName: string;
        fullNameKana: string;
        dateOfBirth: string;
        gender: string;
        email: string;
        mobilePhoneNumber: string;
        livesOverseas: string;
        zipCode: string;
        prefectureId: string;
        address: string;
        phoneNumber: string;
        graduationYear: string;
        graduationMonth: string;
        schoolType: string;
        fieldType: string;
        schoolId: string;
        schoolDepartmentId: string;
        seminarLab: string;
        researchTheme: string;
        clubActivity: string;
        vacationZipCode: string;
        vacationPrefectureId: string;
        vacationAddress: string;
        vacationPhoneNumber: string;
        mobileEmail: string;
        lastJobPosition: string;
        departmentAndPositionTitle: string;
        remarks: string;
    }

    export const defaultValues = (candidate: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate'>): EncoderType => {
        const livesOverseas = !candidate.livesInJapan;

        return {
            acquisitionCategoryId: candidate.acquisitionCategory?.id || '',
            acquisitionChannelId: candidate.acquisitionChannel?.id || '',
            entryCategoryId: candidate.entryCategory?.id || '',
            entryChannelId: candidate.entryChannel?.id || '',
            fullName: candidate.name,
            fullNameKana: candidate.nameKana || '',
            dateOfBirth: hasValue(candidate.dateOfBirth) ? dateFormat.simpleDateWithoutWeekday(new Date(candidate.dateOfBirth), '-') : '',
            gender: candidate.gender || '',
            email: candidate.email,
            mobilePhoneNumber: candidate.mobilePhoneNumber || '',
            livesOverseas: livesOverseas.toString(),
            zipCode: candidate.zipCode || '',
            prefectureId: candidate.prefecture?.id || '',
            address: candidate.address || '',
            phoneNumber: candidate.phoneNumber || '',
            graduationYear: candidate.graduationYear?.toString() || '',
            graduationMonth: candidate.graduationMonth ? (candidate.graduationMonth - 1).toString() : '',
            schoolType: candidate.school?.type || '',
            fieldType: candidate.fieldType === 'UNSPECIFIED' ? '' : candidate.fieldType,
            schoolId: candidate.school?.id || '',
            schoolDepartmentId: candidate.schoolDepartment?.id || '',
            seminarLab: candidate.seminarLaboratory || '',
            researchTheme: candidate.majorTheme || '',
            clubActivity: candidate.clubActivity || '',
            vacationZipCode: candidate.vacationZipCode || '',
            vacationPrefectureId: candidate.vacationPrefecture?.id || '',
            vacationAddress: candidate.vacationAddress || '',
            vacationPhoneNumber: candidate.vacationPhoneNumber || '',
            mobileEmail: candidate.mobileEmail || '',
            lastJobPosition: candidate.lastJobPosition || '',
            departmentAndPositionTitle: candidate.departmentAndPositionTitle || '',
            remarks: candidate.remarks || '',
        };
    }
}

namespace Decoder {
    export const schema = z.object({
        acquisitionCategoryId: z.string().nullish(),
        acquisitionChannelId: z.string().nullish(),
        entryCategoryId: z.string().nullish(),
        entryChannelId: z.string().nullish(),
        fullName: nonNumberSchema,
        fullNameKana: katakanaSchema.nullish(),
        dateOfBirth: z.union([z.string().length(0), birthdateSchema]),
        gender: genderSchema.nullish(),
        email: z.string().email(),
        mobilePhoneNumber: z.string().nullish(),
        livesOverseas: z.string(),
        zipCode: z.string().nullish(),
        prefectureId: z.string().nullish(),
        address: z.string().nullish(),
        phoneNumber: z.string().nullish(),
        graduationYear: graduationYearSchema.nullish(),
        graduationMonth: monthSchema.nullish(),
        schoolType: SchoolType.SchoolTypeDecoder.schema,
        fieldType: FieldType.FieldTypeDecoder.schema,
        schoolId: z.string().nullish(),
        schoolDepartmentId: z.string().nullish(),
        seminarLab: z.string().nullish(),
        researchTheme: z.string().nullish(),
        clubActivity: z.string().nullish(),
        vacationZipCode: z.string().nullish(),
        vacationPrefectureId: z.string().nullish(),
        vacationAddress: z.string().nullish(),
        vacationPhoneNumber: z.string().nullish(),
        mobileEmail: z.string().nullish(),
        lastJobPosition: z.string().nullish(),
        departmentAndPositionTitle: z.string().nullish(),
        remarks: z.string().nullish(),
    }).transform((values, ctx) => {
        try {
            const livesOverseas = fromString(values.livesOverseas);
            const zipCode = values.zipCode && validations.extractDigitsFromString(validations.convertDoubleWidthToRoman(values.zipCode));
            const vacationZipCode = values.vacationZipCode && validations.extractDigitsFromString(validations.convertDoubleWidthToRoman(values.vacationZipCode));

            return {
                ...values,
                acquisitionCategoryId: values.acquisitionCategoryId || null,
                acquisitionChannelId: values.acquisitionChannelId || null,
                entryCategoryId: values.entryCategoryId || null,
                entryChannelId: values.entryChannelId || null,
                fullNameKana: values.fullNameKana || null,
                dateOfBirth: values.dateOfBirth || null,
                gender: values.gender || null,
                mobilePhoneNumber: values.mobilePhoneNumber || null,
                livesOverseas,
                zipCode: zipCode || null,
                prefectureId: values.prefectureId || null,
                address: values.address || null,
                phoneNumber: values.phoneNumber || null,
                graduationYear: values.graduationYear || null,
                graduationMonth: values.graduationMonth || null,
                fieldType: values.fieldType,
                schoolId: values.schoolId || null,
                schoolDepartmentId: values.schoolDepartmentId || null,
                seminarLab: values.seminarLab || null,
                researchTheme: values.researchTheme || null,
                clubActivity: values.clubActivity || null,
                vacationZipCode,
                vacationPrefectureId: values.vacationPrefectureId || null,
                vacationAddress: values.vacationAddress || null,
                vacationPhoneNumber: values.vacationPhoneNumber || null,
                mobileEmail: values.mobileEmail || null,
                lastJobPosition: values.lastJobPosition || null,
                departmentAndPositionTitle: values.departmentAndPositionTitle || null,
                remarks: values.remarks || null,
            };
        } catch (error) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid candidate profile input',
            });

            return z.NEVER;
        }
    });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
    SchoolType,
};