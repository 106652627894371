import { EventSessionGroupLocationType, EventSession_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { useState } from 'react';

interface HideSessionGroupManipulationMode {
    name: 'hide';
}

interface DeleteEventSessionGroupMode {
    name: 'deleteEventSessionGroup';
    payload: {
        groupId: string;
    };
}

interface ManipulateLocationMode {
    name: 'manipulateEventSessionGroupLocation';
    payload: {
        groupId: string;
        groupPosition: number;
        location: string;
        locationType?: EventSessionGroupLocationType | null;
    };
}

interface ManipulateExaminersMode {
    name: 'manipulateEventSessionGroupExaminers';
    payload: {
        groupId: string;
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[];
    };
}

interface ManipulateCandidateMode {
    name: 'manipulateEventSessionGroupCandidate';
    payload: {
        group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>;
    }
}

// TODO: Add more event session group manipulation modes

type SessionGroupManipulationMode =
    HideSessionGroupManipulationMode
    | DeleteEventSessionGroupMode
    | ManipulateLocationMode
    | ManipulateExaminersMode
    | ManipulateCandidateMode;

const useEventSessionDetailViewMode = () => {
    const [mode, setMode] = useState<SessionGroupManipulationMode>({ name: 'hide' })

    const [isAddEventSessionGroupModalOpen, setIsAddEventSessionGroupModalOpen] = useState(false);

    const openAddEventSessionGroupModal = () => setIsAddEventSessionGroupModalOpen(true);

    const closeAddEventSessionGroupModal = () => setIsAddEventSessionGroupModalOpen(false);

    const closeManipulateSessionGroupModal = () => {
        setMode({
            name: 'hide'
        });
    };

    const openDeleteEventSessionGroupConfirmationModal = (groupId: string) => {
        setMode({
            name: 'deleteEventSessionGroup',
            payload: {
                groupId,
            },
        });
    };

    const openEditEventSessionGroupLocation = ({ groupId, groupPosition, location, locationType }: {
        groupId: string;
        groupPosition: number;
        location: string;
        locationType?: EventSessionGroupLocationType | null;
    }) => {
        setMode({
            name: 'manipulateEventSessionGroupLocation',
            payload: {
                groupId,
                groupPosition,
                location,
                locationType,
            },
        });
    };

    const openEditEventSessionGroupExaminersModal = (
        groupId: string,
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[],
    ) => {
        setMode({
            name: 'manipulateEventSessionGroupExaminers',
            payload: {
                groupId,
                examinerAssignments,
            },
        });
    };

    const openEditEventSessionGroupCandidate = (group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>) => {
        setMode({
            name: 'manipulateEventSessionGroupCandidate',
            payload: {
                group,
            },
        });
    };

    return {
        isAddEventSessionGroupModalOpen,
        openAddEventSessionGroupModal,
        closeAddEventSessionGroupModal,
        mode,
        closeManipulateSessionGroupModal,
        openDeleteEventSessionGroupConfirmationModal,
        openEditEventSessionGroupLocation,
        openEditEventSessionGroupExaminersModal,
        openEditEventSessionGroupCandidate,
    };
};

export {
    useEventSessionDetailViewMode,
    SessionGroupManipulationMode
};
