import { RecruitmentStepEvaluation } from "@entities";
import { MappedEvaluationsType } from "@pages/ApplicationsPage/reducer";

export const getPublishedEvaluations = (evaluations: RecruitmentStepEvaluation[]) => {
    const publishedEvaluations = evaluations.filter(evaluation => !evaluation.isDraft);

    const mappedEvaluations: MappedEvaluationsType = new Map();
    const respondents: Set<string> = new Set();

    publishedEvaluations.map(evaluation =>
        evaluation.responses.map(response => {
            const key = response.question;

            const clientName = evaluation.clientName;
            respondents.add(clientName);

            const value = {
                clientName,
                response: response.response,
            };

            const existingValues = mappedEvaluations.get(key) || [];
            existingValues.push(value);
            mappedEvaluations.set(key, existingValues);
        }));

    return {
        respondents,
        mappedEvaluations,
    }
}