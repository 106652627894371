import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useCSVExportingModal } from "./hook";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { hasValue } from "@helpers/core/typeGuards";
import { Button } from "@shared/unsorted/Button/Button";
import { useCurrentLanguage } from "@helpers/core/i18n";
import { Progress } from "@shared/unsorted/Progress/Progress";

const styles = {
    container: Cn.c("py-6"),
    top: Cn.c("px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    content: Cn.c("mt-5 p-4 border border-subdued rounded-xl"),
    contentHeader: Cn.c("flex items-start space-x-4"),
    outerCircle: Cn.c("w-9 h-9 flex items-center bg-surface-primary-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-7 h-7 flex items-center bg-surface-primary-default justify-center rounded-full"),
    fileIcon: Cn.c("w-4 h-4 text-icons-primary-default"),
    fileName: Cn.c("text-emphasized font-paragraph-small-medium mb-1"),
    progressBarContainer: Cn.c("mt-3"),
    buttons: Cn.c("mt-6 border-t border-t-subdued pt-6 px-6"),
    info: Cn.c("w-full"),
}

interface Props extends ModalProps {
    fileName?: string;
    onCancel: VoidFunction
    onError: VoidFunction
    exportAction: string
    progressValue: number
}

const CSVExportingModal: FunctionComponent<Props> = ({
    isOpen,
    fileName,
    onCancel,
    onError,
    exportAction,
    progressValue,
}) => {
    const { t } = useTranslation()

    const currentLanguage = useCurrentLanguage()

    const { totalCount } = useCSVExportingModal(onError, isOpen);

    const getExportActionString = () => {
        let action: string = '';
        switch (exportAction) {
            case 'ACTIVE':
                action = t('csvExportingModal.actions.active')
                break;
            case 'ARCHIVE':
                action = t('csvExportingModal.actions.archived')
                break;
        }

        if (currentLanguage === 'en') action = action + ' ';

        return t('csvExportingModal.description', { action })
    };

    return (
        <Modal
            isOpen={isOpen}
            close={() => { }}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.top}>
                    <p className={styles.description}>{getExportActionString()}</p>
                    <p className={styles.subDescription}>{t('csvExportingModal.subDescription')}</p>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>
                            <div className={styles.outerCircle}>
                                <div className={styles.innerCircle}>
                                    <Icon name="file" className={styles.fileIcon} />
                                </div>
                            </div>
                            <div className={styles.info}>
                                <p className={styles.fileName}>{getWithDefault(fileName)}</p>
                                <p className={styles.subDescription}>
                                    {!hasValue(totalCount)
                                        ? getWithDefault()
                                        : t('csvExportingModal.exportedItems', { count: totalCount })
                                    }
                                </p>
                                <div className={styles.progressBarContainer}>
                                    <Progress
                                        progressValue={progressValue}
                                        showPercentage
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        onClick={onCancel}
                        size="md"
                        isFull={true}
                    >
                        {t('global.cancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {
    CSVExportingModal
}