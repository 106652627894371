import { ApplicationExportedToCsvDocument } from "@entities"
import { isDefined } from "@helpers/core/typeGuards";
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { useEffect, useState } from "react"
import { useSubscription } from "urql"

interface CSVExportingModalHookType {
    totalCount?: number | null;
}

const useCSVExportingModal = (onError: VoidFunction, isOpen?: boolean): CSVExportingModalHookType => {
    const [totalCount, setTotalCount] = useState<number | undefined | null>(undefined)

    const [applicationsExportResponse] = useSubscription({ query: ApplicationExportedToCsvDocument })

    useEffect(() => {
        handleResponse(applicationsExportResponse, {
            onData: ({ applicationExportedToCSV }) => {
                if (applicationExportedToCSV.__typename === 'CandidateCSVExporting') {
                    setTotalCount(applicationExportedToCSV.total)
                } else if (applicationExportedToCSV.__typename === 'CandidateCSVExportComplete' && !applicationExportedToCSV.isSuccessful) {
                    onError()
                }
            }
        })
    }, [applicationsExportResponse])

    useEffect(() => {
        if (isDefined(isOpen) && !isOpen) {
            setTotalCount(undefined) // ensure that the total count is reset when the modal is closed
        }
    }, [isOpen])

    return {
        totalCount,
    }
}

export {
    useCSVExportingModal
}