import { SelectableStepTypesDocument, SelectableStepTypesQuery } from "@entities"
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { AsyncResponseType } from "@typedefs/aliases"
import { Entity } from "@typedefs/graphql"
import { useEffect, useState } from "react"
import { useQuery } from "urql"

type InitialStateData = {
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const [recruitmentStepResponse,] = useQuery({
        query: SelectableStepTypesDocument,
    });

    useEffect(() => {
        handleResponse(
            recruitmentStepResponse,
            {
                onData: (data) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                recruitmentStepTypes: data.selectableStepTypes
                            }
                        }
                    })
                },
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [recruitmentStepResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}