import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useState } from "react";

type CopyMode = 'copy' | 'copied'

interface CopyToClipboardHookType {
    mode: CopyMode;
    copyToClipboard: (location: string) => Promise<void>
}

const useCopyToClipboard = (): CopyToClipboardHookType => {
    const [mode, setMode] = useState<CopyMode>('copy')

    const { error: toastError, success: toastSuccess } = useToast()

    const copyToClipboard = async (location: string) => {
        try {
            await navigator.clipboard.writeText(location)
            setMode('copied')
            toastSuccess('event.eventSession.table.copyToClipboard.copiedTooltip')
        } catch (error) {
            toastError('global.error')
        }

    }

    return {
        mode,
        copyToClipboard
    }
}

export {
    CopyMode,
    useCopyToClipboard
};
