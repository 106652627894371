import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from "@helpers/unsorted/classNames"
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { ChildrenProps } from "@typedefs/props";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const styles = {
  thread: Cn.c("border-t border-b border-default"),
  collapsibleHeader: (index: number) =>
    Cn.join([
      Cn.c(
        "flex flex-row flex-1 font-paragraph-small-medium text-primary-default justify-between items-center px-4 py-3",
      ),
      Cn.ifTrue(index % 2 !== 0, Cn.c("bg-surface-primary-default")),
    ]),
  icon: (isCollapsed: boolean) => Cn.join([
    Cn.c("text-icons-primary-default transform transition-all duration-200 w-6 h-6"),
    !isCollapsed ? Cn.c("rotate-180") : Cn.c("rotate-0"),
  ]),
  collapsibleContent: (isCollapsed: boolean) => Cn.join([
    Cn.c("transform transition-all ease-in-out duration-200"),
    !isCollapsed ? Cn.c("opacity-100 visible") : Cn.c("max-h-0 opacity-60 invisible"),
  ]),
  headerDetailsContainer: Cn.c("flex flex-col"),
  headerDate: Cn.c("font-paragraph-xsmall-regular text-subdued"),
  headerSenderName: Cn.c("font-paragraph-small-medium text-default"),
  headerSubject: Cn.c("flex-1 pl-8")
}

interface Props extends ChildrenProps {
  subject: string;
  date: Date;
  sender: string;
  index: number;
  isCollapsed?: boolean;
  isReceived?: boolean;
}

const EmailThreadItem: FunctionComponent<Props> = ({
  subject,
  date,
  sender,
  index,
  isCollapsed: initialCollapsed=true,
  isReceived=true,
  children
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed)

  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    setIsCollapsed(initialCollapsed)
  }, [initialCollapsed])

  return (
    <div className={styles.thread}>
      <div
        className={styles.collapsibleHeader(~index)}
        onClick={_ => {
          setIsCollapsed(isCollapsed => !isCollapsed)
        }}>
        <div className={styles.headerDetailsContainer}>
          <span className={styles.headerDate}> {dateFormat.simpleDate(date, "/", currentLanguage)} </span>
          <span className={styles.headerSenderName}> {sender} </span>
        </div>
        <span className={styles.headerSubject}>
          {isReceived
            ? t('applications.panel.candidateEmail.received')
            : t('applications.panel.candidateEmail.sent')}
          {subject}
        </span>
        <Icon className={styles.icon(isCollapsed)} name='caret' />
      </div>
      <div className={styles.collapsibleContent(isCollapsed)}> {children}</div>
    </div>
  );
};

export {
  EmailThreadItem,
};

