import { ImportApplicationsFromCsvUrlDocument, ValidateCandidateCsvImportFileMutation } from "@entities"
import { isDefined } from "@helpers/core/typeGuards"
import { getQueryContext } from "@helpers/unsorted/urqlExtra"
import { Entity } from "@typedefs/graphql"
import { SelectionOption } from "@typedefs/selectOption"
import { useEffect, useRef, useState } from "react"
import { useMutation } from "urql"
import { useCSVImportFormContext } from "../../useCSVImportFormContext"
import { useStepperContext } from "@shared/layout/stepper/useStepperContext"
import { useApplicationInformationSubFormState } from "@shared/application/ApplicationInformationSubForm/useApplicationInformationSubFormState"
import { SubmitHandler, useForm } from "react-hook-form"
import * as value from "./value"
import { useToast } from "@helpers/hooks/unsorted/toastHook"

const useCSVImportPreviewStep = (
    validationResults: Entity<ValidateCandidateCsvImportFileMutation, 'validateCandidateCSVImportFile.result'>[],
) => {
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [showSetting, setShowSetting] = useState(false)
    const [{ setting, validCSVUrl, stepTracker }, dispatch] = useCSVImportFormContext()
    const { data: applicationInformationSubFormData, setCurrentJobPositionId } = useApplicationInformationSubFormState()
    const { goToStep } = useStepperContext()
    const settingRef = useRef(setting)

    const onSubFormChange = (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => {
        setCurrentJobPositionId(values.jobPositionId)
    }

    const defaultValues = value.Encoder.defaultValues({
        jobPositionId: setting?.jobPosition.id || '',
        recruitmentStepId: setting?.recruitmentStep.id || '',
        acquisitionCategoryId: setting?.acquisitionCategory?.id || '',
        acquisitionChannelId: setting?.acquisitionChannel?.id || '',
        entryCategoryId: setting?.entryCategory?.id || '',
        entryChannelId: setting?.entryChannel?.id || '',
    })

    const form = useForm<value.Encoder.Type>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues,
    });

    const { reset, handleSubmit } = form

    const { error: toastError } = useToast();

    const displayRows = validationResults.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)

    const [_, importCandidateCsv] = useMutation(ImportApplicationsFromCsvUrlDocument);

    const rowsPerPageOptions: SelectionOption[] = [
        {
            label: "10",
            value: "10",
            key: "10"
        },
        {
            label: "25",
            value: "25",
            key: "25"
        },
        {
            label: "50",
            value: "50",
            key: "50"
        },
    ]

    const previousStepName = stepTracker.some(step => step.id === 'errorCorrection') ? 'errorCorrection' : 'setup'

    const changeRowsPerPage = (value: string) => {
        setRowsPerPage(parseInt(value))
        setCurrentPage(1)
    }

    const backToSetup = () => {
        goToStep('setup')
        dispatch({
            name: 'SetStep',
            payload: {
                step: { name: 'setup' }
            }
        })
    }

    const backToErrorCorrection = () => {
        goToStep('errorCorrection')
        dispatch({
            name: 'SetStep',
            payload: {
                step: {
                    name: 'errorCorrection',
                    data: {
                        stepTracker: [
                            { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                            { id: 'errorCorrection', label: 'applications.csvImport.steps.errorCorrection', variant: 'success' },
                            { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'default' },
                            { id: 'import', label: 'applications.csvImport.steps.import', variant: 'default' },
                        ],
                        resetCSVFile: false
                    }
                }
            }
        })
    }


    const backToPreviousStep = () => {
        if (previousStepName === 'errorCorrection') {
            backToErrorCorrection()
        } else {
            backToSetup()
        }
    }

    const startImportingCSV = () => {
        if (isDefined(settingRef.current) && isDefined(validCSVUrl)) {
            importCandidateCsv({
                fileUrl: validCSVUrl,
                jobPositionId: settingRef.current.jobPosition.id,
                recruitmentStepId: settingRef.current.recruitmentStep.id,
                acquisitionChannelId: settingRef.current.acquisitionChannel?.id,
                entryChannelId: settingRef.current.entryChannel?.id,
            }, getQueryContext('RecruitmentProcesses'));

            dispatch({
                name: 'SetStep',
                payload: {
                    step: {
                        name: 'import', data: { totalCount: validationResults.length }
                    }
                }
            })
            goToStep('import')
        }
    };

    const submitForm: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validData = await value.Decoder.schema.parseAsync(data);

            if (!applicationInformationSubFormData.isLoading) {
                const currentJobPositionTitle = applicationInformationSubFormData
                    .data
                    .jobPositions
                    .find(jobPosition => jobPosition.id === validData.jobPositionId)?.title || '';

                const currentRecruitmentStepName = applicationInformationSubFormData
                    .data
                    .recruitmentSteps
                    .find(recruitmentStep => recruitmentStep.id === validData.recruitmentStepId)?.name || '';

                const currentAcquisitionCategory = applicationInformationSubFormData
                    .data
                    .acquisitionCategories
                    .find(acquisitionCategory => acquisitionCategory.id === validData.acquisitionCategoryId);

                const currentAcquisitionChannel = currentAcquisitionCategory?.channels
                    .find(channel => channel.id === validData.acquisitionChannelId)?.name;

                const currentEntryCategory =
                    applicationInformationSubFormData
                        .data
                        .entryCategories
                        .find(entryCategory => entryCategory.id === validData.entryCategoryId);

                const currentEntryChannel = currentEntryCategory?.channels
                    .find(channel => channel.id === validData.entryChannelId)?.name;

                const setting = {
                    jobPosition: {
                        id: validData.jobPositionId,
                        title: currentJobPositionTitle,
                    },
                    recruitmentStep: {
                        id: validData.recruitmentStepId,
                        name: currentRecruitmentStepName,
                    },
                    acquisitionCategory: isDefined(validData.acquisitionCategoryId) && isDefined(currentAcquisitionCategory)
                        ? {
                            id: validData.acquisitionCategoryId,
                            name: currentAcquisitionCategory.name,
                        } : undefined,
                    acquisitionChannel: isDefined(validData.acquisitionChannelId) && isDefined(currentAcquisitionChannel)
                        ? {
                            id: validData.acquisitionChannelId,
                            name: currentAcquisitionChannel,
                        } : undefined,
                    entryCategory: isDefined(validData.entryCategoryId) && isDefined(currentEntryCategory)
                        ? {
                            id: validData.entryCategoryId,
                            name: currentEntryCategory.name,
                        } : undefined,
                    entryChannel: isDefined(validData.entryChannelId) && isDefined(currentEntryChannel)
                        ? {
                            id: validData.entryChannelId,
                            name: currentEntryChannel,
                        } : undefined,
                }

                dispatch({
                    name: 'SetSettings',
                    payload: {
                        setting,
                    },
                });
                settingRef.current = setting;
                setShowSetting(false)
            } else {
                toastError('global.error');
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    useEffect(() => {
        reset(defaultValues);
        settingRef.current = setting;
    }, [setting]);

    return {
        rowsPerPageOptions,
        rowsPerPage,
        changeRowsPerPage,
        getPageCount: (totalRows: number) => Math.ceil(totalRows / rowsPerPage),
        currentPage,
        setCurrentPage,
        displayRows,
        setting,
        backToPreviousStep,
        showSetting,
        setShowSetting,
        onSubFormChange,
        form,
        previousStepName,
        onSubmit: handleSubmit(submitForm),
        applicationInformationSubFormData,
        startImportingCSV
    }
}

export {
    useCSVImportPreviewStep
}