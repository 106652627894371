import { createContext, useContext } from 'react';
import { State, Action } from './reducer';
import { isUndefined } from '@helpers/core/typeGuards';

type CSVBulkEvaluationFormContextType = [state: State, dispatch: (value: Action) => void]
const CSVBulkEvaluationFormContext = createContext<CSVBulkEvaluationFormContextType | undefined>(undefined);

const useCSVBulkEvaluationFormContext = (): CSVBulkEvaluationFormContextType => {
    const context = useContext(CSVBulkEvaluationFormContext);

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <CSVBulkEvaluationFormContext.Provider>...</CSVBulkEvaluationFormContext.Provider> block.');
    }

    return context;
};

export {
    CSVBulkEvaluationFormContext,
    CSVBulkEvaluationFormContextType,
    useCSVBulkEvaluationFormContext,
};