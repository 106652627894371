import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { EvaluationFormUpdateMode, useEvaluationFormUpdateModal } from "./hook";

import { JobPosition_OneByIdWithStepEventsQuery } from "@entities";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { Chip } from "@shared/unsorted/Chip/Chip";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { Entity } from "@typedefs/graphql";
import { EvaluationFormEditMode } from "./EvaluationFormEditMode";
import { PreviewQuestions } from "./PreviewQuestions";
import { WarningModal } from "../../shared/WarningModal";


const styles = {
    header: Cn.c("flex justify-between items-center px-6 pt-6 pb-5 border-b border-b-default"),
    title: Cn.c("font-h4-bold text-emphasized space-y-2"),
    closeIcon: Cn.c("w-6 h-6 text-icons-emphasized"),
    stepInfoContainer: Cn.c("flex space-x-1"),
    modalBody: Cn.c("p-6"),
    buttons: Cn.c("flex flex-row space-x-3 justify-between p-6 border-t border-t-default"),
    disabledEditContainer: Cn.c("w-full"),
    editTooltip: Cn.c("w-fit text-center"),
}

interface Props extends ModalProps {
    evaluationFormMode?: EvaluationFormUpdateMode;
    openEvaluationFormUpdateModal: (payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        questions?: string | null,
        isModifiable: boolean
    }) => void;
    closeModal: VoidFunction;
}

const EvaluationFormUpdateModal: FunctionComponent<Props> = ({ isOpen, closeModal, evaluationFormMode, openEvaluationFormUpdateModal }) => {
    const {
        step,
        mode,
        moveToEdit,
        moveToShow,
        onSubmit,
        isSubmitting,
        isFormModifiable,
        canSave,
        showWarningModal,
        closeConfirmationModal,
        discardAndClose,
        saveAndExit,
    } = useEvaluationFormUpdateModal(openEvaluationFormUpdateModal, closeModal, evaluationFormMode)

    const { t } = useTranslation()

    //TODO: implement check url validation
    return (
        <Modal isOpen={isOpen} close={closeModal} widthClass="w-[59rem]">
            <div className={styles.header}>
                <div className={styles.title}>
                    <p>{t("jobPositions.evaluationFormUpdateModal.title")}</p>
                    <div className={styles.stepInfoContainer}>
                        {step && step.type !== "SYSTEM" &&
                            <Chip
                                variant="outline"
                                isDisabled
                                size="md"
                                iconName="userSearchLine"
                                label={t(`recruitmentStepTypeV2.${step.type}`)} />
                        }
                        <Chip
                            variant="outline"
                            isDisabled
                            size="md"
                            iconName="flagFill"
                            label={getWithDefault(step?.name)} />
                    </div>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
            </div>

            <form onSubmit={onSubmit}>
                <div className={styles.modalBody}>
                    {mode.name === "show"
                        ? <PreviewQuestions
                            label={"jobPositions.evaluationFormUpdateModal.questionList"}
                            questions={mode.payload.questions} />
                        : <EvaluationFormEditMode form={mode.payload.form} />
                    }
                </div>
                <div className={styles.buttons}>
                    {mode.name === "show" ?
                        <>
                            {isFormModifiable
                                ? <Button
                                    size="md"
                                    onClick={moveToEdit}
                                    variant="primaryFilled"
                                    isFull
                                >
                                    {t("global.edit")}
                                </Button>
                                :
                                <ToolTipBox
                                    tooltipContent={t("jobPositions.evaluationFormUpdateModal.disableEditTooltip")}
                                    placement="bottom"
                                    toolTipClassName={styles.editTooltip}
                                >
                                    <div className={styles.disabledEditContainer}>
                                        <Button
                                            isDisabled={true}
                                            size="md"
                                            variant="primaryFilled"
                                            isFull
                                        >
                                            {t("global.edit")}
                                        </Button>
                                    </div>
                                </ToolTipBox>}
                        </>
                        : <>
                            <Button
                                size="md"
                                onClick={moveToShow}
                                variant="secondary"
                                isDisabled={isSubmitting}
                                isFull
                            >
                                {t("global.cancel")}
                            </Button>
                            <Button
                                isDisabled={!canSave}
                                size="md"
                                type="submit"
                                variant="primaryFilled"
                                isLoading={isSubmitting}
                                isFull
                            >
                                {t("global.save")}
                            </Button>
                        </>}
                </div>
            </form>

            <WarningModal
                isOpen={showWarningModal}
                onClose={closeConfirmationModal}
                onDiscard={discardAndClose}
                onSaveAndExit={saveAndExit}
            />
        </Modal>
    );
}

export {
    EvaluationFormUpdateModal
};
