import { AllTagsQuery, JobPositions_AllSimpleQuery } from '@entities';
import { isOneOf } from '@helpers/core/typeGuards';
import { Candidate } from '@routes/candidate';
import { SortItem } from '@shared/unsorted/Sort/Sort';
import { Entity } from '@typedefs/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface ApplicationSortHookType {
    sortOptions: SortItem[];
    currentSortId: string;
    onSort: (sortId: string) => void;
    currentPage: number;
    rowsPerPage: number;
}

const useApplicationSort = (): ApplicationSortHookType => {
    const queryParams = Candidate.useSearchParams()

    const navigate = useNavigate();

    const sortOptions: SortItem[] = [
        {
            label: "applications.list.sort.createdAtDesc",
            id: "CREATED_AT desc",
            value: {
                field: "CREATED_AT",
                direction: "desc"
            }
        },
        {
            label: "applications.list.sort.createdAtAsc",
            id: "CREATED_AT asc",
            value: {
                field: "CREATED_AT",
                direction: "asc"
            }
        },
        {
            label: "applications.list.sort.updatedAtDesc",
            id: "UPDATED_AT desc",
            value: {
                field: "UPDATED_AT",
                direction: "desc"
            }
        },
        {
            label: "applications.list.sort.candidateNameDesc",
            id: "CANDIDATE_NAME desc",
            value: {
                field: "CANDIDATE_NAME",
                direction: "desc"
            }
        },
        {
            label: "applications.list.sort.candidateNameAsc",
            id: "CANDIDATE_NAME asc",
            value: {
                field: "CANDIDATE_NAME",
                direction: "asc"
            }
        }
    ]

    const currentSortId = useMemo(() => `${queryParams.sortType} ${queryParams.order}`, [queryParams.sortType, queryParams.order])

    const onSort = (sortId: string) => {
        const sortItem = sortOptions.find(sortItem => sortItem.id === sortId) || sortOptions[0]

        if (isOneOf(Candidate.supportedSort)(sortItem.value.field)) {
            navigate(Candidate.toRoute({
                ...queryParams,
                sortType: sortItem.value.field,
                order: sortItem.value.direction,
                mode: 'list'
            }))
        }
    }

    return {
        sortOptions,
        currentSortId,
        onSort,
        currentPage: queryParams.page,
        rowsPerPage: queryParams.rowsPerPage,
    }
}

export {
    useApplicationSort
};
