import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { CollapsibleItem } from '@shared/unsorted/CollapsibleItem/CollapsibleItem';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useApplicationHistory } from './hook';
import { DocumentHistory } from './History/DocumentHistory';
import { EventHistory } from './History/EventHistory';
import { hasValue } from '@helpers/core/typeGuards';
import { getWithDefault } from '@helpers/unsorted/stringExtra';
import { useTranslation } from 'react-i18next';
import { ApplicationModalMode } from '@pages/ApplicationsPage/reducer';

const styles = {
    historyContainer: Cn.c('bg-surface-default flex flex-col min-h-application-panel-tab rounded'),
    content: Cn.c('justify-between p-4 space-y-3'),
    row: Cn.c('flex gap-x-2.5 border-b border-subdued pb-2'),
    section: Cn.c('flex gap-x-2'),
    label: Cn.c('font-paragraph-small-regular text-subdued w-20 shrink-0'),
    text: Cn.c('font-paragraph-small-regular text-default'),
};

interface Props {
    applicationId: string
    history: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.history'>[];
    currentStepId: string
    currentStepStatus: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusV2'>;
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
}

const ApplicationHistory: FunctionComponent<Props> = ({ applicationId, history, currentStepId, currentStepStatus, dispatchApplicationModalMode }) => {
    const { t } = useTranslation();

    const { historyEntries, currentStepIndex } = useApplicationHistory(history, currentStepId);

    return (
        <div className={styles.historyContainer}>
            {historyEntries.map((historyItem, index) => {
                const isCurrentStep = historyItem.id === currentStepId;
                const isStepCompleted = index > currentStepIndex; // Note: This is assuming that the history is sorted in reverse chronological order

                return <CollapsibleItem
                    key={historyItem.id}
                    title={historyItem.name}
                    isCollapsed={!isCurrentStep}
                    isDisabled={!isCurrentStep && !isStepCompleted}
                >
                    <div className={styles.content}>
                        {isCurrentStep &&
                            <div className={styles.row}>
                                <div className={styles.section}>
                                    <div className={styles.label}>{t("applications.panel.candidateHistory.labels.currentStatus")}</div>
                                    <div className={styles.text}>{t(`recruitmentStepStatusV2.${currentStepStatus}`)}
                                    </div>
                                </div>
                            </div>
                        }
                        {historyItem.__typename === 'RecruitmentProcessHistoryDocumentStep' &&
                            <DocumentHistory
                                applicationId={applicationId}
                                history={historyItem}
                                isCurrentStep={isCurrentStep}
                                isStepCompleted={isStepCompleted}
                                dispatchApplicationModalMode={dispatchApplicationModalMode}
                            />
                        }
                        {historyItem.__typename === 'RecruitmentProcessHistoryEventStep' &&
                            <EventHistory
                                applicationId={applicationId}
                                history={historyItem}
                                isCurrentStep={isCurrentStep}
                                isStepCompleted={isStepCompleted}
                                dispatchApplicationModalMode={dispatchApplicationModalMode}
                            />
                        }
                    </div>
                </CollapsibleItem>
            })}
        </div>
    );
};

export {
    ApplicationHistory,
};