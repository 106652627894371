import { Cn } from "@helpers/unsorted/classNames";
import { dateTimeFormat } from "@helpers/unsorted/dateTimeFormat";
import { Badge } from "@shared/unsorted/Badge/Badge";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";

const styles = {
    jobMetadata: Cn.c('py-8 border-b border-b-default'),
    titleContainer: Cn.c('flex items-center space-x-1 pb-3'),
    title: Cn.c('font-h1-bold'),
    createInfo: Cn.c('flex space-x-3'),
    detailContainer: Cn.c('flex items-center space-x-1'),
    label: Cn.c('text-subdued font-paragraph-xsmall-regular'),
    detail: Cn.c('font-paragraph-xsmall-medium'),
    icon: Cn.c('text-icons-disabled w-3.5 h-3.5'),
}

interface Props {
    title: string;
    createdAt: string;
    updatedAt: string;
    creator: string;
    isDraft: boolean;
}

const JobPositionDetailMetadata: FunctionComponent<Props> = ({
    title,
    createdAt,
    updatedAt,
    creator,
    isDraft,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.jobMetadata}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>{title}</p>
                {/* <Badge
                    label={isDraft ? "jobPositions.shared.draftStatus.draft" : "jobPositions.shared.draftStatus.published"}
                    variant={isDraft ? "inactive" : "success"}
                /> */}
            </div>
            <div className={styles.createInfo}>
                <div className={styles.detailContainer}>
                    <Icon name="userAccount" className={styles.icon} />
                    <span className={styles.label}>{t("jobPositions.shared.metadata.createdBy")}</span>
                    <span className={styles.detail}>{creator}</span>
                </div>
                <div className={styles.detailContainer}>
                    <Icon name="calendarFill" className={styles.icon} />
                    <span className={styles.label}>{t("jobPositions.shared.metadata.createdAt")}</span>
                    <span className={styles.detail}>{
                        dateTimeFormat.simpleDateTime(new Date(createdAt))
                    }</span>
                </div>
                <div className={styles.detailContainer}>
                    <Icon name="calendarFill" className={styles.icon} />
                    <span className={styles.label}>{t("jobPositions.shared.metadata.lastUpdated")}</span>
                    <span className={styles.detail}>{
                        dateTimeFormat.simpleDateTime(new Date(updatedAt))
                    }</span>
                </div>
            </div>
        </div>
    );
}

export {
    JobPositionDetailMetadata
}