import { isDefined } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';
import { FieldErrors } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';
import { z } from 'zod';

interface RadioInputItemBoxGroupHookType {
    error?: string;
    getIsChecked: (currentValue: string) => boolean;
}

const useRadioInputItemBoxGroup = (
    name: string,
    value?: string,
    errorLabel?: I18nKey,
    errors?: FieldErrors,
): RadioInputItemBoxGroupHookType => {
    const { t } = useTranslation();

    const error = useErrorMessage(
        name,
        errors,
        isDefined(errorLabel) ? t(errorLabel) : name // As of now, there is no label for RadioInputItemBoxGroup in the design so we are using the name as error label
    );

    const getSelectedValue = () => z.string().default('').parse(value);

    const getIsChecked = (currentValue: string) => {
        const selectedValue = getSelectedValue();

        return selectedValue === currentValue;
    }

    return {
        error,
        getIsChecked,
    };
};

export {
    useRadioInputItemBoxGroup
};