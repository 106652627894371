import { Event_OneByIdQuery, ValidateSessionLocationQuery } from "@entities";
import { Entity } from "@typedefs/graphql";
import { getEventSessionName } from "../helpers";
import { SupportedLanguage } from "@helpers/core/i18n";

interface Error {
    eventName?: string;
    sessionName?: string;
    groupNo: number;
}

const uniqueLocationBetweenGroup = (
    {
        locations: formStateLocations,
        position,
        value
    }: {
        locations: string[],
        position: number,
        value: string
    }): [number, number] | undefined => {
    if (value.length === 0) {
        return undefined
    }

    const duplicateIndex = formStateLocations.findIndex((formStateLocation, index) => formStateLocation === value && index != position)

    if (duplicateIndex === -1) {
        return undefined
    }

    return [duplicateIndex, position]
}

const uniqueLocationBetweenSessions = ({ value, event, initLocation, currentLanguage }: {
    value: string,
    event: Entity<Event_OneByIdQuery, 'event'>,
    currentLanguage: SupportedLanguage,
    initLocation?: string,
}): Error | undefined => {
    if (!event.isOnline || value.length === 0 || initLocation === value) {
        return undefined
    }

    for (const session of event.sessions) {
        for (let index = 0; index < session.groups.length; index++) {
            if (session.groups[index].location === value) {
                return {
                    groupNo: index + 1,
                    eventName: undefined,
                    sessionName: getEventSessionName(session, currentLanguage)
                }
            }
        }
    }
}

const uniqueLocationBetweenEvents = ({
    asyncValidateEventSessionLocation,
    event,
    value,
    initLocation
}: {
    asyncValidateEventSessionLocation: (location: string) => Promise<Entity<ValidateSessionLocationQuery, 'validateEventSessionLocation'> | null | undefined>,
    event: Entity<Event_OneByIdQuery, 'event'>,
    value: string,
    initLocation?: string,
}) => {
    if (event.isOnline && initLocation !== value) {
        return asyncValidateEventSessionLocation(value)
    } else {
        return Promise.resolve(null)
    }
}

const mergeTuple = (tuples: [number, number][]): number[][] => {
    const mergedArrays: number[][] = []

    for (const tuple of tuples) {
        let proceed = false;
        for (const array of mergedArrays) {
            if (array.includes(tuple[0]) || array.includes(tuple[1])) {
                array.push(...tuple)
                proceed = true;
                break;
            }
        }

        if (!proceed) {
            mergedArrays.push([...tuple])
        }
    }

    return mergedArrays.map(array => [...new Set(array)])
}

export {
    uniqueLocationBetweenGroup,
    uniqueLocationBetweenSessions,
    uniqueLocationBetweenEvents,
    mergeTuple,
}