import { DocumentDownloadDocument, RecruitmentProcess_OneByIdQuery, UpdateCandidateDocuments_UpdateDocumentDocument } from "@entities";
import { MAX_FILE_SIZE } from '@helpers/core/constants';
import { hasValue, isDefined } from "@helpers/core/typeGuards";
import { useOpenFolder } from "@helpers/hooks/unsorted/rehooks/openFolder/openFolderHook";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { downloadMultipleUrls, downloadUrl } from '@helpers/unsorted/downloadUrl';
import { parseSize, toSizeString } from '@helpers/unsorted/numberExtra';
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { Entity } from "@typedefs/graphql";
import { useEffect, useReducer } from "react";
import { useClient as useURQLClient, useMutation } from "urql";
import { initialState, reducer, Document } from "./reducer";
import { useClient } from "@helpers/hooks/unsorted/clientHook";

interface ListMode {
    name: 'list',
    payload: {
        switchToEdit: VoidFunction;
        downloadDocument: (documentId: string, filename: string) => Promise<void>;
        downloadAllDocuments: VoidFunction;
    }
}

interface EditMode {
    name: 'edit';
    payload: {
        switchToList: VoidFunction;
        removeSelectedDocument: (documentId: string) => void;
        isUploading: boolean;
        currentDocuments: Document[]; // Includes: both uploaded documents and pending documents
        onSubmit: VoidFunction;
        documentUpload: ReturnType<typeof useOpenFolder>;
    }
}

type Mode = ListMode | EditMode

interface ApplicationCandidateDocumentsHookType {
    mode: Mode;
}

const useApplicationCandidateDocuments = (initialDocuments: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[], applicationId: string): ApplicationCandidateDocumentsHookType => {
    const [state, dispatch] = useReducer(reducer, initialState(initialDocuments))

    const {error: toastError, success: toastSuccess} = useToast()

    const urqlClient = useURQLClient()

    const { currentClient } = useClient();

    const documentUpload = useOpenFolder()

    const [updateDocumentsResponse, updateDocuments] = useMutation(UpdateCandidateDocuments_UpdateDocumentDocument)

    const switchToEdit = () => {
        dispatch({
            name: 'SwitchToEdit',
            payload: {
                initialDocuments,
            }
        })
    }

    const switchToList = () => {
        dispatch({
            name: 'SwitchToList'
        })
    }

    const removeSelectedDocument = (documentId: string) => {
        dispatch({
            name: 'RemoveDocument',
            payload: {
                documentId,
                initialDocuments
            }
        })
    }

    const onSubmit = () => {
       const deletedDocumentIds = state.documentsToDelete.map(({id}) => id)

       const uploadedDocuments = state.documentsToUpload.map(({payload: { filename, file}}) => ({
            filename: filename,
            content: file as any,
            isSelfUpload: false,
            uploadedBy: currentClient.clientState === 'loggedIn' ? currentClient.clientInfo.id : undefined,
       }))

       if(deletedDocumentIds.length === 0 && uploadedDocuments.length === 0) {
            dispatch({
                name: 'SwitchToList'
            })
            toastSuccess('global.documentUpload.success')

            return 
       }
  
       updateDocuments({
         applicationId,
         deletedDocumentIds,
         uploadedDocuments,
       }, getQueryContext('Candidate'))
    }

    const downloadDocument = async (documentId: string, filename: string) => {
        const result = await urqlClient.query(DocumentDownloadDocument, {
            id: documentId
        }).toPromise()

        if(isDefined(result.data) && hasValue(result.data.url)) {
            downloadUrl(filename, result.data.url).then();
        }
    }
    
    const downloadAllDocuments = async () => {
        if (state.isUploading || state.currentDocuments.length === 0) {
            return;
        }
        
        const queries = state.currentDocuments.map(documentInfo => urqlClient.query(DocumentDownloadDocument, {
            id: documentInfo.payload.id,
        }).toPromise());

        const results = await Promise.all(queries);
        
        if (results.length !== state.currentDocuments.length) {
            return;
        }

        const documents = results.reduce<{ fileName: string, url: string }[]>((acc, result, index) => {
            if (isDefined(result.data) && hasValue(result.data.url)) {
                return [
                    ...acc,
                    {
                        fileName: state.currentDocuments[index].payload.filename,
                        url: result.data.url,
                    },
                ];
            } else {
                return acc;
            }
        }, []);
        
        downloadMultipleUrls(documents).then();
    };

    const mode: Mode = 
        state.internalMode === 'list' 
        ? {
            name: 'list',
            payload: {
                switchToEdit,
                downloadDocument,
                downloadAllDocuments,
            }
          } 
        : {
            name: 'edit',
            payload: {
                currentDocuments: state.currentDocuments,
                isUploading: state.isUploading,
                switchToList,
                removeSelectedDocument,
                documentUpload,
                onSubmit
            }
          }

    useEffect(() => {
        handleResponse(updateDocumentsResponse, {
            onFetching: () => {
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: true
                    }
                })
            },
            onError: () => {
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: false
                    }
                })
                toastError('global.documentUpload.fail')
            },
            onData: () => {
                dispatch({
                    name: 'SwitchToList'
                })
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: false
                    }
                })
                toastSuccess('global.documentUpload.success')
            }
        })
    }, [updateDocumentsResponse])

    useEffect(() => {
        const files = documentUpload.files

        if(files) {
            if(files.some(file => file.size > parseSize(MAX_FILE_SIZE.DOCUMENT_UPLOAD))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.DOCUMENT_UPLOAD) })

                return;
            }

            dispatch({
                name: 'UploadDocument',
                payload: {
                    files
                }
            })
        }
    },[documentUpload.files])

    useEffect(() => {
        dispatch({
            name: 'SwitchToList'
        })
    }, [applicationId])
    
    return {
        mode
    }
}

export {
    useApplicationCandidateDocuments
}