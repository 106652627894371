import { ValidateCandidateCsvImportFileDocument } from "@entities";
import { MAX_FILE_SIZE, atsCSVHeaders } from "@helpers/core/constants";
import { isDefined } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useMutation } from "urql";
import { useCSVImportFormContext } from "../../useCSVImportFormContext";
import { useStepperContext } from "@shared/layout/stepper/useStepperContext";
import { useApplicationInformationSubFormState } from "@shared/application/ApplicationInformationSubForm/useApplicationInformationSubFormState";
import { useTranslation } from "react-i18next";
import * as value from "./value";
import { SubmitHandler, useForm } from "react-hook-form";
import { downloadUrl } from "@helpers/unsorted/downloadUrl";
import { parseSize, toSizeString } from "@helpers/unsorted/numberExtra";
import { v4 as uuidv4 } from 'uuid';
import { checkError } from "@pages/application/CSVImportPage/helper";

const useCSVImportSetupStep = () => {
    const { t } = useTranslation();

    const { data: applicationInformationSubFormData, setCurrentJobPositionId } = useApplicationInformationSubFormState()

    const { goToStep } = useStepperContext()

    const [mapping, setMapping] = useState<Record<string, string | undefined>>({})

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [{ setting, csvFile }, dispatch] = useCSVImportFormContext()

    const { error: toastError } = useToast();

    const [validateCsvResponse, validateCandidateCSV] = useMutation(ValidateCandidateCsvImportFileDocument);

    const defaultValues = value.Encoder.defaultValues({
        jobPositionId: setting?.jobPosition.id || '',
        recruitmentStepId: setting?.recruitmentStep.id || '',
        acquisitionCategoryId: setting?.acquisitionCategory?.id,
        acquisitionChannelId: setting?.acquisitionChannel?.id,
        entryCategoryId: setting?.entryCategory?.id,
        entryChannelId: setting?.entryChannel?.id,
    })

    const form = useForm<value.Encoder.Type>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues,
    });

    const { reset, handleSubmit } = form;

    const validateCSV = (currentSetting?: {
        jobPositionId: string;
        recruitmentStepId: string;
        acquisitionCategoryId?: string | undefined;
        acquisitionChannelId?: string | undefined;
        entryCategoryId?: string | undefined;
        entryChannelId?: string | undefined;
    }) => {
        try {
            if (isDefined(csvFile)) {
                validateCandidateCSV({
                    setting: {
                        jobPositionId: currentSetting?.jobPositionId || setting?.jobPosition.id || '',
                        acquisitionChannelId: currentSetting?.acquisitionChannelId || setting?.acquisitionChannel?.id,
                        entryChannelId: currentSetting?.entryChannelId || setting?.entryChannel?.id,
                    },
                    filename: csvFile.filename,
                    content: csvFile.file as any
                });
            }

        } catch (error) {
            toastError('global.error');
        }
    }

    const downloadTemplate = () =>
        downloadUrl(
            t('applications.csvImport.setupStep.templateName'),
            '/import_template.csv',
        );

    const onUpload = (files?: File[]) => {
        if (files?.length) {
            if (files.some(file => file.size > parseSize(MAX_FILE_SIZE.CSV_IMPORT))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.CSV_IMPORT) });
                removeFile()

                return;
            }

            const fileUploaded = files[0];

            dispatch({
                name: 'SetFileUpload',
                payload: {
                    csvFile: {
                        file: fileUploaded,
                        filename: fileUploaded.name,
                        id: uuidv4(),
                    },
                },
            });
        }
    };

    const onSubFormChange = (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => {
        setCurrentJobPositionId(values.jobPositionId)
    }

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validData = await value.Decoder.schema.parseAsync(data);

            const setting = {
                jobPositionId: validData.jobPositionId,
                recruitmentStepId: validData.recruitmentStepId,
                acquisitionCategoryId: validData.acquisitionCategoryId,
                acquisitionChannelId: validData.acquisitionChannelId,
                entryCategoryId: validData.entryCategoryId,
                entryChannelId: validData.entryChannelId,
            }

            if (isDefined(csvFile) && !applicationInformationSubFormData.isLoading) {
                const currentJobPositionTitle = applicationInformationSubFormData
                    .data
                    .jobPositions
                    .find(jobPosition => jobPosition.id === validData.jobPositionId)?.title || '';

                const currentRecruitmentStepName = applicationInformationSubFormData
                    .data
                    .recruitmentSteps
                    .find(recruitmentStep => recruitmentStep.id === validData.recruitmentStepId)?.name || '';

                const currentAcquisitionCategory = applicationInformationSubFormData
                    .data
                    .acquisitionCategories
                    .find(acquisitionCategory => acquisitionCategory.id === validData.acquisitionCategoryId);

                const currentAcquisitionChannel = currentAcquisitionCategory?.channels
                    .find(channel => channel.id === validData.acquisitionChannelId)?.name;

                const currentEntryCategory =
                    applicationInformationSubFormData
                        .data
                        .entryCategories
                        .find(entryCategory => entryCategory.id === validData.entryCategoryId);

                const currentEntryChannel = currentEntryCategory?.channels
                    .find(channel => channel.id === validData.entryChannelId)?.name;

                dispatch({
                    name: 'SetSettings',
                    payload: {
                        setting: {
                            jobPosition: {
                                id: validData.jobPositionId,
                                title: currentJobPositionTitle,
                            },
                            recruitmentStep: {
                                id: validData.recruitmentStepId,
                                name: currentRecruitmentStepName,
                            },
                            acquisitionCategory: isDefined(validData.acquisitionCategoryId) && isDefined(currentAcquisitionCategory)
                                ? {
                                    id: validData.acquisitionCategoryId,
                                    name: currentAcquisitionCategory.name,
                                } : undefined,
                            acquisitionChannel: isDefined(validData.acquisitionChannelId) && isDefined(currentAcquisitionChannel)
                                ? {
                                    id: validData.acquisitionChannelId,
                                    name: currentAcquisitionChannel,
                                } : undefined,
                            entryCategory: isDefined(validData.entryCategoryId) && isDefined(currentEntryCategory)
                                ? {
                                    id: validData.entryCategoryId,
                                    name: currentEntryCategory.name,
                                } : undefined,
                            entryChannel: isDefined(validData.entryChannelId) && isDefined(currentEntryChannel)
                                ? {
                                    id: validData.entryChannelId,
                                    name: currentEntryChannel,
                                } : undefined,
                        },
                    },
                });

                validateCSV(setting)
            } else {
                toastError('global.error');
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    const removeFile = () => dispatch({
        name: 'SetFileUpload',
        payload: {
            csvFile: undefined,
        },
    });

    useEffect(() => {
        reset(defaultValues);
    }, [setting]);

    useEffect(() => {
        handleResponse(validateCsvResponse, {
            onFetching: () => setIsSubmitting(true),
            onError: () => {
                setIsSubmitting(false);
            },
            onData: (data) => {
                setIsSubmitting(false);
                if (checkError(data.validateCandidateCSVImportFile)) {
                    goToStep('errorCorrection')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'errorCorrection',
                                data: {
                                    errorCSVUrl: data.validateCandidateCSVImportFile.errorCSVUrl,
                                    errorSummary: data.validateCandidateCSVImportFile.errorSummary,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                                        { id: 'errorCorrection', label: 'applications.csvImport.steps.errorCorrection', variant: 'selected' },
                                        { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'default' },
                                        { id: 'import', label: 'applications.csvImport.steps.import', variant: 'default' },
                                    ],
                                    resetCSVFile: true
                                }
                            }
                        }
                    })
                } else {
                    goToStep('preview')
                    dispatch({
                        name: 'SetStep',
                        payload: {
                            step: {
                                name: 'preview',
                                data: {
                                    validationResults: data.validateCandidateCSVImportFile.result,
                                    validCSVUrl: data.validateCandidateCSVImportFile.validCSVUrl,
                                    stepTracker: [
                                        { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                                        { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'selected' },
                                        { id: 'import', label: 'applications.csvImport.steps.import', variant: 'default' },
                                    ],
                                }
                            }
                        }
                    })
                }
            },
        });
    }, [validateCsvResponse]);

    useEffect(() => {
        if (csvFile) {
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {

                if (!target || !target.result) {
                    return;
                }

                const csv = Papa.parse(target.result as string, {
                    header: true,
                });

                const mapping = (csv.meta.fields || []).reduce<Record<string, string | undefined>>((acc, header) => {
                    if (atsCSVHeaders.includes(header)) {
                        acc[header] = header;
                    } else {
                        acc[header] = undefined;
                    }

                    return acc
                }, {})

                setMapping(mapping);
            };

            reader.readAsText(csvFile?.file);
        }
    }, [csvFile])

    return {
        applicationInformationSubFormData,
        form,
        onSubmit: handleSubmit(onSubmit),
        onSubFormChange,
        downloadTemplate,
        isSubmitting,
        csvFile,
        onUpload,
        removeFile
    }
}

export {
    useCSVImportSetupStep
};
