import { JobPosition_OneByIdWithStepEventsQuery } from "@entities"
import { allRecruitmentStages, allRecruitmentSteps } from "@helpers/core/constants";
import { RecruitmentStageType, RecruitmentStepType } from "@typedefs/aliases";
import { Entity } from "@typedefs/graphql"
import { z } from 'zod'

namespace Encoder {
    export interface ItemValues {
        item_id: string;
        name: string;
        type: RecruitmentStepType;
        stage: RecruitmentStageType;
        hasDecision: boolean;
        hasEvaluation: boolean;
        hasNotification: boolean;
        previousStepId?: string | null;
        nextStepId?: string | null;
    }

    export interface EncoderType {
        title: string;
        recruitmentSteps: ItemValues[]
    }

    type Item = {
        stage: RecruitmentStageType;
        id?: string;
        name?: string;
        type?: RecruitmentStepType;
        hasDecision?: boolean;
        hasEvaluation?: boolean;
        hasNotification?: boolean;
        previousStepId?: string | null;
        nextStepId?: string | null;
    };

    export const makeItem = (item: Item): ItemValues => {
        const { stage, id, name, type, hasDecision, hasEvaluation, hasNotification, previousStepId, nextStepId } = item;

        return {
            item_id: id ?? '',
            name: name ?? '',
            type: type ?? 'DOCUMENT',
            stage,
            hasDecision: hasDecision ?? false,
            hasEvaluation: hasEvaluation ?? false,
            hasNotification: hasNotification ?? false,
            previousStepId: previousStepId ?? null,
            nextStepId: nextStepId ?? null,
        };
    }

    export const defaultValues = (initialTitle = '', jobRecruitmentSteps: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>[] = []): EncoderType => {
        return {
            title: initialTitle,
            recruitmentSteps: jobRecruitmentSteps.map(step => {
                const { stage, id, name, type, hasDecision, hasEvaluation, previousStepId, nextStepId } = step;

                const hasNotification = step.type === 'EVENT' ? true : step.hasNotification;

                return makeItem({
                    stage,
                    id,
                    name,
                    type,
                    hasDecision,
                    hasEvaluation,
                    hasNotification,
                    previousStepId,
                    nextStepId,
                });
            }),
        }
    }
}

namespace Decoder {
    const recruitmentStepSchema = z.object({
        item_id: z.string().nullish(),
        name: z.string(),
        type: z.enum(allRecruitmentSteps),
        stage: z.enum(allRecruitmentStages),
        hasDecision: z.boolean(),
        hasEvaluation: z.boolean(),
        hasNotification: z.boolean()
    }).transform(({ item_id, ...restStep }) => ({ ...restStep, id: item_id }))

    export const schema = z.object({
        title: z.string().min(1),
        recruitmentSteps: z.array(recruitmentStepSchema)
    })

    export type DecoderType = z.infer<typeof schema>
}

export {
    Encoder,
    Decoder
}
