import { Cn } from "@helpers/unsorted/classNames";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { Button } from "@shared/unsorted/Button/Button";
import { Chip } from "@shared/unsorted/Chip/Chip";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { EmailTemplateMode, useEmailTemplateUpdateModal } from "./hook";

const styles = {
    modal: (isFullScreen: boolean) => Cn.ifTrue(isFullScreen, Cn.c("flex flex-col rounded-none max-h-none h-full")),
    width: (isFullScreen: boolean) => isFullScreen ? Cn.c("w-full") : Cn.c("w-[59rem]"),
    header: Cn.c("flex justify-between items-center px-6 pt-6 pb-5 border-b border-b-default"),
    headerIcon: Cn.c("w-6 h-6 text-icons-emphasized"),
    title: Cn.c("font-h4-bold text-emphasized space-y-2"),
    stepInfoContainer: Cn.c("flex space-x-2"),
    headerButtons: Cn.c("flex space-x-4"),
    modalBody: Cn.c("py-10 px-6 mx-auto mb-auto w-[56rem]"),
    subjectContainer: (isPreviewMode: boolean) => Cn.join([
        Cn.c("pb-6 pl-6 border-b border-b-default flex space-x-8"),
        isPreviewMode ? Cn.c("email-template-preview-subject") : Cn.c("email-template-subject"),
    ]),
    subjectLabel: Cn.c("font-paragraph-base-bold text-emphasized"),
    /* TODO: [CHECK] Remove email-template-body if it is deemed unnecessary when implementing email template editor */
    bodyContainer: (isPreviewMode: boolean) =>
        isPreviewMode ? Cn.c("email-template-preview-body") : Cn.c("email-template-body"),
    button: Cn.c("flex justify-end p-6 border-t border-t-default"),
    previewWarning: Cn.c("bg-surface-warning-subdued py-4 px-6 text-center font-paragraph-small-regular"),
}

interface Props extends ModalProps {
    emailTemplateMode?: EmailTemplateMode;
    closeModal: VoidFunction;
}

const EmailTemplateUpdateModal: FunctionComponent<Props> = ({ isOpen, emailTemplateMode, closeModal }) => {
    const {
        title,
        payload,
        switchToPreviewMode,
        isPreviewMode,
        close,
        isFullScreen,
        switchToFullScreen,
        switchToModal,
    } = useEmailTemplateUpdateModal(closeModal, emailTemplateMode);

    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            close={close}
            widthClass={styles.width(isFullScreen)}
            className={styles.modal(isFullScreen)}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <p>{title}</p>
                    <div className={styles.stepInfoContainer}>
                        {payload && payload.step.type !== 'SYSTEM' &&
                            <Chip
                                variant="outline"
                                isDisabled
                                size="md"
                                iconName="userSearchLine"
                                label={t(`recruitmentStepTypeV2.${payload.step.type}`)} />
                        }
                        <Chip
                            variant="outline"
                            isDisabled
                            size="md"
                            iconName="flagFill"
                            label={getWithDefault(payload?.step.name)} />
                    </div>
                </div>
                <div className={styles.headerButtons}>
                    {isFullScreen
                        ? <Icon
                            name="arrowLeftDownLine"
                            className={styles.headerIcon}
                            onClick={switchToModal}
                        />
                        : <Icon
                            name="arrowRightUpLine"
                            className={styles.headerIcon}
                            onClick={switchToFullScreen}
                        />}
                    <Icon name="close" className={styles.headerIcon} onClick={close} />
                </div>
            </div>
            {isPreviewMode &&
                <div className={styles.previewWarning}>
                    <p>{t("jobPositions.emailTemplateUpdateModal.previewWarning")}</p>
                </div>
            }
            <div className={styles.modalBody}>
                <div className={styles.subjectContainer(isPreviewMode)}>
                    <p className={styles.subjectLabel}>{t('jobPositions.emailTemplateUpdateModal.subject')}</p>
                    {/* TODO: temporary solution */}
                    <p dangerouslySetInnerHTML={{ __html: payload?.emailTemplate.subject || "" }} />
                </div>
                <div className={styles.bodyContainer(isPreviewMode)}>
                    {/* TODO: temporary solution */}
                    <div dangerouslySetInnerHTML={{ __html: payload?.emailTemplate.body || "" }} />
                </div>
            </div>
            <div className={styles.button}>
                {isPreviewMode
                    ? <Button
                        size="md"
                        iconName="exit"
                        onClick={() => switchToPreviewMode(false)}
                        variant="secondary"
                        isFull={!isFullScreen}>
                        {t("jobPositions.emailTemplateUpdateModal.buttons.exitPreview")}
                    </Button>
                    : <Button
                        size="md"
                        iconName="preview"
                        onClick={() => switchToPreviewMode(true)}
                        variant="secondary"
                        isFull={!isFullScreen}>
                        {t("global.preview")}
                    </Button>
                }
            </div>
        </Modal>
    );
}

export {
    EmailTemplateUpdateModal
};
