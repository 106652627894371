import { FunctionComponent } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { Link as ReactRouterLink, To } from 'react-router-dom'

const styles = {
    anchor: (erase: boolean) => Cn.ifTrue(erase, Cn.c('contents')),
};

interface Props extends ChildrenProps {
    to: To;
    replace?: boolean;
    erase?: boolean;
    className?: string;
}

// @ocaml.doc("Creates a safe link to a route in the application. Uses the `a` tag under the hood.
//
// If you need an external link consider using the `ExternalLink` component instead.
//
// - `to`: the `Routes` value to link to
// - `children`: the content of the link
// - `erase`: optional - will apply the `display: contents` style to the inner anchor (defaults to `false`)
// ")
const Link: FunctionComponent<Props> = ({
    to,
    replace = false,
    erase = false,
    className,
    children,
}) => {
    return (
        <ReactRouterLink to={to} className={Cn.join([ styles.anchor(erase), Cn.getSome(className) ])} replace={replace}>
            {children}
        </ReactRouterLink>
    );
};

export {
    Link,
};
