import { FunctionComponent, ReactElement } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { isUndefined } from '@helpers/core/typeGuards';

const styles = {
    root: Cn.c('px-6 py-3 flex gap-x-4 justify-between items-center border-b border-default'),
    title: Cn.c('font-h3-bold text-emphasized py-2'),
    subtitle: Cn.c('font-paragraph-base-regular text-subdued'),
    action: Cn.c('flex gap-x-4'),
    emptyDiv: Cn.c('h-8')
};

interface Props extends ChildrenProps {
    action?: ReactElement | ReactElement[];
}

// @ocaml.doc("The reusable page header component.")
const PageHeader: FunctionComponent<Props> = ({ action, children }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            {/* Note: Temporary fix for pages with empty PageHeader */}
            {(isUndefined(action) || isUndefined(children)) && <div className={styles.emptyDiv} /> }
            <div>{children}</div>
            <div className={styles.action}>{action}</div>
        </div>
    );
};

export {
    PageHeader
};

