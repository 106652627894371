import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Panel } from '@shared/unsorted/Panel/Panel';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useApplicationPanel } from './hook';
import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { Header } from '@shared/unsorted/Panel/Header';
import { ApplicationPanelHeader } from './ApplicationPanelHeader/ApplicationPanelHeader';
import { JobPositionInformation } from './JobPositionInformation/JobPositionInformation';
import { RelatedApplications } from './RelatedApplications/RelatedApplications';
import { ApplicationNote } from './ApplicationNote/ApplicationNote';
import { ApplicationCandidateDocuments } from './ApplicationCandidateDocuments/ApplicationCandidateDocuments';
import { ApplicationCandidateEmail } from './ApplicationCandidateEmail/ApplicationCandidateEmail';
import { Tab } from '@shared/layout/tabs/Tab';
import { Content as TabContent } from '@shared/layout/tabs/Content';
import { Content } from '@shared/unsorted/Panel/Content';
import { useTabs } from '@shared/layout/tabs/useTabs';
import { ApplicationModalMode } from '../reducer';
import { ApplicationInformation } from './ApplicationInformation/ApplicationInformation';
import { ApplicationHistory } from './ApplicationHistory/ApplicationHistory';

const styles = {
    recruitmentPanelContainer: Cn.c('min-w-application-panel flex flex-col h-full'),
    headerContainer: Cn.c('shrink-0'),
    panelContentContainer: Cn.c('flex flex-1 min-h-0 p-4'),
    applicationSection: Cn.c('bg-surface-default drop-shadow-base flex-grow rounded h-full flex flex-col m-0 overflow-y-auto'),
};

interface Props {
    activeApplication?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
}

// @ocaml.doc("The panel component for a recruitment process") 
const ApplicationPanel: FunctionComponent<Props> = ({
    activeApplication,
    dispatchApplicationModalMode,
}) => {
    const { setActiveTab, ControlledTabs } = useTabs('history');

    const {
        closePanel,
        hasAnyUnderSelectionApplication,
        openApplicationDetailPanel,
        applicationStepState,
        dispatchApplicationStepMode,
    } = useApplicationPanel(dispatchApplicationModalMode, activeApplication);

    return (
        <Panel isOpen={isDefined(activeApplication)} variant='applicationPanelSticky'>
            {isDefined(activeApplication) &&
                <div className={styles.recruitmentPanelContainer}>
                    <div className={styles.headerContainer}>
                        <Header close={closePanel}>
                            <ApplicationPanelHeader
                                application={activeApplication}
                                openApplicationDetailPanel={openApplicationDetailPanel} />
                        </Header>
                    </div>
                    <Content className={styles.panelContentContainer}>
                        <RelatedApplications
                            activeApplication={activeApplication}
                        />
                        <div className={styles.applicationSection}>
                            <ApplicationInformation
                                application={activeApplication}
                            />
                            <JobPositionInformation
                                application={activeApplication}
                                disableRevertButton={hasAnyUnderSelectionApplication}
                                setActiveTab={setActiveTab}
                                dispatchApplicationStepMode={dispatchApplicationStepMode}
                                dispatchApplicationModalMode={dispatchApplicationModalMode}
                            />
                            <ApplicationNote
                                id={activeApplication.id}
                                note={activeApplication.note || undefined}
                            />
                            <ControlledTabs className="pt-3">
                                <Tab id="history" label="applications.panel.tabs.history" />
                                <Tab id="candidateDocuments" label="applications.panel.tabs.document" />
                                <Tab id="email" label="applications.panel.tabs.email" />

                                <TabContent id="history">
                                    <ApplicationHistory
                                        applicationId={activeApplication.id}
                                        history={activeApplication.history}
                                        currentStepId={activeApplication.currentStep.id}
                                        currentStepStatus={activeApplication.currentStepStatusV2}
                                        dispatchApplicationModalMode={dispatchApplicationModalMode}
                                    />
                                </TabContent>

                                <TabContent id="candidateDocuments">
                                    <ApplicationCandidateDocuments
                                        documents={activeApplication.candidate.documents}
                                        applicationId={activeApplication.id}
                                    />
                                </TabContent>

                                <TabContent id="email">
                                    <ApplicationCandidateEmail
                                        application={activeApplication}
                                        applicationStepState={applicationStepState}
                                        dispatchApplicationStepMode={dispatchApplicationStepMode}
                                    />
                                </TabContent>
                            </ControlledTabs>
                        </div>
                    </Content>
                </div>
            }
        </Panel>
    );
};

export {
    ApplicationPanel,
};
