import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Button } from '@shared/unsorted/Button/Button';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionByStatus } from './hook';

interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
}

const ActionByStatus: FunctionComponent<Props> = ({ application }) => {
    const { t } = useTranslation();
    const { currentStepStatusV2: currentStepStatus } = application;

    const { moveToEventDetail } = useActionByStatus(application);

    if (currentStepStatus === 'SCHEDULE_ADJUSTMENT') {
        return (
            <Button
                size="md"
                onClick={moveToEventDetail}
            >
                {t("applications.panel.scheduleEvent")}
            </Button>
        );
    }

    // TODO: [CHECK] Pending confirmation from Design, 'hiding' the main action button is the option for now. Once decided, remove this comment.
    return null; // No main action available for the current step status
};

export {
    ActionByStatus,
};