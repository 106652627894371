import { AvailableBulkActionsDocument } from "@entities";
import { bulkActions } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useQueryContext } from "@helpers/unsorted/urqlExtra";
import { Candidate } from "@routes/candidate";
import { ApplicationSelection, useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext";
import { constructBulkActionProperty, SelectedApplications } from "@shared/application/bulkActions/helpers";
import { SimpleApplication } from "@typedefs/aliases";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "urql";

const useApplicationTableHook = (
  applications: SimpleApplication[],
) => {
  const {
    applicationSelection,
    setApplicationSelection,
    selectedApplicationIds,
    addApplications,
    removeApplications,
  } = useApplicationSelectionContext()

  const { error: toastError } = useToast();

  const isAnyApplicationSelected = useMemo(() => Object.values(applicationSelection).length > 0, [applicationSelection]);

  const queryParams = Candidate.useSearchParams();

  const navigate = useNavigate();

  const urqlClient = useClient();

  const [isSendingEmail,] = useState(false);

  const currentApplicationId =
    queryParams.mode.name === "show"
      ? queryParams.mode.payload.applicationId
      : undefined;

  const selectedJobPositionIds = queryParams.jobPositionIds.length
    ? queryParams.jobPositionIds
    : undefined;

  const applicationType = queryParams.applicationType

  const isSelectedApplication = (application: SimpleApplication): boolean => {
    const allActions = Object.keys(applicationSelection).filter(isOneOf(bulkActions));

    return allActions.reduce((isSelected, action) => {
      return isSelected || !!applicationSelection[action]?.[application.id];
    }, false)
  };

  const toggleSelect = (application: SimpleApplication) => {
    const isSelected = isSelectedApplication(application);

    if (!isSelected) {
      addApplications([application]);
    } else {
      removeApplications([application]);
    }
  };

  const selectPage = () => addApplications(applications)

  const isPageSelected = applications.length > 0 && applications.every((application) => isSelectedApplication(application));

  const applicationsQueryContext = useQueryContext("ApplicationApplicableAction");

  const selectAll = async () => {
    const result = await urqlClient.query(AvailableBulkActionsDocument, {
      input: {
        jobPositionIds: selectedJobPositionIds,
        candidateName: queryParams.search.candidateName,
        sortType: queryParams.sortType,
        order: queryParams.order,
        applicationType: queryParams.applicationType !== 'ALL' ? queryParams.applicationType : undefined,
        stepStatuses: queryParams.ongoingStepStatuses,
        stepIds: queryParams.stepIds,
        and: [{
          or: [
            {
              stages: queryParams.stages
            },
            {
              stepStatuses: queryParams.archivedStepStatuses
            },
          ]
        }]
      }
    },
      applicationsQueryContext,
    ).toPromise();

    if (result.error) {
      toastError('global.error');

      return;
    }

    if (result.data) {
      const data: ApplicationSelection = {}

      for (const item of result.data.availableBulkActions) {
        const { action, applications } = item;
        const idsObject: SelectedApplications = {}

        if (isOneOf(bulkActions)(action)) {
          for (const application of applications) {
            idsObject[application.id] = constructBulkActionProperty(action, {
              id: application.id,
              stepId: application.currentStepIdV2,
              eventSessionGroupAssignments: application.eventSessionGroupAssignments
            });
          }

          data[action] = idsObject
        }

      }

      setApplicationSelection(data)
    }
  }

  const openRecruitmentProcessPanel = (newApplicationId: string) => {
    const { mode, ...restQueryParams } = queryParams;

    return navigate(
      Candidate.toRoute({
        ...restQueryParams,
        applicationId: newApplicationId,
        mode: "show",
      }),
    );
  };

  return {
    isPageSelected,
    isSelectedApplication,
    isSendingEmail,
    toggleSelect,
    openRecruitmentProcessPanel,
    applicationType,
    currentApplicationId,
    isAnyApplicationSelected,
    numberOfSelectedApplications: Object.keys(selectedApplicationIds).length,
    selectAll,
    selectPage,
  };
};

export { useApplicationTableHook };

