import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react";
import { I18nKey, useTranslation } from "react-i18next";
import { Icon } from "../Icon/Icon";

const variants = ['default', 'disabled', 'selected', 'success', 'warning', 'fail'] as const;
type Variant = typeof variants[number];

const styles = {
    container: Cn.c("w-full"),
    top: Cn.c("flex items-center"),
    statusIndicator: (variant: Variant) => {
        let variantStyles = Cn.c("")

        switch (variant) {
            case 'default':
                variantStyles = Cn.c("bg-surface-emphasized")
                break;
            case 'disabled':
                variantStyles = Cn.c("bg-surface-disabled")
                break;
            case 'selected':
                variantStyles = Cn.c("bg-surface-dark-selected")
                break;
            case 'success':
                variantStyles = Cn.c("bg-success-default")
                break;
            case 'warning':
                variantStyles = Cn.c("bg-warning-default")
                break;
            case 'fail':
                variantStyles = Cn.c("bg-critical-default")
                break;

            default:
                break;
        }

        return Cn.join([
            variantStyles,
            Cn.c("h-7 w-7 rounded-full flex items-center justify-center mr-1")
        ])
    },
    statusIcon: (variant: Variant) => {
        let variantStyles = Cn.c("")

        switch (variant) {
            case 'default':
                variantStyles = Cn.c("text-subdued font-paragraph-small-bold")
                break;
            case 'disabled':
                variantStyles = Cn.c("text-disabled font-paragraph-small-bold")
                break;
            case 'selected':
                variantStyles = Cn.c("text-on-primary font-paragraph-small-bold")
                break;
            case 'success':
                variantStyles = Cn.c("text-on-primary h-5 w-5")
                break;
            case 'warning':
                variantStyles = Cn.c("text-on-primary h-4 w-4")
                break;
            case 'fail':
                variantStyles = Cn.c("text-on-primary h-5 w-5")
                break;

            default:
                break;
        }

        return variantStyles
    },
    label: (variant: Variant) => {
        let variantStyles = Cn.c("")

        switch (variant) {
            case 'default':
                variantStyles = Cn.c("text-subdued font-paragraph-base-medium")
                break;
            case 'disabled':
                variantStyles = Cn.c("text-disabled font-paragraph-base-medium")
                break;
            case 'selected':
                variantStyles = Cn.c("text-emphasized font-paragraph-base-medium")
                break;
            case 'success':
                variantStyles = Cn.c("text-emphasized font-paragraph-base-medium")
                break;
            case 'warning':
                variantStyles = Cn.c("text-emphasized font-paragraph-base-medium")
                break;
            case 'fail':
                variantStyles = Cn.c("text-emphasized font-paragraph-base-medium")
                break;

            default:
                break;
        }

        return variantStyles
    },
    progress: (variant: Variant) => {
        let variantStyles = Cn.c("")

        switch (variant) {
            case 'default':
                variantStyles = Cn.c("bg-surface-emphasized")
                break;
            case 'disabled':
                variantStyles = Cn.c("bg-surface-disabled")
                break;
            case 'selected':
                variantStyles = Cn.c("bg-surface-dark-selected")
                break;
            case 'success':
                variantStyles = Cn.c("bg-success-default")
                break;
            case 'warning':
                variantStyles = Cn.c("bg-warning-default")
                break;
            case 'fail':
                variantStyles = Cn.c("bg-critical-default")
                break;

            default:
                break;
        }

        return Cn.join([
            variantStyles,
            Cn.c("h-1 mt-3 w-full rounded-3xl")
        ])
    }
}

interface Props {
    variant?: Variant
    label: I18nKey
    stepIndex: number
}

const StepTrackerSegment: FunctionComponent<Props> = ({
    variant = 'default',
    label,
    stepIndex,
}) => {
    const { t } = useTranslation();

    const StatusIndicator = ({ variant, stepIndex }: { stepIndex: number, variant: Variant }) => {
        switch (variant) {
            case 'default':
            case 'disabled':
            case 'selected':
                return <p className={styles.statusIcon(variant)}>{stepIndex}</p>
            case 'success':
                return <Icon name="check" className={styles.statusIcon(variant)} />
            case 'warning':
                return <Icon name="warning" className={styles.statusIcon(variant)} />
            case 'fail':
                return <Icon name="close" className={styles.statusIcon(variant)} />

            default:
                return null
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.statusIndicator(variant)}>
                    <StatusIndicator variant={variant} stepIndex={stepIndex} />
                </div>
                <p className={styles.label(variant)}>{t(label)}</p>
            </div>
            <div className={styles.progress(variant)}></div>
        </div>
    );
}

export {
    StepTrackerSegment,
    variants,
    Variant
}