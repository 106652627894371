import { Cn } from "@helpers/unsorted/classNames";
import { useTranslation } from "react-i18next";

const styles = {
    text: Cn.c('text-warning-default')
}

const ExceedingCapacityText = () => {
    const { t } = useTranslation();

    return (
        <span className={styles.text}>
            {t('applications.bulkEventSessionAssignment.sessionGroupSelection.exceedCapacity')}
        </span>
    )
}

export {
    ExceedingCapacityText
}