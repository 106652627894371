import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from '@helpers/unsorted/classNames';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';
import { EventDetail } from '@routes/event';
import { JobPositionDetail } from '@routes/jobPosition';
import { getEventSessionName } from '@shared/event/helpers';
import { CloseIcon } from '@shared/functional_components/CloseIcon';
import { OpenJobListPanelButton } from '@shared/job_position/JobListPanel/OpenJobListPanelButton';
import { Breadcrumb } from '@shared/unsorted/Breadcrumb/Breadcrumb';
import { Button } from '@shared/unsorted/Button/Button';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { EventSessionDetails } from './EventSessionDetails/EventSessionDetails';
import { useEventSessionDetailViewMode } from './hook';
import { EventSessionGroupAddModal } from './EventSessionGroupAddModal/EventSessionGroupAddModal';
import { EventSessionGroupDeleteModal } from './EventSessionGroupDeleteModal/EventSessionGroupDeleteModal';
import { EventSessionGroupManipulateLocationModal } from './EventSessionGroupManipulateLocationModal/EventSessionGroupManipulateLocationModal';
import { EventSessionGroupManipulateExaminersModal } from './EventSessionGroupManipulateExaminersModal/EventSessionGroupManipulateExaminersModal';
import { EventSessionGroupManipulateCandidateModal } from './EventSessionGroupManipulateCandidateModal/EventSessionGroupManipulateCandidateModal';

const styles = {
    container: Cn.c('flex flex-col bg-surface-default h-full'),
    header: Cn.c('px-6 py-4 flex items-center justify-between'),
    jobListContainer: Cn.c('flex content-center items-center space-x-4'),
    buttons: Cn.c('flex items-center justify-between space-x-4'),
    closeIcon: Cn.c('w-6 h-6 text-icons-emphasized cursor-pointer'),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
    loadingContainer: Cn.c('flex items-center justify-center flex-1'),
};

const EventSessionDetailViewMode: FunctionComponent = () => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();
    const { eventSession, event } = useEventSessionDetailContext()

    const {
        isAddEventSessionGroupModalOpen,
        openAddEventSessionGroupModal,
        closeAddEventSessionGroupModal,
        mode,
        closeManipulateSessionGroupModal,
        openDeleteEventSessionGroupConfirmationModal,
        openEditEventSessionGroupLocation,
        openEditEventSessionGroupExaminersModal,
        openEditEventSessionGroupCandidate,
    } = useEventSessionDetailViewMode();

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.jobListContainer}>
                        <OpenJobListPanelButton />
                        <Breadcrumb
                            items={[
                                {
                                    type: "link",
                                    label: event.recruitmentStepV2.jobPosition.title,
                                    link: generatePath(JobPositionDetail.PATH_NAME, { id: event.recruitmentStepV2.jobPosition.id }),
                                },
                                {
                                    type: "link",
                                    label: event.name,
                                    link: generatePath(EventDetail.PATH_NAME, { id: event.id }),
                                },
                                {
                                    type: "link",
                                    label: getEventSessionName(eventSession, currentLanguage),
                                    link: "", // TODO: Currently no link is provided to avoid refresh. Confirm if it's needed
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <Button size="md" variant="primaryFilled" onClick={openAddEventSessionGroupModal}>{t("event.eventSession.addGroup")}</Button>
                        <CloseIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <EventSessionDetails
                        openDeleteEventSessionGroupConfirmationModal={openDeleteEventSessionGroupConfirmationModal}
                        openEditEventSessionGroupLocation={openEditEventSessionGroupLocation}
                        openEditEventSessionGroupExaminersModal={openEditEventSessionGroupExaminersModal}
                        openEditEventSessionGroupCandidate={openEditEventSessionGroupCandidate} />
                </div>
            </div>
            <EventSessionGroupAddModal
                closeModal={closeAddEventSessionGroupModal}
                isOpen={isAddEventSessionGroupModalOpen}
            />
            <EventSessionGroupDeleteModal
                groupId={mode.name === 'deleteEventSessionGroup'
                    ? mode.payload.groupId
                    : undefined
                }
                closeModal={closeManipulateSessionGroupModal}
                isOpen={mode.name === 'deleteEventSessionGroup'} />
            <EventSessionGroupManipulateLocationModal
                manipulationMode={mode}
                isOpen={mode.name === 'manipulateEventSessionGroupLocation'}
                closeModal={closeManipulateSessionGroupModal}

            />
            <EventSessionGroupManipulateExaminersModal
                manipulationMode={mode}
                isOpen={mode.name === 'manipulateEventSessionGroupExaminers'}
                closeModal={closeManipulateSessionGroupModal}
            />
            <EventSessionGroupManipulateCandidateModal
                isOpen={mode.name === 'manipulateEventSessionGroupCandidate'}
                closeModal={closeManipulateSessionGroupModal}
                manipulationMode={mode}
            />
        </>
    );
};

export {
    EventSessionDetailViewMode
};
