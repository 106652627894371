import { Cn } from "@helpers/unsorted/classNames";
import { ForwardedRefProps, HTMLPassedProps } from "@typedefs/props";
import { FunctionComponent, ReactElement, isValidElement } from "react";
import { I18nKey, useTranslation } from "react-i18next";

const radioInputOrientation = ['horizontal', 'vertical'] as const;
const radioInputSizes = ['sm', 'lg'] as const;

type Orientation = typeof radioInputOrientation[number];
type Size = typeof radioInputSizes[number];

const styles = {
  radioInputContainer: (orientation: Orientation, className?: string) =>
    Cn.join([
      orientation === 'vertical' ? Cn.c("flex flex-col") : Cn.c("flex gap-x-1 items-center"),
      Cn.getSome(className),
    ]),
  radioInput: (size: Size) => size === 'sm' ? Cn.c("h-4 w-4") : Cn.c("h-6 w-6"),
  label: (disabled: boolean, labelClassName?: string) =>
    Cn.join([
      // TODO: [REFACTOR] In zeplin, sm size should have font-paragraph-xsmall-regular.
      Cn.c("font-paragraph-small-regular"),
      Cn.getSome(labelClassName),
      disabled ? Cn.c("text-subdued") : Cn.c("text-default"),
    ])
}

interface Props extends HTMLPassedProps<'value'>, ForwardedRefProps<HTMLInputElement> {
  className?: string;
  label: I18nKey | ReactElement<unknown>;
  labelClassName?: string;
  orientation?: Orientation;
  disabled?: boolean;
  checked: boolean;
  onChange?: (event: any) => void; //TODO: rebind correct type
  prefix?: string;
  size?: Size;
}

// @ocaml.doc("Radio Input Component
//  - `value`: required - the hardcoded value to be applied to the radio input. clicking on the radio input will set this value in state of the React Hook form
//  - `className`: optional - class to be applied to the radio input container
//  - `labelClassName`: optional - class to be applied to the radio input's label
//  - `label`: the label to be displayed for the radio input
//  - `orientation`: optional - how to display the radio input and its' label, either horizontally or vertically - 'horizontal' by default
//  - `disabled`: optional - is the radio input disabled or not - false by default
//  - `checked`: required - function to determine whether this radio input has been selected
//  - `onChange`: optional - function called when a value is selected/ unselected
//  - `prefix`: optional - prefix of the input name
//  - `size`: optional - size of the radio input
// ")
const RadioInput: FunctionComponent<Props> = ({
  value,
  label,
  className,
  labelClassName,
  orientation = 'horizontal',
  disabled = false,
  checked,
  onChange,
  prefix,
  size = 'sm',
  forwardedRef,
}) => {
  const inputName = `${prefix ?? ""}.${value}`
  const { t } = useTranslation()

  return (
    <div className={styles.radioInputContainer(orientation, className)}>
      <input
        type="radio"
        className={styles.radioInput(size)}
        ref={forwardedRef}
        onChange={onChange}
        checked={checked}
        id={inputName}
        name={inputName}
        disabled={disabled}
        value={value}
      />
      <label htmlFor={inputName} className={styles.label(disabled, labelClassName)}>
        {isValidElement(label) ? label : t(label)}
      </label>
    </div>
  );
};

export {
  RadioInput,
  radioInputOrientation,
  radioInputSizes,
  Size,
};

