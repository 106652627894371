import { FunctionComponent } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';

const panelVariants = [
    'panel',
    'applicationPanel',
    'applicationPanelSticky',  // TODO: [REFACTOR] This is a temporary variant only. When all panels are updated to sticky headers, remove this variant and add overflow-y-hidden to the original variant
    'applicationChildPanel',
    'applicationGrandChildPanel',
    'applicationDetailPanel',
] as const;

type Variant = typeof panelVariants[number];

const styles = {
    root: (isOpen: boolean, variant: Variant) => {
        let variantStyle: string;

        switch (variant) {
            case 'panel':
                variantStyle = Cn.c("lg:w-panel w-4/5");
                break;

            case 'applicationPanel':
                variantStyle = Cn.c("lg:w-application-panel w-10/12");
                break;

            // TODO: [REFACTOR] This is a temporary variant only. When all panels are updated to sticky headers, remove this variant and add overflow-y-hidden to the original variant
            case 'applicationPanelSticky':
                variantStyle = Cn.c("lg:w-application-panel w-10/12 overflow-y-hidden");
                break;

            case 'applicationChildPanel':
                variantStyle = Cn.c("lg:w-application-child-panel w-9/12");
                break;

            case 'applicationGrandChildPanel':
                variantStyle = Cn.c("lg:w-application-grandchild-panel w-8/12");
                break;

            case 'applicationDetailPanel':
                variantStyle = Cn.c("lg:w-application-detail-panel w-9/12 overflow-y-hidden");
                break;

            default:
                variantStyle = Cn.c("")
                break;
        }

        return Cn.join([
            Cn.c('absolute right-0 top-0 bottom-0 z-[100] shadow-modal-drop-shadow'),
            variantStyle,
            Cn.c('transition-all transform duration-300 bg-default lg:overflow-x-hidden overflow-y-auto'),
            isOpen ? Cn.c('visible translate-x-0') : Cn.c('invisible translate-x-full'),
        ])
    }
};

interface Props extends ChildrenProps {
    isOpen?: boolean;
    variant?: Variant;
}

// @ocaml.doc("The reusable panel component.")
const Panel: FunctionComponent<Props> = ({ children, isOpen = false, variant = 'panel' }) => {
    return (
        <div className={styles.root(isOpen, variant)}>
            {children}
        </div>
    );
};

export {
    Panel,
    panelVariants,
};
