import { FunctionComponent } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";

import { Cn } from "@helpers/unsorted/classNames";
import { TextArea } from "@shared/unsorted/TextArea/TextArea";
import { useTranslation } from "react-i18next";
import { PreviewQuestions } from "./PreviewQuestions";
import * as value from './value';

const styles = {
    container: Cn.c('space-y-6'),
    questionsContainer: Cn.c('border-b border-b-default pb-6'),
    description: Cn.c('pt-3 font-paragraph-xsmall-regular text-subdued'),
    textArea: Cn.c('h-52'),
};

interface Props {
    form: UseFormReturn<value.Encoder.EncoderType>;
}

const EvaluationFormEditMode: FunctionComponent<Props> = ({ form }) => {
    const { t } = useTranslation();

    const { control, formState: { errors }, clearErrors } = form

    const currentQuestions = useWatch({
        control,
        name: 'questions'
    });

    return (
        <div className={styles.container}>
            <div className={styles.questionsContainer}>
                <Controller
                    name="questions"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <TextArea
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            errors={errors}
                            clearErrors={clearErrors}
                            forwardedRef={ref}
                            value={value}
                            required
                            orientation="vertical"
                            placeholder="jobPositions.evaluationFormUpdateModal.questionsPlaceholder"
                            textAreaClassName={styles.textArea}
                            label="jobPositions.evaluationFormUpdateModal.questions"
                            caption="jobPositions.evaluationFormUpdateModal.questionsDescription"
                        />
                    )}
                />
            </div>

            <PreviewQuestions
                label={"jobPositions.evaluationFormUpdateModal.questionListPreview"}
                questions={currentQuestions} />
        </div>
    );
}

export {
    EvaluationFormEditMode
};
