import { Event_OneByIdQuery } from "@entities";
import { range } from "@helpers/unsorted/arrayExtra";
import { Cn } from "@helpers/unsorted/classNames";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { validations } from "@helpers/unsorted/validation";
import { Button } from "@shared/unsorted/Button/Button";
import { DatePicker } from "@shared/unsorted/DatePicker/DatePicker";
import { Label } from "@shared/unsorted/Label/Label";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { Select } from "@shared/unsorted/Select/Select";
import { Entity } from "@typedefs/graphql";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EventDetails } from "@shared/event/event_session/EventDetails";
import { EventSessionGroupSubForm } from "@shared/event/event_session/EventSessionGroup/EventSessionGroupSubForm";
import { useEventSessionAddModal } from "./hook";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { EventSessionUniqueLocationWarningModal } from "@shared/event/EventSessionUniqueLocationWarningModal/EventSessionUniqueLocationWarningModal";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { TimePicker } from "@shared/unsorted/TimePicker/TimePicker";

const styles = {
  loadingContainer: Cn.c("h-[21rem] rounded-xl"),
  container: Cn.c("max-h-[95%] rounded-xl"),
  form: Cn.c("h-full flex flex-col"),
  header: Cn.c("p-6 flex justify-between items-center border-b border-default"),
  title: Cn.c("text-emphasized font-h4-bold"),
  closeIcon: Cn.c("w-6 h-6 text-emphasized"),
  body: Cn.c("p-6 flex flex-col space-y-6 flex-1"),
  loadingBody: Cn.c("flex items-center justify-center flex-1"),
  row: Cn.c("flex space-x-4 justify-between items-center"),
  dateSpacerText: Cn.c("text-emphasized font-paragraph-small-medium"),
  endTimeContainer: Cn.c("w-full"),
  endTimeText: Cn.c("text-emphasized h-10 font-paragraph-small-regular p-4 flex justify-start items-center"),
  error: Cn.c("text-critical-default font-paragraph-small-regular"),
  groupContainer: Cn.c("mt-6 border-t border-default"),
  groupLabel: Cn.c("py-6 text-emphasized font-paragraph-base-medium"),
  footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
}

interface Props extends ModalProps {
  event: Entity<Event_OneByIdQuery, 'event'>;
  closeModal: VoidFunction;
}

const EventSessionAddModal: FunctionComponent<Props> = ({ isOpen, closeModal, event }) => {
  const { t } = useTranslation()

  const {
    close,
    isSubmitting,
    form,
    onSubmit,
    groupsOptions,
    startDate,
    startTime,
    endTime,
    dateErrors,
    clientOptions,
    dynamicFieldsCount,
    onCloseWarningModal,
    onFinalSubmit,
    mode,
    isLoading,
  } = useEventSessionAddModal(event, closeModal)
  const { clearErrors, control, formState: { errors, isDirty } } = form

  return isLoading
    ? <Modal
      isOpen={isOpen}
      close={close}
      widthClass="w-[50rem]"
      className={styles.loadingContainer}
    >
      <div className={styles.form}>
        <div className={styles.header} data-section="header">
          <p className={styles.title}>{t('event.addSession.title')}</p>
          <Icon name="close" className={styles.closeIcon} onClick={close} />
        </div>
        <div className={styles.loadingBody} data-section="body">
          <Loader
            variant="primaryDefault"
            size="lg"
          />
        </div>
      </div>
    </Modal>
    : mode.name === 'init'
      ? <Modal
        isOpen={isOpen}
        close={close}
        widthClass="w-[50rem]"
        className={styles.container}
      >
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.header} data-section="header">
            <p className={styles.title}>{t('event.addSession.title')}</p>
            <Icon name="close" className={styles.closeIcon} onClick={close} />
          </div>
          <div className={styles.body} data-section="body">
            <EventDetails event={event} />
            <div className={styles.row}>
              <Controller
                name='date'
                control={control}
                rules={{
                  required: true, validate: {
                    wrongDate: (value: string) => validations.validDate(value),
                    conflictingDate: (value: string) => validations.uniqueSessionDateTimeValue({ value, event, startTime })
                  }
                }}
                render={({ field: { name, onBlur, onChange, ref, value } }) => {
                  return <DatePicker
                    name={name}
                    label='event.addSession.startDate'
                    orientation='vertical'
                    required
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    forwardedRef={ref}
                    clearErrors={clearErrors}
                    min={dateFormat.simpleDate(new Date(), "-")}
                  />
                }}
              />
              <Controller
                name='groupCount'
                control={control}
                rules={{ required: true }}
                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                  <Select
                    label='event.addSession.groupCount'
                    tooltip="event.addSession.groupCountTooltip"
                    tooltipPlacement="top"
                    required
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    clearErrors={clearErrors}
                    forwardedRef={ref}
                    value={value}
                    errors={errors}
                    options={groupsOptions}
                  />}
              />
            </div>
            <div className={styles.row}>
              <Controller
                name='startTime'
                control={control}
                rules={{
                  required: true, validate: {
                    conflictingDate: (value: string) => validations.uniqueSessionDateTimeValue({ value, event, startDate })
                  }
                }}
                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                  <TimePicker
                    required
                    label='event.addSession.startTime'
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    clearErrors={clearErrors}
                    forwardedRef={ref}
                    value={value}
                    minuteStep={15}
                  />}
              />
              <p className={styles.dateSpacerText}>~</p>
              <div className={styles.endTimeContainer}>
                <Label
                  label='event.addSession.endTime'
                  tooltip="event.addSession.endTimeTooltip"
                  tooltipPlacement="top">
                </Label>
                <div className={styles.endTimeText}> {endTime} </div>
              </div>
            </div>
            {dateErrors.map((error, index) => <p className={styles.error} key={index}>{error}</p>)}
            <div className={styles.groupContainer}>
              {range(dynamicFieldsCount).map(index => {
                return <div key={index}>
                  <div className={styles.groupLabel}>
                    {t('event.addSession.groupLabel', { count: index + 1, ordinal: true })}
                  </div>
                  <FormProvider {...form}>
                    <EventSessionGroupSubForm
                      isOnline={event.isOnline}
                      clientOptions={clientOptions}
                      path={`groups.${index}`}
                    />
                  </FormProvider>

                </div>
              })}
            </div>
          </div>
          <div className={styles.footer} data-section="footer">
            <Button
              variant="secondary"
              onClick={close}
              size="md"
              isFull={true}
              isDisabled={isSubmitting}
            >
              {t("global.cancel")}
            </Button>
            <Button
              variant="primaryFilled"
              size="md"
              isFull={true}
              type="submit"
              isDisabled={isSubmitting || !isDirty}
              isLoading={isSubmitting}
            >
              {t("global.save")}
            </Button>
          </div>
        </form>
      </Modal>
      : <EventSessionUniqueLocationWarningModal
        onClose={onCloseWarningModal}
        isOpen={isOpen}
        revertToPreviousMode={mode.payload.revertToPreviousMode}
        onSubmit={onFinalSubmit}
        error={mode.payload.error}
      />
};

export {
  EventSessionAddModal
};

