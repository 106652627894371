import { Cn } from "@helpers/unsorted/classNames"
import { useTranslation } from "react-i18next";
import { useCSVBulkEvaluationPage } from "./hook";
import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout";
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader";
import { Button } from "@shared/unsorted/Button/Button";
import { CSVBulkEvaluationForm } from "./CSVBulkEvaluationForm/CSVBulkEvaluationForm";

const styles = {
    title: Cn.c("px-6 pt-6 font-h2-bold text-emphasized"),
}

const CSVBulkEvaluationPage = () => {
    const { t } = useTranslation()

    const {
        switchToTop
    } = useCSVBulkEvaluationPage();

    return (
        <DefaultLayout>
            <PageHeader
                action={[]}
            >
                <Button
                    size="md"
                    variant="ghost"
                    isTrailingIcon={false}
                    iconName="arrowLeftLine"
                    onClick={switchToTop}
                >
                    {t('applications.bulkEvaluation.backToCandidateTop')}
                </Button>
            </PageHeader>
            <p className={styles.title}>{t("applications.bulkEvaluation.title")}</p>
            <CSVBulkEvaluationForm />
        </DefaultLayout >
    )

}

export {
    CSVBulkEvaluationPage
}