type Tree<A> = {
    value: A;
    children: Forest<A>;
};

export const foldTree = <A, B>(tree: Tree<A>, fn: (acc: B, x: A) => B, seed: B): B => {
    return foldForest(tree.children, fn, fn(seed, tree.value));
}

type Forest<A> = Tree<A>[];

export const foldForest = <A, B>(forest: Forest<A>, fn: (acc: B, x: A) => B, seed: B): B => {
    return forest.reduce(
        (acc, tree) => foldTree(tree, fn, acc),
        seed
    )
}

export { Tree, Forest };
