import { useEffect, useState } from 'react';

import { isDefined } from '@helpers/core/typeGuards';

// @ocaml.doc("A hook to return a search term only after a delay in user typing")
const useDebounce = <ValueType>(
    value: ValueType,
    onChange?: (value: ValueType) => void,
    delay = 500,
) => {
      const [ debouncedValue, setDebouncedValue ] = useState(value);

      useEffect(() => {
          const timeoutId = setTimeout(() => {
              setDebouncedValue(value);
          }, delay);

          return () => clearTimeout(timeoutId);
      }, [ value ]);

      useEffect(() => {
          if (isDefined(onChange)) {
              onChange(debouncedValue);
          }
      }, [ debouncedValue ]);

      return debouncedValue;
};

export {
    useDebounce,
};
