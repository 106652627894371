import { FormProps, ForwardedRefProps } from '@typedefs/props';
import { FieldValues } from 'react-hook-form';
import { I18nKey } from 'react-i18next';

import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { SelectionOption } from '@typedefs/selectOption';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { PseudoInput, Size } from '../MultiSelect/PseudoInput/PseudoInput';
import { ToolTipBox, ToolTipBoxProps, TooltipPlacement } from '../ToolTipBox/ToolTipBox';
import { Mode, useSelect } from './hook';

const selectDirection = ['upward', 'downward'] as const;
const optionState = ['selected', 'disabled', 'default'] as const;

type Direction = typeof selectDirection[number];
type OptionState = typeof optionState[number];

const styles = {
    root: Cn.c("w-full"),
    inputLabel: (size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c('font-paragraph-xsmall-medium')
                break;
            case 'medium':
                sizeStyles = Cn.c('font-paragraph-small-medium')
                break;
            case 'large':
            case 'x-large':
                sizeStyles = Cn.c('font-paragraph-base-medium')
                break;
        }

        return Cn.join([
            Cn.c("mb-1 text-emphasized"),
            sizeStyles,
        ])
    },
    selectWrapper: (hasLabel: boolean, direction: Direction, disabled: boolean) => Cn.join([
        Cn.c("flex relative cursor-pointer bg-surface-default"),
        Cn.ifFalse(hasLabel, Cn.c("w-full")),
        direction === 'upward' ? Cn.c("flex-col-reverse") : Cn.c("flex-col"),
        Cn.ifTrue(disabled, Cn.c('bg-surface-disabled')),
    ]),
    input: Cn.c("select-none"),
    caretIcon: (mode: Mode, size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c("right-4 top-2 w-4 h-4")
                break;
            case 'medium':
                sizeStyles = Cn.c("right-4 top-3 w-4 h-4")
                break;
            case 'large':
                sizeStyles = Cn.c("right-4 top-3 w-6 h-6")
                break;
            case 'x-large':
                sizeStyles = Cn.c("right-4 top-5 w-6 h-6")
                break;
        }

        return Cn.join([
            Cn.c("absolute"),
            sizeStyles,
            Cn.ifTrue(mode === 'show', Cn.c("transform rotate-180")),
        ])
    },
    optionsContainer: (direction: Direction, size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = direction === 'upward' ? Cn.c("bottom-10") : Cn.c("top-10")
                break;
            case 'medium':
                sizeStyles = direction === 'upward' ? Cn.c("bottom-12") : Cn.c("top-12")
                break;
            case 'large':
                sizeStyles = direction === 'upward' ? Cn.c("bottom-14") : Cn.c("top-14")
                break;
            case 'x-large':
                sizeStyles = direction === 'upward' ? Cn.c("bottom-[4.5rem]") : Cn.c("top-[4.5rem]")
                break;
        }

        return Cn.join([
            Cn.c("w-full max-h-48 overflow-auto rounded shadow-md bg-surface-default z-[100] absolute"),
            sizeStyles
        ])
    },
    singleOption: (size: Size, state: OptionState) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c("font-paragraph-xsmall-regular")
                break;
            case 'medium':
                sizeStyles = Cn.c("font-paragraph-small-regular")
                break;
            case 'large':
                sizeStyles = Cn.c("font-paragraph-base-regular")
                break;
            case 'x-large':
                sizeStyles = Cn.c("font-paragraph-base-regular")
                break;
        }

        let stateStyles;

        switch (state) {
            case 'disabled':
                stateStyles = Cn.c("text-disabled cursor-not-allowed")
                break;
            case 'selected':
                stateStyles = Cn.c("text-primary-default")
                break;

            default:
                stateStyles = Cn.c("text-emphasized hover:bg-surface-hovered-default")
                break;
        }

        return Cn.join([
            Cn.c("cursor-pointer py-2 px-4 block flex items-center justify-between w-full"),
            sizeStyles,
            stateStyles,
        ])
    },
    leftContainer: Cn.c("flex items-center"),
    icon: Cn.c("mr-2 w-3.5 h-3.5"),
    checkIcon: (size: Size) => {
        let sizeStyles;

        switch (size) {
            case 'small':
                sizeStyles = Cn.c("w-3.5 h-3.5")
                break;
            case 'medium':
                sizeStyles = Cn.c("w-3.5 h-3.5")
                break;
            case 'large':
                sizeStyles = Cn.c("w-4 h-4")
                break;
            case 'x-large':
                sizeStyles = Cn.c("w-4 h-4")
                break;
        }

        return Cn.join([
            Cn.c("text-icons-primary-default"),
            sizeStyles,
        ])
    }
};

interface Props<FieldsType extends FieldValues>
    extends
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLSelectElement> {
    size?: Size;
    required?: boolean;
    placeholder?: I18nKey;
    rawPlaceholder?: string;
    direction?: Direction;
    options: SelectionOption[];
    label?: I18nKey;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    className?: string;
    inputClassName?: string;
    labelClassName?: string;
    optionContainerClassName?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    onBlur?: VoidFunction;
    errorLabel?: I18nKey;
    value?: string;
    warnValidator?: (value: string | undefined) => I18nKey | undefined;
    disabledTooltip?: ToolTipBoxProps;
    placeholderClassName?: string;
    contentClassName?: string;
    iconClassName?: string;
    noEmpty?: boolean;
}

// @ocaml.doc("Select Component
// - `name`: name of the select input
// - `options`: array of object that contains the value, label and key of the options
// - `label`: optional - the label to display for the select input
// - `className`: optional - class to apply to the container
// - `labelClassName`: optional - class to apply to the label
// - `inputClassName`: optional - class to apply to the input
// - `disabled`: optional - is the select input disabled or not - false by default
// - `onChange`: optional - function called when the select input changes
// - `onBlur`: optional - function called when the select input is blurred
// - `value`: optional - value of the select input
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the select input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `required`: bool - show the * for required input - false by default
// - `placeholder`: optional - the placeholder to display in the input
// - `size`: optional - the size of the input
// - `direction`: optional - the direction of the dropdown list
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// - `disabledTooltip`: optional - tooltip to display when the select input is disabled - displayed on the input itself
// - `placeholderClassName`: optional - class to apply to the input's placeholder
// - `contentClassName`: optional - class to apply to the input's content root container
// - `iconClassName`: optional - class to apply to the icon
// - `noEmpty`: optional - not allow an empty selection
// ")
const Select = <FieldsType extends FieldValues>(
    {
        name,
        required: isRequired = false,
        options,
        label,
        className,
        inputClassName,
        labelClassName,
        optionContainerClassName,
        disabled: isDisabled = false,
        onChange,
        onBlur,
        errors,
        clearErrors,
        errorLabel,
        value,
        placeholder = 'form.labels.emptySelect',
        rawPlaceholder,
        size = "medium",
        direction = 'downward',
        tooltip,
        tooltipPlacement,
        warnValidator,
        disabledTooltip: selectDisabledTooltip,
        placeholderClassName,
        contentClassName,
        iconClassName,
        noEmpty = false,
    }: Props<FieldsType>,
) => {
    const {
        mode,
        selectRef,
        onToggleSelect,
        selectedValueLabel,
        toggle,
    } = useSelect(name, options, noEmpty, clearErrors, value, onChange, onBlur);

    const selectContent = (
        <div
            className={Cn.join([styles.selectWrapper(isDefined(label), direction, isDisabled), Cn.getSome(contentClassName)])}
            onClick={() => { !isDisabled && toggle() }}
        >
            <PseudoInput
                pseudoInputClassName={Cn.join([styles.input, Cn.getSome(inputClassName)])}
                title={selectedValueLabel}
                placeholder={placeholder}
                rawPlaceholder={rawPlaceholder}
                name={name}
                errors={errors}
                errorLabel={errorLabel}
                label={label}
                state={mode === 'show' ? 'highlighted' : 'normal'}
                disabled={isDisabled}
                size={size}
                text={selectedValueLabel}
                warnValidator={warnValidator}
                placeholderClassName={placeholderClassName}
            />
            <Icon name='arrowDown' className={Cn.join([styles.caretIcon(mode, size), Cn.getSome(iconClassName)])} />
            {mode === 'show' &&
                <div className={Cn.join([styles.optionsContainer(direction, size), Cn.getSome(optionContainerClassName)])}>
                    {options.map(({ label, value: optionValue, key, disabled, iconName, disabledTooltip, labelSuffix }) => {
                        const state: OptionState = disabled ? 'disabled' : optionValue === value ? 'selected' : 'default';

                        const labelContent = (
                            <label key={key} className={styles.singleOption(size, state)} onClick={() => !disabled && onToggleSelect(optionValue)}>
                                <div className={styles.leftContainer}>
                                    {iconName ? <Icon name={iconName} className={styles.icon} /> : null}
                                    {label}
                                    {isDefined(labelSuffix) && <span>&nbsp;{labelSuffix}</span>}
                                </div>
                                {state === 'selected' && <Icon name='check' className={styles.checkIcon(size)} />}
                            </label>
                        );

                        if (isDefined(disabledTooltip) && disabled) {
                            return (
                                <ToolTipBox
                                    key={key}
                                    delay={isDefined(disabledTooltip.delay) ? disabledTooltip.delay : [500, 0]} // TODO: 500ms show delay is temporary. Might need to play with other select options to make sure it's not too long/short
                                    {...disabledTooltip}
                                >
                                    {labelContent}
                                </ToolTipBox>
                            );
                        }

                        return labelContent;
                    }
                    )}
                </div>
            }
        </div>
    );

    return (
        <div className={Cn.join([styles.root, Cn.getSome(className)])} ref={selectRef}>
            {isDefined(label) &&
                <Label label={label} labelClassName={Cn.join([styles.inputLabel(size), Cn.getSome(labelClassName)])} required={isRequired} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />
            }
            {isDefined(selectDisabledTooltip) && isDisabled
                ? <ToolTipBox
                    {...selectDisabledTooltip}>
                    {selectContent}
                </ToolTipBox>
                : selectContent
            }
        </div>
    );
};

export {
    Select,
    selectDirection
};

