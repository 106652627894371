import {z} from 'zod'

namespace Encoder {
    export interface EncoderType {
        questions: string;
    }

    export const defaultValues = (questions?: string | null): EncoderType => {
        return {
            questions: questions || ''
        }
    }
}

namespace Decoder {
    export const schema = z.object({
        questions: z.string().min(1),
    })

    export type DecoderType = z.infer<typeof schema>
}

export {
    Encoder,
    Decoder
}