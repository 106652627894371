import { Cn } from "@helpers/unsorted/classNames"
import { Button } from "@shared/unsorted/Button/Button"
import { Loader } from "@shared/unsorted/Loader/Loader"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const styles = {
    body: Cn.c("flex justify-center items-center flex-1"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
    rightButtons: Cn.c("flex space-x-3")
}

interface Props {
    closeModal: VoidFunction
    goToSessionSelection: VoidFunction
}

const SessionGroupSelectionStepLoadingState: FunctionComponent<Props> = ({
    closeModal,
    goToSessionSelection
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.body}>
                <Loader
                    variant="primaryDefault"
                />
            </div>
            <div className={styles.footer}>
                <Button
                    size="md"
                    variant="secondary"
                    onClick={closeModal}
                >
                    {t('global.cancel')}
                </Button>
                <div className={styles.rightButtons}>
                    <Button
                        size="md"
                        variant="secondary"
                        onClick={goToSessionSelection}
                        isTrailingIcon={false}
                        iconName="arrowLeftLine"
                    >
                        {t('applications.bulkEventSessionAssignment.sessionGroupSelection.backToSessionSelection')}
                    </Button>
                </div>
            </div>
        </>
    )
}

export {
    SessionGroupSelectionStepLoadingState
}