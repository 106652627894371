import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobPositionInformation } from './hook';
import { ApplicationStepMode } from '../reducer';
import { ApplicationModalMode } from '@pages/ApplicationsPage/reducer';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { OtherActions } from './OtherActions/OtherActions';
import { MainActions } from './MainActions/MainActions';
import { ArchiveActions } from './ArchiveActions/ArchiveActions';

const styles = {
    jobPositionInformationContainer: Cn.c('flex flex-col gap-y-4 bg-surface-default p-4'),
    jobPositionInformationContent: Cn.c('space-y-1'),
    title: Cn.c('font-h3-bold text-default'),
    buttonsContainer: Cn.c('relative flex flex-row justify-between'),
    actionButtonsContainer: Cn.c('flex flex-row space-x-4'),
    emailButton: Cn.c('ml-4'),
    stepInfoContainer: Cn.c('flex space-x-1'),
    stepInfoItem: Cn.c('flex py-1 px-3 border border-subdued rounded-3xl space-x-2 items-center'),
    stepInfoIcon: Cn.c('w-3 h-3 text-icons-default'),
    stepInfoText: Cn.c('font-paragraph-xsmall-medium text-subdued'),
};
interface Props {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    disableRevertButton: boolean;
    setActiveTab: (newActiveTab?: string) => void;
    dispatchApplicationStepMode: (applicationStepMode: ApplicationStepMode) => void;
    dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
}

const JobPositionInformation: FunctionComponent<Props> = ({
    application,
    disableRevertButton,
    setActiveTab,
    dispatchApplicationStepMode,
    dispatchApplicationModalMode,
}) => {
    const { t } = useTranslation();

    const { openEmailEditor, onAction, onRevert } = useJobPositionInformation(
        setActiveTab,
        dispatchApplicationStepMode,
        dispatchApplicationModalMode,
        application,
    );

    const {
        id,
        jobPosition,
        currentStep,
    } = application;

    return (
        <div className={styles.jobPositionInformationContainer}>
            <div className={styles.jobPositionInformationContent}>
                <div className={styles.title}>{jobPosition.title}</div>
                <div className={styles.stepInfoContainer}>
                    <div className={styles.stepInfoItem}>
                        <Icon name="userSearchLine" className={styles.stepInfoIcon} />
                        <p className={styles.stepInfoText}>{t(`jobPositions.panel.recruitmentFlow.stage.${currentStep.stage}.name`)}</p>
                    </div>
                    <div className={styles.stepInfoItem}>
                        <Icon name="flagFill" className={styles.stepInfoIcon} />
                        <p className={styles.stepInfoText}>{currentStep.name}</p>
                    </div>
                </div>
            </div>
            {currentStep.stage !== 'ARCHIVE' &&
                <div className={styles.buttonsContainer}>
                    <div className={styles.actionButtonsContainer}>
                        <MainActions
                            onAction={onAction}
                            application={application}
                        />
                        <OtherActions
                            onAction={onAction}
                            application={application}
                        />
                    </div>
                    <div className={styles.actionButtonsContainer}>
                        <Button size="md"
                            variant="secondary"
                            className={styles.emailButton}
                            onClick={openEmailEditor}
                        >
                            {t("applications.panel.email")}
                        </Button>
                        <ArchiveActions applicationId={id} />
                    </div>
                </div>
            }
            {currentStep.stage === 'ARCHIVE' &&
                <div className={styles.actionButtonsContainer}>
                    <Button
                        size="md"
                        onClick={() => onRevert(id)}
                        isDisabled={disableRevertButton}
                    >
                        {t("applications.panel.revertToActive")}
                    </Button>
                </div>
            }
        </div>
    );
};

export {
    JobPositionInformation,
};