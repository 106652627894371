import { Entity } from '@typedefs/graphql';
import { generatePath, useNavigate } from 'react-router-dom';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { EventDetail } from '@routes/event';

interface ActionByStatusHookResult {
    moveToEventDetail: VoidFunction;
}

const useActionByStatus = (
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>,
): ActionByStatusHookResult => {
    const navigate = useNavigate();

    const moveToEventDetail = () => {
        const currentEventStep = application.history
            .find(item => item.id === application.currentStep.id)

        if (currentEventStep?.__typename === 'RecruitmentProcessHistoryEventStep') {
            const path = generatePath(EventDetail.PATH_NAME, { id: currentEventStep.event.id })

            navigate(path);
        }
    };

    return {
        moveToEventDetail,
    };
};

export {
    useActionByStatus,
};