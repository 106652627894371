import { useJobListPanelContext } from "@shared/job_position/JobListPanel/useJobListPanelContext";
import { Button } from "@shared/unsorted/Button/Button"
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const OpenJobListPanelButton: FunctionComponent = () => {
    const { t } = useTranslation();
    const { showJobListPanel } = useJobListPanelContext();

    return (
        <Button
            variant="secondary"
            size="md"
            iconName="menu"
            onClick={showJobListPanel}
        >
            {t("jobPositions.detail.jobsList")}
        </Button>
    )
}

export {
    OpenJobListPanelButton
}