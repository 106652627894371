import { useEffect } from "react";
import { InitialState } from "./init"
import { useToast } from "@helpers/hooks/unsorted/toastHook";

const useJobPositionDetailEditMode = (initialState: InitialState) => {
    const { error: toastError } = useToast();

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])
}

export {
    useJobPositionDetailEditMode
}