import { OpenFolderInput } from '@helpers/hooks/unsorted/rehooks/openFolder/OpenFolderInput';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { EmptyStateContainer } from '@shared/unsorted/EmptyStateContainer/EmptyStateContainer';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Entity } from '@typedefs/graphql';
import { Row } from './Row';
import { useApplicationCandidateDocuments } from './hook';

const styles = {
    candidateDocumentsContainer: Cn.c('m-4 space-y-6 min-h-application-panel-tab flex flex-col'),
    candidateDocumentsTop: Cn.c('flex flex-row items-center pb-4 border-b-subdued border-b'),
    title: Cn.c('font-paragraph-base-bold text-default flex-1 text-left'),
    table: Cn.c('w-full flex flex-col flex-grow space-y-6'),
    emptyTable: Cn.c('w-full flex flex-col items-center justify-center flex-grow'),
    documentWrapper: Cn.c('border border-default rounded'),
    downloadText: Cn.c('font-paragraph-small-medium text-primary-default cursor-pointer'),
    topButtons: Cn.c('space-x-2 mr-2 flex flex-row'),
    deleteIcon: (isUploading: boolean) => Cn.join([
        Cn.c('text-icons-critical-default w-5 h-5'),
        Cn.ifTrue(isUploading, Cn.c('pointer-events-none'))
        ,]),
    uploadSection: (isUploading: boolean) => Cn.join([
        Cn.c('flex flex-1 flex-col items-center justify-center border-2 border-dashed border-emphasized rounded mb-3 py-14 space-y-4'),
        Cn.ifTrue(isUploading, Cn.c('pointer-events-none')),
    ]),
    uploadInstruction: Cn.c('text-center font-paragraph-xsmall-medium text-subdued'),
    uploadIconContainer: Cn.c('w-10 h-10 bg-surface-dark-subdued flex items-center justify-center rounded-full'),
    uploadIcon: Cn.c('w-6 h-6 text-icons-on-primary'),
};

interface Props {
    documents: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate.documents'>[];
    applicationId: string;
}

const ApplicationCandidateDocuments: FunctionComponent<Props> = ({ documents, applicationId }) => {
    const { t } = useTranslation();

    const {
        mode
    } = useApplicationCandidateDocuments(documents, applicationId);

    return (
        <div className={styles.candidateDocumentsContainer}>
            <div className={styles.candidateDocumentsTop}>
                <div className={styles.title}>
                    {t("applications.panel.candidateDocuments.title")}
                </div>
                {mode.name === "list"
                    ? <div className={styles.topButtons}>
                        <Button size="sm" variant="secondary" onClick={mode.payload.switchToEdit}>
                            {t("global.edit")}
                        </Button>
                        <Button size="sm" variant="secondary" onClick={mode.payload.downloadAllDocuments}>
                            {t("applications.panel.candidateDocuments.downloadAll")}
                        </Button>
                    </div>
                    : <div className={styles.topButtons}>
                        <Button
                            size="sm"
                            variant="secondary"
                            onClick={mode.payload.switchToList}
                            isDisabled={mode.payload.isUploading}>
                            {t("global.cancel")}
                        </Button>
                        <Button
                            size="sm"
                            variant="primaryFilled"
                            onClick={mode.payload.onSubmit}
                            isLoading={mode.payload.isUploading}>
                            {t("global.save")}
                        </Button>
                    </div>
                }
            </div >
            <div className={styles.table}>
                {mode.name === "list" ? (
                    documents.length === 0
                        ? <div className={styles.emptyTable}>
                            <EmptyStateContainer icon="emptyDocument"
                                description={"applications.panel.candidateDocuments.empty.description"}
                                subDescription={"applications.panel.candidateDocuments.empty.subDescription"} />
                        </div>
                        : <div className={styles.documentWrapper}>
                            {documents.map(({ filename, id }) =>
                                <Row key={id} label={filename}>
                                    <p className={styles.downloadText} onClick={() => mode.payload.downloadDocument(id, filename)}>
                                        {t("applications.panel.candidateDocuments.download")}
                                    </p>
                                </Row>
                            )}
                        </div>
                ) : (
                    <>
                        {mode.payload.currentDocuments.length !== 0 &&
                            <div className={styles.documentWrapper}>
                                {mode.payload.currentDocuments.map(({ payload: { id, filename } }) =>
                                    <Row key={id} label={filename}>
                                        <Icon
                                            name="delete"
                                            className={styles.deleteIcon(mode.payload.isUploading)}
                                            onClick={() => mode.payload.removeSelectedDocument(id)}
                                        />
                                    </Row>
                                )}
                            </div>}
                        <div className={styles.uploadSection(mode.payload.isUploading)}>
                            <div className={styles.uploadIconContainer}>
                                <Icon name="upload" className={styles.uploadIcon} />
                            </div>
                            <Button size="md" variant="secondary"
                                onClick={() => mode.payload.documentUpload.openFolder()}>
                                {t("applications.panel.candidateDocuments.uploadSelectButton")}
                            </Button>
                            <div>
                                <div className={styles.uploadInstruction}>
                                    {t("applications.panel.candidateDocuments.uploadSelectSizeInstruction")}
                                </div>
                                <div className={styles.uploadInstruction}>
                                    {t("applications.panel.candidateDocuments.uploadSelectFileInstruction")}
                                </div>
                            </div>
                            <OpenFolderInput
                                multiple={true}
                                accept=".jpeg,.jpg,.png,.bmp,.pdf,.xlsx,.docx,.pptx"
                                openFolderProps={mode.payload.documentUpload.openFolderProps}
                            />
                        </div>
                    </>
                )}
            </div>
        </div >
    );
};

export {
    ApplicationCandidateDocuments
};
