import { createContext, Dispatch, FunctionComponent, SetStateAction } from "react";
import { CurrentClientQuery } from '@entities'
import { ChildrenProps } from "@typedefs/props";

const ClientContext = createContext<[
    client: CurrentClientQuery,
    setClient: Dispatch<SetStateAction<CurrentClientQuery>>
]>([{ currentClient: undefined }, () => {/** do nothing */ }])

interface Props extends ChildrenProps {
    value: [CurrentClientQuery, Dispatch<SetStateAction<CurrentClientQuery>>]
}

const ClientProvider: FunctionComponent<Props> = ({ children, value }) => {
    return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
}

export {
    ClientContext,
    ClientProvider
}