import { BasicCandidateQuery, BasicJobPositionQuery } from "@entities"
import { Entity } from "@typedefs/graphql"
import { v4 as uuidv4 } from 'uuid'

interface PendingDocument {
    name: 'pendingDocument';
    payload: {
        id: string;
        filename: string;
        file: File;
    }
}

interface UploadedDocument {
    name: 'uploadedDocument',
    payload: Entity<BasicCandidateQuery, 'basicCandidate.documents'>
}

type Document = PendingDocument | UploadedDocument

interface DocumentState {
    internalMode: 'list' | 'edit';
    currentDocuments: Document[];
    documentsToUpload: PendingDocument[];
    documentsToDelete: Entity<BasicCandidateQuery, 'basicCandidate.documents'>[];
}

interface LoadedMode {
    name: 'loaded'
    candidate: Entity<BasicCandidateQuery, 'basicCandidate'>
    jobPosition: Entity<BasicJobPositionQuery, 'basicJobPosition'>
    documentState: DocumentState
}

interface ErrorMode {
    name: 'error'
}

interface LoadingState {
    isSubmitting: boolean
    isLoading: true
}

interface LoadedState {
    isSubmitting: boolean
    isLoading: false
    mode: LoadedMode | ErrorMode
}

type State = LoadingState | LoadedState;

interface StartLoadingAction {
    name: 'StartLoading'
}

interface UpdateDocuemntRequirementsAction {
    name: 'UpdateDisplayInformation'
    payload: {
        candidate: Entity<BasicCandidateQuery, 'basicCandidate'>
        jobPosition: Entity<BasicJobPositionQuery, 'basicJobPosition'>
    }
}

interface SetErrorAction {
    name: 'SetError'
}

interface SwitchToEditAction {
    name: 'SwitchToEdit',
}

interface SwitchToListAction {
    name: 'SwitchToList'
}

interface RemoveDocumentAction {
    name: 'RemoveDocument',
    payload: {
        documentId: string;
    }
}

interface UploadDocumentAction {
    name: 'UploadDocument',
    payload: {
        files: File[];
    }
}

interface SetUploadingAction { 
    name: 'SetLoading',
    payload: {
        isUploading: boolean;
    }
}

type Action = StartLoadingAction | UpdateDocuemntRequirementsAction | SetErrorAction | SwitchToEditAction | SwitchToListAction | RemoveDocumentAction | UploadDocumentAction | SetUploadingAction

const reducer = (state: State, action: Action): State => {
    switch (action.name) {
        case 'UpdateDisplayInformation':
            return {
                ...state,
                isLoading: false,
                mode: {
                    name: 'loaded',
                    ...action.payload,
                    documentState: {
                        internalMode: 'list',
                        documentsToDelete: [],
                        documentsToUpload: [],
                        currentDocuments: action.payload.candidate.documents.map(document => ({ name: 'uploadedDocument', payload: document })),    
                    }
                }
            }

        case 'SetError':
            return {
                ...state,
                isLoading: false,
                mode: {
                    name: 'error',
                }
            }

        case 'StartLoading':
            return {
                ...state,
                isLoading: true
            }

        case 'SwitchToEdit':
            if(!state.isLoading && state.mode.name === 'loaded') {
                return {
                    ...state,
                    mode: {
                        ...state.mode,
                        documentState: {
                            ...state.mode.documentState,
                            internalMode: 'edit',
                            currentDocuments: state.mode.candidate.documents.map(file => ({
                                name: 'uploadedDocument',
                                payload: file
                            })),
                        }
                    }
                }
            }
            
            return { ...state }

        case 'SwitchToList':
            if(!state.isLoading && state.mode.name === 'loaded') {
                return {
                    ...state,
                    mode: {
                        ...state.mode,
                        documentState: {
                            ...state.mode.documentState,
                            documentsToDelete: [],
                            documentsToUpload: [],
                            internalMode: 'list'
                        }
                    }
                }
            }
            
            return { ...state }

        case 'RemoveDocument': {
            if(!state.isLoading && state.mode.name === 'loaded') {
                const removedDocumentId = action.payload.documentId
                const deleteDocument = state.mode.candidate.documents.find(doc => doc.id === removedDocumentId)
    
                return {
                    ...state,
                    mode: {
                        ...state.mode,
                        documentState: {
                            ...state.mode.documentState,
                            currentDocuments: state.mode.documentState.currentDocuments.filter(doc => {
                                return doc.payload.id !== removedDocumentId
                            }),
                            documentsToUpload: state.mode.documentState.documentsToUpload.filter(doc => doc.payload.id !== removedDocumentId),
                            documentsToDelete: deleteDocument ? state.mode.documentState.documentsToDelete.concat([deleteDocument]) : state.mode.documentState.documentsToDelete
                        }
                    }
                }
            }

            return { ...state }   
        }

        case 'UploadDocument': {
            if(!state.isLoading && state.mode.name === 'loaded') {
                const documentsToUpload: PendingDocument[] = action.payload.files.map(file => ({
                    name: 'pendingDocument',
                    payload: {
                        file,
                        filename: file.name,
                        id: uuidv4()
                    }
                }))

                return {
                    ...state,
                    mode: {
                        ...state.mode,
                        documentState: {
                            ...state.mode.documentState,
                            documentsToUpload: state.mode.documentState.documentsToUpload.concat(documentsToUpload),
                            currentDocuments: state.mode.documentState.currentDocuments.concat(documentsToUpload)
                        }
                    }
                }
            }
            
            return { ...state } 
        }

        case 'SetLoading': 
            return {
                ...state,
                isSubmitting: action.payload.isUploading
            }

        default:
            return { ...state }
    }
}

const initialState: State = {
    isLoading: true,
    isSubmitting: false
}

export {
    State,
    initialState,
    reducer
}