import { EventSession_OneByIdDocument, EventSession_OneByIdQuery } from "@entities";
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { useEffect, useState } from "react"
import { useQuery } from "urql";
import { SessionGroupManipulationMode } from "../hook";
import { Entity } from "@typedefs/graphql";

interface RemoveCandidateSubMode {
    name: 'remove'
    payload: {
        candidateName: string;
        groupId: string;
        stepId: string;
        recruitmentProcessId: string;
    }
}

interface AddCandidateSubMode {
    name: 'add'
    payload: {
        groupId: string
        stepId: string
    }
}

interface ShowMode {
    name: 'show'
}

type SubMode = RemoveCandidateSubMode | AddCandidateSubMode | ShowMode

interface EventSessionGroupManipulateCandidateModalHookType {
    openRemoveCandidateModal: ({
        candidateName,
        groupId,
        stepId,
        recruitmentProcessId
    }: {
        candidateName: string
        groupId: string
        stepId: string
        recruitmentProcessId: string
    }) => void;
    openAddCandidateModal: ({
        groupId,
        stepId
    }: {
        groupId: string
        stepId: string
    }) => void;
    closeSubModal: VoidFunction;
    subMode: SubMode;
    group?: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>;
}

const useEventSessionGroupManipulateCandidateModal = (manipulationMode: SessionGroupManipulationMode): EventSessionGroupManipulateCandidateModalHookType => {
    const [group, setGroup] = useState(manipulationMode.name === 'manipulateEventSessionGroupCandidate' ? manipulationMode.payload.group : undefined)

    const [subMode, setSubMode] = useState<SubMode>({
        name: 'show'
    })

    const eventSessionContext = useQueryContext(['EventSession', 'EventSessionGroup'])
    const [eventSessionResponse,] = useQuery({
        query: EventSession_OneByIdDocument,
        variables: {
            id: group?.eventSessionId || '',
        },
        context: eventSessionContext,
        pause: !group?.eventSessionId,
    });

    const openRemoveCandidateModal = ({
        candidateName,
        groupId,
        stepId,
        recruitmentProcessId
    }: {
        candidateName: string
        groupId: string
        stepId: string
        recruitmentProcessId: string
    }) => {
        setSubMode({
            name: 'remove',
            payload: {
                candidateName,
                groupId,
                stepId,
                recruitmentProcessId,
            }
        })
    }

    const openAddCandidateModal = ({
        groupId,
        stepId
    }: {
        groupId: string
        stepId: string
    }) => {
        setSubMode({
            name: 'add',
            payload: {
                groupId,
                stepId,
            }
        })
    }

    const closeSubModal = () => {
        setSubMode({
            name: 'show'
        })
    }

    useEffect(() => {
        if (manipulationMode.name === 'manipulateEventSessionGroupCandidate') {
            setGroup(manipulationMode.payload.group)
        }
    }, [manipulationMode])

    useEffect(() => {
        handleResponse(eventSessionResponse, {
            onData: (data) => {
                setGroup(data.eventSession.groups.find(newGroup => newGroup.id === group?.id))
            }
        })
    }, [eventSessionResponse])

    return {
        subMode,
        closeSubModal,
        openRemoveCandidateModal,
        openAddCandidateModal,
        group,
    }
}

export {
    useEventSessionGroupManipulateCandidateModal,
    SubMode,
}