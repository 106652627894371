import { JobPositionCreate } from "@routes/jobPosition"
import { Button } from "@shared/unsorted/Button/Button"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { buttonSizes } from '@shared/unsorted/Button/Button'

interface Props {
    size: typeof buttonSizes[number]
}

const CreateButton: FunctionComponent<Props> = ({
    size
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <Button
            size={size}
            iconName='plus'
            onClick={() => navigate(JobPositionCreate.PATH_NAME)}>
            {t('jobPositions.list.createButton')}
        </Button>
    )
}

export {
    CreateButton
}