import { Event_OneByRecruitmentStepIdDocument, Event_OneByRecruitmentStepIdQuery } from "@entities"
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { AsyncResponseType } from "@typedefs/aliases"
import { Entity } from "@typedefs/graphql"
import { useEffect, useState } from "react"
import { useQuery } from "urql"

type InitialStateData = {
    event: Entity<Event_OneByRecruitmentStepIdQuery, 'eventByRecruitmentStepId'>
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = (recruitmentStepId?: string) => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const [eventResponse,] = useQuery({
        query: Event_OneByRecruitmentStepIdDocument,
        variables: {
            recruitmentStepId: recruitmentStepId || ''
        },
        pause: !recruitmentStepId
    });

    useEffect(() => {
        handleResponse(
            eventResponse,
            {
                onData: (data) => {
                    if (!data.eventByRecruitmentStepId) {
                        setInitialState({
                            isLoading: false,
                            result: {
                                isSuccess: false
                            }
                        })
                        return
                    }

                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                event: data.eventByRecruitmentStepId
                            }
                        }
                    })
                },
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [eventResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}