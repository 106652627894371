import { Candidate } from '@routes/candidate';
import { useNavigate } from 'react-router-dom';

interface ApplicationHookResult {
    onClickApplication: (applicationId: string) => void;
}

const useApplication = (): ApplicationHookResult => {
    const navigate = useNavigate();

    const queryParams = Candidate.useSearchParams();

    const onClickApplication = (applicationId: string) => {
        const { mode, ...restQueryParams } = queryParams;

        navigate(Candidate.toRoute({
            ...restQueryParams,
            mode: 'show',
            applicationId: applicationId,
        }));
    };

    return { onClickApplication };
};

export {
    useApplication,
};