import { FunctionComponent } from 'react';
import { ModalProps } from '@typedefs/props';
import { useTranslation } from 'react-i18next';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { useApplicationRevertModal } from './hook';

const styles = {
    title: Cn.c('font-h3-bold text-emphasized px-6 py-4 border-b border-b-default'),
    description: Cn.c('p-6 font-paragraph-small-medium text-default'),
    buttons: Cn.c('flex flex-row space-x-4 justify-end px-6 py-4 bg-surface-subdued'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    applicationId?: string;
}

const ApplicationRevertModal: FunctionComponent<Props> = ({
    isOpen = false,
    closeModal,
    applicationId,
}) => {
    const { t } = useTranslation();

    const { isSubmitting, submit } = useApplicationRevertModal(closeModal, applicationId);

    return (
        <Modal isOpen={isOpen} close={closeModal}>
            <div className={styles.title}>{t("applications.panel.revertApplication.title")}</div>
            <div className={styles.description}><p>{t("applications.panel.revertApplication.description")}</p></div>
            <div className={styles.buttons}>
                <Button
                    size="md"
                    variant="secondary"
                    isDisabled={isSubmitting}
                    onClick={closeModal}>
                    {t("global.cancel")}
                </Button>
                <Button
                    size="md"
                    onClick={() => submit()}
                    isLoading={isSubmitting}>
                    {t("applications.panel.revertApplication.buttons.confirm")}
                </Button>
            </div>
        </Modal>
    );

};

export {
    ApplicationRevertModal,
};