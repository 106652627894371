import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { EmptyStateContainer } from "@shared/unsorted/EmptyStateContainer/EmptyStateContainer";
import { Button } from "@shared/unsorted/Button/Button";
import { useNavigate } from "react-router-dom";
import { Cn } from "@helpers/unsorted/classNames";
import { JobPosition } from "@routes/jobPosition";

const styles = {
  icon: Cn.c("w-4 h-4")
}

const EmptyJobPositionsContainer: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const jobRoute = JobPosition.toRoute();

  return (
    <EmptyStateContainer
      icon="emptyCandidate"
      description="applications.list.emptyNoJob.description"
      subDescription="applications.list.emptyNoJob.subDescription"
    >
      <Button
        size="md"
        variant="primaryFilled"
        iconName="arrowRightLine"
        isTrailingIcon={true}
        iconClassName={styles.icon}
        onClick={() => {
          navigate(`${jobRoute.PATH_NAME}${jobRoute.search}`);
        }}
      >
        {t("jobPositions.list.empty.moveTo")}
      </Button>
    </EmptyStateContainer>
  );
};

export default EmptyJobPositionsContainer;
