import { FormEventHandler, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import * as value from './value';
import { Entity } from '@typedefs/graphql';
import {
    AllAcquisitionCategories_AllQuery,
    AllEntryCategories_AllQuery,
    AllPrefecturesQuery,
    AllSchoolDepartments_AllDocument,
    AllSchoolTypesQuery,
    AllSchoolsDocument,
    RecruitmentProcess_OneByIdQuery,
    UpdateCandidateInformationDocument,
} from '@entities';
import { SelectionOption } from '@typedefs/selectOption';
import { hasValue, isDefined } from '@helpers/core/typeGuards';
import { useTranslation } from 'react-i18next';
import { fromString } from '@helpers/unsorted/boolExtra';
import { getLocale, useCurrentLanguage } from '@helpers/core/i18n';
import { format } from 'date-fns';
import { range } from '@helpers/unsorted/arrayExtra';
import { useClient, useMutation } from 'urql';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useClient as useClientHook } from '@helpers/hooks/unsorted/clientHook';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';

type EditPayload = {
    acquisitionCategoriesOptions: SelectionOption[];
    acquisitionChannelOptions?: SelectionOption[];
    entryCategoriesOptions: SelectionOption[];
    entryChannelOptions?: SelectionOption[];
    genderOptions: SelectionOption[];
    isLivingOverseasChecked: boolean;
    prefectureOptions: SelectionOption[];
    yearOptions: SelectionOption[];
    monthOptions: SelectionOption[];
    schoolTypeOptions: SelectionOption[];
    humanitiesScienceOptions: SelectionOption[];
    schoolOptions: SelectionOption[];
    getSchools: (value?: string) => void;
    currentSchoolType?: string;
    currentSchoolId?: string;
    schoolDepartmentOptions: SelectionOption[];
    getSchoolDepartments: (value?: string) => void;
};

interface ListMode {
    name: 'list';
}

interface EditMode {
    name: 'edit';
    payload: EditPayload;
}

type Mode = ListMode | EditMode;

interface ApplicationCandidateDetailPanelHookType {
    mode: Mode;
    form: UseFormReturn<value.Encoder.EncoderType>;
    switchToEditMode: VoidFunction;
    switchToListMode: VoidFunction;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isClientExaminerOnly: boolean;
    isSubmitting: boolean;
}

const useApplicationCandidateDetailPanel = (
    candidate: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.candidate'>,
    acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[],
    entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[],
    prefectures: Entity<AllPrefecturesQuery, 'prefectures'>[],
    schoolTypes: Entity<AllSchoolTypesQuery, 'schoolTypes'>,
): ApplicationCandidateDetailPanelHookType => {
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const { error: toastError, success: toastSuccess } = useToast();

    const locale = getLocale(currentLanguage);

    const urqlClient = useClient();

    const { currentClient } = useClientHook();

    const [internalMode, setInternalMode] = useState<'list' | 'edit'>('list');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isClientExaminerOnly, setIsClientExaminerOnly] = useState(false);

    const [schoolOptions, setSchoolOptions] = useState<SelectionOption[]>([]);

    const [schoolDepartmentOptions, setSchoolDepartmentOptions] = useState<SelectionOption[]>([]);

    const [updateCandidateInformationResponse, updateCandidateInformation] = useMutation(UpdateCandidateInformationDocument);

    const defaultValues = value.Encoder.defaultValues(candidate);

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues,
    });

    const acquisitionCategoriesOptions = acquisitionCategories.map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
    }));

    const currentAcquisitionCategoryId = useWatch({
        control: form.control,
        name: 'acquisitionCategoryId',
    });

    const currentAcquisitionChannelsOptions = currentAcquisitionCategoryId
        ? acquisitionCategories.find(acquisitionCategory => acquisitionCategory.id === currentAcquisitionCategoryId)
            ?.channels.map(({ id, name }) => ({
                key: id,
                value: id,
                label: name,
            }))
        : undefined;

    const entryCategoriesOptions = entryCategories.map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
    }));

    const currentEntryCategoryId = useWatch({
        control: form.control,
        name: 'entryCategoryId',
    });

    const currentEntryChannelsOptions = currentEntryCategoryId
        ? entryCategories.find(entryCategory => entryCategory.id === currentEntryCategoryId)
            ?.channels?.map(({ id, name }) => ({
                key: id,
                value: id,
                label: name,
            }))
        : undefined;

    const genderOptions: SelectionOption[] = [
        {
            key: 'MALE',
            label: t('gender.MALE'),
            value: 'MALE',
        },
        {
            key: 'FEMALE',
            label: t('gender.FEMALE'),
            value: 'FEMALE',
        },
        {
            key: 'NOT_APPLICABLE',
            label: t('gender.NOT_APPLICABLE'),
            value: 'NOT_APPLICABLE',
        },
        {
            key: 'UNSPECIFIED',
            label: t('gender.UNSPECIFIED'),
            value: 'UNSPECIFIED',
        },
    ];

    const isLivingOverseasChecked = fromString(useWatch({
        control: form.control,
        name: 'livesOverseas',
    }));

    const prefectureOptions: SelectionOption[] = prefectures.map(pref => ({
        key: pref.id,
        label: pref.name,
        value: pref.id,
    }));

    const yearOptions: SelectionOption[] = range(2031 - 1950, 1950).map(year => ({
        key: year.toString(),
        value: year.toString(),
        label: year.toString(),
    })).reverse();

    const monthOptions: SelectionOption[] = range(12).map(month => ({
        key: month.toString(),
        value: month.toString(),
        label: format(new Date(2022, month, 1), 'LLLL', { locale }),
    }));

    const currentSchoolType = value.SchoolType.SchoolTypeDecoder.schema.parse(useWatch({
        control: form.control,
        name: 'schoolType',
    }));

    const schoolTypeOptions: SelectionOption[] = schoolTypes.map(schoolType => ({
        key: schoolType,
        value: schoolType,
        label: t(`schoolTypes.${schoolType}`),
    }));

    const humanitiesScienceOptions: SelectionOption[] = (['HUMANITIES', 'SCIENCE'] as const).map(fieldType => ({
        key: fieldType,
        value: fieldType,
        label: t(`fieldTypes.${fieldType}`),
    }));

    const getSchools = async (name?: string) => {
        const result = await urqlClient.query(AllSchoolsDocument, {
            first: 7,
            offset: 0,
            type: currentSchoolType,
            name,
        }).toPromise();

        if (result.error) {
            toastError('global.error');
            setSchoolOptions([]);

            return;
        }

        if (result.data) {
            setSchoolOptions(result.data.schools.edges.map(school => ({
                key: school.id,
                value: school.id,
                label: school.name,
            })));
        }
    };

    const currentSchoolId = useWatch({
        control: form.control,
        name: 'schoolId',
    });

    const getSchoolDepartments = async (name?: string) => {
        const result = await urqlClient.query(AllSchoolDepartments_AllDocument, {
            first: 7,
            offset: 0,
            schoolId: currentSchoolId,
            name,
        }).toPromise();

        if (result.error) {
            toastError('global.error');
            setSchoolDepartmentOptions([]);

            return;
        }

        if (result.data) {
            setSchoolDepartmentOptions(result.data.schoolDepartments.edges.map(schoolDepartment => ({
                key: schoolDepartment.id,
                value: schoolDepartment.id,
                label: schoolDepartment.name,
            })));
        }
    };

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            const dateOfBirth = hasValue(validValues.dateOfBirth) && validValues.dateOfBirth instanceof Date
                ? validValues.dateOfBirth.toISOString()
                : null;

            updateCandidateInformation({
                candidateId: candidate.id,
                // Application Information
                acquisitionChannelId: validValues.acquisitionChannelId,
                entryChannelId: validValues.entryChannelId,
                // Basic information
                name: validValues.fullName,
                nameKana: validValues.fullNameKana,
                dateOfBirth,
                livesInJapan: !validValues.livesOverseas,
                email: validValues.email,
                mobilePhoneNumber: validValues.mobilePhoneNumber,
                gender: validValues.gender,
                zipCode: validValues.zipCode,
                prefectureId: validValues.prefectureId,
                address: validValues.address,
                phoneNumber: validValues.phoneNumber,
                // School information
                graduationYear: validValues.graduationYear,
                graduationMonth: hasValue(validValues.graduationMonth) ? validValues.graduationMonth + 1 : null,
                schoolId: validValues.schoolId,
                schoolDepartmentId: validValues.schoolDepartmentId,
                fieldType: validValues.fieldType,
                seminarLaboratory: validValues.seminarLab,
                majorTheme: validValues.researchTheme,
                clubActivity: validValues.clubActivity,
                // Contact
                vacationZipCode: validValues.vacationZipCode,
                vacationPrefectureId: validValues.vacationPrefectureId,
                vacationAddress: validValues.vacationAddress,
                vacationPhoneNumber: validValues.vacationPhoneNumber,
                mobileEmail: validValues.mobileEmail,
                // Job History
                lastJobPosition: validValues.lastJobPosition,
                departmentAndPositionTitle: validValues.departmentAndPositionTitle,
                // Other
                remarks: validValues.remarks,
            }, getQueryContext('RecruitmentProcess'));
        } catch (error) {
            toastError('global.error');
        }
    };

    const switchToEditMode = () => {
        form.reset(defaultValues);

        setInternalMode('edit');
    }

    const switchToListMode = () => {
        setInternalMode('list');
    };

    const mode: Mode = internalMode === 'list'
        ? { name: 'list' }
        : {
            name: 'edit',
            payload: {
                acquisitionCategoriesOptions,
                acquisitionChannelOptions: currentAcquisitionChannelsOptions,
                entryCategoriesOptions,
                entryChannelOptions: currentEntryChannelsOptions,
                genderOptions,
                isLivingOverseasChecked,
                prefectureOptions,
                yearOptions,
                monthOptions,
                schoolTypeOptions,
                humanitiesScienceOptions,
                schoolOptions,
                getSchools,
                currentSchoolType,
                currentSchoolId,
                schoolDepartmentOptions,
                getSchoolDepartments,
            },
        };

    // Prevent edit for examiner-only client
    useEffect(() => {
        if (currentClient.clientState === 'loggedIn') {
            const { clientInfo: { roles } } = currentClient;

            setIsClientExaminerOnly(roles.length === 1 && roles[0].name === 'Examiner');
        }
    }, [currentClient]);

    // Reset acquisition channel value on category change
    useEffect(() => {
        const acquisitionCategory = acquisitionCategories.find(acquisitionCategory => acquisitionCategory.id === currentAcquisitionCategoryId);

        let newValue = '';
        if (isDefined(acquisitionCategory) && acquisitionCategory.channels.length >= 1) {
            newValue = acquisitionCategory.channels[0].id || '';
        }

        form.setValue('acquisitionChannelId', newValue);
    }, [currentAcquisitionCategoryId]);

    // Reset entry channel value on category change
    useEffect(() => {
        const entryCategory = entryCategories.find(entryCategory => entryCategory.id === currentEntryCategoryId);

        let newValue = '';
        if (isDefined(entryCategory) && entryCategory.channels.length >= 1) {
            newValue = entryCategory.channels[0].id || '';
        }

        form.setValue('entryChannelId', newValue);
    }, [currentEntryCategoryId]);

    useEffect(() => {
        if (isLivingOverseasChecked) {
            form.setValue('zipCode', '');
            form.setValue('prefectureId', '');
            form.setValue('address', '');
            form.setValue('phoneNumber', '');
        }
    }, [isLivingOverseasChecked]);

    useEffect(() => {
        if (internalMode === 'edit') {
            form.setValue('schoolId', '');
            form.setValue('schoolDepartmentId', '');
            getSchools();
        }
    }, [currentSchoolType]);

    useEffect(() => {
        if (internalMode === 'edit') {
            form.setValue('schoolDepartmentId', '');
            getSchoolDepartments();
        }
    }, [currentSchoolId]);

    useEffect(() => {
        switchToListMode();
    }, [candidate]);

    useEffect(() => {
        handleResponse(updateCandidateInformationResponse, {
            onFetching: () => setIsSubmitting(true),
            onError: () => {
                setIsSubmitting(false);
                // TODO: [CHECK] Should create a separate i18n entry?
                toastError('applications.panel.candidateBasicInformation.edit.errors.update');
            },
            onData: () => {
                setIsSubmitting(false);
                toastSuccess('applications.panel.candidateBasicInformation.edit.successes.update');
                switchToListMode();
            },
        });
    }, [updateCandidateInformationResponse]);

    return {
        mode,
        form,
        switchToEditMode,
        switchToListMode,
        onSubmit: form.handleSubmit(onSubmit),
        isClientExaminerOnly,
        isSubmitting,
    };
};

export {
    useApplicationCandidateDetailPanel,
    Mode,
    EditPayload,
};