import { Cn } from "@helpers/unsorted/classNames";
import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout";
import { Button } from "@shared/unsorted/Button/Button";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader";
import { Pagination } from "@shared/unsorted/Pagination/Pagination";
import { Search } from "@shared/unsorted/Search/Search";
import { Select } from "@shared/unsorted/Select/Select";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationEvaluationModal } from "./ApplicationEvaluationModal/ApplicationEvaluationModal";
import { ApplicationSort } from "./ApplicationSort/ApplicationSort";
import { ApplicationCandidateDetailPanel } from "./ApplicationPanel/ApplicationCandidateDetailPanel/ApplicationCandidateDetailPanel";
import { ApplicationPanel } from "./ApplicationPanel/ApplicationPanel";
import { ApplicationRevertModal } from "./ApplicationPanel/JobPositionInformation/ApplicationRevertModal/ApplicationRevertModal";
import { FinalizeDecisionModal } from "./ApplicationPanel/JobPositionInformation/FinalizeDecisionModal/FinalizeDecisionModal";
import { ApplicationViewEvaluationsModal } from "./ApplicationViewEvaluationsModal/ApplicationViewEvaluationsModal";
import ApplicationsTable from "./ApplicationsTable/ApplicationsTable";
import {
  EmptyJobPositionsContainer,
} from "./EmptyStateContainers";
import { useApplicationsPage } from "./hook";
import { CSVExportingModal } from "./CSVExportModals/CSVExportingModal";
import { CSVExportErrorModal } from "./CSVExportModals/CSVExportErrorModal";
import { ApplicationSelectionProvider } from "@shared/application/bulkActions/ApplicationSelectionContext";
import { BulkActionGeneralConfirmationModal } from "./BulkActionGeneralConfirmationModal/BulkActionGeneralConfirmationModal";
import { BulkActionLoadingModal } from "./BulkActionLoadingModal/BulkActionLoadingModal";
import { CsvExportActions } from "./ApplicationPanel/CSVExportActions/CsvExportActions";
import { BulkActionErrorModal } from "./BulkActionErrorModal/BulkActionErrorModal";
import { ApplicationFilter } from "./ApplicationFilter/ApplicationFilter";
import { AddCandidatesModal } from "./AddCandidatesModal/AddCandidatesModal";
import { BulkActionModalProvider } from "./BulkActionModalContext";
import { BulkEventSessionAssignmentModal } from "./BulkEventSessionAssignmentModal/BulkEventSessionAssignmentModal";
import { BulkEventSessionAssignmentOptionModal } from "./BulkEventSessionAssignmentOptionModal/BulkEventSessionAssignmentOptionModal";
import { BulkEventSessionAssignmentErrorModal } from "./BulkEventSessionAssignmentErrorModal/BulkEventSessionAssignmentErrorModal";
import { CsvBulkActions } from "./ApplicationPanel/CsvBulkActions/CsvBulkActions";

const tabs = ['active', 'archived'] as const;

type ApplicationsTab = typeof tabs[number];

const styles = {
  breadcrumb: Cn.c('py-4 border-b border-b-default px-6'),
  body: Cn.c("px-6 pt-4 pb-6 flex overflow-auto flex-col flex-1"),
  emptyTable: Cn.c("w-full flex flex-col items-center justify-center bg-surface-default min-h-empty-table m-auto"),
  paginationWrapper: Cn.c("pt-3 flex justify-between items-end grow"),
  paginationLeft: Cn.c("flex items-center space-x-2"),
  rowsPerPage: Cn.c("font-paragraph-xsmall-regular text-subdued"),
  tabContainer: Cn.c('overflow-auto flex flex-col flex-1'),
  rowsPerPageSelect: Cn.c("w-16"),
};

interface Props {
  defaultTab: ApplicationsTab;
}

const ApplicationsPage: FunctionComponent<Props> = ({ defaultTab }) => {
  const { t } = useTranslation();

  const {
    state,
    openCreateApplicationModal,
    dispatchApplicationModalMode,
    dispatchBulkActionModalMode,
    closeModal,
    changePage,
    rowsPerPageOptions,
    changeRowsPerPage,
    getPageCount,
    currentPage,
    rowsPerPage,
    searchKeyword,
    onSubmitSearch,
    exportCandidatesToCsv,
    onCancelCSVExport,
    onCSVExportError,
    onRetryCSVExport,
    csvExportProgress,
  } = useApplicationsPage();

  const {
    jobPositions,
    applications,
    clientHasNoProcess,
    displayMode,
    applicationModalMode,
    totalRows,
  } = state

  return (
    <ApplicationSelectionProvider>
      <BulkActionModalProvider value={{ dispatchBulkActionModalMode }}>
        <DefaultLayout extra={<>
          <ApplicationPanel
            activeApplication={displayMode.name === 'show' ? displayMode.payload.application : undefined}
            dispatchApplicationModalMode={dispatchApplicationModalMode}
          />
          {
            displayMode.name === 'show' &&
            <>
              <ApplicationCandidateDetailPanel
                application={displayMode.payload.application}
                isOpen={applicationModalMode.name === 'showApplicationDetail'}
                closePanel={closeModal}
                prefectures={displayMode.payload.prefectures}
                schoolTypes={displayMode.payload.schoolTypes}
                acquisitionCategories={displayMode.payload.acquisitionCategories}
                entryCategories={displayMode.payload.entryCategories}
              />
            </>
          }
          <FinalizeDecisionModal
            isOpen={applicationModalMode.name === 'finalizeDecision'}
            closeModal={closeModal}
            applicationDetail={applicationModalMode.name === 'finalizeDecision' ? applicationModalMode.payload : undefined}
          />
          <ApplicationRevertModal
            isOpen={applicationModalMode.name === 'revertApplication'}
            closeModal={closeModal}
            applicationId={applicationModalMode.name === 'revertApplication' ? applicationModalMode.payload.applicationId : undefined}
          />
          <ApplicationEvaluationModal
            isOpen={applicationModalMode.name === 'evaluateApplication'}
            closeModal={closeModal}
            evaluationResponses={applicationModalMode.name === 'evaluateApplication' ? applicationModalMode.payload : undefined}
          />
          <ApplicationViewEvaluationsModal
            isOpen={applicationModalMode.name === 'viewAllEvaluations'}
            closeModal={closeModal}
            evaluationResponses={applicationModalMode.name === 'viewAllEvaluations' ? applicationModalMode.payload : undefined}
          />
          <CSVExportingModal
            isOpen={applicationModalMode.name === 'csvExporting'}
            fileName={applicationModalMode.name === 'csvExporting' ? applicationModalMode.payload.fileName : undefined}
            onCancel={onCancelCSVExport}
            onError={onCSVExportError}
            exportAction={state.csvExportMode}
            progressValue={csvExportProgress}
          />
          <CSVExportErrorModal
            isOpen={applicationModalMode.name === 'csvExportError'}
            closeModal={closeModal}
            onRetry={onRetryCSVExport}
          />
          <BulkActionGeneralConfirmationModal
            isOpen={applicationModalMode.name === 'showBulkActionGeneralConfirmationModal'}
            closeModal={closeModal}
            payload={applicationModalMode.name === 'showBulkActionGeneralConfirmationModal' ? applicationModalMode.payload : undefined}
          />
          <BulkActionLoadingModal
            isOpen={applicationModalMode.name === 'showBulkActionLoadingModal'}
            payload={applicationModalMode.name === 'showBulkActionLoadingModal' ? applicationModalMode.payload : undefined}
          />
          <BulkActionErrorModal
            isOpen={applicationModalMode.name === 'showBulkActionErrorModal'}
            closeModal={closeModal}
            payload={applicationModalMode.name === 'showBulkActionErrorModal' ? applicationModalMode.payload : undefined}
          />
          <BulkEventSessionAssignmentModal
            isOpen={applicationModalMode.name === 'showBulkEventSessionAssignmentModal'}
            payload={applicationModalMode.name === 'showBulkEventSessionAssignmentModal' ? applicationModalMode.payload : undefined}
          />
          <BulkEventSessionAssignmentOptionModal
            isOpen={applicationModalMode.name === 'showBulkEventSessionAssignmentOptionModal'}
            closeModal={closeModal}
            payload={applicationModalMode.name === 'showBulkEventSessionAssignmentOptionModal' ? applicationModalMode.payload : undefined}
          />
          <BulkEventSessionAssignmentErrorModal
            isOpen={applicationModalMode.name === 'showBulkEventSessionAssignmentErrorModal'}
            defaultCloseModal={closeModal}
            payload={applicationModalMode.name === 'showBulkEventSessionAssignmentErrorModal' ? applicationModalMode.payload : undefined}
          />
          <AddCandidatesModal
            isOpen={applicationModalMode.name === 'addCandidatesModal'}
            closeModal={closeModal}
          />
        </>}>
          <PageHeader
            action={[
              <CsvExportActions
                key="export"
                onCsvExportAction={exportCandidatesToCsv}
              // disabled={ TODO: This needs to be disabled when there are no applications at all }
              />,
              <CsvBulkActions key="csvBulkActions" />,
              <Button
                key="create"
                iconName="plus"
                size="sm"
                isDisabled={!jobPositions.length}
                onClick={openCreateApplicationModal}
              >
                {t("applications.create.button")}
              </Button>
            ]
            }
          >
            <Search
              keyword={searchKeyword}
              onSearchAction={onSubmitSearch}
              size={"small"}
            />
          </PageHeader>
          <div className={styles.body}>
            {state.loading
              ? <Loader />
              : jobPositions?.length ?
                <>
                  <ApplicationFilter />
                  <ApplicationSort
                    totalRows={totalRows}
                  />
                  <ApplicationsTable
                    applications={applications}
                    clientHasNoProcess={clientHasNoProcess}
                    totalSearchResults={totalRows}
                    openCreateApplicationModal={openCreateApplicationModal}
                  />
                  <div className={styles.paginationWrapper}>
                    <div className={styles.paginationLeft}>
                      <Select
                        name="rowsPerPage"
                        options={rowsPerPageOptions}
                        onChange={changeRowsPerPage}
                        value={rowsPerPage.toString()}
                        size="small"
                        direction="upward"
                        className={styles.rowsPerPageSelect}
                        rawPlaceholder={rowsPerPage.toString()}
                        noEmpty
                      />
                      <div className={styles.rowsPerPage}>{t('jobPositions.list.rowsPerPage')}</div>
                    </div>
                    <Pagination pageCount={getPageCount(state.totalRows)} currentPage={currentPage} onSelectPage={changePage} />
                  </div>
                </>
                : (
                  <div className={styles.emptyTable}>
                    <EmptyJobPositionsContainer />
                  </div>
                )
            }
          </div>
        </DefaultLayout >
      </BulkActionModalProvider>
    </ApplicationSelectionProvider>
  );
};

export { ApplicationsPage };
