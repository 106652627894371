import { Event_OneByIdQuery } from "@entities";
import { Entity } from "@typedefs/graphql";
import { createContext, useState } from "react";

interface HideMode {
    name: 'hide';
}

interface CreateMode {
    name: 'create';
    payload: {
        recruitmentStepId: string
    }
}

interface EditMode {
    name: 'edit';
    payload: {
        event: Entity<Event_OneByIdQuery, 'event'>
    }
}

type Mode = HideMode | CreateMode | EditMode;

type EventSettingContextType = [Mode, (mode: Mode) => void];

const EventSettingContext = createContext<EventSettingContextType | undefined>(undefined);

const EventSettingProvider = ({ children }: { children: React.ReactNode }) => {
    const [mode, setMode] = useState<Mode>({ name: 'hide' });

    return (
        <EventSettingContext.Provider value={[mode, setMode]}>
            {children}
        </EventSettingContext.Provider>
    );
};

export {
    EventSettingContext,
    EventSettingProvider,
    Mode,
}