import { SaveFinalDecisionsDocument } from '@entities';
import { hasValue, isDefined } from '@helpers/core/typeGuards';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';
import { ApplicationModalMode, FinalizeDecisionPayload } from '@pages/ApplicationsPage/reducer';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'urql';

type FailReason = 'REJECTED_SKILL_MISMATCH' | 'REJECTED_CULTURE_MISMATCH' | 'REJECTED_OTHER' | 'CLOSED_NO_REPLY' | 'CLOSED_OTHER';

interface FinalizeDecisionHookResult {
    failReason?: FailReason;
    setFailReason: Dispatch<SetStateAction<FailReason | undefined>>;
    submit: VoidFunction;
    close: VoidFunction;
    isSubmitting: boolean;
    shouldShowWarningMessage: () => boolean;
}

const useFinalizeDecision = (
    closeModal: VoidFunction,
    applicationDetail?: FinalizeDecisionPayload,
): FinalizeDecisionHookResult => {
    const { error: toastError, success: toastSuccess } = useToast();

    const [saveFinalDecisionsResponse, saveFinalDecisions] = useMutation(SaveFinalDecisionsDocument);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [failReason, setFailReason] = useState<FailReason>();

    const submit = () => {
        if (isDefined(applicationDetail)) {
            const { applicationId, stepId, finalResult } = applicationDetail;

            saveFinalDecisions({
                applicationIds: [applicationId],
                stepId,
                result: finalResult,
                failReason,
            }, getQueryContext('RecruitmentProcess'));
        }
    };

    const close = () => {
        setFailReason(undefined); // Reset fail reason when closing the modal
        closeModal();
    };

    const shouldShowWarningMessage = useCallback(() => {
        return hasValue(applicationDetail) && (
            applicationDetail.preliminaryResult === 'FAILED' && applicationDetail.finalResult === 'PASSED' ||
            applicationDetail.preliminaryResult === 'PASSED' && applicationDetail.finalResult === 'FAILED'
        )
    }, [applicationDetail]);

    useEffect(() => {
        handleResponse(saveFinalDecisionsResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false);

                if (data.saveFinalDecisions) {
                    toastSuccess('applications.panel.finalizeDecision.success');
                    close();
                } else {
                    toastError('applications.panel.finalizeDecision.error');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('applications.panel.finalizeDecision.error');
            },
        });
    }, [saveFinalDecisionsResponse]);

    return {
        failReason,
        setFailReason,
        submit,
        close,
        isSubmitting,
        shouldShowWarningMessage,
    };
};

export {
    useFinalizeDecision,
};