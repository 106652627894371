import { DraggableProvided } from '@hello-pangea/dnd';
import { allRecruitmentSteps } from '@helpers/core/constants';
import { isOneOf } from '@helpers/core/typeGuards';
import { useNestedForm } from '@helpers/hooks/unsorted/useNestedForm';
import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Input } from '@shared/unsorted/Input/Input';
import { Select } from '@shared/unsorted/Select/Select';
import { ToggleSwitch } from '@shared/unsorted/ToggleSwitch/ToggleSwitch';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { RecruitmentStageType } from '@typedefs/aliases';
import { SelectionOption } from '@typedefs/selectOption';
import { FunctionComponent } from 'react';
import { Controller, UseFieldArrayRemove } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isStepEditable } from '../helper';
import { stageRange } from './helpers';

import { FormFieldType } from './hook';
import * as itemValue from './itemValue';

const styles = {
    dragHandle: Cn.c('absolute top-5 left-4'),
    baseCellStyle: Cn.c('flex items-start px-3 py-2'),
    cell: (columnNumber: number) => Cn.join([
        styles.baseCellStyle,
        Cn.ifTrue(columnNumber === 1, Cn.c('justify-start flex-[1.2] space-x-1')),
        Cn.ifTrue(columnNumber === 2, Cn.c('justify-start flex-[0.8]')),
        Cn.ifTrue(columnNumber >= 3, Cn.c('justify-center w-[12.5rem] pt-4')),
    ]),
    inputTitleContainer: Cn.c('flex flex-row items-center justify-center w-full'),
    closeAndTooltipPositionStyle: Cn.c('absolute w-6 h-6 top-4 right-4 text-icons-subdued'),
    typeSelect: Cn.c('w-[11.25rem]'),
    questionMark: Cn.c('text-icons-emphasized w-3.5 h-3.5'),
    addStepIconContainer: Cn.c('text-primary-default font-paragraph-small-medium flex items-center justify-start gap-x-2 cursor-pointer pt-6 pb-7 ml-10 w-24'),
    stepName: Cn.c("font-paragraph-small-medium text-emphasized"),
    tooltip: Cn.c('w-[34rem]'),
};

interface Props {
    stage: RecruitmentStageType;
    provided: DraggableProvided;
    step: FormFieldType;
    index: number;
    fields: FormFieldType[];
    removeRecruitmentStep: UseFieldArrayRemove;
    stepTypeOptions: SelectionOption[];
    isEditMode?: boolean;
}

const Row: FunctionComponent<Props> = ({
    stage,
    provided,
    step,
    index,
    fields,
    removeRecruitmentStep,
    stepTypeOptions,
    isEditMode = false,
}) => {

    const { t } = useTranslation();

    const { control, formState: { errors }, clearErrors, getPath } = useNestedForm<itemValue.Encoder.Type>(undefined);

    const isEditable = isStepEditable(step);
    const stageOffset = stageRange(fields, stage);

    const editIndex = index + stageOffset.start;

    return isEditable
        ? <>
            <div {...provided.dragHandleProps}><Icon name="dragHandle" className={styles.dragHandle} /></div>
            <div className={styles.cell(1)}>
                <div className={styles.inputTitleContainer}>
                    <Controller
                        name={getPath(`recruitmentSteps.${editIndex}.name`)}
                        control={control}
                        defaultValue={step.name}
                        rules={{
                            required: { value: true, message: 'requiredStepName' },
                        }}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Input
                                required
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                                clearErrors={() => clearErrors(getPath(`recruitmentSteps.${editIndex}.name`))}
                                forwardedRef={ref}
                                errors={errors}
                                value={value}
                                errorLabel={"jobPositions.shared.recruitmentFlow.stepName"}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={styles.cell(2)}>
                <Controller
                    name={getPath(`recruitmentSteps.${editIndex}.type`)}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) =>
                        <Select
                            className={styles.typeSelect}
                            required
                            name={name}
                            onChange={value => {
                                if (isOneOf(allRecruitmentSteps)(value)) {
                                    onChange(value);
                                }
                            }}
                            onBlur={onBlur}
                            clearErrors={clearErrors}
                            forwardedRef={ref}
                            errors={errors}
                            value={value}
                            options={stepTypeOptions}
                            disabled={isEditMode && step.item_id != ''}
                            disabledTooltip={{
                                tooltipContent: t("jobPositions.shared.recruitmentFlow.disabledStepTypeTooltip"),
                                placement: 'right',
                            }}
                        />}
                />
            </div>
            <div className={styles.cell(3)}>
                <Controller
                    name={getPath(`recruitmentSteps.${editIndex}.hasNotification`)}
                    control={control}
                    render={({ field: { name, value, onChange } }) =>
                        <ToggleSwitch
                            id={name}
                            checked={value}
                            onChange={() => onChange(!value)}
                        />
                    }
                />
            </div>
            <div className={styles.cell(4)}>
                <Controller
                    name={getPath(`recruitmentSteps.${editIndex}.hasEvaluation`)}
                    control={control}
                    render={({ field: { name, value, onChange } }) =>
                        <ToggleSwitch
                            id={name}
                            checked={value}
                            onChange={() => onChange(!value)}
                        />
                    }
                />
            </div>
            <div className={styles.cell(5)}>
                <Controller
                    name={getPath(`recruitmentSteps.${editIndex}.hasDecision`)}
                    control={control}
                    render={({ field: { name, value, onChange } }) =>
                        <ToggleSwitch
                            id={name}
                            checked={value}
                            onChange={() => onChange(!value)}
                        />
                    }
                />
            </div>
            <Icon name="close"
                className={styles.closeAndTooltipPositionStyle
                }
                onClick={() =>
                    removeRecruitmentStep(editIndex)}
            />
        </>
        : <div className={styles.cell(1)}>
            <p className={styles.stepName}>{step.name === 'Recruitment Result' ? t('recruitmentStepType.RecruitmentResult') : step.name}</p>
            <ToolTipBox
                toolTipClassName={styles.tooltip}
                tooltipContent={t("jobPositions.shared.recruitmentFlow.mandatoryStepTooltipText")}
                placement="right">
                <div>
                    <Icon name="questionMark" className={styles.questionMark} />
                </div>
            </ToolTipBox>
        </div>;
};

export {
    Row
};
