import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Mode, useArchiveActions } from './hook';
import { allClosedStepStatuses } from '@helpers/core/constants';

type Direction = 'upward' | 'downward';

const styles = {
    button: Cn.c('ml-px flex-1'),
    caretIcon: (mode: Mode) => Cn.join([
        Cn.c('ml-2 w-6 h-6'),
        Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180'))
    ]),
    list: (direction: Direction) => Cn.join([
        Cn.c('absolute z-10 bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md py-2'),
        direction === 'upward' ? Cn.c('bottom-16') : Cn.c('right-0 top-12'),
    ]),
    listRow: Cn.c('block cursor-pointer font-paragraph-small-regular px-4 py-2 hover:bg-surface-hovered-default'),
    actionsContent: Cn.c('flex flex-row items-center justify-around'),
    groupName: (isFirst: boolean) =>
        Cn.join([
            Cn.c("px-4 text-subdued font-paragraph-xsmall-medium pt-2 pb-1 "),
            Cn.ifFalse(isFirst, Cn.c('border-t border-t-default mt-2 pt-4'))
        ])
};

// @ocaml.doc("Button to display archive actions on recruitment process
//     - `direction`[#upwards | #downwards] - absolute positioning of the list, whether to open upwards or downwards
// ")
interface Props {
    applicationId: string;
    direction?: Direction;
}

const ArchiveActions: FunctionComponent<Props> = ({
    applicationId,
    direction = 'downward',
}) => {
    const { t } = useTranslation();

    const {
        actionListDisplayMode,
        toggleArchiveActionList,
        archiveActionsRef,
        onOptionClick,
        dropdownOptions,
    } = useArchiveActions();

    return (
        <div ref={archiveActionsRef}>
            <Button
                size="md"
                variant="secondary"
                onClick={toggleArchiveActionList}
                className={styles.button}
            >
                <div className={styles.actionsContent}>
                    {t("applications.panel.recruitmentStepArchiveActions")}
                    <Icon name="caret" className={styles.caretIcon(actionListDisplayMode)} />
                </div>
            </Button>
            {actionListDisplayMode === "show" &&
                <div className={styles.list(direction)}>
                    {dropdownOptions.map((option, index) =>
                        option.type === 'header'
                            ? <div className={styles.groupName(index === 0)}>{option.label}</div>
                            : <div className={styles.listRow} key={index} onClick={() => onOptionClick(option.status, applicationId)}>
                                {t(`recruitmentStepStatusV2.${option.status}`)}
                            </div>
                    )}
                </div>
            }
        </div>
    );
};

export {
    ArchiveActions,
};
