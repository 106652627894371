import { Cn } from "@helpers/unsorted/classNames";
import { ChildrenProps } from "@typedefs/props";
import { FunctionComponent } from "react"
import { useStepperContext } from "./useStepperContext";

const styles = {
    container: (isCurrent: boolean) => Cn.ifFalse(isCurrent, "hidden"),
}

interface Props extends ChildrenProps {
    className?: string
    id: string
}

const StepContainer: FunctionComponent<Props> = ({ children, id, className }) => {
    const { currentStepId } = useStepperContext()

    return (
        <div className={Cn.join([styles.container(currentStepId === id), Cn.ifTrue(currentStepId === id, Cn.getSome(className))])} key={id}>
            {children}
        </div>
    );

}

export {
    StepContainer,
    Props,
}