import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { ChildrenProps } from '@typedefs/props';
import { FunctionComponent, useEffect, useState } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

const styles = {
    collapsible: Cn.c('border border-default rounded-xl'),
    collapsibleHeader: Cn.c('flex items-center justify-between px-6 py-4 cursor-pointer'),
    titleContainer: Cn.c('flex gap-x-3'),
    stageIndex: Cn.c('font-paragraph-base-bold text-emphasized w-10 h-10 px-4 border rounded-full border-default flex justify-center items-center text-center'),
    stageTitleContainer: Cn.c('flex flex-col gap-y-2'),
    title: Cn.c('font-paragraph-base-bold text-emphasized'),
    description: Cn.c('font-paragraph-xsmall-regular text-subdued'),
    warningContainer: Cn.c('flex items-center gap-x-4'),
    pendingInfo: Cn.c('bg-surface-warning-default font-paragraph-xsmall-medium text-warning-default rounded-full px-2 py-0.5'),
    icon: (isCollapsed: boolean) => Cn.join([
        Cn.c('text-icons-emphasized transform transition-all duration-200'),
        isCollapsed ? Cn.c('rotate-180') : Cn.c('rotate-0'),
    ]),
    collapsibleContent: (isCollapsed: boolean) => Cn.join([
        Cn.c('transform transition-all ease-in-out duration-200'),
        !isCollapsed ? Cn.c('max-h-screen opacity-100 visible') : Cn.c('max-h-0 opacity-60 invisible'),
    ]),
    warning: Cn.c("bg-surface-warning-subdued flex items-center space-x-1 py-1 px-2 rounded-[2.5rem] text-warning-default font-paragraph-xsmall-medium"),
    questionMark: Cn.c("w-4 h-4 text-icons-warning-default"),
    tooltip: Cn.c("w-[23rem] whitespace-pre")
};

interface Props extends ChildrenProps {
    index: number;
    stageName: I18nKey;
    stageDescription: I18nKey;
    isCollapsed: boolean;
    incompleteStepNames?: string[];
}

const CollapsibleRecruitmentStage: FunctionComponent<Props> = ({
    index,
    stageName,
    stageDescription,
    isCollapsed: isInitiallyCollapsed = true,
    children,
    incompleteStepNames = [],
}) => {
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(() => isInitiallyCollapsed);

    const getIncompleteStepTooltips = () => {
        const basedTooltip = t('jobPositions.shared.recruitmentFlow.incompleteStepsTooltip')

        return `${basedTooltip}\n\n${incompleteStepNames.map((stepName, index) => `${index + 1}. ${stepName}`).join('\n')}`
    }

    useEffect(() => {
        setIsCollapsed(isInitiallyCollapsed)
    }, [isInitiallyCollapsed]);

    return (
        <div className={styles.collapsible}>
            <div className={styles.collapsibleHeader} onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}>
                <div className={styles.titleContainer}>
                    <div className={styles.stageIndex}>{index}</div>
                    <div className={styles.stageTitleContainer}>
                        <span className={styles.title}>{t(stageName)}</span>
                        <span className={styles.description}>{t(stageDescription)}</span>
                    </div>
                </div>
                <div className={styles.warningContainer}>
                    {!!incompleteStepNames.length &&
                        <ToolTipBox
                            tooltipContent={getIncompleteStepTooltips()}
                            toolTipClassName={styles.tooltip}
                            placement='top-end'
                        >
                            <div className={styles.warning}>
                                <p>{t('jobPositions.shared.recruitmentFlow.incompleteStepsWarning', { numOfSteps: incompleteStepNames.length })}</p>
                                <Icon name="questionMark" className={styles.questionMark} />
                            </div>
                        </ToolTipBox>}
                    <Icon className={styles.icon(isCollapsed)} name="arrow" />
                </div>
            </div>
            <div className={styles.collapsibleContent(isCollapsed)}>
                {children}
            </div>
        </div>
    );
};

export {
    CollapsibleRecruitmentStage
};
