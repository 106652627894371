import { isDefined } from "@helpers/core/typeGuards";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useEventSessionDetailContext } from "@pages/EventSessionDetailPage/EventSessionDetailContext";
import { EventSessionGroupAddCandidateModal } from "../EventSessionGroupAddCandidateModal/EventSessionGroupAddCandidateModal";
import { EventSessionGroupRemoveCandidateAssignmentModal } from "../EventSessionGroupRemoveCandidateAssignmentModal/EventSessionGroupRemoveCandidateAssignmentModal";
import { SessionGroupManipulationMode } from "../hook";
import { useEventSessionGroupManipulateCandidateModal } from "./hook";

const styles = {
    loadingContainer: Cn.c("h-[21rem] rounded-xl"),
    loadingBody: Cn.c("flex items-center justify-center flex-1"),
    container: Cn.c('max-h-[95%] rounded-xl'),
    content: Cn.c('h-full flex flex-col'),
    header: Cn.c('p-6 flex justify-between items-center border-b border-default'),
    title: Cn.c('text-emphasized font-h4-bold'),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    body: Cn.c("p-6 flex flex-col space-y-6 flex-1"),
    sessionDetailContainer: Cn.c("bg-default rounded-lg flex items-center p-4 space-x-3"),
    item: Cn.c("flex items-center space-x-1"),
    icon: Cn.c("w-4 h-4 text-icons-disabled"),
    label: Cn.c("text-subdued font-paragraph-small-regular"),
    value: Cn.c("text-emphasized font-paragraph-small-medium text-ellipsis flex-1 min-w-0 whitespace-nowrap overflow-hidden"),
    groupHeader: Cn.c("flex items-center justify-between"),
    groupName: Cn.c("text-emphasized font-paragraph-base-medium"),
    noCandidate: Cn.c("text-subdued font-paragraph-small-regular"),
    tableInnerContainer: Cn.c('bg-surface-default border border-subdued flex'),
    candidateTable: Cn.c("table-sticky w-full"),
    candidateTableHeader: Cn.c("table-thead-row"),
    tableTd: Cn.c("p-4 w-6/12"),
    candidateName: Cn.c("text-emphasized font-paragraph-small-regular"),
    removeIcon: Cn.c("w-6 h-6 text-icons-emphasized cursor-pointer"),
    footer: Cn.c("border-t border-default p-6 flex justify-end"),
}

interface Props extends ModalProps {
    closeModal: VoidFunction;
    manipulationMode: SessionGroupManipulationMode;
}

const EventSessionGroupManipulateCandidateModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    manipulationMode,
}) => {
    const { t } = useTranslation();

    const { event } = useEventSessionDetailContext();

    const {
        openRemoveCandidateModal,
        openAddCandidateModal,
        closeSubModal,
        subMode,
        group
    } = useEventSessionGroupManipulateCandidateModal(manipulationMode)

    return !isDefined(group) ?
        <Modal
            isOpen={isOpen}
            close={closeModal}
            widthClass="w-[50rem]"
            className={styles.loadingContainer}
        >
            <div className={styles.content}>
                <div className={styles.header} data-section="header">
                    <p className={styles.title}>{t("event.eventSessionGroupManipulateCandidatesModal.title")}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                </div>
                <div className={styles.loadingBody} data-section="body">
                    <Loader
                        variant="primaryDefault"
                        size="lg"
                    />
                </div>
            </div>
        </Modal>
        : <>
            <Modal
                isOpen={isOpen}
                widthClass="w-[50rem]"
                close={closeModal}
                className={styles.container}
            >
                <div className={styles.content}>
                    <div className={styles.header} data-section="header">
                        <p className={styles.title}>{t("event.eventSessionGroupManipulateCandidatesModal.title")}</p>
                        <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
                    </div>
                    <div className={styles.body} data-section="body">
                        <div className={styles.sessionDetailContainer} data-section="section-detail">
                            <div className={Cn.join([styles.item, Cn.c("w-[50%]")])}>
                                <Icon name="location" className={styles.icon} />
                                <p className={styles.label}>
                                    {t('event.eventSessionGroupManipulateCandidatesModal.location')}
                                </p>
                                <ToolTipBox
                                    tooltipContent={group.location}
                                    placement="top">
                                    <p className={styles.value}>{group.location}</p>
                                </ToolTipBox>

                            </div>
                            <div className={Cn.join([styles.item, Cn.c("w-[50%]")])}>
                                <Icon name="candidates" className={styles.icon} />
                                <p className={styles.label}>
                                    {t('event.eventSessionGroupManipulateCandidatesModal.examiners')}
                                </p>
                                <p className={styles.value}>{
                                    //TODO: format the examiners' names
                                    group.examinerAssignments.map(({ client: { fullName } }) => fullName).join(', ')
                                }
                                </p>
                            </div>
                        </div>
                        <div className={styles.groupHeader} data-section="group-detail">
                            <p className={styles.groupName}>
                                {`${t('event.addSession.groupLabel', { count: group.order + 1, ordinal: true })}`}
                            </p>
                            <div className={styles.item}>
                                <Icon name="candidates" className={styles.icon} />
                                <p className={styles.label}>
                                    {t('event.eventSessionGroupManipulateCandidatesModal.allocatedCandidate')}
                                </p>
                                <p className={styles.value}>{
                                    t('event.eventSessionGroupManipulateCandidatesModal.candidateCount',
                                        {
                                            registered: group.recruitmentProcessAssignments.length, total: event.candidateCapacity
                                        })}
                                </p>
                            </div>
                        </div>
                        <div data-section="group-candidates-detail">
                            {group.recruitmentProcessAssignments.length === 0
                                ? <p className={styles.noCandidate}>{t('event.eventSessionGroupManipulateCandidatesModal.noAllocatedCandidates')}</p>
                                : <div className={styles.tableInnerContainer}>
                                    <table className={styles.candidateTable}>
                                        <thead>
                                            <tr>
                                                <th className={styles.candidateTableHeader}>
                                                    {t('event.eventSessionGroupManipulateCandidatesModal.reservedCandidates')}
                                                </th>
                                                <th className={styles.candidateTableHeader}>
                                                    {t('event.eventSessionGroupManipulateCandidatesModal.action')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {group.recruitmentProcessAssignments.map(({ id, recruitmentProcess, }) =>
                                                <tr key={id}>
                                                    <td className={styles.tableTd}>
                                                        <p className={styles.candidateName}>{recruitmentProcess.candidate.name}</p>
                                                    </td>
                                                    <td className={styles.tableTd}>
                                                        <Icon
                                                            name="deleteFilled"
                                                            className={styles.removeIcon}
                                                            onClick={() =>
                                                                openRemoveCandidateModal({
                                                                    candidateName: recruitmentProcess.candidate.name,
                                                                    recruitmentProcessId: recruitmentProcess.id,
                                                                    groupId: group.id,
                                                                    stepId: event.recruitmentStepV2.id,
                                                                })}

                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div>
                            <Button
                                size="md"
                                variant="primaryFilled"
                                isFull={false}
                                iconName="plus"
                                onClick={() => openAddCandidateModal({
                                    groupId: group.id,
                                    stepId: event.recruitmentStepV2.id,
                                })}
                            >
                                {t('event.eventSessionGroupManipulateCandidatesModal.addCandidate')}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.footer} data-section="footer">
                        <Button
                            size="md"
                            variant="secondary"
                            isFull={true}
                            onClick={closeModal}
                        >
                            {t('global.dismiss')}
                        </Button>
                    </div>
                </div>
            </Modal>
            <EventSessionGroupRemoveCandidateAssignmentModal
                isOpen={subMode.name === 'remove'}
                closeModal={closeSubModal}
                subMode={subMode}
            />
            <EventSessionGroupAddCandidateModal
                recruitmentProcessAssignments={group.recruitmentProcessAssignments}
                isOpen={subMode.name === 'add'}
                closeModal={closeSubModal}
                subMode={subMode}
            />
        </>
}

export {
    EventSessionGroupManipulateCandidateModal
};
