import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { hasValue } from '@helpers/core/typeGuards';
import { Entity } from '@typedefs/graphql';

type ApplicationCard = {
    id: string;
    title: string;
    currentStepName?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStep.name'>;
    currentStepStage?: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStep.stage'>;
}

interface RelatedApplicationsHookResult {
    underSelectionApplication?: ApplicationCard;
    archivedApplications: ApplicationCard[];
}

const useRelatedApplications = (
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>,
): RelatedApplicationsHookResult => {
    const allApplications = [application, ...application.relatedRecruitmentProcesses];

    const underSelection = allApplications.find((application) => application.currentStep.stage !== 'ARCHIVE')

    const underSelectionApplication = hasValue(underSelection)
        ? {
            id: underSelection.id,
            title: underSelection.jobPosition.title,
            currentStepName: underSelection.currentStep.name,
            currentStepStage: underSelection.currentStep.stage,
        }
        : undefined

    const archivedApplications = allApplications
        .filter((application) => application.currentStep.stage === 'ARCHIVE')
        .map(application => ({
            id: application.id,
            title: application.jobPosition.title,
            currentStepName: application.currentStep.name,
            currentStepStage: application.currentStep.stage,
        }))

    return {
        underSelectionApplication,
        archivedApplications,
    };
};

export {
    useRelatedApplications,
};
