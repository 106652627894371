interface ShowMode {
    type: 'show';
}

interface EditMode {
    type: 'edit';
    value: string;
}

type Mode = ShowMode | EditMode;

type State = { nameValue: string, mode: Mode }

interface FocusInputAction {
    type: 'FocusInput';
}

interface BlurInputAction {
    type: 'BlurInput';
}

interface EditNameValueAction {
    type: 'EditNameValue';
    updatedValue: string;
}

interface SetNewNameValueAction {
    type: 'SetNewNameValue';
    newNameValue: string;
}

type Action = FocusInputAction | BlurInputAction | EditNameValueAction | SetNewNameValueAction;

const typeAheadReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'FocusInput':
            return {
                ...state,
                mode: {
                    type: 'edit',
                    value: "",
                },
            };
        case 'BlurInput':
            return {
                ...state,
                mode: { type: 'show' }
            };
        case 'EditNameValue':
            return {
                ...state,
                mode: {
                    type: 'edit',
                    value: action.updatedValue,
                }
            };
        case 'SetNewNameValue':
            return {
                nameValue: action.newNameValue,
                mode: { type: 'show' }
            };
    }
};

export {
    typeAheadReducer
}