import { useEffect, useState } from "react";
import { useCSVBulkEvaluationFormContext } from "../../CSVBulkEvaluationFormContext";
import { useSubscription } from "urql";
import { EvaluationsFailedToImportFromCsvDocument, EvaluationsSuccessfullyImportedFromCsvDocument } from "@entities";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { hasValue } from "@helpers/core/typeGuards";

const useCSVBulkEvaluationImportStep = () => {
    const [mode, setMode] = useState<'inProgress' | 'success' | 'failure'>('inProgress');

    const [_, dispatch] = useCSVBulkEvaluationFormContext()

    const [evaluationsSuccessfullyImportedResponse] = useSubscription({ query: EvaluationsSuccessfullyImportedFromCsvDocument })
    const [evaluationsFailedToImportResponse] = useSubscription({ query: EvaluationsFailedToImportFromCsvDocument })

    const goToFailure = () => {
        setMode('failure')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'fail' },
                ]
            }
        })
    };

    const goToSuccess = () => {
        setMode('success')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'success' },
                ]
            }
        })
    };

    const goToInProgress = () => {
        setMode('inProgress')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.bulkEvaluation.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.bulkEvaluation.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.bulkEvaluation.steps.import', variant: 'selected' },
                ]
            }
        })
    }

    useEffect(() => {
        handleResponse(evaluationsSuccessfullyImportedResponse, {
            onData: ({ evaluationsSuccessfullyImportedFromCsv }) => {
                if (hasValue(evaluationsSuccessfullyImportedFromCsv)) {
                    goToSuccess()
                }
            }
        })
    }, [evaluationsSuccessfullyImportedResponse])

    useEffect(() => {
        handleResponse(evaluationsFailedToImportResponse, {
            onData: ({ evaluationsFailedToImportFromCsv }) => {
                if (hasValue(evaluationsFailedToImportFromCsv)) {
                    goToFailure()
                }
            }
        })
    }, [evaluationsFailedToImportResponse])

    return {
        mode,
        goToFailure,
        goToSuccess,
        goToInProgress,
    }
}

export {
    useCSVBulkEvaluationImportStep
}