import { Cn } from "@helpers/unsorted/classNames"
import { useBulkActionModalContext } from "@pages/ApplicationsPage/BulkActionModalContext"
import { Button } from "@shared/unsorted/Button/Button"
import { Loader } from "@shared/unsorted/Loader/Loader"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const styles = {
    body: Cn.c("flex justify-center items-center flex-1"),
    footer: Cn.c("p-6 border-t border-default")
}

interface Props {
    closeModal: VoidFunction
}

const SessionSelectionStepLoadingState: FunctionComponent<Props> = ({
    closeModal
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.body}>
                <Loader
                    variant="primaryDefault"
                />
            </div>
            <div className={styles.footer}>
                <Button
                    size="md"
                    variant="secondary"
                    onClick={closeModal}
                >
                    {t('global.cancel')}
                </Button>
            </div>
        </>
    )
}

export {
    SessionSelectionStepLoadingState
}