import { EmailTemplate, JobPosition_OneByIdWithStepEventsQuery } from "@entities"
import { showFullName } from '@helpers/core/client';
import { displayedRecruitmentStages } from "@helpers/core/constants"
import { hasValue, isNullish } from "@helpers/core/typeGuards"
import { useClient } from "@helpers/hooks/unsorted/clientHook"
import { Cn } from "@helpers/unsorted/classNames"
import { getWithDefault } from "@helpers/unsorted/stringExtra"
import { CollapsibleRecruitmentStage } from "@shared/job_position/RecruitmentFlow/CollapsibleRecruitmentStage"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { JobPositionDetailMetadata } from "@shared/job_position/JobPositionDetailMetadata"
import { EmailTemplateMode } from "./EmailTemplateUpdateModal/hook";
import { ReadOnlyRecruitmentStage } from "./ReadOnlyRecruitmentStage";

const styles = {
    section: Cn.c("pb-8"),
    sectionTitle: Cn.c("font-h2-bold text-emphasized mb-2"),
    description: Cn.c('font-paragraph-base-regular text-subdued mb-4'),
    requiredDocs: (isEmpty: boolean) => Cn.join([
        Cn.c('font-paragraph-base-regular'),
        isEmpty ? Cn.c('text-disabled') : Cn.c('text-emphasized')
    ]),
    stageContainer: Cn.c("flex flex-col space-y-6")
}

interface Props {
    jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>
    openEmailTemplateUpdateModal: (modalType: EmailTemplateMode['name'], payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        emailTemplate: EmailTemplate
    }) => void;
    openEvaluationFormUpdateModal: (payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        questions?: string | null,
        isModifiable: boolean
    }) => void;
}

const JobPositionDetail: FunctionComponent<Props> = ({ jobPosition, openEmailTemplateUpdateModal, openEvaluationFormUpdateModal }) => {
    const {
        title,
        documentRequirements,
        recruitmentStepsV2,
        createdAt,
        updatedAt,
        createdBy,
        creator,
        isDraft,
    } = jobPosition;

    const { t } = useTranslation();

    const { currentClient } = useClient();

    const getCreator = () => {
        if (currentClient.clientState === "loggedIn" && createdBy === currentClient.clientInfo.id) {
            return t('global.you')
        }

        return getWithDefault(creator?.fullName && showFullName(t, creator));
    }

    const getIncompleteStepNames = (steps: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>[]) => {
        return steps.filter(step =>
            step.hasNotification && !step.notificationEmailTemplate
            || step.hasEvaluation && !step.evaluationForm
            || step.hasDecision && (!step.decisionFailEmailTemplate || !step.decisionPassEmailTemplate)
        ).map(step => step.name)
    }

    return (
        <>
            <div className={styles.section}>
                <JobPositionDetailMetadata
                    title={title}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    creator={getCreator()}
                    isDraft={isDraft}
                />
            </div>
            <div className={styles.section}>
                <p className={styles.sectionTitle}>{t("jobPositions.shared.documentRequirement.title")}</p>
                <p className={styles.description}>{t("jobPositions.shared.documentRequirement.description")}</p>
                <p className={styles.requiredDocs(isNullish(documentRequirements))}>
                    {
                        hasValue(documentRequirements)
                            ? documentRequirements
                            : t("jobPositions.detail.noRequiredDocs")
                    }
                </p>
            </div>
            <div className={styles.section}>
                <p className={styles.sectionTitle}>{t("jobPositions.shared.recruitmentFlow.title")}</p>
                <p className={styles.description}>{t("jobPositions.detail.flowDescription")}</p>
                <div className={styles.stageContainer}>
                    {displayedRecruitmentStages.map((stage, stageIndex) => {
                        const steps = recruitmentStepsV2.filter(step => step.stage === stage);
                        const incompleteStepNames = getIncompleteStepNames(steps)

                        return (
                            <CollapsibleRecruitmentStage
                                index={stageIndex + 1}
                                stageName={`jobPositions.shared.stage.${stage}.name`}
                                stageDescription={`jobPositions.shared.stage.${stage}.description`}
                                isCollapsed={false}
                                key={stage}
                                incompleteStepNames={incompleteStepNames}
                            >
                                <ReadOnlyRecruitmentStage
                                    recruitmentSteps={steps}
                                    openEmailTemplateUpdateModal={openEmailTemplateUpdateModal}
                                    openEvaluationFormUpdateModal={openEvaluationFormUpdateModal}
                                />
                            </CollapsibleRecruitmentStage>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export {
    JobPositionDetail
}
