import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Cn } from '@helpers/unsorted/classNames'
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { ApplicationCandidateDetailListMode } from './ApplicationCandidateDetailListMode/ApplicationCandidateDetailListMode';
import { ApplicationCandidateDetailEditMode } from './ApplicationCandidateDetailEditMode/ApplicationCandidateDetailEditMode';
import { UseFormReturn } from 'react-hook-form';
import * as value from './value';
import { Mode } from './hook';

const styles = {
    container: Cn.c('bg-surface-default rounded h-full m-4 shadow-base flex flex-col m-0 overflow-y-auto'),
    basicInfoContainer: Cn.c('p-4 space-y-8'),
}

interface Props {
    mode: Mode;
    form: UseFormReturn<value.Encoder.EncoderType>;
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
}

const ApplicationCandidateDetailPanelContent: FunctionComponent<Props> = ({
    mode,
    form,
    application,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.basicInfoContainer}>
                {mode.name === 'list'
                    ? <ApplicationCandidateDetailListMode application={application} />
                    : <ApplicationCandidateDetailEditMode
                        form={form}
                        candidate={application.candidate}
                        editPayload={mode.payload}
                    />
                }
            </div>
        </div>
    );
}

export {
    ApplicationCandidateDetailPanelContent
}