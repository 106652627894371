import { FunctionComponent } from "react";
import { useCSVBulkEvaluationImportStep } from "./hook";
import { CSVBulkEvaluationImportStepInProgess } from "./inProgress/CSVBulkEvaluationImportStepInProgess";
import { CSVBulkEvaluationImportStepFailure } from "./failure/CSVBulkEvaluationImportStepFailure";
import { CSVBulkEvaluationImportStepSuccess } from "./success/CSVBulkEvaluationImportStepSuccess";


interface Props {
    totalCount: number;
}

const CSVBulkEvaluationImportStep: FunctionComponent<Props> = ({
    totalCount
}) => {
    const { mode, goToInProgress } = useCSVBulkEvaluationImportStep()

    return mode === 'inProgress'
        ? <CSVBulkEvaluationImportStepInProgess
            totalCount={totalCount}
        />
        : mode === 'success'
            ? <CSVBulkEvaluationImportStepSuccess totalCount={totalCount} />
            : <CSVBulkEvaluationImportStepFailure goToInProgress={goToInProgress} />
}

export {
    CSVBulkEvaluationImportStep
}