import { Cn } from "@helpers/unsorted/classNames";
import { CloseIcon } from "@shared/functional_components/CloseIcon";
import { OpenJobListPanelButton } from "@shared/job_position/JobListPanel/OpenJobListPanelButton";
import { Button } from "@shared/unsorted/Button/Button";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    container: Cn.c("flex flex-col bg-surface-default h-full"),
    header: Cn.c("px-6 py-4 flex items-center justify-between"),
    rightContainer: Cn.c("flex items-center justify-between space-x-5"),
    jobListContainer: Cn.c("flex content-center items-center space-x-4"),
    buttons: Cn.c("flex items-center justify-between space-x-4"),
    modalTitle: Cn.c("font-paragraph-base-medium text-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-icons-emphasized cursor-pointer"),
    body: Cn.c('overflow-auto flex flex-col flex-1 px-28 pb-8'),
    loadingContainer: Cn.c("flex items-center justify-center flex-1"),
}

interface Props {
    jobTitle: string;
    switchToViewMode: VoidFunction;
}

const JobPositionDetailEditModeLoadingState: FunctionComponent<Props> = ({
    jobTitle,
    switchToViewMode
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.jobListContainer}>
                    <OpenJobListPanelButton />
                    <p className={styles.modalTitle}>{jobTitle}</p>
                </div>
                <div className={styles.rightContainer}>
                    <Button
                        size="md"
                        variant="secondary"
                        onClick={switchToViewMode}
                    >
                        {t('global.cancel')}
                    </Button>
                    <CloseIcon />
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.loadingContainer}>
                    <Loader
                        variant="primaryDefault"
                        size="lg"
                    />
                </div>
            </div>
        </div>
    );
}

export {
    JobPositionDetailEditModeLoadingState
}