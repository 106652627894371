import { EventSessionsDocument, EventSessionsQuery, UpdateCurrentClientShowWelcomeDocument, ActivityLogsInput } from '@entities';
import { useTranslation } from 'react-i18next';
import { useClient, ClientInfo } from '@helpers/hooks/unsorted/clientHook';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { SelectionOption } from '@typedefs/selectOption';
import { handleResponse, useQueryContext } from '@helpers/unsorted/urqlExtra';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import cookies, { WELCOME_MODAL_SHOWN_COOKIE } from '@helpers/core/cookies'
import { isBoolean } from '@helpers/core/typeGuards';
import { endOfWeek } from 'date-fns';
import { Entity } from '@typedefs/graphql';
import { useListPageTrackerContext } from '../../../contexts/ListPageTrackerContext';
import { useLocation } from 'react-router-dom';
import { dateRangeTypeToDateRange, dateRangeTypeSchema, ActivityLogFilter } from "./value";

interface HomePageHookType {
    clientInfo: ClientInfo | null;
    shouldDisplayWelcomeModal: boolean;
    onCloseWelcomeModal: (dontShowAgain: boolean) => void;
    eventSessions: Entity<EventSessionsQuery, 'eventSessions'>[]
    activityLogsInputs: ActivityLogsInput[];
    activityLogFilter: ActivityLogFilter;
    activityLogDateRangeFilterOptions: SelectionOption[];
    onChangeActivityLogDateRangeFilter: (value: string) => void;
    onLoadMoreActivityLogs: (input: ActivityLogsInput) => void;
}

const useHomePage = (): HomePageHookType => {
    const { currentClient } = useClient();

    const { t } = useTranslation();

    const clientInfo = useMemo(() => currentClient.clientState === 'loggedIn' ? currentClient.clientInfo : null, [currentClient]);

    const { error: toastError } = useToast();

    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);

    const [updateShowWelcomeResponse, updateShowWelcome] = useMutation(UpdateCurrentClientShowWelcomeDocument);

    const [eventSessions, setEventSessions] = useState<Entity<EventSessionsQuery, 'eventSessions'>[]>([]);

    const [activityLogFilter, setActivityLogFilter] = useState<ActivityLogFilter>({
        dateRange: dateRangeTypeSchema.enum.thisWeek
    });

    const [activityLogsInputs, setActivityLogsInputs] = useState<ActivityLogsInput[]>([]);

    const [, setLastestListPageRoute] = useListPageTrackerContext()

    const location = useLocation()

    const timeRange = useMemo(() => {
        const startAt = new Date();
        const endAt = endOfWeek(startAt, { weekStartsOn: 1 });

        return {
            startAt,
            endAt
        };
    }, []);

    useEffect(() => {
        const dateRange = dateRangeTypeToDateRange(activityLogFilter.dateRange, new Date());
        setActivityLogsInputs([{
            order: 'desc',
            first: 50,
            offset: 0,
            from: dateRange.from?.toISOString(),
            to: dateRange.to?.toISOString()
        }]);
    }, [activityLogFilter.dateRange]);

    const activityLogDateRangeFilterOptions = useMemo((): SelectionOption[] => {
        return [
            dateRangeTypeSchema.enum.all,
            dateRangeTypeSchema.enum.today,
            dateRangeTypeSchema.enum.thisWeek,
            dateRangeTypeSchema.enum.thisMonth,
            dateRangeTypeSchema.enum.within3Months,
            dateRangeTypeSchema.enum.within6Months,
            dateRangeTypeSchema.enum.within1Year,
            dateRangeTypeSchema.enum.atleast1Year,
        ].map(type => ({
            key: type,
            label: t(`dashboard.dateRanges.${type}`),
            value: type,
        })
        )
    }, [t]);

    const eventSessionsContext = useQueryContext('EventSession');
    const [eventSessionsResponse] = useQuery({
        query: EventSessionsDocument,
        context: eventSessionsContext,
        variables: {
            input: {
                startAt: timeRange.startAt.toISOString(),
                endAt: timeRange.endAt.toISOString(),
                first: 5,
                sortType: 'START_AT',
                order: 'asc'
            }
        }
    })

    const welcomeModalCookie = cookies.get(WELCOME_MODAL_SHOWN_COOKIE);
    const isWelcomeModalAlreadyShown = isBoolean(welcomeModalCookie) ? welcomeModalCookie : false;

    const onCloseAction = () => {
        cookies.set(WELCOME_MODAL_SHOWN_COOKIE, true);
        setIsWelcomeModalOpen(false);
    };

    const onCloseWelcomeModal = (isDontShowAgainChecked: boolean) => {
        if (currentClient.clientState === 'loggedIn' && (currentClient.clientInfo.showWelcome === isDontShowAgainChecked)) {
            // Only call the mutation if the value has changed
            updateShowWelcome({ showWelcome: !isDontShowAgainChecked });
        } else {
            onCloseAction();
        }
    };

    const onChangeActivityLogDateRangeFilter = (value: string) => {
        const result = dateRangeTypeSchema.safeParse(value);
        if (!result.success) {
            return;
        }
        setActivityLogFilter(filter => ({ ...filter, dateRange: result.data }))
    }

    const onLoadMoreActivityLogs = (input: ActivityLogsInput) => {
        setActivityLogsInputs(state => [...state, input]);
    }

    useEffect(() => {
        handleResponse(eventSessionsResponse, {
            onData: ({ eventSessions }) => {
                setEventSessions(eventSessions);
            }
        })
    }, [eventSessionsResponse]);

    useEffect(() => {
        handleResponse(updateShowWelcomeResponse, {
            onData: (data) => {
                if (data.updateCurrentClientShowWelcome) {
                    onCloseAction();
                }
            },
            onError: () => {
                // TODO: Verify how to handle this.
                // Still close the modal, but there is a possibility that it will be shown again even if don't show again is checked
                toastError('global.error');
                onCloseAction();
            },
        });
    }, [updateShowWelcomeResponse]);

    useEffect(() => {
        setLastestListPageRoute({
            PATH_NAME: location.pathname,
            search: location.search
        })
    }, [location])

    return {
        clientInfo,
        shouldDisplayWelcomeModal: currentClient.clientState === 'loggedIn' && currentClient.clientInfo.showWelcome
            && !isWelcomeModalAlreadyShown && isWelcomeModalOpen,
        onCloseWelcomeModal,
        eventSessions,
        activityLogFilter,
        activityLogsInputs,
        activityLogDateRangeFilterOptions,
        onChangeActivityLogDateRangeFilter,
        onLoadMoreActivityLogs
    };
};

export {
    useHomePage,
};
