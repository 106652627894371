import { FunctionComponent } from "react"
import { BulkEventSessionAssignmentErrorModalPayload } from "../reducer"
import { ModalProps } from "@typedefs/props"
import { Modal } from "@shared/unsorted/Modal/Modal"
import { Cn } from "@helpers/unsorted/classNames"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Button } from "@shared/unsorted/Button/Button"
import { useTranslation } from "react-i18next"
import { useBulkEventSessionAssignmentErrorModal } from "./hook"

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-critical-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-critical-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-critical-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    buttons: Cn.c("mt-6 flex space-x-3 justify-between px-6")
}

interface Props extends ModalProps {
    payload?: BulkEventSessionAssignmentErrorModalPayload
    defaultCloseModal: VoidFunction
}

const BulkEventSessionAssignmentErrorModal: FunctionComponent<Props> = ({
    isOpen,
    defaultCloseModal,
    payload
}) => {
    const onClose = payload?.closeModal || defaultCloseModal
    const { t } = useTranslation()

    const { retry } = useBulkEventSessionAssignmentErrorModal({
        closeModal: onClose,
        errorPayload: payload
    })

    return (
        <Modal
            isOpen={isOpen}
            close={onClose}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t('applications.bulkEventSessionAssignmentError.description')}</p>
                    <p className={styles.subDescription}>{t('applications.bulkEventSessionAssignmentError.subDescription')}</p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={onClose}
                        isFull={true}
                    >
                        {t('global.dismiss')}
                    </Button>
                    <Button
                        variant='primaryFilled'
                        size="md"
                        onClick={retry}
                        isFull={true}
                    >
                        {t('global.retry')}
                    </Button>
                </div>
            </div>
        </Modal >
    )
}

export {
    BulkEventSessionAssignmentErrorModal
}