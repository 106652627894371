import { Cn } from '@helpers/unsorted/classNames';
import { FinalizeDecisionPayload } from '@pages/ApplicationsPage/reducer';
import { Button } from '@shared/unsorted/Button/Button';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFinalizeDecision } from './hook';
import { hasValue, isUndefined } from '@helpers/core/typeGuards';

const styles = {
    title: Cn.c('font-h3-bold text-default px-6 py-4 border-b border-b-default'),
    content: Cn.c('p-6'),
    buttons: Cn.c('flex flex-row space-x-4 justify-end px-6 py-4 bg-surface-subdued'),
    required: Cn.c('text-icons-critical-default mr-1'),
    description: Cn.c('mb-6 font-paragraph-small-medium text-default'),
    reasonForm: Cn.c('inline-block space-y-4'),
    reason: Cn.c('flex items-center font-paragraph-small-regular text-default'),
    radioInput: Cn.c('mr-2 h-4 w-4'),
    warningMessage: Cn.c('font-paragraph-small-medium text-default'),
    form: (isWarningMessageDisplayed: boolean) => Cn.ifTrue(isWarningMessageDisplayed, Cn.c('pt-4')),
};

interface Props {
    isOpen?: boolean;
    closeModal: VoidFunction;
    applicationDetail?: FinalizeDecisionPayload;
}

const FinalizeDecisionModal: FunctionComponent<Props> = ({
    isOpen = false,
    closeModal,
    applicationDetail,
}) => {
    const { t } = useTranslation();

    const {
        failReason,
        setFailReason,
        submit,
        close,
        isSubmitting,
        shouldShowWarningMessage,
    } = useFinalizeDecision(closeModal, applicationDetail);

    return (
        <Modal isOpen={isOpen} close={close}>
            <div className={styles.title}>
                {t("applications.panel.finalizeDecision.title")}
            </div>
            <div className={styles.content}>
                {shouldShowWarningMessage() &&
                    <div className={styles.warningMessage}>
                        {t("applications.panel.finalizeDecision.warning", {
                            preliminaryResult: applicationDetail?.preliminaryResult,
                            finalResult: applicationDetail?.finalResult,
                        }
                        )}
                    </div>
                }
                {hasValue(applicationDetail) && applicationDetail.finalResult === 'FAILED' &&
                    <div className={styles.form(shouldShowWarningMessage())}>
                        <p className={styles.description}>
                            <span className={styles.required}>*</span>
                            {t("applications.panel.finalizeDecision.description")}
                        </p>
                        <div className={styles.reasonForm}>
                            <div className={styles.reason}>
                                <label>
                                    <input
                                        type="radio"
                                        className={styles.radioInput}
                                        name="reason"
                                        checked={failReason === "REJECTED_SKILL_MISMATCH"}
                                        onClick={() => setFailReason("REJECTED_SKILL_MISMATCH")}
                                    />
                                    {t("recruitmentStepStatusV2.REJECTED_SKILL_MISMATCH")}
                                </label>
                            </div>
                            <div className={styles.reason}>
                                <label>
                                    <input
                                        type="radio"
                                        className={styles.radioInput}
                                        name="reason"
                                        checked={failReason === "REJECTED_CULTURE_MISMATCH"}
                                        onClick={() => setFailReason("REJECTED_CULTURE_MISMATCH")}
                                    />
                                    {t("recruitmentStepStatusV2.REJECTED_CULTURE_MISMATCH")}
                                </label>
                            </div>
                            <div className={styles.reason}>
                                <label>
                                    <input
                                        type="radio"
                                        className={styles.radioInput}
                                        name="reason"
                                        checked={failReason === "REJECTED_OTHER"}
                                        onClick={() => setFailReason("REJECTED_OTHER")}
                                    />
                                    {t("recruitmentStepStatusV2.REJECTED_OTHER")}
                                </label>
                            </div>
                            <div className={styles.reason}>
                                <label>
                                    <input
                                        type="radio"
                                        className={styles.radioInput}
                                        name="reason"
                                        checked={failReason === "CLOSED_NO_REPLY"}
                                        onClick={() => setFailReason("CLOSED_NO_REPLY")}
                                    />
                                    {t("recruitmentStepStatusV2.CLOSED_NO_REPLY")}
                                </label>
                            </div>
                            <div className={styles.reason}>
                                <label>
                                    <input
                                        type="radio"
                                        className={styles.radioInput}
                                        name="reason"
                                        checked={failReason === "CLOSED_OTHER"}
                                        onClick={() => setFailReason("CLOSED_OTHER")}
                                    />
                                    {t("recruitmentStepStatusV2.CLOSED_OTHER")}
                                </label>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className={styles.buttons}>
                <Button
                    size="md"
                    variant="secondary"
                    isDisabled={isSubmitting}
                    onClick={close}>
                    {t("global.cancel")}
                </Button>
                <Button
                    size="md"
                    onClick={() => submit()}
                    isDisabled={applicationDetail?.finalResult !== 'PASSED' && isUndefined(failReason)}
                    isLoading={isSubmitting}
                >
                    {t("global.confirm")}
                </Button>
            </div>
        </Modal>
    );
};

export {
    FinalizeDecisionModal,
};
