import { createContext } from 'react';

interface TabContextType {
    activeTab?: string;
    setActiveTab: (newActiveTab?: string, route?: string) => void;
}

const TabContext = createContext<TabContextType | undefined>(undefined);

export {
    TabContextType,
    TabContext,
};