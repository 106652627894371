import { Cn } from "@helpers/unsorted/classNames";
import { validations } from "@helpers/unsorted/validation";
import { Button } from "@shared/unsorted/Button/Button";
import { Input } from "@shared/unsorted/Input/Input";
import { FunctionComponent } from "react"
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoginDevPage } from "./hook";

const styles = {
  container: Cn.c("flex flex-col min-h-screen bg-default"),
  card: Cn.c("w-[36rem] bg-surface-default rounded shadow-base m-auto p-6 space-y-6"),
  title: Cn.c("font-h2-bold text-emphasized text-center"),
  password: Cn.c("mt-6"),
  buttons: Cn.c('flex justify-end mt-4'),
}

const LoginDevPage: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    onSubmit,
    form
  } = useLoginDevPage()

  const { control, clearErrors, formState: { errors } } = form

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>
          {t('global.login')}
        </h2>
        <form onSubmit={onSubmit}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true, pattern: validations.email }}
            render={({field: {name, onBlur, onChange, ref, value}}) => 
              <Input
                required
                name={name}
                label={"devLogin.email"}
                onBlur={onBlur}
                onChange={onChange}
                errors={errors}
                clearErrors={clearErrors}
                forwardedRef={ref}
                value={value}
            />
            }
          />
          <div className={styles.password}>
            <Controller
              name='password'
              control={control}
              rules={{ required: true }}
              render={({field: {name, onBlur, onChange, ref, value}}) => 
                <Input
                  required
                  name={name}
                  label={"devLogin.password"}
                  onBlur={onBlur}
                  onChange={onChange}
                  errors={errors}
                  clearErrors={clearErrors}
                  forwardedRef={ref}
                  value={value}
              />
              }
            />
          </div>
          <div className={styles.buttons}>
            <Button type="submit" size='md'>
              {t("global.login")}
            </Button>
          </div>
        
        </form>
      </div>
    </div>
  );
}

export {
    LoginDevPage
}