interface ApplicationStepShowMode {
    name: 'show';
}

interface ApplicationStepUpdateScreeningMode {
    name: 'updateScreening';
    payload: {
        statusId: string;
    };
}
interface ApplicationStepUpdateEvaluationMode {
    name: 'updateEvaluation';
    payload: {
        evaluationId: string;
    };
}

interface ApplicationStepSubmitMode {
    name: 'submit';
}

interface ApplicationForceSubmitEvaluateDocumentMode {
    name: 'forceSubmitEvaluateDocument';
}

interface ApplicationForceSubmitEvaluateInterviewMode {
    name: 'forceSubmitEvaluateInterview';
}

interface ApplicationForceSubmitSetJobOfferDateMode {
    name: 'forceSubmitSetJobOfferDate';
}

interface EmailReplyMode {
    name: 'replyEmail',
    payload: {
        emailThreadItemId: string;
    }
}

type ApplicationStepMode =
    ApplicationStepShowMode |
    ApplicationStepUpdateScreeningMode |
    ApplicationStepUpdateEvaluationMode |
    ApplicationStepSubmitMode |
    ApplicationForceSubmitEvaluateDocumentMode |
    ApplicationForceSubmitEvaluateInterviewMode |
    ApplicationForceSubmitSetJobOfferDateMode |
    EmailReplyMode;

export type State = {
    applicationStepMode: ApplicationStepMode;
};

interface SetApplicationStepModeAction {
    name: 'SetApplicationStepMode';
    applicationStepMode: ApplicationStepMode;
}

type Action = SetApplicationStepModeAction;

const reducer = (state: State, action: Action) => {
    switch (action.name) {
        case 'SetApplicationStepMode':
            return {
                ...state,
                applicationStepMode: action.applicationStepMode,
            };
    }
}

const initialState: State = {
    applicationStepMode: {
        name: 'show',
    },
};

export {
    reducer,
    initialState,
    SetApplicationStepModeAction,
    ApplicationStepMode,
};