import { isOneOf, isUndefined } from "@helpers/core/typeGuards";
import { Candidate } from "@routes/candidate";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BulkActionType, constructBulkActionProperty, SelectedApplications } from "./helpers";
import { SimpleApplication } from "@typedefs/aliases";
import { updateStatusBulkActions } from "@helpers/core/constants";

type ApplicationSelection = Partial<Record<BulkActionType, SelectedApplications>>

type ApplicationSelectionContextType = {
    applicationSelection: ApplicationSelection,
    setApplicationSelection: (mode: ApplicationSelection) => void,
    selectedApplicationIds: string[]
    addApplications: (applications: SimpleApplication[]) => void
    removeApplications: (applications: SimpleApplication[]) => void
};

const ApplicationSelectionContext = createContext<ApplicationSelectionContextType | undefined>(undefined);

const ApplicationSelectionProvider = ({ children }: { children: React.ReactNode }) => {
    const [applicationSelection, setApplicationSelection] = useState<ApplicationSelection>({});

    const queryParams = Candidate.useSearchParams();

    const currentQueryParamRef = useRef<Candidate.QueryParams>(queryParams)

    const getBulkActions = (application: SimpleApplication): BulkActionType[] => {
        const { mainActions, otherActions } = application.currentStepStatusActions;
        let allActions: BulkActionType[] = mainActions.concat(otherActions).filter(isOneOf(updateStatusBulkActions));

        if (application.currentStep.stage !== 'ARCHIVE') {
            allActions = allActions.concat('ARCHIVE')
        } else {
            allActions = allActions.concat('REVERT_TO_PREVIOUS_STATUS')
        }

        return allActions;
    }

    const applicationIdsMap = useMemo(() => Object.values(applicationSelection).reduce((acc, curr) => {
        return {
            ...acc,
            ...curr
        }
    }, {}), [applicationSelection])

    const selectedApplicationIds = useMemo(() => Object.keys(applicationIdsMap), [applicationIdsMap])

    const addApplications = (applications: SimpleApplication[]) => {
        const newApplicationSelection = structuredClone(applicationSelection);

        for (const application of applications) {
            const allActions = getBulkActions(application);

            for (const action of allActions) {
                let actionEntry = newApplicationSelection[action]
                if (!actionEntry) {
                    actionEntry = {};
                }
                actionEntry[application.id] = constructBulkActionProperty(action, {
                    id: application.id,
                    stepId: application.currentStep.id,
                    eventSessionGroupAssignments: application.eventSessionGroupAssignments
                });
                newApplicationSelection[action] = actionEntry;
            }
        }

        setApplicationSelection(newApplicationSelection);
    }

    const removeApplications = (applications: SimpleApplication[]) => {
        const newApplicationSelection = structuredClone(applicationSelection);

        for (const application of applications) {
            const allActions = getBulkActions(application);

            for (const action of allActions) {
                delete newApplicationSelection[action]?.[application.id];

                const actionEntry = newApplicationSelection[action]

                if (actionEntry && Object.keys(actionEntry).length === 0) {
                    delete newApplicationSelection[action];
                }
            }
        }

        setApplicationSelection(newApplicationSelection);
    }

    useEffect(() => {
        if (
            currentQueryParamRef.current.applicationType !== queryParams.applicationType
            || currentQueryParamRef.current.jobPositionIds !== queryParams.jobPositionIds
            || currentQueryParamRef.current.search.candidateName !== queryParams.search.candidateName
            || currentQueryParamRef.current.sortType !== queryParams.sortType
            || currentQueryParamRef.current.order !== queryParams.order
            || currentQueryParamRef.current.stages !== queryParams.stages
            || currentQueryParamRef.current.ongoingStepStatuses !== queryParams.ongoingStepStatuses
            || currentQueryParamRef.current.archivedStepStatuses !== queryParams.archivedStepStatuses
            || currentQueryParamRef.current.stepIds !== queryParams.stepIds
        ) {
            setApplicationSelection({});
        }

        currentQueryParamRef.current = queryParams
    }, [queryParams])

    return (
        <ApplicationSelectionContext.Provider value={{
            applicationSelection,
            setApplicationSelection,
            selectedApplicationIds,
            addApplications,
            removeApplications,
        }}>
            {children}
        </ApplicationSelectionContext.Provider>
    );
};

const useApplicationSelectionContext = () => {
    const context = useContext(ApplicationSelectionContext)

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <ApplicationSelectionProvider>...</ApplicationSelectionProvider> block.');
    }

    return context
}

export {
    ApplicationSelectionContext,
    ApplicationSelectionProvider,
    ApplicationSelection,
    useApplicationSelectionContext,
}
