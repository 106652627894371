import { SelectableStepTypesQuery } from "@entities";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { displayedRecruitmentStages } from "@helpers/core/constants";
import { hasValue } from "@helpers/core/typeGuards";
import { NestedFormProps } from "@helpers/hooks/unsorted/useNestedForm";
import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CollapsibleRecruitmentStage } from "../CollapsibleRecruitmentStage";
import { RecruitmentStageHeader } from "../RecruitmentStageHeader";
import { isStepEditable } from "../helper";
import { Row } from "./Row";
import { useRecruitmentFlowSubForm } from "./hook";

const styles = {
    table: Cn.c('w-full font-paragraph-small-regular'),
    row: (isFirst = false) => Cn.join([
        Cn.c('flex py-0 px-6 items-start relative'),
        Cn.ifTrue(isFirst, Cn.c('mt-4')),
    ]),
    addStepIconContainer: Cn.c('text-primary-default font-paragraph-small-medium flex items-center justify-start gap-x-2 cursor-pointer pt-6 pb-7 ml-10 w-25'),
    addStepIcon: Cn.c('w-5 h-5'),
    noStepsContainer: Cn.c('mt-6 ml-10 text-subdued font-paragraph-small-regular'),
    stageContainer: Cn.c("flex flex-col space-y-6 mt-6")
}

interface Props extends NestedFormProps {
    recruitmentStepTypes: Entity<SelectableStepTypesQuery, 'selectableStepTypes'>;
    isEditMode?: boolean;
}

const RecruitmentFlowSubForm: FunctionComponent<Props> = ({
    recruitmentStepTypes,
    path,
    isEditMode = false,
}) => {
    const {
        currentDropArea,
        setCurrentDropArea,
        onDragEnd,
        fields,
        insertNewRecruitmentStep,
        stepTypeOptions,
        removeRecruitmentStep,
    } = useRecruitmentFlowSubForm(recruitmentStepTypes, path)

    const { t } = useTranslation();

    return (
        <div>
            <DragDropContext
                onDragEnd={(...parameters) => {
                    setCurrentDropArea(null);
                    onDragEnd(...parameters);
                }}
                onDragStart={(result) => setCurrentDropArea(result.source.droppableId)}
                onDragUpdate={(result) => setCurrentDropArea(result.destination?.droppableId || null)}
            >
                {displayedRecruitmentStages.map((stage, stageIndex) => {
                    const stepList = fields.filter(step => step.stage === stage)

                    return (
                        <div className={styles.stageContainer} key={stage}>
                            <CollapsibleRecruitmentStage
                                index={stageIndex + 1}
                                stageName={`jobPositions.shared.stage.${stage}.name`}
                                stageDescription={`jobPositions.shared.stage.${stage}.description`}
                                isCollapsed={false}

                            >
                                <Droppable droppableId={stage}
                                    renderClone={(provided, snapshot, rubric) => {
                                        const stepIndex = rubric.source.index;
                                        const step = stepList[stepIndex];

                                        return (
                                            <div className={styles.row(rubric.source.index === 0)}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                {hasValue(step) &&
                                                    <Row
                                                        fields={fields}
                                                        stage={stage}
                                                        provided={provided}
                                                        step={step}
                                                        index={stepIndex}
                                                        removeRecruitmentStep={removeRecruitmentStep}
                                                        stepTypeOptions={stepTypeOptions}
                                                        isEditMode={isEditMode}
                                                    />
                                                }
                                            </div>
                                        )
                                    }}
                                >
                                    {provided => (
                                        <div className={styles.table}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <RecruitmentStageHeader />
                                            {stepList.map((step, index) => {
                                                const isEditable = isStepEditable(step);

                                                return (
                                                    <Draggable draggableId={step.id} index={index} isDragDisabled={!isEditable} key={step.id}>
                                                        {provided =>
                                                            <div className={styles.row(index === 0)}
                                                                {...provided.draggableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <Row
                                                                    fields={fields}
                                                                    stage={stage}
                                                                    provided={provided}
                                                                    step={step}
                                                                    index={index}
                                                                    removeRecruitmentStep={removeRecruitmentStep}
                                                                    stepTypeOptions={stepTypeOptions}
                                                                    isEditMode={isEditMode}
                                                                />
                                                            </div>
                                                        }
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable >
                                {
                                    stepList.length === 0 && currentDropArea !== stage
                                    && <div className={styles.noStepsContainer}>{t("jobPositions.shared.recruitmentFlow.noStepsToShow")}</div>
                                }
                                <div className={styles.addStepIconContainer} onClick={() => {
                                    insertNewRecruitmentStep(stage);
                                }}>
                                    <Icon name="plus" className={styles.addStepIcon} />
                                    <span>{t("jobPositions.shared.recruitmentFlow.addAStep")}</span>
                                </div >
                            </CollapsibleRecruitmentStage>
                        </div>

                    );
                })}
            </DragDropContext>
        </div>
    );
}

export {
    RecruitmentFlowSubForm
};
