import config from "./config"
import cookies, { WELCOME_MODAL_SHOWN_COOKIE } from "./cookies"

const refreshTokens = () => {
    cookies.remove(WELCOME_MODAL_SHOWN_COOKIE)
    const redirectTo = encodeURIComponent(window.location.toString())
    window.location.replace(`${config.oauthRefreshTokensUri}?redirectTo=${redirectTo}`)
}

const devLogin = (email: string, password: string) => {
    const queryParamEmail = encodeURIComponent(email)
    const queryParamPassword = encodeURIComponent(password)
    window.location.replace(`${config.oauthDevLoginUri}?email=${queryParamEmail}&password=${queryParamPassword}`)
}

const logout = () => {
    window.location.replace(config.oauthAccessLogoutUri)
}

const auth = {
    refreshTokens,
    logout,
    devLogin
}

export default auth