import { ForwardedRefProps, HTMLPassedProps } from '@typedefs/props';
import { FunctionComponent, isValidElement, ReactElement } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';
import { Icon, IconName } from '../Icon/Icon';
import { Cn } from '@helpers/unsorted/classNames';

const styles = {
    root: Cn.c("w-full"),
    inputRoot: (isChecked: boolean, isDisabled: boolean, className?: string) => Cn.join([
        Cn.c('flex p-4 border rounded-xl w-full justify-between'),
        isChecked
            ? Cn.c('border-primary-emphasized bg-surface-primary-subdued')
            : Cn.c('border-default bg-surface-default'),
        Cn.ifTrue(!isDisabled && !isChecked, Cn.c('hover:bg-surface-hovered-default')),
        Cn.ifTrue(isDisabled, Cn.c('border-default bg-surface-disabled cursor-not-allowed')),
        Cn.getSome(className),
    ]),
    container: Cn.c('flex'),
    iconContainer: Cn.c('pr-3'),
    icon: Cn.c('w-5 h-5'),
    content: Cn.c('flex flex-col pr-2 space-y-1'),
    label: Cn.c('text-emphasized font-paragraph-base-medium'),
    description: Cn.c('text-subdued font-paragraph-small-regular'),
    actions: Cn.c('flex pt-3 space-x-3'),
    actionButton: (isPrimary: boolean, isDisabled: boolean) => Cn.join([
        Cn.c('font-paragraph-small-button-medium cursor-pointer'),
        isPrimary ? Cn.c('text-primary-default') : Cn.c('text-subdued'),
        Cn.ifTrue(isDisabled, Cn.c('text-disabled cursor-auto')),
    ]),
    radioButton: Cn.c('w-6 h-6'),
    helperText: Cn.c('font-paragraph-xsmall-regular pt-2')
};

type Action = {
    label: I18nKey;
    onClick: VoidFunction;
};

interface Props extends HTMLPassedProps<'value'>, ForwardedRefProps<HTMLInputElement> {
    /** Label of the radio input card */
    label: I18nKey | ReactElement<unknown>;
    /** Description text displayed under the label */
    description: I18nKey | ReactElement<unknown>;
    /** Helper text displayed under the label */
    helperText?: I18nKey | ReactElement<unknown>;
    /** Indicator whether the radio input card is selected or not */
    checked: boolean;
    /** Icon of the radio input card */
    iconName?: IconName;
    /** Optional indicator whether the radio input card is disabled or not. false by default */
    disabled?: boolean;
    /** Optional Action to be executed when Primary Action is clicked */
    primaryAction?: Action;
    /** Optional Action to be executed when Secondary Action is clicked */
    secondaryAction?: Action;
    /** Optional function called when a radio input card is selected/unselected */
    onChange?: (event: any) => void; //TODO: rebind correct type
    /** Optional prefix of the input name */
    prefix?: string;
    /** Optional Additional class name to be applied to the radio input card's root container */
    className?: string;
}

const RadioInputItemBox: FunctionComponent<Props> = ({
    value,
    label,
    description,
    helperText,
    checked,
    disabled = false,
    iconName,
    primaryAction,
    secondaryAction,
    onChange,
    prefix,
    className,
    forwardedRef,
}) => {
    const inputName = `${prefix ?? ''}.${value}`;

    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.inputRoot(checked, disabled, className)} onClick={() => !disabled && onChange && value && onChange(value)}>
                <div className={styles.container}>
                    {iconName && <div className={styles.iconContainer}><Icon name={iconName} className={styles.icon} /></div>}
                    <div className={styles.content}>
                        {<span className={styles.label}>{isValidElement(label) ? label : t(label)}</span>}
                        {<span className={styles.description}>{isValidElement(description) ? description : t(description)}</span>}
                        {(primaryAction || secondaryAction) && <div className={styles.actions}>
                            {primaryAction &&
                                <div
                                    onClick={() => !disabled && primaryAction.onClick()}
                                    className={styles.actionButton(true, disabled)}
                                >
                                    {t(primaryAction.label)}
                                </div>
                            }
                            {secondaryAction &&
                                <div
                                    onClick={() => !disabled && secondaryAction.onClick()}
                                    className={styles.actionButton(false, disabled)}
                                >
                                    {t(secondaryAction.label)}
                                </div>
                            }
                        </div>}
                    </div>
                </div>
                <div>
                    <input
                        type="radio"
                        id={inputName}
                        name={inputName}
                        value={value}
                        className={styles.radioButton}
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                        ref={forwardedRef}
                    />
                </div>
            </div>
            {helperText && <span className={styles.helperText}>{isValidElement(helperText) ? helperText : t(helperText)}</span>}
        </div>

    );
};

export {
    RadioInputItemBox,
    Action as RadioInputItemBoxAction,
};