import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Mode, useCsvBulkActions } from "./hook";

const styles = {
    caretIcon: (mode: Mode) => Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180')),
    list: Cn.c('w-fit absolute z-[100] bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md top-[3.75rem] py-2'),
    listRow: Cn.c('font-paragraph-small-regular cursor-default py-2 px-4 text-emphasized hover:bg-surface-hovered-default min-w-[12rem]')
};

const CsvBulkActions: FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        toggleList,
        bulkActionsRef,
        listDisplayMode,
        csvBulkActions,
        onSelectAction
    } = useCsvBulkActions();

    return (
        <div ref={bulkActionsRef}>
            <Button
                size="sm"
                variant="secondary"
                iconName="caret"
                iconClassName={styles.caretIcon(listDisplayMode)}
                isTrailingIcon
                onClick={toggleList}
            >
                {t("applications.csvBulkActions.button")}
            </Button>
            {listDisplayMode === "show" &&
                <div className={styles.list}>
                    {csvBulkActions.map(({ label, value }) =>
                        <div
                            key={value}
                            className={styles.listRow}
                            onClick={() => onSelectAction(value)}
                        >
                            {label}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export {
    CsvBulkActions
}