import { FunctionComponent } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { Icon } from '../Icon/Icon';
import { ToolTipBox, TooltipPlacement } from '../ToolTipBox/ToolTipBox';

const labelVariants = ['primary', 'compact'] as const;

type Variant = typeof labelVariants[number];

const styles = {
    // TODO: [CHECK] Why do we need flex here? And why specify flex-row (default value)?
    labelWrapper: (variant: Variant) => Cn.join([
        Cn.c('flex flex-row items-center gap-x-2'),
        variant === 'primary' ? Cn.c("justify-between") : Cn.c("justify-start")
    ]),
    label: Cn.c('font-paragraph-small-medium text-emphasized flex items-center'),
    required: Cn.c('text-icons-critical-default ml-0.5'),
    tooltipIcon: Cn.c("w-3 h-3 ml-0.5"),
    tooltip: Cn.c("max-w-xs"),
};

interface Props extends ChildrenProps {
    label: I18nKey;
    labelClassName?: string;
    className?: string;
    required?: boolean;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    variant?: Variant;
}

// @ocaml.doc("Label Component
// - `label`: optional - the label to display for the input
// - `className`: optional - class to apply to the container
// - `labelClassName`: optional - class to apply to the label
// - `required`: show the * for required input - false by default
// - `variant`: optional - display the label with its sibling
// ")
const Label: FunctionComponent<Props> = ({ label, labelClassName, className, required = false, variant = 'primary', children, tooltip, tooltipPlacement = 'bottom' }) => {
    const { t } = useTranslation();

    return (
        <div className={Cn.join([styles.labelWrapper(variant), Cn.getSome(className)])}>
            <label className={Cn.join([styles.label, Cn.getSome(labelClassName)])}>
                {t(label)}
                {tooltip && <ToolTipBox
                    toolTipClassName={styles.tooltip}
                    tooltipContent={t(tooltip)}
                    placement={tooltipPlacement}>
                    <div><Icon name='questionMark' className={styles.tooltipIcon} /></div>
                </ToolTipBox>}
                {required && <span className={styles.required}>*</span>}
            </label>
            {children}
        </div>
    );
};

export {
    Label,
    Variant,
    labelVariants
};

