import { AllAcquisitionCategories_AllQuery, AllEntryCategories_AllQuery, JobPosition_OneByIdQuery, JobPositions_AllSimpleQuery } from "@entities";
import { isDefined, isUndefined } from "@helpers/core/typeGuards";
import { NestedFormProps } from "@helpers/hooks/unsorted/useNestedForm";
import { Cn } from "@helpers/unsorted/classNames";
import { Select } from "@shared/unsorted/Select/Select";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useApplicationInformationSubForm } from "./hook";
import { Icon } from "@shared/unsorted/Icon/Icon";

const styles = {
    row: Cn.c('flex justify-between space-x-4 pt-6'),
    fullWidth: Cn.c("w-full"),
    jobPositionExplanationText: Cn.c('text-subdued font-paragraph-xsmall-regular pt-1'),
    jobPositionExplanationRectangle: Cn.c('w-full p-4 bg-surface-info-subdued rounded-xl mt-2 flex space-x-3'),
    infoIcon: Cn.c("w-4 h-4 text-icons-info-emphasized"),
    jobPositionExplanation: Cn.c("grow"),
    jobPositionExplanationTitle: Cn.c("text-info-emphasized font-paragraph-small-semi-bold pb-2"),
    jobPositionExplanationDescription: Cn.c("font-paragraph-xsmall-regular text-subdued"),
    jobPositionExplanationDescriptionMain: Cn.c("mb-4"),
    jobPositionExplanationDescriptionStep: Cn.c("mb-2"),
    closeIcon: Cn.c("w-4 h-4 text-icons-info-emphasized"),
    learnMoreText: Cn.c('font-paragraph-xsmall-regular text-primary-default cursor-pointer underline'),
    questionMark: Cn.c('w-3.5 h-3.5 text-icons-primary-default'),
}

interface ApplicationInformationSubFormData {
    jobPositions: Entity<JobPositions_AllSimpleQuery, 'jobPositions.edges'>[]
    entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[]
    acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[]
    recruitmentSteps: Entity<JobPosition_OneByIdQuery, 'jobPosition.recruitmentStepsV2'>[]
}

interface Props extends NestedFormProps, ApplicationInformationSubFormData {
    onChange?: (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => void
}

const ApplicationInformationSubForm: FunctionComponent<Props> = ({
    path,
    jobPositions,
    entryCategories,
    recruitmentSteps,
    onChange,
    acquisitionCategories,
}) => {
    const { t } = useTranslation();

    const {
        jobPositionOptions,
        acquisitionCategoryOptions,
        entryCategoryOptions,
        recruitmentStepOptions,
        currentAcquisitionChannelOptions,
        currentEntryChannelOptions,
        isCurrentRecruitmentStageSourcing,
        form: { getPath, control, formState, clearErrors },
        hasDisabledJobPosition,
        currentJobPositionId,
        currentRecruitmentStepStage,
        showJobPositionExplanation,
        setShowJobPositionExplanation,
    } = useApplicationInformationSubForm({
        jobPositions,
        entryCategories,
        acquisitionCategories,
        recruitmentSteps,
        onChange,
        path,
    });

    return (
        <>
            <div className={styles.row}>
                <div className={styles.fullWidth}>
                    <Controller
                        name={getPath("jobPositionId")}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { name, onBlur, onChange, ref, value } }) => (
                            <Select
                                required
                                label={"applications.create.label.jobPosition"}
                                name={name}
                                onBlur={onBlur}
                                onChange={onChange}
                                clearErrors={() => clearErrors(getPath("jobPositionId"))}
                                forwardedRef={ref}
                                value={value}
                                errors={formState.errors}
                                options={jobPositionOptions}
                                tooltip={hasDisabledJobPosition
                                    ? "applications.create.label.jobPositionUnconfiguredStepsTooltip"
                                    : undefined}
                                tooltipPlacement={"right"}
                            />
                        )}
                    />
                    <p className={styles.jobPositionExplanationText}>
                        <Trans
                            i18nKey={"applications.create.jobPositionExplanation.shortText"}
                            components={{
                                highlight: <span
                                    className={styles.learnMoreText}
                                    onClick={() => setShowJobPositionExplanation(true)}
                                >
                                </span>
                            }}
                        />
                    </p>
                    {showJobPositionExplanation &&
                        <div className={styles.jobPositionExplanationRectangle}>
                            <Icon name="info" className={styles.infoIcon} />
                            <div className={styles.jobPositionExplanation}>
                                <p className={styles.jobPositionExplanationTitle}>
                                    {t('applications.create.jobPositionExplanation.title')}
                                </p>
                                <div className={styles.jobPositionExplanationDescription}>
                                    <p className={styles.jobPositionExplanationDescriptionMain}>
                                        {t('applications.create.jobPositionExplanation.description.main')}
                                    </p>
                                    <p className={styles.jobPositionExplanationDescriptionStep}>
                                        {t('applications.create.jobPositionExplanation.description.step1')}
                                    </p>
                                    <p>{t('applications.create.jobPositionExplanation.description.step2')}</p>
                                </div>
                            </div>
                            <Icon
                                name="close"
                                className={styles.closeIcon}
                                onClick={() => setShowJobPositionExplanation(false)}
                            />
                        </div>
                    }
                </div>
                <Controller
                    name={getPath("recruitmentStepId")}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) => (
                        <Select
                            required
                            label={"applications.create.label.recruitmentStep"}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            clearErrors={() => clearErrors(getPath("recruitmentStepId"))}
                            forwardedRef={ref}
                            value={value}
                            errors={formState.errors}
                            options={recruitmentStepOptions}
                            disabled={currentJobPositionId === ""}
                            disabledTooltip={{
                                tooltipContent: t("applications.create.label.recruitmentStepDisabledTooltip"),
                                placement: "top",
                            }}
                        />
                    )}
                />
            </div>
            <div className={styles.row}>
                <Controller
                    name={getPath("acquisitionCategoryId")}
                    control={control}
                    rules={{ required: isUndefined(currentRecruitmentStepStage) || isCurrentRecruitmentStageSourcing }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) => (
                        <Select
                            required={isUndefined(currentRecruitmentStepStage) || isCurrentRecruitmentStageSourcing}
                            label={"applications.create.label.informationCategory"}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            clearErrors={() => clearErrors(getPath("acquisitionCategoryId"))}
                            forwardedRef={ref}
                            value={value}
                            errors={formState.errors}
                            options={acquisitionCategoryOptions}
                        />
                    )}
                />
                <Controller
                    name={getPath("acquisitionChannelId")}
                    control={control}
                    rules={{ required: isUndefined(currentRecruitmentStepStage) || isCurrentRecruitmentStageSourcing }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) => (
                        <Select
                            required={isUndefined(currentRecruitmentStepStage) || isCurrentRecruitmentStageSourcing}
                            disabled={isUndefined(currentAcquisitionChannelOptions)}
                            label={"applications.create.label.informationChannel"}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            clearErrors={() => clearErrors(getPath("acquisitionChannelId"))}
                            forwardedRef={ref}
                            value={value}
                            errors={formState.errors}
                            options={currentAcquisitionChannelOptions || []}
                            disabledTooltip={{
                                tooltipContent: t("applications.create.label.informationChannelDisabledTooltip"),
                                placement: "top",
                            }}
                        />
                    )}
                />
            </div>
            <div className={Cn.join([styles.row, Cn.ifFalse(isDefined(currentRecruitmentStepStage) && !isCurrentRecruitmentStageSourcing, Cn.c('hidden'))])}>
                <Controller
                    name={getPath("entryCategoryId")}
                    control={control}
                    rules={{ required: isDefined(currentRecruitmentStepStage) && !isCurrentRecruitmentStageSourcing }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) => (
                        <Select
                            required={isDefined(currentRecruitmentStepStage) && !isCurrentRecruitmentStageSourcing}
                            label={"applications.create.label.entryCategory"}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            clearErrors={() => clearErrors(getPath("entryCategoryId"))}
                            forwardedRef={ref}
                            value={value}
                            errors={formState.errors}
                            options={entryCategoryOptions}
                        />
                    )}
                />
                <Controller
                    name={getPath("entryChannelId")}
                    control={control}
                    rules={{ required: isDefined(currentRecruitmentStepStage) && !isCurrentRecruitmentStageSourcing }}
                    render={({ field: { name, onBlur, onChange, ref, value } }) => (
                        <Select
                            required={isDefined(currentRecruitmentStepStage) && !isCurrentRecruitmentStageSourcing}
                            disabled={isUndefined(currentEntryChannelOptions)}
                            label={"applications.create.label.entryChannel"}
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            clearErrors={() => clearErrors(getPath("entryChannelId"))}
                            forwardedRef={ref}
                            value={value}
                            errors={formState.errors}
                            options={currentEntryChannelOptions || []}
                            disabledTooltip={{
                                tooltipContent: t("applications.create.label.entryChannelDisabledTooltip"),
                                placement: "top",
                            }}
                        />
                    )}
                />
            </div>
        </>
    );
}

export {
    ApplicationInformationSubForm,
    ApplicationInformationSubFormData
};
