import { GetAllRegisteredClients_AllRegisteredClientsDocument, GetAllRegisteredClients_AllRegisteredClientsQuery, UpdateEventSessionGroupExaminerAssignmentsDocument } from '@entities';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';
import { ValueItem as MultiValueTypeAheadItem } from '@shared/unsorted/MultiValueTypeAhead/value';
import { Entity } from '@typedefs/graphql';
import { SelectionOption } from '@typedefs/selectOption';
import { isEqual } from 'lodash';
import { FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';
import { SessionGroupManipulationMode } from '../hook';
import * as value from './value';

interface EventSessionGroupManipulateExaminersModalHookType {
    onCloseModal: VoidFunction;
    isLoading: boolean;
    isSubmitting: boolean;
    clientOptions: SelectionOption[];
    form: UseFormReturn<value.Encoder.EncoderType>;
    initClients: MultiValueTypeAheadItem.ValueItemType[];
    filterSelectedClients: (currentClientOptions: MultiValueTypeAheadItem.ValueItemType[]) => void;
    filterMatchingClients: (currentClientOptions: MultiValueTypeAheadItem.ValueItemType[], input?: string) => void;
    onSubmit: FormEventHandler<HTMLFormElement>;
    canSave: boolean;
}

const useEventSessionGroupManipulateExaminersModal = (
    manipulationMode: SessionGroupManipulationMode,
    closeModal: VoidFunction,
): EventSessionGroupManipulateExaminersModalHookType => {

    const { error: toastError, success: toastSuccess } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectableClients, setSelectableClients]
        = useState<Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[]>([]);

    const allClients = useRef<Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[]>([]);

    const [clientsResponse] = useQuery({ query: GetAllRegisteredClients_AllRegisteredClientsDocument });

    const [updateGroupExaminersResponse, updateGroupExaminers] = useMutation(UpdateEventSessionGroupExaminerAssignmentsDocument);

    const examinerAssignments = useMemo(() =>
        manipulationMode.name === 'manipulateEventSessionGroupExaminers'
            ? manipulationMode.payload.examinerAssignments
            : [],
        [manipulationMode]);

    const initClients: MultiValueTypeAheadItem.ValueItemType[] = useMemo(() => examinerAssignments.map(({ client }) => ({
        value: client.id,
        label: client.fullName,
    })), [examinerAssignments])

    const defaultValues = value.Encoder.defaultValues(initClients)

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const { reset, handleSubmit, control } = form;

    const currentSelectedClientIds = useWatch({
        control,
        name: 'clientIds',
    });

    // formState.isDirty is not working as expected for the MultiValueTypeAhead component
    // As a workaround, we are using the isEqual function to compare the current selected client ids with the default values
    const canSave = !isEqual(
        [...currentSelectedClientIds].map(({ value }) => value).sort(),
        [...defaultValues.clientIds].map(({ value }) => value).sort(),
    );

    const getClientOptions = (clients: Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[]) =>
        clients.reduce((options: SelectionOption[], client) => {
            if (client.__typename === 'RegisteredClient') {
                return options.concat([{
                    key: client.id,
                    label: client.fullName,
                    value: client.id
                }]);
            }

            return options;
        }, []);

    const clientOptions = getClientOptions(selectableClients);

    const getRemainingClients = (
        currentClientOptions: MultiValueTypeAheadItem.ValueItemType[],
        allClients: Entity<GetAllRegisteredClients_AllRegisteredClientsQuery, 'clients.edges'>[],
    ) => {
        const currentClientNames = currentClientOptions.map(({ label }) => label);

        const isFound = (fullName: string) => currentClientNames.includes(fullName);

        const remainingClients = allClients.filter(client => client.__typename === 'RegisteredClient' ? !isFound(client.fullName) : true);

        return remainingClients;
    };

    const filterSelectedClients = (currentClientOptions: MultiValueTypeAheadItem.ValueItemType[]) => {
        const remainingClients = getRemainingClients(currentClientOptions, allClients.current);

        setSelectableClients(_ => remainingClients);
    };

    const getComparableString = (value: string) => value.trim().split(" ").join("").toLowerCase()

    const filterMatchingClients = (currentClientOptions: MultiValueTypeAheadItem.ValueItemType[], input?: string) => {
        if (input !== undefined) {
            const cleanedExaminerName = getComparableString(input);
            const remainingClients = getRemainingClients(currentClientOptions, allClients.current);

            const filteredSelectableClients = remainingClients.filter(client => {
                if (client.__typename === 'RegisteredClient') {
                    const cleanedFullName = getComparableString(client.fullName);

                    return cleanedFullName.includes(cleanedExaminerName);
                }

                return false;
            });

            setSelectableClients(filteredSelectableClients);
        }
    };

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            if (manipulationMode.name === 'manipulateEventSessionGroupExaminers') {
                updateGroupExaminers({
                    groupId: manipulationMode.payload.groupId,
                    clientIds: validValues.clientIds,
                }, getQueryContext('EventSessionGroup'));
            }
        } catch (error) {
            toastError('global.error');
        }
    };

    const close = () => {
        closeModal();
        reset();
    };

    useEffect(() => {
        handleResponse(clientsResponse, {
            onFetching: () => setIsLoading(true),
            onData: (data) => {
                setIsLoading(false);

                allClients.current = data.clients.edges
                filterSelectedClients(initClients)
            },
            onError: () => {
                setIsLoading(false);

                toastError('global.error');
                setSelectableClients([]);
            }
        });
    }, [clientsResponse]);

    useEffect(() => {
        handleResponse(updateGroupExaminersResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false);

                if (data.updateEventSessionGroupExaminerAssignments) {
                    toastSuccess('event.eventSessionGroupManipulateExaminersModal.success');
                    close();
                } else {
                    toastError('event.eventSessionGroupManipulateExaminersModal.error');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('event.eventSessionGroupManipulateExaminersModal.error');
            }
        })
    }, [updateGroupExaminersResponse]);

    useEffect(() => {
        reset(defaultValues);
    }, [examinerAssignments])

    return {
        onCloseModal: close,
        isLoading,
        isSubmitting,
        clientOptions,
        form,
        initClients,
        filterSelectedClients,
        filterMatchingClients,
        onSubmit: handleSubmit(onSubmit),
        canSave,
    };
};

export {
    useEventSessionGroupManipulateExaminersModal
};
