import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Mode = 'show' | 'hide';

type CsvExportActions = 'ALL' | 'ACTIVE' | 'ARCHIVE';

type CsvExportActionsOptions = {
    label: string;
    action: CsvExportActions;
};

interface CsvExportActionsHookType {
    csvExportActionsRef: RefObject<HTMLDivElement>;
    csvActionsDisplayMode: Mode;
    toggleCsvActionsList: VoidFunction;
    onClickCsvExportAction: (action: CsvExportActions) => void;
    csvExportActions: CsvExportActionsOptions[];
}

const useCsvExportActions = (onCsvExportAction: (action: CsvExportActions) => void): CsvExportActionsHookType => {
    const { t } = useTranslation();

    const [csvActionsDisplayMode, setCsvActionsDisplayMode] = useState<Mode>('hide');

    const csvExportActionsRef = useRef<HTMLDivElement>(null);

    useClickOutside([csvExportActionsRef], () => setCsvActionsDisplayMode('hide'));

    const toggleCsvActionsList = () => csvActionsDisplayMode === 'hide' 
        ? setCsvActionsDisplayMode('show') 
        : setCsvActionsDisplayMode('hide');

    const onClickCsvExportAction = (action: CsvExportActions) => {
        onCsvExportAction(action);

        setCsvActionsDisplayMode('hide');
    };

    const csvExportActions: CsvExportActionsOptions[] = [
        {
            label: t("applications.exportCandidates.actions.all"),
            action: 'ALL'
        },
        {
            label: t("applications.exportCandidates.actions.active"),
            action: 'ACTIVE'
        },
        {
            label: t("applications.exportCandidates.actions.archive"),
            action: 'ARCHIVE'
        },
    ];

    return {
        csvExportActionsRef,
        csvActionsDisplayMode,
        toggleCsvActionsList,
        onClickCsvExportAction,
        csvExportActions,
    };
};

export {
    useCsvExportActions,
    CsvExportActions,
    Mode,
};