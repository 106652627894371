import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { isDefined } from '@helpers/core/typeGuards';

import { Cn } from '@helpers/unsorted/classNames';
import { Application } from './Application/Application';
import { useRelatedApplications } from './hook';
import { RecruitmentProcess_OneByIdQuery } from '@entities';

const styles = {
    leftSection: Cn.c('flex flex-col space-y-3 font-paragraph-small-regular mt-4 mr-4 min-w-68'),
    subSectionTitle: (isFirst = false) =>
        Cn.join([
            Cn.c('font-paragraph-small-bold text-default'),
            Cn.ifFalse(isFirst, 'pt-4'),
        ]),
    emptyList: Cn.c('p-4 text-subdued'),
    shadow: Cn.c('shadow-base'),
};

interface Props {
    activeApplication: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
}

const RelatedApplications: FunctionComponent<Props> = ({ activeApplication }) => {
    const { t } = useTranslation();

    const { underSelectionApplication, archivedApplications } = useRelatedApplications(activeApplication);

    return (
        <div className={styles.leftSection}>
            <div className={styles.subSectionTitle(true)}>
                {t('applications.subMenu.activeApplication')}
            </div>
            {isDefined(underSelectionApplication) ?
                <div className={styles.shadow}>
                    <Application
                        isSelected={underSelectionApplication.id === activeApplication.id}
                        isActive
                        title={underSelectionApplication.title}
                        currentStepName={underSelectionApplication.currentStepName}
                        currentStepStage={underSelectionApplication.currentStepStage}
                        id={underSelectionApplication.id}
                    />
                </div>
                :
                <div className={styles.emptyList}>
                    {t('applications.subMenu.noActiveApplication')}
                </div>

            }
            <div className={styles.subSectionTitle()}>
                {t('applications.subMenu.archived')}
            </div>
            <div className={styles.shadow}>
                {archivedApplications.length === 0 ?
                    <div className={styles.emptyList}>
                        {t('applications.subMenu.noArchivedApplications')}
                    </div>
                    : archivedApplications.map(archive =>
                        <Application
                            key={archive.id}
                            id={archive.id}
                            isSelected={archive.id === activeApplication.id}
                            title={archive.title}
                            currentStepName={archive.currentStepName}
                            currentStepStage={archive.currentStepStage}
                        />
                    )
                }
            </div>
        </div >
    );
};

export {
    RelatedApplications,
};
