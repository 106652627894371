import { z } from "zod";

const environmentSchema = z.enum(["dev", "stage", "prod"])

const schema = z
  .object({
    OAUTH_CLIENT_ID: z.string().min(1),
    OAUTH_AUTHORIZATION_URI: z.string().min(1),
    OAUTH_ACCESS_TOKEN_URI: z.string().min(1),
    OAUTH_LOGOUT_URI: z.string().min(1),
    OAUTH_REDIRECT_URI: z.string().min(1),
    OAUTH_REFRESH_TOKENS_URI: z.string().min(1),
    OATH_DEV_LOGIN_URI: z.string().min(1),
    TOKEN_EXPIRES_AT_COOKIE: z.string().min(1),
    TOKEN_REFRESH_TIMEOUT_BUFFER: z.string().min(1),
    GRAPHQL_ENDPOINT: z.string().min(1),
    API_DOMAIN: z.string().min(1),
    ENVIRONMENT: environmentSchema,
    SENTRY_DSN: z.string().min(1),
  })
  .transform((config) => ({
    oauthClientId: config.OAUTH_CLIENT_ID,
    oauthAuthorizationUri: config.OAUTH_AUTHORIZATION_URI,
    oauthAccessTokenUri: config.OAUTH_ACCESS_TOKEN_URI,
    oauthAccessLogoutUri: config.OAUTH_LOGOUT_URI,
    oauthRedirectUri: config.OAUTH_REDIRECT_URI,
    oauthRefreshTokensUri: config.OAUTH_REFRESH_TOKENS_URI,
    oauthDevLoginUri: config.OATH_DEV_LOGIN_URI,
    tokenExpiresAtCookie: config.TOKEN_EXPIRES_AT_COOKIE,
    tokenRefreshTimeoutBuffer: parseInt(config.TOKEN_REFRESH_TIMEOUT_BUFFER),
    graphqlEndpoint: config.GRAPHQL_ENDPOINT,
    apiDomain: config.API_DOMAIN,
    environment: config.ENVIRONMENT,
    sentryDSN: config.SENTRY_DSN,
  }));

type Config = z.infer<typeof schema>;

const config: Config = schema.parse({
  OAUTH_CLIENT_ID: process.env.OAUTH_CLIENT_ID,
  OAUTH_AUTHORIZATION_URI: process.env.OAUTH_AUTHORIZATION_URI,
  OAUTH_ACCESS_TOKEN_URI: process.env.OAUTH_ACCESS_TOKEN_URI,
  OAUTH_LOGOUT_URI: process.env.OAUTH_LOGOUT_URI,
  OAUTH_REDIRECT_URI: process.env.OAUTH_REDIRECT_URI,
  OAUTH_REFRESH_TOKENS_URI: process.env.OAUTH_REFRESH_TOKENS_URI,
  OATH_DEV_LOGIN_URI: process.env.OATH_DEV_LOGIN_URI,
  TOKEN_EXPIRES_AT_COOKIE: process.env.TOKEN_EXPIRES_AT_COOKIE,
  TOKEN_REFRESH_TIMEOUT_BUFFER: process.env.TOKEN_REFRESH_TIMEOUT_BUFFER,
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT,
  API_DOMAIN: process.env.API_DOMAIN,
  ENVIRONMENT: process.env.ENVIRONMENT,
  SENTRY_DSN: process.env.SENTRY_DSN,
});
//TODO: handle error when the environment variables missing

export default config;
