import { JobPosition_OneByIdWithStepEventsQuery } from "@entities"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { useInitialState } from "./init"
import { useJobPositionDetailEditMode } from "./hook"
import { JobPositionDetailEditModeLoadingState } from "./JobPositionDetailEditModeLoadingState/JobPositionDetailEditModeLoadingState"
import { JobPositionDetailEditModeSuccessState } from "./JobPositionDetailEditModeSuccessState/JobPositionDetailEditModeSuccessState"

interface Props {
    jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>
    switchToViewMode: VoidFunction
}

const JobPositionDetailEditMode: FunctionComponent<Props> = ({
    jobPosition,
    switchToViewMode,
}) => {
    const {
        initialState
    } = useInitialState()

    useJobPositionDetailEditMode(initialState)

    return initialState.isLoading
        ? <JobPositionDetailEditModeLoadingState
            jobTitle={jobPosition.title}
            switchToViewMode={switchToViewMode}
        />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <JobPositionDetailEditModeSuccessState
                jobPosition={jobPosition}
                recruitmentStepTypes={initialState.result.data.recruitmentStepTypes}
                switchToViewMode={switchToViewMode}
            />
}

export {
    JobPositionDetailEditMode
}