import { isDefined } from "@helpers/core/typeGuards";
import { useErrorMessage } from "@helpers/hooks/unsorted/errorMessageHook";
import { Cn } from "@helpers/unsorted/classNames";
import { SelectionOption } from "@typedefs/selectOption";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { I18nKey, useTranslation } from "react-i18next";

import { FormProps, ForwardedRefProps } from "@typedefs/props";
import { Chip } from "../Chip/Chip";
import { Input, Size } from "../Input/Input";
import { Label } from "../Label/Label";
import { TooltipPlacement } from "../ToolTipBox/ToolTipBox";
import { useMultiValueTypeAhead } from "./hook";
import { Mode } from "./reducer";
import { ValueItem } from './value';

type ListOrientation = 'upwards' | 'downwards'

const styles = {
  container: (className?: string) =>
    Cn.join([Cn.c("w-full relative space-y-1"), Cn.getSome(className)]),
  list: (listOrientation: ListOrientation) =>
    Cn.join([
      Cn.c(
        "w-full absolute z-[100] bg-surface-default rounded-lg overflow-auto max-h-96 shadow-md flex flex-row flex-wrap gap-2 gap-x-2 px-6 py-4",
      ),
      listOrientation === 'upwards' ? Cn.c("bottom-[4.4rem]") : Cn.c("")
    ]),
  label: (labelClassName?: string) =>
    Cn.join([Cn.c("font-paragraph-small-medium text-default"), Cn.getSome(labelClassName)]),
  wrapper: (
    state: Mode,
    error: boolean,
    selectedItemsLength: number,
    isDisabled: boolean,
    hasWarning: boolean,
    className?: string) =>
    Cn.join([
      Cn.c("rounded-lg flex items-center flex-wrap bg-surface-default space-x-2"),
      state.name === 'show' ? Cn.c("border-emphasized") : Cn.c("border-primary-default"),
      Cn.ifTrue(error, "border-critical-default"),
      Cn.ifTrue(selectedItemsLength > 0, "pl-6"),
      isDisabled ? Cn.c("bg-surface-disabled") : Cn.c("bg-surface-default border"),
      Cn.ifTrue(hasWarning, Cn.c("border-warning-default")),
      Cn.getSome(className),
    ]),
  inputWrapper: Cn.c("w-fit grow"),
  input: (selectedItemsLength: number) => Cn.join([
    Cn.c("border-none"),
    Cn.ifTrue(selectedItemsLength > 0, "pl-0"),
  ]),
  error: Cn.c("text-critical-default font-paragraph-small-regular"),
  option: Cn.c("cursor-pointer"),
  selectedOption: (isDisabled: boolean) => Cn.ifTrue(isDisabled, Cn.c('border border-default')),
  warning: Cn.c('text-warning-default font-paragraph-xsmall-regular'),
}

interface Props<FieldsType extends FieldValues> extends
  FormProps<FieldsType>,
  ForwardedRefProps<HTMLInputElement> {
  label?: I18nKey;
  tooltip?: I18nKey;
  tooltipPlacement?: TooltipPlacement;
  placeholder?: I18nKey;
  listOrientation?: ListOrientation;
  required?: boolean;
  options?: SelectionOption[];
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  onInputChange?: (currentValues: ValueItem.ValueItemType[], value?: string) => void;
  onValueChange?: (newValue: ValueItem.ValueItemType[]) => void;
  errorLabel?: I18nKey;
  form: UseFormReturn<any, any>;//TODO: rebind correct type
  inputValue?: ValueItem.ValueItemType[];
  disabled?: boolean;
  enableInsert?: boolean;
  size?: Size;
  warnValidator?: () => I18nKey | undefined;
}

// @ocaml.doc("Input Component
//
// - `name`: name of the input
// - `label`: optional - the label to display for the input
// - `placeholder`: optional - placeholder for the input
// - `listOrientation`: optional - direction of the selection list - downwards by default
// - `required`: optional - show the * for required input - false by default
// - `options`: array of object that contains the value, label and key of the options
// - `className`: optional - class to apply to the container
// - `inputClassName`: optional - class to apply to the input
// - `labelClassName`: optional - class to apply to the label
// - `onInputChange`: optional - trigger when user is typing something
// - `onValueChange`: optional - trigger when user select or remove an option
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `form`: the current form being used
// - `inputValue`: optional - default value
// - `disabled`: optional - is the input disabled or not - false by default
// - `enableInsert`: optional - when set to true, allow user to add new item by pressing enter, default to true
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// - `size`: optional - size of the input
// ")

const MultiValueTypeAhead = <FieldsType extends FieldValues>({
  name,
  label,
  placeholder,
  listOrientation,
  required,
  options,
  className,
  inputClassName,
  labelClassName,
  onInputChange,
  onValueChange,
  errors,
  clearErrors,
  errorLabel,
  form,
  inputValue,
  disabled = false,
  enableInsert,
  forwardedRef,
  tooltip,
  tooltipPlacement,
  size = 'x-large',
  warnValidator,
}: Props<FieldsType>) => {
  const { t } = useTranslation();

  const error = useErrorMessage(
    name,
    errors,
    isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name,
  );

  const {
    typeAheadRef,
    state: { items: selectedItems, mode },
    focus,
    selectItem,
    removeItem,
    onKeyDown,
    onChange,
    warnMessage,
  } = useMultiValueTypeAhead({
    form,
    name,
    onInputChange,
    onValueChange,
    inputValue,
    enableInsert,
    warnValidator,
  })

  return (
    <div className={styles.container(className)} ref={typeAheadRef}>
      {isDefined(label) && <Label label={label} labelClassName={labelClassName} required={required} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
      <div
        className={styles.wrapper(mode, isDefined(error), selectedItems.length, disabled, isDefined(warnMessage), inputClassName)}
        onClick={focus}>
        {selectedItems.map(selectedItem =>
          <Chip
            key={selectedItem.label}
            variant={disabled ? "filled" : "outline"}
            label={selectedItem.label}
            onRemove={() => removeItem(selectedItem.label)}
            isDisabled={disabled}
            className={styles.selectedOption(disabled)}
          />
        )}
        <Input
          name={name}
          className={styles.inputWrapper}
          inputClassName={styles.input(selectedItems.length)}
          placeholder={selectedItems.length === 0 ? placeholder : undefined}
          value={mode.name === 'show' ? "" : mode.payload.value}
          onKeyDown={onKeyDown}
          onChange={onChange}
          clearErrors={clearErrors}
          disabled={disabled}
          autocomplete='off'
          forwardedRef={forwardedRef}
          size={size}
        />
      </div>
      {isDefined(error) && <div className={styles.error}>{error}</div>}
      {mode.name === 'edit' && !disabled && listOrientation && options &&
        <div className={styles.list(listOrientation)} hidden={options.length === 0}>
          {options.map(({ value, label, key }) =>
            <span
              key={key}
              onClick={() => selectItem({ value, label })}
              className={styles.option}
            >
              <Chip
                variant="outline"
                label={label}
              />
            </span>
          )}
        </div>
      }
      {isDefined(warnMessage) && <div className={styles.warning}>{warnMessage}</div>}
    </div>
  );
};

export {
  MultiValueTypeAhead
};
