import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventSettingsActions } from './hook';
import { Entity } from '@typedefs/graphql';
import { Event_OneByIdQuery } from '@entities';

const styles = {
    list: Cn.c('absolute z-10 bg-surface-default border border-on-primary rounded-lg overflow-auto shadow-md top-[3.75rem] w-[9.25rem]'),
    listRow: Cn.c('block cursor-pointer font-paragraph-small-regular px-4 py-2 hover:bg-surface-hovered-default'),
    delete: (isDeleteOption: boolean) => Cn.ifTrue(isDeleteOption, Cn.c('text-critical-default')),
};

interface Props {
    event: Entity<Event_OneByIdQuery, 'event'>;
    openDeleteConfirmationModal: VoidFunction;
}

const EventSettingsActions: FunctionComponent<Props> = ({
    event,
    openDeleteConfirmationModal,
}) => {
    const { t } = useTranslation();

    const {
        eventSettingsDisplayMode,
        eventSettingsActionsRef,
        toggleEventSettingsActionList,
        eventSettingsActions,
        onOptionClick,
    } = useEventSettingsActions(event, openDeleteConfirmationModal);

    return (
        <div ref={eventSettingsActionsRef}>
            <Button
                size="md"
                variant="secondary"
                iconName="caret"
                isTrailingIcon
                onClick={toggleEventSettingsActionList}
            >
                {t("event.eventDetail.eventSettings")}
            </Button>
            {/* TODO: disable action and add tooltip to explain the disablement */}
            {eventSettingsDisplayMode === "show" &&
                <div className={styles.list}>
                    {eventSettingsActions.map((action, index) =>
                        <div
                            className={Cn.join([styles.listRow, styles.delete(action.value === "delete")])}
                            key={index}
                            onClick={() => onOptionClick(action.value)}
                        >
                            {action.label}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export {
    EventSettingsActions
};
