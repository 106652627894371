import { FunctionComponent } from "react"
import { useInitialState } from "./init"
import { useSessionGroupSelectionStep } from "./hook"
import { SessionGroupSelectionStepLoadingState } from "./SessionGroupSelectionStepLoadingState/SessionGroupSelectionStepLoadingState"
import { useBulkEventSessionAssignmentContext } from "../../BulkEventSessionAssignmentContext"
import { SessionGroupSelectionStepSuccessState } from "./SessionGroupSelectionStepSuccessState/SessionGroupSelectionStepSuccessState"

const SessionGroupSelectionStep: FunctionComponent = () => {
    const [{ settings: { sessionId } }] = useBulkEventSessionAssignmentContext()

    const {
        initialState
    } = useInitialState(sessionId || '')

    const {
        closeModal,
        goToSessionSelection
    } = useSessionGroupSelectionStep(initialState)

    return initialState.isLoading
        ? <SessionGroupSelectionStepLoadingState
            closeModal={closeModal}
            goToSessionSelection={goToSessionSelection}
        />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <SessionGroupSelectionStepSuccessState
                closeModal={closeModal}
                goToSessionSelection={goToSessionSelection}
                eventSession={initialState.result.data.eventSession}
            />
}

export {
    SessionGroupSelectionStep
}