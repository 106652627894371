import { ValidateBulkEvaluationCsvFileMutation } from "@entities"
import { isUndefined } from "@helpers/core/typeGuards"
import { Cn } from "@helpers/unsorted/classNames"
import { UploadDragAndDrop } from "@pages/application/CSVImportPage/CSVImportForm/UploadDragAndDrop/UploadDragAndDrop"
import { Button } from "@shared/unsorted/Button/Button"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useCSVBulkEvaluationErrorCorrectionStep } from "./hook"

const styles = {
    container: Cn.c("px-6 flex flex-col flex-1 overflow-auto"),
    section: Cn.c('flex flex-col pt-6'),
    errorContainer: Cn.c("flex p-4 bg-surface-critical-subdued rounded-xl space-x-2"),
    errorIcon: Cn.c("w-6 h-6 text-icons-critical-default"),
    errorDescription: Cn.c("text-critical-default font-paragraph-base-medium"),
    errorButton: Cn.c("mt-6"),
    ul: Cn.c("list-disc mt-4 ml-4"),
    errorDetail: Cn.c("text-critical-default font-paragraph-small-regular"),
    errorHighlight: Cn.c("text-critical-default font-paragraph-small-semi-bold"),
    successContainer: Cn.c("flex p-4 bg-surface-success-subdued rounded-xl justify-between"),
    successIcon: Cn.c("w-6 h-6 text-icons-success-emphasized"),
    successDescription: Cn.c("text-success-emphasized font-paragraph-base-medium flex items-center space-x-2"),
    header: Cn.c('font-paragraph-base-semi-bold text-emphasized'),
    uploadFileRequirement: Cn.c('font-paragraph-small-regular text-subdued mt-2'),
    footer: Cn.c("border-t border-default p-6 flex justify-between")
}

interface Props {
    errorSummary?: Entity<ValidateBulkEvaluationCsvFileMutation, 'validateBulkEvaluationCSVFile.errorSummary'>
    errorCSVUrl?: string
}

const CSVBulkEvaluationErrorCorrectionStep: FunctionComponent<Props> = ({
    errorCSVUrl,
    errorSummary,
}) => {
    const { t } = useTranslation()

    const {
        isMessageShown,
        setIsMessageShown,
        isSubmitting,
        csvFile,
        onUpload,
        removeFile,
        backToSetup,
        onSubmit,
        downloadErrorCSV
    } = useCSVBulkEvaluationErrorCorrectionStep(errorCSVUrl)

    const invalidValueCount = errorSummary
        ? errorSummary.INVALID.count + errorSummary.DUPLICATE.count
        : 0

    return (
        <>
            <div className={styles.container}>
                {isMessageShown && <div className={styles.section}>
                    {errorSummary
                        ? <div className={styles.errorContainer}>
                            <Icon name="errorWarning" className={styles.errorIcon} />
                            <div className={styles.errorDescription}>
                                <p>{t('applications.bulkEvaluation.errorCorrectionStep.errorDescription')}</p>
                                <ul className={styles.ul}>
                                    {
                                        errorSummary.REQUIRED.count > 0 &&
                                        <li className={styles.errorDetail}><Trans
                                            i18nKey={"applications.bulkEvaluation.errorCorrectionStep.errors.missingValue"}
                                            count={errorSummary.REQUIRED.count}
                                            components={{ highlight: <span className={styles.errorHighlight}></span> }}
                                        /></li>
                                    }
                                    {
                                        errorSummary.INVALID_FORMAT.count > 0 &&
                                        <li className={styles.errorDetail}><Trans
                                            i18nKey={"applications.bulkEvaluation.errorCorrectionStep.errors.invalidFormat"}
                                            count={errorSummary.INVALID_FORMAT.count}
                                            components={{ highlight: <span className={styles.errorHighlight}></span> }}
                                        /></li>
                                    }
                                    {
                                        invalidValueCount > 0 &&
                                        <li className={styles.errorDetail}><Trans
                                            i18nKey={"applications.bulkEvaluation.errorCorrectionStep.errors.invalidValue"}
                                            count={invalidValueCount}
                                            components={{ highlight: <span className={styles.errorHighlight}></span> }}
                                        /></li>
                                    }
                                </ul>
                                <Button
                                    size="md"
                                    isTrailingIcon={false}
                                    iconName="downloadFile"
                                    variant='destructiveFilled'
                                    className={styles.errorButton}
                                    onClick={downloadErrorCSV}
                                >
                                    {t('applications.bulkEvaluation.errorCorrectionStep.downloadErrorFile')}
                                </Button>
                            </div>
                        </div>
                        : <div className={styles.successContainer}>
                            <div className={styles.successDescription}>
                                <Icon name="info" className={styles.successIcon} />
                                <p>{t('applications.bulkEvaluation.errorCorrectionStep.successDescription')}</p>
                            </div>
                            <Icon name="close" className={styles.successIcon} onClick={() => setIsMessageShown(false)} />
                        </div>
                    }
                </div>}
                <div className={styles.section}>
                    <p className={styles.header}>{t("applications.bulkEvaluation.errorCorrectionStep.sectionHeader.reuploadFile")}</p>
                    <p className={styles.uploadFileRequirement}>{t("applications.bulkEvaluation.errorCorrectionStep.reuploadFileRequirement")}</p>
                    <UploadDragAndDrop
                        onUpload={onUpload}
                        onRemove={removeFile}
                        csvFile={csvFile}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <Button
                    variant="secondary"
                    size="md"
                    onClick={backToSetup}
                    iconName="arrowLeftLine">
                    {t("applications.bulkEvaluation.previewStep.backToSetup")}
                </Button>
                <Button
                    variant="primaryFilled"
                    size="md"
                    iconName="arrowRightLine"
                    isTrailingIcon
                    isLoading={isSubmitting}
                    isDisabled={isUndefined(csvFile)}
                    onClick={onSubmit}>
                    {t("applications.bulkEvaluation.moveToPreview")}
                </Button>
            </div>
        </>
    )
}

export {
    CSVBulkEvaluationErrorCorrectionStep
}