import { Cn } from "@helpers/unsorted/classNames";
import { validations } from "@helpers/unsorted/validation";
import { Button } from "@shared/unsorted/Button/Button";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Input } from "@shared/unsorted/Input/Input";
import { Label } from "@shared/unsorted/Label/Label";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { RadioInputGroup } from "@shared/unsorted/RadioInput/RadioInputGroup/RadioInputGroup";
import { Select } from "@shared/unsorted/Select/Select";
import { TextArea } from "@shared/unsorted/TextArea/TextArea";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEventSettingsModal } from "./hook";

const styles = {
    container: Cn.c("h-[95%]"),
    form: Cn.c("h-full flex flex-col"),
    header: Cn.c("p-6 flex justify-between items-center border-b border-default"),
    title: Cn.c("text-emphasized font-h4-bold"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("overflow-auto p-6 flex flex-col space-y-6 flex-1"),
    row: Cn.c("flex space-x-4 justify-between"),
    descriptionContainer: Cn.c("w-full"),
    description: Cn.c("font-paragraph-xsmall-regular text-subdued mt-2 whitespace-pre-line"),
    separateLabel: Cn.c("mb-1"),
    connectText: Cn.c("flex items-center text-emphasized font-paragraph-small-medium"),
    flexBox: Cn.c("flex-1"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3")
}

const EventSettingsModal: FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        form: { control, clearErrors, formState: { errors, isDirty } },
        onSubmit,
        isSubmitting,
        close,
        formatOptions,
        daysBeforeOptions,
        cutoffTimeOptions,
        setExpirationLinkOptions,
        expirationLinkOptions,
        shouldSetExpirationLink,
        mode,
    } = useEventSettingsModal()

    return (
        <Modal
            isOpen={mode.name !== 'hide'}
            widthClass="w-[50rem]"
            close={close}
            className={styles.container}
        >
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.header} data-section="header">
                    <p className={styles.title}>{t('event.setting.title')}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={close} />
                </div>
                <div className={styles.body} data-section="body">
                    <div className={styles.row}>
                        <Controller
                            name='name'
                            control={control}
                            rules={{
                                required: { value: true, message: "requiredEventName" },
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label='event.setting.eventName'
                                    placeholder="event.setting.placeholder.eventName"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    required
                                />}
                        />
                        <Controller
                            name='displayName'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label='event.setting.displayName'
                                    placeholder="event.setting.placeholder.displayName"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    required
                                />}
                        />
                    </div>
                    <div className={styles.row}>
                        <Controller
                            name='duration'
                            control={control}
                            rules={{ required: true, pattern: validations.number }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label='event.setting.duration'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    required
                                    type="number"
                                />}
                        />
                        <Controller
                            name='candidateCapacity'
                            control={control}
                            rules={{ required: true, pattern: validations.number }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Input
                                    name={name}
                                    label='event.setting.candidateCapacity'
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    required
                                    type="number"
                                />}
                        />
                    </div>
                    <div className={styles.row}>
                        <div>
                            <Controller
                                name='isOnline'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                    <RadioInputGroup
                                        name={name}
                                        label='event.setting.eventFormat'
                                        options={formatOptions}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        value={value}
                                        required
                                    />
                                }
                            />
                            <div className={styles.description}>
                                {t('event.setting.formatNote')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.descriptionContainer}>
                            <Controller
                                name='description'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                    <TextArea
                                        name={name}
                                        size='md'
                                        orientation='vertical'
                                        label='event.setting.description'
                                        placeholder='event.setting.placeholder.description'
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errors={errors}
                                        clearErrors={clearErrors}
                                        forwardedRef={ref}
                                        value={value}
                                        required
                                        caption='event.setting.descriptionNote'
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <Label
                            label='event.setting.deadline'
                            required
                            className={styles.separateLabel}
                        />
                        <div className={styles.row}>
                            <div className={styles.flexBox}>
                                <Controller
                                    name='daysPrior'
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "requiredDaysPrior" },
                                    }}
                                    render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                        <Select
                                            required
                                            name={name}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            forwardedRef={ref}
                                            value={value}
                                            options={daysBeforeOptions}
                                        />}
                                />
                            </div>
                            <p className={styles.connectText}>
                                {t('event.setting.deadlineDaysBefore')}
                            </p>
                            <div className={styles.flexBox}>
                                <Controller
                                    name='cutoffTime'
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "requiredCutoffTime" },
                                    }}
                                    render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                        <Select
                                            required
                                            name={name}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            forwardedRef={ref}
                                            value={value}
                                            options={cutoffTimeOptions}
                                        />}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Controller
                            name='shouldSetExpirationLink'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <RadioInputGroup
                                    name={name}
                                    label='event.setting.shouldSetExpirationLink'
                                    options={setExpirationLinkOptions}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    required
                                />
                            }
                        />
                        <div className={styles.description}>
                            {t('event.setting.shouldSetExpirationLinkNote')}
                        </div>
                    </div>
                    <div>
                        <Controller
                            name='daysUntilLinkInvalid'
                            control={control}
                            rules={{
                                required: { value: shouldSetExpirationLink, message: "requiredExpirationLink" },
                            }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    label="event.setting.expirationLinkLabel"
                                    required
                                    disabled={!shouldSetExpirationLink}
                                    name={name}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    value={value}
                                    options={expirationLinkOptions}
                                />}
                        />
                        <span className={styles.description}>
                            {t('event.setting.expirationLinkNote')}
                        </span>
                    </div>
                </div>
                <div className={styles.footer} data-section="footer">
                    <Button
                        variant="secondary"
                        onClick={close}
                        size="md"
                        isFull={true}
                        isDisabled={isSubmitting}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        isFull={true}
                        type="submit"
                        isDisabled={isSubmitting || !isDirty}
                        isLoading={isSubmitting}
                    >
                        {t("global.save")}
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export {
    EventSettingsModal
};
