import * as candidateApplicationInformation from '@shared/application/ApplicationInformationSubForm/value';

namespace Encoder {
    export type Type = ReturnType<typeof defaultValues>;
    interface DefaultValuesInput {
        jobPositionId?: string;
        recruitmentStepId?: string;
        acquisitionCategoryId?: string;
        acquisitionChannelId?: string;
        entryCategoryId?: string;
        entryChannelId?: string;
    }

    export const defaultValues = (input?: DefaultValuesInput): candidateApplicationInformation.Encoder.Type =>
        candidateApplicationInformation.Encoder.defaultValue({
            jobPositionId: input?.jobPositionId || '',
            recruitmentStepId: input?.recruitmentStepId || '',
            acquisitionCategoryId: input?.acquisitionCategoryId || '',
            acquisitionChannelId: input?.acquisitionChannelId || '',
            entryCategoryId: input?.entryCategoryId || '',
            entryChannelId: input?.entryChannelId || '',
        });
}

namespace Decoder {
    export const schema = candidateApplicationInformation.Decoder.schema
        .transform((values) => ({
            ...values,
            acquisitionCategoryId: values.acquisitionCategoryId || undefined,
            acquisitionChannelId: values.acquisitionChannelId || undefined,
            entryCategoryId: values.entryCategoryId || undefined,
            entryChannelId: values.entryChannelId || undefined,
        }));
}

export {
    Decoder,
    Encoder
};

