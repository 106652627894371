import { useMutation } from "urql";
import { isDefined } from "@helpers/core/typeGuards";
import { useCSVBulkEvaluationFormContext } from "../../../CSVBulkEvaluationFormContext";
import { ImportEvaluationsFromCsvUrlDocument } from "@entities";

const useCSVBulkEvaluationImportStepFailure = (
    goToInProgress: VoidFunction
) => {
    const [{ validCSVUrl }] = useCSVBulkEvaluationFormContext()

    const [_, importEvaluationCsv] = useMutation(ImportEvaluationsFromCsvUrlDocument);

    const openEmail = () => {
        window.location.href = `mailto:bluum@reccoo.com`
    }

    const retryImportingCSV = () => {
        if (isDefined(validCSVUrl)) {
            importEvaluationCsv({
                fileUrl: validCSVUrl,
            });

            goToInProgress()
        }
    };

    return {
        openEmail,
        retryImportingCSV
    }
}

export {
    useCSVBulkEvaluationImportStepFailure
}