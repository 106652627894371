import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { FunctionComponent, ReactNode, useRef, useState } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';

const baseMenuItem = Cn.c("p-4 font-paragraph-xsmall-regular border-default border-b whitespace-nowrap")

const styles = {
  container: Cn.c("relative"),
  menuIconWrapper: Cn.c("flex w-fit items-center cursor-pointer bg-surface-hovered-emphasized"),
  menuIcon: Cn.c("text-icons-emphasized w-6 h-6 p-1"),
  menuBody: (isOpened: boolean) =>
    Cn.join([
      Cn.c(
        "absolute w-fit mt-1 rounded-lg bg-surface-default shadow-lg overflow-hidden z-10",
      ),
      isOpened ? Cn.c("block") : Cn.c("hidden"),
    ]),
  editMenuItem: (isEnabled: boolean) =>
    Cn.join([
      baseMenuItem,
      isEnabled
        ? Cn.c("text-emphasized cursor-pointer hover:bg-surface-hovered-default ")
        : Cn.c("text-disabled"),
    ]),
  deleteMenuItem: (isEnabled: boolean) =>
    Cn.join([
      baseMenuItem,
      isEnabled
        ? Cn.c("text-critical-default cursor-pointer hover:bg-surface-hovered-default")
        : Cn.c("text-disabled"),
    ])
};

interface RenderProps {
  editMenuItemClassName: (isEnabled: boolean) => string;
  deleteMenuItemClassName: (isEnabled: boolean) => string;
  toggle: VoidFunction;
}

interface Props {
  render: (props: RenderProps) => ReactNode;
}

const ExtraMenu: FunctionComponent<Props> = ({
  render
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggle = () => {
    setIsOpened(!isOpened);
  }

  const extraMenuRef = useRef<HTMLDivElement>(null);

  useClickOutside([extraMenuRef], () => {
    setIsOpened(false);
  });

  return (
    <div ref={extraMenuRef} className={styles.container}>
      <div className={styles.menuIconWrapper} onClick={toggle}>
        <Icon name='more' className={styles.menuIcon} />
      </div >
      <ul className={styles.menuBody(isOpened)}>
        {render({
          editMenuItemClassName: styles.editMenuItem,
          deleteMenuItemClassName: styles.deleteMenuItem,
          toggle,
        })}
      </ul>
    </div>
  );
};

export {
  ExtraMenu
};

