import { useBackToPreviousListPage } from "@helpers/hooks/useBackToPreviousListPage";
import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { EmptyStateContainer } from "@shared/unsorted/EmptyStateContainer/EmptyStateContainer";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "@shared/functional_components/CloseIcon";
import { OpenJobListPanelButton } from "@shared/job_position/JobListPanel/OpenJobListPanelButton";

const styles = {
    container: Cn.c("flex flex-col bg-surface-default h-full"),
    header: Cn.c("px-6 py-4 flex items-center justify-between"),
    jobListContainer: Cn.c("flex content-center items-center space-x-4"),
    buttons: Cn.c("flex items-center justify-between space-x-5"),
    title: Cn.c("font-paragraph-base-medium text-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-icons-emphasized cursor-pointer"),
    body: Cn.c('flex flex-col flex-1 w-full items-center justify-center px-28 pb-8'),
}

const JobPositionDetailErrorState: FunctionComponent = () => {
    const { t } = useTranslation();

    const { backToPreviousListPage } = useBackToPreviousListPage()

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.jobListContainer}>
                    <OpenJobListPanelButton />
                    <p className={styles.title}>{
                        t('jobPositions.detail.empty.title')
                    }</p>
                </div>
                <div className={styles.buttons}>
                    <CloseIcon />
                </div>
            </div>
            <div className={styles.body}>
                <EmptyStateContainer
                    description="jobPositions.detail.empty.description"
                    subDescription="jobPositions.detail.empty.subDescription"
                    icon="emptyJobPosition"
                >
                    <Button
                        size="md"
                        variant="primaryFilled"
                        onClick={backToPreviousListPage}
                    >
                        {t("jobPositions.detail.empty.backToPreviousPage")}
                    </Button>
                </EmptyStateContainer>
            </div>
        </div>
    );
}

export {
    JobPositionDetailErrorState
};
