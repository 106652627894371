import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { FormEventHandler, useEffect } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import * as value from './value';
import { useDebounce } from '@helpers/hooks/unsorted/debounceHook';

interface JobSearchHookType {
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
}

const useSearch = (
    onSearchAction: (keyword: string) => void,
    searchOnChange: boolean,
    keyword?: string | null,
): JobSearchHookType => {
    const { error: toastError } = useToast();

    const defaultValues = value.Encoder.defaultValues(keyword);

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues: defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    useDebounce(form.watch('searchKeyword'), (value) => {
        if (searchOnChange) {
            onSearchAction(value);
        }
    }, 500);

    const { reset, handleSubmit } = form;

    const submitSearch: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            onSearchAction(validValues.searchKeyword);
        } catch (error) {
            toastError('global.error');
        }
    };

    useEffect(() => {
        reset(value.Encoder.defaultValues(keyword));
    }, [keyword]);

    return {
        form,
        onSubmit: handleSubmit(submitSearch),
    };
};

export {
    useSearch
};

