import { EventSessionsQuery } from "@entities";
import { useCurrentLanguage } from "@helpers/core/i18n";
import { Cn } from "@helpers/unsorted/classNames";
import { Chip } from "@shared/unsorted/Chip/Chip";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Entity } from "@typedefs/graphql";
import { format } from "date-fns";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    eventName: Cn.c("pt-1 font-paragraph-small-semi-bold text-emphasized"),
    eventRow: Cn.c("flex space-x-2"),
    detailContainer: Cn.c("flex items-center space-x-1 pt-3"),
    detailIcon: Cn.c("w-3.5 h-3.5 text-icons-subdued"),
    detailText: Cn.c("font-paragraph-xsmall-regular text-subdued")
};

interface Props {
    eventSession: Entity<EventSessionsQuery, 'eventSessions'>
}

const EventSessionDetail: FunctionComponent<Props> = ({
    eventSession
}) => {
    const { t } = useTranslation()
    const currentLanguage = useCurrentLanguage();

    return (
        <div>
            <Chip
                label={eventSession.event.recruitmentStepV2.jobPosition.title}
                variant='outline'
                isDisabled={true}
                size='sm'
            />
            <p className={styles.eventName}>{eventSession.event.name}</p>
            <div className={styles.eventRow}>
                <span className={styles.detailContainer}>
                    <Icon name="calendar" className={styles.detailIcon} />
                    <p className={styles.detailText}>{
                        currentLanguage === 'en'
                            ? format(new Date(eventSession.startAt), 'MMMM d, yyyy')
                            : format(new Date(eventSession.startAt), 'yyyy年M月d日')
                    }</p>
                </span>
                <span className={styles.detailContainer}>
                    <Icon name="time" className={styles.detailIcon} />
                    <p className={styles.detailText}>{
                        `${format(new Date(eventSession.startAt), 'H:mm')}-${format(new Date(eventSession.endAt), 'H:mm')}`
                    }</p>
                </span>
                <span className={styles.detailContainer}>
                    <Icon name="location" className={styles.detailIcon} />
                    <p className={styles.detailText}>{
                        eventSession.event.isOnline
                            ? t('event.format.online')
                            : t('event.format.offline')
                    }</p>
                </span>
            </div>
            <div className={styles.eventRow}>
                <span className={styles.detailContainer}>
                    <Icon name="candidates" className={styles.detailIcon} />
                    <p className={styles.detailText}>{
                        `${t('dashboard.upcomingEvents.participant', { count: eventSession.registeredCount })} ${t('event.eventSession.groupCount', { count: eventSession.groupCount })}`
                    }</p>
                </span>
            </div>
        </div>
    )
}

export {
    EventSessionDetail
}