import { ActivityLogEntry } from '@shared/unsorted/ActivityLogEntry/ActivityLogEntry';
import { useActivityLogList, useActivityLogs, UseActivityLogListInput, UseActivityLogsInput } from './hooks';

type ActivityLogListProps = UseActivityLogListInput & {
    listClassName?: string;
    listItemClassName?: string;
    renderNoContent: () => React.ReactNode;
}

export const ActivityLogList: React.FunctionComponent<ActivityLogListProps> = ({ renderNoContent, listClassName, listItemClassName = '', ...rest }) => {
    const { activityLogs, activityLogListEndRef } = useActivityLogList(rest);

    return (
        <>
            {activityLogs.length > 0 ? (
                <ul className={listClassName}>
                    {activityLogs.map(activityLog => (
                        <li key={activityLog.id} className={listItemClassName}>
                            <ActivityLogEntry activityLog={activityLog} />
                        </li>
                    ))}
                    {rest.subsequentQueryInputs.map((queryInput) => (
                        <ActivityLogListItems
                            key={queryInput.offset}
                            className={listItemClassName}
                            queryInput={queryInput}
                        />
                    ))}
                    <li ref={activityLogListEndRef} />
                </ul>
            ) : renderNoContent()}
        </>
    )
}

type ActivityLogListItemsProps = UseActivityLogsInput & {
    className?: string;
}

export const ActivityLogListItems: React.FunctionComponent<ActivityLogListItemsProps> = (props) => {
    const {
        activityLogs,
    } = useActivityLogs(props)

    return (
        <>
            {activityLogs.map(activityLog => (
                <li key={activityLog.id} className={props.className}>
                    <ActivityLogEntry activityLog={activityLog} />
                </li>
            ))}
        </>
    )
}
