import { isDefined } from "@helpers/core/typeGuards";
import { ChildrenProps } from "@typedefs/props";
import {
    Children,
    FunctionComponent,
    MutableRefObject,
    isValidElement,
    useEffect,
    useState,
} from "react";

import { Cn } from "@helpers/unsorted/classNames";
import { useNavigate } from "react-router-dom";
import { Content } from "./Content";
import { Tab } from "./Tab";
import { TabContext } from "./TabContext";

const styles = {
    root: Cn.c("border-b-2 border-default box-border"),
};

interface Props extends ChildrenProps {
    defaultTab?: string;
    onTabChange?: (newActiveTab?: string) => void;
    setActiveTabRef?: MutableRefObject<
        ((newActiveTab?: string) => void) | undefined
    >;
    className?: string;
    tabListClassName?: string;
    tabChildClassName?: string;
}

const Tabs: FunctionComponent<Props> = ({
    defaultTab,
    onTabChange,
    setActiveTabRef,
    className,
    tabListClassName = "",
    tabChildClassName,
    children,
}) => {
    const [activeTab, setActiveTab] = useState<string | undefined>(defaultTab);

    const navigate = useNavigate();

    const setActiveTabWithCallback = (
        newActiveTab?: string,
        route?: string
    ) => {
        if (isDefined(route)) {
            navigate(route);
        } else {
            setActiveTab(newActiveTab);
            if (isDefined(onTabChange)) {
                onTabChange(newActiveTab);
            }
        }
    };

    useEffect(() => {
        if (isDefined(setActiveTabRef)) {
            setActiveTabRef.current = setActiveTabWithCallback;
        }
    }, [setActiveTab]);

    return (
        <TabContext.Provider
            value={{ activeTab, setActiveTab: setActiveTabWithCallback }}
        >
            <div className={className}>
                <div className={Cn.join([styles.root, tabListClassName])}>
                    {Children.map(children, (child) =>
                        isValidElement(child) && child.type === Tab
                            ? child
                            : null
                    )}
                </div>
                <div className={tabChildClassName}>
                    {Children.map(children, (child) =>
                        isValidElement(child) && child.type === Content
                            ? child
                            : null
                    )}
                </div>
            </div>
        </TabContext.Provider>
    );
};

export { Props, Tabs };
