import { useClickOutside } from "@helpers/hooks/unsorted/clickOutsideHook";
import { CSVBulkActions } from "@routes/candidate";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Mode = 'show' | 'hide';

const useCsvBulkActions = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [listDisplayMode, setListDisplayMode] = useState<Mode>('hide');

    const toggleList = () => listDisplayMode === 'hide'
        ? setListDisplayMode('show')
        : setListDisplayMode('hide');

    const bulkActionsRef = useRef<HTMLDivElement>(null);

    useClickOutside([bulkActionsRef], () => setListDisplayMode('hide'));

    const onSelectAction = (action: string) => {
        if (action === "BULK_EVALUATION") {
            navigate(CSVBulkActions.CSV_BULK_EVALUTION_PATH_NAME)
        }

        // if (action === "BULK_SCHEDULING") {
        //     console.log("Bulk scheduling action selected");
        // }
    }

    const csvBulkActions = [
        {
            label: t("applications.csvBulkActions.actions.bulkEvaluation"),
            value: "BULK_EVALUATION"
        },
        // {
        //     label: t("applications.csvBulkActions.actions.bulkScheduling"),
        //     value: "BULK_SCHEDULING"
        // },
    ];

    return {
        bulkActionsRef,
        toggleList,
        listDisplayMode,
        csvBulkActions,
        onSelectAction
    }
}

export {
    Mode,
    useCsvBulkActions
}