import { hasValue } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { EventSessionUniqueLocationWarningModal } from '@shared/event/EventSessionUniqueLocationWarningModal/EventSessionUniqueLocationWarningModal';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Input } from '@shared/unsorted/Input/Input';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEventSessionDetailContext } from '@pages/EventSessionDetailPage/EventSessionDetailContext';
import { SessionGroupManipulationMode } from '../hook';
import { useEventSessionGroupManipulateLocationModal } from './hook';

const styles = {
    container: Cn.c('max-h-[95%] rounded-xl'),
    form: Cn.c('h-full flex flex-col'),
    header: Cn.c('p-6 flex justify-between items-center border-b border-default'),
    title: Cn.c('text-emphasized font-h4-bold'),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    body: Cn.c('p-6 flex flex-col space-y-6 flex-1'),
    loadingBody: Cn.c('flex items-center justify-center flex-1'),
    groupContainer: Cn.c('mt-6'),
    footer: Cn.c('p-6 border-t border-default flex justify-between items-center space-x-3'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    manipulationMode: SessionGroupManipulationMode;
}

const EventSessionGroupManipulateLocationModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    manipulationMode,
}) => {
    const { t } = useTranslation();

    const { event } = useEventSessionDetailContext()

    const {
        mode,
        onCloseWarningModal,
        onFinalSubmit,
        form,
        onSubmit,
        isSubmitting,
        onCloseModal,
    } = useEventSessionGroupManipulateLocationModal(manipulationMode, closeModal);

    const { control, formState: { errors, isDirty }, clearErrors } = form;

    return (mode.name === 'init'
        ? < Modal
            isOpen={isOpen}
            widthClass="w-[50rem]"
            close={onCloseModal}
            className={styles.container}
        >
            {<form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.header}>
                    <p className={styles.title}>{t("event.eventSessionGroupManipulateLocationModal.title")}</p>
                    <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
                </div>
                <div className={styles.body}>
                    <Controller
                        name="location"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { name, onBlur, onChange, ref, value } }) =>
                            <Input
                                name={name}
                                size="x-large"
                                label={event.isOnline
                                    ? "event.addSession.locationOnlineLabel"
                                    : "event.addSession.locationOfflineLabel"
                                }
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={errors}
                                clearErrors={clearErrors}
                                forwardedRef={ref}
                                value={value}
                            />}
                    />
                </div>
                <div className={styles.footer}>
                    <Button
                        variant="secondary"
                        onClick={onCloseModal}
                        size="md"
                        isFull={true}
                        isDisabled={isSubmitting}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        isFull={true}
                        type="submit"
                        isDisabled={isSubmitting || !isDirty}
                        isLoading={isSubmitting}
                    >
                        {t("global.save")}
                    </Button>
                </div>
            </form>}
        </Modal>
        : <EventSessionUniqueLocationWarningModal
            onClose={onCloseWarningModal}
            isOpen={isOpen}
            revertToPreviousMode={mode.payload.revertToPreviousMode}
            onSubmit={onFinalSubmit}
            error={mode.payload.error}
        />
    );

};

export {
    EventSessionGroupManipulateLocationModal
};
