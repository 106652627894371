import { hasValue, isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { ExtractStrict } from '@typedefs/helpers';
import { ChildrenProps, HTMLPassedProps } from '@typedefs/props';
import { ButtonHTMLAttributes, FunctionComponent, useMemo } from 'react';
import { Icon, IconName } from '../Icon/Icon';
import { Loader, LoaderSize, LoaderVariant } from '../Loader/Loader';

const buttonVariants = [
    'primaryFilled',
    'primaryOutline',
    'primaryPlain',
    'primaryPlainBg', // zeplin: primary plain with bg
    'secondary',
    'destructiveFilled',
    'destructiveOutline',
    'destructivePlain',
    'ghost',
] as const;

const buttonSizes = [
    'sm',
    'md',
    'lg',
] as const;

const roundedVariants = [
    'all',
    'left',
    'right',
] as const;

type Variant = typeof buttonVariants[number];
// TODO: [REMOVE] 'jobDetailsCustom' size is only for job details page. Remove this when usage is removed
type Size = typeof buttonSizes[number] | 'jobDetailsCustom';
type RoundedVariant = typeof roundedVariants[number];
type Type = ExtractStrict<ButtonHTMLAttributes<HTMLButtonElement>['type'], 'button' | 'submit'>;

const styles = {
    base: Cn.c('group'),
    button: (
        size: Size,
        variant: Variant,
        roundedVariant: RoundedVariant,
        isDisabled: boolean,
    ) => {
        let sizeStyle: string;
        switch (size) {
            case 'sm':
                sizeStyle = Cn.c('py-2 px-3 h-8 space-x-1 font-paragraph-small-button-medium');
                break;
            case 'md':
                sizeStyle = Cn.c('py-3 px-4 h-10 space-x-1 font-paragraph-small-button-medium');
                break;
            case 'lg':
                sizeStyle = Cn.c('py-4 px-6 h-12 space-x-2 font-paragraph-base-button-medium');
                break;
            // TODO: [REMOVE] 'jobDetailsCustom' size is only for job details page. Remove this when usage is removed
            case 'jobDetailsCustom':
                sizeStyle = Cn.c('w-36 h-[38px] space-x-2 font-paragraph-small-medium');
                break;
        }

        let variantStyle: string;
        switch (variant) {
            case 'primaryFilled':
                variantStyle = isDisabled
                    ? Cn.c('bg-actions-primary-disabled text-disabled')
                    : Cn.join([
                        Cn.c('bg-actions-primary-default text-on-primary'),
                        Cn.c('group-hover:bg-actions-primary-hovered'),
                    ]);
                break;
            case 'primaryOutline':
                variantStyle = isDisabled
                    ? Cn.c('bg-actions-primary-disabled text-disabled')
                    : Cn.join([
                        Cn.c('bg-surface-default text-primary-default border border-primary-default'),
                        Cn.c('group-hover:bg-surface-primary-subdued'),
                    ]);
                break;
            case 'primaryPlain':
                variantStyle = isDisabled
                    ? Cn.c('text-disabled')
                    : Cn.join([
                        Cn.c('text-primary-default'),
                        Cn.c('group-hover:underline group-hover:text-primary-emphasized'),
                    ]);
                break;
            case 'primaryPlainBg':
                variantStyle = isDisabled
                    ? Cn.c('bg-default text-disabled')
                    : Cn.join([
                        Cn.c('bg-default text-primary-default'),
                        Cn.c('group-hover:text-primary-default group-hover:bg-surface-hovered-emphasized'),
                    ]);
                break;
            case 'secondary':
                variantStyle = isDisabled
                    ? Cn.c('bg-actions-primary-disabled text-disabled')
                    : Cn.join([
                        Cn.c('bg-surface-default text-emphasized border border-emphasized'),
                        Cn.c('group-hover:bg-surface-hovered-default'),
                    ]);
                break;
            case 'destructiveFilled':
                variantStyle = isDisabled
                    ? Cn.c('bg-actions-critical-disabled text-disabled')
                    : Cn.join([
                        Cn.c('bg-actions-critical-default text-on-primary'),
                        Cn.c('group-hover:bg-actions-critical-hovered'),
                    ]);
                break;
            case 'destructiveOutline':
                variantStyle = isDisabled
                    ? Cn.c('bg-actions-primary-disabled text-disabled')
                    : Cn.join([
                        Cn.c('bg-surface-default text-critical-default border border-critical-default'),
                        Cn.c('group-hover:bg-surface-critical-subdued'),
                    ]);
                break;
            case 'destructivePlain':
                variantStyle = isDisabled
                    ? Cn.c('text-disabled')
                    : Cn.join([
                        Cn.c('text-critical-default'),
                        Cn.c('group-hover:underline group-hover:text-critical-emphasized'),
                    ]);
                break;
            case 'ghost':
                variantStyle = isDisabled
                    ? Cn.c('text-disabled')
                    : Cn.join([
                        Cn.c('text-emphasized'),
                        Cn.c('group-hover:rounded group-hover:bg-surface-hovered-emphasized'),
                    ]);
                break;
        }

        let roundedStyle: string;
        switch (roundedVariant) {
            case 'all':
                roundedStyle = Cn.c('rounded-lg');
                break;
            case 'left':
                roundedStyle = Cn.c('rounded-l-lg');
                break;
            case 'right':
                roundedStyle = Cn.c('rounded-r-lg');
                break;
        }

        return Cn.join([
            Cn.c('flex flex-row items-center justify-center content-center'),
            sizeStyle,
            variantStyle,
            roundedStyle,
        ]);
    },
    icon: (variant: Variant, size: Size, isDisabled: boolean) => {
        // TODO: [FIX] Defining the size of the icon messes up the rendered SVG icon. This can be possible fixed with the suggestion here: https://stackoverflow.com/a/60211578
        let sizeStyle: string;
        switch (size) {
            case 'sm':
            case 'md':
                sizeStyle = Cn.c('w-4 h-4');
                break;
            case 'lg':
            case 'jobDetailsCustom': // TODO: [REMOVE] 'jobDetailsCustom' size is only for job details page. Remove this when usage is removed
                sizeStyle = Cn.c('w-6 h-6');
                break;
        }

        let enabledVariantStyle: string;
        switch (variant) {
            case 'primaryFilled':
                enabledVariantStyle = Cn.c('text-icons-on-primary');
                break;
            case 'primaryOutline':
            case 'primaryPlainBg':
                enabledVariantStyle = Cn.c('text-icons-primary-default');
                break;
            case 'primaryPlain':
                enabledVariantStyle = Cn.join([
                    Cn.c('text-icons-primary-default'),
                    Cn.c('group-hover:text-icons-primary-emphasized'),
                ]);
                break;
            case 'secondary':
                enabledVariantStyle = Cn.c('text-icons-emphasized');
                break;
            case 'destructiveFilled':
                enabledVariantStyle = Cn.c('text-icons-on-destructive');
                break;
            case 'destructiveOutline':
                enabledVariantStyle = Cn.c('text-icons-critical-default');
                break;
            case 'destructivePlain':
                enabledVariantStyle = Cn.join([
                    Cn.c('text-icons-critical-default'),
                    Cn.c('group-hover:text-icons-critical-emphasized'),
                ]);
                break;
            case 'ghost':
                enabledVariantStyle = Cn.c('text-icons-emphasized');
                break;
        }

        const variantStyle = isDisabled ? Cn.c('text-icons-disabled') : enabledVariantStyle;

        return Cn.join([
            sizeStyle,
            variantStyle,
        ]);
    },
};

interface Props extends ChildrenProps, HTMLPassedProps<'id' | 'className' | 'onClick'> {
    /** The size of the button */
    size: Size;
    /** Defines look of the button */
    variant?: Variant;
    /** Defines which sides of the button to apply rounded borders */
    roundedVariant?: RoundedVariant;
    /** Indicates the behavior of the button */
    type?: Type;
    /** Indicates whether or not the button is disabled */
    isDisabled?: boolean;
    /**  Icon of the button */
    iconName?: IconName;
    /** Indicates whether the icon should be displayed after the button's content */
    isTrailingIcon?: boolean;
    /** Indicates whether the button should take the full width of its container */
    isFull?: boolean;
    /** Indicates whether to show a loading spinner instead of the button's content*/
    isLoading?: boolean;
    /**  Custom class names for the icon */
    iconClassName?: string;
    /**  ID of form that the element belongs to */
    formId?: string;
}

const Button: FunctionComponent<Props> = ({
    size,
    variant = 'primaryFilled',
    roundedVariant = 'all', // TODO: Check if this is still needed for the multi-action button variant
    type = 'button',
    isDisabled = false,
    iconName,
    isTrailingIcon = false,
    isFull = false,
    isLoading = false,
    children,
    iconClassName,
    formId,
    ...passedProps
}) => {
    const loaderVariant: LoaderVariant = useMemo(() => {
        switch (variant) {
            case 'primaryFilled':
            case 'destructiveFilled':
                return 'onPrimary';
            case 'primaryOutline':
            case 'primaryPlain': // TODO: [CHECK] In zeplin, no spinner is defined for this variant. Temporarily assign loader to closest variant
            case 'primaryPlainBg': // TODO: [CHECK] In zeplin, no spinner is defined for this variant. Temporarily assign loader to closest variant
                return 'primaryDefault';
            case 'secondary':
            case 'ghost':
                return 'emphasized';
            case 'destructiveOutline':
            case 'destructivePlain': // TODO: [CHECK] In zeplin, no spinner is defined for this variant. Temporarily assign loader to closest variant
                return 'criticalDefault';
        }
    }, [variant]);

    const loaderSize: LoaderSize = useMemo(() => {
        switch (size) {
            case 'sm':
            case 'md':
                return 'sm';
            case 'lg':
            case 'jobDetailsCustom': // TODO: [REMOVE] 'jobDetailsCustom' size is only for job details page. Remove this when usage is removed
                return 'lg';
        }
    }, [size]);

    return (
        <button
            id={passedProps.id}
            type={type}
            form={formId}
            onClick={passedProps.onClick}
            disabled={isDisabled || isLoading}
            className={Cn.join([
                styles.base,
                Cn.ifTrue(isFull, "w-full"),
            ])}
        >
            <div
                className={Cn.join([
                    styles.button(size, variant, roundedVariant, isDisabled),
                    Cn.getSome(passedProps.className),
                ])}
            >
                {/* TODO: [REFACTOR] Use Suspense */}
                {isLoading
                    ? <Loader variant={loaderVariant} size={loaderSize} />
                    : <>
                        {isDefined(iconName) && !isTrailingIcon && <Icon name={iconName} className={Cn.join([styles.icon(variant, size, isDisabled), Cn.getSome(iconClassName)])} />}
                        {hasValue(children) && <div>{children}</div>}
                        {isDefined(iconName) && isTrailingIcon && <Icon name={iconName} className={Cn.join([styles.icon(variant, size, isDisabled), Cn.getSome(iconClassName)])} />}
                    </>
                }
            </div>
        </button>
    );
};

export {
    Button, buttonSizes, buttonVariants, roundedVariants
};
