import { FunctionComponent } from "react"
import { useInitialState } from "./init";
import { useEventSessionDetailPage } from "./hook";
import { EventSessionDetailLoadingState } from "./EventSessionDetailLoadingState/EventSessionDetailLoadingState";
import { EventSessionDetailSuccessState } from "./EventSessionDetailSuccessState/EventSessionDetailSuccessState";
import { EventSessionDetailContext } from "./EventSessionDetailContext";

const EventSessionDetailPage: FunctionComponent = () => {
    const { initialState } = useInitialState();

    useEventSessionDetailPage(initialState);

    return initialState.isLoading
        ? <EventSessionDetailLoadingState />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <EventSessionDetailContext.Provider value={initialState.result.data}>
                <EventSessionDetailSuccessState />
            </EventSessionDetailContext.Provider>
}

export {
    EventSessionDetailPage
}