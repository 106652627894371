import { FunctionComponent, MouseEventHandler } from 'react';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';

const modalVariants = ['default', 'fixed'] as const;

type Variant = typeof modalVariants[number];

const styles = {
    root: (isOpen: boolean) => Cn.join([
        Cn.c('absolute flex inset-0 transition-all duration-300 items-center justify-center z-[150]'),
        isOpen ? Cn.c('visible') : Cn.c('invisible'),
    ]),
    overlay: (isOpen: boolean) => Cn.join([
        Cn.c('absolute inset-0 bg-overlay-default z-50'),
        isOpen ? Cn.c('bg-opacity-40') : Cn.c('bg-opacity-0'),
    ]),
    inner: (isOpen: boolean, widthClass?: string, variant?: Variant) => {
        let variantStyle: string = Cn.c('');

        switch (variant) {
            case 'default':
                variantStyle = Cn.c("overflow-y-auto");
                break;

            case 'fixed':
                variantStyle = Cn.c("overflow-y-visible");
                break;
        }

        return Cn.join([
            Cn.c('bg-surface-default transform-gpu transition-transform duration-300 rounded-xl max-h-12/13 z-50 shadow-xl'),
            isOpen ? Cn.c('scale-100') : Cn.c('scale-0'),
            variantStyle,
            widthClass ?? Cn.c('w-128')
        ])
    },
    header: Cn.c('flex justify-end'),
};

interface Props extends ChildrenProps {
    className?: string;
    isOpen?: boolean;
    close: MouseEventHandler<HTMLDivElement>;
    widthClass?: string;
    variant?: Variant;
}

// @ocaml.doc("The reusable modal component.")
const Modal: FunctionComponent<Props> = ({ isOpen = false, close, children, className, widthClass, variant = 'default' }) => {
    return (
        <div className={styles.root(isOpen)}>
            <div className={styles.overlay(isOpen)} onClick={close} />
            <div className={Cn.join([styles.inner(isOpen, widthClass, variant), Cn.getSome(className)])}>{children}</div>
        </div>
    );
};

export {
    Modal,
    modalVariants
};

