import { Button } from '@shared/unsorted/Button/Button';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CsvExportActions, Mode, useCsvExportActions } from './hook';
import { Cn } from '@helpers/unsorted/classNames';

const styles = {
    caretIcon: (mode: Mode) => Cn.ifTrue(mode === 'show', Cn.c('transform rotate-180')),
    list: Cn.c('w-fit absolute z-[100] bg-surface-default border border-subdued rounded-lg overflow-auto shadow-md top-[3.75rem] py-2'),
    listRow: Cn.c('font-paragraph-small-regular cursor-default py-2 px-4 text-emphasized hover:bg-surface-hovered-default')
};

interface Props {
    onCsvExportAction: (action: CsvExportActions) => void;
    disabled?: boolean;
}

const CsvExportActions: FunctionComponent<Props> = ({ onCsvExportAction, disabled: isDisabled = false }) => {
    const { t } = useTranslation();

    const {
        csvExportActionsRef,
        csvActionsDisplayMode,
        toggleCsvActionsList,
        onClickCsvExportAction,
        csvExportActions
    } = useCsvExportActions(onCsvExportAction);

    return (
        <div ref={csvExportActionsRef}>
            <Button
                size="sm"
                variant="secondary"
                iconName="caret"
                iconClassName={styles.caretIcon(csvActionsDisplayMode)}
                isTrailingIcon
                onClick={toggleCsvActionsList}
                isDisabled={isDisabled}
            >
                {t("applications.exportCandidates.button")}
            </Button>
            {csvActionsDisplayMode === "show" &&
                <div className={styles.list}>
                    {csvExportActions.map(({ label, action }) =>
                        <div
                            key={action}
                            className={styles.listRow}
                            onClick={() => onClickCsvExportAction(action)}
                        >
                            {label}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export {
    CsvExportActions,
};