import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventSettingContext } from '@shared/event/EventSettingsModal/useEventSettingContext';
import { Entity } from '@typedefs/graphql';
import { Event_OneByIdQuery } from '@entities';

type Mode = 'show' | 'hide';

interface EventSettingsActionsHookType {
    eventSettingsDisplayMode: Mode;
    eventSettingsActionsRef: React.RefObject<HTMLDivElement>;
    toggleEventSettingsActionList: VoidFunction;
    eventSettingsActions: {
        label: string;
        value: string;
    }[];
    onOptionClick: (eventSettingAction: string) => void;
}

const useEventSettingsActions = (event: Entity<Event_OneByIdQuery, 'event'>, openDeleteConfirmationModal: VoidFunction): EventSettingsActionsHookType => {
    const { t } = useTranslation();

    const [eventSettingsDisplayMode, setEventSettingsDisplayMode] = useState<Mode>('hide');

    const [_, setEventSettingModalMode] = useEventSettingContext()

    const eventSettingsActionsRef = useRef<HTMLDivElement>(null);

    useClickOutside([eventSettingsActionsRef], () => setEventSettingsDisplayMode('hide'));

    const toggleEventSettingsActionList = () => eventSettingsDisplayMode === 'hide' ? setEventSettingsDisplayMode('show') : setEventSettingsDisplayMode('hide');

    const eventSettingsActions = [
        {
            label: t('global.edit'),
            value: 'edit',
        },
        {
            label: t('global.delete'),
            value: 'delete',
        }
    ];

    const onOptionClick = (eventSettingAction: string) => {
        if (eventSettingAction === 'edit') {
            setEventSettingModalMode({
                name: 'edit',
                payload: {
                    event
                }
            })
        } else {
            openDeleteConfirmationModal();
        }
    };

    return {
        eventSettingsDisplayMode,
        eventSettingsActionsRef,
        toggleEventSettingsActionList,
        eventSettingsActions,
        onOptionClick,
    };
};

export {
    useEventSettingsActions
};
