import { useBulkActionExecution } from "@shared/application/useBulkActionExecution";
import { useBulkActionModalContext } from "../BulkActionModalContext";
import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { SelectedApplications } from "@shared/application/bulkActions/helpers";
import { splitToChunks } from "@helpers/unsorted/arrayExtra";
import { BULK_ACTION_ID_SIZE } from "../ApplicationsTable/BulkActionMenu/hook";
import { BulkActionErrorModalPayload } from "../reducer";

const useBulkEventSessionAssignmentErrorModal = ({
    closeModal,
    errorPayload,
}: {
    closeModal: VoidFunction,
    errorPayload?: BulkActionErrorModalPayload,
}) => {
    const action = 'ASSIGN_TO_SESSION_MANUALLY'
    const failedApplications = errorPayload?.failedIds || []
    const bulkActionPayload = errorPayload?.bulkActionPayload

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const { setApplicationSelection, applicationSelection } = useApplicationSelectionContext()

    const { success: toastSuccess } = useToast()

    const { execute } = useBulkActionExecution({
        action,
        payload: {
            action,
            applicationIdBatches: splitToChunks(failedApplications || [], BULK_ACTION_ID_SIZE),
            bulkActionPayload,
        },
        onStartExecution: () => {
            if (action) {
                dispatchBulkActionModalMode({
                    name: 'showBulkActionLoadingModal',
                    payload: {
                        action,
                        total: failedApplications.length,
                        processed: 0
                    }
                })
            }
        },
        onCompleteExecutingBatch: (result) => {
            dispatchBulkActionModalMode({
                name: 'showBulkActionLoadingModal',
                payload: {
                    action,
                    total: failedApplications.length,
                    processed: result.totalProcessed
                }
            })
        },
        onCompleteExecution: (result) => {
            if (action) {
                if (result.detail.success === failedApplications.length) {
                    closeModal();
                    toastSuccess('applications.bulkAction.success');
                    setApplicationSelection({});
                }

                if (result.detail.failed > 0) {
                    const originalSelectedApplications = applicationSelection[action]

                    if (originalSelectedApplications) {
                        setApplicationSelection({
                            ...applicationSelection,
                            [action]: result.detail.failedIds.reduce((acc: SelectedApplications, id) => {
                                acc[id] = originalSelectedApplications[id]

                                return acc
                            }, {})
                        })

                        // Need to call onError again if there are still failed applications
                        dispatchBulkActionModalMode({
                            name: 'showBulkEventSessionAssignmentErrorModal',
                            payload: {
                                action,
                                failedIds: result.detail.failedIds,
                                success: result.detail.success,
                                bulkActionPayload: errorPayload?.bulkActionPayload,
                                closeModal
                            }
                        })
                    }
                }
            }

        },
    });

    return {
        retry: execute
    };
}

export {
    useBulkEventSessionAssignmentErrorModal
}