import { FunctionComponent } from "react"
import { InitialStateData } from "../init"
import { Cn } from "@helpers/unsorted/classNames"
import { EventDetail } from "../../EventDetail/EventDetail"
import { Button } from "@shared/unsorted/Button/Button"
import { useTranslation } from "react-i18next"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { format } from "date-fns"
import { RadioInputItemBoxGroup } from "@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup"
import { useSessionGroupSelectionStepSuccessState } from "./hook"

const styles = {
    body: Cn.c("p-6 pt-0 flex flex-col flex-1 overflow-auto"),
    footer: Cn.c("p-6 border-t border-default flex justify-between items-center space-x-3"),
    selectedSessionText: Cn.c("text-subdued font-paragraph-small-regular pt-6"),
    row: Cn.c("flex items-center space-x-3 pt-1"),
    line: Cn.c("flex items-center space-x-0.5"),
    icon: Cn.c("w-6 h-6 text-icons-emphasized"),
    text: Cn.c("font-h4-bold text-emphasized"),
    selectGroupText: Cn.c("text-emphasized font-paragraph-small-regular py-6"),
    scrollable: Cn.c("flex flex-col flex-1 overflow-auto"),
    rightButtons: Cn.c("flex items-center space-x-3")
}

interface Props extends InitialStateData {
    closeModal: VoidFunction
    goToSessionSelection: VoidFunction
}

const SessionGroupSelectionStepSuccessState: FunctionComponent<Props> = ({
    closeModal,
    goToSessionSelection,
    eventSession
}) => {
    const { t } = useTranslation()
    const {
        onGroupChange,
        groupOptions,
        groupId,
        confirm
    } = useSessionGroupSelectionStepSuccessState(eventSession, closeModal)

    return (
        <>
            <div className={styles.body}>
                <EventDetail
                    jobTitle={eventSession.event.recruitmentStepV2.jobPosition.title}
                    stepName={eventSession.event.recruitmentStepV2.name}
                    eventName={eventSession.event.name}
                />
                <p className={styles.selectedSessionText}>
                    {t('applications.bulkEventSessionAssignment.sessionGroupSelection.selectedSession')}
                </p>
                <div className={styles.row}>
                    <div className={styles.line}>
                        <Icon name="calendarFill" className={styles.icon} />
                        <p className={styles.text}>
                            {format(new Date(eventSession.startAt), 'yyyy-LL-dd (E)')}
                        </p>
                    </div>
                    <div className={styles.line}>
                        <Icon name="clock" className={styles.icon} />
                        <p className={styles.text}>
                            {`${format(new Date(eventSession.startAt), 'h:mm')}~${format(new Date(eventSession.endAt), 'h:mm')}`}
                        </p>
                    </div>
                </div>
                <p className={styles.selectGroupText}>
                    {t('applications.bulkEventSessionAssignment.sessionGroupSelection.selectGroup')}
                </p>
                <RadioInputItemBoxGroup
                    name={"groupId"}
                    layout="grid"
                    gridColumns={2}
                    options={groupOptions}
                    onChange={onGroupChange}
                    value={groupId || ''}
                    className={styles.scrollable}
                />
            </div>
            <div className={styles.footer}>
                <Button
                    size="md"
                    variant="secondary"
                    onClick={closeModal}
                >
                    {t('global.cancel')}
                </Button>
                <div className={styles.rightButtons}>
                    <Button
                        size="md"
                        variant="secondary"
                        onClick={goToSessionSelection}
                        isTrailingIcon={false}
                        iconName="arrowLeftLine"
                    >
                        {t('applications.bulkEventSessionAssignment.sessionGroupSelection.backToSessionSelection')}
                    </Button>
                    <Button
                        size="md"
                        variant='primaryFilled'
                        onClick={confirm}
                        isDisabled={!groupId}
                    >
                        {t('applications.bulkEventSessionAssignment.sessionGroupSelection.confirm')}
                    </Button>
                </div>
            </div>
        </>
    )
}

export {
    SessionGroupSelectionStepSuccessState
}