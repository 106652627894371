import { FocusEventHandler, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { I18nKey, useTranslation } from 'react-i18next';

import { isDefined } from '@helpers/core/typeGuards';
import { useErrorMessage } from '@helpers/hooks/unsorted/errorMessageHook';
import { Cn } from '@helpers/unsorted/classNames';
import { FormProps, ForwardedRefProps, HTMLPassedProps } from '@typedefs/props';
import { Label } from '../Label/Label';
import { ToolTipBox, ToolTipBoxProps, TooltipPlacement } from '../ToolTipBox/ToolTipBox';
import { Icon, IconName } from '../Icon/Icon';
import { assertNever } from '@helpers/typeHelpers';

const textAreaSizes = ['lg', 'md', 'sm'] as const;

type Orientation = 'horizontal' | 'vertical';
type AutocompleteMode = 'on' | 'off';
type Size = typeof textAreaSizes[number];


const styles = {
    root: (orientation: Orientation) => Cn.join([
        Cn.c("flex w-full font-paragraph-small-regular"),
        orientation === "horizontal" ? Cn.c("flex-row space-x-3 items-baseline") : Cn.c("flex-col space-y-1"),
    ]),
    label: (size: Size, labelClassName?: string) => {
        let fontSize = "";

        switch (size) {
            case "sm":
                fontSize = Cn.c("font-paragraph-xsmall-medium");
                break;
            case "md":
                fontSize = Cn.c("font-paragraph-small-medium");
                break;
            case "lg":
                fontSize = Cn.c("font-paragraph-base-medium");
                break;
            default:
                assertNever(size);
                break;
        }
        return Cn.join([
            Cn.c("text-emphasized"),
            Cn.c(fontSize),
            Cn.getSome(labelClassName),
        ]);
    },
    textAreaContainer: Cn.c("flex w-full items-center relative"),
    textAreaIconContainer: Cn.c("flex w-full items-center relative"),
    textArea: ({ size, isDisabled, hasIcon, hasError, hasWarning }:
        {
            size: Size,
            isDisabled: boolean,
            hasIcon: boolean,
            hasError: boolean,
            hasWarning: boolean,
        }) => {
        let fontSize = "";

        switch (size) {
            case "sm":
                fontSize = Cn.c("font-paragraph-xsmall-regular");
                break;
            case "md":
                fontSize = Cn.c("font-paragraph-small-regular");
                break;
            case "lg":
                fontSize = Cn.c("font-paragraph-base-regular");
                break;
            default:
                assertNever(size);
                break;
        }

        return Cn.join([
            Cn.c("border h-44 border-emphasized w-full cursor-pointer rounded-lg bg-surface-default focus:outline-none focus:border-primary-default placeholder-default text-emphasized"),
            Cn.c(fontSize),
            Cn.ifTrue(hasError, "border-critical-default"),
            Cn.ifTrue(hasWarning, "border-warning-default"),
            Cn.ifTrue(isDisabled, "bg-surface-disabled"),
            hasIcon ? Cn.c("pr-6 pl-10 py-4") : Cn.c("px-6 py-4"),
        ]);
    },
    icon: Cn.c("w-4 h-4 text-icons-subdued absolute left-6 top-4"),
    error: Cn.c("text-critical-default font-paragraph-xsmall-regular"),
    caption: Cn.c("text-subdued font-paragraph-xsmall-regular"),
    warning: Cn.c("text-warning-default font-paragraph-xsmall-regular"),
};

interface Props<FieldsType extends FieldValues>
    extends
    HTMLPassedProps<'value'>,
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLTextAreaElement> {
    /** Size of the text area */
    size?: Size;
    orientation?: Orientation;
    required?: boolean;
    label?: I18nKey;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    className?: string;
    textAreaClassName?: string;
    labelClassName?: string;
    placeholder?: I18nKey;
    autocomplete?: AutocompleteMode;
    onBlur?: VoidFunction;
    onChange?: (event: unknown) => void; // TODO: [IMPLEMENT] Replace with correct type
    onFocus?: FocusEventHandler;
    errorLabel?: I18nKey;
    disabled?: boolean;
    /** Tooltip shown when the text area is disabled */
    disabledTooltip?: ToolTipBoxProps;
    /**  Icon of the text area */
    iconName?: IconName;
    iconClassName?: string;
    /** Caption text displayed under the text area */
    caption?: I18nKey;
    /** Function executed onChange to check for the presence of a warning. */
    warnValidator?: (value: string) => I18nKey | undefined;
}

// @ocaml.doc("Text Area component
// - `name`: name of the input
// - `orientation`: optional - direction between label and input - horizontal by default
// - `label`: optional - the label to display for the input
// - `className`: optional - class to apply to the container
// - `textAreaClassName`: optional - class to apply to the textarea
// - `labelClassName`: optional - class to apply to the label
// - `disabled`: optional - is the input disabled or not - false by default
// - `placeholder`: optional - placeholder for the input
// - `onChange`: optional - function called when the input changes
// - `onBlur`: optional - function called when the input is blurred
// - `onFocus`: optional - function called when the input is focused
// - `value`: optional - value of the input
// - `errors`: optional - the possible errors of a form (returned by the `RescriptHookForm.Hooks.use` hook)
// - `clearErrors`: optional - a function that will clear the errors of the input on focus automatically (returned by the `RescriptHookForm.Hooks.use` hook)
// - `errorLabel`: optional - the label to display in the error message (defaults to the `label` prop if provided, `name` otherwise)
// - `required`: optional - show the * for required input - false by default
// - `autocomplete`: optional - if browser should display suggestions on user inserted text - 'on' by default
// - `tooltip`: optional - the tooltip to explain for the input, only displayed if the label is present
// - `tooltipPlacement`: optional - the placement of the tooltip - 'bottom' by default
// ")
const TextArea = <FieldsType extends FieldValues>(
    {
        size = 'lg',
        name,
        orientation = 'horizontal',
        required: isRequired = false,
        label,
        className,
        textAreaClassName,
        labelClassName,
        placeholder,
        autocomplete = 'off',
        onBlur,
        onChange,
        onFocus,
        errors,
        clearErrors,
        errorLabel,
        disabled: isDisabled = false,
        value,
        forwardedRef,
        tooltip,
        tooltipPlacement,
        disabledTooltip: textAreaDisabledTooltip,
        iconName,
        iconClassName,
        caption,
        warnValidator,
    }: Props<FieldsType>,
) => {
    const { t } = useTranslation();

    const errorFieldName = isDefined(errorLabel) ? t(errorLabel) : isDefined(label) ? t(label) : name;

    const error = useErrorMessage(
        name,
        errors,
        errorFieldName,
    );

    const [warnMessage, setWarnMessage] = useState<string | undefined>(undefined);

    const textAreaContent = (
        <div className={Cn.join([styles.root(orientation), Cn.getSome(className)])}>
            {isDefined(label) && <Label label={label} labelClassName={styles.label(size, labelClassName)} required={isRequired} tooltip={tooltip} tooltipPlacement={tooltipPlacement} />}
            <div className={styles.textAreaContainer}>
                <div className={styles.textAreaIconContainer}>
                    <textarea
                        name={name}
                        className={Cn.join([
                            styles.textArea({
                                size,
                                isDisabled,
                                hasIcon: isDefined(iconName),
                                hasError: isDefined(error),
                                hasWarning: isDefined(warnMessage),
                            }),
                            Cn.getSome(textAreaClassName)
                        ])}
                        autoComplete={autocomplete}
                        placeholder={isDefined(placeholder) ? t(placeholder) : undefined}
                        onChange={e => {
                            if (isDefined(warnValidator)) {
                                const result = warnValidator(e.target.value)
                                setWarnMessage(result ? t(result, { fieldName: errorFieldName }) : undefined);
                            }

                            onChange && onChange(e);
                        }}
                        onBlur={() => onBlur && onBlur()}
                        onFocus={event => {
                            clearErrors && clearErrors(name);
                            onFocus && onFocus(event);
                        }}
                        value={value}
                        ref={forwardedRef}
                        disabled={isDisabled}
                    />
                    {isDefined(iconName) && <Icon name={iconName} className={Cn.join([styles.icon, Cn.getSome(iconClassName)])} />}
                </div>
            </div>
            {isDefined(caption) && <div className={styles.caption}>{t(caption)}</div>}
            {isDefined(error) && <div className={styles.error}>{error}</div>}
            {isDefined(warnMessage) && <div className={styles.warning}>{warnMessage}</div>}
        </div>
    );

    return (isDefined(textAreaDisabledTooltip) && isDisabled
        ? <ToolTipBox
            {...textAreaDisabledTooltip}
        >
            {textAreaContent}
        </ToolTipBox>
        : textAreaContent
    );
};

export {
    TextArea,
    textAreaSizes,
};

