import { ValueItem as MultiValueTypeAheadItem } from '@shared/unsorted/MultiValueTypeAhead/value';
import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        clientIds: MultiValueTypeAheadItem.ValueItemType[];
    }

    export const defaultValues = (clientIds: MultiValueTypeAheadItem.ValueItemType[]) => ({
        clientIds,
    });
}

namespace Decoder {
    export const schema = z.object({
        clientIds: z.array(MultiValueTypeAheadItem.schema).min(1)
    }).transform((values, ctx) => {
        const clientIds = values.clientIds.map(({ value }) => value || '')

        if (clientIds.length === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'No application ids found',
            });

            return z.NEVER;
        }

        return {
            clientIds,
        }
    });
}

export {
    Decoder, Encoder
};

