import { JobListPanel } from "@shared/job_position/JobListPanel/JobListPanel";
import { JobListPanelContext } from "@shared/job_position/JobListPanel/JobListPanelContext";
import { useJobListPanelDisplay } from "@shared/job_position/JobListPanel/useJobListPanelDisplay";
import { FunctionComponent } from "react";
import { EventSessionDetailViewMode } from "./EventSessionDetailViewMode/EventSessionDetailViewMode";
import { useEventSessionDetailContext } from "../EventSessionDetailContext";

const EventSessionDetailSuccessState: FunctionComponent = () => {
    const { hideJobListPanel, showJobListPanel, displayMode: jobListPanelDisplayMode } = useJobListPanelDisplay()
    const { event } = useEventSessionDetailContext()

    return (
        <JobListPanelContext.Provider value={{ displayMode: jobListPanelDisplayMode, showJobListPanel, hideJobListPanel }}>
            <JobListPanel
                isOpen={jobListPanelDisplayMode === "show"}
                currentJobId={event.recruitmentStepV2.jobPosition.id}
            />
            <EventSessionDetailViewMode />
        </JobListPanelContext.Provider>
    );
}

export {
    EventSessionDetailSuccessState
}