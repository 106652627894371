import { MAX_FILE_SIZE } from '@helpers/core/constants';
import { DocumentUpload } from "@routes/unauthenticated"
import { useToast } from "@helpers/hooks/unsorted/toastHook"
import { parseSize, toSizeString } from '@helpers/unsorted/numberExtra';
import { useEffect, useReducer } from "react"
import { useClient, useMutation, useQuery } from "urql"
import { State, initialState, reducer } from "./reducer"
import { getQueryContext, handleResponse, responseHasError, useQueryContext } from "@helpers/unsorted/urqlExtra"
import { hasValue, isDefined } from "@helpers/core/typeGuards"
import { BasicCandidateDocument, BasicJobPositionDocument, DocumentDownloadDocument, UpdateCandidateDocuments_UpdateDocumentDocument } from "@entities"
import { useOpenFolder } from "@helpers/hooks/unsorted/rehooks/openFolder/openFolderHook"
import { downloadUrl } from "@helpers/unsorted/downloadUrl"

interface DocumentUploadPageHookType {
    state: State;
    switchToEdit: VoidFunction;
    switchToList: VoidFunction;
    removeSelectedDocument: (documentId: string) => void;
    documentUpload: ReturnType<typeof useOpenFolder>;
    downloadDocument: (documentId: string, filename: string) => Promise<void>;
    onSubmit: VoidFunction;
}

const useDocumentUploadPage = (): DocumentUploadPageHookType => {
    const queryParams = DocumentUpload.useSearchParams()

    const {error: toastError, success: toastSuccess} = useToast()

    const [state, dispatch] = useReducer(reducer, initialState)

    const documentUpload = useOpenFolder()

    const urqlClient = useClient()

    const candidateQueryContext = useQueryContext("BasicCandidate");
    const [candidateResponse,] = useQuery({
        query: BasicCandidateDocument,
        context: candidateQueryContext,
        variables: {
            recruitmentProcessId: queryParams.applicationId
        }
    })

    const jobPositionQueryContext = useQueryContext("BasicJobPosition");
    const [jobPositionResponse,] = useQuery({
        query: BasicJobPositionDocument,
        context: jobPositionQueryContext,
        variables: {
            applicationId: queryParams.applicationId
        }
    })

    const [updateDocumentsResponse, updateDocuments] = useMutation(UpdateCandidateDocuments_UpdateDocumentDocument)

    const switchToEdit = () => {
        dispatch({
            name: 'SwitchToEdit',
        })
    }

    const switchToList = () => {
        dispatch({
            name: 'SwitchToList'
        })
    }

    const removeSelectedDocument = (documentId: string) => {
        dispatch({
            name: 'RemoveDocument',
            payload: {
                documentId,
            }
        })
    }

    const downloadDocument = async (documentId: string, filename: string) => {
        const result = await urqlClient.query(DocumentDownloadDocument, {
            id: documentId
        }).toPromise()

        if(isDefined(result.data) && hasValue(result.data.url)) {
            downloadUrl(filename, result.data.url)
        }
    }

    const onSubmit = () => {
        if(!state.isLoading && state.mode.name === 'loaded') {
            const deletedDocumentIds = state.mode.documentState.documentsToDelete.map(({id}) => id)
 
            const uploadedDocuments = state.mode.documentState.documentsToUpload.map(({payload: { filename, file}}) => ({
                 filename: filename,
                 content: file as any,
                 isSelfUpload: true,
            }))
     
            if(deletedDocumentIds.length === 0 && uploadedDocuments.length === 0) {
                 dispatch({
                     name: 'SwitchToList'
                 })
                 toastSuccess('global.documentUpload.success')
     
                 return 
            }
       
            updateDocuments({
              applicationId: queryParams.applicationId,
              deletedDocumentIds,
              uploadedDocuments,
            }, getQueryContext('BasicCandidate'))
        }
     }

    useEffect(() => {
        const candidate = candidateResponse.data?.basicCandidate
        const jobPosition = jobPositionResponse.data?.basicJobPosition

        if (hasValue(candidate) && hasValue(jobPosition)) {
            dispatch({
                name: 'UpdateDisplayInformation',
                payload: {
                    candidate,
                    jobPosition
                }
            })
        }

        if(responseHasError(candidateResponse) || responseHasError(jobPositionResponse)) {
            dispatch({
                name: 'SetError'
            })
        }
    }, [
        candidateResponse,
        jobPositionResponse,
    ])

    useEffect(() => {
        handleResponse(updateDocumentsResponse, {
            onFetching: () => {
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: true
                    }
                })
            },
            onError: () => {
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: false
                    }
                })
                toastError('global.documentUpload.fail')
            },
            onData: () => {
                dispatch({
                    name: 'SwitchToList'
                })
                dispatch({
                    name: 'SetLoading',
                    payload: {
                        isUploading: false
                    }
                })
                toastSuccess('global.documentUpload.success')
            }
        })
    }, [updateDocumentsResponse])

    useEffect(() => {
        const files = documentUpload.files

        if(files) {
            if(files.some(file => file.size > parseSize(MAX_FILE_SIZE.DOCUMENT_UPLOAD))) {
                toastError('global.documentUpload.tooLarge', { size: toSizeString(MAX_FILE_SIZE.DOCUMENT_UPLOAD) })

                return;
            }

            dispatch({
                name: 'UploadDocument',
                payload: {
                    files
                }
            })
        }
    },[documentUpload.files])

    return {
        state,
        switchToEdit,
        switchToList,
        removeSelectedDocument,
        documentUpload,
        downloadDocument,
        onSubmit,
    }
}

export {
    useDocumentUploadPage
}