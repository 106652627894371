import { Event_OneByRecruitmentStepIdQuery } from "@entities"
import { getLocale, useCurrentLanguage } from "@helpers/core/i18n"
import { useBulkEventSessionAssignmentContext } from "@pages/ApplicationsPage/BulkEventSessionAssignmentModal/BulkEventSessionAssignmentContext"
import { useStepperContext } from "@shared/layout/stepper/useStepperContext"
import { RadioInputItemBoxOptions } from "@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup"
import { Entity } from "@typedefs/graphql"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

const useSessionSelectionStepSuccessState = (event: Entity<Event_OneByRecruitmentStepIdQuery, 'eventByRecruitmentStepId'>) => {

    const currentLanguage = useCurrentLanguage();

    const locale = getLocale(currentLanguage);

    const { t } = useTranslation()

    const { goToStep } = useStepperContext()

    const [{ settings }, dispatch] = useBulkEventSessionAssignmentContext()

    const sessionOptions: RadioInputItemBoxOptions[] = event.sessions.map(session => ({
        label: <span>{
            `${format(new Date(session.startAt), 'yyyy-LL-dd, (E) H:mm', { locale })}~${format(new Date(session.endAt), 'H:mm', { locale })}`
        }</span>,
        description: <span>{t('applications.bulkEventSessionAssignment.sessionSelection.numOfGroup', { count: session.groupCount })}</span>,
        value: session.id,
        disabled: false,
    }))

    const onSessionChange = (event: unknown) => {
        if (typeof event === 'string') {
            dispatch({
                name: 'SetSettings',
                payload: {
                    settings: {
                        ...settings,
                        sessionId: event
                    }
                }
            })
        }
    }

    const goToSessionGroupSelection = () => {
        dispatch({
            name: 'SetStep',
            payload: {
                step: {
                    name: 'sessionGroupSelection'
                }
            }
        })
        goToStep('sessionGroupSelection')
    }

    return {
        sessionOptions,
        onSessionChange,
        goToSessionGroupSelection,
    }
}

export {
    useSessionSelectionStepSuccessState
}