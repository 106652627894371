import { FunctionComponent } from "react"
import { useInitialState } from "./init"
import { SessionSelectionStepLoadingState } from "./SessionSelectionStepLoadingState/SessionSelectionStepLoadingState"
import { SessionSelectionStepSuccessState } from "./SessionSelectionStepSuccessState/SessionSelectionStepSuccessState"
import { useSessionSelectionStep } from "./hook"
import { useBulkEventSessionAssignmentContext } from "../../BulkEventSessionAssignmentContext"

const SessionSelectionStep: FunctionComponent = () => {
    const [{ settings }] = useBulkEventSessionAssignmentContext()

    const {
        initialState
    } = useInitialState(settings.currentRecruitmentStepId)

    const { closeModal } = useSessionSelectionStep(initialState)

    return initialState.isLoading
        ? <SessionSelectionStepLoadingState closeModal={closeModal} />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <SessionSelectionStepSuccessState
                closeModal={closeModal}
                event={initialState.result.data.event}
            />
}

export {
    SessionSelectionStep
}