import { assertNever } from "@helpers/typeHelpers";
import { Cn } from "@helpers/unsorted/classNames";
import {
    useCallback,
    useLayoutEffect,
    useMemo,
    useState,
    forwardRef,
} from "react";
import { motion, HTMLMotionProps, Variants } from "framer-motion";
import { Size } from "../styles";

const styles = {
    container: (size: Size) =>
        Cn.join([
            Cn.c(
                "absolute z-[100] min-w-full w-max bg-white rounded-lg shadow-md"
            ),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("font-paragraph-xsmall-regular");
                    }
                    case "medium": {
                        return Cn.c("font-paragraph-small-regular");
                    }
                    case "large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    case "x-large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    default: {
                        assertNever(size);
                        return "";
                    }
                }
            })(),
        ]),
};

const variants: Variants = {
    enter: {
        visibility: "visible",
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.1,
            easings: "easeOut",
        },
        transformOrigin: "top left",
    },
    exit: {
        transitionEnd: {
            visibility: "hidden",
        },
        opacity: 0,
        scale: 0.8,
        transition: {
            duration: 0.1,
            easings: "easeIn",
        },
        transformOrigin: "top left",
    },
};

type Props = HTMLMotionProps<"div"> & {
    isOpen: boolean;
    size: Size;
    lazy?: boolean;
    lazyBehavior?: "unmount" | "keepMounted";
};

const DropdownMenu = forwardRef<HTMLDivElement, Props>(
    (props, forwardedRef) => {
        const {
            isOpen,
            className = "",
            children,
            size,
            lazy = false,
            lazyBehavior = "unmount",
            ...rest
        } = props;
        const [visible, setVisible] = useState(false);
        const variant = useMemo(() => (isOpen ? "enter" : "exit"), [isOpen]);
        const shouldRender = useMemo(
            () => !lazy || visible || lazyBehavior === "keepMounted",
            [lazy, lazyBehavior, visible]
        );

        useLayoutEffect(() => {
            if (isOpen) {
                setVisible(true);
            }
        }, [isOpen]);

        const handleAnimationComplete = useCallback(() => {
            if (variant === "exit") {
                setVisible(false);
            }
        }, [variant]);

        return (
            <motion.div
                {...rest}
                initial="exit"
                variants={variants}
                animate={variant}
                className={Cn.join([styles.container(size), className])}
                tabIndex={-1}
                ref={forwardedRef}
                onAnimationComplete={handleAnimationComplete}
            >
                {shouldRender && children}
            </motion.div>
        );
    }
);

export { DropdownMenu };
