import { z } from 'zod';

namespace Encoder {
    export interface EncoderType {
        note: string;
    }

    export const defaultValues = (note?: string): EncoderType => {
        return {
            note: note || '',
        };
    }
}

namespace Decoder {
    export const schema = z.object({
        note: z.string().nullish(),
    });

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Encoder,
    Decoder,
};