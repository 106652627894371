import { enGB as enLocale, ja as jaLocale } from 'date-fns/locale';
import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';

import en from './en.json';
import ja from './ja.json';

const translations = { en, ja };

type SupportedLanguage = keyof typeof translations;
// TODO: [REFACTOR] Move this to the .env file
const DEFAULT_LANG: SupportedLanguage = 'en';

const getLocale = (language: SupportedLanguage = DEFAULT_LANG): Locale => {
  switch (language) {
    case 'en':
      return enLocale;
    case 'ja':
      return jaLocale;
  }
};

const isSupportedLanguage = (language: string): language is SupportedLanguage => {
  return Object.keys(translations).includes(language);
};

const getSupportedLanguage = (language: string): SupportedLanguage => {
  return isSupportedLanguage(language) ? language : DEFAULT_LANG
}

const useCurrentLanguage = (): SupportedLanguage => {
  const { i18n } = useTranslation();

  return getSupportedLanguage(i18n.language);
};

const useLanguageChange = () => {
  const { i18n } = useTranslation();

  return (newLanguage: SupportedLanguage) => {
    i18n
      .changeLanguage(newLanguage)
      .then(() => {
        console.info(`Language successfully changed to [${newLanguage}]`);
      });
  };
};

const initOptions: InitOptions = {
  debug: false,
  fallbackLng: DEFAULT_LANG,
  returnNull: false,
  interpolation: { escapeValue: false },
  resources: translations,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initOptions)
  .then();

export {
  SupportedLanguage,
  getLocale,
  getSupportedLanguage,
  isSupportedLanguage,
  translations,
  useCurrentLanguage,
  useLanguageChange,
};

export default i18n;
