import { validations } from "@helpers/unsorted/validation";
import { useTranslation } from "react-i18next";
import { useWatch, UseFormReturn } from "react-hook-form";
import { Encoder } from "./value";

type Input = {
  form: UseFormReturn<Encoder.Type>;
}

const useAccountForm = ({ form }: Input) => {
    const { t } = useTranslation();
    const oldPassword = useWatch({
        control: form.control,
        name: "oldPassword",
    });
    const newPassword = useWatch({
        control: form.control,
        name: "newPassword",
    });

    const validateNewPassword = (input: string, inputs: Encoder.Type) => {
        if (input.length === 0) {
            return;
        }
        if (input === inputs.oldPassword) {
            return "expectedNewPassword";
        }
        if (!validations.password(input)) {
            return "invalidFormat";
        }
    };

    const validateNewPasswordConfirm = (input: string, inputs: Encoder.Type) => {
        if (input !== inputs.newPassword) {
            return t("form.errors.inputMismatch", {
                targetFieldName: t(
                    "settings.account.form.newPassword.title"
                ).toLowerCase(),
            });
        }
    };

    return { t, oldPassword, newPassword, validateNewPassword, validateNewPasswordConfirm }
}

export { useAccountForm };
