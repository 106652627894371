import {
  AllAcquisitionCategories_AllQuery,
  AllEntryCategories_AllQuery,
  AllPrefecturesQuery,
  AllSchoolTypesQuery,
  RecruitmentProcess_OneByIdQuery,
} from '@entities';
import { useCurrentLanguage } from '@helpers/core/i18n';
import { Cn } from '@helpers/unsorted/classNames'
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { Content } from '@shared/unsorted/Panel/Content';
import { Header } from '@shared/unsorted/Panel/Header';
import { Panel } from '@shared/unsorted/Panel/Panel';
import { Entity } from '@typedefs/graphql';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationCandidateDetailPanelContent } from './ApplicationCandidateDetailPanelContent';
import { Button } from '@shared/unsorted/Button/Button';
import { useApplicationCandidateDetailPanel } from './hook';

const styles = {
  panelContainer: Cn.c('min-w-application-detail-panel flex flex-col h-full'),
  headerContainer: Cn.c('shrink-0'),
  applicationDetailHeader: Cn.c('bg-surface-default w-full py-4 pr-6 gap-y-1 flex justify-between'),
  infoContainer: Cn.c('flex flex-col gap-y-1'),
  headerTitle: Cn.c('font-h3-bold text-emphasized'),
  headerSubtitle: Cn.c('text-subdued font-paragraph-xsmall-regular'),
  contentContainer: Cn.c('flex-1 min-h-0 p-4'),
  editButtons: Cn.c('flex flex-row space-x-4'),
};

interface Props extends ModalProps {
  application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
  closePanel: VoidFunction;
  acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[];
  entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[];
  prefectures: Entity<AllPrefecturesQuery, 'prefectures'>[];
  schoolTypes: Entity<AllSchoolTypesQuery, 'schoolTypes'>;
}

const ApplicationCandidateDetailPanel: FunctionComponent<Props> = ({
  application,
  closePanel,
  isOpen,
  acquisitionCategories,
  entryCategories,
  prefectures,
  schoolTypes,
}) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();

  const {
    mode,
    form,
    switchToEditMode,
    switchToListMode,
    onSubmit,
    isClientExaminerOnly,
    isSubmitting,
  } = useApplicationCandidateDetailPanel(application.candidate, acquisitionCategories, entryCategories, prefectures, schoolTypes);

  return (
    <Panel isOpen={isOpen} variant="applicationDetailPanel">
      <form onSubmit={onSubmit} className={styles.panelContainer}>
        <div className={styles.headerContainer}>
          <Header close={closePanel}>
            <div className={styles.applicationDetailHeader}>
              <div className={styles.infoContainer}>
                <span className={styles.headerTitle}>
                  {t("applications.panel.candidateDetailPanel.title")}
                </span>
                <span className={styles.headerSubtitle}>
                  {t("applications.panel.candidateDetailPanel.lastUpdate")}
                  {`${dateFormat.simpleDate(new Date(application.updatedAt), "/", currentLanguage)} ${dateFormat.simpleTime(new Date(application.updatedAt))}`}
                </span>
              </div>
              {mode.name === 'list'
                ?
                !isClientExaminerOnly &&
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => switchToEditMode()}
                >
                  {t("global.edit")}
                </Button>
                : <div className={styles.editButtons}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => switchToListMode()}
                    isDisabled={isSubmitting}
                  >
                    {t("global.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    isDisabled={!form.formState.isDirty}
                    isLoading={isSubmitting}
                  >
                    {t("global.save")}
                  </Button>
                </div>
              }
            </div>
          </Header>
        </div>
        <Content className={styles.contentContainer}>
          <ApplicationCandidateDetailPanelContent
            mode={mode}
            form={form}
            application={application}
          />
        </Content>
      </form>
    </Panel>
  );
}

export {
  ApplicationCandidateDetailPanel,
}