import { ChangeEventHandler, FunctionComponent } from 'react';

import { Cn } from "@helpers/unsorted/classNames";

const checkboxSizes = ['sm', 'md', 'lg'] as const;

type Size = typeof checkboxSizes[number];

const styles = {
    box: (size: string, isDisabled: boolean) => {
        let sizeStyle = Cn.c('');
        switch (size) {
            case 'sm':
                sizeStyle = Cn.c('h-4 w-4 min-w-4 min-h-4');
                break;
            case 'md':
                sizeStyle = Cn.c('h-5 w-5 min-w-5 min-h-5');
                break;
            case 'lg':
                sizeStyle = Cn.c('h-6 w-6 min-w-6 min-h-6');
                break;
        }

        return Cn.join([
            Cn.c('appearance-none cursor-pointer checked:bg-actions-primary-default checked:border-transparent bg-surface-default border-emphasized'),
            isDisabled ? Cn.c('checked:bg-actions-primary-disabled border-subdued') : Cn.c('cursor-pointer checked:bg-actions-primary-default'),
            sizeStyle,
        ]);
    },
};

interface Props {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    size?: Size;
    id: string;
    checked: boolean;
    disabled?: boolean;
}

const Checkbox: FunctionComponent<Props> = ({
    onChange,
    size = 'lg',
    id,
    checked,
    disabled: isDisabled = false,
}) => {
    return (
        <input id={id} type="checkbox" className={styles.box(size, isDisabled)} onChange={onChange} checked={checked} />
    );
};

export {
    Checkbox, Size, checkboxSizes
};
