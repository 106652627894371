import { EventSession_OneByIdQuery } from "@entities"
import { RadioInputItemBoxOptions } from "@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup"
import { Entity } from "@typedefs/graphql"
import { useTranslation } from "react-i18next"
import { GroupDetail } from "./GroupDetail/GroupDetail"
import { useBulkEventSessionAssignmentContext } from "@pages/ApplicationsPage/BulkEventSessionAssignmentModal/BulkEventSessionAssignmentContext"
import { useBulkActionExecution } from "@shared/application/useBulkActionExecution"
import { splitToChunks } from "@helpers/unsorted/arrayExtra"
import { BULK_ACTION_ID_SIZE } from "@pages/ApplicationsPage/ApplicationsTable/BulkActionMenu/hook"
import { useBulkActionModalContext } from "@pages/ApplicationsPage/BulkActionModalContext"
import { useToast } from "@helpers/hooks/unsorted/toastHook"
import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext"
import { SelectedApplications } from "@shared/application/bulkActions/helpers"
import { ExceedingCapacityText } from "./ExceedingCapacityText/ExceedingCapacityText"

const useSessionGroupSelectionStepSuccessState = (session: Entity<EventSession_OneByIdQuery, 'eventSession'>, closeModal: VoidFunction) => {
    const [{ settings }, dispatch] = useBulkEventSessionAssignmentContext()

    const { groupId, applicationIds } = settings

    const { t } = useTranslation()

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const { success: toastSuccess } = useToast()

    const { setApplicationSelection, applicationSelection } = useApplicationSelectionContext()

    const groupOptions: RadioInputItemBoxOptions[] = session.groups.map(group => {
        const currentCapacity = group.recruitmentProcessAssignments.length + (groupId && groupId === group.id && applicationIds ? applicationIds.length : 0)

        return {
            label: <span>
                {t('event.addSession.groupLabel', { count: group.order + 1, ordinal: true })}
            </span>,
            value: group.id,
            description: <GroupDetail group={group} candidateCapacity={session.event.candidateCapacity} />,
            disabled: false,
            helperText: currentCapacity > session.event.candidateCapacity
                ? <ExceedingCapacityText />
                : undefined
        }
    })

    const onGroupChange = (event: unknown) => {
        if (typeof event === 'string') {
            dispatch({
                name: 'SetSettings',
                payload: {
                    settings: {
                        ...settings,
                        groupId: event
                    }
                }
            })
        }

    }

    const action = 'ASSIGN_TO_SESSION_MANUALLY'
    const totalApplications = settings.applicationIds?.length || 0

    const bulkActionPayload = {
        type: 'assignToEventSession',
        groupId: settings.groupId || '',
        recruitmentStepId: settings.currentRecruitmentStepId || ''
    } as const

    const { execute } = useBulkActionExecution({
        action,
        payload: {
            action,
            applicationIdBatches: splitToChunks(settings.applicationIds || [], BULK_ACTION_ID_SIZE),
            bulkActionPayload,
        },
        onStartExecution: () => {
            dispatchBulkActionModalMode({
                name: 'showBulkActionLoadingModal',
                payload: {
                    action,
                    total: totalApplications,
                    processed: 0
                }
            })
        },
        onCompleteExecutingBatch: (result) => {
            dispatchBulkActionModalMode({
                name: 'showBulkActionLoadingModal',
                payload: {
                    action,
                    total: totalApplications,
                    processed: result.totalProcessed
                }
            })
        },
        onCompleteExecution: (result) => {
            if (result.detail.success === totalApplications) {
                closeModal()
                toastSuccess('applications.bulkAction.success')
                setApplicationSelection({})
            }

            if (result.detail.failed > 0) {
                const originalSelectedApplications = applicationSelection[action]

                if (originalSelectedApplications) {
                    setApplicationSelection({
                        ...applicationSelection,
                        [action]: result.detail.failedIds.reduce((acc: SelectedApplications, id) => {
                            acc[id] = originalSelectedApplications[id]

                            return acc
                        }, {})
                    })

                    dispatchBulkActionModalMode({
                        name: 'showBulkEventSessionAssignmentErrorModal',
                        payload: {
                            action,
                            failedIds: result.detail.failedIds,
                            success: result.detail.success,
                            bulkActionPayload: bulkActionPayload,
                            closeModal
                        }
                    })
                }
            }
        },
    })

    return {
        onGroupChange,
        groupId: settings.groupId,
        groupOptions,
        confirm: execute
    }
}

export {
    useSessionGroupSelectionStepSuccessState
}