import { EventSessionGroupLocationType, EventSession_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';

interface EventSessionDetailsHookType {
    canDeleteSessionGroup: boolean;
    onDeleteEventSessionGroup: ({ groupId, toggleMenu }:
        { groupId: string, toggleMenu: VoidFunction }) => void;
    onEditEventSessionGroupLocation: ({ groupId, groupPosition, location, locationType, toggleMenu }: {
        groupId: string;
        groupPosition: number;
        location: string;
        locationType?: EventSessionGroupLocationType | null;
        toggleMenu: VoidFunction;
    }) => void;
    onEditEventSessionGroupExaminers: ({ groupId, examinerAssignments, toggleMenu }: {
        groupId: string;
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[];
        toggleMenu: VoidFunction;
    }) => void;
    onEditEventSessionGroupCandidate: ({ group, toggleMenu }: {
        group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>,
        toggleMenu: VoidFunction
    }) => void;
}

const useEventSessionDetails = ({
    eventSession,
    openDeleteEventSessionGroupConfirmationModal,
    openEditEventSessionGroupLocation,
    openEditEventSessionGroupExaminersModal,
    openEditEventSessionGroupCandidate,
}: {
    eventSession: Entity<EventSession_OneByIdQuery, 'eventSession'>;
    openDeleteEventSessionGroupConfirmationModal: (groupId: string) => void;
    openEditEventSessionGroupLocation: ({ groupId, groupPosition, location, locationType }: {
        groupId: string;
        groupPosition: number;
        location: string;
        locationType?: EventSessionGroupLocationType | null;
    }) => void;
    openEditEventSessionGroupExaminersModal: (
        groupId: string,
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[],
    ) => void;
    openEditEventSessionGroupCandidate: (group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>) => void;
}): EventSessionDetailsHookType => {
    const canDeleteSessionGroup = eventSession.registeredCount === 0 && eventSession.groupCount > 1;

    const onDeleteEventSessionGroup = ({ groupId, toggleMenu, }: {
        groupId: string,
        toggleMenu: VoidFunction
    }) => {
        if (canDeleteSessionGroup) {
            toggleMenu();
            openDeleteEventSessionGroupConfirmationModal(groupId);
        }
    };

    const onEditEventSessionGroupLocation = ({ groupId, groupPosition, location, locationType, toggleMenu }: {
        groupId: string,
        groupPosition: number,
        location: string,
        locationType?: EventSessionGroupLocationType | null,
        toggleMenu: VoidFunction
    }
    ) => {
        toggleMenu();
        openEditEventSessionGroupLocation({ groupId, groupPosition, location, locationType });
    };

    const onEditEventSessionGroupExaminers = ({ groupId, examinerAssignments, toggleMenu }: {
        groupId: string,
        examinerAssignments: Entity<EventSession_OneByIdQuery, 'eventSession.groups.examinerAssignments'>[],
        toggleMenu: VoidFunction,
    }) => {
        toggleMenu();
        openEditEventSessionGroupExaminersModal(groupId, examinerAssignments);
    };

    const onEditEventSessionGroupCandidate = ({ group, toggleMenu }: {
        group: Entity<EventSession_OneByIdQuery, 'eventSession.groups'>,
        toggleMenu: VoidFunction
    }) => {
        toggleMenu();
        openEditEventSessionGroupCandidate(group);
    }

    return {
        canDeleteSessionGroup,
        onDeleteEventSessionGroup,
        onEditEventSessionGroupLocation,
        onEditEventSessionGroupExaminers,
        onEditEventSessionGroupCandidate,
    };
};

export {
    useEventSessionDetails
};
