{
  "translation": {
    "analytics": {
      "title": "Analytics"
    },
    "applications": {
      "activeJobs": "Active Jobs",
      "allJobs": "All Jobs",
      "bulkAction": {
        "updateStepStatus": "Update step status",
        "assignToSession": "Assign to a session...",
        "exportToCSV": "Export selection as CSV file",
        "archive": "Archive active candidates",
        "revertToActive": "Revert archived candidates to active",
        "noArchivedCandidateSelection": "Disabled because there are no archived candidates selected",
        "noActiveCandidateSelection": "Disabled because there are no active candidates selected",
        "noCandidateInSameJobAndStep": "Enable this option by selecting candidates under the same job position and step.",
        "candidate_one": "{{count}} candidate",
        "candidate_other": "{{count}} candidates",
        "ASSIGN_TO_SESSION_MANUALLY": {
          "title": "Assign multiple candidates to a session"
        },
        "ARCHIVE": {
          "title": "Archive",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will be archived as “{{archiveReason}}”. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "COMPLETE_EVALUATION": {
          "title": "Complete Evaluation",
          "description": "You are about to finalize the evaluation for $t(applications.bulkAction.candidate, {\"count\": {{count}} }). Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "CONFIRM_DOCUMENTS": {
          "title": "Confirm Documents",
          "description": "You are about to approve the documents for $t(applications.bulkAction.candidate, {\"count\": {{count}} }). Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "FINALIZE_FAIL": {
          "title": "Finalize Fail",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will be finalised as “FAIL”. Would you like to proceed?",
          "note": "Note: Some candidates were marked as “PASS”. Please make sure that you intend to finalize them as “FAIL”.",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "FINALIZE_PASS": {
          "title": "Finalize Pass",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will be finalised as “PASS”. Would you like to proceed?",
          "note": "Note: Some candidates were marked as “FAIL”. Please make sure that you intend to finalize them as “PASS”.",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "MARK_AS_FAIL": {
          "title": "Mark As Fail",
          "description": "You are about to mark $t(applications.bulkAction.candidate, {\"count\": {{count}} }) as “FAIL”. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "MARK_AS_JOINED": {
          "title": "Mark As Joined",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will be archived and marked as having joined the company. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "MARK_AS_PASS": {
          "title": "Mark As Pass",
          "description": "You are about to mark $t(applications.bulkAction.candidate, {\"count\": {{count}} }) as “PASS”. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "MARK_AS_UNDECIDED": {
          "title": "Mark As Undecided",
          "description": "You are about to mark $t(applications.bulkAction.candidate, {\"count\": {{count}} }) as “UNDECIDED”. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "REGISTER_ATTENDANCE": {
          "title": "Register Attendance",
          "description": "You are about to register the attendance of $t(applications.bulkAction.candidate, {\"count\": {{count}} }). Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "REVERT_TO_PREVIOUS_STATUS": {
          "title": "Revert to Selection",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will be moved back to active selection. Would you like to proceed?",
          "note": "Note: This will resume the selection process for the selected applications. They will each be reverted to the state it was in before being archived.",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "SEND_DOCUMENT_NOTIFICATION": {
          "title": "Send Document Notification",
          "description": "$t(applications.bulkAction.candidate, {\"count\": {{count}} }) will receive a document notification email. Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "SEND_EVENT_ANNOUNCEMENT": {
          "title": "Send Event Announcement",
          "description": "You are about to send an event announcement email to $t(applications.bulkAction.candidate, {\"count\": {{count}} }). Would you like to proceed?",
          "cancel": "No",
          "primaryCTA": "Yes"
        },
        "success": "Processing successful"
      },
      "bulkActionError": {
        "subDescription": {
          "allError": "Failed to perform {{action}}. You can either retry or choose another bulk action.",
          "partialError": "There were candidates that weren’t processed successfully. You can retry performing the bulk action."
        },
        "result": {
          "success": "success",
          "failed": "failed",
          "description": "{{count}} candidates:"
        },
        "note": "<highlight>Note: </highlight>If the processing still fails even after multiple retries, please <contact>contact the Bluumhire team</contact>"
      },
      "bulkActionLoading": {
        "processCandidates": "{{processed}}/$t(applications.bulkAction.candidate, {\"count\": {{total}} }) processed"
      },
      "bulkEventSessionAssignment": {
        "sessionSelection": {
          "title": "Select a session",
          "jobTitle": "Job Title:",
          "currentRecruitmentStep": "Current Recruitment Step:",
          "eventName": "Event Name:",
          "selectSession": "Please select a session.",
          "numOfGroup_one": "1 group",
          "numOfGroup_other": "{{count}} groups",
          "chooseGroup": "Next: Choose a group"
        },
        "sessionGroupSelection": {
          "title": "Choose a group",
          "selectedSession": "Selected session",
          "selectGroup": "Please select a group.",
          "examiners": "Examiners:",
          "moreExaminers": "+{{count}} more",
          "candidates": "Candidates assigned:",
          "candidateCount": "{{registered}}/{{total}} people",
          "backToSessionSelection": "Back to session selection",
          "confirm": "Confirm group assignment",
          "exceedCapacity": "Adding the selected candidates will exceed the maximum number of participants set. It is still possible to add them however, please check in advance to ensure that there are no problems with the actual event operation."
        }
      },
      "bulkEventSessionAssignmentOption": {
        "title_one": "{{count}} of the selected candidates is already assigned to a session group",
        "title_other": "{{count}} of the selected candidates are already assigned to a session group",
        "description": "How would you like to proceed?",
        "proceedAll": "Assign all candidates to a new session group",
        "proceedUnassigned": "Process unassigned candidates only ({{count}})"
      },
      "bulkEventSessionAssignmentError": {
        "description": "Failed to add multiple candidates to session group",
        "subDescription": "Please retry or choose another batch action."
      },
      "bulkEvaluation": {
        "backToCandidateTop": "Back to Candidates Top",
        "title": "Bulk evaluation",
        "steps": {
          "setup": "Setup",
          "errorCorrection": "Error Correction",
          "preview": "Preview",
          "import": "Import"
        },
        "setupStep": {
          "sectionHeader": {
            "uploadFile": "Upload File"
          },
          "templateName": "BluumHireBulkEvaluation.csv",
          "uploadFileRequirement": "It’s required to upload files that follow the Bluumhire format. <highlight>Download Bluumhire template here</highlight>"
        },
        "errorCorrectionStep": {
          "errorDescription": "There were invalid items found in the uploaded file. Download the error items CSV to correct them.",
          "successDescription": "Error items have been corrected. You may proceed with the import process.",
          "downloadErrorFile": "Download CSV file with error logs",
          "sectionHeader": {
            "reuploadFile": "File re-upload"
          },
          "reuploadFileRequirement": "After downloading the error CSV file and correcting the invalid items, re-upload them in CSV format to proceed previewing all items for import.",
          "errorFileName": "errors.csv",
          "errors": {
            "invalidValue_one": "<highlight>{{count}} item</highlight> has fields with incorrect inputs",
            "invalidValue_other": "<highlight>{{count}} items</highlight> have fields with incorrect inputs",
            "invalidFormat_one": "<highlight>{{count}} item</highlight> has field inputs in an incorrect format",
            "invalidFormat_other": "<highlight>{{count}} items</highlight> have field inputs in an incorrect format",
            "missingValue_one": "<highlight>{{count}} item</highlight> has blank fields",
            "missingValue_other": "<highlight>{{count}} items</highlight> have blank fields"
          }
        },
        "previewStep": {
          "sectionHeader": {
            "tablePreview": ""
          },
          "totalCount": "Total number of items to process: <highlight>{{count}}</highlight>",
          "tableHeaders": {
            "rowNumber": "Row Number",
            "candidateEmail": "Candidate Email",
            "candidateName": "Candidate Name",
            "respondentEmail": "Respondent Email",
            "question": "Question",
            "answer": "Answer"
          },
          "rowsPerPage": "Rows per page",
          "backToSetup": "Back to Setup",
          "backToErrorCorrection": "Back to Error Correction",
          "startImport": "Start Importing"
        },
        "importStep": {
          "inProgress": {
            "importingFile": "Importing file",
            "progressText": "{{current}}/{{total}} items processed"
          },
          "success": {
            "importSuccess": "Import successful",
            "description": "{{count}} items have been successfuly added .",
            "viewCandidateList": "View candidate list",
            "importAnotherFile": "Import another file"
          },
          "failure": {
            "importFailed": "File import failed",
            "description": "An error occurred during import. Please try again.\nIf this issue continues to occur, please report it to the Bluumhire Team.",
            "retry": "Retry",
            "contactUs": "Contact Us"
          }
        },
        "moveToPreview": "Move to Preview"
      },
      "csvImport": {
        "backToCandidateTop": "Back to Candidates Top",
        "switchToManualInput": "Switch to manual Input",
        "title": "CSV File Import",
        "steps": {
          "setup": "Setup",
          "errorCorrection": "Error Correction",
          "preview": "Preview",
          "import": "Import"
        },
        "setupStep": {
          "sectionHeader": {
            "importSettings": "Import Settings",
            "uploadFile": "Upload File"
          },
          "templateName": "BluumHireCandidateImport.csv",
          "uploadFileRequirement": "It’s required to upload files that follow the Bluumhire format. <highlight>Download Bluumhire template here</highlight>"
        },
        "errorCorrectionStep": {
          "errorDescription": "There were invalid items found in the uploaded file. Download the error items CSV to correct them.",
          "successDescription": "Error items have been corrected. You may proceed with the import process.",
          "downloadErrorFile": "Download CSV file with error logs",
          "sectionHeader": {
            "reuploadFile": "File re-upload"
          },
          "reuploadFileRequirement": "After downloading the error CSV file and correcting the invalid items, re-upload them in CSV format to proceed previewing all items for import.",
          "errorFileName": "errors.csv",
          "errors": {
            "invalidValue_one": "<highlight>{{count}} candidate</highlight> has fields with incorrect inputs",
            "invalidValue_other": "<highlight>{{count}} candidates</highlight> have fields with incorrect inputs",
            "invalidFormat_one": "<highlight>{{count}} candidate</highlight> has field inputs in an incorrect format",
            "invalidFormat_other": "<highlight>{{count}} candidates</highlight> have field inputs in an incorrect format",
            "missingValue_one": "<highlight>{{count}} candidate</highlight> has blank fields",
            "missingValue_other": "<highlight>{{count}} candidates</highlight> have blank fields"
          }
        },
        "uploadArea": {
          "clickToUpload": "Click to upload",
          "dragDrop": "or drag and drop",
          "fileNote": "File should be in CSV format and less than {{size}}."
        },
        "previewStep": {
          "sectionHeader": {
            "importSettings": "Import Settings",
            "tablePreview": "Candidate Table Preview"
          },
          "jobPosition": "Job Position:",
          "acquisitionChannel": "Acquisition Channel:",
          "entryChannel": "Entry Channel:",
          "recruitmentStep": "Recruitment Step:",
          "changeImportSettings": "Change import settings",
          "totalCount": "Total number of candidates for import: <highlight>{{count}}</highlight>",
          "tableHeaders": {
            "rowNumber": "Row Number",
            "candidateName": "Candidate Name",
            "university": "University"
          },
          "rowsPerPage": "Rows per page",
          "backToSetup": "Back to Setup",
          "backToErrorCorrection": "Back to Error Correction",
          "startImport": "Start Importing"
        },
        "importStep": {
          "inProgress": {
            "importingFile": "Importing file",
            "progressText": "{{current}}/{{total}} candidates processed"
          },
          "success": {
            "importSuccess": "Import successful",
            "description": "{{count}} candidates have been successfuly added .",
            "viewCandidateList": "View candidate list",
            "importAnotherFile": "Import another file"
          },
          "failure": {
            "importFailed": "File import failed",
            "description": "An error occurred during import. Please try again.\nIf this issue continues to occur, please report it to the Bluumhire Team.",
            "retry": "Retry",
            "contactUs": "Contact Us"
          }
        },
        "moveToPreview": "Move to Preview"
      },
      "create": {
        "manualInput": {
          "title": "Manual Input",
          "switchToCSVImport": "Switch to CSV Import",
          "backToCandidateTop": "Back to Candidates Top",
          "label": {
            "dateOfBirth": "Date of Birth",
            "email": "Email",
            "entryStatus": "Entry Status",
            "expectedGraduationMonth": "Expected Graduation Month",
            "expectedGraduationYear": "Expected Graduation Year",
            "firstName": "First Name",
            "firstNameKana": "First Name (Hiragana)",
            "gender": "Gender",
            "graduationMonth": "Graduation Month",
            "graduationYear": "Graduation Year",
            "lastName": "Last Name",
            "lastNameKana": "Last Name (Hiragana)",
            "name": "Name",
            "nameKana": "Name Katakana",
            "phoneNumber": "Phone Number"
          }
        },
        "button": "Add candidates",
        "buttons": {
          "add": "Add Candidate "
        },
        "entryStatus": {
          "afterEntry": "After entry",
          "beforeEntry": "Before entry (Information acquisition only)"
        },
        "label": {
          "jobPosition": "Job Position",
          "jobPositionOptionDisabledTooltip": "This job position cannot be selected because there are unconfigured steps associated with it. Please ensure all steps are configured before attempting to select this job position.",
          "jobPositionUnconfiguredStepsTooltip": "Some job positions below cannot be selected because they are not fully configured. If you want to select any of these jobs, please first make sure you finalise them (set events, add forms).",
          "recruitmentStep": "Recruitment Step",
          "recruitmentStepDisabledTooltip": "Make sure to select a Job Position before choosing a Recruitment Step.",
          "entryCategory": "Entry Channel Category",
          "entryChannel": "Entry Channel",
          "entryChannelDisabledTooltip": "Make sure to select an Entry Channel Category before choosing an Entry Channel.",
          "informationCategory": "Acquisition Channel Category",
          "informationChannel": "Acquisition Channel",
          "informationChannelDisabledTooltip": "Make sure to select an Acquisition Channel Category before choosing an Acquisition Channel."
        },
        "jobPositionExplanation": {
          "shortText": "Incomplete jobs cannot be selected. <highlight>Learn more</highlight>",
          "title": "Why can’t I select the job title I want?",
          "description": {
            "main": "If the job title you’re looking for cannot be selected, you need to finish setting it up in the “Jobs” tab by:",
            "step1": "1. Completing the event setup for all “Event” type steps",
            "step2": "2. Email templates (where needed) are properly assigned for all steps"
          }
        },
        "modes": {
          "csv": "Import with BluumHire CSV",
          "manual": "Add through manual input",
          "medium": "Import with a file exported in each medium"
        },
        "modal": {
          "description": "Choose a method for adding candidates",
          "options": {
            "csv": {
              "label": "CSV File Import (recommended)",
              "description": "Add multiple candidates using the Bluumhire template CSV file."
            },
            "manual": {
              "label": "Manual Input",
              "description": "Add candidates one-by-one manually"
            }
          },
          "title": "Add candidates"
        },
        "sectionHeader": {
          "applicationInformation": "Application Information",
          "basicInformation": "Basic Information",
          "candidateInformation": "Candidate Information"
        },
        "title": "Add a candidate"
      },
      "defaultSteps": {
        "all": "All",
        "entry": "Entry",
        "offer": "Job Offer",
        "offerAccepted": "Job Offer Accepted",
        "ongoing": "Ongoing"
      },
      "deleteDetailModal": {
        "buttons": {
          "delete": "Delete"
        },
        "evaluation": {
          "confirmation": "Are you sure to delete the evaluation?",
          "description": "The evaluation of {{recruitmentStepName}} will be deleted. Deletion of evaluation cannot be undone.",
          "error": "Failed in Deleting Evaluation",
          "success": "Deleted Evaluation",
          "title": "Are you sure to delete the evaluation?"
        },
        "screeningDetail": {
          "confirmation": "Are you sure to delete the screening detail?",
          "description": "The screening detail of {{recruitmentStepName}} will be deleted. Deletion of screening detail cannot be undone.",
          "error": "Failed in deleting screening detail",
          "success": "Deleted screening detail",
          "title": " Are you sure to delete the screening detail?"
        }
      },
      "errors": {
        "appliedCandidateEmail": "Candidate email has been already applied in other job position",
        "invalidCandidateData": "Invalid candidate data provided"
      },
      "exportCandidates": {
        "button": "Candidates CSV Export",
        "filename": "Bluum_{{action}}CandidateList_{{date}}.csv",
        "actions": {
          "all": "Export all candidates",
          "active": "Export active candidates only",
          "archive": "Export archived candidates only"
        }
      },
      "csvBulkActions": {
        "button": "CSV Bulk Actions",
        "actions": {
          "bulkEvaluation": "Bulk Evaluation",
          "bulkScheduling": "Bulk scheduling"
        }
      },
      "filters": {
        "candidateStatus": "Candidate Status",
        "candidateStatusOptions": {
          "ONGOING": "Active",
          "ARCHIVED": "Archived"
        },
        "clear": "Clear",
        "unavailableReason": {
          "unavailableOnArchived": "Display active candidates to enable this field."
        },
        "emptySearch": "No entries match your search criteria. Please search with different keywords.",
        "jobTitle": "Job Title",
        "recruitmentStage": "Recruitment Stage",
        "recruitmentStepStatus": "Recruitment Step Status",
        "recruitmentStepStatusGroups": {
          "GENERAL": "General",
          "DOCUMENT": "Document",
          "EVENT": "Event",
          "ARCHIVE_REASON": "Archived as"
        },
        "searchPlaceholder": "Search",
        "stepName": "Step Name",
        "stepNameDisabledTooltip": "Select one “Job Title” to enable this field.",
        "university": "School Name"
      },
      "list": {
        "all": "All",
        "candidate_one": "<highlight>{{count}}</highlight> Candidate",
        "candidate_other": "<highlight>{{count}}</highlight> Candidates",
        "candidatesCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> of $t(applications.list.candidate, {\"count\": {{count}} })",
        "displayCount": "people displayed",
        "emptyNoJob": {
          "description": "Job creation is required to add candidates",
          "subDescription": "There are currently no published job positions. Please create a job position first from the Jobs page."
        },
        "emptyPastApplication": {
          "description": "No archived candidates",
          "subDescription": "There are currently no archived candidates."
        },
        "emptyUnderSelection": {
          "description": "No active candidates",
          "subDescription": "There are currently no active candidates."
        },
        "emptyAll": {
          "description": "No candidates have been added",
          "subDescription": "Add a candidate and start managing the selection process."
        },
        "errors": {
          "appliedCandidateEmail": "Candidate email has been already applied in other job position",
          "invalidCandidateData": "Invalid candidate data provided"
        },
        "filterButton": "Filter",
        "filterButtonCounter_one": "{{count}} item applied",
        "filterButtonCounter_other": "{{count}} items applied",
        "filterButtonCounter_zero": "",
        "mismatchedUnderSelection": {
          "description": "No search results",
          "subDescription": "No entries match your query, please try a different keyword."
        },
        "orderBy": "Order by",
        "people": "people",
        "searchPlaceholder": "Search by Candidate Name",
        "selected": "{{count}} selected",
        "sendAnnouncement": "Send Announcement",
        "sort": {
          "candidateNameAsc": "By Candidate Name (A-Z)",
          "candidateNameDesc": "By Candidate Name (Z-A)",
          "createdAtAsc": "Date added (ascending)",
          "createdAtDesc": "Date added (descending)",
          "updatedAtAsc": "Order by Update Date Asc",
          "updatedAtDesc": "Last updated"
        },
        "subtitle": "You can check the added candidates, add new candidates, and manage the selection process.",
        "table": {
          "active": "Active",
          "allApplicationsSelected_one": "All {{count}} candidate selected",
          "allApplicationsSelected_other": "All {{count}} candidates selected",
          "applicationSelected_one": "{{count}} candidate selected",
          "applicationSelected_other": "{{count}} candidates selected",
          "archiveActions": "Archive",
          "archived": "Archived",
          "bulkActions": "Actions",
          "bulkActionsDisabledTooltip": "No bulk actions available for the selected items",
          "recruitmentState": "Recruitment State",
          "currentStep": "Current Step",
          "deselectAllApplication_one": "Deselect all {{count}} candidate",
          "deselectAllApplication_other": "Deselect all {{count}} candidates",
          "deselectApplicationOnThisPage_one": "Deselect {{count}} candidate in this page",
          "deselectApplicationOnThisPage_other": "Deselect {{count}} candidates in this page",
          "entryChannel": "Entry Channel",
          "jobTitle": "Job Title",
          "name": "Name",
          "noBulkAction": "No bulk action",
          "recruitmentStage": "Recruitment Stage",
          "selectAllCandidates_one": "Select all candidates ({{count}} candidate)",
          "selectAllCandidates_other": "Select all candidates ({{count}} candidates)",
          "stepStatus": "Status",
          "university": "School Name"
        },
        "title": "Candidates",
        "totalCount": "people in total:"
      },
      "newCandidate": "Add candidate",
      "panel": {
        "addTag": "Add a tag",
        "candidateApplicationInformation": {
          "acquisitionCategory": "Acquisition Category",
          "acquisitionChannel": "Acquisition Channel",
          "afterEntry": "After entry",
          "beforeEntry": "Before entry (Information acquisition only)",
          "edit": {
            "label": {
              "entryCategory": "Entry Category",
              "entryChannel": "Entry Channel"
            },
            "placeholder": {
              "entryCategory": "",
              "entryChannel": ""
            }
          },
          "entryCategory": "Entry Category",
          "entryChannel": "Entry Channel",
          "entryDate": "Entry Date",
          "entryStatus": "Entry Status",
          "title": "Application Information"
        },
        "candidateApplicationInformationDisplay": {
          "entryChannel": "Entry Channel",
          "informationAcquisition": "Information Acquisition"
        },
        "candidateBasicInformation": {
          "currentAddress": "Address",
          "dateOfBirth": "Date of Birth",
          "edit": {
            "currentAddress": "Current Address Detail",
            "errors": {
              "update": "Failed to update candidate information."
            },
            "phoneNumber": "Current Address Phone Number",
            "placeholder": {
              "currentAddress": "City, street, building name",
              "email": "Ex) hello.bluum@email.com",
              "furigana": "Ex: スミス ジョン",
              "name": "Ex: Smith John",
              "phoneNumber": "Ex) 09012349876",
              "zipCode": "Ex) 09012345678"
            },
            "prefecture": "Prefecture",
            "residenceCountry": "Country of Residence",
            "successes": {
              "update": "Successfully updated candidate information."
            },
            "zipCode": "Zip Code"
          },
          "email": "Email",
          "furigana": "Name (Katakana)",
          "gender": "Gender",
          "mobilePhoneNumber": "Mobile Number",
          "name": "Name",
          "overseas": "Overseas",
          "phoneNumber": "Phone Number",
          "title": "Candidate Information"
        },
        "candidateDetailPanel": {
          "lastUpdate": "Last update: ",
          "navigation": {
            "applicationInformation": "Application Information",
            "candidateInformation": "Candidate Information",
            "contact": "Contact",
            "jobHistory": "Job History",
            "other": "Other",
            "schoolInformation": "School Information"
          },
          "title": "Candidate Profile"
        },
        "candidateDocuments": {
          "download": "Download",
          "downloadAll": "Download All",
          "empty": {
            "description": "No document attached yet",
            "subDescription": "You can upload documents by clicking on [Edit]"
          },
          "title": "Attached Documents",
          "uploadSelectButton": "Select files",
          "uploadSelectFileInstruction": "File format: jpeg, jpg, png, bmp, pdf, xlsx, docx, pptx",
          "uploadSelectSizeInstruction": "Size: < {{size}}",
          "uploadSelectTitle": "Select a file and upload"
        },
        "candidateEmail": {
          "addCcBcc": "Add Cc/Bcc",
          "bcc": "Bcc",
          "cc": "Cc",
          "content": "Content",
          "createEmail": "Create Email",
          "date": "Date",
          "emptyDescription": "No emails yet",
          "emptySubDescription": "Communication via email regarding this application will appear here once available.",
          "errors": {
            "sendEmail": "Couldn't send email."
          },
          "from": "From",
          "received": "Received：",
          "reply": "Reply",
          "sendEmail": "Send Email",
          "sent": "Sent：",
          "subject": "Subject",
          "successes": {
            "sendEmail": "Successfully sent an email."
          },
          "title": "Send an email",
          "to": "To"
        },
        "candidateHistory": {
          "decision": {
            "final": {
              "FAILED": "FAIL",
              "PASSED": "PASS"
            },
            "preliminary": {
              "FAILED": "Marked as Fail (pending)",
              "PASSED": "Marked as Pass (pending)",
              "UNDECIDED": "Marked as Undecided"
            }
          },
          "documentConfirmed": "Confirmed",
          "labels": {
            "currentStatus": "Status",
            "decision": "Decision",
            "documentStatus": "Document Status",
            "evaluation": "Evaluation",
            "examiners": "Examiners",
            "schedule": "Schedule"
          },
          "pending": "Pending",
          "viewEvaluations": "View Evaluations"
        },
        "candidateInformationUpdated": "Candidate information successfully updated",
        "candidateJobHistoryInformation": {
          "departmentAndPositionTitle": "Job Title / Department",
          "edit": {
            "errors": {
              "update": "Failed to update candidate information."
            },
            "placeholder": {
              "departmentAndPositionTitle": "Job Title / Department",
              "lastJobPosition": "Company Name"
            },
            "successes": {
              "update": "Successfully updated candidate information."
            }
          },
          "lastJobPosition": "Last place of employment",
          "title": "Job History"
        },
        "candidateOtherContactInformation": {
          "edit": {
            "errors": {
              "update": "Failed to update candidate information."
            },
            "placeholder": {
              "mobileEmail": "Ex) hello.bluum@mobile.com",
              "vacationAddress": "City, street, building name",
              "vacationPhoneNumber": "Ex) 0312345678",
              "vacationZipcode": "Ex) 1234567"
            },
            "successes": {
              "update": "Successfully updated candidate information."
            },
            "vacationAddress": "Alternate Address Details",
            "vacationPrefecture": "Prefecture",
            "vacationZipcode": "Alternate Address Zip Code"
          },
          "mobileEmail": "Alternate Email",
          "title": "Other Contact Information",
          "vacationContactAddress": "Alternate Address",
          "vacationPhoneNumber": "Alternate Phone Number"
        },
        "candidateOtherInformation": {
          "edit": {
            "errors": {
              "update": "Failed to update candidate information."
            },
            "successes": {
              "update": "Successfully updated candidate information."
            }
          },
          "remarks": "Remarks",
          "title": "Other"
        },
        "candidateSchoolInformation": {
          "clubActivity": "Club activity",
          "edit": {
            "errors": {
              "update": "Failed to update candidate information."
            },
            "expectedGraduationMonth": "Expected Graduation Month",
            "expectedGraduationYear": "Expected Graduation Year",
            "graduationMonth": "Graduation Month",
            "graduationYear": "Graduation Year",
            "placeholder": {
              "graduationYear": "Ex) 2022"
            },
            "schoolType": "School Type",
            "successes": {
              "update": "Successfully updated candidate information."
            }
          },
          "expectedGraduation": "Expected Graduation Year & Month",
          "fieldType": "Humanities and Science classification",
          "graduationYearMonth": "{{month}}/{{year}}",
          "major": "Major",
          "researchTheme": "Major/Research Theme",
          "school": "School",
          "seminarLab": "Seminar/ Laboratory",
          "title": "School Information"
        },
        "currentStep": "Current Step: ",
        "editTags": {
          "error": "Failed to save tags",
          "success": "Tags successfully saved"
        },
        "email": "Email",
        "entryChannel": "Entry Channel: ",
        "evaluateApplication": {
          "backToEvaluation": "Go back",
          "cancel": "Cancel evaluation",
          "cancellationDescription": "Your current changes will be lost if you cancel.\n\nAre you sure you want to cancel evaluation?",
          "cancellationTitle": "Cancel Evaluation",
          "error": "Couldn't save evaluation",
          "saveDraft": "Save Draft",
          "submissionDescription": "You're about to submit your evaluation. Once submitted, you can no longer make changes.\n\nAre you sure you want to proceed?",
          "submissionTitle": "Submit Evaluation",
          "submit": "Submit evaluation",
          "success": "Evaluation successfully submitted",
          "title": "Candidate evaluation"
        },
        "fillEvaluation": {
          "error": "No evaluation form registered"
        },
        "finalizeDecision": {
          "description": "Please choose the reason for the fail decision.",
          "error": "Couldn't finalize decision",
          "success": "Decision has been finalized",
          "title": "Finalize Decision",
          "warning": "This candidate was marked as {{preliminaryResult}}. Please make sure that you intended to finalize them as {{finalResult}}."
        },
        "graduationYearMonth": "Expected to graduate in {{month}}/{{year}}",
        "moreInfo": "More Info",
        "note": {
          "add": "Add Notes",
          "error": "Couldn't save notes.",
          "success": "Successfully saved notes.",
          "title": "Note"
        },
        "recruitmentStepArchiveActions": "Archive as",
        "recruitmentStepFinalizeDecision": "Finalize decision",
        "recruitmentStepMainActions": "Mark candidate",
        "recruitmentStepOtherActions": "More Actions",
        "revertApplication": {
          "buttons": {
            "confirm": "Revert to selection"
          },
          "description": "Click \"Revert to selection\" to resume the selection process for this application. It will be reverted to the state it was in before being archived.",
          "error": "Couldn't revert to the selection.",
          "success": "Reverted to Under Selection",
          "title": "Resume selection"
        },
        "revertToActive": "Revert to active",
        "saveTag": "Save",
        "scheduleEvent": "Schedule this event",
        "tabs": {
          "candidateInfo": "Candidate Info",
          "document": "Documents",
          "email": "Email Communication",
          "history": "History"
        },
        "updated": "Updated Date: ",
        "viewApplicationEvaluations": {
          "noEvaluation": "No evaluations yet",
          "respondents": "respondent(s):",
          "title": "Evaluations"
        }
      },
      "pastApplication": {
        "all": "All",
        "closedOther": "Other Reason",
        "declinedContinueCurrentJob": "Declined (Continuation of Current Job)",
        "declinedJoinedOtherCompany": "Declined (Joined Other Company)",
        "declinedOthers": "Declined (Others)",
        "emptyTable": "No selection for the application has been finished yet.",
        "joined": "Joined",
        "noReply": "No Reply / Missing Interview",
        "rejectedCultureMismatched": "Rejected (Culture Mismatched)",
        "rejectedOthers": "Rejected  (Others）",
        "rejectedSkillMismatched": "Rejected (Skill Mismatched)"
      },
      "subMenu": {
        "active": "Active",
        "archived": "Archived",
        "jobPositionFilter": {
          "buttonLabel": "Clear All"
        },
        "noArchivedApplications": "There are no archived applications",
        "noActiveApplication": "There is no active application",
        "activeApplication": "Active"
      }
    },
    "calendar": {
      "title": "Calendar"
    },
    "comingSoon": {
      "contactUs": "Contact Us",
      "description": "{{feature}} Feature Coming Soon!",
      "subDescription": "We're still currently developing this part of the app. Feel free to contact us if you're curious to know about how far along we are in the process!"
    },
    "csvExportErrorModal": {
      "description": "Candidate list export failed",
      "subDescription": "An error occurred while preparing the export file. Please try again later. If this issue continues to occur, please report it to the BluumHire Team."
    },
    "csvExportingModal": {
      "actions": {
        "active": "Active",
        "archived": "Archived"
      },
      "description": "Export {{action}}Candidate List",
      "exportedItems": "{{count}} items",
      "subDescription": "Exporting the candidate list may take some time. Please don't close or refresh the page while the CSV file is being prepared."
    },
    "dashboard": {
      "comingSoon": "Coming soon",
      "recentActivity": "Recent activity",
      "stats": {
        "noChange": "No change",
        "trendDataLabel": "from last month",
        "trendDataValue": "({{value}}%)",
        "viewDetails": "View details"
      },
      "title": "Dashboard",
      "upcomingEvents": {
        "noEvent": "No upcoming events",
        "title": "Upcoming events",
        "participant_one": "{{count}} participant,",
        "participant_other": "{{count}} participants,"
      },
      "activities": {
        "noActivity": "No recent activity"
      },
      "dateRanges": {
        "all": "All",
        "today": "Today",
        "thisWeek": "This week",
        "thisMonth": "This month",
        "within3Months": "Within 3 months",
        "within6Months": "Within 6 months",
        "within1Year": "Within 1 year",
        "atleast1Year": "More than a year"
      }
    },
    "devLogin": {
      "email": "Email",
      "password": "Password"
    },
    "documentUpload": {
      "instruction": "Please upload the required documents here.",
      "title": "Candidate documents"
    },
    "emails": {
      "templates": {
        "variables": {
          "descriptions": {
            "candidate_name": "[candidate_name]",
            "company_name": "[company_name]",
            "document_upload_deadline": "[document_upload_deadline]",
            "document_upload_url": "[document_upload_url]",
            "evaluation_deadline": "[evaluation_deadline]",
            "evaluation_url": "[evaluation_url]",
            "event_contact": "[event_contact]",
            "event_date_time": "[event_date_time]",
            "event_examiner_location": "[event_examiner_location]",
            "event_location": "[event_location]",
            "event_page_url": "[event_page_url]",
            "examiner_name": "[examiner_name]",
            "external_signature": "[external_signature]",
            "internal_signature": "[internal_signature]",
            "job_name": "[job_name]",
            "reservation_deadline": "[reservation_deadline]",
            "reservation_url": "[reservation_url]",
            "step_name": "[step_name]"
          },
          "names": {
            "candidate_name": "Candidate name",
            "company_name": "Company name",
            "document_upload_deadline": "Document upload deadline",
            "document_upload_url": "Document upload URL",
            "evaluation_deadline": "Evaluation deadline",
            "evaluation_url": "Evaluation URL",
            "event_contact": "Event contact",
            "event_date_time": "Event datetime",
            "event_examiner_location": "Event examiner location",
            "event_location": "Event location",
            "event_page_url": "Event page URL",
            "examiner_name": "Examiner name",
            "external_signature": "External signature",
            "internal_signature": "Internal signature",
            "job_name": "Job name",
            "reservation_deadline": "Reservation deadline",
            "reservation_url": "Reservation URL",
            "step_name": "Step name"
          },
          "samples": {
            "candidate_name": "Taylor Smith",
            "company_name": "Bluum Co., Ltd.",
            "document_upload_deadline": "July 05th 23:00",
            "document_upload_url": "https://bluum.jp/document-upload?application_id=abcd1234-abcd-1234-abcd-1234abcd1234&step_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "evaluation_deadline": "July 17th 23:00",
            "evaluation_url": "https://forms.gle/AbCd1234aBcD1234A",
            "event_contact": "030-1234-5678",
            "event_date_time": "July 12th 13:00-14:00",
            "event_examiner_location": "Meeting room A",
            "event_location": "Tokyo-to, Shibuya-ku, Ebisu 1-23-23",
            "event_page_url": "https://bluum.jp/job-positions?mode=show&id=abcd1234-abcd-1234-abcd-1234abcd1234&event_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "examiner_name": "Jordan Lee",
            "external_signature": "Bluum Co., Ltd. New Graduate Recruitment Team\nrecruit@bluum.com",
            "internal_signature": "Recruitment Team @recruitment",
            "job_name": "General Employee (Science/Engineering)",
            "reservation_deadline": "July 05th 23:00",
            "reservation_url": "https://bluum.jp/event_reservation?email_identifier=abcd1234-abcd-1234-abcd-1234abcd1234&application_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "step_name": "Group Discussion"
          }
        }
      }
    },
    "event": {
      "format": {
        "offline": "In-person",
        "online": "Online"
      },
      "addSession": {
        "candidateCapacity": "Max. candidate per group",
        "candidateCapacityUnit": "people",
        "createFailed": "Failed to add the event session",
        "createSuccessfully": "Event session added",
        "duration": "Duration",
        "durationUnit": "mins",
        "endTime": "End time",
        "endTimeTooltip": "The end time is automatically filled based on the \"Duration\" set at the time of the event creation.",
        "examinersLabel": "Examiners",
        "format": "Format",
        "groupCount": "Number of groups",
        "groupCountTooltip": "If you wish to hold multiple event sessions on the same day and at the same time, change the number of groups to 2 or more.",
        "groupLabel_few": "{{count}}rd Group",
        "groupLabel_one": "{{count}}st Group",
        "groupLabel_other": "{{count}}th Group",
        "groupLabel_two": "{{count}}nd Group",
        "locationOfflineLabel": "Session Location",
        "locationOnlineLabel": "Session URL",
        "placeholders": {
          "locationOffline": "Enter session location",
          "locationOnline": "Enter session URL"
        },
        "startDate": "Date",
        "startTime": "Start time",
        "title": "Add Event Session"
      },
      "addSessionGroup": {
        "createFailed": "Failed to create new session group",
        "createSuccessfully": "Successfully created new session group",
        "title": "Add Session Group"
      },
      "eventDeleteConfirmationModal": {
        "deleteEvent": "Delete event",
        "deleteEventFailed": "Failed to delete event",
        "deleteEventSuccessfully": "Successfully deleted event",
        "description": "Delete event",
        "subDescription": "Are you sure you want to delete the event “{{eventName}}”? Deleting this event cannot be undone."
      },
      "eventDetail": {
        "addSession": "Add session",
        "days": "{{count}} day",
        "days_other": "{{count}} days",
        "displayNameLabel": "Display name for candidates",
        "empty": {
          "description": "There are currently no sessions",
          "subDescription": "A session is needed to assign a candidate to an event."
        },
        "eventSessionManagement": "Event session management",
        "eventSettings": "Event settings",
        "formatLabel": "Format",
        "hideCompletedSessions": "Hide completed sessions",
        "maxCapacity": "{{count}} people",
        "maxCapacityLabel": "Max. number of candidates per group",
        "reservationDeadline": "$t(event.eventDetail.days, {\"count\": {{days}} }) before, until {{time}}:00",
        "reservationDeadlineLabel": "Reservation change/cancellation deadline",
        "table": {
          "additionTypes": {
            "auto": "Auto",
            "manual": "Manual"
          },
          "availabilityStatuses": {
            "completed": "Completed",
            "ongoing": "Ongoing",
            "upcoming": "Upcoming"
          },
          "header": {
            "additionType": "Type of addition",
            "eventSession": "Event Session",
            "groupsCount": "Number of groups",
            "reservedCandidatesCount": "Number of reserved candidates",
            "status": "Status"
          },
          "reservedCandidates": "{{registered}} / {{total}} people"
        }
      },
      "eventSession": {
        "eventSessionGroup": {
          "name_few": "{{count}}rd Group",
          "name_one": "{{count}}st Group",
          "name_other": "{{count}}th Group",
          "name_two": "{{count}}nd Group"
        },
        "addGroup": "Add group",
        "candidateCapacity": "{{count}}",
        "candidateCapacityLabel": "Candidate capacity",
        "groupCount_one": "{{count}} group",
        "groupCount_other": "{{count}} groups",
        "groupCountLabel": "Number of groups",
        "sessionGroup": "Session Group",
        "table": {
          "applicantsCount": "{{registered}} / {{total}} people",
          "copyToClipboard": {
            "copiedTooltip": "Copied",
            "copyTooltip": "Copy Link"
          },
          "extraMenu": {
            "delete": "Delete session group",
            "editCandidates": "Edit candidates",
            "editExaminers": "Edit examiners",
            "editLocation": "Edit location",
            "tooltip": {
              "candidateRegisteredTooltipText": "If the examiner or candidate has already been scheduled to attend, the event session group cannot be deleted.",
              "editTooltipText": "You cannot edit a group of an event session that have already taken place.",
              "lastGroupTooltip": "This group cannot be deleted because at least one event dates group is required. You may delete the entire session if you no longer need this session."
            }
          },
          "header": {
            "action": "Action",
            "applicants": "Applicants (Confirmed {{count}})",
            "applicantsTooltip": "Participation will be confirmed as soon as they respond to the schedule.",
            "examiner": "Examiner",
            "location": "Location",
            "sessionUrl": "Session URL"
          },
          "notAssigned": "Not assigned"
        }
      },
      "eventSessionGroupAddCandidateModal": {
        "appliedJobPositionLabel": "Applied Job Position",
        "candidateToAdd": "Select candidates to add:",
        "candidateToAddPlaceholder": "Please select candidates to add",
        "currentRecruitmentStep": "Current Recruitment Step",
        "description": "You can manually add candidates to the event session group by selecting candidates who meet the following criteria:",
        "emailDelivery": "2. Event reservation email delivery",
        "error": "Candidates could not be added",
        "selectCandidate": "1. Select Candidates",
        "sendEventReservation": "Send event reservation to selected candidates",
        "sendEventReservationTooltip": "Checking the \"Send\" checkbox allows you to send the email that is normally sent to candidates when booking an event to the selected candidates upon addition.",
        "success": "Candidates added successfully",
        "title": "Add Candidates to Group",
        "warning": "Adding the selected candidates will exceed the maximum number of participants set. It is still possible to add them however, please check in advance to ensure that there are no problems with the actual event operation."
      },
      "eventSessionGroupDeleteModal": {
        "description": "Do you want to delete this session group?",
        "error": "Failed to delete a group",
        "subDescription": "Are you sure you want to proceed? A deleted group cannot be restored.",
        "success": "Group successfully deleted"
      },
      "eventSessionGroupManipulateCandidatesModal": {
        "action": "Action",
        "addCandidate": "Add candidates",
        "allocatedCandidate": "Allocated candidates",
        "candidateCount": "{{registered}}/{{total}} people",
        "examiners": "Examiners",
        "location": "Location for examiners",
        "noAllocatedCandidates": "Currently no allocated candidates",
        "reservedCandidates": "Reserved Candidates",
        "title": "Edit Candidates"
      },
      "eventSessionGroupManipulateExaminersModal": {
        "error": "Examiners could not be updated.",
        "examinersLabel": "Examiners",
        "success": "Examiners have been updated.",
        "title": "Edit Examiners"
      },
      "eventSessionGroupManipulateLocationModal": {
        "errors": {
          "update": "The location could not be updated."
        },
        "successes": {
          "update": "Location has been updated."
        },
        "title": "Edit Location"
      },
      "eventSessionGroupRemoveCandidateModal": {
        "cancellationEmail": "Send reservation cancelled email to candidate",
        "cancellationEmailTooltip": "Checking the \"Send\" box allows you to send this candidate an email that would normally be sent to the candidate upon cancellation of an event at the same time as the reservation is cancelled.",
        "description": "Do you want to remove this candidate from the group?",
        "failedToRemove": "Failed to remove candidate assignment",
        "subDescription": "Proceed with deleting \"{{candidateName}}\" from this group? This cannot be undone.",
        "successfullyRemove": "Candidate assignment successfully removed"
      },
      "eventSessionUniqueLocationWarningModal": {
        "buttons": {
          "back": "Back",
          "continue": "Continue saving"
        },
        "groupError": " - same as <highlight>{{path}}</highlight>",
        "sameUrlBetweenEventDescription1": "The group(s) below have the same session URLs with other sessions.",
        "sameUrlBetweenEventDescription2": "Do you still want to proceed adding the event session?",
        "sameUrlBetweenEventTitle": "Duplicate sessions URLs found",
        "sameUrlBetweenGroupDescription1": "The following groups have the same session URLs:",
        "sameUrlBetweenGroupDescription2": "Do you still want to proceed adding the event session?",
        "sameUrlBetweenGroupTitle": "There are groups with the same session URLs"
      },
      "setting": {
        "candidateCapacity": "Candidate capacity (maximum no. of people)",
        "createFailedMessage": "Failed to create new event",
        "createSuccessMessage": "Successfully created new event",
        "deadline": "Reservation Change/Cancellation Deadline",
        "deadlineDaysBefore": "day(s) before at",
        "description": "Description",
        "descriptionNote": "Enter the information that will appear on the event reservation page for candidates This information will appear at the top of it. We recommend to enter the event location and contact information.",
        "displayName": "Display Name (name that candidates will see)",
        "duration": "Duration (minutes)",
        "eventFormat": "Event format",
        "eventName": "Event Name",
        "expirationLinkDay_one": "1 day",
        "expirationLinkDay_other": "{{ count }} days",
        "expirationLinkLabel": "Event reservation link validity duration",
        "expirationLinkNote": "Select the duration where the event reservation link will be valid after the email is sent.",
        "formatNote": "For online events, the URL must be set after the event registration.",
        "placeholder": {
          "description": "Enter event description",
          "displayName": "Enter display name",
          "eventName": "Enter event name"
        },
        "reservationDay_one": "1 day later",
        "reservationDay_other": "{{ count }} days later",
        "reservationDay_zero": "Today",
        "reservationNote": "Sets when event dates will be displayed as available for reservation, calculated from the date the candidate accesses the event reservation page.",
        "setExpirationLink": {
          "no": "No",
          "yes": "Yes"
        },
        "shouldSetExpirationLink": "Set an expiration date on the event reservation link?",
        "shouldSetExpirationLinkNote": "By selecting \"Yes,\" you can set an expiration date for the reservation link, calculated from the time the email containing the link to the event reservation page was sent to the candidate. If a candidate opens a reservation page that has exceeded the expiration date, a message will be displayed stating that the reservation is not available.",
        "title": "Event Settings",
        "to": "to",
        "updateFailedMessage": "Failed to update the event",
        "updateSuccessMessage": "Event updated"
      }
    },
    "eventRegistration": {
      "confirmation": "Click the button below to confirm your reservation for the selected date. After making a reservation, you may cancel or change the date up to 23:59 one day prior to the reservation date. After that, please contact the recruiter directly.",
      "contactRecruiter": "Contact recruiter",
      "durationLabel": "Duration:",
      "durationUnit": "mins",
      "errors": {
        "alreadyReserved": "You have already made a reservation for this event.",
        "reservationLinkExpired": "The event reservation link has expired.",
        "sessionFull": "Please select another session.",
        "sessionNoLongerAvailable": "The session is no longer available."
      },
      "instruction": "Please select one date you can attend and make a reservation.",
      "invalidLinkDescription": "The reservation URL is invalid or may have expired, and we apologise for the inconvenience. Please report this to your recruiter to obtain a valid reservation URL.",
      "invalidLinkTitle": "Invalid reservation link",
      "online": "[online]",
      "reserve": "Make a reservation for the selected date",
      "success": "Reservation confirmed.",
      "successDescription": "You have successfully reserved a spot for the event. We look forward to seeing you there!",
      "successTitle": "Reservation successful",
      "title": "Reserve attendance slot"
    },
    "fieldTypes": {
      "HUMANITIES": "Humanities",
      "SCIENCE": "Science"
    },
    "form": {
      "errors": {
        "conflictingDate": "There is already a session on this date and start time. Please either change the date, start time, or both.",
        "cutoffTimeRequired": "Please enter until what time the reservation change/cancellation is allowed",
        "daysPriorRequired": "Please enter how many days in advance you would like candidates to change/cancel reservation",
        "duplicateSessionUrl": "The same session location cannot be used.",
        "eventNameRequired": "Please enter event name",
        "expectedNewPassword": "Password must be new",
        "expirationLinkRequired": "Please select the link validity duration",
        "inputMismatch": "Input must match the {{targetFieldName}} field",
        "invalidFormat": "{{fieldName}} has an invalid format",
        "reservationFromRequired": "Please select from when candidates can access the event reservation page",
        "reservationToRequired": "Please select until when candidates can access the event reservation page",
        "required": "{{fieldName}} is required",
        "reusedSessionUrl": "This sessions URL used for other event session. If you wish to avoid access from candidates who are not eligible to participate, we recommend changing the URL.",
        "stepNameRequired": "Please assign step name",
        "unknown": "{{fieldName}} is invalid",
        "wrongDate": "Please set correct dates",
        "wrongDateTime": "Please set correct date times",
        "wrongPassword": "The password is incorrect"
      },
      "labels": {
        "emptySelect": "Please select",
        "emptySelectShort": "Select"
      }
    },
    "gender": {
      "FEMALE": "Female",
      "MALE": "Male",
      "NOT_APPLICABLE": "Not applicable",
      "UNSPECIFIED": "Doesn't want to share their gender"
    },
    "global": {
      "add": "Add",
      "apply": "Apply",
      "cancel": "Cancel",
      "close": "Close",
      "confirm": "Confirm",
      "delete": "Delete",
      "dismiss": "Dismiss",
      "documentUpload": {
        "fail": "Unsuccessfully uploaded",
        "invalidCsvFormat": "Invalid file format. Please upload CSV file.",
        "success": "Successfully uploaded",
        "tooLarge": "File is too large, please make sure it's less than {{size}}."
      },
      "edit": "Edit",
      "error": "An error occurred",
      "login": "Login",
      "preview": "Preview",
      "remove": "Remove",
      "retry": "Retry",
      "save": "Save",
      "searchPlaceholder": "Type and hit enter to search",
      "submit": "Submit",
      "you": "You"
    },
    "jobPositionCreate": {
      "breadcrumbs": {
        "title": "Create Job Position"
      },
      "label": {
        "flow": "Recruitment Flow",
        "flowDescription": "Map out the recruitment process for this job position",
        "overview": "Overview",
        "overviewDescription": "General information about the job position",
        "requiredDocs": "Required Documents",
        "title": "Job Title"
      },
      "messages": {
        "errors": {
          "titleAlreadyUsed": "Title already used, please provide an other one."
        }
      },
      "placeholder": {
        "jobPositionName": "Enter job title"
      }
    },
    "jobPositions": {
      "detail": {
        "addEmail": "Add email",
        "addForm": "Add form",
        "empty": {
          "backToPreviousPage": "Back to previous screen",
          "description": "No job detail to be displayed",
          "subDescription": "Sorry, the page you are looking for either does not exist or has been removed.",
          "title": "Job Detail"
        },
        "flowDescription": "View the recruitment process for this position and set the necessary emails, forms and events for the steps.",
        "jobsList": "Jobs List",
        "noRequiredDocs": "No documents set",
        "setEvent": "Set event",
        "viewEmail": "View Email",
        "viewEmails": "View Emails",
        "viewEventDetails": "View event",
        "viewFailDecisionEmail": "Fail Decision Email",
        "viewForm": "View Form",
        "viewPassDecisionEmail": "Pass Decision Email"
      },
      "emailTemplateUpdateModal": {
        "buttons": {
          "exitPreview": "Exit Preview"
        },
        "failDecisionTitle": "Fail decision email",
        "notificationTitle": "Notification email",
        "passDecisionTitle": "Pass decision email",
        "previewWarning": "This is a preview. Variable values are placeholders only.",
        "subject": "Subject"
      },
      "evaluationFormUpdateModal": {
        "addForm": "Add form link",
        "disableEditTooltip": "The question can not be modified because some examiners already started giving evaluations",
        "error": "Evaluation form can not be saved",
        "questionsDescription": "Please enter the questions you want to include in the evaluation form for this recruitment step. You can add as many questions as you want. Please make sure to separate each question with at least one empty line. Each question can be written on multiple lines as long as it is does not contain any empty line, otherwise it will be counted as 2 different questions.",
        "questionList": "Question List",
        "questionListPreview": "Question List Preview",
        "questionsPlaceholder": "Example:\n\nWas the candidate punctual for the interview?\n\nHow confident did the candidate appear during the presentation?\n\nRate the candidate's communication skills.\nPlease rate on a scale of 1 to 5",
        "questions": "Questions",
        "success": "Evaluation form saved successfully",
        "title": "Evaluation Form"
      },
      "list": {
        "createButton": "Create job position",
        "empty": {
          "description": "No jobs have been added",
          "moveTo": "Go to Jobs page",
          "subDescription": "Start managing your company's openings by creating your first job position!"
        },
        "emptySearch": {
          "description": "No search results",
          "subDescription": "No entries match your query, please try a different keyword."
        },
        "jobPosition_one": "<highlight>{{count}}</highlight> Job Position",
        "jobPosition_other": "<highlight>{{count}}</highlight> Job Positions",
        "jobPositionsCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> of $t(jobPositions.list.jobPosition, {\"count\": {{count}} })",
        "orderBy": "Order by",
        "rowsPerPage": "Rows per page",
        "sort": {
          "addedAsc": "Date added (ascending)",
          "addedDesc": "Date added (descending)",
          "latestUpdate": "Last updated",
          "titleAsc": "By Job Title (A-Z)",
          "titleDesc": "By Job Title (Z-A)"
        },
        "subtitle": "You can view the jobs you have created or create a new job.",
        "table": {
          "publicId": "ID",
          "status": "Status",
          "title": "Job Title",
          "updatedAt": "Last Updated"
        },
        "title": "Jobs"
      },
      "modify": {
        "continueEditing": "Continue editing",
        "discardChanges": "Discard changes",
        "jobPositionCreatedFailed": "Failed to save new job position",
        "jobPositionCreatedSuccessfully": "Successfully saved new job position",
        "jobPositionUpdated": "The job position has been updated.",
        "proceed": "Proceed",
        "recruitmentFlowDescription": "Map out the recruitment process for this job position",
        "saveAndExit": "Save & exit",
        "stepNameExists": "Step name already exists",
        "unsafeExitCreatedDescription": "Job position not yet saved",
        "unsafeExitCreatedSubDescription": "Exiting without saving will discard any changes made. Are you sure you want to proceed?",
        "unsafeExitUpdatedDescription": "Unsaved changes",
        "unsafeExitUpdatedSubDescription": "There are changes made. Do you want to save or discard them?"
      },
      "panel": {
        "add": "Add a step",
        "edit": "Edit",
        "incompleteStageWarning": "Incomplete Stages Remaining",
        "incompleteStageWarningTooltip": "Job cannot be assigned to a candidate unless all stages have been set up.",
        "jobTitle": "Job Title",
        "jobTitleUpdated": "The job position title has been updated.",
        "mandatoryStepTooltipText": "This is a mandatory step. Once candidates finish the flow, they are assigned to this step.",
        "messages": {
          "errors": {
            "stepNameExists": "Step name already exists"
          },
          "successes": {
            "jobPositionUpdated": "The job position has been updated.",
            "recruitmentStepsUpdated": "The job position recruitment steps has been updated."
          }
        },
        "overview": {
          "title": "Overview",
          "description": "General information about the job position"
        },
        "recruitmentFlow": {
          "event": {
            "date": "Date : ",
            "disableDeleteActionTooltip": "You cannot delete an event if it has already taken place or if applicants have already been scheduled to attend to it.",
            "disableEditActionTooltip": "You cannot edit an event if it has already taken place or if applicants have already been scheduled to attend to it.",
            "examiner": "Examiner : ",
            "header": "Event",
            "notRegistered": "Not Registered",
            "register": "Add",
            "registered": "Registered"
          },
          "stage": {
            "ARCHIVE": {
              "name": "Archive"
            },
            "OFFER": {
              "description": "Candidate is given a job offer",
              "name": "Offer"
            },
            "PRE_EMPLOYMENT": {
              "description": "Candidate has accepted offer and is awaiting official employment",
              "name": "Pre-employment"
            },
            "SCREENING": {
              "description": "Candidate has formally made an application",
              "name": "Screening"
            },
            "SOURCING": {
              "description": "Information acquisition only, candidate hasn't made a formal application",
              "name": "Acquisition"
            }
          },
          "stepName": "Recruitment Step Name",
          "steps": {
            "archived": "Archived",
            "declined": "Declined",
            "eventWithoutScreening": "Event without screening",
            "eventWithScreening": "Event with screening",
            "failed": "Failed"
          },
          "stepsEdit": "Recruitment Flow",
          "stepType": "Recruitment Step Type",
          "stepTypeV2": {
            "document": "Document",
            "event": "Event"
          },
          "table": {
            "addEmail": "Add email",
            "addEmails": "Add emails",
            "addForm": "Add form",
            "decision": {
              "name": "Decision",
              "tooltipText": "Setting for creating an automated pass/fail emails to candidates depending on their performance in a recruitment step"
            },
            "evaluation": {
              "name": "Evaluation",
              "tooltipText": "Setting for creating an evaluation form to record candidate's performance in a recruitment step"
            },
            "incompleteStepsWarning": "Incomplete Step(s) Remaining",
            "mandatoryStepTooltipText": "This is a mandatory step. Once candidates finish the flow, they are assigned to this step.",
            "noSteps": "No steps to show",
            "noStepsWarning": "No steps set",
            "notification": {
              "name": "Notification",
              "tooltipText": "Setting for creating automated email to notify candidates they're moving to a recruitment step"
            },
            "setEvent": "Set Event",
            "stepName": {
              "name": "Step Name"
            },
            "stepType": {
              "name": "Step Type"
            },
            "viewEmail": "View Email",
            "viewEmails": "View Emails",
            "viewEventDetails": "View Details",
            "viewFailDecisionEmail": "Fail Decision Email",
            "viewForm": "View Form",
            "viewPassDecisionEmail": "Pass Decision Email"
          }
        },
        "recruitmentStepsUpdated": "The job position recruitment steps has been updated.",
        "updatedAt": "Updated at: "
      },
      "shared": {
        "documentRequirement": {
          "description": "What are the required documents that candidate need to submit for this job role?",
          "title": "Required Documents"
        },
        "draftStatus": {
          "draft": "Draft",
          "published": "Published"
        },
        "metadata": {
          "createdAt": "Date Created",
          "createdBy": "Created by",
          "lastUpdated": "Last Updated on"
        },
        "placeholder": {
          "documentRequirement": "Enter any relevant information you would like to communicate to candidates regarding documents they should upload. For example, list of documents, file types, file size, deadline, etc."
        },
        "recruitmentFlow": {
          "addAStep": "Add a step",
          "decision": "Decision",
          "decisionTooltip": "Setting for creating an automated pass/fail emails to candidates depending on their performance in a recruitment step",
          "evaluation": "Evaluation",
          "evaluationTooltip": "Setting for creating an evaluation form to record candidate's performance in a recruitment step",
          "incompleteStepsTooltip": "Events/email/forms haven't been set in the following step(s):",
          "incompleteStepsWarning": "{{ numOfSteps }} Incomplete Steps",
          "mandatoryStepTooltipText": "This is a mandatory step. Once candidates finish the flow, they are assigned to this step.",
          "noStepsToShow": "No steps to show",
          "noStepsWarning": "No steps set",
          "notification": "Notification",
          "notificationTooltip": "Setting for creating automated email to notify candidates they're moving to a recruitment step",
          "stepName": "Step Name",
          "stepType": "Step Type",
          "title": "Recruitment Flow",
          "disabledStepTypeTooltip": "The step type cannot be changed for existing steps."
        },
        "stage": {
          "ARCHIVE": {
            "name": "Archive"
          },
          "OFFER": {
            "description": "Candidate is given a job offer",
            "name": "Offer"
          },
          "PRE_EMPLOYMENT": {
            "description": "Candidate has accepted offer and is awaiting official employment",
            "name": "Pre-employment"
          },
          "SCREENING": {
            "description": "Candidate has formally made an application",
            "name": "Screening"
          },
          "SOURCING": {
            "description": "Information acquisition only, candidate hasn't made a formal application",
            "name": "Acquisition"
          }
        }
      }
    },
    "livesIn": {
      "JAPAN": "Japan",
      "OVERSEAS": "Overseas"
    },
    "loading": "Loading...",
    "menu": {
      "items": {
        "jobPositions": "Job Positions"
      }
    },
    "notFound": {
      "backToTopPage": "Back to Top Page",
      "description": "Couldn't find a page",
      "subDescription": "The specified page may have been deleted or moved to another location."
    },
    "recruitmentStage": {
      "SOURCING": "Acquisition",
      "SCREENING": "Screening",
      "OFFER": "Offer",
      "PRE_EMPLOYMENT": "Pre-employment",
      "ARCHIVE": "Archive"
    },
    "recruitmentStepStatus": {
      "CLOSED_NO_REPLY": "Closed (No Reply / Missing an Interview)",
      "CLOSED_OTHER": "Closed (Other Reason)",
      "DOCUMENT_SCREENING_EVALUATED": "Evaluated",
      "DOCUMENT_SCREENING_WAITING_FOR_ASSIGNMENT": "Waiting for assigning person",
      "DOCUMENT_SCREENING_WAITING_FOR_EVALUATION": "Waiting for an evaluation",
      "ENTRY": "Entry",
      "INFORMATION_REGISTRATION": "Information registration",
      "INTERVIEW_ADJUSTMENT": "Adjusting Interview",
      "INTERVIEW_EVALUATED": "Schedule adjusted - Evaluated",
      "INTERVIEW_WAITING_FOR_ASSIGNMENT": "Waiting for setting interview",
      "INTERVIEW_WAITING_FOR_EVALUATION": "Schedule adjusted - Waiting for an evaluation",
      "JOINED": "Joined",
      "OFFER": "Job offer",
      "OFFER_ACCEPTED": "Job offer accepted",
      "REJECTED_CULTURE_MISMATCH": "Rejected (Culture Mismatched)",
      "REJECTED_OTHER": "Rejected (Others)",
      "REJECTED_SKILL_MISMATCH": "Rejected (Skill Mismatched)",
      "WITHDRAWN_OTHER": "Declined (Others)",
      "WITHDRAWN_OTHER_OFFER": "Declined (Joined Other Company)",
      "WITHDRAWN_REMAINED": "Declined (Continuation of Current Job)"
    },
    "recruitmentStepStatusAction": {
      "ADJUST_INTERVIEW": "Adjust interview",
      "ARCHIVE": "Archive",
      "ASSIGN_FOR_DOCUMENT_SCREENING_EVALUATION": "Assign a person",
      "ASSIGN_FOR_INTERVIEW": "Set an interview",
      "ASSIGN_TO_SESSION_MANUALLY": "Assign To Session Manually",
      "COMPLETE_EVALUATION": "Complete Evaluation",
      "DECLINE_SELECTION": "[DECLINE_SELECTION]",
      "CONFIRM_DOCUMENTS": "Confirm Documents",
      "EVALUATE_DOCUMENT_SCREENING": "Fill an evaluation",
      "EVALUATE_INTERVIEW": "Fill an evaluation",
      "FILL_EVALUATION": "Fill Evaluation",
      "FINALIZE_FAIL": "Finalize Fail",
      "FINALIZE_PASS": "Finalize Pass",
      "FINISH_SELECTION_DUE_TO_JOIN": "Finish Selection due to join",
      "FINISH_SELECTION_DUE_TO_OTHER_REASONS": "[FINISH_SELECTION_DUE_TO_OTHER_REASONS]",
      "MARK_AS_FAIL": "Mark As Fail",
      "MARK_AS_JOINED": "Mark As Joined",
      "MARK_AS_PASS": "Mark As Pass",
      "MARK_AS_UNDECIDED": "Mark As Undecided",
      "MOVE_TO_NEXT_STATUS": "Move To Next Status",
      "MOVE_TO_NEXT_STEP": "Move to next step",
      "REGISTER_ATTENDANCE": "Register Attendance",
      "REJECT_SELECTION": "[REJECT_SELECTION]",
      "REVERT_TO_PREVIOUS_STATUS": "Revert to selection",
      "SEND_DOCUMENT_NOTIFICATION": "Send Document Notification",
      "SEND_EVENT_ANNOUNCEMENT": "Send Event Announcement",
      "SEND_FOLLOW_UP_EMAIL": "Send Follow Up Email",
      "SEND_REMINDER_EMAIL": "Send Reminder Email",
      "SEND_REMINDER_TO_PENDING_EXAMINERS": "Send Reminder To Pending Examiners",
      "SET_JOB_OFFER_ACCEPTED_DATE": "Move to Job Offer Accepted",
      "SET_JOB_OFFER_DATE": "Move to Job Offer",
      "VIEW_EVALUATIONS": "View Evaluations"
    },
    "recruitmentStepStatusOtherActions": {
      "ADJUST_INTERVIEW": "[ADJUST_INTERVIEW]",
      "ASSIGN_FOR_DOCUMENT_SCREENING_EVALUATION": "[ASSIGN_FOR_DOCUMENT_SCREENING_EVALUATION]",
      "ASSIGN_FOR_INTERVIEW": "[ASSIGN_FOR_INTERVIEW]",
      "DECLINE_SELECTION": "Finish selection due to decline",
      "EVALUATE_DOCUMENT_SCREENING": "Fill an evaluation",
      "EVALUATE_INTERVIEW": "Fill an evaluation",
      "FINISH_SELECTION_DUE_TO_JOIN": "Finish Selection due to join",
      "FINISH_SELECTION_DUE_TO_OTHER_REASONS": "Finish selection due to other reasons",
      "MOVE_TO_NEXT_STEP": "Move to next step",
      "REJECT_SELECTION": "Finish selection due to rejection",
      "REVERT_TO_PREVIOUS_STATUS": "Revert to previous one",
      "SET_JOB_OFFER_ACCEPTED_DATE": "[SET_JOB_OFFER_ACCEPTED_DATE]",
      "SET_JOB_OFFER_DATE": "Move to Job Offer"
    },
    "recruitmentStepStatusV2": {
      "CLOSED_NO_REPLY": "Closed - No reply/Missed interview",
      "CLOSED_OTHER": "Closed - Other",
      "JOINED": "Joined",
      "PENDING_ATTENDANCE": "Pending Attendance",
      "PENDING_DECISION": "Pending Decision",
      "PENDING_DOCUMENT": "Pending Document",
      "PENDING_EVALUATION": "Pending Evaluation",
      "PENDING_EVENT": "Pending Event",
      "PENDING_EVENT_ANNOUNCEMENT": "Pending Event Announcement",
      "PENDING_FINAL_DECISION": "Pending Final Decision",
      "PENDING_NOTIFICATION": "Pending Notification",
      "PENDING_RESULT": "Pending Result",
      "REJECTED_CULTURE_MISMATCH": "Rejected - Culture Mismatch",
      "REJECTED_OTHER": "Rejected - Other",
      "REJECTED_SKILL_MISMATCH": "Rejected - Skill Mismatch",
      "SCHEDULE_ADJUSTMENT": "Schedule Adjustment",
      "WITHDRAWN_OTHER": "Declined - Other",
      "WITHDRAWN_OTHER_OFFER": "Declined - Joined other company",
      "WITHDRAWN_REMAINED": "Declined - Continuation of current job"
    },
    "recruitmentStepType": {
      "CLOSED": "Closed",
      "DOCUMENT_SCREENING": "Document Screening",
      "ENTRY": "Entry",
      "INFORMATION_REGISTRATION": "Information Registration",
      "INTERVIEW": "Event",
      "OFFER": "Job offer",
      "OFFER_ACCEPTED": "Job offer accepted",
      "RecruitmentResult": "Recruitment Result"
    },
    "recruitmentStepTypeV2": {
      "DOCUMENT": "Document",
      "EVENT": "Event",
      "SYSTEM": "System"
    },
    "schoolTypes": {
      "GRADUATE_SCHOOL": "Graduate School",
      "HIGH_SCHOOL": "High School",
      "JUNIOR_COLLEGE": "Junior College",
      "TECHNICAL_COLLEGE": "Technical College",
      "UNIVERSITY": "University",
      "VOCATIONAL_SCHOOL": "Vocational School"
    },
    "archiveReasonGroup": {
      "REJECTED": "Rejected",
      "DECLINED": "Declined",
      "OTHER": "Other"
    },
    "settings": {
      "list": {
        "title": "Settings"
      },
      "preferences": {
        "emailSignature": {
          "externalEmailSignature": "External Email Signature",
          "externalEmailSignatureDescription": "Used when sending emails to candidates.",
          "externalEmailPlaceholder": "Enter external email signature",
          "internalEmailSignature": "Internal Email Signature",
          "internalEmailSignatureDescription": "Used when sending emails to other users in the company.",
          "internalEmailPlaceholder": "Enter internal email signature",
          "noExternalSignature": "No external email signature set",
          "noInternalSignature": "No internal email signature set",
          "success": "Successfully updated preferences",
          "fail": "Failed to update preferences"
        },
        "title": "Preferences",
        "discardNotification": "Discarded changes",
        "promptModal": {
          "title": "Unsaved changes",
          "description": "There are changes made. Do you want to save or discard them?",
          "actions": {
            "proceed": "Discard changes",
            "cancel": "Continue editing",
            "saveAndProceed": "Save & exit"
          }
        }
      },
      "tabs": {
        "account": "Account",
        "comingSoon": "Coming Soon!",
        "preferences": "Preferences",
        "templates": "Templates",
        "userManagement": "User Management"
      },
      "account": {
        "title": "Account",
        "actions": {
          "save": {
            "button": "Save",
            "success": "Successfully updated account settings.",
            "error": "Failed to update account settings."
          },
          "discardNotification": "Discarded changes",
          "reset": {
            "button": "Reset"
          }
        },
        "section": {
          "password": {
            "heading": "Set new password",
            "subheading": "The new password can’t be the same as the current password or  user ID."
          }
        },
        "form": {
          "firstName": {
            "title": "First name"
          },
          "lastName": {
            "title": "Last name"
          },
          "firstNameKana": {
            "title": "First name (Katakana)"
          },
          "lastNameKana": {
            "title": "Last name (Katakana)"
          },
          "email": {
            "title": "Email"
          },
          "oldPassword": {
            "title": "Current password",
            "placeholder": "Enter current password"
          },
          "newPassword": {
            "title": "New password",
            "placeholder": "Enter new password",
            "patterns": {
              "minimumLength": "At least 8 characters",
              "halfWidthNumbers": "Half-width numerals (e.g. 1, 2, 3)",
              "halfWidthUpperCaseLetters": "Upper case letters (e.g. A, B, C)",
              "halfWidthLowerCaseLetters": "Lower case letters (e.g. a, b, c)"
            }
          },
          "newPasswordConfirm": {
            "title": "Confirm new password",
            "placeholder": "Re-enter new password"
          }
        },
        "promptModal": {
          "title": "Unsaved changes",
          "description": "There are changes made. Do you want to save or discard them?",
          "actions": {
            "proceed": "Discard changes",
            "cancel": "Continue editing",
            "saveAndProceed": "Save & exit"
          }
        }
      },
      "userManagement": {
        "addUser": "Add user",
        "addUserError": {
          "contactUs": "Contact Us",
          "description": "Failed to send email invitation",
          "subDescription": "Please try again. If the problem continues, please contact Bluumhire team."
        },
        "create": {
          "description": "To add a user into Bluum, enter email address of the person you want to invite and set the role/s.",
          "form": {
            "email": {
              "placeholder": "Email address",
              "title": "Email"
            },
            "role": "Role"
          },
          "sendInvite": "Send email invitation",
          "title": "Add user"
        },
        "list": {
          "emptySearch": {
            "description": "No search results",
            "subDescription": "No entries match your query, please try a different keyword."
          },
          "orderBy": "Order by",
          "pending": "Pending",
          "role": {
            "ScovilleAdmin": "Scoville Admin",
            "Scoville": "Scoville User",
            "Admin": "Admin",
            "Coordinator": "Coordinator",
            "Examiner": "Examiner",
            "Unknown": "Unknown"
          },
          "rowsPerPage": "Rows per page",
          "search": "Search",
          "sort": {
            "nameAsc": "By User Name (A-Z)",
            "nameDesc": "By User Name (Z-A)"
          },
          "status": {
            "INVITED": "Invited",
            "REGISTERED": "Active"
          },
          "table": {
            "email": "E-mail",
            "role": "Role",
            "status": "Status",
            "username": "User Name"
          },
          "user_one": "<highlight>{{count}}</highlight> User",
          "user_other": "<highlight>{{count}}</highlight> Users",
          "usersCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> of $t(settings.userManagement.list.user, {\"count\": {{count}} })"
        },
        "subtitle": "As an admin, you can add, delete, or change the permissions of users.",
        "title": "User Management",
        "updateUserError": {
          "contactUs": "Contact Us",
          "description": "Failed to save user details",
          "subDescription": "Please try again. If the problem continues, please contact Bluumhire team."
        }
      }
    },
    "sideMenu": {
      "analytics": "Analytics",
      "calendar": "Calendar",
      "candidates": "Candidates",
      "dashboard": "Dashboard",
      "english": "English",
      "japanese": "日本語",
      "job": "Jobs",
      "logout": "Log out",
      "settings": "Settings",
      "userRolesTooltip": "Your account has the roles: {{roles}}."
    },
    "storybook": {
      "disabled": "Disabled"
    },
    "users": {
      "create": {
        "invitationEmail": {
          "send": {
            "error": "Failed sending invitation link",
            "success": "Successfully sent email invitation"
          }
        },
        "labels": {
          "email": "Email",
          "role": "Role"
        },
        "title": "Add user",
        "tooltips": {
          "scovilleAdmin": "Can create and delete company accounts. Can add other Scoville accounts",
          "scoville": "Can do everything an admin can do, but cannot be seen or modified by non-Scoville admins",
          "admin": "Can manage user accounts and edit company account information.",
          "coordinator": "Can manage jobs and candidate selection.",
          "examiner": "Can evaluate interviews and screen documents they have been assigned to."
        }
      },
      "edit": {
        "buttons": {
          "relinquish": "Relinquish it and Save",
          "save": "Save"
        },
        "confirmation": {
          "description": {
            "part1": "This change in user information involves relinquishing your {{roles, list}} permissions.",
            "part2": "If you relinquish these permissions:"
          },
          "question": "Do you want to relinquish these permissions and save your changes?",
          "title": "Do you relinquish administrative permission?",
          "warning": {
            "INVISIBLE": "you will become visible to non-Scoville users",
            "VIEW_SCOVILLE_USER": "you will no longer be able to view users with “Scoville User” or “Scoville Admin” roles",
            "INVITE_SCOVILLE_USER": "you will no longer be able to invite users with the “Scoville User” or “Scoville Admin” roles.",
            "GIVE_SCOVILLE_ROLE": "you will no longer be able to give the “Scoville User” or “Scoville Admin” roles to existing users",
            "INVITE_USER": "you will no longer be able to invite users",
            "EDIT_USER": "you will no longer be able to make changes to user information"
          }
        },
        "error": "Failed to update user information ",
        "labels": {
          "email": "Email",
          "firstName": "First name",
          "firstNameKana": "First name (Kana)",
          "lastName": "Last name",
          "lastNameKana": "Last name (Kana)",
          "roles": "Role"
        },
        "placeholders": {
          "email": "Email",
          "firstName": "First Name",
          "lastName": "Last Name",
          "firstNameKana": "ジョン",
          "lastNameKana": "スミス"
        },
        "success": "User Information successfully updated"
      },
      "errors": {
        "emailAlreadyRegistered": "This email address is already registered",
        "emailAlreadyInvited": "This email address has already been invited"
      },
      "role": {
        "ScovilleAdmin": "Scoville Admin",
        "Scoville": "Scoville User",
        "Admin": "Admin",
        "Coordinator": "Coordinator",
        "Examiner": "Examiner",
        "Unknown": "Unknown"
      },
      "show": {
        "description": "Only users with \"Admin\" role can make changes on user details.",
        "invitedDescription": "This user hasn't activated their account yet. You will be able to edit their details once their account is active.",
        "labels": {
          "email": "Email",
          "firstName": "First name",
          "firstNameKana": "First name (Kana)",
          "lastName": "Last name",
          "lastNameKana": "Last name (Kana)",
          "roles": "Role"
        },
        "resendInvitation": "Re-send email invitation",
        "cancelInvitation": "Cancel invitation",
        "scovilleUserDescription": "This user is a “Scoville Admin“. Only other “Scoville Admin“ users can change their user information.",
        "title": "User Detail",
        "deleted": {
          "fullName": "{{fullName}} [user deleted]"
        }
      },
      "resendInvitation": {
        "description": "Re-send email invitation",
        "subDescription": "If you re-send the email invitation, the previous invitation link will become invalid. Would you like to proceed?",
        "confirm": "Re-send email invitation",
        "resendSuccess": "Invitation email re-sent",
        "resendFailed": "Failed to re-send invitation"
      },
      "cancelInvitation": {
        "description": "Cancel invitation",
        "subDescription": "Are you sure you want to cancel invitation for <highlight>{{email}}</highlight>? This action cannot be undone.",
        "confirm": "Confirm cancel invitation",
        "cancelSuccess": "Invitation cancelled",
        "cancelFailed": "Failed to cancel invitation"
      }
    },
    "time": {
      "hours": "Hr.",
      "minutes": "Min."
    },
    "welcome": {
      "content": {
        "slide1": {
          "title": "Customizable Job Openings",
          "caption": "Create job positions and set the recruitment flow, activate automated emails and compose evaluation forms."
        },
        "slide2": {
          "title": "Flexible Candidate Management",
          "caption": "Import and export candidate information en masse, process multiple candidates with just a few clicks."
        },
        "slide3": {
          "title": "Reduce Repetitive Tasks",
          "caption": "Set up automatic emails, evaluation forms and event invites to individual or multiple candidates."
        }
      },
      "createFirstJob": "Create my first job",
      "description": "Welcome to Bluumhire",
      "dontShowAgain": "Don’t show again",
      "subDescription": "Bluumhire helps you track and manage your company’s recruitment activities."
    },
    "activityLog": {
      "timestamp": "{{date}}, {{time}}",
      "types": {
        "InviteUserUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> invited <inviteeLink>{{inviteeName}}</inviteeLink> to join the <strong>{{companyName}}</strong> organization."
        },
        "DeleteUserUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> deleted the account of <strong>{{deletedName}}</strong>."
        },
        "EditPreferencesUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> updated <settingsLink>{{settingsField}}</settingsLink> in the preferences."
        },
        "AddApplicationManualUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> added <applicationLink>{{candidateName}}</applicationLink> as a candidate for the <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> position."
        },
        "AddApplicationCsvUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> added <strong>{{candidateCount}}</strong> candidates to be considered for the <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> position."
        },
        "EditCandidateInfoUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> updated <candidateLink>{{candidateName}}</candidateLink>'s profile information."
        },
        "UploadCandidateDocumentUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> uploaded <strong>{{fileName}}</strong> to <candidateLink>{{candidateName}}</candidateLink>'s profile. "
        },
        "DeleteCandidateDocumentUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> deleted <strong>{{fileName}}</strong> from <candidateLink>{{candidateName}}</candidateLink>'s profile. "
        },
        "SendCandidateEmailUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> sent an email to <applicationLink>{{candidateName}}</applicationLink>."
        },
        "SendCandidateEmailBulkUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> sent a batch email to <strong>{{candidateCount}}</strong> candidates."
        },
        "SendCandidateEmailSystemActivityLog": {
          "message": "An email was sent to <applicationLink>{{candidateName}}</applicationLink>."
        },
        "ReceiveCandidateEmailSystemActivityLog": {
          "message": "An email was received from <applicationLink>{{candidateName}}</applicationLink>."
        },
        "UpdateApplicationStatusUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> updated the status of <applicationLink>{{candidateName}}</applicationLink> from <strong>{{previousStatus}}</strong> to <strong>{{newStatus}}</strong> in <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - {{stepName}}."
        },
        "UpdateApplicationStatusBulkUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> updated the status of <strong>{{candidateCount}}</strong> candidates from <strong>{{previousStatus}}</strong> to <strong>{{newStatus}}</strong> in <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "UpdateApplicationStatusSystemActivityLog": {
          "message": "The status of <applicationLink>{{candidateName}}</applicationLink> was automatically updated from <strong>{{previousStatus}}</strong> to <strong>{{newStatus}}</strong> in <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "UpdateApplicationStatusBulkSystemActivityLog": {
          "message": "The status of <strong>{{candidateCount}}</strong> candidates was automatically updated from <strong>{{previousStatus}}</strong> to <strong>{{newStatus}}</strong> in <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "ArchiveApplicationUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> archived <applicationLink>{{candidateName}}</applicationLink> as <strong>{{archiveReason}}</strong>."
        },
        "ArchiveApplicationUserBulkActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> archived <strong>{{candidateCount}}</strong> candidates as <strong>{{archiveReason}}</strong>."
        },
        "UnarchiveApplicationUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> restored <applicationLink>{{candidateName}}</applicationLink> from the archive."
        },
        "UnarchiveApplicationUserBulkActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> restored <strong>{{candidateCount}}</strong> candidates from the archive."
        },
        "DeleteCandidateUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> deleted <candidateLink>{{candidateName}}</candidateLink>'s profile."
        },
        "DeleteCandidateUserBulkActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> deleted <strong>{{candidateCount}}</strong> candidate profiles."
        },
        "FillEvaluationUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> has submitted an evaluation for <applicationLink>{{candidateName}}</applicationLink> for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "FillEvaluationUserBulkActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> has submitted evaluations for <strong>{{candidateCount}}</strong> candidates for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "AddJobUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> created the <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> position."
        },
        "DeleteJobUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> deleted the <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> position."
        },
        "EditJobUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> modified the <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> position."
        },
        "SetEvaluationFormUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> set an evaluation form for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "EditEmailTemplateUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> modified the email templates for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "SetEventUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> set an event for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "EditEventUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> modified the event for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{stepName}}</strong>."
        },
        "AddEventSessionUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> added a session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong> for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{eventName}}</strong>."
        },
        "EditEventSessionUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> updated the details of the session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong> for <jobPositionLink>{{jobPositionTitle}}</jobPositionLink> - <strong>{{eventName}}</strong>."
        },
        "AssignCandidateUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> assigned <applicationLink>{{candidateName}}</applicationLink> to group <strong>{{groupNumber}}</strong> of the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "AssignCandidateBulkUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> assigned <strong>{{candidateCount}}</strong> candidates to group <strong>{{groupNumber}}</strong> of the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "UnassignCandidateUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> removed <applicationLink>{{candidateName}}</applicationLink> from the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "UnassignCandidateBulkUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> removed <strong>{{candidateCount}}</strong> candidates from the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "AssignExaminerUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> assigned <examinerLink>{{examinerName}}</examinerLink> as an examiner for group <strong>{{groupNumber}}</strong> of the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "UnassignExaminerUserActivityLog": {
          "message": "<actorLink>{{actorName}}</actorLink> removed <examinerLink>{{examinerName}}</examinerLink> as an examiner for group <strong>{{groupNumber}}</strong> of the <strong>{{eventName}}</strong> session scheduled for <strong>{{sessionDate}}</strong> at <strong>{{sessionTime}}</strong>."
        },
        "UnknownActivityLog": {
          "message": ""
        }
      }
    }
  }
}