import { Entity } from "@typedefs/graphql";
import {
  JobPositions_AllSimpleQuery,
  AllTagsQuery,
  AllEntryCategories_AllQuery,
  AllAcquisitionCategories_AllQuery,
  RecruitmentProcess_OneByIdQuery,
  AllPrefecturesQuery,
  AllSchoolTypesQuery,
  StepPreliminaryDecision,
  StepFinalDecision,
  RecruitmentStepStatus,
} from "@entities";
import { EvaluationResponse, SimpleApplication } from "@typedefs/aliases";
import { CsvExportActions } from "./ApplicationPanel/CSVExportActions/hook";
import { BulkActionType } from "@shared/application/bulkActions/helpers";

interface ListMode {
  name: 'list';
}

interface ShowMode {
  name: 'show';
  payload: {
    application: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess'>;
    prefectures: Entity<AllPrefecturesQuery, 'prefectures'>[];
    schoolTypes: Entity<AllSchoolTypesQuery, 'schoolTypes'>;
    acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[];
    entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[];
  };
}

// Note: Temporarily disabled this since it's unused. After refactoring the full screen modal, decide if still needed
// interface CreateMode {
//   name: 'create';
//   payload: {
//     entryCategories: Entity<AllEntryCategories_AllQuery, 'entryCategories'>[];
//     acquisitionCategories: Entity<AllAcquisitionCategories_AllQuery, 'acquisitionCategories'>[];
//   };
// }

type DisplayMode = ListMode | ShowMode;

type Target = 'screeningDetail' | 'evaluation';

type Step = 'documentScreening' | 'interview';

export type DeleteDetailPayload = {
  step: Step;
  target: Target;
  id: string;
  stepName: string;
};

export type FinalizeDecisionPayload = {
  applicationId: string;
  stepId: string;
  preliminaryResult?: StepPreliminaryDecision | null;
  finalResult: StepFinalDecision;
};

export type ArchiveBulkActionPayload = {
  type: 'archive'
  archiveReason: RecruitmentStepStatus;
}

export type BulkEventSessionAssignmentPayload = {
  type: 'assignToEventSession'
  recruitmentStepId: string
  groupId: string
}

export type BulkActionPayload =
  | ArchiveBulkActionPayload
  | BulkEventSessionAssignmentPayload;

export type BulkActionGeneralConfirmationPayload = {
  action: BulkActionType
  applicationIdBatches: string[][]
  bulkActionPayload?: BulkActionPayload
}

export type BulkActionLoadingModalPayload = {
  total: number;
  processed: number;
  action: BulkActionType;
}

export type BulkActionErrorModalPayload = {
  action: BulkActionType;
  failedIds: string[];
  success: number;
  bulkActionPayload?: BulkActionPayload
}

export type BulkEventSessionAssignmentModalPayload = {
  applicationIds: string[]
  recruitmentStepId: string
}

export type BulkEventSessionAssignmentOptionModalPayload = {
  recruitmentStepId: string
  numOfApplicationsAssignedToSession: number
  totalApplications: number
}

export type BulkEventSessionAssignmentErrorModalPayload = BulkActionErrorModalPayload & {
  closeModal: VoidFunction
}

interface ApplicationHideMode {
  name: 'hide'
}

interface ApplicationDetailShowMode {
  name: 'showApplicationDetail',
}

interface FinalizeDecisionMode {
  name: 'finalizeDecision';
  payload: FinalizeDecisionPayload;
}

interface RevertApplicationMode {
  name: 'revertApplication';
  payload: {
    applicationId: string;
  };
}

export interface EvaluateApplicationPayload {
  applicationId: string;
  stepId: string;
  clientId: string;
  responses: EvaluationResponse[];
}

interface EvaluateApplicationMode {
  name: 'evaluateApplication';
  payload: EvaluateApplicationPayload;
}

export type ClientAndResponseType = {
  clientName: string;
  response: string;
}

export type MappedEvaluationsType = Map<string, ClientAndResponseType[]>;

export interface AllEvaluationsPayloadType {
  applicationId: string;
  stepId: string;
  respondents: string[];
  evaluations: MappedEvaluationsType;
}

interface ViewAllEvaluationsMode {
  name: 'viewAllEvaluations';
  payload: AllEvaluationsPayloadType;
}

interface CSVExportingMode {
  name: 'csvExporting';
  payload: {
    fileName: string;
  }
}

interface CSVExportErrorMode {
  name: 'csvExportError';
}

interface BulkActionGeneralConfirmationModalMode {
  name: 'showBulkActionGeneralConfirmationModal';
  payload: BulkActionGeneralConfirmationPayload
}

interface BulkActionLoadingModalMode {
  name: 'showBulkActionLoadingModal';
  payload?: BulkActionLoadingModalPayload
}

interface BulkActionErrorModalMode {
  name: 'showBulkActionErrorModal';
  payload: BulkActionErrorModalPayload;
}

interface BulkEventSessionAssignmentModalMode {
  name: 'showBulkEventSessionAssignmentModal';
  payload: BulkEventSessionAssignmentModalPayload;
}

interface BulkEventSessionAssignmentOptionModalMode {
  name: 'showBulkEventSessionAssignmentOptionModal';
  payload: BulkEventSessionAssignmentOptionModalPayload;
}

interface BulkEventSessionAssignmentErrorModalMode {
  name: 'showBulkEventSessionAssignmentErrorModal';
  payload: BulkEventSessionAssignmentErrorModalPayload;
}

interface AddCandidatesModalMode {
  name: 'addCandidatesModal';
}

type BulkActionModalMode =
  | BulkActionGeneralConfirmationModalMode
  | BulkActionLoadingModalMode
  | BulkActionErrorModalMode
  | BulkEventSessionAssignmentModalMode
  | BulkEventSessionAssignmentOptionModalMode
  | BulkEventSessionAssignmentErrorModalMode
  | ApplicationHideMode

type ApplicationModalMode =
  | ApplicationHideMode
  | ApplicationDetailShowMode
  | FinalizeDecisionMode
  | RevertApplicationMode
  | EvaluateApplicationMode
  | ViewAllEvaluationsMode
  | CSVExportingMode
  | CSVExportErrorMode
  | BulkActionModalMode
  | AddCandidatesModalMode;

interface State {
  loading: boolean;
  jobPositions: Entity<JobPositions_AllSimpleQuery, "jobPositions.edges">[];
  totalRows: number;
  clientHasNoProcess: boolean;
  applications: SimpleApplication[];
  displayMode: DisplayMode;
  applicationModalMode: ApplicationModalMode;
  csvExportMode: CsvExportActions | 'NONE';
}

interface UpdateJobPositionsAction {
  name: "UpdateJobPositions";
  jobPositions: Entity<JobPositions_AllSimpleQuery, "jobPositions.edges">[];
}

interface UpdateApplicationsAction {
  name: "UpdateApplications";
  applications: SimpleApplication[];
  totalRows: number;
  clientHasNoProcess: boolean;
}

interface SetLoadingAction {
  name: "SetLoading";
  loading: boolean;
}

interface SetModeAction {
  name: 'SetMode';
  displayMode: DisplayMode;
}

interface SetApplicationModalModeAction {
  name: 'SetApplicationModalMode',
  applicationModalMode: ApplicationModalMode
}

interface SetCSVExportModeAction {
  name: 'SetCSVExportMode',
  csvExportMode: CsvExportActions | 'NONE'
}

type Action =
  | UpdateJobPositionsAction
  | UpdateApplicationsAction
  | SetLoadingAction
  | SetModeAction
  | SetApplicationModalModeAction
  | SetCSVExportModeAction;

const reducer = (state: State, action: Action): State => {
  switch (action.name) {
    case "UpdateJobPositions":
      return { ...state, jobPositions: action.jobPositions };

    case "SetLoading":
      return { ...state, loading: action.loading };

    // TODO: Figure out when to set loading: false -> e.g. we do it when update past application
    // summaries and recruitment step summaries
    case "UpdateApplications":
      return {
        ...state,
        loading: false,
        applications: action.applications,
        totalRows: action.totalRows,
        clientHasNoProcess: action.clientHasNoProcess,
      };

    case 'SetMode':
      return { ...state, displayMode: action.displayMode };

    case 'SetApplicationModalMode':
      return { ...state, applicationModalMode: action.applicationModalMode }

    case 'SetCSVExportMode':
      return { ...state, csvExportMode: action.csvExportMode }

    default:
      return state;
  }
};

const getInitialState = (): State => ({
  loading: false,
  jobPositions: [],
  totalRows: 0,
  clientHasNoProcess: true,
  applications: [],
  displayMode: {
    name: 'list',
  },
  applicationModalMode: {
    name: 'hide'
  },
  csvExportMode: 'NONE',
});

export {
  reducer,
  getInitialState,
  ApplicationModalMode,
  State,
  BulkActionModalMode
};
