import { MAX_FILE_SIZE } from "@helpers/core/constants";
import { OpenFolderInput } from "@helpers/hooks/unsorted/rehooks/openFolder/OpenFolderInput";
import { Cn } from "@helpers/unsorted/classNames";
import { toSizeString } from "@helpers/unsorted/numberExtra";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { useUploadDragAndDrop } from "./hook";
import { PendingDocument } from "../reducer";
import { isDefined } from "@helpers/core/typeGuards";
import { Button } from "@shared/unsorted/Button/Button";

const styles = {
    documentSection: Cn.c('mt-6 w-full flex items-center justify-between p-6 border border-emphasized rounded-xl font-paragraph-small-regular text-emphasized'),
    uploadSection: Cn.c('mt-6 w-full p-6 flex flex-col items-center justify-center border border-dashed border-emphasized rounded-xl cursor-pointer'),
    uploadIcon: Cn.c('w-10 h-10 text-icons-subdued'),
    uploadLabelContainer: Cn.c('flex space-x-1 pt-3'),
    clickToUpload: Cn.c('font-paragraph-small-button-medium text-primary-default'),
    label: Cn.c('font-paragraph-small-button-medium text-subdued'),
    fileNote: Cn.c('pt-1'),
    labelWrapper: Cn.c('flex space-x-1 items-center'),
    documentIcon: Cn.c('w-6 h-6 text-icons-subdued'),
}

interface Props {
    onUpload: (files?: File[]) => void;
    onRemove: VoidFunction
    csvFile?: PendingDocument
}

const UploadDragAndDrop: FunctionComponent<Props> = ({
    onUpload,
    onRemove,
    csvFile
}) => {
    const { t } = useTranslation();

    const {
        fileDropArea,
        documentUpload: { openFolder, openFolderProps },
    } = useUploadDragAndDrop(onUpload);

    return isDefined(csvFile)
        ? <div className={styles.documentSection} title={csvFile.filename}>
            <div className={styles.labelWrapper}>
                <Icon name="document" className={styles.documentIcon} />
                <p> {csvFile.filename} </p>
            </div>
            <Button
                size="md"
                variant="destructivePlain"
                iconName="delete"
                onClick={onRemove}
            >
                {t("global.delete")}
            </Button>
        </div>
        : <div className={styles.uploadSection} ref={fileDropArea}>
            <Icon name="upload" className={styles.uploadIcon} />
            <div className={styles.uploadLabelContainer}>
                <span
                    onClick={openFolder}
                    className={styles.clickToUpload}>
                    {t("applications.csvImport.uploadArea.clickToUpload")}
                </span>
                <span className={styles.label}>{t("applications.csvImport.uploadArea.dragDrop")}</span>
            </div>
            <div className={Cn.join([styles.label, styles.fileNote])}>{t("applications.csvImport.uploadArea.fileNote", { size: toSizeString(MAX_FILE_SIZE.CSV_IMPORT) })}</div>
            <OpenFolderInput
                multiple={true}
                accept=".csv"
                openFolderProps={openFolderProps}
            />
        </div>
        ;
}

export {
    UploadDragAndDrop
}