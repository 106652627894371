import { Cn } from "@helpers/unsorted/classNames";
import {
    Input,
    Props as InputProps,
    Size,
    inputSizes,
} from "@shared/unsorted/Input/Input";
import { useCallback, useState, ReactElement } from "react";
import { FieldValues } from "react-hook-form";

type Props<FieldsType extends FieldValues> = Omit<
    InputProps<FieldsType>,
    "type" | "iconName" | "onClickIcon" | "iconPosition" | "unit"
>;

const style = {
    icon: Cn.c("text-primary-default"),
};

const PasswordInput = <FieldsType extends FieldValues>(
    props: Props<FieldsType>
): ReactElement => {
    const [masked, setMasked] = useState<boolean>(true);

    const handleToggleMasked = useCallback(() => {
        setMasked((s) => !s);
    }, []);

    return (
        <Input
            {...props}
            type={masked ? "password" : "text"}
            iconName={masked ? "eye" : "eyeOff"}
            iconClassName={style.icon}
            iconPosition="right"
            onClickIcon={handleToggleMasked}
        />
    );
};

export { PasswordInput, Props, Size, inputSizes };
