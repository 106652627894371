import { Cn } from "@helpers/unsorted/classNames";
import { Label } from "@shared/unsorted/Label/Label";
import { FunctionComponent } from "react";
import { I18nKey } from "react-i18next";

const styles = {
    container: Cn.c("space-y-6"),
    questionLabel: Cn.c("font-paragraph-base-medium"),
    list: Cn.c("space-y-2 font-paragraph-small-regular text-emphasized"),
    question: Cn.c('whitespace-pre-line'),
}

interface Props {
    label: I18nKey;
    questions: string;
}

const PreviewQuestions: FunctionComponent<Props> = ({ label, questions }) => {
    const questionList = questions.trim().split(/\n{2,}/)

    return (
        <div className={styles.container}>
            <Label label={label} labelClassName={styles.questionLabel} />
            <div className={styles.list}>
                {questionList.map((question, index) =>
                    <p className={styles.question} key={`${index}.${question}`}>{`${index + 1}. ${question}`}</p>
                )}
            </div>
        </div>
    );
}

export {
    PreviewQuestions
};

