import { Cn } from '@helpers/unsorted/classNames';
import { DefaultLayout } from '@shared/layout/DefaultLayout/DefaultLayout';
import { Collapsible } from '@shared/unsorted/Collapsible/Collapsible';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Select } from '@shared/unsorted/Select/Select';
import { useTranslation } from 'react-i18next';
import { EventSessionDetail } from './UpcomingEvents/EventSessionDetail';
import { WelcomeModal } from './WelcomeModal/WelcomeModal';
import { useHomePage } from './hook';
import { EventSessionGroupDetail } from './UpcomingEvents/EventSessionGroupDetail';
import { ActivityLogList } from './ActivityLogList/ActivityLogList';

const styles = {
    body: Cn.c('px-6 flex flex-col flex-1 overflow-auto'),
    hello: Cn.c('font-h2-bold p-6 text-emphasized'),
    container: Cn.c('flex space-x-8 overflow-y-auto flex-1'),
    column: Cn.c('flex flex-col flex-1'),
    header: Cn.c('border-b w-full h-16 border-b-default flex items-center justify-between'),
    title: Cn.c('font-paragraph-base-semi-bold text-emphasized'),
    right: Cn.c('flex items-center space-x-1 font-paragraph-small-medium text-emphasized'),
    icon: Cn.c('w-4 h-4 text-icons-emphasized'),
    select: {
        container: Cn.c('w-fit'),
        content: Cn.c('flex-row gap-1 items-center'),
        icon: Cn.c('relative shrink-0 right-0 top-0'),
        options: Cn.c('w-fit max-h-none whitespace-nowrap right-0'),
        input: Cn.c('p-0 border-0'),
    },
    emptyContent: Cn.c('flex flex-col items-center pt-16 font-paragraph-base-semi-bold text-disabled'),
    placeHolderIcon: Cn.c('h-16 w-16 text-icons-subdued'),
    emptyDescription: Cn.c('font-paragraph-base-semi-bold text-placeholder pt-2'),
    eventListContainer: Cn.c("flex flex-col flex-1 overflow-auto"),
    eventListItem: Cn.c("pt-4 pb-2 border-b border-b-default"),
    activityListContainer: Cn.c("flex flex-col flex-1 overflow-auto pt-4"),
    activityListItem: Cn.c("mb-6"),
    collapsibleContent: Cn.c("pt-4 px-6"),
    collapsibleListItem: Cn.c("pt-3"),
};

const HomePage = () => {
    const { t } = useTranslation()

    const {
        clientInfo,
        shouldDisplayWelcomeModal,
        onCloseWelcomeModal,
        eventSessions,
        activityLogsInputs,
        activityLogFilter,
        activityLogDateRangeFilterOptions,
        onChangeActivityLogDateRangeFilter,
        onLoadMoreActivityLogs
    } = useHomePage();

    return (
        <DefaultLayout>
            <p className={styles.hello}>{t("dashboard.title")}</p>
            <div className={styles.body}>
                <div className={styles.container}>
                    <div className={styles.column}>
                        <div className={styles.header}>
                            <p className={styles.title}>{t("dashboard.recentActivity")}</p>
                            {clientInfo?.appConfig?.features?.displayActivityLogs && (
                                <Select
                                    name="dateRangeFilter"
                                    className={styles.select.container}
                                    contentClassName={styles.select.content}
                                    inputClassName={styles.select.input}
                                    iconClassName={styles.select.icon}
                                    optionContainerClassName={styles.select.options}
                                    options={activityLogDateRangeFilterOptions}
                                    value={activityLogFilter.dateRange}
                                    onChange={onChangeActivityLogDateRangeFilter}
                                />
                            )}
                        </div>
                        {clientInfo?.appConfig?.features?.displayActivityLogs
                            ? (
                                <>
                                    {activityLogsInputs.length > 0 && (
                                        <ActivityLogList
                                            listClassName={styles.activityListContainer}
                                            listItemClassName={styles.activityListItem}
                                            initialQueryInput={activityLogsInputs[0]}
                                            subsequentQueryInputs={activityLogsInputs.slice(1)}
                                            loadMoreSize={10}
                                            onLoadMore={onLoadMoreActivityLogs}
                                            renderNoContent={() => (
                                                <div className={styles.emptyContent}>
                                                    <Icon name="noNotification" className={styles.placeHolderIcon} />
                                                    <p className={styles.emptyDescription}>{t("dashboard.activities.noActivity")}</p>
                                                </div>
                                            )}
                                        />
                                    )}
                                </>
                            )
                            : (
                                <div className={styles.emptyContent}>
                                    <Icon name="noNotification" className={styles.placeHolderIcon} />
                                    <p className={styles.emptyDescription}>{t("dashboard.comingSoon")}</p>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.column}>
                        <div className={styles.header}>
                            <p className={styles.title}>{t("dashboard.upcomingEvents.title")}</p>
                        </div>
                        {eventSessions.length === 0 ?
                            <div className={styles.emptyContent}>
                                <Icon name="calendar" className={styles.placeHolderIcon} />
                                <p className={styles.emptyDescription}>{t("dashboard.upcomingEvents.noEvent")}</p>
                            </div>
                            : <ul className={styles.eventListContainer}>
                                {eventSessions.map((eventSession) => (
                                    <li key={eventSession.id} className={styles.eventListItem}>
                                        <Collapsible
                                            header={<EventSessionDetail eventSession={eventSession} />}
                                        >
                                            <ul className={styles.collapsibleContent}>
                                                {eventSession.groups.map((group, index) => (
                                                    <li key={group.id} className={Cn.ifTrue(index > 0, styles.collapsibleListItem)}>
                                                        <EventSessionGroupDetail
                                                            eventSessionGroup={group}
                                                            isOnline={eventSession.event.isOnline}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </Collapsible>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            </div>

            <WelcomeModal isOpen={shouldDisplayWelcomeModal} onCloseModal={onCloseWelcomeModal} />
        </DefaultLayout>
    );
};

export {
    HomePage
};

