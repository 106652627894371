import { createContext, useContext } from "react";
import { Action, State } from "./reducer";

type BulkEventSessionAssignmentContextType = [state: State, dispatch: (value: Action) => void]
const BulkEventSessionAssignmentContext = createContext<BulkEventSessionAssignmentContextType | undefined>(undefined);

const BulkEventSessionAssignmentProvider = BulkEventSessionAssignmentContext.Provider

const useBulkEventSessionAssignmentContext = () => {
    const context = useContext(BulkEventSessionAssignmentContext)
    if (!context) {
        throw new Error('This component must be used inside a <BulkEventSessionAssignmentProvider>...</BulkEventSessionAssignmentProvider> block.')
    }
    return context
}

export {
    BulkEventSessionAssignmentContext,
    BulkEventSessionAssignmentProvider,
    BulkEventSessionAssignmentContextType,
    useBulkEventSessionAssignmentContext,
};