import { I18nKey } from 'react-i18next';
import { RadioInputItemBox, RadioInputItemBoxAction } from '../RadioInputItemBox';
import { FormProps, ForwardedRefProps, HTMLPassedProps } from '@typedefs/props';
import { FieldValues } from 'react-hook-form';
import { FocusEventHandler, ReactElement } from 'react';
import { Cn } from '@helpers/unsorted/classNames';
import { assertNever } from '@helpers/typeHelpers';
import { IconName } from '@shared/unsorted/Icon/Icon';
import { isDefined } from '@helpers/core/typeGuards';
import { useRadioInputItemBoxGroup } from './hook';
import { chunk } from 'lodash';
import { range } from '@helpers/unsorted/arrayExtra';

const radioInputItemBoxLayouts = ['horizontal', 'vertical', 'grid'] as const;

type Layout = typeof radioInputItemBoxLayouts[number];

interface RadioInputItemBoxOptions {
    label: I18nKey | ReactElement<unknown>;
    description: I18nKey | ReactElement<unknown>;
    helperText?: I18nKey | ReactElement<unknown>;
    icon?: IconName;
    value: string;
    disabled: boolean;
    primaryAction?: RadioInputItemBoxAction;
    secondaryAction?: RadioInputItemBoxAction;
}

const styles = {
    root: (layout: Layout, className?: string) => {
        let layoutStyle: string = '';

        switch (layout) {
            case 'horizontal':
                layoutStyle = Cn.c('flex gap-x-3');
                break;
            case 'vertical':
                layoutStyle = Cn.c('flex flex-col gap-y-3');
                break;
            case 'grid':
                layoutStyle = Cn.c('flex flex-col gap-3')
                break;
            default: {
                assertNever(layout);
                break;
            }
        }

        return Cn.join([
            layoutStyle,
            Cn.getSome(className),
        ]);
    },
    rowContainer: Cn.c('flex gap-3'),
    emptyDiv: Cn.c('w-full invisible p-4'),
    error: Cn.c('text-critical-default font-paragraph-small-regular'),
};

interface BaseProps<FieldsType extends FieldValues>
    extends HTMLPassedProps<'value'>,
    FormProps<FieldsType>,
    ForwardedRefProps<HTMLInputElement> {
    /** Layout of the radio input item box group */
    layout: Layout;
    /** Options for the radio input item box group */
    options: RadioInputItemBoxOptions[];
    /** Value of the radio input item box group */
    value?: string;
    /** Optional Additional class name to be applied to the radio input item box's group root container */
    className?: string;
    /** Optional function called when a radio input item box group value changes*/
    onChange?: (event: any) => void; //TODO: rebind correct type
    /** Optional label to be displayed as the error message */
    errorLabel?: I18nKey;
    /** Optional function called when the radio input ite box group is blurred */
    onBlur?: VoidFunction;
    /** Optional function called when the radio input ite box group is focused */
    onFocus?: FocusEventHandler;
}

interface NonGridProps<FieldsType extends FieldValues> extends BaseProps<FieldsType> {
    layout: 'vertical' | 'horizontal';
    gridColumns?: never;
}

interface GridProps<FieldsType extends FieldValues> extends BaseProps<FieldsType> {
    layout: 'grid';
    /** Number of columns of the grid */
    gridColumns: number;
}

type Props<FieldsType extends FieldValues> = NonGridProps<FieldsType> | GridProps<FieldsType>;

const RadioInputItemBoxGroup = <FieldsType extends FieldValues>({
    name,
    layout,
    options,
    onChange,
    value,
    className,
    errors,
    errorLabel,
    forwardedRef,
    onBlur,
    onFocus,
    clearErrors,
    gridColumns,
}: Props<FieldsType>) => {
    const { error, getIsChecked } = useRadioInputItemBoxGroup(name, value, errorLabel, errors);

    const nonGridLayout =
        <div
            className={styles.root(layout, className)}
            onBlur={() => onBlur && onBlur()}
            onFocus={event => {
                clearErrors && clearErrors();
                onFocus && onFocus(event);
            }}
        >
            {options.map(({ label, description, icon, value: radioInputItemBoxValue, disabled, primaryAction, secondaryAction, helperText }, index) =>
                <RadioInputItemBox
                    key={index}
                    label={label}
                    description={description}
                    helperText={helperText}
                    checked={getIsChecked(radioInputItemBoxValue)}
                    iconName={icon}
                    primaryAction={primaryAction}
                    secondaryAction={secondaryAction}
                    disabled={disabled}
                    value={radioInputItemBoxValue}
                    onChange={onChange}
                    forwardedRef={forwardedRef}
                    prefix={name}
                />
            )}
        </div>
        ;

    const gridLayout = () => {
        if (layout !== 'grid') return null;

        if (gridColumns < 1) {
            throw new Error('Invalid gridColumns value. gridColumns must be greater than 0');
        }

        const chunkedOptions = chunk(options, gridColumns);

        return (
            <div
                className={styles.root(layout, className)}
                onBlur={() => onBlur && onBlur()}
                onFocus={event => {
                    clearErrors && clearErrors();
                    onFocus && onFocus(event);
                }}
            >
                {chunkedOptions.map((row, index) =>
                    <div key={index} className={styles.rowContainer}>
                        {row.map(({ label, description, icon, value: radioInputItemBoxValue, disabled, primaryAction, secondaryAction, helperText }, optionIndex) =>
                            <RadioInputItemBox
                                key={optionIndex}
                                label={label}
                                description={description}
                                helperText={helperText}
                                checked={getIsChecked(radioInputItemBoxValue)}
                                iconName={icon}
                                primaryAction={primaryAction}
                                secondaryAction={secondaryAction}
                                disabled={disabled}
                                value={radioInputItemBoxValue}
                                onChange={onChange}
                                forwardedRef={forwardedRef}
                                prefix={name}
                            />
                        )}
                        {/* Use filler divs to match the widths of options from other rows. This is a temporary solution and can be changed once a layout like this is used */}
                        {row.length < gridColumns && range(gridColumns - row.length).map((_, i) => <div key={i} className={styles.emptyDiv} />)}
                    </div>
                )}
            </div>
        );
    };

    return (<>
        {layout != 'grid' ? nonGridLayout : gridLayout()}
        {/* TODO: Currently, there's no defined error message in the design. This is added as a placeholder in case we need to add it in the future */}
        {isDefined(error) && <div className={styles.error}>{error}</div>}
    </>);
};

export {
    RadioInputItemBoxGroup,
    RadioInputItemBoxOptions,
    radioInputItemBoxLayouts,
};