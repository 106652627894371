import { FunctionComponent } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';

const styles = {
    row: Cn.c('text-default flex flex-row flex-1 items-center gap-x-2'),
    label: Cn.c('font-paragraph-small-medium text-subdued w-40'),
    value: Cn.c('font-paragraph-small-regular whitespace-pre-wrap'),
};

interface Props {
    label: I18nKey;
    value: string;
}

const CandidateInformationField: FunctionComponent<Props> = ({ label, value }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.row}>
            <div className={styles.label}>{t(label)}</div>
            <div className={styles.value}>{value}</div>
        </div>
    );
};

export {
    CandidateInformationField,
};
