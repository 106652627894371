import { Event_OneByIdQuery } from '@entities';
import { assertNever } from '@helpers/typeHelpers';
import { Entity } from '@typedefs/graphql';
import { getDate, isAfter, isBefore } from 'date-fns';
import { useMemo, useState } from 'react';

type EventStatusType = 'completed' | 'ongoing' | 'upcoming';

interface EventDetailsHookType {
    hideCompletedSessions: boolean;
    toggleHideCompletedSessions: VoidFunction;
    filteredEventSessions: Entity<Event_OneByIdQuery, 'event.sessions'>[];
    getEventSessionStatus: (eventSession: Entity<Event_OneByIdQuery, 'event.sessions'>) => EventStatusType | undefined;
}

const useEventDetails = (event: Entity<Event_OneByIdQuery, 'event'>): EventDetailsHookType => {
    const [hideCompletedSessions, setHideCompletedSessions] = useState(false);

    const toggleHideCompletedSessions = () => setHideCompletedSessions(shouldHideCompletedSessions => !shouldHideCompletedSessions);

    const filteredEventSessions = useMemo(() => {
        return hideCompletedSessions
            ? event.sessions.filter(({ endAt }) => isBefore(new Date(), new Date(endAt)))
            : event.sessions;
    }, [hideCompletedSessions, event.sessions]);

    const getEventSessionStatus = (eventSession: Entity<Event_OneByIdQuery, 'event.sessions'>): EventStatusType | undefined => {
        if (isBefore(new Date(eventSession.endAt), new Date())) {
            return "completed"
        }

        if (isAfter(new Date(eventSession.startAt), new Date())) {
            return "upcoming"
        }

        if (isBefore(new Date(eventSession.startAt), new Date()) && isAfter(new Date(eventSession.endAt), new Date())) {
            return "ongoing"
        }
    };

    return {
        hideCompletedSessions,
        toggleHideCompletedSessions,
        filteredEventSessions,
        getEventSessionStatus,
    };
};

export {
    EventStatusType,
    useEventDetails,
};

