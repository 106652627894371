import { hasValue } from "@helpers/core/typeGuards";
import { useMemo } from 'react';
import { AnyVariables, CombinedError, UseQueryState } from "urql";

const getAllErrorCode = (error: CombinedError | undefined) => {
    if(error) {
        return error.graphQLErrors.map(({extensions}) => {
            return extensions.code
        })
    }

    return []
}

const hasErrorCode = (error: CombinedError | undefined, code: string) => { 
    return getAllErrorCode(error).includes(code)
}

const responseHasError = <T, U extends AnyVariables>(response: UseQueryState<T, U>) => {
    return !response.fetching && hasValue(response.error)
}

//TODO: bind more specific type instead of any
const handleResponse = <T, U  extends AnyVariables>(
    response: UseQueryState<T, U>, 
    {onFetching, onData, onError}: {onFetching?: () => void, onData?: (data: T) => void, onError?: (error: CombinedError| undefined) => void}) => {
    const { fetching, data, error} = response

    if(fetching) {
        onFetching && onFetching()

        return
    }

    if(hasValue(error)) {
        onError && onError(error)

        return
    }

    if(hasValue(data)) {
        onData && onData(data)

        return
    }
}

const getQueryContext = (typeName: string | string[]) => ({
    additionalTypenames: Array.isArray(typeName) ? typeName: [typeName],
});
const useQueryContext = (typeName: string | string[]) => {
    return useMemo(() => getQueryContext(typeName), []);
};

export {
    hasErrorCode,
    handleResponse,
    getQueryContext,
    useQueryContext,
    responseHasError,
}
