import { RefObject, useRef, useState } from 'react';
import { useClickOutside } from '@helpers/hooks/unsorted/clickOutsideHook';
import { RecruitmentProcess_OneByIdQuery } from '@entities';
import { Entity } from '@typedefs/graphql';
import { ActionType } from '../hook';

type Mode = 'show' | 'hide';

interface OtherActionsHookResult {
    actionListDisplayMode: Mode;
    otherActionsRef: RefObject<HTMLDivElement>;
    toggleOtherActionList: VoidFunction;
    onClickOtherAction: (
        otherAction: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.otherActions'>[number],
    ) => void;
}

const useOtherActions = (
    onAction: (otherAction: ActionType) => void,
): OtherActionsHookResult => {
    const [actionListDisplayMode, setActionListDisplayMode] = useState<Mode>('hide');

    const otherActionsRef = useRef<HTMLDivElement>(null);

    useClickOutside([otherActionsRef], () => setActionListDisplayMode('hide'));

    const toggleOtherActionList = () => actionListDisplayMode === 'hide' ? setActionListDisplayMode('show') : setActionListDisplayMode('hide');

    const onClickOtherAction = (
        otherAction: Entity<RecruitmentProcess_OneByIdQuery, 'recruitmentProcess.currentStepStatusActions.mainActions'>[number],
    ) => {
        onAction(otherAction);
        setActionListDisplayMode('hide');
    };


    return {
        actionListDisplayMode,
        otherActionsRef,
        toggleOtherActionList,
        onClickOtherAction,
    };
};

export {
    Mode,
    useOtherActions,
};
