import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props";
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";

const styles = {
    title: Cn.c('font-h3-bold text-emphasized px-6 py-4 border-b border-b-default'),
    body: Cn.c('p-6 space-y-4'),
    description: Cn.c('font-paragraph-small-regular text-default whitespace-pre-line'),
    buttons: Cn.c('flex flex-row space-x-4 justify-end px-6 py-4 bg-surface-subdued'),
};

interface Props extends ModalProps {
    onReject: VoidFunction;
    onConfirm: VoidFunction;
}

const SubmitConfirmationModal: FunctionComponent<Props> = ({
    isOpen,
    onReject,
    onConfirm
}) => {
    const { t } = useTranslation();
    
    return (
        <Modal close={() => { return }} isOpen={isOpen}>
            <div className={styles.title}>{t("applications.panel.evaluateApplication.submissionTitle")}</div>
            <div className={styles.body}>
                <p className={styles.description}>
                    {t("applications.panel.evaluateApplication.submissionDescription")}
                </p>
            </div>
            <div className={styles.buttons}>
                <Button size="md" variant="secondary" onClick={onReject}>
                    {t("applications.panel.evaluateApplication.backToEvaluation")}
                </Button>
                <Button size="md" isLoading={false} variant="primaryFilled" onClick={onConfirm}>
                    {t("applications.panel.evaluateApplication.submit")}
                </Button>
            </div>
        </Modal>
    );
}

export {
    SubmitConfirmationModal
}