const MAX_RANGE_SIZE = 1000;
const range = (size: number, startAt = 0): readonly number[] => {
    if (size > MAX_RANGE_SIZE) {
        throw new Error(`Size must be less than or equal to ${MAX_RANGE_SIZE}`);
    }

    return Array.from({ length: size }, (_, i) => i + startAt);
}

const removeDuplicates = <ItemType extends string | number | boolean>(list: ItemType[]) => {
    return list.filter((item, index) => list.indexOf(item) === index);
};

/**
 * Implementation of the Fisher-Yates shuffle algorithm.
 * Note: This function will NOT mutate the original array.
 * @param array The array to shuffle
 * @returns A new array with the same items as the original array, but in a random order
 */
const shuffle = <ItemType>(array: ItemType[]) => {
    const shuffled = [...array];
    let currentIndex = array.length;
    let randomIndex: number;
    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [shuffled[currentIndex], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[currentIndex]];
    }

    return shuffled;
};

const findLastIndex = <T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number => {
    let index = array.length;
    while (index--) {
        if (predicate(array[index], index, array)) {
            return index;
        }
    }

    return -1;
}

const splitToChunks = <T>(array: T[], chunkSize: number): T[][] => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}

const nub = <A>(array: A[], eq: (x: A, y: A) => boolean): A[] => {
    const out: A[] = [];
    parentLoop: for (const x of array) {
        for (const y of out) {
            if (eq(x, y)) {
                continue parentLoop;
            }
        }
        out.push(x);
    }
    return out;
};

export {
    MAX_RANGE_SIZE,
    range,
    removeDuplicates,
    shuffle,
    findLastIndex,
    splitToChunks,
    nub
};
