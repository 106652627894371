import { ChildrenProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { Client, Provider } from 'urql';

interface Props extends ChildrenProps {
  client: Client
}

const GraphqlProvider: FunctionComponent<Props> = ({ children, client }) => {
  return <Provider value={client}>{children}</Provider>;
};

export {
  GraphqlProvider
};