import { Cn } from "@helpers/unsorted/classNames"
import { Dropdown } from "@shared/unsorted/Dropdown/Dropdown"
import { NestedMenu } from "@shared/unsorted/NestedMenu/NestedMenu"
import { FunctionComponent } from "react"
import { useBulkActionMenu } from "./hook"
import { useTranslation } from "react-i18next"

const styles = {
    menuContainer: Cn.c("right-[175%]"),
    bulkActionsDisabledTooltip: Cn.c("w-full"),
}

const BulkActionMenu: FunctionComponent = () => {
    const {
        bulkActionMenu,
        onBulkActionChange,
    } = useBulkActionMenu()

    const { t } = useTranslation();

    return (
        <Dropdown
            name="bulkActions"
            text={t("applications.list.table.bulkActions")}
            size="small"
            menuClassName={styles.menuContainer}
        >
            <NestedMenu
                menu={{
                    groups: bulkActionMenu,
                    item: {
                        tooltip: {
                            placement: 'left',
                            toolTipClassName: styles.bulkActionsDisabledTooltip,
                        }
                    }
                }}
                size="small"
                submenuPosition="left"
                onChange={onBulkActionChange}
            />
        </Dropdown>
    )
}

export {
    BulkActionMenu
}