import { useNavigate } from "react-router-dom";

interface ModalHookType {
    closeModal: VoidFunction
}

const useModal = (): ModalHookType => {
    const navigate = useNavigate();

    const closeModal = () => navigate(-1)

    return {
        closeModal
    }
}

export {
    useModal
}