import { FieldError, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isDefined, isNullish, isString, isSome, isUndefined, isObject } from '@helpers/core/typeGuards';

const getError = (errors: FieldErrors | undefined, path: string): FieldError | undefined => {
    // This type guard is declared here to remove any risk of it being used anywhere else in the codebase.
    // This is because it is not technically a proper type guard for FieldError but just necessary to narrow
    // types for the sake of this function.
    const isFieldErrorLike = (value: unknown): value is FieldError =>
        isObject(value)
        && isString(value.type)
        && isSome(isString, isUndefined)(value.message);

    const splitPath = path.split('.');
    let found: FieldErrors | FieldError | undefined = errors;
    for (const pathSegment of splitPath) {
        if (isNullish(found) || isFieldErrorLike(found)) {
            break;
        } else {
            found = found[pathSegment];
        }
    }

    return isFieldErrorLike(found) ? found : undefined;
};

const useErrorMessage = (name: string, errors?: FieldErrors, label?: string) => {
    const { t } = useTranslation();

    const errorLabel = label || name;

    const getErrorMessageLabel = (errorMessage?: string) => {
        switch (errorMessage) {
            case 'expectedNewPassword': return t('form.errors.expectedNewPassword');
            case 'invalidFormat': return t('form.errors.invalidFormat', { fieldName: errorLabel });
            case 'passwordMismatch': return t('form.errors.expectedNewPassword');
            case 'requiredStepName': return t('form.errors.stepNameRequired');
            case 'requiredEventName': return t('form.errors.eventNameRequired');
            case 'requiredReservationFrom': return t('form.errors.reservationFromRequired');
            case 'requiredReservationTo': return t('form.errors.reservationToRequired');
            case 'requiredDaysPrior': return t('form.errors.daysPriorRequired');
            case 'requiredCutoffTime': return t('form.errors.cutoffTimeRequired');
            case 'requiredExpirationLink': return t('form.errors.expirationLinkRequired');
        }
    };

    if (isDefined(errors)) {
        const error = getError(errors, name);
        const errorType = isDefined(error) ? error.type : 'noError';
        const errorMessage = isDefined(error) ? error.message : 'noErrorMessage';

        switch (errorType) {
            case 'validate':
                return getErrorMessageLabel(errorMessage) || errorMessage;
            case 'required':
                return getErrorMessageLabel(errorMessage) || t('form.errors.required', { fieldName: errorLabel });
            case 'wrongDate':
                return t('form.errors.wrongDate');
            case 'invalidDateTimesSet':
            case 'wrongDateTimeFormat':
            case 'wrongDateTimeValue':
            case 'wrongDateTimesSet':
                return t('form.errors.wrongDateTime');
            case 'wrongPassword':
                return t('form.errors.wrongPassword');
            case 'conflictingDate':
                return t('form.errors.conflictingDate');
            case 'isUniqueLocationBetweenGroups':
            case 'isUniqueLocationBetweenSessions':
                return t('form.errors.duplicateSessionUrl');
            case 'isUniqueLocationBetweenEvents':
                return t('form.errors.reusedSessionUrl');
            case 'noError':
                return undefined;
            case 'unknown':
            default:
                return t('form.errors.unknown', { fieldName: errorLabel });
        }
    }

    return undefined;
};

export {
    useErrorMessage,
};
