import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { TextArea } from '@shared/unsorted/TextArea/TextArea';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useApplicationNote } from './hook';


const styles = {
    container: Cn.c("border-t border-b border-default mx-4 p-4"),
    addWrapper: Cn.c("flex items-center gap-x-2 cursor-pointer"),
    addIcon: Cn.c("text-icons-primary-default w-5 h-5"),
    addTitle: Cn.c("font-paragraph-small-medium text-default"),
    editHeader: Cn.c("flex items-center justify-between pb-2.5"),
    editTitle: Cn.c("font-paragraph-small-medium text-default"),
    editText: Cn.c("font-paragraph-small-regular text-default"),
    formButtons: Cn.c("space-x-2 flex mt-6"),
    noteLink: Cn.c("underline font-paragraph-small-regular text-primary-default"),
};

const linkDecorator = (decoratedHref: string, decoratedText: string, key: number) =>
    <a href={decoratedHref} key={key} target="_blank" className={styles.noteLink} rel="noreferrer">{decoratedText}</a>;

interface Props {
    id: string;
    note?: string;
}

const ApplicationNote: FunctionComponent<Props> = ({ id, note }) => {
    const { t } = useTranslation();

    const {
        mode,
        toEdit,
        toShow,
        onSubmit,
        form: { clearErrors, control, formState: { errors } },
        isSubmitting,
    } = useApplicationNote(id, note);

    return (
        <div className={styles.container}>
            {mode === "show" ?
                isDefined(note) ? <div>
                    <div className={styles.editHeader}>
                        <p className={styles.editTitle}>{t("applications.panel.note.title")}</p>
                        <Icon name="edit" className={styles.addIcon} onClick={() => toEdit()} />
                    </div>
                    <Linkify componentDecorator={linkDecorator}>
                        <p className={styles.editText}>{note}</p>
                    </Linkify>
                </div>
                    : <div className={styles.addWrapper} onClick={() => toEdit()}>
                        <Icon name="plus" className={styles.addIcon} />
                        <p className={styles.addTitle}>{t("applications.panel.note.add")}</p>
                    </div>
                : <form onSubmit={onSubmit}>
                    <Controller
                        name="note"
                        control={control}
                        render={({ field: { name, onBlur, onChange, ref, value } }) => (
                            <TextArea
                                name={name}
                                orientation="vertical"
                                label={"applications.panel.note.title"}
                                onBlur={onBlur}
                                onChange={onChange}
                                errors={errors}
                                clearErrors={() => clearErrors("note")}
                                value={value}
                                forwardedRef={ref}
                            />
                        )}
                    />
                    <div className={styles.formButtons}>
                        <Button size="md" variant="secondary" isDisabled={isSubmitting} onClick={() => toShow()}>{t("global.cancel")}</Button>
                        <Button size="md" type="submit" variant="primaryFilled" isLoading={isSubmitting}>{t("global.submit")}</Button>
                    </div>
                </form>
            }
        </div>
    );
};

export {
    ApplicationNote
};
