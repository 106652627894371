import { allRecruitmentStages, allRecruitmentSteps } from '@helpers/core/constants';
import { RecruitmentStageType, RecruitmentStepType } from '@typedefs/aliases';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

namespace Encoder {
    export interface ItemValues {
        item_id: string;
        name: string;
        type: RecruitmentStepType;
        stage: RecruitmentStageType;
        hasDecision: boolean;
        hasEvaluation: boolean;
        hasNotification: boolean;
        previousStepId?: string | null;
        nextStepId?: string | null;
    }

    export interface EncoderType {
        title: string;
        recruitmentSteps: ItemValues[];
        documentRequirements: string;
    }

    type Item = {
        stage: RecruitmentStageType;
        id?: string;
        name?: string;
        type?: RecruitmentStepType;
        hasDecision?: boolean;
        hasEvaluation?: boolean;
        hasNotification?: boolean;
        previousStepId?: string | null;
        nextStepId?: string | null;
    };

    export const makeItem = (item: Item): ItemValues => {
        const { stage, id, name, type, hasDecision, hasEvaluation, hasNotification, previousStepId, nextStepId } = item;

        return {
            item_id: id ?? '',
            name: name ?? '',
            type: type ?? 'DOCUMENT',
            stage,
            hasDecision: hasDecision ?? false,
            hasEvaluation: hasEvaluation ?? false,
            hasNotification: hasNotification ?? false,
            previousStepId: previousStepId ?? null,
            nextStepId: nextStepId ?? null,
        };
    }

    export const defaultValues = (): EncoderType => {
        const recruitmentResultId = uuidv4(); // TODO: Verify if this approach is correct
        const archivedStepId = uuidv4(); // TODO: Verify if this approach is correct

        const recruitmentResultStep = makeItem({
            id: recruitmentResultId,
            stage: 'PRE_EMPLOYMENT',
            name: 'Recruitment Result',
            type: 'SYSTEM',
            nextStepId: archivedStepId,
        });

        // TODO: Check if this can be handled in API side. If handled in API, handling of moving steps should be updated as well
        const archivedStep = makeItem({
            id: archivedStepId,
            stage: 'ARCHIVE',
            name: 'Archived',
            type: 'SYSTEM',
            previousStepId: recruitmentResultId,
        });

        return {
            title: '',
            recruitmentSteps: [
                recruitmentResultStep,
                archivedStep,
            ],
            documentRequirements: '',
        };
    }
}

namespace Decoder {
    const recruitmentStepSchema = z.object({
        item_id: z.string().nullish(),
        name: z.string(),
        type: z.enum(allRecruitmentSteps),
        stage: z.enum(allRecruitmentStages),
        hasDecision: z.boolean(),
        hasEvaluation: z.boolean(),
        hasNotification: z.boolean()
    }).transform(({ item_id, ...restStep }) => ({ ...restStep, id: item_id }));

    export const schema = z.object({
        title: z.string().min(1),
        recruitmentSteps: z.array(recruitmentStepSchema),
        documentRequirements: z.string(),
    }).transform((values) => ({
        ...values,
        documentRequirements: values.documentRequirements || undefined,
    }));

    export type DecoderType = z.infer<typeof schema>;
}

export {
    Decoder,
    Encoder
};

