// TODO: This is just a temporary equivalent of what was done in ReScript to keep all the tailwind classes wrapped
//       until we figure out a better way to validate the tailwind classes we use (harder to do now with JIT)

import { isNullish } from '@helpers/core/typeGuards';
import { removeDuplicates } from '@helpers/unsorted/arrayExtra';
import { twMerge } from 'tailwind-merge';

// @ocaml.doc("While class names are strings at runtime,
// you should not use strings directly but rather the utility functions provided here.")

// @ocaml.doc("You must use this function when adding css class names
// in order to help Tailwind to purge the css file accordingly")
const c = (className: string) => twMerge(className);

const extractClassList = (classNames: string[]) => classNames.reduce<string[]>((extractedList, className) => [
    ...extractedList,
    ...className
        .split(' ')
        .reduce<string[]>((cleanList, item) =>
            item.trim().length === 0 ? cleanList : [ ...cleanList, item.trim() ],
        []),
], []);

// @ocaml.doc("Joins an array of class names into a valid css string")
const join = (classNames: string[]) => twMerge(classNames);

// TODO: Make sense of what this function does in ReScript and if it is still
//       needed in the TS code  (Some being a specific ReScript concept)
// @ocaml.doc("Get the some value of an optional class name.
//
// Example:
//
// ```
// Some(Cn.c(\"flex\"))->Cn.getSome
// ```
// ")
const getSome = (className: string | undefined | null) => isNullish(className) ? '' : className;

// @ocaml.doc("Adds a class name only if a condition is true.
//
// _See also `ifFalse`._
//
// Example:
//
//     ```
// Cn.c(\"flex\")->Cn.ifTrue(true)
// ```
// ")
const ifTrue = (condition: boolean, className: string) => condition ? className : '';

// @ocaml.doc("Adds a class name only if a condition is false.
//
// _See also `ifTrue`._
//
// Example:
//
//     ```
// Cn.c(\"flex\")->Cn.ifFalse(false)
// ```
// ")
const ifFalse = (condition: boolean, className: string) => ifTrue(!condition, className);

const Cn = {
    c,
    join,
    getSome,
    ifTrue,
    ifFalse,
};

export {
    Cn,
};
