import { Event_OneByIdQuery } from "@entities";
import { SupportedLanguage } from "@helpers/core/i18n";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { Entity } from "@typedefs/graphql";

const getEventSessionName = (eventSession: { startAt: string, endAt: string }, currentLanguage: SupportedLanguage) => {
    const { startAt, endAt } = eventSession;

    return `${dateFormat.simpleDate(new Date(startAt), '-', currentLanguage)}, ${dateFormat.simpleTime(new Date(startAt))}~${dateFormat.simpleTime(new Date(endAt))}`;
}

export {
    getEventSessionName,
}