import { assertNever } from "@helpers/typeHelpers";
import { isDefined } from "@helpers/core/typeGuards";
import { Cn } from "@helpers/unsorted/classNames";
import {
    ToolTipBox,
    ToolTipBoxProps,
    TooltipPlacement,
} from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { Label } from "@shared/unsorted/Label/Label";
import { IconName } from "@shared/unsorted/Icon/Icon";
import { useMemo } from "react";
import { FieldValues, FieldPath, FieldErrors } from "react-hook-form";
import { I18nKey } from "react-i18next";
import {
    useDropdown,
    UseDropdownStateInput,
    UseDropdownStateOutput,
} from "./hook";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import { DropdownToggle } from "./DropdownToggle/DropdownToggle";
import { Size, sizes } from "./styles";

const styles = {
    container: Cn.c("relative"),
    inputLabel: (size: Size) =>
        Cn.join([
            Cn.c("mb-1 text-emphasized"),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("font-paragraph-xsmall-medium");
                    }
                    case "medium": {
                        return Cn.c("font-paragraph-small-medium");
                    }
                    case "large":
                    case "x-large": {
                        return Cn.c("font-paragraph-base-medium");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    toggle: (error: boolean) =>
        Cn.ifTrue(error, Cn.c("border-critical-default mb-1")),
    error: (size: Size) =>
        Cn.join([
            Cn.c("text-critical-default"),
            (() => {
                switch (size) {
                    case "small": {
                        return Cn.c("font-paragraph-xsmall-regular");
                    }
                    case "medium": {
                        return Cn.c("font-paragraph-small-regular");
                    }
                    case "large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    case "x-large": {
                        return Cn.c("font-paragraph-base-regular");
                    }
                    default: {
                        assertNever(size);

                        return "";
                    }
                }
            })(),
        ]),
    placeholder: Cn.c("text-placeholder"),
};

type Props<FieldsType extends FieldValues> = UseDropdownStateInput & {
    className?: string;
    children:
    | React.ReactNode
    | ((state: UseDropdownStateOutput) => React.ReactNode);
    name: FieldPath<FieldsType>;
    text: React.ReactNode;
    size?: Size;
    required?: boolean;
    label?: I18nKey;
    labelClassName?: string;
    menuClassName?: string;
    iconName?: IconName;
    tooltip?: I18nKey;
    tooltipPlacement?: TooltipPlacement;
    placeholder?: I18nKey;
    errors?: FieldErrors<FieldsType>;
    errorLabel?: I18nKey;
    disabled?: boolean;
    disabledTooltip?: ToolTipBoxProps;
    lazy?: boolean;
    lazyBehavior?: "unmount" | "keepMounted";
};

const Dropdown = <FieldsType extends FieldValues>(props: Props<FieldsType>) => {
    const {
        className = "",
        children: childrenOrFn,
        size = "medium",
        name,
        iconName,
        text,
        required = false,
        errors,
        label,
        labelClassName,
        tooltip,
        tooltipPlacement,
        errorLabel,
        placeholder: placeholderKey,
        disabled = false,
        disabledTooltip,
        isOpen: initialIsOpen,
        menuClassName,
        onOpen,
        onClose,
        lazy,
        lazyBehavior,
    } = props;
    const { isOpen, open, close, placeholder, error, menuRef, toggleRef } =
        useDropdown({
            isOpen: initialIsOpen,
            onOpen,
            onClose,
            name,
            label,
            errorLabel,
            errors,
            placeholder: placeholderKey,
        });
    const toggle = (
        <DropdownToggle
            isOpen={isOpen}
            open={open}
            close={close}
            size={size}
            iconName={iconName}
            disabled={disabled}
            className={styles.toggle(!!error)}
            ref={toggleRef}
        >
            {text || (
                <span className={styles.placeholder}>
                    {placeholder}
                </span>
            )}
        </DropdownToggle>
    );

    const children = useMemo(
        () =>
            typeof childrenOrFn === "function"
                ? childrenOrFn({ isOpen, open, close })
                : childrenOrFn,
        [isOpen, open, close, childrenOrFn]
    );

    return (
        <div className={Cn.join([styles.container, className])}>
            {isDefined(label) && (
                <Label
                    label={label}
                    labelClassName={Cn.join([
                        styles.inputLabel(size),
                        Cn.getSome(labelClassName),
                    ])}
                    required={required}
                    tooltip={tooltip}
                    tooltipPlacement={tooltipPlacement}
                />
            )}
            {disabledTooltip && disabled ? (
                <ToolTipBox {...disabledTooltip}>{toggle}</ToolTipBox>
            ) : (
                toggle
            )}
            {isDefined(error) && <p className={styles.error(size)}>{error}</p>}
            <DropdownMenu
                isOpen={isOpen}
                size={size}
                lazy={lazy}
                lazyBehavior={lazyBehavior}
                ref={menuRef}
                className={menuClassName}
            >
                {children}
            </DropdownMenu>
        </div>
    );
};

export { Dropdown, Size, sizes };
