import { Event_OneByIdQuery } from "@entities";
import { JobListPanel } from "@shared/job_position/JobListPanel/JobListPanel";
import { JobListPanelContext } from "@shared/job_position/JobListPanel/JobListPanelContext";
import { useJobListPanelDisplay } from "@shared/job_position/JobListPanel/useJobListPanelDisplay";
import { Entity } from "@typedefs/graphql";
import { FunctionComponent } from "react";
import { EventSettingProvider } from "@shared/event/EventSettingsModal/EventSettingContext";
import { EventSettingsModal } from "@shared/event/EventSettingsModal/EventSettingsModal";
import { EventDetailViewMode } from "./EventDetailViewMode/EventDetailViewMode";
import { InitialStateData } from "../init";

interface Props extends InitialStateData {
}

const EventDetailSuccessState: FunctionComponent<Props> = ({
    event
}) => {
    const { hideJobListPanel, showJobListPanel, displayMode: jobListPanelDisplayMode } = useJobListPanelDisplay()

    return (
        <JobListPanelContext.Provider value={{ displayMode: jobListPanelDisplayMode, showJobListPanel, hideJobListPanel }}>
            <JobListPanel
                isOpen={jobListPanelDisplayMode === "show"}
                currentJobId={event.recruitmentStepV2.jobPosition.id}
            />
            <EventSettingProvider>
                <EventDetailViewMode event={event} />
                <EventSettingsModal />
            </EventSettingProvider>
        </JobListPanelContext.Provider>
    )
}

export {
    EventDetailSuccessState
}