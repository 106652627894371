import { useEffect, useState } from 'react';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useMutation } from 'urql';
import { ApplicationModalMode } from '@pages/ApplicationsPage/reducer';
import { getQueryContext, handleResponse } from '@helpers/unsorted/urqlExtra';
import { RevertArchivedApplicationsToActiveDocument } from '@entities';
import { isDefined } from '@helpers/core/typeGuards';

interface ApplicationRevertModalHookResult {
    isSubmitting: boolean;
    submit: VoidFunction;
}

const useApplicationRevertModal = (
    closeModal: VoidFunction,
    applicationId?: string,
): ApplicationRevertModalHookResult => {

    const { error: toastError, success: toastSuccess } = useToast();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [revertArchivedApplicationsResponse, revertArchivedApplications] = useMutation(RevertArchivedApplicationsToActiveDocument);

    const queryContext = getQueryContext([
        'RecruitmentProcess',
        'RecruitmentProcesses',
        'ApplicationApplicableAction',
        'OngoingRecruitmentProcessSummary',
        'ArchivedRecruitmentProcessSummary',
    ])

    const submit = () => {
        isDefined(applicationId) &&
            revertArchivedApplications({
                input: {
                    applicationIds: [applicationId],
                }
            }, queryContext);
    };

    useEffect(() => {
        handleResponse(revertArchivedApplicationsResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false);

                if (data.revertArchivedApplicationsToActive.success === data.revertArchivedApplicationsToActive.total) {
                    toastSuccess('applications.panel.revertApplication.success');
                    closeModal();
                } else {
                    toastError('applications.panel.revertApplication.error');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('applications.panel.revertApplication.error');
            },
        });
    }, [revertArchivedApplicationsResponse]);

    return {
        isSubmitting,
        submit,
    };
};

export {
    useApplicationRevertModal,
};