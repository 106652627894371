import { JobPosition_OneByIdWithStepEventsDocument, JobPosition_OneByIdWithStepEventsQuery } from "@entities"
import { handleResponse, useQueryContext } from "@helpers/unsorted/urqlExtra"
import { JobPositionDetail } from "@routes/jobPosition"
import { AsyncResponseType } from "@typedefs/aliases"
import { Entity } from "@typedefs/graphql"
import { useEffect, useState } from "react"
import { useQuery } from "urql"

type InitialStateData = {
    jobPosition: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition'>
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = () => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const { id } = JobPositionDetail.useSearchParams();

    const jobPositionQueryContext = useQueryContext(['JobPosition', 'Event']);
    const [jobPositionResponse,] = useQuery({
        query: JobPosition_OneByIdWithStepEventsDocument,
        variables: {
            id
        },
        context: jobPositionQueryContext
    });

    useEffect(() => {
        handleResponse(
            jobPositionResponse,
            {
                onData: (data) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                jobPosition: data.jobPosition
                            }
                        }
                    })
                },
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [jobPositionResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}