import { FunctionComponent } from 'react';
import { I18nKey, useTranslation } from 'react-i18next';

import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { EmptyStateIcon, EmptyStateIconName } from './EmptyStateIcon';

const styles = {
    container: Cn.c('flex flex-col items-center justify-center h-full'),
    iconWrapper: Cn.c('w-[7.5rem] h-[7.5rem] bg-default flex items-center justify-center rounded-full'),
    icon: Cn.c('w-16 h-16 text-icons-subdued'),
    description: Cn.c('font-h4-bold text-emphasized pt-8'),
    subDescription: Cn.c('font-paragraph-small-regular text-subdued pt-2 whitespace-pre-line text-center  w-[56.625rem]'),
    children: Cn.c('pt-6'),
};

interface Props extends ChildrenProps {
    icon: EmptyStateIconName;
    description: string;
    subDescription: I18nKey;
}

const EmptyPageContainer: FunctionComponent<Props> = ({ icon, description, subDescription, children }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.iconWrapper}>
                <EmptyStateIcon name={icon} className={styles.icon} />
            </div>
            <p className={styles.description}>{description}</p>
            <p className={styles.subDescription}>{t(subDescription)}</p>
            <div className={styles.children}>
                {children}
            </div>
        </div>
    );
};

export {
    EmptyPageContainer
};

