import { useApplicationSelectionContext } from "@shared/application/bulkActions/ApplicationSelectionContext"
import { useBulkActionModalContext } from "../BulkActionModalContext"
import { isAssignToEventSessionGroupProperty } from "@shared/application/bulkActions/helpers"

const useBulkEventSessionAssignmentOptionModal = (recruitmentStepId: string) => {
    const { applicationSelection } = useApplicationSelectionContext()

    const { dispatchBulkActionModalMode } = useBulkActionModalContext()

    const proceedAllApplications = () => {
        const applications = applicationSelection['ASSIGN_TO_SESSION_MANUALLY']

        if (!applications) {
            return
        }

        const applicationIds = Object.keys(applications);
        dispatchBulkActionModalMode({
            name: 'showBulkEventSessionAssignmentModal',
            payload: {
                applicationIds,
                recruitmentStepId
            }
        })
    }

    const proceedUnassignedApplicationsOnly = () => {
        const applications = applicationSelection['ASSIGN_TO_SESSION_MANUALLY']

        if (!applications) {
            return
        }

        const applicationIds = Object
            .entries(applications)
            .filter(([_, property]) => isAssignToEventSessionGroupProperty(property) && property.currentEventSessionGroupAssignments.length === 0)
            .map(([id]) => id)

        dispatchBulkActionModalMode({
            name: 'showBulkEventSessionAssignmentModal',
            payload: {
                applicationIds,
                recruitmentStepId
            }
        })
    }

    return {
        proceedAllApplications,
        proceedUnassignedApplicationsOnly
    }
}

export {
    useBulkEventSessionAssignmentOptionModal
}