import { FunctionComponent } from "react"
import { useInitialState } from "./init"
import { useEventDetailPage } from "./hook"
import { EventDetailLoadingState } from "./EventDetailLoadingState/EventDetailLoadingState"
import { EventDetailSuccessState } from "./EventDetailSuccessState/EventDetailSuccessState"

const EventDetailPage: FunctionComponent = () => {
    const { initialState } = useInitialState()
    useEventDetailPage(initialState)

    return initialState.isLoading
        ? <EventDetailLoadingState />
        : !initialState.result.isSuccess
            ? null //TODO: Add error component
            : <EventDetailSuccessState
                event={initialState.result.data.event}
            />
}

export {
    EventDetailPage
}