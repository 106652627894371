import { JobPosition_OneByIdWithStepEventsQuery } from "@entities"
import { Entity } from "@typedefs/graphql"
import { FunctionComponent, useEffect, useState } from "react"
import { JobPositionDetailViewMode } from "./JobPositionDetailViewMode/JobPositionDetailViewMode"
import { EventSettingProvider } from "@shared/event/EventSettingsModal/EventSettingContext"
import { JobPositionDetailEditMode } from "./JobPositionDetailEditMode/JobPositionDetailEditMode"
import { EventSettingsModal } from "@shared/event/EventSettingsModal/EventSettingsModal"
import { InitialStateData } from "../init"

interface Props extends InitialStateData { }

const JobPositionDetailSuccessState: FunctionComponent<Props> = ({
    jobPosition
}) => {
    const [mode, setMode] = useState<'view' | 'edit'>('view');

    const switchToEditMode = () => setMode('edit');

    const switchToViewMode = () => setMode('view');

    useEffect(() => {
        switchToViewMode()
    }, [jobPosition])

    return (
        <EventSettingProvider>
            {
                mode === "view"
                    ? <JobPositionDetailViewMode jobPosition={jobPosition} switchToEditMode={switchToEditMode} />
                    : <JobPositionDetailEditMode jobPosition={jobPosition} switchToViewMode={switchToViewMode} />
            }
            <EventSettingsModal />
        </EventSettingProvider>
    )
}

export {
    JobPositionDetailSuccessState
}