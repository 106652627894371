import { bulkActions } from "@helpers/core/constants";

type ApplicationId = string;

type NoProperty = true;

type AssignToEventSessionGroupProperty = {
    type: 'ASSIGN_TO_EVENT_SESSION_GROUP'
    stepId: string
    currentEventSessionGroupAssignments: {
        groupId: string
    }[]
}

type BulkApplicationProperty = NoProperty | AssignToEventSessionGroupProperty;

type SelectedApplications = Record<ApplicationId, BulkApplicationProperty>;

type BulkActionType = typeof bulkActions[number];

const isAssignToEventSessionGroupProperty = (property: BulkApplicationProperty): property is AssignToEventSessionGroupProperty => {
    return typeof property === 'object' && property.type === 'ASSIGN_TO_EVENT_SESSION_GROUP'
}

const constructBulkActionProperty = (action: BulkActionType, application: {
    id: string
    stepId: string
    eventSessionGroupAssignments: {
        eventSessionGroupId: string
    }[]
}): BulkApplicationProperty => {
    if (action === 'ASSIGN_TO_SESSION_MANUALLY') {
        return {
            type: 'ASSIGN_TO_EVENT_SESSION_GROUP',
            stepId: application.stepId,
            currentEventSessionGroupAssignments: application.eventSessionGroupAssignments.map((assignment) => ({
                groupId: assignment.eventSessionGroupId
            }))
        }
    }
    return true
}

export {
    ApplicationId,
    NoProperty,
    AssignToEventSessionGroupProperty,
    BulkApplicationProperty,
    SelectedApplications,
    BulkActionType,
    constructBulkActionProperty,
    isAssignToEventSessionGroupProperty,
}