import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Progress } from "@shared/unsorted/Progress/Progress";
import { useCSVBulkEvaluationImportStepInProgess } from "./hook";

const styles = {
    container: Cn.c("pt-6 flex flex-col flex-1 overflow-auto items-center justify-center"),
    importText: Cn.c("text-emphasized font-h2-bold mb-2"),
    progressText: Cn.c("text-subdued font-paragraph-base-regular"),
    progressBarContainer: Cn.c("mt-6 w-[37.5rem]"),
}

interface Props {
    totalCount: number;
}

const CSVBulkEvaluationImportStepInProgess: FunctionComponent<Props> = ({
    totalCount,
}) => {
    const { t } = useTranslation()
    const { imported } = useCSVBulkEvaluationImportStepInProgess()

    return (
        <div className={styles.container}>
            <p className={styles.importText}>{t('applications.bulkEvaluation.importStep.inProgress.importingFile')}</p>
            <p className={styles.progressText}>{t('applications.bulkEvaluation.importStep.inProgress.progressText', {
                current: imported,
                total: totalCount
            })}</p>
            <div className={styles.progressBarContainer}>
                <Progress
                    progressValue={Math.round(imported / totalCount * 100)}
                />
            </div>
        </div>
    )

}

export {
    CSVBulkEvaluationImportStepInProgess
};
