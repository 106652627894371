import { ValidSize } from '@helpers/unsorted/numberExtra';

export const selectableRecruitmentSteps = [
    'DOCUMENT',
    'EVENT',
] as const

export const allRecruitmentSteps = [
    ...selectableRecruitmentSteps,
    'SYSTEM',
] as const

export const displayedRecruitmentStages = [
    'SOURCING',
    'SCREENING',
    'OFFER',
    'PRE_EMPLOYMENT',
] as const;

export const allRecruitmentStages = [
    ...displayedRecruitmentStages,
    'ARCHIVE',
] as const

export const documentStepStatuses = [
    'PENDING_NOTIFICATION',
    'PENDING_DOCUMENT',
] as const

export const eventStepStatuses = [
    'PENDING_EVENT_ANNOUNCEMENT',
    'SCHEDULE_ADJUSTMENT',
    'PENDING_EVENT',
    'PENDING_ATTENDANCE',
] as const

// General group. Named same as the constant declared in API
export const optionalStepStatuses = [
    'PENDING_EVALUATION',
    'PENDING_DECISION',
    'PENDING_FINAL_DECISION',
    'PENDING_RESULT', // Included here to match the grouping in filter UI
] as const

const rejectedClosedStepStatuses = [
    'REJECTED_SKILL_MISMATCH',
    'REJECTED_CULTURE_MISMATCH',
    'REJECTED_OTHER',
] as const

const declinedClosedStepStatuses = [
    'WITHDRAWN_OTHER_OFFER',
    'WITHDRAWN_REMAINED',
    'WITHDRAWN_OTHER',
] as const

const otherClosedStepStatuses = [
    'CLOSED_NO_REPLY',
    'CLOSED_OTHER',
    'JOINED',
] as const

export const allClosedStepStatuses = [
    ...rejectedClosedStepStatuses,
    ...declinedClosedStepStatuses,
    ...otherClosedStepStatuses,
] as const

export type ClosedStepStatus = typeof allClosedStepStatuses[number]

export const allOngoingStepStatuses = [
    ...optionalStepStatuses,
    ...documentStepStatuses,
    ...eventStepStatuses,
] as const

export type OngoingStepStatus = typeof allOngoingStepStatuses[number]

export const allStepStatuses = [
    ...allOngoingStepStatuses,
    ...allClosedStepStatuses,
] as const

export const recruitmentStepStatusGroups = {
    GENERAL: optionalStepStatuses,
    DOCUMENT: documentStepStatuses,
    EVENT: eventStepStatuses,
    ARCHIVE_REASON: allClosedStepStatuses,
}

export const archivedReasonGroups = {
    REJECTED: rejectedClosedStepStatuses,
    DECLINED: declinedClosedStepStatuses,
    OTHER: otherClosedStepStatuses,
}

export const genders = ['MALE', 'FEMALE', 'NOT_APPLICABLE', 'UNSPECIFIED'] as const

export const noSetupUpdateStatusBulkActions = [
    'COMPLETE_EVALUATION',
    'CONFIRM_DOCUMENTS',
    'MARK_AS_FAIL',
    'MARK_AS_JOINED',
    'MARK_AS_PASS',
    'MARK_AS_UNDECIDED',
    'REGISTER_ATTENDANCE',
] as const

export const needSetupUpdateStatusBulkActions = [
    'ASSIGN_TO_SESSION_MANUALLY',
] as const

//TODO: Open comment when server completes optimizing
export const updateStatusBulkActions = [
    ...noSetupUpdateStatusBulkActions,
    ...needSetupUpdateStatusBulkActions,
] as const

export const bulkActions = [
    ...updateStatusBulkActions,
    'ARCHIVE',
    'REVERT_TO_PREVIOUS_STATUS'
] as const

export const MAX_FILE_SIZE: Record<string, ValidSize> = {
    DOCUMENT_UPLOAD: [10, 'MB'],
    CSV_IMPORT: [10, 'MB'],
};

export const atsCSVHeaders = [
    "名前",
    "名前（カタカナ）",
    "生年月日",
    "性別",
    "PCメールアドレス",
    "携帯電話番号",
    "居住国",
    "現住所郵便番号",
    "現住所都道府県",
    "現住所詳細",
    "現住所電話番号",
    "卒業予定年",
    "卒業予定月",
    "学校種別",
    "文理区分",
    "学校",
    "専攻",
    "ゼミ・研究室",
    "専攻テーマ",
    "クラブ・サークル",
    "休暇中住所郵便番号",
    "休暇中住所都道府県",
    "休暇中住所詳細",
    "休暇中住所電話番号",
    "携帯メールアドレス",
    "最終勤務先",
    "部署・役職",
    "備考"
]

export const ACCEPTED_ROLE_KEYS = ['ScovilleAdmin', 'Scoville', 'Admin', 'Coordinator', 'Examiner'] as const;
