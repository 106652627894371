import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Button } from "@shared/unsorted/Button/Button";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    window: Cn.c("w-11/12 max-w-lg"),
    header: Cn.c("font-h4-bold text-emphasized p-6 flex justify-between"),
    warningGradient: {
        layer1: Cn.c("p-2 bg-surface-warning-subdued rounded-full"),
        layer2: Cn.c("p-2 bg-surface-warning-default rounded-full"),
    },
    featuredIcon: Cn.c("text-warning-emphasized w-6 h-6"),
    closeIcon: Cn.c("text-icons-emphasized w-6 h-6"),
    body: Cn.c("pb-6 px-6 border-b border-b-default"),
    title: Cn.c("block font-h3-bold text-emphasized mb-2"),
    description: Cn.c("text-subdued"),
    footer: Cn.c("p-6 min-h-96 flex-wrap flex justify-between items-center"),
    safeActions: Cn.c("flex flex-row items-center gap-x-4"),
    proceedButton: Cn.c("pl-0"),
};

type Props = {
    className?: string;
    formId: string;
    isOpen: boolean;
    cancel: VoidFunction;
    proceed: VoidFunction;
    isDisabled?: boolean;
    isSubmitting?: boolean;
};

const AccountFormPromptModal: FunctionComponent<Props> = (props) => {
    const {
        className = "",
        formId,
        isOpen,
        cancel,
        proceed,
        isDisabled = false,
        isSubmitting = false,
    } = props;
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            close={cancel}
            widthClass={Cn.join([styles.window, className])}
        >
            <div className={styles.header}>
                <div className={styles.warningGradient.layer1}>
                    <div className={styles.warningGradient.layer2}>
                        <Icon
                            name="save"
                            className={Cn.join([styles.featuredIcon])}
                        />
                    </div>
                </div>
                <Icon
                    name="close"
                    className={styles.closeIcon}
                    onClick={cancel}
                />
            </div>
            <div className={styles.body}>
                <span className={styles.title}>
                    {t("settings.account.promptModal.title")}
                </span>
                <p className={styles.description}>
                    {t("settings.account.promptModal.description")}
                </p>
            </div>
            <div className={styles.footer}>
                <Button
                    size="md"
                    variant="destructivePlain"
                    className={styles.proceedButton}
                    onClick={proceed}
                >
                    {t("settings.account.promptModal.actions.proceed")}
                </Button>
                <div className={styles.safeActions}>
                    <Button size="md" variant="secondary" onClick={cancel}>
                        {t("settings.account.promptModal.actions.cancel")}
                    </Button>
                    <Button
                        type="submit"
                        formId={formId}
                        isDisabled={isDisabled || isSubmitting}
                        isLoading={isSubmitting}
                        size="md"
                    >
                        {t(
                            "settings.account.promptModal.actions.saveAndProceed"
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export { AccountFormPromptModal };
