import { genders } from "@helpers/core/constants";
import { getLocale, useCurrentLanguage } from "@helpers/core/i18n";
import { range } from "@helpers/unsorted/arrayExtra";
import { SelectionOption } from "@typedefs/selectOption";
import { format } from 'date-fns';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import { CreateRecruitmentProcessDocument } from "@entities";
import { hasValue } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { getQueryContext, handleResponse, hasErrorCode } from "@helpers/unsorted/urqlExtra";
import { ApplicationInformationSubFormData } from "@shared/application/ApplicationInformationSubForm/ApplicationInformationSubForm";
import { useApplicationInformationSubFormState } from "@shared/application/ApplicationInformationSubForm/useApplicationInformationSubFormState";
import { AwaitableState } from "@typedefs/aliases";
import { FormEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import * as value from './value';
import { Candidate } from "@routes/candidate";

interface ManualInputHookType {
    monthOptions: SelectionOption[];
    yearOptions: SelectionOption[];
    genderOptions: SelectionOption[];
    form: UseFormReturn<value.Encoder.Type>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isSubmitting: boolean;
    state: AwaitableState<ApplicationInformationSubFormData>;
    onSubFormChange: (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => void
}

const useManualInput = (): ManualInputHookType => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { data: state, setCurrentJobPositionId } = useApplicationInformationSubFormState()

    const { t } = useTranslation();

    const navigate = useNavigate();

    const currentLanguage = useCurrentLanguage();

    const locale = getLocale(currentLanguage);

    const { error: toastError } = useToast();

    const [createRecruitmentProcessResponse, createRecruitmentProcess] = useMutation(CreateRecruitmentProcessDocument);

    const form = useForm<value.Encoder.Type>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: value.Encoder.defaultValues(),
    });

    const monthOptions: SelectionOption[] = Array.from({ length: 12 }, (_, month) => ({
        key: month.toString(),
        value: month.toString(),
        label: format(new Date(2022, month, 1), 'LLLL', { locale }),
    }));

    const yearOptions: SelectionOption[] = range(2031 - 1950, 1950).map(year => ({
        key: year.toString(),
        value: year.toString(),
        label: year.toString(),
    })).reverse();

    const genderOptions: SelectionOption[] = genders.map(gender => ({
        key: gender,
        value: gender,
        label: t(`gender.${gender}`),
    }))

    const onSubFormChange = (values: {
        jobPositionId: string
        recruitmentStepId: string
        acquisitionCategoryId?: string
        acquisitionChannelId?: string
        entryCategoryId?: string
        entryChannelId?: string
    }) => {
        setCurrentJobPositionId(values.jobPositionId)
    }

    const onSubmit: SubmitHandler<value.Encoder.Type> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            const dateOfBirth = hasValue(validValues.dateOfBirth) && validValues.dateOfBirth instanceof Date
                ? validValues.dateOfBirth.toISOString()
                : undefined;

            createRecruitmentProcess({
                jobPositionId: validValues.jobPositionId,
                recruitmentStepId: validValues.recruitmentStepId,
                candidate: {
                    name: validValues.name,
                    nameKana: validValues.nameKana,
                    mobilePhoneNumber: validValues.mobilePhoneNumber,
                    email: validValues.email,
                    entryChannelId: validValues.entryChannelId,
                    acquisitionChannelId: validValues.acquisitionChannelId,
                    gender: validValues.gender,
                    livesInJapan: undefined,
                    dateOfBirth,
                    graduationYear: validValues.graduationYear,
                    graduationMonth: hasValue(validValues.graduationMonth) ? validValues.graduationMonth + 1 : undefined,
                },
            }, getQueryContext('OngoingRecruitmentProcessSummary'));
        } catch (error) {
            toastError('global.error');
        }
    };

    useEffect(() => {
        handleResponse(createRecruitmentProcessResponse, {
            onFetching: () => {
                setIsSubmitting(true);
            },
            onData: (data) => {
                setIsSubmitting(false);
                const showRoute = Candidate.toRoute({
                    mode: 'show',
                    applicationId: data.createRecruitmentProcess.id,
                })
                navigate(`${showRoute.PATH_NAME}${showRoute.search}`);

                form.reset();
            },
            onError: (error) => {
                setIsSubmitting(false);
                if (hasErrorCode(error, 'RECORD_ALREADY_EXISTS')) {
                    toastError('applications.errors.appliedCandidateEmail');

                    return;
                }

                toastError('global.error');
            },
        });
    }, [createRecruitmentProcessResponse]);

    return {
        isSubmitting,
        monthOptions,
        yearOptions,
        genderOptions,
        form,
        onSubmit: form.handleSubmit(onSubmit),
        state,
        onSubFormChange,
    }
}

export {
    useManualInput
};
