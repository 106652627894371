import { ImportApplicationsFromCsvUrlDocument } from "@entities";
import { useMutation } from "urql";
import { useCSVImportFormContext } from "../../../useCSVImportFormContext";
import { isDefined } from "@helpers/core/typeGuards";
import { getQueryContext } from "@helpers/unsorted/urqlExtra";

const useCSVImportImportStepFailure = (
    goToInProgress: VoidFunction
) => {
    const [{ setting, validCSVUrl }] = useCSVImportFormContext()

    const [_, importCandidateCsv] = useMutation(ImportApplicationsFromCsvUrlDocument);

    const openEmail = () => {
        window.location.href = `mailto:bluum@reccoo.com`
    }

    const retryImportingCSV = () => {
        if (isDefined(setting) && isDefined(validCSVUrl)) {
            importCandidateCsv({
                fileUrl: validCSVUrl,
                jobPositionId: setting.jobPosition.id,
                recruitmentStepId: setting.recruitmentStep.id,
                acquisitionChannelId: setting.acquisitionChannel?.id,
                entryChannelId: setting.entryChannel?.id,
            }, getQueryContext('RecruitmentProcesses'));

            goToInProgress()
        }
    };

    return {
        openEmail,
        retryImportingCSV
    }
}

export {
    useCSVImportImportStepFailure
}