import { ArchiveApplicationsDocument, AssignApplicationsToEventSessionGroupDocument, PerformActionToApplicationsDocument, RevertArchivedApplicationsToActiveDocument } from "@entities";
import { updateStatusBulkActions } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { getQueryContext } from "@helpers/unsorted/urqlExtra";
import { BulkActionGeneralConfirmationPayload } from "@pages/ApplicationsPage/reducer";
import { useMutation } from "urql";
import { BulkActionType } from "./bulkActions/helpers";

interface BatchApplicationExecutionResult {
    totalProcessed: number,
    detail: {
        success: number,
        failed: number,
        failedIds: string[]
    }
}

const useBulkActionExecution = ({
    action,
    payload,
    onStartExecution,
    onStartExecutingBatch,
    onCompleteExecutingBatch,
    onCompleteExecution,
}: {
    action?: BulkActionType,
    payload?: BulkActionGeneralConfirmationPayload
    onStartExecution?: VoidFunction,
    onStartExecutingBatch?: VoidFunction,
    onCompleteExecutingBatch?: (result: BatchApplicationExecutionResult) => void,
    onCompleteExecution?: (result: BatchApplicationExecutionResult) => void,
}) => {
    const [_, performActionToApplications] = useMutation(PerformActionToApplicationsDocument)

    const [__, archiveApplications] = useMutation(ArchiveApplicationsDocument)

    const [___, revertToPreviousStatus] = useMutation(RevertArchivedApplicationsToActiveDocument)

    const [, bulkEventSessionAssignment] = useMutation(AssignApplicationsToEventSessionGroupDocument)

    const applicationIdBatches = payload?.applicationIdBatches || []

    const execute = async () => {
        const queryContext = getQueryContext([
            'RecruitmentProcesses',
            'OngoingRecruitmentProcessSummary',
            'ArchivedRecruitmentProcessSummary',
            'RecruitmentProcess'
        ])
        const result: BatchApplicationExecutionResult = {
            totalProcessed: 0,
            detail: {
                success: 0,
                failed: 0,
                failedIds: []
            }
        }

        if (onStartExecution) {
            onStartExecution()
        }

        for (let i = 0; i <= applicationIdBatches.length - 1; i++) {
            const batch = applicationIdBatches[i]
            const isLastBatch = i === applicationIdBatches.length - 1

            if (onStartExecutingBatch) {
                onStartExecutingBatch()
            }
            if (isOneOf(updateStatusBulkActions)(action)) {
                if (action === 'MARK_AS_JOINED') {
                    const { data } = await archiveApplications({
                        input: {
                            applicationIds: batch,
                            reason: "JOINED"
                        },
                    }, isLastBatch ? queryContext : undefined)
                    if (data) {
                        result.detail.success += data.archiveApplications.success
                        result.detail.failed += data.archiveApplications.failed
                        result.detail.failedIds = result.detail.failedIds.concat(data.archiveApplications.failedIds)
                    } else {
                        result.detail.failed += batch.length
                        result.detail.failedIds = result.detail.failedIds.concat(batch)
                    }

                } else if (action === 'ASSIGN_TO_SESSION_MANUALLY' && payload?.bulkActionPayload?.type === 'assignToEventSession') {
                    const { data } = await bulkEventSessionAssignment({
                        applicationIds: batch,
                        groupId: payload?.bulkActionPayload.groupId || '',
                        stepId: payload?.bulkActionPayload.recruitmentStepId || ''
                    }, isLastBatch ? queryContext : undefined)

                    if (data) {
                        result.detail.success += data.assignApplicationsToEventSessionGroup.success
                        result.detail.failed += data.assignApplicationsToEventSessionGroup.failed
                        result.detail.failedIds = result.detail.failedIds.concat(data.assignApplicationsToEventSessionGroup.failedIds)
                    } else {
                        result.detail.failed += batch.length
                        result.detail.failedIds = result.detail.failedIds.concat(batch)
                    }

                } else {
                    const { data } = await performActionToApplications({
                        input: {
                            applicationIds: batch,
                            action
                        }
                    }, isLastBatch ? queryContext : undefined)
                    if (data) {
                        result.detail.success += data.performActionToApplications.success
                        result.detail.failed += data.performActionToApplications.failed
                        result.detail.failedIds = result.detail.failedIds.concat(data.performActionToApplications.failedIds)
                    } else {
                        result.detail.failed += batch.length
                        result.detail.failedIds = result.detail.failedIds.concat(batch)
                    }
                }
            }

            if (action === 'ARCHIVE' && payload?.bulkActionPayload?.type === 'archive') {
                const { data } = await archiveApplications({
                    input: {
                        applicationIds: batch,
                        reason: payload.bulkActionPayload.archiveReason
                    }
                }, isLastBatch ? queryContext : undefined)

                if (data) {
                    result.detail.success += data.archiveApplications.success
                    result.detail.failed += data.archiveApplications.failed
                    result.detail.failedIds = result.detail.failedIds.concat(data.archiveApplications.failedIds)
                } else {
                    result.detail.failed += batch.length
                    result.detail.failedIds = result.detail.failedIds.concat(batch)
                }
            }

            if (action === 'REVERT_TO_PREVIOUS_STATUS') {
                const { data } = await revertToPreviousStatus({
                    input: {
                        applicationIds: batch,
                    }
                }, isLastBatch ? queryContext : undefined)
                if (data) {
                    result.detail.success += data.revertArchivedApplicationsToActive.success
                    result.detail.failed += data.revertArchivedApplicationsToActive.failed
                    result.detail.failedIds = result.detail.failedIds.concat(data.revertArchivedApplicationsToActive.failedIds)
                } else {
                    result.detail.failed += batch.length
                    result.detail.failedIds = result.detail.failedIds.concat(batch)
                }
            }
            result.totalProcessed += batch.length

            if (onCompleteExecutingBatch) {
                onCompleteExecutingBatch(result)
            }
        }

        if (onCompleteExecution) {
            onCompleteExecution(result)
        }
    }

    return {
        execute
    }
}

export {
    useBulkActionExecution
}