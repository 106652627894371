import { FunctionComponent, useEffect, useState } from 'react';

import { isDefined } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { ChildrenProps } from '@typedefs/props';
import { Icon } from '../Icon/Icon';

const styles = {
    collapsible: Cn.c('mb-4'),
    collapsibleHeader: (isDisabled: boolean) => Cn.join([
        Cn.c('flex flex-row flex-1 font-paragraph-small-medium text-default justify-between items-center px-4 py-3'),
        isDisabled ? Cn.c('bg-surface-disabled') : Cn.c('bg-surface-primary-default '),
    ]),
    subtitle: Cn.c('ml-4 font-paragraph-small-regular text-subdued'),
    icon: (isCollapsed: boolean) => Cn.join([
        Cn.c('text-icons-primary-default transform transition-all duration-200 w-6 h-6'),
        !isCollapsed ? Cn.c('rotate-180') : Cn.c('rotate-0'),
    ]),
    collapsibleContent: (isCollapsed: boolean) => Cn.join([
        Cn.c('transform transition-all ease-in-out duration-200'),
        !isCollapsed ? Cn.c('max-h-screen opacity-100 visible') : Cn.c('max-h-0 opacity-60 invisible'),
    ]),
};

interface Props extends ChildrenProps {
    title?: string;
    className?: string;
    isCollapsed: boolean;
    subtitle?: string;
    isDisabled?: boolean;
}

// @ocaml.doc("Collapsible Component
//
// - `title`: title of the header div
// - `children`: children to be displayed when expanded
// ")
const CollapsibleItem: FunctionComponent<Props> = ({ title, className, isCollapsed: isInitiallyCollapsed = true, subtitle, isDisabled = false, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(() => isInitiallyCollapsed);

    useEffect(() => {
        setIsCollapsed(isInitiallyCollapsed)
    }, [isInitiallyCollapsed]);

    return (
        <div className={Cn.join([styles.collapsible, Cn.getSome(className)])}>
            <div className={styles.collapsibleHeader(isDisabled)} onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}>
                <div>
                    {title}
                    {isDefined(subtitle) && <span className={styles.subtitle}>{subtitle}</span>}
                </div>
                {!isDisabled && <Icon className={styles.icon(isCollapsed)} name="caret" />}
            </div>
            {!isDisabled && <div className={styles.collapsibleContent(isCollapsed)}>{children}</div>}
        </div>
    );
};

export {
    CollapsibleItem,
};
