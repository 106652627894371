import { EvaluationResponse } from '@typedefs/aliases';
import {z} from 'zod'

namespace Encoder {
    export interface EncoderType {
        evaluationResponses: EvaluationResponse[];
    }

    export const defaultValues = (evaluationResponses: EvaluationResponse[]): EncoderType => {
        return {
            evaluationResponses
        }
    }
}

namespace Decoder {
    export const schema = z.array(z.object({
        question: z.string(),
        response: z.string()
    }))

    export type DecoderType = z.infer<typeof schema>
}

export {
    Encoder,
    Decoder
}