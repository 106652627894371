import { ClientInfo } from "@helpers/hooks/unsorted/clientHook";

namespace Encoder {
    export type Type = ReturnType<typeof Encoder.defaultValues>;

    export const defaultValues = (clientInfo?: ClientInfo) => {
        return {
            email: clientInfo?.email ?? "",
            firstName: clientInfo?.firstName ?? "",
            lastName: clientInfo?.lastName ?? "",
            firstNameKana: clientInfo?.firstNameKana ?? "",
            lastNameKana: clientInfo?.lastNameKana ?? "",
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
        };
    };
}

namespace Decoder {
    export const fromEncodedValue = (input: Encoder.Type) => ({
        ...input,
        password:
            input.oldPassword && input.newPassword
                ? {
                      old: input.oldPassword,
                      new: input.newPassword,
                  }
                : undefined,
    });
}

export { Encoder, Decoder };
