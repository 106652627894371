import { useEffect, useState } from "react";
import { useCSVImportFormContext } from "../../useCSVImportFormContext";
import { useSubscription } from "urql";
import { ApplicationsFailedToImportFromCsvDocument, ApplicationsSuccessfullyImportedFromCsvDocument } from "@entities";
import { handleResponse } from "@helpers/unsorted/urqlExtra";
import { hasValue } from "@helpers/core/typeGuards";

const useCSVImportImportStep = () => {
    const [mode, setMode] = useState<'inProgress' | 'success' | 'failure'>('inProgress');

    const [_, dispatch] = useCSVImportFormContext()

    const [applicationsSuccessfullyImportedResponse] = useSubscription({ query: ApplicationsSuccessfullyImportedFromCsvDocument })
    const [applicationsFailedToImportResponse] = useSubscription({ query: ApplicationsFailedToImportFromCsvDocument })

    const goToFailure = () => {
        setMode('failure')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.csvImport.steps.import', variant: 'fail' },
                ]
            }
        })
    };

    const goToSuccess = () => {
        setMode('success')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.csvImport.steps.import', variant: 'success' },
                ]
            }
        })
    };

    const goToInProgress = () => {
        setMode('inProgress')
        dispatch({
            name: 'SetStepTracker',
            payload: {
                stepTracker: [
                    { id: 'setup', label: 'applications.csvImport.steps.setup', variant: 'success' },
                    { id: 'preview', label: 'applications.csvImport.steps.preview', variant: 'success' },
                    { id: 'import', label: 'applications.csvImport.steps.import', variant: 'selected' },
                ]
            }
        })
    }

    useEffect(() => {
        handleResponse(applicationsSuccessfullyImportedResponse, {
            onData: ({ applicationsSuccessfullyImportedFromCsv }) => {
                if (hasValue(applicationsSuccessfullyImportedFromCsv)) {
                    goToSuccess()
                }
            }
        })
    }, [applicationsSuccessfullyImportedResponse])

    useEffect(() => {
        handleResponse(applicationsFailedToImportResponse, {
            onData: ({ applicationsFailedToImportFromCsv }) => {
                if (hasValue(applicationsFailedToImportFromCsv)) {
                    goToFailure()
                }
            }
        })
    }, [applicationsFailedToImportResponse])

    return {
        mode,
        goToFailure,
        goToSuccess,
        goToInProgress,
    }
}

export {
    useCSVImportImportStep
}