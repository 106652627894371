import { Candidate, CandidateAdd } from "@routes/candidate";
import { useNavigate } from "react-router-dom";

const useCSVImportPage = () => {
    const navigate = useNavigate();

    const switchToManuallyAdding = () => {
        navigate(CandidateAdd.MANUAL_PATH_NAME);
    }

    const switchToTop = () => {
        const routes = Candidate.toRoute()
        navigate(`${routes.PATH_NAME}${routes.search}`)
    }

    return {
        switchToManuallyAdding,
        switchToTop,
    }
}

export {
    useCSVImportPage
};
