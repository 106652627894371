import { useOpenFolder } from "@helpers/hooks/unsorted/rehooks/openFolder/openFolderHook";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { useEffect, useRef } from "react";

const useUploadDragAndDrop = (onUpload: (files?: File[]) => void) => {
    const fileDropArea = useRef<HTMLDivElement>(null);

    const { error: toastError } = useToast();

    const handleDragOver = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer as DataTransfer;
        const forUpload: File[] = Array.from(files);

        if (forUpload.some(file => file.type !== 'text/csv')) {
            toastError('global.documentUpload.invalidCsvFormat');

            return;
        }

        onUpload(Array.from(files));
    };

    const documentUpload = useOpenFolder();

    useEffect(() => {
        fileDropArea.current?.addEventListener('dragover', handleDragOver);
        fileDropArea.current?.addEventListener('drop', handleDrop);

        return () => {
            fileDropArea.current?.removeEventListener('dragover', handleDragOver);
            fileDropArea.current?.removeEventListener('drop', handleDrop);
        };
    }, []);

    useEffect(() => {
        const files = documentUpload.files;

        onUpload(files);
    }, [documentUpload.files]);

    return {
        fileDropArea,
        documentUpload,
    }
}

export {
    useUploadDragAndDrop
};
