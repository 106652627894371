import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AllEvaluationsPayloadType } from '../reducer';
import { hasValue } from '@helpers/core/typeGuards';

const styles = {
    title: Cn.c('font-h3-bold text-emphasized px-6 py-4 border-b border-b-default'),
    modalBody: Cn.c('py-6 px-12 space-y-4'),
    summary: Cn.c('font-paragraph-small-regular flex space-x-1'),
    respondentsCount: Cn.c('font-paragraph-small-bold'),
    respondentsContainer: Cn.c('flex space-x-1'),
    respondent: Cn.c('font-paragraph-small-bold bg-surface-emphasized px-2 w-fit rounded-full'),
    buttons: Cn.c('flex flex-row justify-end px-6 py-4 bg-surface-subdued'),
    evaluationsContainer: Cn.c('font-paragraph-small-regular space-y-4'),
    question: Cn.c('font-paragraph-small-bold whitespace-pre-line'),
    responsesList: Cn.c('list-disc list-inside pl-4 space-y-1'),
    respondentName: Cn.c('font-paragraph-small-bold'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    evaluationResponses?: AllEvaluationsPayloadType;
}

const ApplicationViewEvaluationsModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    evaluationResponses,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} close={closeModal} widthClass="w-[59rem]">
            <div className={styles.title}>{t("applications.panel.viewApplicationEvaluations.title")}</div>
            <div className={styles.modalBody}>
                {hasValue(evaluationResponses) && evaluationResponses.evaluations.size > 0
                    ? <>
                        <div className={styles.summary}>
                            <span className={styles.respondentsCount}>{evaluationResponses?.respondents.length}</span>
                            <span>{t("applications.panel.viewApplicationEvaluations.respondents")}</span>
                            <div className={styles.respondentsContainer}>
                                {evaluationResponses?.respondents.map((respondent, index) => (
                                    <div key={index} className={styles.respondent}>
                                        {respondent}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.evaluationsContainer}>
                            {[...evaluationResponses.evaluations].map(([question, responses], index) => (
                                <div key={index + 1}>
                                    <div className={styles.question}>{index + 1}/ {question}</div>
                                    <ul className={styles.responsesList}>
                                        {responses.map(({ clientName, response }, responseIndex) => (
                                            <li key={responseIndex}>
                                                <span className={styles.respondentName}>{clientName}</span>: {response}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                            }
                        </div>
                    </>
                    : <div>{t("applications.panel.viewApplicationEvaluations.noEvaluation")}</div>
                }
            </div>
            <div className={styles.buttons}>
                <Button size='md' variant='secondary' onClick={closeModal}>
                    {t('global.close')}
                </Button>
            </div>
        </Modal >
    );
};

export {
    ApplicationViewEvaluationsModal,
}
