import { JobPosition, JobPositionCreate } from '@routes/jobPosition';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface WelcomeModalHookType {
    isDontShowAgainChecked: boolean;
    onSetDontShowAgain: VoidFunction;
    onClose: VoidFunction;
    onCreateFirstJob: VoidFunction;
}

const useWelcomeModal = (onCloseModal: (dontShowAgain: boolean) => void): WelcomeModalHookType => {
    const navigate = useNavigate();

    const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);

    const onSetDontShowAgain = () => setIsDontShowAgainChecked((previousValue) => !previousValue);

    const onClose = () => {
        onCloseModal(isDontShowAgainChecked);
    };

    const onCreateFirstJob = () => {
        onClose();
        navigate(JobPositionCreate.PATH_NAME);
    };

    return {
        isDontShowAgainChecked,
        onSetDontShowAgain,
        onClose,
        onCreateFirstJob,
    };
};

export {
    useWelcomeModal,
};