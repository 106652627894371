export type ResultFailure<A> = {
    error: A
    success: false
}

export type ResultSuccess<A> = {
    data: A
    success: true
}

export type Result<A, B> = ResultFailure<A> | ResultSuccess<B>

export const mapSuccess = <A, B, C>(result: Result<A, B>, fn: (x: B) => C): Result<A, C> => {
    if (result.success) {
        return {
            ...result,
            data: fn(result.data)
        }
    }
    return result;
}

export const firstOf = <A, B>(thunks: Array<() => Result<A, B>>): B | null => {
    for (const thunk of thunks) {
        const result = thunk();
        if (result.success) {
            return result.data;
        }
    }
    return null;
}

