import { ChildrenProps } from "@typedefs/props";
import { Children, FunctionComponent, isValidElement } from "react";
import { SegmentedStepTracker } from '../../unsorted/SegmentedStepTracker/SegmentedStepTracker';
import { Variant as StepTrackerSegmentVariant } from '../../unsorted/StepTrackerSegment/StepTrackerSegment'
import { StepContainer } from "./StepContainer";
import { I18nKey } from "react-i18next";
import { Cn } from "@helpers/unsorted/classNames";

interface Props extends ChildrenProps {
    className?: string
    trackerClassname?: string
    stepTracker: {
        id: string
        label: I18nKey
        variant: StepTrackerSegmentVariant
    }[]
}

const Stepper: FunctionComponent<Props> = ({ stepTracker, children, className, trackerClassname }) => {
    const validChildren = Children.map(children, (child) =>
        isValidElement(child) && child.type === StepContainer
            ? child
            : null
    )

    return (
        <div className={Cn.getSome(className)}>
            <SegmentedStepTracker segments={stepTracker} className={trackerClassname} />
            {validChildren}
        </div>
    )
}

export {
    Props, Stepper
};

