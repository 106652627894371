import { Cn } from '@helpers/unsorted/classNames';
import { Button } from '@shared/unsorted/Button/Button';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { RadioInputGroup } from '@shared/unsorted/RadioInput/RadioInputGroup/RadioInputGroup';
import { RadioInputItemBoxGroup } from '@shared/unsorted/RadioInputItemBox/RadioInputItemBoxGroup/RadioInputItemBoxGroup';
import { ModalProps } from '@typedefs/props';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddCandidatesModal } from './hook';
import { Controller } from 'react-hook-form';

const styles = {
    container: Cn.c('p-6 rounded-xl'),
    header: Cn.c('flex flex-col space-y-2 pt-6 px-6'),
    titleContainer: Cn.c('flex items-center justify-between'),
    title: Cn.c('font-h4-bold text-emphasized'),
    description: Cn.c('font-paragraph-small-regular text-subdued'),
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    content: Cn.c('pt-5 pb-6 px-6'),
    footer: Cn.c('p-6 border-t border-default flex justify-between items-center space-x-3'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
}

const AddCandidatesModal: FunctionComponent<Props> = ({ isOpen, closeModal }) => {
    const { t } = useTranslation();

    const {
        options,
        form : { control, },
        onConfirm,
        onClose,
    } = useAddCandidatesModal(closeModal);

    return (
        <Modal isOpen={isOpen} close={onClose}>
            <form onSubmit={onConfirm}>
                <div className={styles.header}>
                    <div className={styles.titleContainer}>
                        <p className={styles.title}>{t("applications.create.modal.title")}</p>
                        <Icon name="close" className={styles.closeIcon} onClick={onClose} />
                    </div>
                    <p className={styles.description}>{t("applications.create.modal.description")}</p>
                </div>
                <div className={styles.content}>
                    <Controller
                        name="addMethod"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { name, onChange, ref, value } }) =>
                            <RadioInputItemBoxGroup
                                name={name}
                                layout="vertical"
                                options={options}
                                onChange={onChange}
                                value={value}
                                forwardedRef={ref}
                            />
                    }/>
                </div>
                <div className={styles.footer}>
                    <Button
                        variant="secondary"
                        onClick={onClose}
                        size="md"
                        isFull={true}
                    >
                        {t("global.cancel")}
                    </Button>
                    <Button
                        variant="primaryFilled"
                        size="md"
                        isFull={true}
                        type="submit"
                    >
                        {t("global.confirm")}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export {
    AddCandidatesModal
};