import { CreateJobPositionDocument, SelectableStepTypesDocument } from '@entities';
import { hasValue } from '@helpers/core/typeGuards';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { handleResponse, hasErrorCode, responseHasError } from '@helpers/unsorted/urqlExtra';
import { FormEventHandler, useEffect, useReducer, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { State, initialState, reducer } from './reducer';
import * as value from './value';
import { useModal } from '@helpers/hooks/unsorted/useModal';
import { JobPositionDetail } from '@routes/jobPosition';

interface JobPositionCreatePageHookType {
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    state: State;
    isSubmitting: boolean;
    currentTitle: string;
    showWarningModal: boolean;
    closeWarningModal: VoidFunction;
    onCancel: VoidFunction;
}

const useJobPositionCreatePage = (): JobPositionCreatePageHookType => {
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);

    const { success: toastSuccess, error: toastError } = useToast();

    const [recruitmentStepResponse,] = useQuery({
        query: SelectableStepTypesDocument,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showWarningModal, setShowWarningModal] = useState(false);

    const [createJobPositionResponse, createJobPosition] = useMutation(CreateJobPositionDocument);

    const { closeModal } = useModal()

    const form = useForm<value.Encoder.EncoderType>({
        defaultValues: value.Encoder.defaultValues(),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const currentTitle = useWatch({
        control: form.control,
        name: 'title',
    })

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            createJobPosition({
                title: validValues.title,
                recruitmentSteps: validValues.recruitmentSteps,
                documentRequirements: validValues.documentRequirements,
            });
        } catch (error) {
            toastError('global.error');
        }
    };

    const closeWarningModal = () => setShowWarningModal(false)

    const onCancel = () => {
        if (form.formState.isDirty) {
            setShowWarningModal(true);

            return;
        }

        closeModal()
    }

    useEffect(() => {
        if (hasValue(recruitmentStepResponse.data)) {
            dispatch({
                name: 'CreateJobPosition',
                recruitmentStepTypes: recruitmentStepResponse.data.selectableStepTypes,
            });
        }

        if (responseHasError(recruitmentStepResponse)) {
            toastError('global.error');
        }
    }, [recruitmentStepResponse]);

    useEffect(() => {
        handleResponse(createJobPositionResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: (data) => {
                setIsSubmitting(false)
                form.reset();
                toastSuccess('jobPositions.modify.jobPositionCreatedSuccessfully')

                const path = generatePath(JobPositionDetail.PATH_NAME, { id: data.createJobPosition.id });

                navigate(path);
            },
            onError: (error) => {
                setIsSubmitting(false);
                if (hasErrorCode(error, 'RECORD_ALREADY_EXISTS')) {
                    toastError('jobPositionCreate.messages.errors.titleAlreadyUsed');

                    return;
                }

                toastError('jobPositions.modify.jobPositionCreatedFailed');
            }
        })

    }, [createJobPositionResponse]);

    return {
        form,
        onSubmit: form.handleSubmit(onSubmit),
        state,
        isSubmitting,
        currentTitle,
        showWarningModal,
        closeWarningModal,
        onCancel,
    };
};

export {
    useJobPositionCreatePage
};

