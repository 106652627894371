import { Cn } from "@helpers/unsorted/classNames";
import { FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { Icon } from "../Icon/Icon";

const styles = {
    collapsibleHeader: Cn.c('flex flex-row flex-1 justify-between items-center'),
    icon: (isCollapsed: boolean) => Cn.join([
        Cn.c('text-icons-emphasized transform transition-all duration-200 w-6 h-6'),
        !isCollapsed ? Cn.c('rotate-180') : Cn.c('rotate-0'),
    ]),
    collapsibleContent: (isCollapsed: boolean) => Cn.join([
        Cn.c('transform transition-all ease-in-out duration-200'),
        !isCollapsed ? Cn.c('max-h-screen opacity-100 visible') : Cn.c('max-h-0 opacity-60 invisible'),
    ]),
}

interface Props extends PropsWithChildren {
    header: ReactElement
    isCollapsed?: boolean;
    className?: string;
    isDisabled?: boolean;
}

const Collapsible: FunctionComponent<Props> = ({
    header,
    children,
    isCollapsed: isInitiallyCollapsed = true,
    isDisabled = false,
    className,
}) => {
    const [isCollapsed, setIsCollapsed] = useState(() => isInitiallyCollapsed);

    useEffect(() => {
        setIsCollapsed(isInitiallyCollapsed)
    }, [isInitiallyCollapsed]);

    return (
        <div className={Cn.getSome(className)} >
            <div className={styles.collapsibleHeader} onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}>
                {header}
                {!isDisabled && <Icon className={styles.icon(isCollapsed)} name="arrowDown" />}
            </div>
            {!isDisabled && <div className={styles.collapsibleContent(isCollapsed)}>{children}</div>}
        </div >
    );
}

export { Collapsible };