import { DraggableProvided } from '@hello-pangea/dnd';
import { allRecruitmentSteps } from '@helpers/core/constants';
import { isOneOf } from '@helpers/core/typeGuards';
import { Cn } from '@helpers/unsorted/classNames';
import { StepFormType, isStepEditable, stageRange } from '@shared/job_position/JobPositionRecruitmentSteps/helpers';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Input } from '@shared/unsorted/Input/Input';
import { Select } from '@shared/unsorted/Select/Select';
import { ToggleSwitch } from '@shared/unsorted/ToggleSwitch/ToggleSwitch';
import { ToolTipBox } from '@shared/unsorted/ToolTipBox/ToolTipBox';
import { RecruitmentStageType } from '@typedefs/aliases';
import { SelectionOption } from '@typedefs/selectOption';
import { FunctionComponent } from 'react';
import { Controller, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as value from '../value';

const styles = {
    dragHandle: Cn.c('absolute top-5 left-4'),
    baseCellStyle: Cn.c('flex items-start px-3 py-2'),
    cell: (columnNumber: number) => Cn.join([
        styles.baseCellStyle,
        Cn.ifTrue(columnNumber === 1, Cn.c('justify-start flex-[1.2]')),
        Cn.ifTrue(columnNumber === 2, Cn.c('justify-start flex-[0.8]')),
        Cn.ifTrue(columnNumber >= 3, Cn.c('justify-center w-[176px] pt-4')),
    ]),
    inputTitleContainer: Cn.c('flex flex-row items-center justify-center'),
    stepNameField: Cn.c('w-[300px]'),
    closePositionStyle: Cn.c('absolute w-6 h-6 top-4 right-4 text-icons-subdued'),
    tooltip: Cn.c('w-[531px]'),
    typeSelect: Cn.c('w-[180px]'),
    questionMark: Cn.c('text-icons-emphasized w-3.5 h-3.5'),
    addStepIconContainer: Cn.c('text-primary-default font-paragraph-small-medium flex items-center justify-start gap-x-2 cursor-pointer pt-6 pb-7 ml-10 w-24'),
    nonEditableStepContainer: Cn.c('flex space-x-1 items-center'),
};

interface Props {
    stage: RecruitmentStageType;
    provided: DraggableProvided;
    step: StepFormType;
    index: number;
    fields: StepFormType[];
    removeRecruitmentStep: UseFieldArrayRemove;
    stepTypeOptions: SelectionOption[];
    form: UseFormReturn<value.Encoder.EncoderType>;
}

// TODO: This file is almost an exact duplicate of RecruitmentStageEditMode/Row.tsx. Consider refactoring.
const Row: FunctionComponent<Props> = ({
    stage,
    provided,
    step,
    index,
    fields,
    removeRecruitmentStep,
    stepTypeOptions,
    form,
}) => {

    const { t } = useTranslation();

    const { control, formState: { errors }, clearErrors } = form;

    const isEditable = isStepEditable(step);
    const stageOffset = stageRange(fields, stage);

    const editIndex = index + stageOffset.start;

    return (
        <>
            {isEditable && <div {...provided.dragHandleProps}><Icon name="dragHandle" className={styles.dragHandle} /></div>}
            <div className={styles.cell(1)}>
                {isEditable
                    ? <div className={styles.inputTitleContainer}>
                        <Controller
                            name={`recruitmentSteps.${editIndex}.name`}
                            control={control}
                            defaultValue={step.name}
                            rules={{
                                required: { value: true, message: 'requiredStepName' },
                            }}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Input
                                    required
                                    inputClassName={styles.stepNameField}
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    clearErrors={() => clearErrors(`recruitmentSteps.${editIndex}.name`)}
                                    forwardedRef={ref}
                                    errors={errors}
                                    value={value}
                                    errorLabel={"jobPositions.panel.recruitmentFlow.stepName"}
                                />
                            )}
                        />
                    </div>
                    : <div className={styles.nonEditableStepContainer}>
                        <div>{step.name}</div>
                        <div>
                            <ToolTipBox
                                toolTipClassName={styles.tooltip}
                                tooltipContent={t("jobPositions.panel.recruitmentFlow.table.mandatoryStepTooltipText")}
                                placement="right">
                                <div>
                                    <Icon name="questionMark" className={styles.questionMark} />
                                </div>
                            </ToolTipBox>
                        </div>
                    </div>
                }
            </div>
            <div className={styles.cell(2)}>
                {isEditable &&
                    <div>
                        {/* TODO: [FIX] Type shouldn't be editable. Need to update Figma design? */}
                        <Controller
                            name={`recruitmentSteps.${editIndex}.type`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                <Select
                                    className={styles.typeSelect}
                                    required
                                    name={name}
                                    onChange={value => {
                                        if (isOneOf(allRecruitmentSteps)(value)) {
                                            onChange(value);
                                        }
                                    }}
                                    onBlur={onBlur}
                                    clearErrors={clearErrors}
                                    forwardedRef={ref}
                                    errors={errors}
                                    value={value}
                                    options={stepTypeOptions}
                                />}
                        />
                    </div>}
            </div>
            <div className={styles.cell(3)}>
                {isEditable &&
                    <Controller
                        name={`recruitmentSteps.${editIndex}.hasNotification`}
                        control={control}
                        render={({ field: { name, value, onChange } }) =>
                            <ToggleSwitch
                                id={name}
                                checked={value}
                                onChange={() => onChange(!value)}
                            />
                        }
                    />}
            </div>
            <div className={styles.cell(4)}>
                {isEditable &&
                    <Controller
                        name={`recruitmentSteps.${editIndex}.hasEvaluation`}
                        control={control}
                        render={({ field: { name, value, onChange } }) =>
                            <ToggleSwitch
                                id={name}
                                checked={value}
                                onChange={() => onChange(!value)}
                            />
                        }
                    />}
            </div>
            <div className={styles.cell(5)}>
                {isEditable && <Controller
                    name={`recruitmentSteps.${editIndex}.hasDecision`}
                    control={control}
                    render={({ field: { name, value, onChange } }) =>
                        <ToggleSwitch
                            id={name}
                            checked={value}
                            onChange={() => onChange(!value)}
                        />
                    }
                />}
            </div>
            {isEditable &&
                <Icon name="close"
                    className={styles.closePositionStyle}
                    onClick={() =>
                        removeRecruitmentStep(editIndex)}
                />
            }
        </>
    );
};

export {
    Row
};
