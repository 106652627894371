import { Cn } from "@helpers/unsorted/classNames";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    topBar: Cn.c(
        "flex flex-shrink-0 px-10 items-center justify-center w-full h-12 bg-surface-default shadow-md fixed z-50 text-black",
    ),
    wrapper: Cn.c("pt-28 m-auto max-w-144"),
    form: Cn.c("flex flex-col bg-surface-default rounded shadow-base p-6 pb-8"),
    candidateName: Cn.c("text-black"),
    eventName: Cn.c("font-h4-medium pt-1 text-subdued"),
    requestTitle: Cn.c("font-h2-bold text-emphasized pt-1 pb-3"),
    successContainer: Cn.c("flex justify-center pt-8"),
    successIcon: Cn.c("text-icons-success-default w-14 h-14"),
    successTitle: Cn.c("font-h4-medium text-center text-success-default pt-4"),
    successDescription: Cn.c("pt-2 text-success-default text-center"),
    spacing: Cn.c("pt-8"),
}

interface Props {
    candidateName: string
    companyName: string
    eventDisplayName: string
}

const SuccessMode: FunctionComponent<Props> = ({ companyName, candidateName, eventDisplayName }) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.topBar}> {companyName} </div>
            <div className={styles.wrapper}>
                <div className={styles.form}>
                    <span className={styles.candidateName}> {candidateName} </span>
                    <span className={styles.eventName}> {eventDisplayName} </span>
                    <span className={styles.requestTitle}> {t('eventRegistration.title')}</span>
                    <span> {t('eventRegistration.instruction')} </span>
                    <div className={styles.successContainer}><Icon name="checkCircle" className={styles.successIcon} /></div>
                    <span className={styles.successTitle}>{t('eventRegistration.successTitle')}</span>
                    <span className={styles.successDescription}>
                        {t('eventRegistration.successDescription')}
                    </span>
                </div>
            </div>
        </>
    );
}

export {
    SuccessMode
};
