import { Cn } from "@helpers/unsorted/classNames";
import { TabHeader } from "@pages/SettingsPage/TabHeader/TabHeader";
import { Loader } from "@shared/unsorted/Loader/Loader";
import { FunctionComponent } from "react";

const styles = {
    container: Cn.c("flex items-center justify-center flex-1")
}

const PreferencesLoadingState: FunctionComponent = () => {
    return (<>
        <TabHeader
            title="settings.preferences.title"
        />
        <div className={styles.container}>
            <Loader variant="primaryDefault" size="lg" />
        </div>
    </>);
}

export {
    PreferencesLoadingState,
}