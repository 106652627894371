import { useState } from "react";
import { EmailTemplateMode } from "./EmailTemplateUpdateModal/hook";
import { EvaluationFormUpdateMode } from "./EvaluationFormUpdateModal/hook";
import { Entity } from "@typedefs/graphql";
import { EmailTemplate, JobPosition_OneByIdWithStepEventsQuery } from "@entities";

const useJobPositionDetailViewMode = () => {
    const [emailTemplateMode, setEmailTemplateMode] = useState<EmailTemplateMode | EvaluationFormUpdateMode | undefined>();

    const openEmailTemplateUpdateModal = (modalType: EmailTemplateMode['name'], payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        emailTemplate: EmailTemplate
    }) => {
        switch (modalType) {
            case 'updateNotificationEmailTemplate': {
                setEmailTemplateMode({
                    name: 'updateNotificationEmailTemplate',
                    payload
                })

                return;
            }

            case 'updatePassDecisionEmailTemplate': {
                setEmailTemplateMode({
                    name: 'updatePassDecisionEmailTemplate',
                    payload
                })

                return;
            }
            case 'updateFailDecisionEmailTemplate': {
                setEmailTemplateMode({
                    name: 'updateFailDecisionEmailTemplate',
                    payload
                })

                return;
            }
        }
    }

    const openEvaluationFormUpdateModal = (payload: {
        step: Entity<JobPosition_OneByIdWithStepEventsQuery, 'jobPosition.recruitmentStepsV2'>
        questions?: string | null,
        isModifiable: boolean
    }) => {
        setEmailTemplateMode({
            name: 'updateEvaluationForm',
            payload
        })
    }

    const closeSubModal = () => setEmailTemplateMode(undefined);

    return {
        emailTemplateMode,
        openEmailTemplateUpdateModal,
        openEvaluationFormUpdateModal,
        closeSubModal,
    }
}

export {
    useJobPositionDetailViewMode
}