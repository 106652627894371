import { hasValue, isFunction, isObject, isPrimitive } from '@helpers/core/typeGuards';
import { shuffle } from '@helpers/unsorted/arrayExtra';

const DEFAULT_PLACEHOLDER = '—';
const getWithDefault = (value?: string | null) => {
    if (hasValue(value) && value.trim().length > 0) {
        return value;
    } else {
        return DEFAULT_PLACEHOLDER;
    }
};

const stringify = (value: unknown) => {
    return isPrimitive(value)
        ? `${value}`
        : isObject(value)
        && isFunction(value.toString)
        && value.toString !== Object.prototype.toString
            ? value.toString()
            : JSON.stringify(value);
};

const shuffleString = (value: string) => {
    return shuffle([ ...value ]).join('');
};

const reverseString = (value: string) => {
    return [ ...value ].reverse().join('');
};

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export {
    DEFAULT_PLACEHOLDER,
    getWithDefault,
    stringify,
    shuffleString,
    reverseString,
    capitalizeFirstLetter
};
