import { Event_OneByIdQuery } from '@entities';
import { isDefined, isUndefined } from '@helpers/core/typeGuards';
import { dateTimeFormat } from '@helpers/unsorted/dateTimeFormat';
import { Entity } from '@typedefs/graphql';
import { isValid } from 'date-fns';
import { dateFormat } from './dateFormat';
import { ValueItem as MultiValueTypeAheadValue } from '@shared/unsorted/MultiValueTypeAhead/value'

const hiragana = /^[\u30FC\u3040-\u309F]*$/;

const katakana = /^[\u3000\u0020\u30A0-\u30FF]*$/;

const email = /.+@.+\..+/i;

const number = /^\d+$/;

const alphabetOnly = /\p{L}+/u

const digitsOnly = /\d+/gm

const zipCode = /^\d{7}$/

const alphaNumerical = /^[a-zA-Z0-9]*$/

const containsNumber = /[0-9]/

const containsUpperCase = /[A-Z]/

const containsLowerCase = /[a-z]/

const minimumPasswordLength = /.{8,}/

const validDate = (value?: string, acceptEmptyValues = false) => {
    if (acceptEmptyValues && value?.length === 0) {
        return true;
    }

    if (isDefined(value)) {
        return isValid(dateFormat.fromString(value))
    }

    return false;
};

const notEmpty = (value: string[] | undefined) => {
    // TODO: [CHECK] Might need to update this based on other use
    return isUndefined(value) || value.length > 0;
};

const emailArray = (value: MultiValueTypeAheadValue.ValueItemType[]) => {
    return value
        .map(({ label }) => label)
        .every(emailAddress => email.test(emailAddress))
}

// @ocaml.doc("Convert double width roman characters and spaces to half width roman characters")
const convertDoubleWidthToRoman = (value: string) => {
    return value.replace(/\u3000/g, String.fromCharCode(0x0020))
        .replace(/\u2212/g, String.fromCharCode(0x002D))
        .replace(/[\uff01-\uff5e]/g, (fullwidthChar) => {
            const charCode = fullwidthChar.codePointAt(0);

            return charCode ? String.fromCharCode(charCode - 0xfee0) : '';
        });
};

const validGraduationYearFormat = (value?: string, acceptEmptyValues = false) => {
    if (acceptEmptyValues && value?.length === 0) {
        return true;
    }

    if (isDefined(value)) {
        const halfWidth = convertDoubleWidthToRoman(value);

        return /^\d{4}$/.test(halfWidth);
    }

    return false;
};

const tooSmallInteger = (minValue: number, value?: string, acceptEmptyValues = false) => {
    if (acceptEmptyValues && value?.length === 0) {
        return true;
    }

    if (isDefined(value)) {
        const halfWidth = parseInt(convertDoubleWidthToRoman(value));

        return halfWidth >= minValue;
    }

    return false;
};
const uniqueSessionDateTimeValue = ({
    value,
    event,
    startDate,
    startTime
}: {
    value: string,
    event: Entity<Event_OneByIdQuery, 'event'>,
    startDate?: string,
    startTime?: string,
}) => {
    if (!startDate && !startTime) {
        return true
    }

    if (startDate && !startTime) {
        const newDate = dateTimeFormat.fromString(`${startDate} ${value}`);
        const overlappedSession = event.sessions.find(session => session.startAt === newDate?.toISOString())

        return overlappedSession === undefined
    }

    if (!startDate && startTime) {
        const newDate = dateTimeFormat.fromString(`${value} ${startTime}`);
        const overlappedSession = event.sessions.find(session => session.startAt === newDate?.toISOString())

        return overlappedSession === undefined
    }

    if (startDate && startTime) {
        const newDate = dateTimeFormat.fromString(`${startDate} ${startTime}`);
        const overlappedSession = event.sessions.find(session => session.startAt === newDate?.toISOString())

        return overlappedSession === undefined
    }
}

const uniqueLocationBetweenSessions = ({ value, event, initLocation }: {
    value: string,
    event: Entity<Event_OneByIdQuery, 'event'>,
    initLocation?: string,
}) => {
    return !event.isOnline ||
        value.length === 0
        || initLocation === value
        || event.sessions.find(session => session.groups.find(group => group.location === value) !== undefined) === undefined
}

const uniqueLocationBetweenGroup = ({ locations: formStateLocations, position, value }: { locations: string[], position: number, value: string }) => {
    return value.length === 0
        || formStateLocations.find((formStateLocation, index) => formStateLocation === value && index != position) === undefined
}

const uniqueLocationBetweenEvents = ({
    asyncValidateEventSessionLocation,
    event,
    value,
    initLocation
}: {
    asyncValidateEventSessionLocation: (location: string) => Promise<boolean>,
    event: Entity<Event_OneByIdQuery, 'event'>,
    value: string,
    initLocation?: string,
}) => {
    if (event.isOnline && initLocation !== value) {
        return asyncValidateEventSessionLocation(value)
    } else {
        return Promise.resolve(true)
    }
}

const extractDigitsFromString = (text: string) => {
    return text.match(digitsOnly)?.join("") || ""
}

const validZipCode = (value: string, acceptEmptyValues = false) => {
    if (acceptEmptyValues && value.length === 0) {
        return true;
    }

    const halfWidth = convertDoubleWidthToRoman(value)
    const hasAscii = alphabetOnly.test(halfWidth)
    const zipCodeDigits = extractDigitsFromString(halfWidth)

    return !hasAscii && zipCode.test(zipCodeDigits)
}

const password = (value: string) => {
  return (
      minimumPasswordLength.test(value) &&
      alphaNumerical.test(value) &&
      containsNumber.test(value) &&
      containsLowerCase.test(value) &&
      containsUpperCase.test(value)
  )
}

const validations = {
    hiragana,
    katakana,
    email,
    number,
    validDate,
    notEmpty,
    uniqueSessionDateTimeValue,
    uniqueLocationBetweenSessions,
    uniqueLocationBetweenGroup,
    uniqueLocationBetweenEvents,
    validZipCode,
    emailArray,
    convertDoubleWidthToRoman,
    extractDigitsFromString,
    validGraduationYearFormat,
    tooSmallInteger,
    alphaNumerical,
    containsNumber,
    containsLowerCase,
    containsUpperCase,
    minimumPasswordLength,
    password
};

export {
    validations,
};
