import { hasValue, isDefined } from "@helpers/core/typeGuards";
import { useClient } from "@helpers/hooks/unsorted/clientHook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as value from "./value";
import { useMutation } from "urql";
import { UpdateExternalEmailSignatureDocument, UpdateInternalEmailSignatureDocument } from "@entities";
import { getQueryContext, responseHasError } from "@helpers/unsorted/urqlExtra";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

const usePreferencesForm = (
    emailSignatures: {
        internalEmailSignature?: string | null | undefined;
        externalEmailSignature?: string | null | undefined;
    }
) => {
    const defaultValues = value.Encoder.defaultValues(emailSignatures);

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: defaultValues,
    });

    const { currentClient } = useClient();

    // Prevent edit for non-admin
    const isEditClientAdmin = useMemo(() => {
        if (currentClient.clientState === 'loggedIn') {
            const { clientInfo: { isScovilleAdmin, isScovilleUser, isAdmin } } = currentClient;

            return isScovilleAdmin || isScovilleUser || isAdmin;
        }

        return false
    }, [currentClient])

    const { error: toastError, success: toastSuccess, info: toastInfo } = useToast();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            form.formState.isDirty &&
            currentLocation.pathname !== nextLocation.pathname
    );

    const [updateExternalEmailSignatureResponse, updateExternalEmailSignature] =
        useMutation(UpdateExternalEmailSignatureDocument);

    const [updateInternalEmailSignatureResponse, updateInternalEmailSignature] =
        useMutation(UpdateInternalEmailSignatureDocument,);

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        setIsSubmitting(true);

        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            updateExternalEmailSignature({
                emailSignature: validValues.externalEmailSignature
            }, getQueryContext('ExternalEmailSignature'));
            updateInternalEmailSignature({
                emailSignature: validValues.internalEmailSignature
            }, getQueryContext('InternalEmailSignature'));
        } catch (error) {
            toastError('global.error');
            setIsSubmitting(false);
        }
    };

    const handleProceedBlocker = useCallback(() => {
        if (blocker.state === "blocked") {
            blocker.proceed();
        }
    }, [blocker]);

    const discardChanges = () => {
        handleProceedBlocker()
        toastInfo("settings.account.actions.discardNotification")
    }

    const handleResetBlocker = useCallback(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
    }, [blocker]);

    const handleResetForm = useCallback(() => {
        form.reset(defaultValues);
    }, [form]);

    useEffect(() => {
        // Used this method instead since we want to ensure that both APIs responded
        const isExternalSignatureUpdated = updateExternalEmailSignatureResponse.data?.updateExternalEmailSignature;
        const isInternalSignatureUpdated = updateInternalEmailSignatureResponse.data?.updateInternalEmailSignature;

        if (hasValue(isExternalSignatureUpdated) && hasValue(isInternalSignatureUpdated)) {
            if (isExternalSignatureUpdated && isInternalSignatureUpdated) {
                handleProceedBlocker();
                toastSuccess('settings.preferences.emailSignature.success');
            } else {
                toastError('settings.preferences.emailSignature.fail');
            }
            handleResetBlocker()
            setIsSubmitting(false);
        }

        if (responseHasError(updateExternalEmailSignatureResponse)
            || responseHasError(updateInternalEmailSignatureResponse)) {
            handleResetBlocker()
            toastError('settings.preferences.emailSignature.fail');
            setIsSubmitting(false);
        }
    }, [
        updateExternalEmailSignatureResponse,
        updateInternalEmailSignatureResponse,
    ]);

    useEffect(() => {
        form.reset(defaultValues);
    }, [emailSignatures])

    return {
        isEditClientAdmin,
        onSubmit: form.handleSubmit(onSubmit),
        isSubmitting,
        form,
        blocker,
        handleResetBlocker,
        discardChanges,
        handleResetForm,
    }
}

export {
    usePreferencesForm
}