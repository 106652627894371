import { ApplicationsImportedFromCsvDocument } from "@entities"
import { hasValue } from "@helpers/core/typeGuards"
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { useEffect, useState } from "react"
import { useSubscription } from "urql"

const useCSVImportImportStepInProgess = () => {
    const [imported, setImported] = useState(0)

    const [applicationsImportResponse] = useSubscription({ query: ApplicationsImportedFromCsvDocument })

    useEffect(() => {
        handleResponse(applicationsImportResponse, {
            onFetching: () => {
                setImported(0)
            },
            onData: ({ applicationsImportedFromCsv }) => {
                if (hasValue(applicationsImportedFromCsv)) {
                    const imported = applicationsImportedFromCsv.imported

                    setImported(imported)
                }
            }
        })
    }, [applicationsImportResponse])

    return {
        imported,
    }
}

export {
    useCSVImportImportStepInProgess
}
