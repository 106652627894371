import { Cn } from "@helpers/unsorted/classNames";
import { Button } from "@shared/unsorted/Button/Button";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { useApplicationEvaluationModal } from "./hook";
import { EvaluateApplicationPayload } from "../reducer";
import { Controller } from "react-hook-form";
import { TextArea } from "@shared/unsorted/TextArea/TextArea";
import { CancelConfirmationModal } from "./CancelConfirmationModal";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";

const styles = {
    title: Cn.c('font-h3-bold text-emphasized px-6 py-4 border-b border-b-default'),
    modalBody: Cn.c("py-6 px-12"),
    question: Cn.c('font-paragraph-small-medium text-default whitespace-pre-line'),
    buttons: Cn.c('flex flex-row space-x-4 justify-end px-6 py-4 bg-surface-subdued'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction;
    evaluationResponses?: EvaluateApplicationPayload
}

const ApplicationEvaluationModal: FunctionComponent<Props> = ({ isOpen, closeModal, evaluationResponses }) => {
    const { t } = useTranslation();    

    const {
        isSubmitting,
        form,
        currentEvaluationResponses,
        onSubmit,
        saveDraft,
        canSaveDraft,
        canSubmit,
        showCancelConfirmation,
        onCancel,
        onCancelReject,
        onCancelConfirm,
        showSubmitConfirmation,
        onSubmitReject,
        onSubmitConfirm,
    } = useApplicationEvaluationModal(closeModal, evaluationResponses)    

    const { control, formState: { errors }, clearErrors } = form
    
    return (
        <Modal isOpen={isOpen} close={() => !showCancelConfirmation && !showSubmitConfirmation && onCancel()} widthClass="w-[59rem]">
            <div className={styles.title}>{t("applications.panel.evaluateApplication.title")}</div>
            <form onSubmit={onSubmit}>
                <div className={styles.modalBody}>
                    {
                        currentEvaluationResponses.map((_, index) => {
                            return (
                                <div key={index}>
                                    <Controller
                                        name={`evaluationResponses.${index}.question`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value } }) => <p className={styles.question}>{`${index + 1}/ ${value}`}</p>}
                                    />
                                    <Controller
                                        name={`evaluationResponses.${index}.response`}
                                        control={control}
                                        render={({ field: { name, onBlur, onChange, ref, value } }) =>
                                        <TextArea
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            clearErrors={clearErrors}
                                            forwardedRef={ref}
                                            errors={errors}
                                            value={value}
                                        />}
                                />
                            </div>
                            );
                        })
                    }
                </div>
                <div className={styles.buttons}>
                    <Button size='md' variant='primaryPlain' onClick={saveDraft} isDisabled={!canSaveDraft || isSubmitting}>
                        {t('applications.panel.evaluateApplication.saveDraft')}
                    </Button>
                    <Button size='md' variant='secondary' onClick={onCancel} isDisabled={isSubmitting}>
                        {t('global.cancel')}
                    </Button>
                    <Button
                        size='md'
                        type='submit'
                        isLoading={isSubmitting}
                        isDisabled={!canSubmit}
                        variant='primaryFilled'>
                        {t('global.submit')}
                    </Button>
                </div>
            </form>
            <CancelConfirmationModal 
                isOpen={showCancelConfirmation}
                onConfirm={onCancelConfirm}
                onReject={onCancelReject}
            />
            <SubmitConfirmationModal 
                isOpen={showSubmitConfirmation}
                onConfirm={onSubmitConfirm}
                onReject={onSubmitReject}
            />
        </Modal>
    );
}

export {
    ApplicationEvaluationModal
}
