import React, { useCallback } from 'react';
import { Controller, UseFormReturn, Path, FieldValues, useFormContext, FieldErrors } from 'react-hook-form';
import { get } from 'lodash';

type UnknownPath = 'Unknown path - please use getPath()';

/**
 * Makes form components reusable.
 *
 * https://codesandbox.io/s/use-nested-form-v2-y13f33?file=/src/App.tsx
 *
 * @param parentPath - the path from the root of the form to this nested form.
 *
 * @example
 * ```tsx
 * import { defaultAddressFormValues, AddressForm } from './AddressForm';
 * import { defaultNameFormValues, NameForm } from './NameForm';
 *
 * const defaultPersonFormValues = {
 *   name: defaultNameFormValues,
 *   address: defaultAddressFormValues
 * };
 *
 * function PersonForm({ formPath }: NestedFormProps) {
 *   const { getErrors, getPath } = useNestedForm<typeof defaultPersonFormValues>(formPath);
 *   const nameErrors = getErrors('name'); // easily get errors
 *
 *   return (
 *     <fieldset>
 *       <legend>Person</legend>
 *       <NameForm formPath={getPath('name')} />
 *       {nameErrors}
 *       <AddressForm formPath={getPath('address')} />
 *     </fieldset>
 *   );
 * }
 * ```
 */
export function useNestedForm<NestedValues extends FieldValues>(
    // Use `string | undefined` instead of ? to make sure something is passed in as it's easy to forget
    parentPath: string | undefined
) {
    const form = useFormContext<Record<UnknownPath, NestedValues>>();

    const getPath = useCallback(
        <FormPath extends Path<NestedValues> | undefined = undefined>(
            childPath?: FormPath
        ): FormPath extends undefined ? UnknownPath : `${UnknownPath}.${NonNullable<typeof childPath>}` => {
            // Generic-extending unions cannot be narrowed - https://github.com/Microsoft/TypeScript/issues/13995
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return childPath ? joinPaths(parentPath, childPath) : (childPath as any);
        },
        [parentPath]
    );

    // const getErrors = useCallback(
    //     <FormPath extends Path<NestedValues> | undefined = undefined>(
    //         childPath?: FormPath
    //     ): FormPath extends undefined ? FieldErrors<NestedValues> : GetByPath<FieldErrors<NestedValues>, FormPath> => {
    //         return get(form.formState.errors, getPath(childPath));
    //     },
    //     [form.formState.errors, getPath]
    // );

    return { ...form, getPath };
}

/**
 * Can be extended by your props def.
 *
 * @example
 * ```ts
 * export interface PersonFormProps extends UseNestedFormProps {
 *   title: string;
 * }
 *
 * function PersonForm({ formPath, title }: PersonFormProps) {
 *   const form = useNestedForm(formPath);
 * }
 * ```
 */
export interface NestedFormProps {
    /** The path from the root of the form to this nested form. Can be omitted if the child form controls should appear at the same level as the parent. */
    path?: string;
}


/**
 * Used to join parent form paths with child form paths.
 *
 * @example
 * ```ts
 * joinPaths('a.', '.b.', '3, 'd.'); // returns 'a.b.3.d'
 * ```
 */
export function joinPaths(...paths: (string | number | undefined | null)[]): string {
    return paths
        .join('.') // add . between each path
        .replace(/\.{2,}/g, '.') // replace .. with .
        .replace(/^\./, '') // remove leading .
        .replace(/\.$/, ''); // remove trailing .
}

/**
 * Used to get a deeply nested type via a provided string path using dot notation.
 *
 * @example
 * ```ts
 * const obj = { person: { name: string, age: number }};
 * type A = GetByPath<obj, 'person'>; // { name: string; age: number }
 * type B = GetByPath<obj, 'person.name'>; // string
 * type C = GetByPath<obj, 'person.age'>; // number
 * type D = GetByPath<obj, 'person.invalid'>; // undefined
 * ```
 * https://dev.to/tipsy_dev/advanced-typescript-reinventing-lodash-get-4fhe
 */
export type GetByPath<Obj, Path> = Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Obj
    ? GetByPath<Exclude<Obj[Left], undefined>, Right> | Extract<Obj[Left], undefined>
    : undefined
    : Path extends keyof Obj
    ? Obj[Path]
    : undefined;