import { DeleteEventSessionGroupDocument } from "@entities";
import { hasValue } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { useEffect, useState } from "react";
import { useMutation } from "urql";

interface EventSessionGroupDeleteModalHookType {
    isSubmitting: boolean;
    deleteGroup: () => void;
}

const useEventSessionGroupDeleteModal = (closeModal: VoidFunction, groupId?: string): EventSessionGroupDeleteModalHookType => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { error: toastError, success: toastSuccess } = useToast();

    const [deleteEventSessionGroupResponse, deleteEventSessionGroup] = useMutation(DeleteEventSessionGroupDocument);

    const deleteGroup = () => {
        if (hasValue(groupId)) {
            deleteEventSessionGroup({
                id: groupId,
            }, getQueryContext('EventSessionGroup'));
        }
    };

    useEffect(() => {
        handleResponse(deleteEventSessionGroupResponse, {
            onFetching: () => {
                setIsSubmitting(true)
            },
            onData: (data) => {
                setIsSubmitting(false)
                if (data.deleteEventSessionGroup) {
                    toastSuccess('event.eventSessionGroupDeleteModal.success');
                    closeModal();
                } else {
                    toastError('event.eventSessionGroupDeleteModal.error');
                }
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('event.eventSessionGroupDeleteModal.error');
            },
        });
    }, [deleteEventSessionGroupResponse]);

    return {
        isSubmitting,
        deleteGroup,
    }
}

export {
    useEventSessionGroupDeleteModal
};

