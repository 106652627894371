import { Cn } from "@helpers/unsorted/classNames"
import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout"
import { Loader } from "@shared/unsorted/Loader/Loader"
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader"
import { FunctionComponent } from "react"
import { CreateButton } from "../CreateButton"

const styles = {
    container: Cn.c("flex items-center justify-center flex-1")
}

const JobPositionsLoadingState: FunctionComponent = () => {
    return (
        <DefaultLayout>
            <PageHeader
                action={<CreateButton size='sm' />}
            >
            </PageHeader>
            <div className={styles.container}>
                <Loader variant="primaryDefault" size="lg" />
            </div>
        </DefaultLayout>
    )
}

export {
    JobPositionsLoadingState
}
