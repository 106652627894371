import { Cn } from '@helpers/unsorted/classNames';
import { dateFormat } from '@helpers/unsorted/dateFormat';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const jobCardVariants = ['default', 'selected'] as const;

type Variant = typeof jobCardVariants[number];

const styles = {
    container: (variant: Variant) => {
        let variantStyle: string;

        switch (variant) {
            case 'default':
                variantStyle = Cn.c('border-subdued bg-surface-default hover:border-subdued hover:bg-surface-hovered-emphasized');
                break;
            case 'selected':
                variantStyle = Cn.c('border-primary-default bg-surface-primary-subdued');
                break;
        }

        return Cn.join([
            Cn.c('w-full px-6 py-3 cursor-pointer border rounded-lg'),
            variantStyle,
        ]);
    },
    jobId: Cn.c('font-paragraph-xsmall-regular text-subdued'),
    jobTitle: Cn.c('font-h4-bold text-emphasized pt-1'),
    lastUpdate: Cn.c('font-paragraph-xsmall-link-regular text-subdued pt-6'),
};

interface Props {
    variant?: Variant;
    publicId: number;
    title: string;
    updatedAt: string;
    onClick?: VoidFunction
}

const JobCard: FunctionComponent<Props> = ({ variant = 'default', publicId, title, updatedAt, onClick}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container(variant)} onClick={onClick}>
            <p className={styles.jobId}>{`${t("jobPositions.list.table.publicId")} ${publicId.toString().padStart(4, "0")}`}</p>
            <p className={styles.jobTitle}>{title}</p>
            <p className={styles.lastUpdate}>{`${t("jobPositions.list.table.updatedAt")} ${dateFormat.simpleDateWithoutWeekday(new Date(updatedAt), "-")}`}</p>
        </div>
    );
};

export {
    JobCard,
    jobCardVariants
};

