import { Cn } from '@helpers/unsorted/classNames';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { Progress } from '@shared/unsorted/Progress/Progress';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
    container: Cn.c('z-[200] fixed flex flex-col items-center justify-center w-full h-full bg-surface-default'),
    progressBar: Cn.c('mt-12 w-[25rem]'),
    logo: Cn.c('w-[8.8rem] h-4'),
    loading: Cn.c('font-paragraph-base-medium text-subdued mt-4'),
};

interface Props {
    progress: number;
}

const Loading: FunctionComponent<Props> = ({ progress }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <Icon name="logo" className={styles.logo} />
            <Progress progressValue={progress} className={styles.progressBar} />
            <p className={styles.loading}>{t("loading")}</p>
        </div>
    );
};

export {
    Loading,
};