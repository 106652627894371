import { createContext, useContext } from "react";
import { BulkActionModalMode } from "./reducer"
import { isUndefined } from "@helpers/core/typeGuards";

type BulkActionModalContextType = {
    dispatchBulkActionModalMode: (bulkActionModalMode: BulkActionModalMode) => void
}

const BulkActionModalContext = createContext<BulkActionModalContextType | undefined>(undefined);

const useBulkActionModalContext = () => {
    const context = useContext(BulkActionModalContext)

    if (isUndefined(context)) {
        throw new Error('This component must be used inside a <BulkActionModalProvider>...</BulkActionModalProvider> block.');
    }

    return context
}

const BulkActionModalProvider = BulkActionModalContext.Provider

export {
    useBulkActionModalContext,
    BulkActionModalProvider
}