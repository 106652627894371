import { FormEventHandler, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { UpdateRecruitmentProcessDocument } from '@entities';
import { useMutation } from 'urql';
import { handleResponse } from '@helpers/unsorted/urqlExtra';
import * as value from './value';

// TODO: [CHECK] Consider moving this to a separate type file since this is used several times in different places
type Mode = 'show' | 'edit';

interface ApplicationNoteHookResult {
    mode: Mode;
    toEdit: VoidFunction;
    toShow: VoidFunction;
    isSubmitting: boolean;
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
}

const useApplicationNote = (id: string, note?: string): ApplicationNoteHookResult => {
    const [mode, setMode] = useState<Mode>('show');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { error: toastError, success: toastSuccess } = useToast();

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: value.Encoder.defaultValues(note),
    });

    const [updateApplicationResponse, updateApplication] = useMutation(UpdateRecruitmentProcessDocument);

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {

        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            updateApplication({
                id,
                note: validValues.note,
            });
        } catch {
            toastError('global.error');
        }
    };

    const toEdit = () => setMode('edit');

    const toShow = () => {
        setMode('show');

        form.reset(value.Encoder.defaultValues(note));
    };

    useEffect(() => {
        handleResponse(updateApplicationResponse, {
            onFetching: () => setIsSubmitting(true),
            onData: () => {
                setIsSubmitting(false);
                toastSuccess('applications.panel.note.success');
                setMode('show');
            },
            onError: () => {
                setIsSubmitting(false);
                toastError('applications.panel.note.error');
            },
        });
    }, [updateApplicationResponse]);

    useEffect(() => {
        toShow();
    }, [id]);

    return {
        mode,
        toEdit,
        toShow,
        isSubmitting,
        form,
        onSubmit: form.handleSubmit(onSubmit),
    };
};

export {
    useApplicationNote,
};