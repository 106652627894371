import { EventSession_OneByIdDocument, EventSession_OneByIdQuery } from "@entities"
import { handleResponse } from "@helpers/unsorted/urqlExtra"
import { AsyncResponseType } from "@typedefs/aliases"
import { Entity } from "@typedefs/graphql"
import { useEffect, useState } from "react"
import { useQuery } from "urql"

type InitialStateData = {
    eventSession: Entity<EventSession_OneByIdQuery, 'eventSession'>
}

type InitialState = AsyncResponseType<InitialStateData>

const useInitialState = (sessionId: string) => {
    const [initialState, setInitialState] = useState<InitialState>({
        isLoading: true
    })

    const [eventSessionResponse,] = useQuery({
        query: EventSession_OneByIdDocument,
        variables: {
            id: sessionId
        },
    });

    useEffect(() => {
        handleResponse(
            eventSessionResponse,
            {
                onData: (data) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: true,
                            data: {
                                eventSession: data.eventSession
                            }
                        }
                    })
                },
                onError: (_error) => {
                    setInitialState({
                        isLoading: false,
                        result: {
                            isSuccess: false
                        }
                    })
                }
            }
        )
    }, [eventSessionResponse])

    return {
        initialState
    }
}

export {
    useInitialState,
    InitialState,
    InitialStateData
}